var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.modalWrapper }, [
    _c("div", { class: _vm.$style.block }, [
      _c("h2", { class: _vm.$style.block_ttl }, [
        _vm._v("업로드데이터 수정의뢰"),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.block_container }, [
        _c(
          "div",
          { class: _vm.$style.modal },
          [
            _c("p", { class: _vm.$style.modal_lightGray }, [
              _vm._v(
                "\n          견적/주문No." +
                  _vm._s(_vm.values.request_number) +
                  "\n          "
              ),
              _c("br"),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.values.order_data_name) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("PrTextarea", {
              attrs: {
                label: "아래 수정내용을 입력해주세요",
                name: "modify_content",
                validate: "required",
                "pr-row": [100, 100],
              },
              model: {
                value: _vm.textariaValue,
                callback: function ($$v) {
                  _vm.textariaValue = $$v
                },
                expression: "textariaValue",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$style.buttons },
              [
                _c(
                  "Button",
                  {
                    attrs: { color: "submit", size: "assist" },
                    on: {
                      click: function ($event) {
                        return _vm.handleModifyRequest()
                      },
                    },
                  },
                  [_vm._v("수정 의뢰하기")]
                ),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    attrs: { color: "cancel", size: "assist" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClose()
                      },
                    },
                  },
                  [_vm._v("취소")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }