var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.values !== null
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("DealingHeader"),
          _vm._v(" "),
          _c("PageTitle", [_vm._v("入金・商品配送情報")]),
          _vm._v(" "),
          _c(
            "main",
            { class: _vm.$style.container },
            [
              _c("AdminDealingDeliveryProductInfo", {
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              }),
              _vm._v(" "),
              _c("AdminDealingDeliveryDeliveryInfo", {
                attrs: { deal: _vm.items },
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              }),
              _vm._v(" "),
              _c("AdminDealingDeliveryAddressInfo", {
                attrs: {
                  whom: "sender",
                  head: "送り主情報",
                  deal: _vm.items,
                  "customer-persons": _vm.customerPersons,
                  customerPersonOptions: _vm.customerPersonOptions.sender,
                },
                model: {
                  value: _vm.values.shipping,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "shipping", $$v)
                  },
                  expression: "values.shipping",
                },
              }),
              _vm._v(" "),
              _c("AdminDealingDeliveryAddressInfo", {
                attrs: {
                  whom: "delivery",
                  head: "配送先情報",
                  deal: _vm.items,
                  "customer-persons": _vm.customerPersons,
                  customerPersonOptions: _vm.customerPersonOptions.delivery,
                },
                model: {
                  value: _vm.values.shipping,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "shipping", $$v)
                  },
                  expression: "values.shipping",
                },
              }),
              _vm._v(" "),
              _c("AdminDealingDeliveryPaymentInfo", {
                attrs: { contracts: _vm.items.contract },
                model: {
                  value: _vm.values.contract,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "contract", $$v)
                  },
                  expression: "values.contract",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.buttons },
            [
              _c("DialogProvider", {
                attrs: {
                  texts: {
                    ttl: "確認",
                    msg: `お客様に入金確認完了のメールが送信されます。`,
                    yas: "OK",
                    no: "キャンセル",
                  },
                },
                on: { accept: _vm.handleSaveButtonClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "Button",
                            {
                              attrs: { size: "main", color: "submit" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSaveButtonClick(on, $event)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2646940753
                ),
              }),
              _vm._v(" "),
              _c(
                "Button",
                {
                  attrs: { size: "assist", color: "cancel" },
                  on: { click: _vm.initValues },
                },
                [_vm._v("クリア")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }