var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.wrapper },
    [
      _c("div", { class: _vm.$style.noticeWrappe }, [
        _c("div", { class: _vm.$style.notice }, [
          _c("p", { class: _vm.$style.notice_head }, [_vm._v("お知らせ")]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _vm.$style.notice_content }, [
              _c("p", { class: _vm.$style.notice_content_date }, [
                _vm._v("2019/10/11"),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.notice_content_txt }, [
                _vm._v(
                  "\n            「ご商談一覧」ページにてお取引先様全員分の商談が表示されるようになりました。注文月/担当者/ステータスで商談を絞り込むことができます。\n          "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("PageTitle", [_vm._v("マイページ")]),
      _vm._v(" "),
      _c(
        "section",
        { class: _vm.$style.index },
        [
          _c("router-link", { attrs: { to: "/customer/dealings" } }, [
            _c("h3", [
              _c(
                "span",
                [
                  _c("IconKarter", { attrs: { height: "36", width: "36" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("ご商談一覧")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        現在進行中の商談の一覧が確認できます。また、お見積りの確認やご注文、データの入稿もこちらから行うことができます。\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/customer/ordered-histories" } }, [
            _c("h3", [
              _c(
                "span",
                [
                  _c("IconClock", { attrs: { height: "36", width: "36" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("ご注文履歴")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        ご注文の履歴が確認できます。また、履歴情報をもとに再注文を行うことができます｡\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/customer/accounts" } }, [
            _c("h3", [
              _c(
                "span",
                [
                  _c("IconUser", { attrs: { height: "36", width: "36" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("アカウント情報")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        メールアドレス・パスワードの変更や、ご登録のメールアドレスに紐づく会員情報の確認・変更、メールマガジンの設定を行うことができます。\n      "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.HPLinks }, [
        _c("h2", { class: _vm.$style.ttl }, [_vm._v("ホームページリンク")]),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "https://www.pr39.com/contact/", target: "_blank" },
          },
          [
            _c("v-icon", [_vm._v("launch")]),
            _vm._v(" "),
            _c("h3", [_vm._v("お問い合わせ")]),
            _vm._v(" "),
            _c("p", [_vm._v("お気軽にお問い合わせください。")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "https://www.pr39.com/sample/", target: "_blank" } },
          [
            _c("v-icon", [_vm._v("launch")]),
            _vm._v(" "),
            _c("h3", [_vm._v("サンプル請求")]),
            _vm._v(" "),
            _c("p", [_vm._v("紙質、色、糊位置などをご確認いただけます。")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.pr39.com/printorder/",
              target: "_blank",
            },
          },
          [
            _c("v-icon", [_vm._v("launch")]),
            _vm._v(" "),
            _c("h3", [_vm._v("既製品見積")]),
            _vm._v(" "),
            _c("p", [
              _vm._v("カバーなしふせん、バラエティふせん、その他ノベルティ等"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.pr39.com/original-form/",
              target: "_blank",
            },
          },
          [
            _c("v-icon", [_vm._v("launch")]),
            _vm._v(" "),
            _c("h3", [_vm._v("オーダーメイド見積")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        オリジナルふせんのお見積依頼はこちらから。お見積後、商品の注文が可能となります。\n      "
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.pr39.com/printorder/",
              target: "_blank",
            },
          },
          [
            _c("v-icon", [_vm._v("launch")]),
            _vm._v(" "),
            _c("h3", [_vm._v("既製品注文")]),
            _vm._v(" "),
            _c("p", [
              _vm._v("カバーなしふせん、バラエティふせん、その他ノベルティ等"),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }