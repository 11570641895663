import * as types from "@/store/mutation-types"
import api, { paths } from "@/utils/api.js"

const state = {
  items: [],
}

/*
  {
    "status": 1,
    "lock_version": 0,
    "language_code": null,
    "id": 1,
    "display_sort_no": 1,
    "display_discernment_code": null,
    "definition_value": "広告代理業",
    "definition_name": "業種",
    "definition_code": "1",
    "definition_category": "Industry",
    "definition_abbreviated": null
  },
*/
const getters = {
  getDefinitionByKey: state => (category, definition, code, lang = null) => {
    const data = state.items.filter(
      data => data.definition_category === category,
    )
    const target = data.find(data => {
      let result = false
      if (data.definition_code == code) {
        if (lang != null) {
          if (data.language_code === lang) {
            result = true
          }
        } else {
          result = true
        }
      }
      return result
    })
    if (!target) {
      console.log("getDefinitionByKey error")
      return ""
    }
    return target[definition]
  },
  getOptionValueByKey: state => (category, code, lang = null) => {
    const data = state.items.filter(
      data => data.definition_category === category,
    )
    const target = data.find(data => {
      let result = false
      if (data.definition_code == code) {
        if (lang != null) {
          if (data.language_code === lang) {
            result = true
          }
        } else {
          result = true
        }
      }
      return result
    })
    if (!target) {
      console.log("getOptionValueByKey error")
      return ""
    }
    return target.definition_value
  },
  getOptionKeyByValue: state => (category, value, lang = null) => {
    const data = state.items.filter(
      data => data.definition_category === category,
    )
    const target = data.find(data => {
      let result = false
      if (data.definition_value == value) {
        if (lang != null) {
          if (data.language_code === lang) {
            result = true
          }
        } else {
          result = true
        }
      }
      return result
    })
    if (!target) {
      console.log("getOptionKeyByValue error")
      return null
    }
    return target.definition_code
  },
  getOptions: state => (category, lang = null) => {
    const data = state.items.filter(data => {
      var isCategory = data.definition_category === category

      if (lang == null) {
        return isCategory
      } else {
        return isCategory && data.language_code === lang
      }
    })
    return data.map(v => {
      return {
        key: v.definition_code,
        value: v.definition_value,
      }
    })
  },
  getOptionsByDefinition: state => (
    category,
    definition,
    code,
    lang = null,
  ) => {
    const data = state.items.filter(data => {
      var isCategory = data.definition_category === category

      if (lang == null) {
        return isCategory
      } else {
        return isCategory && data.language_code === lang
      }
    })
    return data
      .filter(v => v[definition] == code)
      .map(v => {
        return {
          key: v.definition_code,
          value: v.definition_value,
        }
      })
  },
}

const actions = {
  fetch: async ctx => {
    const { ok: result, body: data } = await api.post(
      paths.SEARCH_DEFINITIONS,
      {},
      ctx.rootState.auth.token,
    )

    if (result) {
      ctx.commit(types.FETCH_SEARCH_DEFINITIONS, data)
    }
    return { ok: result, body: data }
  },
}

const mutations = {
  [types.FETCH_SEARCH_DEFINITIONS](state, payload) {
    state.items = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
