import SupplierDealingIndex from "@/components/organisms/supplier/SupplierDealingIndex.vue"
import SupplierContract from "@/components/organisms/supplier/SupplierContract.vue"
import SupplierGoodsIssue from "@/components/organisms/supplier/SupplierGoodsIssue.vue"
import SupplierOrdersheetKY from "@/components/organisms/admin/AdminDealingOrdersheetKY.vue"
import SupplierOrdersheetAC from "@/components/organisms/admin/AdminDealingOrdersheetAC.vue"
import SupplierOrdersheetTN from "@/components/organisms/admin/AdminDealingOrdersheetTN.vue"

const role = ["supplier"]

const routes = [
  {
    path: "/supplier",
    name: "supplier",
    component: SupplierDealingIndex,
    meta: { role, title: "견적/주문일람" },
  },
  {
    path: "/supplier/dealings",
    name: "supplierDealingIndex",
    component: SupplierDealingIndex,
    meta: { role, title: "견적/주문일람" },
  },
  {
    path: "/supplier/dealings/:deal_id/contract",
    name: "supplierContract",
    component: SupplierContract,
    meta: { role, title: "견적 입력" },
  },
  {
    path: "/supplier/dealings/:deal_id/goods-issue",
    name: "supplierGoodsIssue",
    component: SupplierGoodsIssue,
    meta: { role, title: "발송정보 입력" },
  },
  {
    path: "/supplier/dealings/:deal_id/ordersheet/ky",
    name: "supplierOrdersheetKY",
    component: SupplierOrdersheetKY,
    meta: { role, title: "주문서" },
  },
  {
    path: "/supplier/dealings/:deal_id/ordersheet/ac",
    name: "supplierOrdersheetAC",
    component: SupplierOrdersheetAC,
    meta: { role, title: "주문서" },
  },
  {
    path: "/supplier/dealings/:deal_id/ordersheet/tn",
    name: "supplierOrdersheetTN",
    component: SupplierOrdersheetTN,
    meta: { role, title: "주문서" },
  },
]

export default routes
