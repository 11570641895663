<template>
  <p :class="$style.userName">
    {{ customerPerson.last_name + " " + customerPerson.first_name + " " }}様
  </p>
</template>

<script>
export default {
  props: {
    customerPerson: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.userName {
  max-width: 250px;
  text-align: center;
  margin: 0 auto 20px;
  padding: 5px 10px;
  background-color: map-get($colors, yellow);
}
</style>
