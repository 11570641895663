<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>見積確認</PageTitle>
    <CustomerUserName :customer-person="items.customer_person" />
    <InfoBlock width="100%" margin="0" center>
      <template slot="head">
        お客様（注文者）ご住所
      </template>
      <p :class="$style.explain">
        下記のお客様の見積内容から、注文を確定したい内容を選択して下さい。
      </p>
      <div :class="$style.contracts">
        <div
          :class="$style.quotation"
          v-for="(detail, index) in details"
          :key="index"
        >
          <PrInputRadio
            :label="`見積NO.${detail.no}`"
            name="quotation"
            v-model="orderVal"
            :value="index"
          />

          <p
            v-if="detail.productPeriod != null && detail.productPeriod != '0'"
            :class="$style.quotation_productionPeriod"
            v-cloak
          >
            製作期間：ご注文（データ入稿）から
            <span>{{ detail.productPeriod }}日後</span
            >に発送予定（土日祝を除く）
          </p>
          <div
            :class="$style.quotation_detail"
            v-for="(item, index2) in detail.data"
            :key="index2"
          >
            <div :class="$style.quotation_detail_row" v-if="item.name.visible">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.name.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.name.value }}
              </p>
            </div>

            <div :class="$style.quotation_detail_row" v-if="item.size1.visible">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.size1.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.size1.value }}
              </p>
            </div>

            <div
              :class="$style.quotation_detail_row"
              v-if="item.category3.visible"
            >
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.category3.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.category3.value }}
              </p>
            </div>

            <div :class="$style.quotation_detail_row" v-if="item.size2.visible">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.size2.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.size2.value }}
              </p>
            </div>

            <div
              :class="$style.quotation_detail_row"
              v-if="item.category4.visible"
            >
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.category4.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.category4.value }}
              </p>
            </div>

            <div :class="$style.quotation_detail_row" v-if="item.color.visible">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.color.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.color.value }}
              </p>
            </div>

            <div :class="$style.quotation_detail_row" v-if="item.size3.visible">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.size3.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.size3.value }}
              </p>
            </div>

            <div
              :class="$style.quotation_detail_row"
              v-if="item.amount.visible"
            >
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.amount.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.amount.value | formatDecimalAmount }}
              </p>
            </div>

            <div :class="$style.quotation_detail_row" v-if="item.price.visible">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.price.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.price.value | formatDecimalPrice }}
              </p>
            </div>

            <div :class="$style.quotation_detail_row" v-if="item.total.visible">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ item.total.label }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ item.total.value | formatDecimalPrice }}
              </p>
            </div>
            <div :class="$style.quotation_detail_divider" />
          </div>

          <div
            :class="$style.quotation_detail"
            v-for="(option, index) in detail.options"
            :key="`option-${index}`"
          >
            <div :class="$style.quotation_detail_row">
              <p :class="$style.quotation_detail_row_name" v-cloak>
                {{ option.name }}
              </p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ option.price | formatDecimalPrice }}
              </p>
            </div>
          </div>

          <div
            :class="$style.quotation_detail"
            v-if="detail.options.length !== 0"
          >
            <div :class="$style.quotation_detail_divider" />
          </div>

          <div :class="$style.quotation_detail">
            <div :class="$style.quotation_detail_row">
              <p :class="$style.quotation_detail_row_name">備考</p>
              <!-- prettier-ignore -->
              <p :class="$style.quotation_detail_row_note" v-cloak>{{ detail.note1 }}</p>
            </div>
            <div :class="$style.quotation_detail_row">
              <p :class="$style.quotation_detail_row_name">見積総額(税別)</p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{
                  (+detail.total_amount + +detail.discount) | formatDecimalPrice
                }}
              </p>
            </div>
            <div :class="$style.quotation_detail_row">
              <p :class="$style.quotation_detail_row_name">消費税</p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{ detail.tax_amount | formatDecimalPrice }}
              </p>
            </div>
            <template v-if="detail.discount != '0'">
              <div :class="$style.quotation_detail_row">
                <p :class="$style.quotation_detail_row_name">お値引き</p>
                <p :class="$style.quotation_detail_row_txt" v-cloak>
                  -{{ detail.discount | formatDecimalPrice }}
                </p>
              </div>
            </template>
            <div :class="$style.quotation_detail_row">
              <p :class="$style.quotation_detail_row_name">見積総額(税込)</p>
              <p :class="$style.quotation_detail_row_txt" v-cloak>
                {{
                  (Number(detail.total_amount) + Number(detail.tax_amount))
                    | formatDecimalPrice
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.bottomAria">
        <p :class="[$style.explain, $style.rice]">
          ※カバーへのフルカラー印刷無料。 OPP個包装・送料無料
          但し、送料は一括納品 に限ります。
        </p>
        <p :class="[$style.explain, $style.rice]">
          ※お見積価格はご入稿いただくデザインの内容に
          よっては変動がある場合がございます。
          お見積の有効期限は1ヵ月となっております。
        </p>
        <div :class="$style.button">
          <Button color="submit" size="main" @click="handleMoveOrder"
            >注文画面へ進む</Button
          >
        </div>
        <div :class="$style.proof">
          <p :class="$style.proof_txt">
            注文前に色校正を希望される方はこちらから
            （色校正の場合、仕様により代金が変わります
            ので、受付後弊社からメールでお知らせいた します）
          </p>
          <div :class="$style.button">
            <Button color="submit" size="main" @click="handleMoveProof"
              >色校正へ進む</Button
            >
          </div>
        </div>
      </div>
    </InfoBlock>
  </div>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import PrInputRadio from "@/components/atoms/PrInputRadio.vue"
import CustomerUserName from "@/components/atoms/CustomerUserName.vue"
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError, formatDecimalPrice } from "@/utils/shared.js"
import * as math from "mathjs"

export default {
  components: {
    PrInputRadio,
    InfoBlock,
    Button,
    PageTitle,
    CustomerUserName,
  },
  data() {
    return {
      isReady: false,
      id: null,
      items: null,
      orderVal: 0,
      details: [],
    }
  },
  filters: {
    formatDecimalPrice: num => formatDecimalPrice(num),
    formatDecimalAmount: num => formatDecimalPrice(num, false, ""),
  },
  async created() {
    this.$store.dispatch("customer/clearOrderInfo")

    this.id = this.$route.params.deal_id
    const { ok, body } = await api.get(
      `${paths.CUSTOMER_DEALINGS}/${this.id}`,
      this.$store.state.auth.token,
    )

    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }

    this.items = body

    // 見積確認・注文が未確認でない場合
    if (this.items.contract_status != 1) {
      routeReplaceError(this, 900)
      return
    }

    this.$store.dispatch("customer/setRequestId", this.id)
    this.$store.dispatch("customer/setDealing", this.items)

    const table = {
      hs: {
        // 付箋
        name: { label: "商品名", visible: true, value: null },
        size1: { label: "カバーサイズ", visible: true, value: null },
        category3: { label: "dummy", visible: false, value: null },
        size2: { label: "付箋サイズ", visible: true, value: null },
        category4: { label: "dummy", visible: false, value: null },
        color: { label: "付箋印刷", visible: true, value: null },
        size3: { label: "付箋枚数", visible: true, value: null },
        amount: { label: "生産個数", visible: true, value: null },
        price: { label: "単価(税別)", visible: true, value: null },
        total: { label: "総額(税別)", visible: true, value: null },
      },
      mf: {
        // マイクロファイバー
        name: { label: "商品名", visible: true, value: null },
        size1: { label: "サイズ", visible: true, value: null },
        category3: { label: "dummy", visible: false, value: null },
        size2: { label: "dummy", visible: false, value: null },
        category4: { label: "dummy", visible: false, value: null },
        color: { label: "印刷", visible: true, value: null },
        size3: { label: "dummy", visible: false, value: null },
        amount: { label: "生産個数", visible: true, value: null },
        price: { label: "単価(税別)", visible: true, value: null },
        total: { label: "総額(税別)", visible: true, value: null },
      },
      om: {
        // オーダーメイド
        name: { label: "商品名", visible: true, value: null },
        size1: { label: "カバーサイズ", visible: true, value: null },
        category3: { label: "カバー型抜き", visible: true, value: null },
        size2: { label: "付箋サイズ", visible: true, value: null },
        category4: { label: "付箋型抜き", visible: true, value: null },
        color: { label: "付箋印刷", visible: true, value: null },
        size3: { label: "付箋枚数", visible: true, value: null },
        amount: { label: "生産個数", visible: true, value: null },
        price: { label: "単価(税別)", visible: true, value: null },
        total: { label: "総額(税別)", visible: true, value: null },
      },
    }

    this.items.contract.forEach(item => {
      let arrayData = []
      let options = []
      item.contract.contract_details.forEach(detail => {
        let data = {}
        if (["ready_made", "order_made"].indexOf(detail.category2) >= 0) {
          switch (detail.jan_code) {
            case "0":
              data = JSON.parse(JSON.stringify(table.hs))
              break
            case "1":
              data = JSON.parse(JSON.stringify(table.mf))
              break
            case "2":
              data = JSON.parse(JSON.stringify(table.om))
              break
            default:
              data = JSON.parse(JSON.stringify(table.hs))
              break
          }

          data.name.value =
            this.getProductFamily(detail.category1) + " " + detail.name
          data.size1.value = detail.size1
          data.category3.value = this.getCuttings(detail.category3)
          data.size2.value = detail.size2
          data.category4.value = this.getCuttings(detail.category4)
          data.color.value = this.getTagPrint(detail.color)
          data.size3.value = detail.size3
          data.amount.value = detail.amount
          data.price.value = math
            .chain(math.bignumber(Number(detail.price)))
            .subtract(math.bignumber(Number(detail.thumbnail)))
            .done()
            .toNumber()
          data.total.value = math
            .chain(math.bignumber(Number(detail.amount)))
            .multiply(math.bignumber(Number(data.price.value)))
            .floor()
            .done()
            .toNumber()
          arrayData.push(JSON.parse(JSON.stringify(data)))
        } else {
          if (Number(detail.price) !== 0 && detail.category2 != "discount") {
            const priceTable = {
              proofreading: { sort: 0, name: "色校正代金(税別)" },
              design_fee: { sort: 1, name: "デザイン料(税別)" },
              mount_fee: { sort: 2, name: "MF台紙代(税別)" },
              type_fee: { sort: 3, name: "型代(税別)" },
              shipping_fee: { sort: 4, name: "その他送料(税別)" },
              other_fee: { sort: 5, name: "その他代金(税別)" },
              // discount: { sort: 9, name: "値引(税別)" },
            }
            options.push({
              sort: priceTable[detail.category2].sort,
              name: priceTable[detail.category2].name,
              price: Number(detail.price),
            })
          }
        }
      })
      this.details.push({
        no: item.contract.contract_no,
        productPeriod: item.contract.shipping_fee,
        tax_amount: item.contract.tax_amount,
        total_amount: item.contract.total_amount,
        discount: item.contract.contract_details.find(
          ({ category2 }) => category2 == "discount",
        ).price,
        note1: item.note1,
        options: options.sort((a, b) => a.sort - b.sort),
        data: arrayData,
      })
    })

    // 見積Noを検索する
    const orderVal = this.details.findIndex(value => {
      if (value.no == this.$store.state.customer.selectedEstimateNo) {
        return true
      }
    })

    if (orderVal != -1) {
      this.orderVal = orderVal
    } else {
      this.$store.dispatch("customer/clearEstimateNo")
    }

    this.isReady = true
  },
  methods: {
    getCuttings(val) {
      return this.$store.getters["defs/getOptionValueByKey"]("Cuttings", val)
    },
    getProductFamily(val) {
      return this.$store.getters["defs/getOptionValueByKey"](
        "ProductFamily",
        val,
      )
    },
    getTagPrint(val) {
      return this.$store.getters["defs/getOptionValueByKey"]("TagPrint", val)
    },
    handleMoveOrder() {
      this.$store.dispatch(
        "customer/setEstimateNo",
        this.details[this.orderVal].no,
      )
      this.$router.push({ path: `/customer/dealings/${this.id}/order` })
    },
    handleMoveProof() {
      this.$store.dispatch(
        "customer/setEstimateNo",
        this.details[this.orderVal].no,
      )
      this.$router.push({ path: `/customer/dealings/${this.id}/proof` })
    },
  },
}
</script>

<style lang="scss" module>
@include clientPage();

.wrapper {
  padding-bottom: 30px;
  max-width: 1200px;
}

/* autoprefixer: off */
.contracts {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
}

.explain {
  padding: 0 10px;
  margin: 20px 0 10px;
  color: map-get($colors, red);
}

.quotation {
  width: 320px;
  margin: 10px;
  margin-bottom: 50px;
  &_detail {
    padding: 0 5px;
    &_row {
      padding: 5px 0;
      display: flex;
      flex-wrap: wrap;
      &_name {
        margin-right: 10px;
        font-weight: bold;
        color: map-get($colors, darkGlay);
      }
      &_note {
        white-space: pre-wrap;
      }
    }
    &_divider {
      margin: 15px 0;
      border-top: 1px dotted map-get($colors, darkGlay);
    }
  }
  &_productionPeriod {
    width: 100%;
    margin-bottom: 10px;
    padding: 3px 0;
    text-align: left;
    font-size: 0.14rem;
    background-color: map-get($colors, lightlightGray);
    > span {
      color: map-get($colors, red);
    }
  }
  > label > span:last-child {
    font-size: 0.2rem;
    font-weight: bold;
  }
}

.bottomAria {
  width: 100%;
  padding: 10px;
}

.proof {
  margin-top: 30px;
  padding: 15px;
  background-color: map-get($colors, pink);
  &_txt {
    margin-bottom: 20px;
    font-size: 0.14rem;
    color: map-get($colors, darkGlay);
  }
}

.button {
  text-align: center;
}
</style>
