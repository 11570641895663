<template>
  <div v-if="!$route.meta.noHeader" :class="[$style.wrapper]">
    <router-link to="/">
      <Logo style="vertical-align: bottom;" :class="$style.Logo" />
    </router-link>
    <div :class="$style.contact" v-if="!isLoggedIn || isCustomer">
      <p><v-icon color="#ef9013">phone</v-icon><span>092-409-6039</span></p>
      <a href="https://www.pr39.com/contact/" target="_blank">
        <v-icon color="#fff" size="18px">mail_outline</v-icon
        ><span>info@pr39.com</span>
      </a>
    </div>
    <div v-if="!$route.meta.headerError">
      <div :class="$style.leftBlock" v-if="isLoggedIn && isPR39">
        <div @click="toggleSlideMenuShow" :class="$style.open" />
      </div>
      <div v-else-if="isLoggedIn">
        <a :class="$style.anker" @click="handleLogout" v-cloak>
          <v-icon color="indigo" large>exit_to_app</v-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// comitのため
import Logo from "@/components/atoms/Logo.vue"

export default {
  components: {
    Logo,
  },
  data() {
    return {
      isDealMenuShow: false,
    }
  },
  props: {
    toggleSlideMenuShow: {
      type: Function,
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.token != null
    },
    isPR39() {
      if (
        this.$store.state.auth.role === "admin" ||
        this.$store.state.auth.role === "operator"
      ) {
        return true
      } else {
        return false
      }
    },
    isCustomer() {
      return this.$store.state.auth.role === "customer"
    },
    logoutString() {
      return this.$store.state.auth.role === "supplier"
        ? "로그 아웃"
        : "ログアウト"
    },
  },
  methods: {
    handleDealButtonClick() {
      this.isDealMenuShow = !this.isDealMenuShow
    },
    async handleLogout() {
      let result = await this.$store.dispatch("auth/logout")

      if (!result) {
        console.log(`auth/logout result = ${result}`)
      }

      this.$router.push({ path: "/login" })
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  @include media(sp) {
    padding: 15px;
  }
  @include media(print) {
    display: none;
  }
  &.hidden {
    display: none;
  }
}

.leftBlock {
  display: flex;
  align-items: center;
  /* @include media(sp) {
     display: none;
     } */
}

.deals {
  width: 240px;
  height: 44px;
  margin-right: 30px;
  padding-left: 20px;
  line-height: 44px;
  border-radius: 4px;
  border: 1px solid map-get($colors, glay);
  cursor: pointer;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 17px;
    right: 20px;
    border: 7.5px solid transparent;
    border-top-color: map-get($colors, darkGlay);
  }
}

.menu {
  width: 225px;
  border: 1px solid map-get($colors, darkGlay);
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: map-get($colors, white);
  &::before {
    content: "";
    position: absolute;
    top: -20px;
    right: 16px;
    border: 10px solid transparent;
    border-bottom-color: map-get($colors, darkGlay);
  }
  &::after {
    content: "";
    position: absolute;
    top: -16px;
    right: 18px;
    border: 8px solid transparent;
    border-bottom-color: map-get($colors, white);
  }
}

.menuItem {
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: map-get($colors, yellow);
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.open {
  width: 30px;
  height: 28px;
  border-top: 4px solid map-get($colors, black);
  border-bottom: 4px solid map-get($colors, black);
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: map-get($colors, black);
  }
}

.Logo {
  vertical-align: bottom;
  @media screen and (max-width: 425px) {
    width: 120px !important;
  }
}

.anker {
  color: #016cca;
  cursor: pointer;
  font-size: 0.16rem;
  position: relative;
  text-decoration: none;
}

.contact {
  margin: 0 20px 0 auto;
  @include media(sp) {
    margin-right: 10px;
  }
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    font-size: 20px;
    font-weight: bold;
    color: #ef9013;
    @include media(sp) {
      font-size: 15px;
    }
  }
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    padding: 3px 5px;
    border-radius: 3px;
    background: #ef9013;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    @include media(sp) {
      font-size: 12px;
    }
    &:hover {
      opacity: 0.7;
    }
    > span {
      margin-left: 3px;
    }
  }
}
</style>
