import {
  calcBillingAmountPrice,
  calcBillingAmountDiscountPrice,
  calcBillingTaxPrice,
} from "@/helpers/QuotationDetailViewHelper.js"

function getProductName(
  name,
  family,
  color,
  size,
  jan_code,
  coverSize,
  getOptionValueByKey,
) {
  // マイクロファイバーの場合、カバーサイズを利用する
  const displaySize = jan_code === "1" ? coverSize : size + "枚"
  let data =
    name +
    " " +
    getOptionValueByKey("ProductFamily", family) +
    " " +
    getOptionValueByKey("TagPrint", color) +
    " " +
    displaySize

  return data
}

export function parseContract(contract, getOptionValueByKey) {
  const category2_Table = {
    proofreading: "色校正代金",
    design_fee: "デザイン料",
    mount_fee: "MF台紙代",
    type_fee: "型代",
    shipping_fee: "その他送料",
    other_fee: "その他代金",
    discount: "値引き",
  }

  let index = 1
  let discountPrice = null
  let companyDiscount = 0 // 企業値引き
  let subTotalPrice = 0 // 小計
  let procutTotalDiscount = 0 // 商品金額(企業値引き後)
  let taxTotalPrice = 0 // 消費税合計
  let totalPrice = 0 // 税込金額
  let taxRate = contract.contract.contract_details[0].tax.tax_rate

  // 商品
  const detailsProduct = contract.contract.contract_details.reduce(
    (prev, curr) => {
      if (curr.category2 == "ready_made" || curr.category2 == "order_made") {
        const amountPrice = calcBillingAmountPrice(curr.price, curr.amount)
        const taxPrice = calcBillingTaxPrice(
          curr.price,
          curr.amount,
          curr.thumbnail,
          curr.tax.tax_rate,
        )

        const name = getProductName(
          curr.name,
          curr.category1,
          curr.color,
          curr.size3,
          curr.jan_code,
          curr.size1,
          getOptionValueByKey,
        )

        prev.push({
          index: index++,
          name: name,
          amount: curr.amount,
          price: curr.price,
          amountPrice: amountPrice,
          taxPrice: taxPrice,
        })

        procutTotalDiscount += calcBillingAmountDiscountPrice(
          curr.price,
          curr.amount,
          curr.thumbnail,
        )

        taxTotalPrice += taxPrice
        subTotalPrice += amountPrice
      }
      return prev
    },
    [],
  )

  companyDiscount = subTotalPrice - procutTotalDiscount

  // その他諸費用
  const detailsOther = contract.contract.contract_details.reduce(
    (prev, curr) => {
      if (curr.category2 in category2_Table) {
        const amountPrice = calcBillingAmountPrice(curr.price, curr.amount)
        const taxPrice = calcBillingTaxPrice(
          curr.price,
          curr.amount,
          curr.thumbnail,
          curr.tax.tax_rate,
        )

        if (amountPrice !== 0) {
          if (curr.category2 == "discount") {
            if (curr.category3 == "old") {
              // 旧ロジックの場合は、税込み金額から値引きする
              discountPrice = amountPrice
            } else {
              // 新ロジックの場合は、値引き金額を項目に表示する
              prev.push({
                index: index++,
                name: category2_Table[curr.category2],
                amount: curr.amount,
                price: -1 * curr.price,
                amountPrice: -1 * amountPrice,
                taxPrice: taxPrice,
              })

              taxTotalPrice -= taxPrice
              subTotalPrice -= amountPrice
            }
          } else {
            prev.push({
              index: index++,
              name: category2_Table[curr.category2],
              amount: curr.amount,
              price: curr.price,
              amountPrice: amountPrice,
              taxPrice: taxPrice,
            })

            taxTotalPrice += taxPrice
            subTotalPrice += amountPrice
          }
        }
      }
      return prev
    },
    [],
  )
  if (discountPrice !== null) {
    subTotalPrice -= discountPrice
  }

  if (companyDiscount !== 0) {
    detailsOther.push({
      index: index++,
      name: "御社特別値引き",
      amount: 1,
      price: -1 * companyDiscount,
      amountPrice: -1 * companyDiscount,
      taxPrice: 0,
    })

    subTotalPrice -= companyDiscount
  }

  // 行数が少ない場合、表に余白行を作る
  let detailsReserved = []
  for (let i = 0; i < 11 - (index - 1); i++) {
    detailsReserved.push({})
  }

  // 税込金額 = 小計 + 消費税
  totalPrice = subTotalPrice + taxTotalPrice

  return {
    detailsProduct,
    detailsOther,
    detailsReserved,
    taxRate,
    taxTotalPrice,
    subTotalPrice,
    discountPrice,
    totalPrice,
  }
}
