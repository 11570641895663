import CustomerIndex from "@/components/organisms/customer/CustomerIndex.vue"
import CustomerDealingIndex from "@/components/organisms/customer/CustomerDealingIndex.vue"
import CustomerHistoryIndex from "@/components/organisms/customer/CustomerHistoryIndex.vue"
import CustomerHistoryDetail from "@/components/organisms/customer/CustomerHistoryDetail.vue"
import CustomerReOrder from "@/components/organisms/customer/CustomerReOrder.vue"
import CustomerContract from "@/components/organisms/customer/CustomerContract.vue"
import CustomerOrder from "@/components/organisms/customer/CustomerOrder.vue"
import CustomerDraftUpload from "@/components/organisms/customer/CustomerDraftUpload.vue"
import CustomerVerificationImage from "@/components/organisms/customer/CustomerVerificationImage.vue"
import CustomerOrderConfirmed from "@/components/organisms/customer/CustomerOrderConfirmed.vue"
import CustomerMailMagazine from "@/components/organisms/customer/CustomerMailMagazine.vue"
import CustomerAccountIndex from "@/components/organisms/customer/CustomerAccountIndex.vue"
import CustomerNewEmail from "@/components/organisms/customer/CustomerNewEmail.vue"
import CustomerUserEdit from "@/components/organisms/customer/CustomerUserEdit.vue"

const meta = {
  role: ["customer"],
}

const routes = [
  {
    path: "/customer",
    name: "customerIndex",
    component: CustomerIndex,
    meta: { ...meta, title: "マイページトップ" },
  },
  {
    path: "/customer/dealings",
    name: "customerDealingIndex",
    component: CustomerDealingIndex,
    meta: { ...meta, title: "ご商談一覧" },
  },
  {
    path: "/customer/dealings/:deal_id/contract",
    name: "customerContract",
    component: CustomerContract,
    meta: { ...meta, title: "見積確認" },
  },
  {
    path: "/customer/dealings/:deal_id/order",
    name: "customerOrder",
    component: CustomerOrder,
    meta: { ...meta, title: "注文" },
  },
  {
    path: "/customer/dealings/:deal_id/proof",
    name: "customerProof",
    component: CustomerOrder,
    meta: { ...meta, title: "色校正依頼" },
  },
  {
    path: "/customer/dealings/:deal_id/order-confirmed",
    name: "customerOrderConfirmed",
    component: CustomerOrderConfirmed,
    meta: { ...meta, title: "内容確認" },
  },

  {
    path: "/customer/dealings/:deal_id/draft-upload",
    name: "customerDraftUpload",
    component: CustomerDraftUpload,
    meta: { ...meta, title: "データ入稿" },
  },
  {
    path: "/customer/dealings/:deal_id/verification-image",
    name: "customerVerificationImage",
    component: CustomerVerificationImage,
    meta: { ...meta, title: "最終確認画像" },
  },

  {
    path: "/customer/ordered-histories",
    name: "customerHistoryIndex",
    component: CustomerHistoryIndex,
    meta: { ...meta, title: "ご注文履歴" },
  },

  {
    path: "/customer/ordered-histories/:deal_id",
    name: "customerHistoryDetail",
    component: CustomerHistoryDetail,
    meta: { ...meta, title: "注文履歴詳細" },
  },
  {
    path: "/customer/ordered-histories/:deal_id/re-order",
    name: "customerReOrder",
    component: CustomerReOrder,
    meta: { ...meta, title: "再注文" },
  },

  {
    path: "/customer/accounts",
    name: "customerAccountIndex",
    component: CustomerAccountIndex,
    meta: { ...meta, title: "アカウント情報" },
  },
  {
    path: "/customer/accounts/mail-magazine",
    name: "customerMailMagazine",
    component: CustomerMailMagazine,
    meta: { ...meta, title: "メールマガジン" },
  },
  {
    path: "/customer/accounts/new-email",
    name: "customerNewEmail",
    component: CustomerNewEmail,
    meta: { ...meta, title: "メールアドレス変更" },
  },

  {
    path: "/customer/accounts/users/:user_id",
    name: "customerUserEdit",
    component: CustomerUserEdit,
    meta: { ...meta, title: "登録情報編集" },
  },
]
export default routes
