<template>
  <div :class="$style.wrapper">
    <PageTitle>{{ title }}</PageTitle>
    <p :class="$style.userName" v-cloak>{{ customerAddr.name }}様</p>
    <InfoBlock block width="320px" margin="0 auto" center>
      <template slot="head">
        お客様（注文者）ご住所
      </template>
      <div :class="$style.formsAria">
        <PrInput
          label="郵便番号"
          name="c-zip"
          v-model="customerAddr.zip"
          :validate="'required|zipcode'"
          :pr-row="[100, 75]"
          placeholder="例) 812-0895"
          requiredIcon
        >
          <ButtonSerchZipCode
            slot="after"
            :zipcode="customerAddr.zip"
            @click="handleSearchCustomerZipCode"
          />
        </PrInput>

        <PrInput
          label="都道府県"
          name="c-region"
          v-model="customerAddr.region"
          :validate="'required'"
          :pr-row="[100, 100]"
          placeholder="例) 福岡県"
          requiredIcon
        />
        <PrInput
          label="市区町村"
          :pr-row="[100, 100]"
          name="c-locality"
          v-model="customerAddr.locality"
          :validate="'required'"
          placeholder="例) 福岡市博多区"
          requiredIcon
        />
        <PrInput
          label="町名・番地"
          :pr-row="[100, 100]"
          name="c-street"
          v-model="customerAddr.street"
          :validate="'required'"
          placeholder="例) 竹下1丁目11-23"
          requiredIcon
        />
        <PrInput
          label="建物名・部屋番号"
          :pr-row="[100, 100]"
          v-model="customerAddr.building"
          placeholder="例) アクアサイドGEN1F"
        />
      </div>
    </InfoBlock>

    <InfoBlock block width="320px" margin="0 auto" center>
      <template slot="head">
        入金・配送・納品
      </template>
      <div :class="$style.formsAria">
        <p :class="$style.formsAria_label">
          お支払い方法
        </p>
        <PrInputRadio
          label="銀行振込"
          name="payment"
          v-model="payment"
          id="cash"
          value="0"
        />
        <PrInputRadio
          label="代引き"
          name="payment"
          v-model="payment"
          id="bank"
          value="1"
        />

        <PrDatePicker
          label="入金予定日"
          :pr-row="[100]"
          v-model="scheduleDate"
          :disabledDates="schedulePicker.disabledDates"
        />
        <PrDatePicker
          label="納品希望日"
          :pr-row="[100]"
          v-model="prefDate"
          :disabledDates="prefPicker.disabledDates"
        />

        <p :class="$style.formsAria_label">受取可能曜日</p>
        <PrCheckbox
          v-for="(day, idx) in ['月', '火', '水', '木', '金', '土', '日・祝']"
          :before-label="day"
          :key="idx"
          v-model="receiveDates[idx]"
        />

        <p :class="$style.formsAria_label">商品お届け先</p>
        <PrInputRadio
          label="注文者と同じ"
          name="destination"
          v-model="destination"
          id="same"
          value="0"
        />
        <PrInputRadio
          label="指定住所へ配送"
          name="destination"
          v-model="destination"
          id="specify"
          value="1"
        />

        <div v-show="isValidDestination" :class="$style.formsAria">
          <PrInput
            label="会社名・団体名"
            :pr-row="[100, 100]"
            name="d-company"
            v-model="destAddr.company"
            placeholder="例) 株式会社PR39"
          />

          <PrInput
            label="部署名"
            :pr-row="[100, 100]"
            name="d-division"
            v-model="destAddr.division"
            placeholder="例) 企画販促課"
          />

          <PrInput
            label="ご担当者氏名(お届け先名)"
            :pr-row="[100, 100]"
            name="d-name"
            v-model="destAddr.name"
            :validate="isValidDestination ? 'required' : ''"
            placeholder="例) 比井有 三九"
            requiredIcon
          />

          <PrInput
            label="郵便番号"
            :pr-row="[100, 75]"
            name="d-zip"
            v-model="destAddr.zip"
            :validate="isValidDestination ? 'required|zipcode' : ''"
            placeholder="例) 812-0895"
            requiredIcon
          >
            <ButtonSerchZipCode
              slot="after"
              :zipcode="destAddr.zip"
              @click="handleSearchDestinationZipCode"
            />
          </PrInput>

          <PrInput
            label="都道府県"
            :pr-row="[100, 100]"
            name="d-region"
            v-model="destAddr.region"
            :validate="isValidDestination ? 'required' : ''"
            placeholder="例) 福岡県"
            requiredIcon
          />

          <PrInput
            label="市区町村"
            :pr-row="[100, 100]"
            name="d-locality"
            v-model="destAddr.locality"
            :validate="isValidDestination ? 'required' : ''"
            placeholder="例) 福岡市博多区"
            requiredIcon
          />

          <PrInput
            label="町名・番地"
            :pr-row="[100, 100]"
            name="d-street"
            v-model="destAddr.street"
            :validate="isValidDestination ? 'required' : ''"
            placeholder="例) 竹下1丁目11-23"
            requiredIcon
          />

          <PrInput
            label="建物名・部屋番号"
            :pr-row="[100, 100]"
            v-model="destAddr.building"
            placeholder="例) アクアサイドGEN1F"
          />

          <PrInput
            label="電話番号"
            :pr-row="[100, 100]"
            name="d-tel"
            v-model="destAddr.tel"
            :validate="isValidDestination ? 'required|telephone' : ''"
            placeholder="例) 092-409-6039"
            requiredIcon
          />
        </div>

        <p :class="$style.formsAria_label">送り主変更オプション</p>
        <PrCheckbox
          after-label="変更する"
          :value="isSenderChange ? '1' : '0'"
          @input="isSenderChange = $event == '1' ? true : false"
        />

        <p :class="$style.explain">
          送り状に表示する送り主を、弊社からご注文者または任意の情報に変更することができます。変更された場合、弊社の領収証や広告物など一切同封いたしません。ご安心のうえ、クライアント様に直接発送が可能です。
        </p>

        <p :class="$style.formsAria_label">
          送り主 <span v-show="!isSenderChange">PR39</span>
        </p>

        <div v-show="isSenderChange">
          <PrInputRadio
            label="注文者"
            name="sender"
            v-model="sender"
            id="sender"
            value="0"
          />
          <PrInputRadio
            label="下記の情報"
            name="sender"
            v-model="sender"
            id="other"
            value="1"
          />

          <div v-show="isValidSender" :class="$style.formsAria">
            <PrInput
              label="会社名・団体名"
              :pr-row="[100, 100]"
              name="s-company"
              v-model="senderAddr.company"
              placeholder="例) 株式会社PR39"
            />

            <PrInput
              label="部署名"
              :pr-row="[100, 100]"
              name="s-division"
              v-model="senderAddr.division"
              placeholder="例) 企画販促課"
            />

            <PrInput
              label="ご担当者氏名(送り主名)"
              :pr-row="[100, 100]"
              name="s-name"
              v-model="senderAddr.name"
              :validate="isValidSender ? 'required' : ''"
              placeholder="例) 比井有 三九"
              requiredIcon
            />

            <PrInput
              label="郵便番号"
              :pr-row="[100, 75]"
              name="s-zip"
              v-model="senderAddr.zip"
              :validate="isValidSender ? 'required|zipcode' : ''"
              placeholder="例) 812-0895"
              requiredIcon
            >
              <ButtonSerchZipCode
                slot="after"
                :zipcode="senderAddr.zip"
                @click="handleSearchSenderZipCode"
              />
            </PrInput>

            <PrInput
              label="都道府県"
              :pr-row="[100, 100]"
              name="s-region"
              v-model="senderAddr.region"
              :validate="isValidSender ? 'required' : ''"
              placeholder="例) 福岡県"
              requiredIcon
            />

            <PrInput
              label="市区町村"
              :pr-row="[100, 100]"
              name="s-locality"
              v-model="senderAddr.locality"
              :validate="isValidSender ? 'required' : ''"
              placeholder="例) 福岡市博多区"
              requiredIcon
            />

            <PrInput
              label="町名・番地"
              :pr-row="[100, 100]"
              name="s-street"
              v-model="senderAddr.street"
              :validate="isValidSender ? 'required' : ''"
              placeholder="例) 竹下1丁目11-23"
              requiredIcon
            />

            <PrInput
              label="建物名・部屋番号"
              :pr-row="[100, 100]"
              v-model="senderAddr.building"
              placeholder="例) アクアサイドGEN1F"
            />

            <PrInput
              label="電話番号"
              :pr-row="[100, 100]"
              name="s-tel"
              v-model="senderAddr.tel"
              :validate="isValidSender ? 'required|telephone' : ''"
              placeholder="例) 092-409-6039"
              requiredIcon
            />
          </div>
        </div>
      </div>
    </InfoBlock>

    <InfoBlock block width="320px" margin="0 auto" center>
      <template slot="head">
        その他
      </template>
      <div :class="$style.formsAria">
        <p :class="$style.formsAria_label">備考</p>
        <p :class="[$style.explain, $style.dark]">
          その他、ご注文に関してご希望などありましたら こちらにご入力ください。
        </p>
        <PrTextarea :pr-row="[0, 100]" v-model="remarks" />

        <p :class="[$style.explain, $style.dark]">
          <a href="https://www.pr39.com/terms/" target="_blank"
            >「PR39」利用規約</a
          >の内容をご確認のうえ、 「同意する」チェックボックスにチェックを入れ
          てください。
        </p>

        <p style="text-align:center;">
          <PrCheckbox
            style="justify-content: center;"
            after-label="同意する"
            name="isAgree"
            :validate="'required'"
            :value="isAgree ? '1' : '0'"
            @input="isAgree = $event == '1' ? true : false"
          />
        </p>
        <div :class="$style.button">
          <Button color="submit" size="main" @click="handleNext"
            >確認画面へ進む</Button
          >
        </div>
      </div>
    </InfoBlock>
  </div>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import PrInputRadio from "@/components/atoms/PrInputRadio.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import ButtonSerchZipCode from "@/components/molecules/ButtonSerchZipCode.vue"
import { routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    PageTitle,
    InfoBlock,
    Button,
    PrInput,
    PrInputRadio,
    PrDatePicker,
    PrCheckbox,
    PrTextarea,
    ButtonSerchZipCode,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const id = vm.$route.params.deal_id
      if (vm.$store.state.customer.selectedRequestId != id) {
        routeReplaceError(vm, 900)
        return
      }
      if (vm.$store.state.customer.selectedEstimateNo == null) {
        routeReplaceError(vm, 900)
        return
      }
    })
  },
  data() {
    return {
      unko: [],
      title: "注文画面",
      id: null,
      isProof: false,
      customerAddr: {
        company: "",
        division: "",
        name: "",
        tel: "",
        zip: "",
        region: "",
        locality: "",
        street: "",
        building: "",
      },
      destAddr: {
        company: "",
        division: "",
        name: "",
        tel: "",
        zip: "",
        region: "",
        locality: "",
        street: "",
        building: "",
      },
      senderAddr: {
        company: "",
        division: "",
        name: "",
        tel: "",
        zip: "",
        region: "",
        locality: "",
        street: "",
        building: "",
      },
      schedulePicker: {
        disabledDates: {},
      },
      prefPicker: {
        disabledDates: {},
        openDate: null,
      },
      isSenderChange: false,
      payment: 0,
      destination: 0,
      scheduleDate: null,
      prefDate: null,
      receiveDates: ["0", "0", "0", "0", "0", "0", "0"],
      sender: 0,
      isAgree: false,
      remarks: "",
      specific: 0,
    }
  },
  watch: {
    receiveDates(_new, _old) {
      console.log("new", _new)
      console.log("old", _old)
    },
  },
  created() {
    this.id = this.$route.params.deal_id
    let address = this.$store.getters["customer/getCustomerAddress"]

    this.customerAddr.company = address.company
    this.customerAddr.division = address.division
    this.customerAddr.name = address.name
    this.customerAddr.tel = address.tel
    this.customerAddr.zip = address.zip_code
    this.customerAddr.region = address.location
    this.customerAddr.locality = address.address1
    this.customerAddr.street = address.address2
    this.customerAddr.building = address.building

    let customerInfo = this.$store.getters["customer/getCustomerInfo"]

    let disableSchDt = new Date()
    disableSchDt.setDate(disableSchDt.getDate() - 1)
    this.schedulePicker.disabledDates = {
      to: disableSchDt,
    }

    let disablePrefDt = new Date()
    let days = customerInfo.specific === 1 ? 14 : 21
    disablePrefDt.setDate(disablePrefDt.getDate() + days - 1)
    this.prefPicker.disabledDates = {
      to: disablePrefDt,
    }

    let openDt = new Date()
    openDt.setDate(openDt.getDate() + days)
    this.prefPicker.openDate = openDt

    if (this.$route.name === "customerProof") {
      this.isProof = true
      this.title = "色校正依頼"
    }

    this.restore()
  },
  computed: {
    isValidDestination() {
      return this.destination == 1
    },
    isValidSender() {
      return this.isSenderChange == true && this.sender == 1
    },
  },
  methods: {
    restore() {
      if (this.$store.state.customer.orderInfo !== null) {
        if (this.$store.state.customer.orderInfo.isProof === this.isProof) {
          const orderInfo = JSON.parse(
            JSON.stringify(this.$store.state.customer.orderInfo),
          )
          this.isProof = orderInfo.isProof
          this.customerAddr = orderInfo.customerAddr
          this.payment = orderInfo.payment
          this.scheduleDate = orderInfo.scheduleDate
          this.prefDate = orderInfo.prefDate
          this.receiveDates = orderInfo.receiveDates
          this.destination = orderInfo.destination
          this.destAddr = orderInfo.destAddr
          this.isSenderChange = orderInfo.isSenderChange
          this.sender = orderInfo.sender
          this.senderAddr = orderInfo.senderAddr
          this.remarks = orderInfo.remarks
          this.isAgree = orderInfo.isAgree
        } else {
          this.$store.dispatch("customer/clearOrderInfo")
        }
      }
    },
    handleSearchCustomerZipCode(response) {
      this.handleSearchZipCode(this.customerAddr, "c", response)
    },
    handleSearchDestinationZipCode(response) {
      this.handleSearchZipCode(this.destAddr, "d", response)
    },
    handleSearchSenderZipCode(response) {
      this.handleSearchZipCode(this.senderAddr, "s", response)
    },
    handleSearchZipCode(data, who, { ok, address }) {
      if (!ok) {
        return
      }
      data.region = address.location
      data.locality = address.address1
      data.street = address.address2

      for (const { name, scope } of [
        `${who}-region`,
        `${who}-locality`,
        `${who}-street`,
      ].map(key => this.$validator.fields.find({ name: key }))) {
        this.errors.remove(name, scope)
      }
    },
    removeValidError(option) {
      const field = this.$validator.fields.find({ name: option })
      field.reset()
      this.errors.remove(field.name, field.scope)
    },
    async handleNext() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      if (!this.isValidDestination) {
        this.removeValidError("d-name")
        this.removeValidError("d-zip")
        this.removeValidError("d-region")
        this.removeValidError("d-locality")
        this.removeValidError("d-street")
        this.removeValidError("d-tel")
      }

      if (!this.isValidSender) {
        this.removeValidError("s-name")
        this.removeValidError("s-zip")
        this.removeValidError("s-region")
        this.removeValidError("s-locality")
        this.removeValidError("s-street")
        this.removeValidError("s-tel")
      }

      if (result) {
        let orderInfo = {
          isProof: this.isProof,
          customerAddr: this.customerAddr,
          payment: this.payment,
          scheduleDate: this.scheduleDate,
          prefDate: this.prefDate,
          receiveDates: this.receiveDates,
          destination: this.destination,
          destAddr: this.destAddr,
          isSenderChange: this.isSenderChange,
          sender: this.sender,
          senderAddr: this.senderAddr,
          remarks: this.remarks,
          isAgree: this.isAgree,
        }
        this.$store.dispatch("customer/setOrderInfo", orderInfo)
        this.$router.push({
          path: `/customer/dealings/${this.id}/order-confirmed`,
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
@include clientPage();

.wrapper {
  margin-bottom: 30px;
}

.formsAria {
  width: 100%;
  *[name^="pr-"] {
    margin-bottom: 15px;
  }
  div[name^="pr-checkbox"],
  label[name^="pr-radio"] {
    margin-right: 10px;
    > p {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  &_label {
    margin-bottom: 0.2em;
    color: #707070;
    > span {
      display: inline-block;
      margin-left: 15px;
      color: map-get($colors, black);
    }
  }
}

.button {
  text-align: center;
}
</style>
