var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items !== null
    ? _c("div", { class: _vm.$style.wrapper }, [
        _c(
          "section",
          { class: _vm.$style.section },
          [
            _c("DealingHeader", {
              attrs: { deal: _vm.items, users: _vm.users },
              on: { updated: _vm.handleUpdate },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "section",
          { class: _vm.$style.section },
          [
            _c("DealingPhase", {
              attrs: { items: _vm.items },
              on: { updated: _vm.handleUpdate },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { class: _vm.$style.flex }, [
          _c("div", { class: _vm.$style.flex_left }, [
            _c(
              "section",
              { class: _vm.$style.section },
              [
                _c("DealingContract", {
                  attrs: { items: _vm.items },
                  on: { updated: _vm.handleUpdate },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { class: _vm.$style.section },
              [_c("DealingDelivery", { attrs: { items: _vm.items } })],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { class: _vm.$style.section },
              [
                _c("DealingContactSupplier", {
                  attrs: { items: _vm.items },
                  on: { updated: _vm.handleUpdate },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { class: _vm.$style.section },
              [
                _c("DealingContactCustomer", {
                  attrs: { items: _vm.items },
                  on: { updated: _vm.handleUpdate },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { class: _vm.$style.section },
              [
                _c("DealingInquiry", {
                  attrs: { items: _vm.items },
                  on: { updated: _vm.handleUpdate },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { class: _vm.$style.section },
              [
                _c("DealingSample", {
                  attrs: { items: _vm.items },
                  on: { updated: _vm.handleUpdate },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { class: _vm.$style.section },
              [
                _c("DealingOther", {
                  attrs: { items: _vm.items },
                  on: { updated: _vm.handleUpdate },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.flex_right }, [
            _c(
              "section",
              { class: _vm.$style.history },
              [_c("DealingHistory", { attrs: { items: _vm.items } })],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.flex }, [
          _c(
            "section",
            { class: _vm.$style.section },
            [
              _c("DealingDefect", {
                attrs: { items: _vm.items },
                on: { updated: _vm.handleUpdate },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            { class: _vm.$style.section },
            [
              _c("DealingCustomer", {
                attrs: { items: _vm.items },
                on: { updated: _vm.handleUpdate },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            { class: _vm.$style.section },
            [_c("DealingSupplier", { attrs: { items: _vm.items } })],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }