var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    {
      class: _vm.$style.InfoBlock,
      attrs: { block: "", width: "1600px", margin: "0" },
    },
    [
      _c("template", { slot: "head" }, [
        _vm._v("オーダーメイド見積" + _vm._s(_vm.isNew ? "新規作成" : "編集")),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("h3", [_vm._v("商品情報")]),
          _vm._v(" "),
          _c("table", { class: _vm.$style.table }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "19%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "16%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "13%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "16%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "13%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "13%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "5%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "5%" } }),
            ]),
            _vm._v(" "),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("商品ファミリ")]),
                _vm._v(" "),
                _c("th", [_vm._v("カバー・台紙サイズ")]),
                _vm._v(" "),
                _c("th", [_vm._v("カバー・台紙型抜き")]),
                _vm._v(" "),
                _c("th", [_vm._v("付箋サイズ")]),
                _vm._v(" "),
                _c("th", [_vm._v("付箋型抜き")]),
                _vm._v(" "),
                _c("th", [_vm._v("付箋印刷")]),
                _vm._v(" "),
                _c("th", [_vm._v("付箋枚数")]),
                _vm._v(" "),
                _c("th", [_vm._v("個数")]),
              ]),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("PrSelect", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: {
                        options: _vm.$store.getters["defs/getOptions"](
                          "ProductFamily",
                          "JP"
                        ),
                        name: "category1",
                        validate: "required",
                        "pr-row": [100],
                      },
                      model: {
                        value: _vm.values.category1,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "category1", $$v)
                        },
                        expression: "values.category1",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrInput", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: { "pr-row": [0, 100] },
                      model: {
                        value: _vm.values.size1,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "size1", $$v)
                        },
                        expression: "values.size1",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrSelect", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: {
                        options: _vm.$store.getters["defs/getOptions"](
                          "Cuttings",
                          "JP"
                        ),
                        name: "category3",
                        "pr-row": [100],
                      },
                      model: {
                        value: _vm.values.category3,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "category3", $$v)
                        },
                        expression: "values.category3",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrInput", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: {
                        name: "size2",
                        validate: "required",
                        "pr-row": [0, 100],
                      },
                      model: {
                        value: _vm.values.size2,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "size2", $$v)
                        },
                        expression: "values.size2",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrSelect", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: {
                        name: "category4",
                        options: _vm.$store.getters["defs/getOptions"](
                          "Cuttings",
                          "JP"
                        ),
                        validate: "required",
                        "pr-row": [100],
                      },
                      model: {
                        value: _vm.values.category4,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "category4", $$v)
                        },
                        expression: "values.category4",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrSelect", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: {
                        name: "color",
                        options: _vm.$store.getters["defs/getOptions"](
                          "TagPrint",
                          "JP"
                        ),
                        validate: "required",
                        "pr-row": [100],
                      },
                      model: {
                        value: _vm.values.color,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "color", $$v)
                        },
                        expression: "values.color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrInput", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: {
                        validate: "required|numeric",
                        name: "size3",
                        "pr-row": [0, 100],
                      },
                      model: {
                        value: _vm.values.size3,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "size3", $$v)
                        },
                        expression: "values.size3",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrInput", {
                      staticStyle: { width: "100%", padding: "0 10px" },
                      attrs: {
                        validate: "required|numeric",
                        name: "amount",
                        "pr-row": [0, 100],
                      },
                      model: {
                        value: _vm.values.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "amount", $$v)
                        },
                        expression: "values.amount",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("価格情報")]),
          _vm._v(" "),
          _c("table", { class: _vm.$style.table }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("仕入額（₩）")]),
                _vm._v(" "),
                _c("th", [_vm._v("輸送額")]),
                _vm._v(" "),
                _c("th", [_vm._v("原価")]),
                _vm._v(" "),
                _c("th", [_vm._v("掛率")]),
                _vm._v(" "),
                _c("th", [_vm._v("単価")]),
                _vm._v(" "),
                _c("th", [_vm._v("小計")]),
                _vm._v(" "),
                _c("th", [_vm._v("粗利額")]),
              ]),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("PrInput", {
                      attrs: {
                        name: "purchase_amount",
                        "pr-row": [0, 100],
                        validate: "numeric",
                      },
                      model: {
                        value: _vm.values.purchase_amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "purchase_amount", $$v)
                        },
                        expression: "values.purchase_amount",
                      },
                    }),
                    _c("br"),
                    _vm._v(" "),
                    _vm.isPurchaseAmountValide
                      ? _c("PrErrorText", { staticStyle: { width: "155px" } }, [
                          _vm._v("仕入額を入力してください"),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrInput", {
                      attrs: {
                        name: "merchandise_cost",
                        validate: "decimal:1",
                        "pr-row": [0, 100],
                      },
                      model: {
                        value: _vm.values.merchandise_cost,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "merchandise_cost", $$v)
                        },
                        expression: "values.merchandise_cost",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm._f("calcCost")(_vm.values)))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PrInput", {
                      attrs: {
                        name: "weight1",
                        validate: "decimal:3",
                        "pr-row": [0, 100],
                      },
                      model: {
                        value: _vm.values.weight1,
                        callback: function ($$v) {
                          _vm.$set(_vm.values, "weight1", $$v)
                        },
                        expression: "values.weight1",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    !_vm.values.weight1 || _vm.values.weight1 === "0"
                      ? _c("PrInput", {
                          attrs: {
                            name: "price",
                            validate: "required|decimal:3",
                            "pr-row": [0, 100],
                          },
                          model: {
                            value: _vm.values.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.values, "price", $$v)
                            },
                            expression: "values.price",
                          },
                        })
                      : [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm._f("calcOrderUnitPrice")(_vm.values)) +
                              "\n            "
                          ),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("calcOrderTotalPrice")(_vm.values))),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("calcGrossProfit")(_vm.values))),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.column }, [
            _c(
              "div",
              [
                _c("PrTextarea", {
                  attrs: { label: "備考", "pr-row": [100, 100] },
                  model: {
                    value: _vm.values.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.values, "description", $$v)
                    },
                    expression: "values.description",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("h3", [_vm._v("参考画像")]),
                _vm._v(" "),
                _vm.values.image_url
                  ? _c(
                      "div",
                      { class: _vm.$style.img },
                      [
                        _c("PrImg", { attrs: { src: _vm.values.image_url } }),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "Button",
                          {
                            attrs: { size: "assist", color: "cancel" },
                            on: {
                              click: function ($event) {
                                _vm.values.image_url = ""
                              },
                            },
                          },
                          [_vm._v("削除")]
                        ),
                      ],
                      1
                    )
                  : _c("PrImgInput", {
                      model: {
                        value: _vm.file,
                        callback: function ($$v) {
                          _vm.file = $$v
                        },
                        expression: "file",
                      },
                    }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._t("buttons", null, null, {
            handleAddButtonClick: _vm.handleAddButtonClick,
            init: _vm.init,
            resetValidator: _vm.resetValidator,
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }