<template>
  <div :class="$style.wrapper">
    <h1 :class="$style.title">パスワード設定</h1>
    <div>
      <p :class="$style.message_error" v-if="validMessage" v-cloak>
        {{ validMessage }}
      </p>
      <form :class="$style.formAria" @submit.prevent="handlePassChange">
        <p :class="$style.desc" v-cloak>
          設定したいパスワードを入力してください。
        </p>
        <InputForm
          name="password"
          :class="$style.form"
          type="password"
          v-model="password"
          v-validate="'required|min:8|max:20|password'"
          ref="password"
          >パスワード</InputForm
        >
        <InputForm
          name="repassword"
          :class="$style.form"
          type="password"
          v-model="repassword"
          v-validate="'required|min:8|max:20|confirmed:password'"
          data-vv-as="password"
          >パスワード(確認用)</InputForm
        >
        <Button type="submit" color="submit" size="main">設定する</Button>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import InputForm from "@/components/molecules/InputForm.vue"
import api, { paths } from "@/utils/api.js"
import { showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    Button,
    InputForm,
  },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      password: "",
      repassword: "",
      validMessage: null,
    }
  },
  methods: {
    setValidMessage(message) {
      this.validMessage = message
    },
    validate() {
      return this.$validator.validateAll().then(result => {
        if (!result) {
          if (this.errors.firstByRule("password", "required")) {
            this.setValidMessage("パスワードを入力してください。")
            return false
          }
          if (
            this.errors.firstByRule("password", "min") ||
            this.errors.firstByRule("password", "max")
          ) {
            this.setValidMessage(
              "パスワードは8文字以上20文字以下で入力してください。",
            )
            return false
          }
          if (this.errors.firstByRule("repassword", "required")) {
            this.setValidMessage("パスワード(確認用)を入力してください。")
            return false
          }
          if (
            this.errors.firstByRule("repassword", "min") ||
            this.errors.firstByRule("repassword", "max")
          ) {
            this.setValidMessage(
              "パスワード(確認用)は8文字以上20文字以下で入力してください。",
            )

            return false
          }
          if (this.errors.firstByRule("repassword", "confirmed")) {
            this.setValidMessage(
              "パスワードとパスワード(確認用)が一致していません。",
            )

            return false
          }

          return false
        } else {
          this.setValidMessage(null)
          return true
        }
      })
    },
    async handlePassChange() {
      let result = await this.validate()
      if (!result) {
        return
      }

      const loader = this.$loading.show()

      try {
        // ユーザー登録する(パスワード設定)
        const { ok, body, errorMsg } = await api.post(
          paths.USER_REGISTRATION,
          {
            name: "-",
            role: "customer",
            password: this.password,
          },
          this.token,
        )

        if (!ok) {
          showErrorToast(
            this,
            body.slice(-3),
            errorMsg,
            "パスワードの設定に失敗しました。",
          )
          return
        }

        this.$router.replace({ path: "/password/new/done" })
      } finally {
        loader.hide()
      }
    },
  },
}
</script>
<style lang="scss" module>
@include displayMessage();

.wrapper {
  min-height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  padding: 55px 0;
}

.formAria {
  width: 800px;
  margin: 0 auto;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  & > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form {
  width: 465px;
}

.desc {
  font-size: 0.18rem;
  color: #707070;
}
</style>
