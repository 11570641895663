var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { opacity: "1" },
      attrs: {
        version: "1.1",
        id: "_x32_",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st0",
          staticStyle: { fill: "rgb(75, 75, 75)" },
          attrs: {
            d: "M255.988,282.537c78.002,0,141.224-63.221,141.224-141.213c0-77.982-63.222-141.213-141.224-141.213\n\t       c-77.99,0-141.203,63.23-141.203,141.213C114.785,219.316,177.998,282.537,255.988,282.537z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "st0",
          staticStyle: { fill: "rgb(75, 75, 75)" },
          attrs: {
            d: "M503.748,468.222C473.826,376.236,364.008,326.139,256,326.139c-108.02,0-217.828,50.098-247.75,142.084\n\t       c-4.805,14.74-7.428,29.387-8.25,43.666h512C511.166,497.609,508.553,482.963,503.748,468.222z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }