var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "wrapper", class: _vm.$style.wrapper, style: _vm.styleObj },
    [
      _c("div", { class: _vm.$style.head }, [
        _c("p", [_vm._v(_vm._s(_vm.deal.request_name))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.deal.customer.organization.name))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.forms },
        [
          _c("PrInput", {
            attrs: {
              label: "出庫箱数",
              "pr-row": [50, 50],
              name: "product.quantity3",
              validate: "required|numeric",
            },
            model: {
              value: _vm.values.product.quantity3,
              callback: function ($$v) {
                _vm.$set(_vm.values.product, "quantity3", $$v)
              },
              expression: "values.product.quantity3",
            },
          }),
          _vm._v(" "),
          _c("PrDatePicker", {
            attrs: { label: "港受取日", "pr-row": [50, 50] },
            model: {
              value: _vm.values.product.request_date2,
              callback: function ($$v) {
                _vm.$set(_vm.values.product, "request_date2", $$v)
              },
              expression: "values.product.request_date2",
            },
          }),
          _vm._v(" "),
          _c("PrInput", {
            attrs: {
              label: "出庫商品総数",
              "pr-row": [50, 50],
              name: "product.quantity4",
              validate: "required|numeric",
            },
            model: {
              value: _vm.values.product.quantity4,
              callback: function ($$v) {
                _vm.$set(_vm.values.product, "quantity4", $$v)
              },
              expression: "values.product.quantity4",
            },
          }),
          _vm._v(" "),
          _c("PrInput", {
            attrs: {
              label: "保管商品個数",
              "pr-row": [50, 50],
              name: "product.quantity6",
              validate: "required|numeric",
            },
            model: {
              value: _vm.values.product.quantity6,
              callback: function ($$v) {
                _vm.$set(_vm.values.product, "quantity6", $$v)
              },
              expression: "values.product.quantity6",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.sendGoodsAmount },
            [
              _c("p", [_vm._v("発送商品総数")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("checkNaN")(
                        _vm.values.product.quantity4 -
                          _vm.values.product.quantity6
                      )
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              !_vm.isAmountValide
                ? _c("PrErrorText", [
                    _vm._v("発送商品総数が0以上となるようにしてください。"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("PrInput", {
            attrs: {
              label: "発送箱数",
              "pr-row": [50, 50],
              name: "product.quantity5",
              validate: "required|numeric",
            },
            model: {
              value: _vm.values.product.quantity5,
              callback: function ($$v) {
                _vm.$set(_vm.values.product, "quantity5", $$v)
              },
              expression: "values.product.quantity5",
            },
          }),
          _vm._v(" "),
          _c("PrDatePicker", {
            attrs: { label: "発送予定日", "pr-row": [50, 50] },
            model: {
              value: _vm.values.delivery.request_date2,
              callback: function ($$v) {
                _vm.$set(_vm.values.delivery, "request_date2", $$v)
              },
              expression: "values.delivery.request_date2",
            },
          }),
          _vm._v(" "),
          _c("PrDatePicker", {
            attrs: { label: "お届け予定日", "pr-row": [50, 50] },
            model: {
              value: _vm.values.delivery.request_date3,
              callback: function ($$v) {
                _vm.$set(_vm.values.delivery, "request_date3", $$v)
              },
              expression: "values.delivery.request_date3",
            },
          }),
          _vm._v(" "),
          _c("PrTextarea", {
            attrs: { label: "備考", "pr-row": [50, 50] },
            model: {
              value: _vm.values.product.note2,
              callback: function ($$v) {
                _vm.$set(_vm.values.product, "note2", $$v)
              },
              expression: "values.product.note2",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.buttons },
        [
          _c(
            "Button",
            {
              attrs: { size: "main", color: "submit" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: { size: "assist", color: "cancel" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("キャンセル")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }