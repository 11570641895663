import * as types from "@/store/mutation-types"

const state = {
  password: {
    reminder: {
      email: null,
    },
  },
}

const getters = {}

const actions = {
  setPasswordReminderEmail: ({ commit }, payload) => {
    commit(types.SET_PASSWORD_REMINDER_EMAIL, payload)
  },
  clearPasswordReminderEmail: ({ commit }) => {
    commit(types.SET_PASSWORD_REMINDER_EMAIL, null)
  },
}

const mutations = {
  [types.SET_PASSWORD_REMINDER_EMAIL](state, payload) {
    state.password.reminder.email = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
