var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        on: {
          click: () => {
            if (!_vm.noOpen) _vm.toggleIsActive()
          },
        },
      },
      [_vm._t("button", null, { toggleActive: _vm.toggleIsActive })],
      2
    ),
    _vm._v(" "),
    _vm.isActive || _vm.debug !== undefined
      ? _c("div", { class: _vm.$style.container }, [
          _c(
            "div",
            { class: _vm.$style.wrapper },
            [
              _vm.closeButton
                ? _c(
                    "v-btn",
                    {
                      class: _vm.$style.closeButton,
                      attrs: { icon: "", absolute: true },
                      on: { click: _vm.toggleIsActive },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "white" } },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { class: { [_vm.$style.inner]: _vm.scroll } },
                [_vm._t("default", null, { toggle: _vm.toggleIsActive })],
                2
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }