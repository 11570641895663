var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.styles.formAria, staticStyle: { width: "800px" } },
        [
          _c("PrInput", {
            attrs: {
              label: "表示名(名前)",
              name: "name",
              validate: "required",
              "pr-row": [100, 100],
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _vm._v(" "),
          _c("PrInput", {
            attrs: {
              label: "メールアドレス",
              name: "email",
              validate: "required|email",
              "pr-row": [100, 100],
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
          _vm._v(" "),
          _c("PrSelect", {
            attrs: {
              label: "権限",
              name: "role",
              options: _vm.roleOptions,
              validate: "required",
              "pr-row": [100, 100],
              autoWidth: false,
            },
            model: {
              value: _vm.role,
              callback: function ($$v) {
                _vm.role = $$v
              },
              expression: "role",
            },
          }),
          _vm._v(" "),
          _c("PrInput", {
            attrs: {
              label: "パスワード",
              type: "password",
              name: "password",
              validate: "required|min:8|max:20|password",
              "pr-row": [100, 100],
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: { color: "submit", size: "main" },
              on: { click: _vm.handleRegist },
            },
            [_vm._v("登録する")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }