<template>
  <div :class="$style.PrSelect" name="pr-select">
    <span
      v-if="label"
      name="label"
      :class="$style.label"
      :style="styleForLabel"
    >
      {{ label }}
      <router-link v-if="linkPath" :to="`${linkPath}/${value}`"
        ><v-icon>navigate_next</v-icon>{{ linkName }}</router-link
      >
    </span>
    <div :class="$style.select" :style="styleForSelect">
      <label :data-psid="selfId" :class="classForButton">
        <button
          :data-psid="selfId"
          @click="handleButtonClick"
          @keydown.prevent
          @keyup.down="handleKeyupDown"
          @keyup.up="handleKeyupUp"
          @keyup.enter="handleKeyupEnter"
        >
          {{ buttonText }}
        </button>
        <span :data-psid="selfId" />
      </label>
      <div :data-psid="selfId" v-show="isActive" :class="$style.dropDown">
        <label v-if="searchable" :data-psid="selfId" :class="$style.search">
          <v-icon :data-psid="selfId">search</v-icon>
          <input
            type="text"
            :data-psid="selfId"
            :class="$style.search_input"
            @input="handleInputInput"
            @keyup.down="handleKeyupDown"
            @keyup.up="handleKeyupUp"
            @keyup.enter="handleKeyupEnter"
          />
        </label>
        <ul :data-psid="selfId" :class="$style.options" ref="optionElm">
          <li
            v-for="({ key, value }, idx) in filteredOptions"
            :key="key"
            :class="classForOption(key, idx)"
            @click="handleOptionClick(key)"
            ref="optionItemElms"
            :data-key="key"
          >
            {{ value }}
          </li>
        </ul>
      </div>
    </div>
    <template v-if="validate">
      <input v-show="false" :name="name" :value="value" v-validate="validate" />
      <PrErrorText v-show="errors.has(name)" :center="prRow[0] !== null">
        {{ errors.first(name) }}
      </PrErrorText>
    </template>
  </div>
</template>

<script>
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import { getUniqueStr } from "@/utils/shared.js"

export default {
  inject: ["$validator"],
  $_veeValidate: {
    value() {
      return this.value
    },
  },
  components: {
    PrErrorText,
  },
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    label: String,
    name: String,
    validate: String,
    prRow: {
      type: Array,
      default() {
        return [null, null]
      },
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    linkPath: String,
    linkName: String,
  },
  data() {
    return {
      isActive: false,
      selfId: getUniqueStr(),
      keySelectIdx: -1,
      searchValue: "",
    }
  },
  computed: {
    buttonText() {
      const { value, options } = this
      return options.some(({ key }) => key == value)
        ? options.find(({ key }) => key == value).value
        : "未設定"
    },
    filteredOptions() {
      const { searchValue, options } = this
      if (!searchValue) {
        return this.options
      }
      return options.filter(({ value }) => RegExp(searchValue, "i").test(value))
    },
    keyupHandlerVars() {
      const {
        filteredOptions,
        keySelectIdx,
        $refs: { optionElm, optionItemElms },
      } = this
      const {
        bottom: optionElmBtm,
        top: optionElmTop,
      } = optionElm.getBoundingClientRect()
      return {
        filteredOptions,
        keySelectIdx,
        optionElm,
        optionItemElms,
        optionElmBtm,
        optionElmTop,
      }
    },
    styleForLabel() {
      return {
        width: this.prRow[0] + "%",
      }
    },
    styleForSelect() {
      const [lw, sw] = this.prRow
      return {
        width: lw ? (lw === 100 ? "100%" : `${sw || 100 - lw}%`) : "10em",
      }
    },
    classForButton() {
      const {
        isActive,
        $style: { button, active },
      } = this
      return [button, { [active]: isActive }]
    },
  },
  methods: {
    classForOption(key, idx) {
      const {
        value,
        keySelectIdx,
        $style: { options_item, keySelected, selected },
      } = this
      return [
        options_item,
        { [selected]: value == key },
        { [keySelected]: keySelectIdx == idx },
      ]
    },
    handleInputInput(e) {
      this.searchValue = e.target.value
      this.keySelectIdx = -1
    },
    handleKeyupDown() {
      const {
        filteredOptions,
        keySelectIdx,
        optionElm,
        optionItemElms,
        optionElmBtm,
      } = this.keyupHandlerVars

      if (filteredOptions.length <= keySelectIdx + 1) {
        return
      }

      let _keySelectIdx = keySelectIdx
      _keySelectIdx++

      const {
        bottom: optionItemElmBtm,
        height: optionItemElmHgt,
      } = optionItemElms[_keySelectIdx].getBoundingClientRect()

      if (optionElmBtm < optionItemElmBtm) {
        optionElm.scrollTop += optionItemElmHgt
      }

      this.keySelectIdx = _keySelectIdx
    },
    handleKeyupUp() {
      const {
        keySelectIdx,
        optionElm,
        optionItemElms,
        optionElmTop,
      } = this.keyupHandlerVars

      if (0 >= keySelectIdx) {
        return
      }

      let _keySelectIdx = keySelectIdx
      _keySelectIdx--

      const { top, height } = optionItemElms[
        _keySelectIdx
      ].getBoundingClientRect()

      if (optionElmTop > top) {
        optionElm.scrollTop -= height
      }

      this.keySelectIdx = _keySelectIdx
    },
    handleKeyupEnter() {
      const {
        filteredOptions,
        keySelectIdx,
        optionItemElms,
      } = this.keyupHandlerVars

      if (filteredOptions.length < keySelectIdx + 1 || 0 > keySelectIdx) {
        return
      }

      const { key } = optionItemElms[keySelectIdx].dataset
      this.$emit("input", String(key))
      this.isActive = false
    },
    handleOptionClick(key) {
      this.$emit("input", String(key))
    },
    handleButtonClick() {
      const { isActive } = this
      this.isActive = !isActive
    },
    handleBodyClick(e) {
      const { selfId } = this
      const { psid } = e.target.dataset

      if (psid === selfId) {
        return
      }

      this.isActive = false
    },
  },
  watch: {
    value(val) {
      if (
        !this.validate ||
        !this.errors.items.some(({ field }) => field === this.name)
      ) {
        return
      }

      if (val && val !== 0) {
        const field = this.$validator.fields.find({ name: this.name })
        field.reset()
        this.errors.remove(field.name, field.scope)
      }
    },
  },
  mounted() {
    document.body.addEventListener("click", this.handleBodyClick)
  },
  destroyed() {
    document.body.removeEventListener("click", this.handleBodyClick)
  },
}
</script>

<style lang="scss" module>
.PrSelect {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.label {
  margin-bottom: 0.2em;
  color: map-get($colors, darkGlay);
}
.select {
  text-align: left !important;
  position: relative;
}
.button {
  display: flex;
  align-items: center;
  padding: 0.3em 0 0.3em 0.5em;
  border: solid 1px #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;

  transition-timing-function: ease-in-out;
  transition-duration: 0.15s;
  transition-property: border, box-shadow;

  > button {
    outline: none;
    text-align: inherit;
    white-space: nowrap;

    overflow-x: hidden;
    flex-grow: 1;
  }
  > span {
    padding: 0 0.3em;
    display: flex;
    justify-content: center;
    &::after {
      content: "";
      display: block;
      border: 0.4em solid transparent;
      border-top: 0.5em solid gray;
      border-bottom: none;
    }
  }
  &.active {
    border: solid 1px #00836b;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0 3px #00836b;
    > span::after {
      border-top: none;
      border-bottom: 0.5em solid #2c9a7a;
    }
  }
}
.dropDown {
  width: 100%;

  border: solid 1px #bdbdbd;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}
.search {
  display: block;
  padding: 5px 5px 0;
  position: relative;
  > i {
    position: absolute;
    top: 0.4em;
    left: 0.4em;
    cursor: text;
  }
  > input {
    font-size: 0.14rem;
    min-height: 0.32rem;
    width: 100% !important;
    height: auto;
    margin: 0;
    padding: 0 6px 0 34px;
    border: solid 1px #999;
    border-radius: 2px;
    outline: 0;
  }
}
.options {
  padding: 5px;
  list-style: none;
  font-size: 0.13rem;

  max-height: 260px;
  overflow-y: auto;
  > li {
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: #e3eee8;
    }
    &.selected,
    &.keySelected {
      background-color: #e3eee8;
      &:hover {
        background-color: darken(#e3eee8, 10%);
      }
    }
    &.selected.keySelected {
      background-color: darken(#e3eee8, 10%);
    }
  }
}
</style>
