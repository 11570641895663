<template>
  <div :class="$style.wrapper">
    <div :class="$style.content">
      <Anker to="/login">ログイン画面に戻る</Anker>
      <div :class="$style.formAria">
        <p :class="$style.formAria_text" v-cloak>
          <span>{{ message1 }}</span>
          <br />
          {{ message2 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Anker from "@/components/atoms/Anker.vue"

export default {
  components: {
    Anker,
  },
  data() {
    return {
      email: null,
      message1: "",
      message2: "",
    }
  },
  created() {
    // パスワード新規設定
    if (this.$route.name === "userPasswordNewDone") {
      this.message1 = "パスワードが設定されました。"
      this.message2 =
        "ログイン画面より、設定したパスワードでログインしてください。"
      // パスワード変更
    } else {
      this.message1 = "パスワードが変更されました。"
      this.message2 = "ログイン画面より、新規パスワードでログインしてください。"
    }
  },
}
</script>

<style lang="scss" module>
.wrapper {
  min-height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  margin-top: 50px;
}

.title {
  padding: 55px 0;
}

.formAria {
  width: 800px;
  margin: 0 auto;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  &_text {
    padding: 30px;
    > span {
      font-weight: bold;
    }
  }
}
</style>
