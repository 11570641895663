var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "layer",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 27 27",
      },
    },
    [
      _c("g", { attrs: { id: "circle" } }, [
        _c("path", {
          class: _vm.$style.cls_1,
          attrs: {
            d: "M13.5,3A10.5,10.5,0,1,1,3,13.5,10.51,10.51,0,0,1,13.5,3m0-3A13.5,13.5,0,1,0,27,13.5,13.49,13.49,0,0,0,13.5,0Z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { id: "circle-2", "data-name": "circle" } }, [
        _c("circle", {
          class: _vm.$style.cls_1,
          attrs: { cx: "13.5", cy: "19.98", r: "1.81" },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { id: "path" } }, [
        _c("path", {
          class: _vm.$style.cls_1,
          attrs: {
            d: "M15.31,7c0,1-.81,10-1.81,10S11.69,8,11.69,7a1.81,1.81,0,0,1,3.62,0Z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }