<template>
  <div :class="$style.wrapper">
    <label :class="$style.label">
      <slot />
    </label>
    <input
      v-if="type !== 'select'"
      :class="$style.input"
      :type="type"
      :readonly="readonly !== undefined"
      :name="name"
      :value="value"
      @input="updateValue"
      @change="updateValue"
      @blur="$emit('blur')"
    />
    <select
      v-if="type === 'select'"
      :class="$style.input"
      :name="name"
      :value="value"
      @input="updateValue"
      @change="updateValue"
    >
      <option v-for="(v, j) in options" :value="v.key" :key="j">{{
        v.value
      }}</option>
    </select>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    value() {
      return this.value
    },
  },
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
    },
    name: {
      type: String,
    },
    options: {
      type: Array,
    },
    readonly: null,
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value)
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  //font-size: 0.18rem;
  font-weight: bold;
  color: #707070;
}

.label {
  display: block;
  margin-bottom: 10px;
}

.input {
  display: block;
  width: 100%;
  height: 45px;
  padding-left: 1em;
  border: 1px solid #afafaf;
  outline: none;
  &:focus {
    background-color: #e3e3e3;
  }
}

.block {
  display: block;
}
</style>
