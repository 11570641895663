<template>
  <div :class="$style.history">
    <h2 :class="$style.history_head">商談履歴</h2>
    <div :class="$style.history_container">
      <div
        v-for="history in sortedHistory"
        :key="history.id"
        :class="$style.history_item"
      >
        <p :class="$style.history_date">{{ history.inserted_at | getDate }}</p>
        <p :class="$style.history_text">
          {{ history.description }}
          <template v-if="history.inserted.role === 'admin'">
            【{{ history.inserted.name }}】
          </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDate } from "@/utils/shared.js"

export default {
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortedHistory() {
      const history = JSON.parse(JSON.stringify(this.items.history))
      history.sort((a, b) => a.inserted_at - b.inserted_at)
      return history
    },
  },
  filters: {
    getDate,
  },
}
</script>

<style lang="scss" module>
.history {
  width: 100%;
  padding: 10px;
  background-color: map-get($colors, white);
  &_head {
    text-align: center;
    font-size: 0.18rem;
    margin-bottom: 10px;
  }
  &_container {
    max-height: 1770px;
    overflow-y: auto;
  }
  &_item {
    padding: 10px;
    display: flex;
    align-items: center;
    &:nth-of-type(odd) {
      background-color: map-get($colors, lightlightGray);
    }
  }
  &_date {
    margin-right: 10px;
    color: map-get($colors, darkGlay);
  }
}
</style>
