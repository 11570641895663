var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "320px", margin: "0", center: "", block: "" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    その他\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("p", [_vm._v("備考")]),
          _vm._v(" "),
          _c("p", { staticStyle: { "white-space": "pre-wrap" } }, [
            _vm._v(_vm._s(_vm.deal.contract.note1)),
          ]),
          _vm._v(" "),
          _vm.imagePath
            ? [
                _vm.deal.reusable
                  ? [
                      _c("p", [_vm._v("印刷データ")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.deal.reusable === "1"
                              ? "前回と同じ"
                              : "新しく入稿する"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.deal.reusable === "1"
                        ? [
                            _c("p", [_vm._v("最終確認画像")]),
                            _vm._v(" "),
                            _c("span", [
                              _c("img", { attrs: { src: _vm.imagePath } }),
                            ]),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _c("p", [_vm._v("最終確認画像")]),
                      _vm._v(" "),
                      _c("span", [
                        _c("img", { attrs: { src: _vm.imagePath } }),
                      ]),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }