<template>
  <div v-if="isReady" :class="$style.wrapper">
    <SupplierDraftDataModifyModal
      v-if="isDraftModify"
      :values="itemsDealings[selectedIndex]"
      @changed="handleDraftChanged"
    />

    <h1 :class="$style.pageTtl">&nbsp;</h1>

    <div :class="$style.search">
      <div>
        <PrSelect
          v-model="filterValue"
          :options="filterOptions"
          label="진행상황"
          :pr-row="[100]"
        />
      </div>
      <div>
        <PrSelect
          v-model="sortValue.key"
          :options="sortOptions"
          label="정렬"
          :pr-row="[100]"
        />
      </div>
      <v-btn
        icon
        color="#ff6000"
        style="color: white;"
        @click="sortValue.isDesc = !sortValue.isDesc"
      >
        <v-icon v-if="sortValue.isDesc">keyboard_arrow_down </v-icon>
        <v-icon v-else>keyboard_arrow_up </v-icon>
      </v-btn>
    </div>

    <InfoBlock block width="100%" margin="0" center>
      <template slot="head">
        견적/주문일람
      </template>
      <div v-if="itemsDealings.length === 0" :class="$style.nodata">
        상담이 없습니다
      </div>
      <div v-else :class="$style.deals">
        <div
          v-for="item in sortedItems"
          :class="$style.dealWrapper"
          :key="item.index"
        >
          <div :class="$style.deal">
            <div :class="$style.deal_ns">
              <!-- 商談No -->
              <p :class="$style.deal_ns_name" v-cloak>
                상담No.{{ item.request_number }}
              </p>
              <!-- 発注データ名 -->
              <p
                :class="$style.deal_ns_name"
                style="word-break: break-all;"
                v-cloak
              >
                {{ item.order_data_name }}
              </p>
            </div>
            <div :class="$style.deal_dates">
              <!-- 発生日 -->
              <p :class="$style.deal_dates_txt" v-cloak>
                발주일：{{ item.request_date1 | getDate }}
              </p>
              <!-- 出庫予定日 -->
              <p :class="$style.deal_dates_txt" v-cloak>
                출고예정일：{{ item.product.request_date1 | getDate }}
              </p>
              <!-- 発送箱数 -->
              <p :class="$style.deal_dates_txt" v-cloak>
                발송박스수：{{ item.product.quantity5 | shippingBoxNumber }}
              </p>
              <!-- 更新日 -->
              <p :class="$style.deal_dates_txt" v-cloak>
                갱신일：{{ item.updated_at | getDate }}
              </p>
            </div>
            <div :class="$style.deal_action">
              <!-- 見積 -->
              <p :class="$style.deal_action_txt">・견적</p>
              <Button
                width="120px"
                :color="item.estimate.color"
                size="main"
                :disabled="item.estimate.disabled"
                @click="handleQuotation(item.request_number)"
                v-cloak
                >{{ item.estimate.label }}</Button
              >
            </div>
            <div :class="[$style.deal_action, $style.hegiht]">
              <!-- 入校データ -->
              <p :class="$style.deal_action_txt">
                ・업로드데이터
                <!-- DLリンク staus=1(未確認), 2(確認済み)の場合表示 -->
                <a
                  :class="[$style.deal_action_anker, $style.anker]"
                  v-if="
                    (item.draft.status === 1 || item.draft.status === 2) &&
                      item.draft.image_url
                  "
                  :href="item.draft.image_url"
                  >DL</a
                >
              </p>
              <Button
                width="120px"
                :color="item.draft.color"
                size="main"
                :disabled="item.draft.disabled"
                @click="handleDraft(item.index)"
                v-cloak
                >{{ item.draft.label }}</Button
              >
              <!-- データの修正依頼リンク status=1(未確認)の場合表示 -->
              <Anker
                v-if="item.draft.status === 1"
                to
                @click="openDraftModify(item.index)"
                :class="$style.absolete"
                >데이터 수정의뢰</Anker
              >
            </div>

            <div :class="[$style.deal_action, $style.hegiht]">
              <!-- 最終確認画像 -->
              <p :class="$style.deal_action_txt">・최종 확인 이미지</p>
              <Button
                width="120px"
                :color="item.verify.color"
                size="main"
                :disabled="item.verify.disabled"
                @click="handleUploadVerifyImage(item.index)"
                v-cloak
                >{{ item.verify.label }}</Button
              >

              <!-- 登録するの場合は、ファイル参照可能 -->
              <InputFile
                :class="$style.absolete"
                v-if="item.verify.status === 1"
                :file.sync="fileVerify[item.index]"
                :accept-type="['jpeg', 'jpg', 'png']"
                style="width:90%"
                korean
              />
              <!-- 登録済みの場合は、ファイルのDLリンクを表示（別タブで開かせる） -->
              <!-- ファイルのリンク -->
              <a
                v-else-if="item.verify.status === 2 || item.verify.status === 3"
                :class="[$style.anker, $style.absolete, $style.imageUrl]"
                :href="item.verify.image_url"
                target="_blank"
                v-cloak
                >{{ item.verify.image_file_name }}</a
              >
            </div>

            <div :class="$style.deal_action">
              <!-- 発注書 -->
              <p :class="$style.deal_action_txt">
                ・발주서
                <!-- 確認するリンク staus=0(準備中)以外の場合表示 -->
                <a
                  :class="[$style.deal_action_anker, $style.anker]"
                  v-if="item.purchase.isLink"
                  @click="handleCheckPurchase(item.index)"
                  >확인하기</a
                >
              </p>
              <Button
                width="120px"
                :color="item.purchase.color"
                size="main"
                :disabled="item.purchase.disabled"
                @click="handlePurchase(item.index)"
                v-cloak
                >{{ item.purchase.label }}</Button
              >
            </div>
            <div :class="[$style.deal_action, $style.hegiht]">
              <!-- 商品写真 -->
              <p :class="$style.deal_action_txt">・상품사진</p>
              <Button
                width="120px"
                :color="item.product.color"
                size="main"
                :disabled="item.product.disabled"
                @click="handleUploadProductImage(item.index)"
                v-cloak
                >{{ item.product.label }}</Button
              >

              <!-- 登録するの場合は、ファイル参照可能 -->
              <InputFile
                :class="$style.absolete"
                v-if="item.product.status === 1"
                :file.sync="fileProduct[item.index]"
                :accept-type="['jpeg', 'jpg', 'png']"
                style="width:90%"
                korean
              />
              <!-- 登録済みの場合は、ファイルのDLリンクを表示（別タブで開かせる） -->
              <!-- ファイルのリンク -->
              <a
                v-else-if="item.product.status === 2"
                :class="[$style.anker, $style.absolete, $style.imageUrl]"
                :href="item.product.image_url"
                target="_blank"
                v-cloak
                >{{ item.product.image_file_name }}</a
              >
            </div>

            <div :class="$style.deal_action">
              <!-- 発送情報 -->
              <p :class="$style.deal_action_txt">・발송정보</p>
              <Button
                width="120px"
                :color="item.delivery.color"
                size="main"
                :disabled="item.delivery.disabled"
                @click="handleGoodsIssue(item.request_number)"
                v-cloak
                >{{ item.delivery.label }}</Button
              >
            </div>
          </div>
        </div>
      </div>
    </InfoBlock>

    <InfoBlock block width="320px" margin="20px auto 0" center>
      <template slot="head">
        등록정보
      </template>
      <div :class="$style.userInfo">
        <p :class="$style.userInfo_txt" v-cloak>
          <span>등록정보 등록명：</span>
          <span>{{ itemsAccount.user.name }}</span>
        </p>
        <p :class="$style.userInfo_txt" v-cloak>
          <span>Email：</span>
          <span>{{ itemsAccount.user.email }}</span>
        </p>
        <Anker to="/password/edit">비밀번호 변경하기</Anker>
      </div>
    </InfoBlock>
  </div>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import Button from "@/components/atoms/Button.vue"
import Anker from "@/components/atoms/Anker.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import InputFile from "@/components/atoms/InputFile.vue"
import SupplierDraftDataModifyModal from "@/components/molecules/SupplierDraftDataModifyModal.vue"
import api, { paths } from "@/utils/api.js"
import { uploadFile } from "@/utils/fileUpload.js"
import {
  routeReplaceError,
  getDate,
  showToast,
  showErrorToast,
} from "@/utils/shared.js"

export default {
  components: {
    Button,
    Anker,
    PrSelect,
    InputFile,
    InfoBlock,
    SupplierDraftDataModifyModal,
  },
  data() {
    return {
      isReady: false,
      selectedIndex: null,
      isDraftModify: false,
      itemsDealings: [],
      itemsAccount: [],
      status: [],
      fileVerify: [],
      fileProduct: [],
      filterValue: 0,
      filterOptions: [
        { key: 0, value: "미완료" },
        { key: 1, value: "완료" },
        { key: 2, value: "전체보기" },
      ],
      sortValue: {
        key: 0,
        isDesc: 1,
      },
      sortOptions: [
        { key: 0, value: "출고예정일" },
        { key: 1, value: "갱신일" },
        { key: 2, value: "상담No" },
      ],
    }
  },
  async created() {
    const loader = this.$loading.show()
    try {
      await this.init()
    } finally {
      loader.hide()
    }
    this.isReady = true
  },
  filters: {
    getDate(dateStr) {
      return getDate(dateStr, "미 설정")
    },
    shippingBoxNumber(quantity) {
      if (quantity == null) {
        return "미 설정"
      }
      return `${quantity}개 `
    },
  },
  computed: {
    filteredItems() {
      // すべて
      if (this.filterValue == 2) {
        return this.status
      }

      // 未完了
      if (this.filterValue == 0) {
        return this.status.filter(item => !item.is_completed)
      }
      // 完了
      else {
        return this.status.filter(item => item.is_completed)
      }
    },
    sortedItems() {
      const { key, isDesc } = this.sortValue

      return this.filteredItems
        .map(item => ({ ...item }))
        .sort((a, b) => {
          if (key == 0) {
            a = a.product.request_date1
            b = b.product.request_date1
          } else if (key == 1) {
            a = a.updated_at
            b = b.updated_at
          } else {
            a = a.request_number
            b = b.request_number
          }

          if (isDesc) {
            return a === b ? 0 : a < b ? 1 : -1
          } else {
            return a === b ? 0 : a > b ? 1 : -1
          }
        })
    },
  },
  methods: {
    async init() {
      //------------------------
      // 商談一覧
      //------------------------
      let result = await this.getDealings()
      if (!result.ok) {
        routeReplaceError(this, result.body.slice(-3))
        return
      }

      this.itemsDealings = result.body
      this.status = []
      this.itemsDealings.forEach((item, index) => {
        let status = {
          estimate: {}, // 見積
          delivery: {}, // 発送情報
          purchase: {}, // 発注書
          draft: {}, // 入稿データ
          verify: {}, // 最終確認画像
          product: {}, // 商品写真
        }

        status.index = index

        // 見積
        status.estimate = this.getEstimateStatus(item.estimate_status)

        // 発送情報
        status.delivery = this.getDeliveryStatus(item.delivery_status)

        // 発注書
        status.purchase = this.getPurchaseStatus(
          item.production_status,
          item.quantity4,
        )

        item.request_appendices.forEach(data => {
          switch (data.appendix_category) {
            case "draft":
              // 入稿データ
              status.draft = this.getDraftStatus(data.appendix_status)
              status.draft.image_url = data.appendix_name
              break
            case "verification_image":
              // 最終確認画像
              status.verify = this.getVerifyStatus(data.appendix_status)
              status.verify.image_url = data.appendix_name
              status.verify.image_file_name = this.getFileNameFromUrl(
                data.appendix_name,
              )
              break
            case "product_image":
              // 商品写真
              status.product = this.getProductStatus(data.appendix_status)
              status.product.image_url = data.appendix_name
              status.product.image_file_name = this.getFileNameFromUrl(
                data.appendix_name,
              )
              break
          }

          status.request_number = item.request_number
          status.order_data_name = item.order_data_name
          status.request_date1 = item.request_date1
          status.product.request_date1 = item.product.request_date1
          status.product.quantity5 = item.product.quantity5
          status.updated_at = item.updated_at
          status.is_completed = item.is_completed
        })

        this.status.push(JSON.parse(JSON.stringify(status)))
      })
      //------------------------
      // アカウント情報
      //------------------------
      result = await this.getAccount()
      if (!result.ok) {
        routeReplaceError(this, result.body.slice(-3))
        return
      }
      this.itemsAccount = result.body
    },
    async getDealings() {
      const { ok: result, body: items } = await api.get(
        paths.SUPPLIER_DEALINGS,
        this.$store.state.auth.token,
      )

      return { ok: result, body: items }
    },
    async getAccount() {
      const { ok: result, body: items } = await api.get(
        paths.SUPPLIER_USER,
        this.$store.state.auth.token,
      )

      return { ok: result, body: items }
    },
    getFileNameFromUrl(url) {
      if (!url) {
        return ""
      }

      const match = url.match(/[^/]+$/i)
      if (match) {
        return match[0]
      }

      return ""
    },
    toDefsString(category, key, lang = null) {
      const str = this.$store.getters["defs/getOptionValueByKey"](
        category,
        key,
        lang,
      )
      return str
    },
    getEstimateStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 確認する
        1: {
          color: "alert",
          disabled: false,
        },
        // 確認済
        2: {
          color: "checked",
          disabled: false,
        },
      }

      data.status = value
      data.label = this.toDefsString("SupplierEstimates", value, "KR")
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    getDraftStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 未確認
        1: {
          color: "alert",
          disabled: false,
        },
        // 確認済み
        2: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = value
      data.label = this.toDefsString("DraftStatus", value, "KR")
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    getVerifyStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 登録する
        1: {
          color: "alert",
          disabled: false,
        },
        // 登録済
        2: {
          color: "checked",
          disabled: true,
        },
        // 登録済
        3: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = value
      data.label = this.toDefsString("VerificationStatus", value, "KR")
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    getPurchaseStatus(production_status, quantity4) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 生産開始
        1: {
          color: "alert",
          disabled: false,
        },
        // 生産開始
        2: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = production_status
      data.label = this.toDefsString("PurchasePublish", production_status, "KR")
      data.color = table[production_status].color
      data.disabled = table[production_status].disabled
      data.isLink = quantity4 ? true : false

      return data
    },
    getProductStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 登録する
        1: {
          color: "alert",
          disabled: false,
        },
        // 登録済み
        2: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = value
      data.label = this.toDefsString("ProductStatus", value, "KR")
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    getDeliveryStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 未入力
        1: {
          color: "alert",
          disabled: false,
        },
        // 入力済み
        2: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = value
      data.label = this.toDefsString("GoodsIssue", value, "KR")
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    handleDraftChanged(result) {
      this.isDraftModify = false
      if (result) {
        const loader = this.$loading.show()
        try {
          this.init()
        } finally {
          loader.hide()
        }
      }
    },
    openDraftModify(index) {
      this.selectedIndex = index
      this.isDraftModify = true
    },
    handleQuotation(id) {
      this.$router.push({ path: `/supplier/dealings/${id}/contract` })
    },
    // 入稿データ
    async handleDraft(index) {
      const item = this.itemsDealings[index].request_appendices.find(data => {
        return data.appendix_category === "draft"
      })

      const data = {
        id: item.id,
        lock_version: item.lock_version,
        request_key1: item.request_key1,
        request_number: item.request_number,
        appendix_category: item.appendix_category,
      }

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.SUPPLIER_DRAFT_CHECK}/${item.request_number}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          console.log(`put SUPPLIER_DRAFT_CHECK ${body}`)
          showErrorToast(
            this,
            body.slice(-3),
            errorMsg,
            "등록에 실패했습니다",
            "KR",
          )
          return
        }

        // リストを再取得する
        await this.init()
      } finally {
        loader.hide()
      }
    },
    handleGoodsIssue(id) {
      this.$router.push({ path: `/supplier/dealings/${id}/goods-issue` })
    },
    // 発注書
    async handlePurchase(index) {
      const item = this.itemsDealings[index].request_appendices.find(data => {
        return data.appendix_category === "product_image"
      })

      const data = {
        id: item.id,
        lock_version: item.lock_version,
        request_key1: item.request_key1,
        request_number: item.request_number,
        appendix_category: item.appendix_category,
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.SUPPLIER_START_PRODUCT}/${item.request_number}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          console.log(`put SUPPLIER_START_PRODUCT ${body}`)
          showErrorToast(
            this,
            body.slice(-3),
            errorMsg,
            "등록에 실패했습니다",
            "KR",
          )
          return
        }

        // リストを再取得する
        await this.init()
      } finally {
        loader.hide()
      }
    },
    // eslint-disable-next-line
    handleCheckPurchase(index) {
      const id = this.itemsDealings[index].request_number
      const path = {
        KY: `/supplier/dealings/${id}/ordersheet/ky`,
        AC: `/supplier/dealings/${id}/ordersheet/ac`,
        TN: `/supplier/dealings/${id}/ordersheet/tn`,
      }

      const supplierCode = this.$store.getters["defs/getOptionValueByKey"](
        "SupplierCode",
        this.itemsAccount.supplier_code,
      )

      const key = supplierCode.substring(0, 2)

      this.$router.push({ path: path[key] })
    },
    // 最終確認画像
    async handleUploadVerifyImage(index) {
      if (!this.fileVerify[index]) {
        return
      }

      await this.uploadImage(
        this.fileVerify[index],
        "verification_image",
        this.itemsDealings[index].request_appendices,
      )
      this.fileVerify[index] = null
    },
    // 商品写真
    async handleUploadProductImage(index) {
      if (!this.fileProduct[index]) {
        return
      }

      await this.uploadImage(
        this.fileProduct[index],
        "product_image",
        this.itemsDealings[index].request_appendices,
      )
      this.fileProduct[index] = null
    },
    async uploadImage(file, category, request_appendices) {
      const loader = this.$loading.show()

      try {
        // 画像をアップロードして、URLを取得する
        const {
          ok: uploadResult,
          body: uploadBody,
          errorMsg: uploadErrorMsg,
        } = await uploadFile(
          file,
          this.$store.state.auth.userId,
          this.$store.state.auth.token,
        )

        if (!uploadResult) {
          // 登録に失敗しました。
          showErrorToast(
            this,
            uploadBody.slice(-3),
            uploadErrorMsg,
            "등록에 실패했습니다",
            "KR",
            0,
            true,
          )

          return
        }

        const url = uploadBody.path

        const item = request_appendices.find(data => {
          return data.appendix_category === category
        })

        const data = {
          id: item.id,
          lock_version: item.lock_version,
          request_key1: item.request_key1,
          request_number: item.request_number,
          appendix_name: url,
        }

        const apiPath = {
          verification_image: paths.SUPPLIER_VERIFICATION_UPLOAD,
          product_image: paths.SUPPLIER_PRODUCT_UPLOAD,
        }

        const {
          ok: updateResult,
          body: updateBody,
          errorMsg: updateErrorMsg,
        } = await api.put(
          `${apiPath[category]}/${item.request_number}`,
          data,
          this.$store.state.auth.token,
        )

        if (!updateResult) {
          console.log(`put ${apiPath[category]} ${updateBody}`)

          showErrorToast(
            this,
            updateBody.slice(-3),
            updateErrorMsg,
            "등록에 실패했습니다",
            "KR",
            0,
            true,
          )
          return
        }

        // リストを再取得する
        await this.init()

        // 登録完了しました。
        showToast(this, "success", "등록 완료했습니다", 0, true)
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 100vw;
  padding: 10px;
}
.deals {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hegiht {
  height: 100px;
  align-items: baseline !important;
  position: relative;
}

.absolete {
  position: absolute !important;
  top: 40px;
  right: 0;
  text-align: right;
}

.deal {
  width: 300px;
  margin: 10px;
  &Wrapper {
    border-bottom: 1px dotted map-get($colors, darkGlay);
  }
  padding: 15px 10px;
  &_ns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    &_name {
      width: 50%;
      font-size: 0.18rem;
      font-weight: bold;
      &:last-child {
        text-align: right;
      }
    }
    &_status {
      width: 50%;
      font-size: 0.18rem;
      font-weight: bold;
      text-align: right;
      color: map-get($colors, red);
    }
  }
  &_dates {
    margin-bottom: 10px;
    padding: 5px 10px;
    display: block;
    font-size: 0.14rem;
    background-color: map-get($colors, lightGray);
  }
  &_inputFile {
    margin: -5px 0 15px;
    padding-left: 50px;
  }
  &_action {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_anker {
      display: inline-block;
      margin-left: 15px;
    }
  }
  &_anker {
    display: flex;
    margin-bottom: 15px;
    justify-content: flex-end;
  }
}

.userInfo {
  > * {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_txt {
    display: flex;
    > span {
      &:last-child {
        word-break: break-all;
      }
    }
  }
}

.search {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;

  > div {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  align-items: flex-end;
}

.anker {
  color: #016cca;
  cursor: pointer;
  font-size: 0.18rem;
  font-weight: bold;
  position: relative;
  text-decoration: none;
}

.imageUrl {
  overflow-x: hidden;
  white-space: nowrap;
  width: 100%;
}

.nodata {
  text-align: center;
  margin: 0 auto;
  font-size: 0.3rem;
  border-radius: 20px;
  background-color: white;
}
</style>
