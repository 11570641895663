<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>不良品{{ isNew ? "新規作成" : "詳細" }}</PageTitle>
    <div :class="$style.relatedInfo">
      <div>
        <p :class="$style.relatedInfo_p"><slot /></p>
        <div :class="$style.relatedInfo_infoAria">
          <div :class="$style.relatedInfo_item">
            <p :class="$style.relatedInfo_ttl">不良品管理No</p>
            <p :class="$style.relatedInfo_txt">{{ this.items.request_key2 }}</p>
          </div>
          <div :class="$style.relatedInfo_item">
            <p :class="$style.relatedInfo_ttl">発注先</p>
            <p :class="$style.relatedInfo_txt">
              {{
                this.$store.getters["defs/getOptionValueByKey"](
                  "SupplierCode",
                  this.itemsRelated.supplier.supplier_code,
                )
              }}
            </p>
          </div>
          <div :class="$style.relatedInfo_item">
            <p :class="$style.relatedInfo_ttl">商談名</p>
            <p :class="$style.relatedInfo_txt">
              <router-link :to="`/admin/dealings/${this.items.request_number}`">
                {{ this.itemsRelated.request_name }}
              </router-link>
            </p>
          </div>
          <div :class="$style.relatedInfo_item">
            <p :class="$style.relatedInfo_ttl">取引先</p>
            <p :class="$style.relatedInfo_txt">
              {{ this.itemsRelated.customer.organization.name }}
            </p>
          </div>
          <div :class="$style.relatedInfo_item">
            <p :class="$style.relatedInfo_ttl">取引先責任者</p>
            <p :class="$style.relatedInfo_txt">
              {{
                this.itemsRelated.customer_person.last_name +
                  this.itemsRelated.customer_person.first_name
              }}
            </p>
          </div>
          <div :class="$style.relatedInfo_item">
            <p :class="$style.relatedInfo_ttl">更新者</p>
            <p :class="$style.relatedInfo_txt">
              {{ this.items.inserted.name }}
            </p>
          </div>
          <div :class="$style.relatedInfo_item">
            <p :class="$style.relatedInfo_ttl">更新日</p>
            <p :class="$style.relatedInfo_txt" v-if="this.isNew"></p>
            <p :class="$style.relatedInfo_txt" v-else>
              {{ this.items.updated_at | moment }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <InfoBlock width="1000px" block>
      <template slot="head"
        >不良品詳細</template
      >
      <div :class="$style.row">
        <PrSelect
          label="商品"
          :pr-row="[35]"
          name="name"
          v-model="values['name']"
          :validate="'required'"
          :options="productOptions"
        />
        <PrSelect
          label="顧客連絡"
          :pr-row="[35]"
          name="status"
          v-model="values['status']"
          :validate="'required'"
          :options="$store.getters['defs/getOptions']('DefectiveStatus')"
        />
      </div>
      <div :class="$style.row">
        <PrDatePicker
          label="対応期限"
          :pr-row="[35]"
          v-model="values['request_date2']"
        />
        <PrCheckbox
          before-label="再生産有無"
          :pr-row="[35]"
          v-model="values['quantity1']"
        />
      </div>
      <div :class="$style.row">
        <PrSelect
          label="不良種別"
          :pr-row="[35]"
          name="quantity2"
          v-model="values['quantity2']"
          :validate="'required'"
          :options="$store.getters['defs/getOptions']('DefectiveReason')"
        />
      </div>
      <div :class="$style.row">
        <PrInput label="不良品詳細" :pr-row="[35]" v-model="values['note1']" />
        <PrInput label="原因" :pr-row="[35]" v-model="values['note2']" />
      </div>
      <div :class="$style.row">
        <div class="special">
          <p>不良品写真①</p>
          <div>
            <div v-if="values['appendix_name_image1']" :class="$style.img">
              <PrImg :src="values['appendix_name_image1']" />
              <Button
                size="assist"
                color="cancel"
                @click="values['appendix_name_image1'] = null"
                >削除</Button
              >
            </div>
            <PrImgInput v-else v-model="fileImage[0]" />
          </div>
        </div>
        <div class="special">
          <p>不良品写真②</p>
          <div>
            <div v-if="values['appendix_name_image2']" :class="$style.img">
              <PrImg :src="values['appendix_name_image2']" />
              <Button
                size="assist"
                color="cancel"
                @click="values['appendix_name_image2'] = null"
                >削除</Button
              >
            </div>
            <PrImgInput v-else v-model="fileImage[1]" />
          </div>
        </div>
      </div>
    </InfoBlock>

    <InfoBlock width="1000px" block>
      <template slot="head"
        >対策情報</template
      >
      <div :class="$style.row">
        <PrInput label="対策概要" :pr-row="[35]" v-model="values['note3']" />
        <PrInput label="対策詳細" :pr-row="[35]" v-model="values['note4']" />
      </div>
    </InfoBlock>

    <div :class="$style.buttons_center">
      <Button color="submit" size="main" @click.native="handleSaveButtonClick"
        >編集内容を保存する</Button
      >
      <Button
        v-if="!isNew"
        color="cancel"
        size="assist"
        @click.native="initValues"
        >クリア</Button
      >
      <DialogProvider
        v-if="!isNew"
        :texts="{
          ttl: '確認',
          msg: `削除しますか？`,
          yas: '削除する',
          no: 'キャンセル',
        }"
        @accept="handleDeleteButtonClick"
      >
        <Button slot-scope="{ on }" color="alert" size="assist" v-on="on"
          >削除</Button
        >
      </DialogProvider>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrImgInput from "@/components/molecules/PrImgInput.vue"
import PrImg from "@/components/atoms/PrImg.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import { uploadFile } from "@/utils/fileUpload.js"
import { showToast, showErrorToast, routeReplaceError } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"

export default {
  components: {
    PageTitle,
    PrImgInput,
    PrInput,
    PrImg,
    PrCheckbox,
    PrDatePicker,
    PrSelect,
    InfoBlock,
    Button,
    DialogProvider,
  },
  data() {
    return {
      isReady: false,
      isNew: false,
      items: null,
      itemsRelated: null,
      values: null,
      productOptions: null,
      fileImage: [],
    }
  },
  filters: {
    moment: date => moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD"),
  },
  methods: {
    async validate() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      return result
    },
    async uploadImage(file) {
      console.log(file)
      if (!file) {
        return { ok: true, url: null }
      }
      const { ok: uploadResult, body: uploadBody } = await uploadFile(
        file,
        this.$store.state.auth.userId,
        this.$store.state.auth.token,
      )

      if (!uploadResult) {
        return { ok: false, url: null }
      }

      return { ok: true, url: uploadBody.path }
    },
    async handleSaveButtonClick() {
      let result = await this.validate()
      if (!result) {
        return
      }

      const loader = this.$loading.show()

      try {
        // ファイルアップロード
        result = null
        result = await this.uploadImage(this.fileImage[0])
        if (result.ok) {
          if (result.url) {
            this.values.appendix_name_image1 = result.url
          }
        } else {
          showToast(
            this,
            "error",
            "不良品写真①のアップロードに失敗しました。",
            3000,
            false,
          )

          return
        }
        this.fileImage[0] = null

        result = await this.uploadImage(this.fileImage[1])
        if (result.ok) {
          if (result.url) {
            this.values.appendix_name_image2 = result.url
          }
        } else {
          showToast(
            this,
            "error",
            "不良品写真②のアップロードに失敗しました。",
            3000,
            false,
          )

          return
        }
        this.fileImage[1] = null

        let appendix_1 = this.items.request_appendices.find(
          ({ appendix_category }) => appendix_category === "defect_image1",
        )
        appendix_1.appendix_name = this.values.appendix_name_image1

        let appendix_2 = this.items.request_appendices.find(
          ({ appendix_category }) => appendix_category === "defect_image2",
        )
        appendix_2.appendix_name = this.values.appendix_name_image2

        let data = {
          request_key1: this.items.request_key1,
          request_key3: this.values.name,
          request_key4: this.itemsRelated.supplier.supplier_code,
          request_number: this.items.request_number,
          request_date2: moment(this.values.request_date2)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ssZ"),
          quantity1: this.values.quantity1,
          quantity2: this.values.quantity2,
          note1: this.values.note1,
          note2: this.values.note2,
          note3: this.values.note3,
          note4: this.values.note4,
          status: this.values.status,
          request_appendices: [
            {
              request_key1: appendix_1.request_key1,
              request_number: appendix_1.request_number,
              appendix_category: appendix_1.appendix_category,
              appendix_name: appendix_1.appendix_name,
            },
            {
              request_key1: appendix_2.request_key1,
              request_number: appendix_2.request_number,
              appendix_category: appendix_2.appendix_category,
              appendix_name: appendix_2.appendix_name,
            },
          ],
        }

        if (!this.isNew) {
          // 編集
          data.request_key2 = this.items.request_key2
          data.lock_version = this.items.lock_version

          data.request_appendices[0].id = appendix_1.id
          data.request_appendices[0].request_key2 = appendix_1.request_key2
          data.request_appendices[0].lock_version = appendix_1.lock_version

          data.request_appendices[1].id = appendix_2.id
          data.request_appendices[1].request_key2 = appendix_2.request_key2
          data.request_appendices[1].lock_version = appendix_2.lock_version

          result = await api.put(
            `${paths.ADMIN_DEFECTIVES}/${this.$route.params.defect_id}`,
            data,
            this.$store.state.auth.token,
          )
        } else {
          // 新規
          data.request_date1 = moment(new Date())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ssZ")

          result = await api.post(
            paths.ADMIN_DEFECTIVES,
            data,
            this.$store.state.auth.token,
          )
        }

        if (!result.ok) {
          const errStatus = result.body.slice(-3)
          const errMsg = result.errorMsg
          if (errStatus === String(400)) {
            showErrorToast(this, errStatus, errMsg, errMsg)
            return
          }
          showErrorToast(this, errStatus, errMsg)
          return
        }

        if (this.isNew) {
          this.$router.replace(`/admin/dealings/${this.$route.params.deal_id}`)
        } else if (this.$route.name === "adminDefectiveDetail") {
          const ordered = await api.get(
            `${paths.ADMIN_ORDERED_CONSTRACTS}/${result.body.request_number}`,
            this.$store.state.auth.token,
          )
          if (!ordered.ok) {
            routeReplaceError(this, ordered.body.slice(-3))
            return
          }
          this.items = result.body
          this.itemsRelated = ordered.body
          this.initValues()
          this.setProductOptions()
        } else {
          this.$router.push(`/admin/dealings/${this.$route.params.deal_id}`)
        }
      } finally {
        loader.hide()
      }
    },
    async handleDeleteButtonClick() {
      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.DELETE(
          `${paths.ADMIN_DEFECTIVES}/${this.items.request_key2}`,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg, "削除に失敗しました。")
          return
        }
        if (this.$route.name === "adminDefectiveDetail") {
          this.$router.replace("/admin/defectives")
        } else {
          this.$router.replace(`/admin/dealings/${this.$route.params.deal_id}`)
        }
      } finally {
        loader.hide()
      }
    },
    initValues() {
      this.$validator.reset()
      const request_date2 = moment(
        this.items.request_date2,
        "YYYY-MM-DDTHH:mm:ssZ",
      ).format("YYYY-MM-DD")

      const appendix_name_image1 = this.items.request_appendices.find(
        ({ appendix_category }) => appendix_category === "defect_image1",
      ).appendix_name

      const appendix_name_image2 = this.items.request_appendices.find(
        ({ appendix_category }) => appendix_category === "defect_image2",
      ).appendix_name

      this.values = {
        name:
          this.items.contract_detail != null
            ? this.items.contract_detail.contract_name
            : "未設定",
        status: this.items.status,
        request_date2: request_date2,
        quantity1: this.items.quantity1,
        quantity2: this.items.quantity2,
        appendix_name_image1: appendix_name_image1,
        appendix_name_image2: appendix_name_image2,
        note1: this.items.note1,
        note2: this.items.note2,
        note3: this.items.note3,
        note4: this.items.note4,
      }

      this.fileImage = []
    },
    setProductOptions() {
      this.productOptions = this.itemsRelated.contract.contract.contract_details.reduce(
        (prev, curr) => {
          if (
            curr.category2 === "ready_made" ||
            curr.category2 === "order_made"
          ) {
            let value = ""
            if (curr.name) {
              value += curr.name
            }
            value += "-"
            if (curr.size3) {
              value += curr.size3
            }
            value += "-"
            if (curr.color) {
              let abbreviated = this.$store.getters["defs/getDefinitionByKey"](
                "TagPrint",
                "definition_abbreviated",
                curr.color,
                "JP",
              )
              value += abbreviated ? abbreviated : ""
            }
            value += "-"
            if (curr.size4) {
              value += curr.size4
            }

            prev.push({
              key: curr.contract_name,
              value: value,
            })
          }

          return prev
        },
        [],
      )
    },
  },
  async created() {
    if (
      this.$route.name === "adminDefectiveNew" ||
      this.$route.name === "adminDealingDefectiveNew"
    ) {
      this.isNew = true

      const ordered = await api.get(
        `${paths.ADMIN_ORDERED_CONSTRACTS}/${this.$route.params.deal_id}`,
        this.$store.state.auth.token,
      )
      if (!ordered.ok) {
        routeReplaceError(this, ordered.body.slice(-3))
        return
      }
      this.itemsRelated = ordered.body
      this.items = {
        request_key1: "defect",
        request_key2: "",
        request_number: this.$route.params.deal_id,
        contract_detail: {
          contract_name: "",
        },
        status: "",
        request_date2: moment(new Date(), "YYYY-MM-DDTHH:mm:ssZ").format(
          "YYYY/MM/DD",
        ),
        request_appendices: [
          {
            request_key1: "defect",
            appendix_category: "defect_image1",
            appendix_name: "",
            request_number: this.$route.params.deal_id,
          },
          {
            request_key1: "defect",
            appendix_category: "defect_image2",
            appendix_name: "",
            request_number: this.$route.params.deal_id,
          },
        ],
        quantity1: "",
        quantity2: "",
        note1: "",
        note2: "",
        note3: "",
        note4: "",
        inserted: {
          name: "",
        },
      }
    } else {
      // 不良品詳細
      const defectives = await api.get(
        `${paths.ADMIN_DEFECTIVES}/${this.$route.params.defect_id}`,
        this.$store.state.auth.token,
      )
      if (!defectives.ok) {
        routeReplaceError(this, defectives.body.slice(-3))
        return
      }

      // 関連情報
      const ordered = await api.get(
        `${paths.ADMIN_ORDERED_CONSTRACTS}/${defectives.body.request_number}`,
        this.$store.state.auth.token,
      )
      if (!ordered.ok) {
        routeReplaceError(this, ordered.body.slice(-3))
        return
      }

      this.items = defectives.body
      this.itemsRelated = ordered.body
    }
    this.initValues()
    this.setProductOptions()

    this.isReady = true
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  > div {
    width: 48%;
    &[class="special"] {
      display: flex;
      align-items: center;
      > p {
        width: 35%;
        color: map-get($colors, darkGlay);
      }
      > div {
        width: 65%;
      }
    }
  }
}
.img {
  text-align: center;
  > button {
    margin-top: 5px;
  }
}
.buttons_center {
  width: 1000px;
  margin: 50px 0;
  text-align: center;
}
.relatedInfo {
  width: 1000px;
  padding: 20px 20px;
  background-color: map-get($colors, white);
  &_p {
    font-size: 0.18rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &_infoAria {
    padding: 22px 0 0;
    display: flex;
    flex-wrap: wrap;
    background-color: map-get($colors, lightGray);
  }
  &_item {
    display: flex;
    align-items: center;
    padding: 0.8em 20px;
    &:nth-child(odd) {
      width: 55%;
    }
    &:nth-child(even) {
      width: 45%;
    }
  }
  &_ttl {
    width: 30%;
    color: map-get($colors, darkGlay);
  }
  &_txt {
    color: map-get($colors, black);
  }
}
</style>
