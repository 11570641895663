<template>
  <span
    :class="[
      $style.searchButton,
      { [$style.nobg]: nobg, [$style.close]: close },
    ]"
    v-on="$listeners"
  />
</template>

<script>
export default {
  props: {
    nobg: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" module>
.searchButton {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  background-color: map-get($colors, darkGlay);
  &:before {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    border: 2px solid #fff;
    transition: all 0.5s;
  }
  &:after {
    content: "";
    position: absolute;
    top: 17px;
    left: 20px;
    width: 2px;
    height: 8px;
    background-color: #fff;
    transform: rotate(-45deg);
    transition: all 0.5s;
  }
  &.nobg {
    background-color: transparent;
    &:before {
      border: 2px solid map-get($colors, black);
    }
    &:after {
      background-color: map-get($colors, black);
    }
  }
  &.close {
    &::before {
      top: 15px;
      left: 9px;
      width: 15px;
      height: 0px;
      border-width: 1px;
      transform: rotate(-45deg);
    }
    &:after {
      top: 9px;
      left: 15px;
      width: 2px;
      height: 15px;
    }
  }
}
</style>
