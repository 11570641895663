<template>
  <InfoBlock block width="1115px" margin="0">
    <template slot="head">
      その他情報
    </template>
    <template slot="head-right">
      <ModalToggler>
        <template slot="button">
          <v-btn flat icon color="indigo">
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
        <template slot-scope="{ toggle }">
          <InfoBlock block width="700px">
            <template slot="head"
              >その他情報編集</template
            >
            <div
              v-if="items.contract.some(({ status }) => status === 1)"
              :class="$style.info_row"
            >
              <PrSelect
                label="公開情報"
                name="note2"
                :pr-row="[30]"
                v-model="values.contract.note2"
                :options="
                  $store.getters['defs/getOptions']('PublicInformation')
                "
                :validate="'required'"
              />
            </div>
            <div
              v-if="items.contract.some(({ status }) => status === 1)"
              :class="$style.info_row"
            >
              <PrTextarea
                name="contract.note2"
                v-model="values.contract.note3"
                label="公開情報その他"
                :pr-row="[30]"
              />
            </div>
            <div :class="[$style.info_row, $style.accsecc]">
              <p>アクセス経路</p>
              <div>
                <PrCheckbox
                  v-for="(label, i) in [
                    'ネット検索',
                    'ネット広告',
                    'ダイレクトメール',
                    '展示会',
                    '知人の紹介',
                    '過去に注文したことがある',
                    'その他',
                  ]"
                  v-model="accsecc[i]"
                  :before-label="label"
                  :key="label"
                />
              </div>
            </div>
            <div :class="$style.info_row">
              <PrTextarea
                v-model="values.note3"
                label="アクセス経路その他"
                :pr-row="[30]"
              />
            </div>
            <div :class="$style.info_row">
              <PrTextarea v-model="values.note1" label="備考" :pr-row="[30]" />
            </div>
            <div style="text-align:center;margin:30px 0 0;">
              <Button
                size="main"
                color="submit"
                @click="handleSaveClick(toggle)"
                >保存</Button
              >
              <Button
                size="assist"
                color="cancel"
                @click="
                  () => {
                    initValues()
                    toggle()
                  }
                "
                >キャンセル</Button
              >
            </div>
          </InfoBlock>
        </template>
      </ModalToggler>
    </template>
    <div :class="$style.container">
      <div :class="$style.info">
        <div
          v-if="items.contract.some(({ status }) => status === 1)"
          :class="$style.info_row"
        >
          <p :class="$style.info_name">公開情報</p>
          <p :class="$style.info_content">
            {{ items.contract.find(({ status }) => status === 1).note2 }}
          </p>
        </div>
        <div
          v-if="items.contract.some(({ status }) => status === 1)"
          :class="$style.info_row"
        >
          <p :class="$style.info_name">公開情報その他</p>
          <!-- prettier-ignore -->
          <p :class="[$style.info_content, $style.preWrap]">{{ items.contract.find(({ status }) => status === 1).note3 }}</p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">アクセス経路</p>
          <p :class="$style.info_content">
            {{ items.note2 }}
          </p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">アクセス経路その他</p>
          <!-- prettier-ignore -->
          <p :class="[$style.info_content, $style.preWrap]">{{ items.note3 }}</p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">備考</p>
          <!-- prettier-ignore -->
          <p :class="[$style.info_content, $style.preWrap]">{{ items.note1 }}</p>
        </div>
      </div>
    </div>
  </InfoBlock>
</template>

<script>
import PrSelect from "@/components/molecules/PrSelect.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import Button from "@/components/atoms/Button.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import { showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

const accseccs = [
  "ネット検索",
  "ネット広告",
  "ダイレクトメール",
  "展示会",
  "知人の紹介",
  "過去に注文したことがある",
  "その他",
]

export default {
  components: {
    InfoBlock,
    Button,
    ModalToggler,
    PrSelect,
    PrCheckbox,
    PrTextarea,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      values: null,
      accsecc: [],
    }
  },
  methods: {
    initValues() {
      const dealing = this.items
      const contract = this.items.contract.find(({ status }) => status === 1)
      const newValues = {
        request_key1: "request",
        request_number: dealing.request_number,
        lock_version: dealing.lock_version,
        note1: dealing.note1,
        note3: dealing.note3,
      }

      this.values =
        contract === undefined
          ? newValues
          : {
              ...newValues,
              contract: {
                request_key1: "contract",
                request_number: contract.request_number,
                request_key2: contract.request_key2,
                lock_version: contract.lock_version,
                note2: contract.note2,
                note3: contract.note3,
              },
            }

      this.accsecc = accseccs.map(str =>
        dealing.note2 !== null && dealing.note2.includes(str) ? "1" : "0",
      )
    },
    async handleSaveClick(toggle) {
      const result = await this.$validator.validateAll().then(result => result)
      if (!result) {
        return
      }

      const { values, $store } = this
      const putValues = JSON.parse(JSON.stringify(values))

      putValues.note2 = accseccs
        .reduce(
          (acc, str, idx) =>
            this.accsecc[idx] === "1" ? `${acc}/${str}` : acc,
          "",
        )
        .slice(1)

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_DEALINGS_ETC}/${putValues.request_number}`,
          {
            ...putValues,
            description: "その他情報が更新されました。",
          },
          $store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        this.$emit("updated", body)
        toggle()
      } finally {
        loader.hide()
      }
    },
  },
  created() {
    this.initValues()
  },
  watch: {
    items() {
      console.log("items changed")
      this.initValues()
    },
  },
}
</script>

<style lang="scss" module>
@include InquirySampleOther();
.accsecc {
  align-items: center;
  > p {
    width: 30%;
    color: map-get($colors, darkGlay);
  }
  > div {
    width: 70%;
    > div {
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }
}
</style>
