<template>
  <InfoBlock width="685px" margin="0" block>
    <template slot="head"
      >見積り／注文</template
    >
    <template slot="head-right">
      <v-btn
        flat
        icon
        color="indigo"
        @click="handlePrintClick"
        v-if="printContracts.length != 0"
      >
        <v-icon>print</v-icon>
      </v-btn>
    </template>
    <div :class="$style.tableWrapper">
      <table v-if="contracts.length !== 0" :class="$style.table">
        <thead :class="$style.thead">
          <tr>
            <td>印刷</td>
            <td>見積名</td>
            <td>見積総額(税別)</td>
            <td>単価</td>
            <td>見積先</td>
            <td>見積日</td>
            <td>色校</td>
            <td>注文</td>
            <td>公開</td>
          </tr>
        </thead>
        <tbody :class="$style.tbody">
          <tr
            v-for="contract in contracts"
            :key="contract.id"
            :class="$style.tr"
          >
            <td>
              <PrCheckbox v-model="printTargets[contract.request_key2]" />
            </td>
            <td :class="$style.td">
              <router-link
                :to="
                  `/admin/dealings/${contract.request_number}/contracts/${
                    contract.contract.contract_no
                  }`
                "
                >{{ contract.request_name || "未設定" }}</router-link
              >
            </td>
            <td :class="$style.td">
              {{ contract.contract.total_amount | formatPrice }}
            </td>
            <td :class="$style.td">
              {{ contract.contract.contract_details | getUnitPrice }}
            </td>
            <td :class="$style.td">
              {{ contract.supplier ? contract.supplier.user.name : "未設定" }}
            </td>
            <td :class="$style.td">{{ contract.request_date1 | getDate }}</td>
            <td :class="$style.td">
              <PrCheckbox :value="contract.quantity3" disabled />
            </td>
            <td :class="$style.td">
              <PrCheckbox :value="contract.status" disabled />
            </td>
            <td :class="$style.td">
              <DialogProvider
                :texts="{
                  ttl: '確認',
                  msg: `${contract.request_name || '名称未設定'}を${
                    contract.quantity2 == 0 ? '公開' : '非公開に'
                  }しますか？`,
                  yas: 'OK',
                  no: 'キャンセル',
                }"
                @accept="handleReleaseAccept(contract)"
              >
                <template slot-scope="{ on }">
                  <span @click.capture.stop="on.click" style="cursor: pointer;">
                    <PrCheckbox
                      :value="contract.quantity2"
                      style="pointer-events: none;"
                    />
                  </span>
                </template>
              </DialogProvider>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else :class="$style.not">登録された見積りはありません</p>
      <div :class="$style.buttons">
        <Anker
          v-if="contracts.length !== 0"
          :to="`/admin/dealings/${items.request_number}/dealing-contracts`"
          >見積品目一覧</Anker
        >
        <Button
          v-if="items.status <= 3"
          color="submit"
          size="main"
          @click.native="
            $router.push(
              `/admin/dealings/${items.request_number}/contracts/new`,
            )
          "
          >新規見積</Button
        >
      </div>
    </div>
  </InfoBlock>
</template>

<script>
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import Button from "@/components/atoms/Button.vue"
import Anker from "@/components/atoms/Anker.vue"
import {
  getDate,
  showErrorToast,
  formatPrice,
  formatDecimalPrice,
  openNewWindow,
} from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    DialogProvider,
    InfoBlock,
    Anker,
    PrCheckbox,
    Button,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { printTargets: null }
  },
  computed: {
    contracts() {
      const contracts = JSON.parse(JSON.stringify(this.items.contract))
      return contracts.sort((a, b) => a.request_key2 - b.request_key2)
    },
    printContracts() {
      const { printTargets } = this
      if (printTargets == null) {
        return []
      }
      return Object.entries(printTargets)
        .filter(([_, val]) => val != "0")
        .map(([key]) => key)
    },
  },
  filters: {
    getDate: str => getDate(str),
    formatPrice: num => formatPrice(num),
    getUnitPrice: contractDetails => {
      if (!contractDetails) {
        return 0
      } else {
        let product = contractDetails.filter(
          detail => ["ready_made", "order_made"].indexOf(detail.category2) >= 0,
        )
        return product.length === 1 ? formatDecimalPrice(product[0].price) : ""
      }
    },
  },
  methods: {
    async handleReleaseAccept({
      request_number,
      request_key1,
      request_key2,
      lock_version,
      request_name,
      quantity2,
    }) {
      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_DEALINGS_CONTRACT}/${this.items.request_number}`,
          {
            request_number,
            request_key1,
            request_key2,
            lock_version,
            request_name,
            quantity2: quantity2 == 0 ? "1" : "0",
          },
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        this.$emit("updated", body)
      } finally {
        loader.hide()
      }
    },
    handlePrintClick() {
      const { printContracts, items } = this
      openNewWindow(
        `/admin/dealings/written-estimate/${
          items.request_number
        }?contracts=${printContracts.join()}`,
      )
    },
  },
  created() {
    this.printTargets = this.items.contract.reduce(
      (acc, c) => ({ ...acc, [c.request_key2]: "0" }),
      {},
    )
  },
}
</script>

<style lang="scss" module>
.tableWrapper {
  background-color: map-get($colors, white);
}
.table {
  width: 100%;
  border-style: none;
  border-spacing: 0;
  text-align: center;
}
.thead {
  font-size: 0.14rem;
  color: map-get($colors, darkGlay);
}
.tr {
  color: map-get($colors, darkGlay);
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
}
.td {
  padding: 5px;
}
.buttons {
  padding: 20px 15px 15px;
  display: flex;
  justify-content: space-between;
}
.not {
  padding: 5px 0;
  background-color: map-get($colors, lightlightGray);
  text-align: center;
}
</style>
