var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.wrapper },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.thumbnailPath !== "",
              expression: "thumbnailPath !== ''",
            },
          ],
        },
        [_c("img", { attrs: { src: _vm.thumbnailPath } })]
      ),
      _vm._v(" "),
      _c("InputFile", {
        attrs: { "accept-type": ["jpeg", "jpg", "png"], file: _vm.file },
        on: {
          change: _vm.showThumbnail,
          clear: _vm.handleClear,
          "update:file": function ($event) {
            _vm.file = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }