<template>
  <div :class="$style.wrapper">
    <div v-show="thumbnailPath !== ''">
      <img :src="thumbnailPath" />
    </div>
    <InputFile
      @change="showThumbnail"
      @clear="handleClear"
      :accept-type="['jpeg', 'jpg', 'png']"
      :file.sync="file"
    />
  </div>
</template>

<script>
import InputFile from "@/components/atoms/InputFile.vue"

export default {
  components: {
    InputFile,
  },
  data() {
    return {
      file: null,
      thumbnailPath: "",
    }
  },
  methods: {
    showThumbnail(file) {
      this.file = file
      const reader = new FileReader()
      reader.addEventListener("load", e => {
        this.thumbnailPath = e.target.result
      })
      reader.readAsDataURL(file)
      this.$emit("input", file)
    },
    handleClear() {
      this.thumbnailPath = ""
      this.$emit("input", null)
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  > div {
    padding: 0 10px 10px 10px;
    text-align: center;
  }
  > label {
    width: 100%;
  }
}
</style>
