var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.checkbox, attrs: { name: "pr-checkbox" } },
    [
      _vm.beforeLabel
        ? _c("p", { style: _vm.labelStyling }, [
            _vm._v(_vm._s(_vm.beforeLabel)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("label", [
        _vm.validate !== undefined && _vm.validate !== null
          ? _c(
              "input",
              _vm._b(
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.validate,
                      expression: "validate",
                    },
                  ],
                  attrs: { name: _vm.name, type: "checkbox" },
                  domProps: { value: _vm.value },
                  on: { change: _vm.handleChange },
                },
                "input",
                _vm.myAttrs,
                false
              )
            )
          : _c(
              "input",
              _vm._b(
                {
                  attrs: { type: "checkbox" },
                  domProps: { value: _vm.value },
                  on: { change: _vm.handleChange },
                },
                "input",
                _vm.myAttrs,
                false
              )
            ),
        _vm._v(" "),
        _c("span", {
          attrs: { name: "checkbox" },
          on: {
            click: function ($event) {
              _vm.$emit("click", Number(_vm.value) === 1 ? "0" : "1")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm.afterLabel ? _c("p", [_vm._v(_vm._s(_vm.afterLabel))]) : _vm._e(),
      _vm._v(" "),
      _vm.validate
        ? [
            _c(
              "PrErrorText",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has(_vm.name),
                    expression: "errors.has(name)",
                  },
                ],
                attrs: { center: _vm.prRow[0] !== null },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.errors.first(_vm.name)) + "\n    "
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }