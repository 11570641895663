var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.customers && _vm.customerPersons
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [_vm._v("商談新規作成")]),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "100%", block: "", center: "" } },
            [
              _c("template", { slot: "head" }, [
                _vm._v("\n      商談情報\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.forms },
                [
                  _c("PrSelect", {
                    attrs: {
                      label: "取引先",
                      "pr-row": [40],
                      value: _vm.values.request_key2,
                      options: _vm.customerOptions,
                      searchable: true,
                      name: "request_key2",
                      validate: "required",
                    },
                    on: { input: _vm.handleCustomerSelect },
                  }),
                  _vm._v(" "),
                  _c("PrSelect", {
                    attrs: {
                      label: "取引先責任者",
                      "pr-row": [40],
                      value: _vm.values.request_key3,
                      options: _vm.customerPersonOptions,
                      searchable: true,
                      name: "request_key3",
                      validate: "required",
                    },
                    on: { input: _vm.handleCustomerPersonSelect },
                  }),
                  _vm._v(" "),
                  _c("PrSelect", {
                    attrs: {
                      label: "フェーズ",
                      "pr-row": [40],
                      options: _vm.$store.getters[
                        "defs/getOptionsByDefinition"
                      ]("Progress", "display_discernment_code", 0),
                      name: "status",
                      validate: "required",
                    },
                    model: {
                      value: _vm.values.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "status", $$v)
                      },
                      expression: "values.status",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrDatePicker", {
                    attrs: { label: "完了予定日", "pr-row": [40] },
                    model: {
                      value: _vm.values.request_date3,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "request_date3", $$v)
                      },
                      expression: "values.request_date3",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "100%", block: "", center: "" } },
            [
              _c("template", { slot: "head" }, [
                _vm._v("\n      配送情報\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.forms },
                [
                  _c("PrSelect", {
                    attrs: {
                      label: "納品書情報",
                      "pr-row": [40],
                      options: [
                        { key: 0, value: "なし" },
                        { key: 1, value: "あり" },
                      ],
                    },
                    model: {
                      value: _vm.values.delivery.quantity3,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.delivery, "quantity3", $$v)
                      },
                      expression: "values.delivery.quantity3",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrCheckbox", {
                    attrs: {
                      "before-label": "ヤマトタイムサービス",
                      "pr-row": [40],
                    },
                    model: {
                      value: _vm.values.delivery.quantity4,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.delivery, "quantity4", $$v)
                      },
                      expression: "values.delivery.quantity4",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "100%", block: "", center: "" } },
            [
              _c("template", { slot: "head" }, [
                _vm._v("\n      サンプル送付情報\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.forms },
                [
                  _c("PrDatePicker", {
                    attrs: { label: "サンプル請求受付日", "pr-row": [40] },
                    model: {
                      value: _vm.values.inquiry.request_date3,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.inquiry, "request_date3", $$v)
                      },
                      expression: "values.inquiry.request_date3",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: { label: "サンプル請求内容", "pr-row": [40] },
                    model: {
                      value: _vm.values.inquiry.note1,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.inquiry, "note1", $$v)
                      },
                      expression: "values.inquiry.note1",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrTextarea", {
                    attrs: { label: "サンプル請求備考", "pr-row": [19] },
                    model: {
                      value: _vm.values.inquiry.note2,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.inquiry, "note2", $$v)
                      },
                      expression: "values.inquiry.note2",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "100%", block: "", center: "" } },
            [
              _c("template", { slot: "head" }, [
                _vm._v("\n      問い合わせ情報\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.forms },
                [
                  _c("PrDatePicker", {
                    attrs: { label: "問合せ日", "pr-row": [40] },
                    model: {
                      value: _vm.values.inquiry.request_date1,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.inquiry, "request_date1", $$v)
                      },
                      expression: "values.inquiry.request_date1",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrSelect", {
                    attrs: {
                      label: "問合せトラフィック",
                      "pr-row": [40],
                      options:
                        _vm.$store.getters["defs/getOptions"]("InquiryRoute"),
                    },
                    model: {
                      value: _vm.values.inquiry.quantity1,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.inquiry, "quantity1", $$v)
                      },
                      expression: "values.inquiry.quantity1",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrTextarea", {
                    attrs: { label: "問合せ内容", "pr-row": [19] },
                    model: {
                      value: _vm.values.inquiry.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.values.inquiry, "description", $$v)
                      },
                      expression: "values.inquiry.description",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "100%", block: "", center: "" } },
            [
              _c("template", { slot: "head" }, [
                _vm._v("\n      その他\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.formsBig },
                [
                  _c("div", { staticClass: "access" }, [
                    _c("p", [_vm._v("アクセス経路")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      _vm._l(
                        [
                          "ネット検索",
                          "ネット広告",
                          "ダイレクトメール",
                          "展示会",
                          "知人の紹介",
                          "過去に注文したことがある",
                          "その他",
                        ],
                        function (label, idx) {
                          return _c("PrCheckbox", {
                            key: label,
                            attrs: { "after-label": label },
                            model: {
                              value: _vm.access[idx],
                              callback: function ($$v) {
                                _vm.$set(_vm.access, idx, $$v)
                              },
                              expression: "access[idx]",
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("PrTextarea", {
                    attrs: { label: "アクセス経路その他", "pr-row": [19] },
                    model: {
                      value: _vm.values.note3,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "note3", $$v)
                      },
                      expression: "values.note3",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrTextarea", {
                    attrs: { label: "備考", "pr-row": [19] },
                    model: {
                      value: _vm.values.note1,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "note1", $$v)
                      },
                      expression: "values.note1",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "Button",
            {
              staticStyle: { "margin-top": "30px" },
              attrs: { color: "submit", size: "main" },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleSaveButtonClick.apply(null, arguments)
                },
              },
            },
            [_vm._v("登録する")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }