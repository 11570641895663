var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { class: _vm.$style.wapper }, [
    _c("span", { class: _vm.$style.button }, [_vm._v(_vm._s(_vm.labels[0]))]),
    _vm._v(" "),
    _c("input", {
      ref: "input",
      attrs: { type: "file", accept: _vm.accept },
      on: { change: _vm.handleInputChange },
    }),
    _vm._v(" "),
    _c(
      "span",
      { class: _vm.$style.fileName },
      [
        _vm._v(_vm._s(_vm.fileName ? _vm.fileName : _vm.labels[1]) + "\n    "),
        _vm.fileName !== ""
          ? _c(
              "v-icon",
              {
                class: _vm.$style.clear,
                attrs: { color: "white" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleClearClick.apply(null, arguments)
                  },
                },
              },
              [_vm._v("clear")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }