<template>
  <div v-if="items" :class="$style.wrapper">
    <div style="margin-bottom:20px;">
      <DealingHeader />
    </div>
    <div
      v-for="contract in items.contract"
      :key="contract.id"
      :class="$style.quotation"
    >
      <div :class="$style.quotation_head">
        <p :class="$style.quotation_head_name">見積管理No</p>
        <router-link
          :to="
            `/admin/dealings/${contract.request_number}/dealing-contracts/${
              contract.contract.contract_no
            }`
          "
          :class="[$style.quotation_head_item, $style.small]"
          >{{ contract.contract.contract_no }}</router-link
        >
      </div>
      <template v-if="contractDetails[contract.id].readyMade.body.length !== 0">
        <h3 :class="$style.quotation_made">既成品見積一覧</h3>
        <InnerTable
          :head="contractDetails[contract.id].readyMade.head"
          :body="contractDetails[contract.id].readyMade.body"
        />
      </template>
      <template v-if="contractDetails[contract.id].orderMade.body.length !== 0">
        <h3 :class="$style.quotation_made">
          オーダーメイド見積一覧
        </h3>
        <InnerTable
          :head="contractDetails[contract.id].orderMade.head"
          :body="contractDetails[contract.id].orderMade.body"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DealingHeader from "@/components/molecules/DealingHeader.vue"
import InnerTable from "@/components/molecules/InnerTable.vue"
import { routeReplaceError, formatPrice } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    DealingHeader,
    InnerTable,
  },
  data() {
    return {
      items: null,
    }
  },
  computed: {
    contractDetails() {
      const govbk = this.$store.getters["defs/getOptionValueByKey"]

      return this.items.contract.reduce(
        (acc, contract) => ({
          ...acc,
          [contract.id]: {
            readyMade: {
              head: [
                "商品ファミリ",
                "商品名",
                "カバー・台紙サイズ",
                "付箋サイズ",
                "付箋印刷",
                "付箋枚数",
                "価格表個数",
                "注文個数",
                "単価(税別)",
                "総額(税別)",
              ],
              body: contract.contract.contract_details
                .filter(({ category2 }) => category2 === "ready_made")
                .map(cd => [
                  cd.category1,
                  cd.name,
                  cd.size1,
                  cd.size2,
                  govbk("TagPrint", cd.color),
                  cd.size3,
                  cd.size4,
                  cd.amount,
                  cd.price,
                  formatPrice(cd.amount * cd.price, false),
                ]),
            },
            orderMade: {
              head: [
                "商品ファミリ",
                "カバー・台紙サイズ",
                "カバー・台紙型抜き",
                "付箋サイズ",
                "付箋型抜き",
                "付箋印刷",
                "付箋枚数",
                "注文個数",
                "単価(税別)",
                "総額(税別)",
              ],
              body: contract.contract.contract_details
                .filter(({ category2 }) => category2 === "order_made")
                .map(cd => [
                  govbk("ProductFamily", cd.category1),
                  cd.size1,
                  govbk("Cuttings", cd.category3),
                  cd.size2,
                  govbk("Cuttings", cd.category4),
                  govbk("TagPrint", cd.color),
                  cd.size3,
                  cd.amount,
                  cd.price,
                  formatPrice(cd.amount * cd.price, false),
                ]),
            },
          },
        }),
        {},
      )
    },
  },
  async created() {
    const { ok, body } = await api.get(
      `${paths.ADMIN_DEALINGS_CONTRACTS}/${this.$route.params.deal_id}`,
      this.$store.state.auth.token,
    )
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }

    this.items = body
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 1460px;
  margin: 20px auto;
}
.quotation {
  margin-bottom: 25px;
  padding: 25px;
  background-color: map-get($colors, white);
  &_head {
    padding-bottom: 25px;
    border-bottom: 1px solid map-get($colors, lightGray);
    display: flex;
    align-items: center;
    &_name {
      margin-right: 10px;
      font-size: 0.13rem;
      color: map-get($colors, darkDarkGray);
    }
    &_item {
      color: map-get($colors, blue);
      text-decoration: underline;
      &.small {
        margin-right: 35px;
        font-size: 0.18rem;
      }
      &.big {
        font-size: 0.24rem;
      }
    }
  }
  &_made {
    margin: 40px 0 20px;
  }
}
</style>
