var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ModalToggler",
    {
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ toggle }) {
            return [
              _c(
                "OMContractDetailEditer",
                _vm._b(
                  {
                    on: {
                      saved: function ($event) {
                        return _vm.$emit("saved", $event)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function ({ handleAddButtonClick, init }) {
                            return _c(
                              "div",
                              { class: _vm.$style.buttons },
                              [
                                _c(
                                  "Button",
                                  {
                                    attrs: { size: "main", color: "submit" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSaveClick(
                                          handleAddButtonClick,
                                          init,
                                          toggle
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "Button",
                                  {
                                    attrs: { size: "assist", color: "cancel" },
                                    on: {
                                      click: () => {
                                        init()
                                        toggle()
                                      },
                                    },
                                  },
                                  [_vm._v("キャンセル")]
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  "OMContractDetailEditer",
                  { contructDetail: _vm.contructDetail },
                  false
                )
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "button" },
        [
          this.contructDetail === null
            ? _c(
                "Button",
                { attrs: { size: "main", color: "submit", plus: "" } },
                [_vm._v("新規オーダーメイド見積")]
              )
            : _c(
                "v-btn",
                { attrs: { flat: "", icon: "", color: "black" } },
                [_c("v-icon", [_vm._v("edit")])],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }