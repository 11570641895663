var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", { class: _vm.$style.wrapper }, [
        _c("h1", { class: _vm.$style.title }, [_vm._v("メールアドレス変更")]),
        _vm._v(" "),
        _c("div", [
          _vm.validMessage
            ? _c("p", { class: _vm.$style.message_error }, [
                _vm._v("\n      " + _vm._s(_vm.validMessage) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              class: _vm.$style.formAria,
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleChange.apply(null, arguments)
                },
              },
            },
            [
              _c("p", { class: _vm.$style.desc }, [
                _vm._v(
                  "\n        変更したいメールアドレスを入力してください。\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "InputForm",
                {
                  class: _vm.$style.form,
                  attrs: {
                    name: "now_email",
                    type: "email",
                    value: _vm.nowEmail,
                    readonly: "",
                  },
                },
                [_vm._v("現在のメールアドレス")]
              ),
              _vm._v(" "),
              _c(
                "InputForm",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'",
                    },
                  ],
                  class: _vm.$style.form,
                  attrs: { name: "new_email", type: "email" },
                  model: {
                    value: _vm.newEmail,
                    callback: function ($$v) {
                      _vm.newEmail = $$v
                    },
                    expression: "newEmail",
                  },
                },
                [_vm._v("新しいメールアドレス")]
              ),
              _vm._v(" "),
              _c(
                "Button",
                { attrs: { type: "submit", color: "submit", size: "main" } },
                [_vm._v("変更する")]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }