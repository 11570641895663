var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "320px", margin: "0", center: "", block: "" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    商品情報\n  ")]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.wrapper }, [
        _c("h3", [_vm._v("見積No." + _vm._s(_vm.deal.contract.request_key2))]),
      ]),
      _vm._v(" "),
      _vm._l(
        _vm.deal.contract.contract.contract_details.filter(({ category2 }) =>
          category2.endsWith("_made")
        ),
        function (cd) {
          return _c(
            "div",
            { key: cd.id, class: _vm.$style.wrapper },
            [
              _c("p", [_vm._v("商品名")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$store.getters["defs/getOptionValueByKey"](
                        "ProductFamily",
                        cd.category1,
                        "JP"
                      )
                    ) +
                    _vm._s(cd.name) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              cd.jan_code == "0"
                ? [
                    _c("p", [_vm._v("カバーサイズ")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(cd.size1))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("付箋サイズ")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(cd.size2))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("付箋印刷")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$store.getters["defs/getOptionValueByKey"](
                              "TagPrint",
                              cd.color
                            )
                          ) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("付箋枚数")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(cd.size3))]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              cd.jan_code == "1"
                ? [
                    _c("p", [_vm._v("サイズ")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(cd.size1))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("印刷")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$store.getters["defs/getOptionValueByKey"](
                              "TagPrint",
                              cd.color
                            )
                          ) +
                          "\n      "
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              cd.jan_code === "2"
                ? [
                    _c("p", [_vm._v("カバーサイズ")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(cd.size1))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("カバー型抜き")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$store.getters["defs/getOptionValueByKey"](
                              "Cuttings",
                              cd.category3,
                              "JP"
                            )
                          ) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("付箋サイズ")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(cd.size2))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("付箋型抜き")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$store.getters["defs/getOptionValueByKey"](
                              "Cuttings",
                              cd.category4,
                              "JP"
                            )
                          ) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("付箋印刷")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$store.getters["defs/getOptionValueByKey"](
                              "TagPrint",
                              cd.color
                            )
                          ) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("付箋枚数")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(cd.size3))]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("p", [_vm._v("生産個数")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm._f("formatDecimalAmount")(cd.amount))),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("単価")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatDecimalPrice")(
                      _vm.calcPrice(cd.price, cd.thumbnail)
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("総額")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("formatDecimalPrice")(
                        _vm.calcTotalPrice(cd.price, cd.thumbnail, cd.amount)
                      )
                    ) +
                    "\n    "
                ),
              ]),
            ],
            2
          )
        }
      ),
      _vm._v(" "),
      _vm.priceOption.length !== 0
        ? _c(
            "div",
            { class: _vm.$style.wrapper },
            _vm._l(_vm.priceOption, function (cd) {
              return _c(
                "div",
                { key: `option-${cd.id}`, class: _vm.$style.priceOptions },
                [
                  _c("p", [_vm._v(_vm._s(cd.name))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm._f("formatPrice")(cd.price)))]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("p", [_vm._v("見積総額")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("formatPrice")(
                    +_vm.deal.contract.contract.total_amount + _vm.discount
                  )
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("消費税")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("formatPrice")(_vm.deal.contract.contract.tax_amount)
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.discount != 0
            ? [
                _c("p", [_vm._v("値引き")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("-" + _vm._s(_vm._f("formatPrice")(_vm.discount))),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("p", [_vm._v("見積総額(税込)")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("formatPrice")(
                    Number(_vm.deal.contract.contract.total_amount) +
                      Number(_vm.deal.contract.contract.tax_amount)
                  )
                ) +
                "\n    "
            ),
          ]),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }