<template>
  <div v-if="values !== null" :class="$style.wrapper">
    <PageTitle>{{ isConfirm ? "再注文確認" : "再注文" }}</PageTitle>
    <CustomerUserName :customer-person="items.customer_person" />

    <section v-show="!isConfirm">
      <InfoBlock width="320px" margin="0" center block>
        <template slot="head">
          お客様（注文者）ご住所
        </template>
        <div :class="$style.inner">
          <PrInput
            label="郵便番号"
            :pr-row="[100, 75]"
            v-model="values.shipping.order_address1.zip_code"
            @change="handleOrderAddressChange"
            name="default.order_address1.zip_code"
            :validate="'required|zipcode'"
            data-vv-scope="default"
            placeholder="例) 812-0895"
            requiredIcon
          >
            <ButtonSerchZipCode
              slot="after"
              :zipcode="values.shipping.order_address1.zip_code"
              @click="handleZipSearch"
            />
          </PrInput>
          <PrInput
            label="都道府県"
            :pr-row="[100, 100]"
            v-model="values.shipping.order_address1.location"
            @change="handleOrderAddressChange"
            name="default.order_address1.location"
            :validate="'required'"
            data-vv-scope="default"
            placeholder="例) 福岡県"
            requiredIcon
          />
          <PrInput
            label="市区町村"
            :pr-row="[100, 100]"
            v-model="values.shipping.order_address1.address1"
            @change="handleOrderAddressChange"
            name="default.order_address1.address1"
            :validate="'required'"
            data-vv-scope="default"
            placeholder="例) 福岡市博多区"
            requiredIcon
          />
          <PrInput
            label="町名・番地"
            :pr-row="[100, 100]"
            v-model="values.shipping.order_address1.address2"
            @change="handleOrderAddressChange"
            name="default.order_address1.address2"
            :validate="'required'"
            data-vv-scope="default"
            placeholder="例) 竹下1丁目11-23"
            requiredIcon
          />
          <PrInput
            label="建物名・部屋番号"
            :pr-row="[100, 100]"
            v-model="values.shipping.order_address2.location"
            @change="handleOrderAddressChange"
            placeholder="例) アクアサイドGEN1F"
          />
        </div>
      </InfoBlock>
      <InfoBlock width="320px" margin="0" center block>
        <template slot="head">
          入金・配送・納品
        </template>
        <div :class="$style.inner">
          <div :class="$style.formGroup">
            <p>お支払方法</p>
            <template
              v-for="({ key, value }, idx) in $store.getters['defs/getOptions'](
                'PaymentMethod',
              )"
            >
              <PrInputRadio
                v-if="idx < 2"
                :label="value"
                :value="key"
                :key="key"
                v-model="values.contract.quantity4"
            /></template>
          </div>
          <PrDatePicker
            label="入金予定日"
            :pr-row="[100, 100]"
            v-model="values.contract.request_date3"
            v-bind="paymentDateRegulation"
          />
          <PrDatePicker
            label="納品希望日"
            :pr-row="[100, 100]"
            v-model="values.request_date2"
            v-bind="draftDateRegulation"
          />

          <div :class="$style.formGroup">
            <p>受取可能曜日</p>
            <PrCheckbox
              v-for="(day, i) in ['月', '火', '水', '木', '金', '土', '日・祝']"
              v-model="receiveDates[i]"
              :before-label="day"
              :key="i"
            />
          </div>
          <div :class="$style.formGroup">
            <p>商品お届け先</p>
            <PrInputRadio
              v-for="[label, value] in [
                ['注文者と同じ', '0'],
                ['指定住所へ配送', '1'],
              ]"
              :label="label"
              :value="value"
              :key="value"
              v-model="values.shipping.status"
              @change="handleDeliveryRadioChange"
            />
          </div>
          <CustomerOrderAddressInputs
            v-show="values.shipping.status == '1'"
            :setCustomAddress="setCustomAddress"
            :flag="values.shipping.status"
            :address="values.shipping"
            whom="delivery"
          />
          <div :class="$style.formGroup">
            <p>送り主変更オプション</p>
            <PrCheckbox
              v-model="values.delivery.quantity2"
              @change="handleSenderCheckChange"
              after-label="変更する"
            /><br />
            <span name="red">
              送り状に表示する送り主を、弊社からご注文者または任意の情報に変更することができます。変更された場合、弊社の領収証や広告物など一切同封いたしません。ご安心のうえ、クライアント様に直接発送が可能です。
            </span>
          </div>
          <div :class="$style.formGroup">
            <p>
              送り主<span v-if="values.delivery.quantity2 == '0'">PR39</span>
            </p>
            <template v-if="values.delivery.quantity2 == '1'">
              <PrInputRadio
                v-for="[lab, val] in [['注文者', '0'], ['下記の情報', '1']]"
                :label="lab"
                :value="val"
                :key="val"
                v-model="isSenderChange"
                @change="handleSenderRadioChange"
              />
            </template>
            <CustomerOrderAddressInputs
              v-show="values.delivery.quantity2 == '1' && isSenderChange == '1'"
              :setCustomAddress="setCustomAddress"
              :flag="isSenderChange"
              whom="sender"
              :address="values.shipping"
            />
          </div>
        </div>
      </InfoBlock>
      <InfoBlock width="320px" margin="0" center block>
        <template slot="head">
          その他
        </template>
        <div :class="$style.inner">
          <div v-if="items.verification_image_path" :class="$style.formGroup">
            <p>印刷データ</p>
            <PrInputRadio
              v-for="[lab, val] in [
                ['新しく入稿する', '0'],
                ['前回と同じ', '1'],
              ]"
              :label="lab"
              :value="val"
              :key="val"
              v-model="values.reusable"
            />
            <span name="caption">前回の最終確認画像</span>
            <img :src="items.verification_image_path" />
          </div>
          <div :class="$style.formGroup">
            <p>備考</p>
            <span name="gray">
              その他、ご注文に関してご希望などありましたらこちらにご入力ください。
            </span>
            <PrTextarea
              v-model="values.contract.note1"
              :pr-row="[0, 100]"
              rows="5"
            />
          </div>
          <div :class="$style.formGroup">
            <span name="gray">
              <a href="https://www.pr39.com/terms/" target="_brank"
                >「PR39」利用規約</a
              >の内容をご確認のうえ、「同意する」チェックボックスにチェックを入れてください。
            </span>
            <span name="confirm">
              <PrCheckbox
                v-model="isAgree"
                after-label="同意する"
                name="default.isAgree"
                :validate="'required'"
                data-vv-scope="default"
                style="justify-content: center;"
              />
            </span>
          </div>
        </div>
      </InfoBlock>
    </section>

    <section v-if="isConfirm">
      <CustomerProductInfo :deal="items" />
      <CustomerOrderAddress :deal="values" />
      <CustomerPayment :deal="values" />
      <CustomerOther
        :deal="values"
        :image-path="items.verification_image_path"
      />
    </section>

    <div v-if="!isConfirm">
      <Button
        size="assist"
        color="cancel"
        @click.native="
          () => {
            initValues()
            removeValidation()
          }
        "
        >クリア</Button
      >
      <Button size="main" color="submit" @click.native="handleGoConfirm"
        >確認画面へ進む</Button
      >
    </div>
    <div v-else>
      <Button size="assist" color="alert" @click.native="$router.back()"
        >戻る</Button
      >
      <Button
        v-if="isConfirm"
        size="main"
        color="submit"
        @click.native="handleSubmit"
        >再注文を確定する</Button
      >
    </div>
  </div>
</template>

<script>
import CustomerUserName from "@/components/atoms/CustomerUserName.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import ButtonSerchZipCode from "@/components/molecules/ButtonSerchZipCode.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrInputRadio from "@/components/atoms/PrInputRadio.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import CustomerOrderAddressInputs from "@/components/molecules/CustomerOrderAddressInputs.vue"
import CustomerProductInfo from "@/components/molecules/CustomerProductInfo.vue"
import CustomerOrderAddress from "@/components/molecules/CustomerOrderAddress.vue"
import CustomerPayment from "@/components/molecules/CustomerPayment.vue"
import CustomerOther from "@/components/molecules/CustomerOther.vue"
import api, { paths } from "@/utils/api.js"
import { showErrorToast, routeReplaceError } from "@/utils/shared.js"
import {
  setValues,
  days,
  extractAddress,
  mergeAddress,
  makeReceiveDates,
  checkAddressEquality,
} from "@/helpers/ClientReOrderHelper.js"

export default {
  components: {
    PageTitle,
    CustomerUserName,
    ButtonSerchZipCode,
    Button,
    PrInputRadio,
    PrCheckbox,
    InfoBlock,
    PrInput,
    PrDatePicker,
    PrTextarea,
    CustomerOrderAddressInputs,
    CustomerOrderAddress,
    CustomerProductInfo,
    CustomerPayment,
    CustomerOther,
  },
  data() {
    return {
      items: null,
      values: null,
      receiveDates: null,
      isSenderChange: "0",
      isAgree: "0",
    }
  },
  watch: {
    receiveDates(newVal) {
      this.values.delivery.note1 = days
        .reduce((acc, day, i) => acc + (newVal[i] == "1" ? `${day}/` : ""), "")
        .slice(0, -1)
    },
  },
  computed: {
    isConfirm() {
      return this.$route.fullPath.endsWith("#confirmed")
    },
    paymentDateRegulation() {
      const disableSchDt = new Date()
      disableSchDt.setDate(disableSchDt.getDate() - 1)
      return { disabledDates: { to: disableSchDt } }
    },
    draftDateRegulation() {
      let customerInfo = this.items.customer
      let days = customerInfo.specific === 1 ? 14 : 21
      const disablePrefDt = new Date()
      disablePrefDt.setDate(disablePrefDt.getDate() + days - 1)
      const openDt = new Date()
      openDt.setDate(openDt.getDate() + days)
      return { disabledDates: { to: disablePrefDt }, openDate: openDt }
    },
  },
  methods: {
    initValues() {
      const { items, $store } = this
      const reOrderInfo = $store.state.customer.reOrderInfo
      const isRe =
        reOrderInfo !== null &&
        reOrderInfo.request_number == this.$route.params.deal_id

      this.isSenderChange = isRe ? reOrderInfo.isSenderChange : "0"
      this.isAgree = isRe ? reOrderInfo.isAgree : "0"
      this.receiveDates = makeReceiveDates(
        isRe ? reOrderInfo.delivery.note1 : items.delivery.note1,
      )

      const values = setValues(isRe ? reOrderInfo : items)
      if (isRe) {
        this.values = values
        this.isSenderChange = reOrderInfo.isSenderChange
        return
      }

      let shipping = values.shipping
      if (values.shipping.status == "0") {
        const address = extractAddress(shipping, "order_address", "delivery")
        shipping = mergeAddress(shipping, address)
      }
      if (values.delivery.quantity2 == "0") {
        const address = extractAddress(this.items, "pr39_address_", "sender")
        shipping = mergeAddress(shipping, address)
        this.isSenderChange = "0"
      } else {
        if (checkAddressEquality(shipping, "order_address", "sender_address")) {
          this.isSenderChange = "0"
        } else {
          this.isSenderChange = "1"
        }
      }

      this.values = { ...values, shipping }
    },
    removeValidation() {
      for (const field of this.$validator.fields) {
        console.log(`field is`, field)
        field.reset()
        this.errors.remove(field.name, field.scope)
      }
    },
    setCustomAddress(address) {
      this.values.shipping = mergeAddress(this.values.shipping, address)
    },
    handleOrderAddressChange() {
      let shipping = JSON.parse(JSON.stringify(this.values.shipping))
      if (String(shipping.status) === "0") {
        const address = extractAddress(shipping, "order_address", "delivery")
        shipping = mergeAddress(shipping, address)
      }
      if (
        String(this.values.delivery.quantity2) === "1" &&
        String(this.isSenderChange) === "0"
      ) {
        const address = extractAddress(shipping, "order_address", "sender")
        shipping = mergeAddress(shipping, address)
      }
      this.values.shipping = shipping
    },
    handleDeliveryRadioChange(flag) {
      if (String(flag) === "0") {
        const shipping = this.values.shipping
        const address = extractAddress(shipping, "order_address", "delivery")
        this.values.shipping = mergeAddress(shipping, address)
      }
    },
    handleSenderCheckChange(flag) {
      const shipping = this.values.shipping
      if (String(flag) === "0") {
        const address = extractAddress(this.items, "pr39_address_", "sender")
        this.values.shipping = mergeAddress(shipping, address)
      } else {
        const address = extractAddress(shipping, "order_address", "sender")
        this.values.shipping = mergeAddress(shipping, address)
      }
    },
    handleSenderRadioChange(flag, _setCustomAddress) {
      if (String(flag) === "0") {
        const shipping = this.values.shipping
        const address = extractAddress(shipping, "order_address", "sender")
        this.values.shipping = mergeAddress(shipping, address)
      }
    },
    handleZipSearch({ ok, address }) {
      if (!ok) {
        return
      }
      this.values.shipping.order_address1 = {
        ...this.values.shipping.order_address1,
        ...address,
      }

      for (const { name, scope } of [
        "default.order_address1.location",
        "default.order_address1.address1",
        "default.order_address1.address2",
      ].map(key => this.$validator.fields.find({ name: key }))) {
        this.errors.remove(name, scope)
      }
    },
    async handleGoConfirm() {
      const { values, isSenderChange, isAgree, $validator } = this
      const isCustomDeliveryShow = values.shipping.status == "1"
      const isCustomSenderShow =
        values.delivery.quantity2 == "1" && isSenderChange == "1"

      const results = await Promise.all([
        $validator.validateAll("default").then(r => r),
        isCustomDeliveryShow
          ? $validator.validateAll("custom_delivery").then(r => r)
          : Promise.resolve(true),
        isCustomSenderShow
          ? $validator.validateAll("custom_sender").then(r => r)
          : Promise.resolve(true),
      ])

      if (results.includes(false)) {
        return
      }

      const request_number = this.$route.params.deal_id

      this.$store.dispatch("customer/setReOrderInfo", {
        ...JSON.parse(JSON.stringify(this.values)),
        request_number,
        isSenderChange,
        isAgree,
      })
      this.$router.push(
        `/customer/ordered-histories/${request_number}/re-order#confirmed`,
      )
    },
    async handleSubmit() {
      const postValues = JSON.parse(JSON.stringify(this.values))

      console.log(`postValues =`, postValues)
      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.post(
          paths.CUSTOMER_RE_ORDER,
          postValues,
          this.$store.state.auth.token,
        )
        if (!ok) {
          console.log("POST失敗", body)
          showErrorToast(this, body.slice(-3), errorMsg, "通信に失敗しました。")
          return
        }

        this.$router.push("/customer/ordered-histories")
      } finally {
        loader.hide()
      }
    },
  },
  async created() {
    const { ok, body } = await api.get(
      paths.CUSTOMER_RE_ORDER + "/" + this.$route.params.deal_id,
      this.$store.state.auth.token,
    )
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }
    this.items = body
    this.initValues()

    if (this.isConfirm && this.isAgree === "0") {
      routeReplaceError(this, 900)
    }
  },
}
</script>

<style lang="scss" module>
.wrapper {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > button,
  > div {
    margin-top: 20px;
  }
}
.inner {
  > * {
    width: 100%;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.formGroup {
  width: 100%;
  > div[name="pr-checkbox"] {
    margin: 0 10px 5px 0;
  }
  > div[name="pr-textarea"] {
    width: 100%;
  }
  > label[name="pr-radio"] {
    margin-right: 10px;
  }
  > span {
    margin-bottom: 0.2em;
    display: block;
    font-size: 0.14rem;
    &[name="red"] {
      color: map-get($colors, red);
    }
    &[name="gray"] {
      color: map-get($colors, darkGlay);
    }
    &[name="caption"] {
      text-align: center;
      margin-bottom: 0;
      color: map-get($colors, white);
      background-color: map-get($colors, darkGlay);
    }
    &[name="confirm"] {
      margin-top: 20px;
      text-align: center;
      margin-bottom: 0;
    }
    > a {
      text-decoration: underline;
    }
  }
  > img {
    border: 1px solid map-get($colors, darkGlay);
  }
  /* stylelint-disable */
  > p {
    margin-bottom: 0.2em;
    color: map-get($colors, darkGlay);
    > span {
      margin-left: 1em;
      color: map-get($colors, black);
    }
  }
}
</style>
