<template>
  <InfoBlock block width="1115px" margin="0">
    <template slot="head">
      お問合せ情報
    </template>
    <template slot="head-right">
      <PrSelect
        :value="items.inquiry.quantity2"
        @input="handleStatusChnge"
        :options="$store.getters['defs/getOptions']('InquiryStatus')"
      />
      <ModalToggler>
        <template slot="button">
          <v-btn flat icon color="indigo">
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
        <template slot-scope="{ toggle }">
          <InfoBlock style="position:relative;" block width="600px" margin="0">
            <template slot="head"
              >お問合せ情報編集</template
            >
            <div :class="$style.info_row">
              <PrDatePicker
                label="問合日"
                :pr-row="[30]"
                v-model="values.request_date1"
              />
            </div>
            <div :class="$style.info_row">
              <PrTextarea
                v-model="values.description"
                label="内容"
                :pr-row="[30]"
                :validate="'required'"
                name="description"
              />
            </div>
            <div style="text-align:center;margin:30px 0 50px;">
              <Button
                size="main"
                color="submit"
                @click="handleSaveClick(toggle)"
                >保存</Button
              >
              <Button
                size="assist"
                color="cancel"
                @click="
                  () => {
                    initValues()
                    toggle()
                  }
                "
                >キャンセル</Button
              >
            </div>
          </InfoBlock>
        </template>
      </ModalToggler>
    </template>
    <div :class="$style.container">
      <div :class="$style.info">
        <div :class="$style.info_row">
          <p :class="$style.info_name">問合日</p>
          <div :class="$style.info_content">
            <span v-if="items.inquiry.request_date1">
              {{ items.inquiry.request_date1 | getDate }}
            </span>
          </div>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">内容</p>
          <!-- prettier-ignore -->
          <p :class="[$style.info_content, $style.preWrap]">{{ items.inquiry.description }}</p>
        </div>
      </div>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import Button from "@/components/atoms/Button.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import { showErrorToast, getDate } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    InfoBlock,
    PrSelect,
    Button,
    ModalToggler,
    PrDatePicker,
    PrTextarea,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      values: null,
    }
  },
  filters: {
    getDate: str => getDate(str),
  },
  methods: {
    initValues() {
      const inquiry = this.items.inquiry
      this.values = {
        request_key1: "inquiry",
        request_number: inquiry.request_number,
        lock_version: inquiry.lock_version,
        request_date1: inquiry.request_date1,
        description: inquiry.description,
        request_appendices: inquiry.request_appendices.map(ra => ({ ...ra })),
      }
    },
    async putApi(putValues) {
      const { ok, body, errorMsg } = await api.put(
        `${paths.ADMIN_DEALINGS_INQUIRY}/${putValues.request_number}`,
        putValues,
        this.$store.state.auth.token,
      )
      if (!ok) {
        showErrorToast(this, body.slice(-3), errorMsg)
        return
      }
      this.$emit("updated", body)
    },
    async handleSaveClick(toggle) {
      const result = await this.$validator.validateAll().then(result => result)
      if (!result) {
        return
      }

      const { putApi, values } = this
      const putValues = JSON.parse(JSON.stringify(values))

      const loader = this.$loading.show()
      try {
        await putApi({
          ...putValues,
          history_message: "お問い合わせ情報が更新されました。",
        })
        toggle()
      } finally {
        loader.hide()
      }
    },
    async handleStatusChnge(quantity2) {
      const {
        $store,
        putApi,
        items: { inquiry },
      } = this

      const loader = this.$loading.show()
      try {
        await putApi({
          request_key1: "inquiry",
          request_number: inquiry.request_number,
          request_appendices: inquiry.request_appendices.map(ra => ({ ...ra })),
          lock_version: inquiry.lock_version,
          quantity2,
          history_message: `お問い合わせ情報が[${$store.getters[
            "defs/getOptionValueByKey"
          ]("InquiryStatus", quantity2)}]に更新されました。`,
        })
      } finally {
        loader.hide()
      }
    },
  },
  created() {
    this.initValues()
  },
  watch: {
    items() {
      this.initValues()
    },
  },
}
</script>

<style lang="scss" module>
@include InquirySampleOther();
</style>
