<template>
  <div>
    <div :class="$style.dataImport">
      <PrInput
        label="ご担当者様氏名"
        name="name"
        v-model="values.name"
        :pr-row="[60, 60]"
        validate="required"
      />
      <PrInput
        label="ご担当者様メールアドレス"
        name="email"
        v-model="values.email"
        :pr-row="[60, 60]"
        validate="required|email"
      />
      <PrInput
        label="電話番号"
        v-model="values.phone"
        :pr-row="[60, 60]"
        name="phone_number"
        validate="required|telephone"
      />
      <PrInput
        label="GigaFile便URL"
        v-model="values.giga_file_url"
        :pr-row="[60, 60]"
        name="giga_file_url"
        validate="url"
      />
      <p :class="$style.label">入稿データ選択</p>
      <InputFile
        @change="values.file = $event"
        :accept-type="['zip', 'ai']"
        style="width:60%;"
      />
      <template
        v-if="isButtonClicked && !(values.file || values.giga_file_url)"
      >
        <PrErrorText>
          入稿ファイル選択またはGigaFile便URLを入力してください
        </PrErrorText>
      </template>

      <br />
      <br />
      <ModalToggler :scroll="false" noOpen>
        <template slot="button" slot-scope="{ toggleActive }">
          <Button
            type="button"
            color="submit"
            size="main"
            style="margin: 0 auto;display:block;"
            @click="handleUpdate(toggleActive)"
            >送信する</Button
          >
        </template>
        <template>
          <div :class="$style.done">
            <template v-if="success">
              <h3>データの入稿が完了しました</h3>
              <p>
                入稿処理完了のメールが届きます。<br />
                また、これよりデータチェックの工程へと進みます。<br />
                問題がなければデータチェック完了メールにてご連絡差し上げます。<br />
                問題を発見しましたらお電話もしくはメールにてご報告致します。
              </p>
            </template>
            <template v-else>
              <h3>データの入稿に失敗しました。</h3>
              <p>
                大変申し訳ございません。<br />
                不明なエラーによりファイルの送信に失敗しました。<br />
                時間を再度お試し下さい。
              </p>
            </template>
          </div>
        </template>
      </ModalToggler>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import Button from "@/components/atoms/Button.vue"
import InputFile from "@/components/atoms/InputFile.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"

export default {
  components: {
    Button,
    PrInput,
    InputFile,
    PrErrorText,
    ModalToggler,
  },
  data() {
    return {
      success: false,
      isButtonClicked: false,
      values: {
        app_key:
          "LXSLMsVu1rvWsSZ7pOLBu0z3luuUKWE0+MsOP2jr5720dqt7NWvPSxL+yquj5D+6",
        phone: "",
        item_name: "",
        file: null,
        email: "",
        name: "",
        giga_file_url: "",
      },
    }
  },
  mounted() {
    const sty = document.createElement("style")
    sty.innerHTML =
      "html {overflow: auto !important;}body {background-color: #fff !important;}body > div {min-height: auto !important;}body > div > div {min-height: auto !important;}"
    document.head.appendChild(sty)
  },
  methods: {
    async handleUpdate(toggleActive) {
      this.isButtonClicked = true
      const result = await this.$validator.validateAll().then(result => result)
      if (!result || !(this.values.file || this.values.giga_file_url)) {
        return
      }

      const formData = new FormData()
      for (const [key, val] of Object.entries(this.values)) {
        console.log(key, val)
        formData.append(key, val)
      }

      axios
        .post("/api/web-upload", formData, {
          headers: {
            ContentType: "multipart/form-data",
          },
        })
        .then(() => {
          this.success = true
        })
        .catch(() => {
          this.success = false
        })
        .finally(toggleActive)
    },
  },
}
</script>

<style lang="scss" module>
.head3 {
  font-size: 16px;
}
.rice {
  font-size: 14px;
  margin-bottom: 16px;
}
.flow {
  margin-bottom: 30px;
  dt {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  ol {
    counter-reset: my-counter;
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 10px;
      padding-left: 30px;
      position: relative;
      font-size: 16px;
      line-height: 20px;
      &::before {
        font-size: 14px;
        margin-top: 0 !important;
        content: counter(my-counter);
        counter-increment: my-counter;
        background-color: #11a0c2;
        color: #fff;
        display: block;
        float: left;
        line-height: 20px;
        margin-left: -30px;
        text-align: center;
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }
    }
  }
}
.dataImport {
  [name="pr-text"] {
    margin-bottom: 30px;
  }
}
.label {
  margin-bottom: 0.2em;
  color: #707070;
}
.done {
  padding: 50px 0;
  height: 100vh;
  width: 100vw;
  background: #fff;
  text-align: center;
  > h3 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  > p {
    line-height: 2em;
  }
  > a {
    margin-top: 40px;
    display: inline-block;
    &:last-of-type {
      margin-left: 30px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
