<template>
  <div :class="$style.checkbox" name="pr-checkbox">
    <p v-if="beforeLabel" :style="labelStyling">{{ beforeLabel }}</p>
    <label>
      <input
        v-if="validate !== undefined && validate !== null"
        v-validate="validate"
        :name="name"
        type="checkbox"
        v-bind="myAttrs"
        :value="value"
        @change="handleChange"
      />
      <input
        v-else
        type="checkbox"
        v-bind="myAttrs"
        :value="value"
        @change="handleChange"
      />
      <span
        name="checkbox"
        @click="$emit('click', Number(value) === 1 ? '0' : '1')"
      ></span>
    </label>
    <p v-if="afterLabel">{{ afterLabel }}</p>
    <template v-if="validate">
      <PrErrorText v-show="errors.has(name)" :center="prRow[0] !== null">
        {{ errors.first(name) }}
      </PrErrorText>
    </template>
  </div>
</template>

<script>
import PrErrorText from "@/components/atoms/PrErrorText.vue"

export default {
  inject: ["$validator"],
  inheritAttrs: false,
  components: {
    PrErrorText,
  },
  props: {
    value: {
      required: true,
    },
    beforeLabel: String,
    afterLabel: String,
    validate: String,
    name: String,
    labelStyle: Object,
    prRow: {
      type: Array,
      default() {
        return [null, null]
      },
    },
  },
  computed: {
    labelStyling() {
      const { prRow } = this
      return {
        ...this.labelStyle,
        width: prRow[0] ? `${prRow[0]}%` : "auto",
      }
    },
    inputStyle() {
      const { prRow } = this
      return {
        width: prRow[1] ? `${prRow[1]}%` : "auto",
      }
    },
    myAttrs() {
      return {
        ...this.$attrs,
        ...(this.value == 1 ? { checked: "checked" } : {}),
      }
    },
  },
  methods: {
    handleChange() {
      const { value } = this
      this.$emit("change", Number(value) === 1 ? "0" : "1")
      this.$emit("input", Number(value) === 1 ? "0" : "1")
    },
  },
}
</script>

<style lang="scss" module>
.checkbox {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  //justify-content: center;
  > p {
    display: inline-block;
    color: map-get($colors, darkGlay);
    &:first-of-type {
      margin-right: 3px;
    }
    &:last-of-type {
      margin-left: 3px;
    }
  }
  > label {
    display: inline-block;
    > span {
      &[name="checkbox"] {
        vertical-align: bottom;
        cursor: pointer;
        display: inline-block;
        width: 26px;
        height: 26px;
        border-radius: 4px;
        background-color: map-get($colors, lightGray);
        position: relative;
      }
    }
    > input {
      display: none;
      &:checked + span::after {
        content: "";
        width: 13px;
        height: 20px;
        position: absolute;
        top: -1px;
        left: 6px;
        border-right: 5px solid map-get($colors, red);
        border-bottom: 5px solid map-get($colors, red);
        transform: rotate(45deg);
      }
      &:disabled + span {
        background-color: map-get($colors, darkGlay);
        cursor: default;
      }
    }
  }
}
</style>
