<template>
  <InfoBlock :class="$style.InfoBlock" block width="1600px" margin="0">
    <template slot="head"
      >オーダーメイド見積{{ isNew ? "新規作成" : "編集" }}</template
    >
    <div :class="$style.wrapper">
      <h3>商品情報</h3>
      <table :class="$style.table">
        <colgroup>
          <col style="width: 19%;" />
          <col style="width: 16%;" />
          <col style="width: 13%;" />
          <col style="width: 16%;" />
          <col style="width: 13%;" />
          <col style="width: 13%;" />
          <col style="width: 5%;" />
          <col style="width: 5%;" />
        </colgroup>
        <thead>
          <tr>
            <th>商品ファミリ</th>
            <th>カバー・台紙サイズ</th>
            <th>カバー・台紙型抜き</th>
            <th>付箋サイズ</th>
            <th>付箋型抜き</th>
            <th>付箋印刷</th>
            <th>付箋枚数</th>
            <th>個数</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <PrSelect
                v-model="values.category1"
                :options="
                  $store.getters['defs/getOptions']('ProductFamily', 'JP')
                "
                name="category1"
                :validate="'required'"
                :pr-row="[100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
            <td>
              <PrInput
                v-model="values.size1"
                :pr-row="[0, 100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
            <td>
              <PrSelect
                v-model="values.category3"
                :options="$store.getters['defs/getOptions']('Cuttings', 'JP')"
                name="category3"
                :pr-row="[100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
            <td>
              <PrInput
                v-model="values.size2"
                name="size2"
                :validate="'required'"
                :pr-row="[0, 100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
            <td>
              <PrSelect
                name="category4"
                v-model="values.category4"
                :options="$store.getters['defs/getOptions']('Cuttings', 'JP')"
                :validate="'required'"
                :pr-row="[100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
            <td>
              <PrSelect
                name="color"
                v-model="values.color"
                :options="$store.getters['defs/getOptions']('TagPrint', 'JP')"
                :validate="'required'"
                :pr-row="[100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
            <td>
              <PrInput
                v-model="values.size3"
                :validate="'required|numeric'"
                name="size3"
                :pr-row="[0, 100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
            <td>
              <PrInput
                v-model="values.amount"
                :validate="'required|numeric'"
                name="amount"
                :pr-row="[0, 100]"
                style="width:100%;padding: 0 10px"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <h3>価格情報</h3>
      <table :class="$style.table">
        <thead>
          <tr>
            <th>仕入額（₩）</th>
            <th>輸送額</th>
            <th>原価</th>
            <th>掛率</th>
            <th>単価</th>
            <th>小計</th>
            <th>粗利額</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <PrInput
                name="purchase_amount"
                :pr-row="[0, 100]"
                v-model="values.purchase_amount"
                :validate="'numeric'"
              /><br />
              <PrErrorText v-if="isPurchaseAmountValide" style="width:155px;"
                >仕入額を入力してください</PrErrorText
              >
            </td>
            <td>
              <PrInput
                name="merchandise_cost"
                v-model="values.merchandise_cost"
                :validate="'decimal:1'"
                :pr-row="[0, 100]"
              />
            </td>
            <td>{{ values | calcCost }}</td>
            <td>
              <PrInput
                name="weight1"
                v-model="values.weight1"
                :validate="'decimal:3'"
                :pr-row="[0, 100]"
              />
            </td>
            <td>
              <PrInput
                name="price"
                v-model="values.price"
                v-if="!values.weight1 || values.weight1 === '0'"
                :validate="'required|decimal:3'"
                :pr-row="[0, 100]"
              />
              <template v-else>
                {{ values | calcOrderUnitPrice }}
              </template>
            </td>
            <td>{{ values | calcOrderTotalPrice }}</td>
            <td>{{ values | calcGrossProfit }}</td>
          </tr>
        </tbody>
      </table>
      <div :class="$style.column">
        <div>
          <PrTextarea
            label="備考"
            v-model="values.description"
            :pr-row="[100, 100]"
          />
        </div>
        <div>
          <h3>参考画像</h3>
          <div v-if="values.image_url" :class="$style.img">
            <PrImg :src="values.image_url" /><br />
            <Button size="assist" color="cancel" @click="values.image_url = ''"
              >削除</Button
            >
          </div>
          <PrImgInput v-else v-model="file" />
        </div>
      </div>
      <slot
        name="buttons"
        v-bind="{
          handleAddButtonClick,
          init,
          resetValidator,
        }"
      />
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import Button from "@/components/atoms/Button.vue"
import PrImg from "@/components/atoms/PrImg.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import PrImgInput from "@/components/molecules/PrImgInput.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import {
  getUniqueStr,
  calcCost,
  calcOrderUnitPrice,
  calcOrderTotalPrice,
  calcGrossProfit,
} from "@/helpers/QuotationDetailViewHelper.js"
import { uploadFile } from "@/utils/fileUpload.js"
import { showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    InfoBlock,
    Button,
    PrImg,
    PrImgInput,
    PrInput,
    PrSelect,
    PrTextarea,
    PrErrorText,
  },
  props: {
    contructDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      values: null,
    }
  },
  computed: {
    isNew() {
      return this.contructDetail === null
    },
    isPurchaseAmountValide() {
      return (
        !this.values.purchase_amount &&
        (this.values.merchandise_cost || this.values.weight1)
      )
    },
  },
  filters: {
    calcCost,
    calcOrderUnitPrice,
    calcOrderTotalPrice,
    calcGrossProfit,
  },
  methods: {
    resetValidator() {
      this.$validator.reset()
    },
    init() {
      this.values = this.isNew
        ? {
            id: `new_${getUniqueStr()}`,
            category1: "", // 商品ファミリ
            category2: "order_made",
            category3: "", // カバー型抜き
            category4: "", // 付箋型抜き
            size1: "", // カバーサイズ
            size2: "", // 付箋サイズ
            size3: "", // 付箋枚数
            color: "", // 付箋印刷
            amount: "", // 個数
            description: "", // 備考
            purchase_amount: "", // 仕入額
            merchandise_cost: "", // 輸送費
            weight1: "", // 掛け率
            weight3: "0", // 見積内容
            //manufacturer: "1", //見積先
            tax_category: "Default",
            delivery_area: "",
            price: "0",
          }
        : { ...this.contructDetail }

      this.file = null
    },
    async handleAddButtonClick() {
      const result = await this.$validator.validateAll().then(r => r)
      if (!result || this.isPurchaseAmountValide) {
        return false
      }

      const { values, file, $store } = this
      const postValues = { ...values }

      postValues.price =
        !postValues.weight1 || postValues.weight1 === "0"
          ? postValues.price
          : String(calcOrderUnitPrice(postValues))
      postValues.jan_code = $store.getters["defs/getDefinitionByKey"](
        "ProductFamily",
        "display_discernment_code",
        postValues.category1,
      )

      postValues.name = $store.getters["defs/getDefinitionByKey"](
        "ProductFamily",
        "definition_abbreviated",
        postValues.category1,
      )

      if (file !== null) {
        const loader = this.$loading.show()
        try {
          const { ok, body, errorMsg } = await uploadFile(
            file,
            $store.state.auth.userId,
            $store.state.auth.token,
          )
          if (!ok) {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              "画像のアップロードに失敗しました。",
            )
            return false
          }

          this.file = null
          postValues.image_url = body.path
        } finally {
          loader.hide()
        }
      }

      this.$emit("saved", postValues)
      return true
    },
  },
  created() {
    this.init()
  },
  watch: {
    contructDetail() {
      this.init()
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  text-align: left;
  h3 {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.table {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  border-style: none;
  border-spacing: 0;
  font-size: 0.12rem;
  border: 1px solid #8f8f8f;
  th {
    color: map-get($colors, white);
    background-color: #8f8f8f;
  }
  td {
    padding: 5px 0 10px;
    min-width: 100px;
    > div[name^="pr-"] {
      display: inline-flex;
      //width: auto;
    }
  }
  &_body_tr {
    &:nth-child(even) {
      background-color: map-get($colors, lightlightGray);
    }
    &_td {
      padding: 15px 5px;
    }
  }
}

.img {
  text-align: center;
  > button {
    margin-top: 5px;
  }
}

.buttonWrapper {
  margin-top: 20px;
  text-align: center;
}

.column {
  display: flex;
  justify-content: space-between;
  /* stylelint-disable */
  > div {
    width: 30%;
    > label {
      &[name="pr-select"] {
        width: 150px;
      }
      > span[name="label"] {
        font-size: 0.18rem;
        font-weight: bold;
        color: map-get($colors, black);
      }
    }
  }
}
</style>
