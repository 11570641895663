var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-top": "30px" } }, [
    _c(
      "div",
      { class: _vm.$style.head },
      [
        _c("h3", [_vm._v("出荷伝票")]),
        _vm._v(" "),
        _vm.excelTargets.length != 0
          ? [
              _c(
                "Button",
                {
                  attrs: { size: "main", color: "submit" },
                  on: { click: _vm.handleDownload },
                },
                [_vm._v("\n        ダウンロード\n      ")]
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  attrs: { size: "assist", color: "cancel" },
                  on: { click: _vm.clearFlagExcel },
                },
                [_vm._v("\n        クリア\n      ")]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.inner },
      [
        _vm._l(_vm.excelTargets, function ({ request_number, name }) {
          return [
            _c("p", { key: request_number }, [
              _vm._v(_vm._s(request_number) + ":" + _vm._s(name)),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }