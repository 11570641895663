<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="headers"
    :filter-func="filterFunc"
    page-id="items"
    style="width: 1400px"
  >
    <template slot="page-title"
      >商品一覧</template
    >
    <div slot="create-link" style="display: flex">
      <v-btn @click="handleDownloadCsv" style="align-self: flex-end"
        >CSVダウンロード
      </v-btn>
      <ModalToggler>
        <template slot="button">
          <v-btn style="align-self: flex-end">CSVアップロード</v-btn>
        </template>
        <template slot-scope="{ toggle }">
          <InfoBlock block width="700px" margin="0">
            <template slot="head"
              >CSVアップロード</template
            >
            <div :class="$style.info_row">
              <p :class="$style.info_name" style="width: 30%">CSVファイル</p>
              <div :class="$style.info_content" style="width: 70%">
                <InputFile @change="csvFile = $event" :accept-type="['csv']" />
              </div>
            </div>
            <div style="text-align: center; margin: 30px 0 0">
              <Button
                size="main"
                color="submit"
                @click="handleUploadCsv(toggle)"
                :disabled="csvFile == null"
                >アップロード</Button
              >
              <Button
                size="assist"
                color="cancel"
                @click="
                  csvFile = null
                  toggle()
                "
                >キャンセル</Button
              >
            </div>
          </InfoBlock>
        </template>
      </ModalToggler>
      <Button size="main" color="submit" plus to="/admin/items/new">
        新しい商品を追加する
      </Button>
    </div>

    <div
      slot="filter"
      slot-scope="{
        filter,
        filterClass,
        handleTextInput,
        handleFlagOn,
        handleSelectInput,
      }"
      :class="filterClass"
    >
      <PrSelect
        label="商品ファミリ"
        :pr-row="[100]"
        :value="filter.family"
        :options="familyOptions"
        @input="handleSelectInput($event, 'family')"
      />

      <PrInput
        label="生産個数"
        :pr-row="[100, 85]"
        :value="quantityHandler.value"
        @input="quantityHandler.input"
        @keydown.native.enter="quantityHandler.keydown"
        @keyup.native.enter="
          quantityHandler.keyup($event, handleFlagOn, handleTextInput, filter)
        "
        style="justify-content: space-between"
      >
        <SearchButton
          slot="after"
          @click="quantityHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="quantityHandler.isOn"
        />
      </PrInput>
      <PrInput
        label="キーワード"
        :pr-row="[100, 85]"
        :value="keywordHandler.value"
        @input="keywordHandler.input"
        @keydown.native.enter="keywordHandler.keydown"
        @keyup.native.enter="
          keywordHandler.keyup($event, handleFlagOn, handleTextInput, filter)
        "
        style="justify-content: space-between"
      >
        <SearchButton
          slot="after"
          @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="keywordHandler.isOn"
        />
      </PrInput>
    </div>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import Button from "@/components/atoms/Button.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import InputFile from "@/components/atoms/InputFile.vue"
import {
  getDate,
  routeReplaceError,
  showErrorToast,
  showToast,
} from "@/utils/shared.js"

import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PrInput,
    PrSelect,
    SearchButton,
    Button,
    IndexTableProvider,
    ModalToggler,
    InfoBlock,
    InputFile,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"]("items")
    return {
      items: null,
      keywordHandler: newWordSearchHandler("keyword", filter),
      quantityHandler: newWordSearchHandler("quantity", filter),
      headers: [
        {
          text: "商品名",
          value: "name",
          link: ({ id }) => `/admin/items/${id}`,
          width: 10,
        },
        { text: "型番", value: "model_number", width: 10 },
        { text: "カバー・台紙サイズ", value: "size1", width: 20 },
        { text: "付箋サイズ", value: "size2", width: 12 },
        {
          text: "商品ファミリ",
          value: ({ category1 }) =>
            this.$store.getters["defs/getOptionValueByKey"](
              "ProductFamily",
              category1,
            ),
          width: 14,
        },
        { text: "生産個数", value: "size4", width: 7, sortType: "number" },
        {
          text: "価格",
          value: "price.unit_price",
          width: 4,
          sortType: "number",
        },
        {
          text: "特別価格",
          value: "price.special_unit_price",
          width: 6,
          sortType: "number",
        },
        {
          text: "仕入れ値",
          value: "price.purchase_amount",
          width: 6,
          sortType: "number",
        },
        {
          text: "更新日付",
          value: ({ updated_at }) => getDate(updated_at),
          width: 12,
        },
      ],
      familyOptions: [
        { key: "すべて", value: "すべて" },
        ...this.$store.getters["defs/getOptions"]("ProductFamily", "JP").map(
          f => ({
            ...f,
            key: f.value,
          }),
        ),
      ],
      csvFile: null,
    }
  },
  methods: {
    filterFunc: ({ family, quantity, keyword }) => item => {
      let test = true

      if (family !== "すべて") {
        test = item[4] === family
      }

      if (test && quantity.isOn) {
        test = String(item[5]) === quantity.value
      }

      test = keywordFilter(keyword, test, item)

      return test
    },
    async handleDownloadCsv() {
      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.csv.download(
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        const aTag = document.createElement("a")
        const blob = new Blob([body], { type: "text/csv" })
        aTag.href = URL.createObjectURL(blob)
        aTag.target = "_blank"
        aTag.download = "商品.csv"
        aTag.click()
        URL.revokeObjectURL(aTag.href)
      } finally {
        loader.hide()
      }
    },
    async handleUploadCsv(toggle) {
      const loader = this.$loading.show()
      try {
        let formData = new FormData()
        formData.append("file", this.csvFile)
        const { ok, body, errorMsg, errors } = await api.csv.upload(
          formData,
          this.$store.state.auth.token,
        )
        if (!ok) {
          if (errors) {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              ["登録に失敗しました。", ...errors].join("<br/>"),
              "JP",
              null,
              true,
            )
          } else {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              `登録に失敗しました。${this.csvFile.name}の形式が不正です。`,
              "JP",
              null,
              true,
            )
          }

          return
        }
        await this.initItems()
        showToast(this, "success", "登録しました。", 2000, false)
      } finally {
        loader.hide()
        toggle()
      }
    },
    async initItems() {
      const { ok, body } = await api.get(
        paths.ADMIN_ITEMS,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      await this.initItems()
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>

<style lang="scss" module>
@include InquirySampleOther();
.imgWrapper {
  text-align: center;
}
</style>
