<template>
  <div :class="$style.wrapper">
    <PageTitle>商品CSV一括更新</PageTitle>
    <InfoBlock width="1000px" block>
      <template slot="head"
        >CSVダウンロード</template
      >
      <div :class="$style.filter">
        <PrSelect
          label="商品ファミリ"
          :pr-row="[100]"
          :options="familyOptions"
          v-model="selectedFamily"
        />
        <PrInput
          label="商品名"
          :pr-row="[100, 85]"
          v-model="inputedproductName"
          style="justify-content: space-between"
        />
        <v-btn @click="handleDownloadCsv" style="align-self: flex-end"
          >CSVダウンロード
        </v-btn>
      </div>
    </InfoBlock>
    <InfoBlock width="1000px" block>
      <template slot="head"
        >CSV一括更新</template
      >
      <div style="margin-bottom: 18px">
        <p>商品の以下の項目をCSVで一括更新できます。</p>
        <ul>
          <li>商品名</li>
          <li>型番</li>
          <li>商品ファミリ</li>
          <li>カバー・台紙サイズ</li>
          <li>付箋サイズ</li>
          <li>付箋印刷</li>
          <li>付箋枚数</li>
          <li>生産個数</li>
        </ul>
      </div>
      <div :class="$style.actionButtons">
        <InputFile @change="csvFile = $event" :accept-type="['csv']" />
        <Button
          size="main"
          color="submit"
          @click="handleUploadCsv()"
          :disabled="csvFile == null"
          >CSV一括更新</Button
        >
      </div>
    </InfoBlock>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import Button from "@/components/atoms/Button.vue"
import InputFile from "@/components/atoms/InputFile.vue"
import { showToast, showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PageTitle,
    InfoBlock,
    PrSelect,
    PrInput,
    InputFile,
    Button,
  },
  props: {},
  data() {
    return {
      productName: "",
      familiy: "すべて",
      familyOptions: [
        { key: "すべて", value: "すべて" },
        ...this.$store.getters["defs/getOptions"]("ProductFamily", "JP").map(
          f => ({
            ...f,
          }),
        ),
      ],
      csvFile: null,
    }
  },
  computed: {
    inputedproductName: {
      get() {
        return this.$data.productName
      },
      set(value) {
        this.$data.productName = value
      },
    },
    selectedFamily: {
      get() {
        return this.$data.familiy
      },
      set(value) {
        this.$data.familiy = value
      },
    },
  },
  methods: {
    async handleDownloadCsv() {
      const loader = this.$loading.show()

      const category = this.$data.familiy === "すべて" ? "" : this.$data.familiy
      const name = this.$data.productName

      const params = {
        category,
        name,
      }

      try {
        const { ok, body, errorMsg } = await api.get(
          paths.ADMIN_ITEM_CSV + "?" + new URLSearchParams(params).toString(),
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        const aTag = document.createElement("a")
        const blob = new Blob([body], { type: "text/csv" })
        aTag.href = URL.createObjectURL(blob)
        aTag.target = "_blank"
        aTag.download = "商品.csv"
        aTag.click()
        URL.revokeObjectURL(aTag.href)
      } finally {
        loader.hide()
      }
    },
    async handleUploadCsv() {
      const loader = this.$loading.show()
      try {
        let formData = new FormData()
        formData.append("file", this.csvFile)
        const { ok, body, errorMsg, errors } = await api.postUpload(
          paths.ADMIN_ITEM_CSV,
          formData,
          this.$store.state.auth.token,
        )
        if (ok) {
          showToast(
            this,
            "success",
            body.count + "件の商品を更新しました。",
            2000,
            false,
          )
        } else {
          if (errors) {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              ["登録に失敗しました。", ...errors].join("<br/>"),
              "JP",
              null,
              true,
            )
          } else {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              `登録に失敗しました。<br/>${errorMsg.message}`,
              "JP",
              null,
              true,
            )
          }

          return
        }
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.filter {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.actionButtons {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > label {
    width: 50%;
  }
}
</style>
