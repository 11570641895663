var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OrdersheetProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ values, onChanged, isSupplier, isEdit }) {
          return [
            _c(
              "div",
              { class: _vm.$style.wrapper },
              _vm._l(
                values.contract.contract.contract_details,
                function (detail, index) {
                  return _c(
                    "div",
                    { key: index, class: _vm.$style.container },
                    [
                      _c("section", { class: _vm.$style.section }, [
                        _c("table", { class: _vm.$style.tac }, [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "40%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "40%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  class: _vm.$style.fwb,
                                  attrs: { colspan: "4", valign: "middle" },
                                },
                                [
                                  _c("div", { class: _vm.$style.title }, [
                                    _c("img", {
                                      class: _vm.$style.logo,
                                      attrs: {
                                        src: require("@/images/TN.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", { class: _vm.$style.text }, [
                                      _vm._v(
                                        "\n                      더 네 이 처 발 주 서\n                    "
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [_c("td", { attrs: { colspan: "4" } })]),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("상 호")]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.fwb }, [
                                _vm._v("PR39"),
                              ]),
                              _vm._v(" "),
                              _c("th", [_vm._v("전화번호")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("070-8234-2510")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("담 당 자")]),
                              _vm._v(" "),
                              _c("td", {}, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      values.user ? values.user.name : "미 설정"
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [_vm._v("발 주 일")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrDatePicker", {
                                          attrs: {
                                            name: "request_date5",
                                            validate: "required",
                                            format: "yyyy년 MM월 dd일",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.request_date5,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract,
                                                "request_date5",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.contract.request_date5",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("getDate")(
                                                values.contract.request_date5
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", { attrs: { rowspan: "2" } }, [
                                _vm._v("주 소"),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "일본 후쿠오카시하카타쿠타케시타1-11-23"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [_vm._v("출 고 날")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrDatePicker", {
                                          attrs: {
                                            name: "request_date1",
                                            validate: "required",
                                            format: "yyyy년 MM월 dd일",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: values.product.request_date1,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.product,
                                                "request_date1",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.product.request_date1",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("getDate")(
                                                values.product.request_date1
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td"),
                              _vm._v(" "),
                              _c("th", [_vm._v("배송방법")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("택배 (선불)")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { attrs: { colspan: "4" } }, [
                                _vm._v(
                                  "\n                  * 제품 주문시\n                  "
                                ),
                                _c("span", { class: _vm.$style.fwb }, [
                                  _vm._v("특이사항은"),
                                ]),
                                _vm._v(
                                  " 반드시 주문란에\n                  별도 표기해 주시기 바랍니다.\n                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "12%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "26%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "14%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "14%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "8%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "8%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "8%" } }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            { class: [_vm.$style.bt0, _vm.$style.tac] },
                            [
                              _c("tr", [
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("데이터명"),
                                ]),
                                _vm._v(" "),
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("제품명"),
                                ]),
                                _vm._v(" "),
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("표지사이즈"),
                                ]),
                                _vm._v(" "),
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("내지사이즈"),
                                ]),
                                _vm._v(" "),
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("매수"),
                                ]),
                                _vm._v(" "),
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("인쇄도수"),
                                ]),
                                _vm._v(" "),
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("수량"),
                                ]),
                                _vm._v(" "),
                                _c("th", { class: _vm.$style.bt0 }, [
                                  _vm._v("단가"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrInput", {
                                            attrs: {
                                              type: "text",
                                              name: "description",
                                              validate: "required",
                                              "pr-row": [0, 100],
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value:
                                                values.contract.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  values.contract,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "values.contract.description",
                                            },
                                          }),
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(values.contract.description)
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrSelect", {
                                            attrs: {
                                              name: "category1_" + index,
                                              validate: "required",
                                              options: _vm.productFamilyOptions,
                                              "pr-row": [0, 100],
                                              autoWidth: false,
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value: detail.category1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "category1",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.category1",
                                            },
                                          }),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.toDefsString(
                                                  "ProductFamily",
                                                  detail.category1,
                                                  "KR"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrInput", {
                                            attrs: {
                                              type: "text",
                                              name: "size1_" + index,
                                              "pr-row": [0, 100],
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value: detail.size1,
                                              callback: function ($$v) {
                                                _vm.$set(detail, "size1", $$v)
                                              },
                                              expression: "detail.size1",
                                            },
                                          }),
                                        ]
                                      : [_vm._v(_vm._s(detail.size1))],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrInput", {
                                            attrs: {
                                              type: "text",
                                              name: "size1_" + index,
                                              validate: "required",
                                              "pr-row": [0, 100],
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value: detail.size2,
                                              callback: function ($$v) {
                                                _vm.$set(detail, "size2", $$v)
                                              },
                                              expression: "detail.size2",
                                            },
                                          }),
                                        ]
                                      : [_vm._v(_vm._s(detail.size2))],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrInput", {
                                            attrs: {
                                              type: "text",
                                              name: "size3_" + index,
                                              validate: "required|numeric",
                                              "pr-row": [0, 100],
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value: detail.size3,
                                              callback: function ($$v) {
                                                _vm.$set(detail, "size3", $$v)
                                              },
                                              expression: "detail.size3",
                                            },
                                          }),
                                        ]
                                      : [_vm._v(_vm._s(detail.size3))],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrSelect", {
                                            attrs: {
                                              name: "color_" + index,
                                              validate: "required",
                                              options: _vm.tagPrintOptions,
                                              "pr-row": [0, 100],
                                              autoWidth: false,
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value: detail.color,
                                              callback: function ($$v) {
                                                _vm.$set(detail, "color", $$v)
                                              },
                                              expression: "detail.color",
                                            },
                                          }),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.toDefsString(
                                                  "TagPrint",
                                                  detail.color,
                                                  "KR"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrInput", {
                                            attrs: {
                                              type: "text",
                                              name: "amount_" + index,
                                              validate: "required|numeric",
                                              "pr-row": [0, 100],
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value: detail.amount,
                                              callback: function ($$v) {
                                                _vm.$set(detail, "amount", $$v)
                                              },
                                              expression: "detail.amount",
                                            },
                                          }),
                                        ]
                                      : [_vm._v(_vm._s(detail.amount))],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrInput", {
                                            attrs: {
                                              type: "text",
                                              name: "purchase_amount_" + index,
                                              validate: "required|numeric",
                                              "pr-row": [0, 100],
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value: detail.purchase_amount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "purchase_amount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.purchase_amount",
                                            },
                                          }),
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(detail.purchase_amount)
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  class: [_vm.$style.fwb, _vm.$style.tac],
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _vm._v(
                                    "\n                  기재요망\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { class: [_vm.$style.fwb, _vm.$style.tac] },
                                [_vm._v("포장:OPP봉투")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: [
                                    {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                    _vm.$style.fwb,
                                    _vm.$style.highlight,
                                    _vm.$style.tac,
                                  ],
                                  attrs: { colspan: "6", rowspan: "2" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "note1",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order.description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.description\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            detail.purchase_order.description
                                          )
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                { class: [_vm.$style.fwb, _vm.$style.tac] },
                                [_vm._v("고급품질")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _vm._v("제품받는 "),
                                _c("br"),
                                _vm._v("주 소"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: _vm.$style.tac,
                                  attrs: { colspan: "5" },
                                },
                                [
                                  _vm._v(
                                    "\n                  부산시동구초량동충장대로206 국제여객터미널 탁송화물 검사장\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tac }, [
                                _vm._v("받는사람"),
                              ]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tac }, [
                                _vm._v("김광애"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { attrs: { colspan: "5" } }),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tac }, [
                                _vm._v("전화번호"),
                              ]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tac }, [
                                _vm._v("011-835-8045"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { class: _vm.$style.tac }, [
                                _vm._v("디자인"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: _vm.$style.tal,
                                  attrs: { colspan: "7" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getVerificationImageUrl(
                                        values.request_appendices
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  class: _vm.$style.tac,
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v("은행명")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { class: [_vm.$style.fwb, _vm.$style.tac] },
                                [_vm._v("신한은행")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: _vm.$style.tac,
                                  attrs: { rowspan: "2", colspan: "1" },
                                },
                                [_vm._v("계좌번호")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: [_vm.$style.tac, _vm.$style.highlight],
                                  attrs: { rowspan: "2", colspan: "4" },
                                },
                                [
                                  _vm._v(
                                    "\n                  110-420-498320\n                "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  class: _vm.$style.tac,
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v("예금주")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { class: [_vm.$style.fwb, _vm.$style.tac] },
                                [_vm._v("박미리")]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "♣ 세금계산서 발행시는 사업자등록증 사본 송부요망."
                          ),
                        ]),
                      ]),
                    ]
                  )
                }
              ),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }