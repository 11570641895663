import moment from "moment"
import * as math from "mathjs"
import store from "@/store"

/* eslint-disable */
const today = moment()
  .utc()
  .format("YYYY-MM-DDTHH:mm:ssZ")
const nextMonth = moment()
  .utc()
  .add(3, "months")
  .format("YYYY-MM-DDTHH:mm:ssZ")

const setValues = items =>
  items
    ? {
        request_number: items.contract.request_number,
        request_key1: items.contract.request_key1,
        lock_version: items.contract.lock_version,
        request_key2: items.contract.request_key2, // 見積管理No
        request_name: items.contract.request_name, //見積名
        note1: items.contract.note1, //見積備考
        note4: items.contract.note4, //備考(内部確認用)
        description: items.contract.description, //発注データ名
        request_date5: items.contract.request_date5, //発注日
        request_date1: items.contract.request_date1, //見積日
        quantity2: items.contract.quantity2, //見積公開
        request_key4: items.contract.request_key4, //発注先
        request_date2: items.contract.request_date2, //入校日
        status: items.contract.status, //注文
        quantity3: items.contract.quantity3, //色校正
        contract: {
          lock_version: items.contract.contract.lock_version,
          shipping_fee: items.contract.contract.shipping_fee, //納期日数
          contracted_date: items.contract.contract.contracted_date, //注文日
          expiration_date: items.contract.contract.expiration_date, //有効期限
          contract_details: items.contract.contract.contract_details.map(
            cd => ({
              id: cd.id,
              contract_name: cd.contract_name,
              contract_no: cd.contract_no,
              lock_version: cd.lock_version,
              category1: cd.category1,
              category2: cd.category2,
              item_code: cd.item_code,
              name: cd.name,
              size1: cd.size1,
              size2: cd.size2,
              color: cd.color,
              size3: cd.size3,
              size4: cd.size4,
              amount: cd.amount,
              price: cd.price,
              tax_category: cd.tax_category,
              thumbnail: cd.thumbnail,
              category3: cd.category3, // カバー型抜き
              category4: cd.category4, // 付箋型抜き
              description: cd.description, // 備考
              purchase_amount: cd.purchase_amount, // 仕入額
              merchandise_cost: cd.merchandise_cost, // 輸送費
              weight1: cd.weight1, // 掛け率
              //manufacturer: cd.manufacturer, //見積先
              image_url: cd.image_url,
              delivery_area: cd.delivery_area,
              is_special_unit_price: cd.is_special_unit_price
                ? cd.is_special_unit_price.toString()
                : "0",
            }),
          ),
        },
      }
    : {
        request_number: location.pathname.split("/")[3],
        request_date1: today, // 見積日
        quantity2: "0", // 見積公開
        note1: "", // 見積備考
        note4: "", // 備考(内部確認用)
        request_key4: null, // 発注先
        contract: {
          expiration_date: nextMonth, // 有効期限
          shipping_fee: "", // 納期日祝
          contract_details: [
            "proofreading",
            "design_fee",
            "mount_fee",
            "type_fee",
            "discount",
            "shipping_fee",
            "other_fee",
          ].map(str => ({
            price: "0",
            tax_category: "Default",
            amount: "1",
            category2: str,
          })),
        },
      }
/* eslint-enable */

const getUniqueStr = () => {
  const str =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$%&=~/*-+"
  const len = 8
  let uniStr = ""
  for (var i = 0; i < len; i++) {
    uniStr += str.charAt(Math.floor(Math.random() * str.length))
  }
  return uniStr
}

/*
 * 金額計算大集合！！
 * cd = contract_detail
 * cds = contract_details
 */

const calcCompanyDiscount = (cd, discount) => {
  if (cd.is_special_unit_price === "1") {
    return 0
  }

  const p = math
    .chain(math.bignumber(Number(cd.price)))
    .multiply(math.bignumber(Number(discount)))
    .divide(100)
    .multiply(100)
    .ceil()
    .divide(100)
    .done()
    .toNumber()

  return p
}

// 単価(小数点3桁/四捨五入)
const calcReadyUnitPrice = (cd, discount) => {
  const p = math
    .chain(math.bignumber(Number(cd.price)))
    .subtract(math.bignumber(calcCompanyDiscount(cd, discount)))
    .round(3)
    .done()
    .toNumber()

  return math.isNaN(p) ? "算出不可" : p
}

const calcReadyTotalPrice = (cd, discount) =>
  math
    .chain(math.bignumber(Number(cd.amount)))
    .multiply(math.bignumber(calcReadyUnitPrice(cd, discount)))
    .floor()
    .done()
    .toNumber()

// 単価(小数点3桁/四捨五入)
const calcOrderUnitPrice = cd => {
  if (!cd.weight1 || cd.weight1 === "0") {
    return Number(cd.price)
  } else {
    const p = math
      .chain(math.bignumber(Number(cd.weight1)))
      .multiply(math.bignumber(Number(cd.purchase_amount)))
      .round(3)
      .done()
      .toNumber()
    return math.isNaN(p) ? "算出不可" : p
  }
}

const calcOrderTotalPrice = cd => {
  const p = math
    .chain(math.bignumber(Number(cd.amount)))
    .multiply(math.bignumber(calcOrderUnitPrice(cd)))
    .floor()
    .done()
    .toNumber()

  return math.isNaN(p) ? "算出不可" : p
}

const calcCost = cd => {
  const p = math
    .chain(math.bignumber(Number(cd.purchase_amount)))
    .divide(10)
    .add(math.bignumber(Number(cd.merchandise_cost)))
    .done()
    .toNumber()

  return math.isNaN(p) ? "算出不可" : p
}

const calcGrossProfit = cd => {
  const p = math
    .chain(math.bignumber(calcOrderUnitPrice(cd)))
    .subtract(math.bignumber(calcCost(cd)))
    .multiply(math.bignumber(Number(cd.amount)))
    .multiply(100)
    .floor()
    .divide(100)
    .done()
    .toNumber()

  return math.isNaN(p) ? "算出不可" : p
}

/* eslint-disable */
const calcTotalCompanyDiscount = (cds, discount) =>
  cds.reduce(
    (acc, cd) =>
      cd.category2 === "ready_made"
        ? acc +
          math
            .chain(math.bignumber(Number(cd.price)))
            .multiply(math.bignumber(Number(cd.amount)))
            .floor()
            .subtract(math.bignumber(calcReadyTotalPrice(cd, discount)))
            .done()
            .toNumber()
        : acc,
    0,
  )
/* eslint-enable */

const calcFinalAllTotalPrice = (cds, discount) => {
  return cds.reduce((acc, cd) => {
    let p = 0
    switch (cd.category2) {
      case "ready_made":
        p = calcReadyTotalPrice(cd, discount)
        break
      case "order_made":
        p = calcOrderTotalPrice(cd)
        break
      case "discount":
        p = math
          .chain(math.bignumber(Number(cd.price)))
          .unaryMinus()
          .done()
          .toNumber()
        break
      default:
        p = cd.price * 1
    }
    return acc + p
  }, 0)
}

const calcFinalAllTotalPriceNoDiscount = (cds, discount) => {
  return cds.reduce((acc, cd) => {
    let p = 0
    switch (cd.category2) {
      case "ready_made":
        p = calcReadyTotalPrice(cd, discount)
        break
      case "order_made":
        p = calcOrderTotalPrice(cd)
        break
      case "discount":
        p = 0
        break
      default:
        p = cd.price * 1
    }
    return acc + p
  }, 0)
}

const calcTaxRate = (taxs, date, category) =>
  taxs.find(
    ({ start_datetime, end_datetime, tax_category }) =>
      category === tax_category &&
      moment(date).isBetween(start_datetime, end_datetime, null, "[]"),
  ).tax_rate / 100

const calcFinalAllTotalTax = (cds, discount, taxs, date) => {
  return cds.reduce((acc, cd) => {
    const taxRate = calcTaxRate(taxs, date, cd.tax_category)
    let t = 0
    switch (cd.category2) {
      case "ready_made":
        t = math
          .chain(math.bignumber(calcReadyTotalPrice(cd, discount)))
          .multiply(math.bignumber(Number(taxRate)))
          .floor()
          .done()
          .toNumber()
        break
      case "order_made":
        t = math
          .chain(math.bignumber(calcOrderTotalPrice(cd)))
          .multiply(math.bignumber(Number(taxRate)))
          .floor()
          .done()
          .toNumber()
        break
      case "discount":
        if (cd.category3 == "old") {
          t = 0
        } else {
          // 新値引きロジックの場合は値引き金額の税額を計算する
          t = math
            .chain(math.bignumber(Number(cd.price)))
            .multiply(math.bignumber(Number(taxRate)))
            .multiply(-1)
            .floor()
            .done()
            .toNumber()
        }
        break
      default:
        t = math
          .chain(math.bignumber(Number(cd.price)))
          .multiply(math.bignumber(Number(taxRate)))
          .floor()
          .done()
          .toNumber()
    }
    return acc + Math.floor(t)
  }, 0)
}

const calcFinalAllTotalTaxIncudedPrice = (cds, discount, taxs, date) =>
  calcFinalAllTotalPrice(cds, discount) +
  calcFinalAllTotalTax(cds, discount, taxs, date)

/*
 * 請求書系の金額計算
 */

/**
 * 商品金額計算（企業値引きなし)）
 * 単価×個数（小数点以下切り捨て）
 */
const calcBillingAmountPrice = (price, amount) => {
  return math
    .chain(math.bignumber(Number(price)))
    .multiply(math.bignumber(Number(amount)))
    .floor()
    .done()
    .toNumber()
}

/**
 * 商品金額計算（企業値引きあり）
 * (単価 - 企業値引き) × 個数（小数点以下切り捨て）
 */
const calcBillingAmountDiscountPrice = (price, amount, thumbnail) => {
  return math
    .chain(math.bignumber(Number(price)))
    .subtract(math.bignumber(Number(thumbnail)))
    .multiply(math.bignumber(Number(amount)))
    .floor()
    .done()
    .toNumber()
}

/**
 * 商品（企業値引きあり）の消費税計算
 * (単価 - 企業値引き) × 個数（小数点以下切り捨て）
 */
const calcBillingTaxPrice = (price, amount, thumbnail, tax) => {
  return math
    .chain(
      math.bignumber(calcBillingAmountDiscountPrice(price, amount, thumbnail)),
    )
    .multiply(math.bignumber(Number(tax)))
    .divide(100)
    .floor()
    .done()
    .toNumber()
}

/**
 * 消費税を抜いた金額計算
 */
const calcBillingUnTaxPrice = (price, amount, thumbnail, tax) => {
  return math
    .chain(
      math.bignumber(calcBillingAmountDiscountPrice(price, amount, thumbnail)),
    )
    .divide(math.bignumber(Number(tax)).plus(100))
    .multiply(100)
    .floor()
    .done()
    .toNumber()
}

/**
 * 請求書関連全般の金額計算
 * cds: contract_details
 */
const calcBillingTotalPrice = cds => {
  let [subTotal, taxTotal, subDiscountTotal, discount] = cds.reduce(
    ([subTotal, taxTotal, subDiscountTotal, discount], cd) => {
      const amountPrice = calcBillingAmountPrice(cd.price, cd.amount)
      const discountPrice = calcBillingAmountDiscountPrice(
        cd.price,
        cd.amount,
        cd.thumbnail,
      )
      const taxPrice = calcBillingTaxPrice(
        cd.price,
        cd.amount,
        cd.thumbnail,
        cd.tax.tax_rate,
      )

      switch (cd.category2) {
        case "discount":
          discount = amountPrice + taxPrice
          break
        default:
          subTotal += amountPrice // 商品金額合計
          taxTotal += taxPrice // 消費税合計
          subDiscountTotal += discountPrice // 商品金額合計(企業値引き後)
          break
      }

      return [subTotal, taxTotal, subDiscountTotal, discount]
    },
    [0, 0, 0, 0],
  )

  // 企業値引き合計
  const companyDiscount = subTotal - subDiscountTotal

  // 小計から値引き、企業値引きを引く
  subTotal -= companyDiscount + discount

  // 合計金額 (小計 + 消費税合計)
  let total = subTotal + taxTotal

  return {
    total: total,
    subTotal: subTotal,
    taxTotal: taxTotal,
  }
}

/**
 * 代引き手数料計算
 * isCashDelivery: 1: 代引き, その他: 代引き以外
 */
const calcBillingDeliveryFee = (totalPrice, isCashDelivery) => {
  // 代引き手数料
  let deliveryFee = 0
  if (isCashDelivery == 1) {
    let deliveryFeeFloor = math
      .chain(math.bignumber(Number(totalPrice)))
      .divide(300000)
      .floor()
      .multiply(
        math.bignumber(
          Number(
            store.getters["defs/getOptionValueByKey"]("CashOnDelivery", 4),
          ),
        ),
      )
      .done()
      .toNumber()
    let deliveryFeeModulus = math
      .chain(math.bignumber(Number(totalPrice)))
      .mod(300000)
      .done()
      .toNumber()
    let deliveryFeeTemp = 0
    if (math.equal(deliveryFeeModulus, 0)) {
      deliveryFeeTemp = 0
    } else if (math.smaller(deliveryFeeModulus, 10000)) {
      deliveryFeeTemp = Number(
        store.getters["defs/getOptionValueByKey"]("CashOnDelivery", 1),
      )
    } else if (math.smaller(deliveryFeeModulus, 30000)) {
      deliveryFeeTemp = Number(
        store.getters["defs/getOptionValueByKey"]("CashOnDelivery", 2),
      )
    } else if (math.smaller(deliveryFeeModulus, 100000)) {
      deliveryFeeTemp = Number(
        store.getters["defs/getOptionValueByKey"]("CashOnDelivery", 3),
      )
    } else {
      deliveryFeeTemp = Number(
        store.getters["defs/getOptionValueByKey"]("CashOnDelivery", 4),
      )
    }
    deliveryFee = math
      .chain(deliveryFeeFloor)
      .add(math.bignumber(Number(deliveryFeeTemp)))
      .done()
      .toNumber()
  }
  return deliveryFee
}

export {
  setValues,
  getUniqueStr,
  calcReadyUnitPrice,
  calcReadyTotalPrice,
  calcOrderUnitPrice,
  calcOrderTotalPrice,
  calcCost,
  calcGrossProfit,
  calcTotalCompanyDiscount,
  calcFinalAllTotalPrice,
  calcFinalAllTotalTax,
  calcFinalAllTotalTaxIncudedPrice,
  calcBillingAmountPrice,
  calcBillingAmountDiscountPrice,
  calcBillingTaxPrice,
  calcBillingUnTaxPrice,
  calcBillingTotalPrice,
  calcBillingDeliveryFee,
  calcFinalAllTotalPriceNoDiscount,
}
