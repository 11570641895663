import AdminDefectIndex from "@/components/organisms/admin/AdminDefectIndex.vue"
import AdminDefectDetail from "@/components/organisms/admin/AdminDefectDetail.vue"
import AdminDealingIndex from "@/components/organisms/admin/AdminDealingIndex.vue"
import AdminDealingIndexCard from "@/components/organisms/admin/AdminDealingIndexCard.vue"
import AdminDealingIndexCardPrint from "@/components/organisms/admin/AdminDealingIndexCardPrint.vue"
import AdminDealingPrintTransmittal from "@/components/organisms/admin/AdminDealingPrintTransmittal.vue"
import AdminTaxIndex from "@/components/organisms/admin/AdminTaxIndex.vue"
import AdminTaxDetail from "@/components/organisms/admin/AdminTaxDetail.vue"
import AdminPriceIndex from "@/components/organisms/admin/AdminPriceIndex.vue"
import AdminPriceDetail from "@/components/organisms/admin/AdminPriceDetail.vue"
import AdminSupplierIndex from "@/components/organisms/admin/AdminSupplierIndex.vue"
import AdminSupplierDetail from "@/components/organisms/admin/AdminSupplierDetail.vue"
import AdminCustomerPersonIndex from "@/components/organisms/admin/AdminCustomerPersonIndex.vue"
import AdminCustomerPersonDetail from "@/components/organisms/admin/AdminCustomerPersonDetail.vue"
import AdminCustomerIndex from "@/components/organisms/admin/AdminCustomerIndex.vue"
import AdminCustomerDetail from "@/components/organisms/admin/AdminCustomerDetail.vue"
import AdminItemIndex from "@/components/organisms/admin/AdminItemIndex.vue"
import AdminItemDetail from "@/components/organisms/admin/AdminItemDetail.vue"
import AdminItemCsvUpdate from "@/components/organisms/admin/AdminItemCsvUpdate.vue"
import AdminDealingNew from "@/components/organisms/admin/AdminDealingNew.vue"
import AdminDealingDetail from "@/components/organisms/admin/AdminDealingDetail.vue"
import AdminDealingContractIndex from "@/components/organisms/admin/AdminDealingContractIndex.vue"
import AdminContractIndex from "@/components/organisms/admin/AdminContractIndex.vue"
import AdminContractDetail from "@/components/organisms/admin/AdminContractDetail.vue"
import AdminOMContractIndex from "@/components/organisms/admin/AdminOMContractIndex.vue"
import AdminOMContractDetail from "@/components/organisms/admin/AdminOMContractDetail.vue"
import AdminDealingDelivery from "@/components/organisms/admin/AdminDealingDelivery.vue"
import AdminDealingOrdersheetKY from "@/components/organisms/admin/AdminDealingOrdersheetKY.vue"
import AdminDealingOrdersheetAC from "@/components/organisms/admin/AdminDealingOrdersheetAC.vue"
import AdminDealingOrdersheetTN from "@/components/organisms/admin/AdminDealingOrdersheetTN.vue"
import AdminDealingPrintInvoice from "@/components/organisms/admin/AdminDealingPrintInvoice.vue"
import AdminDealingPrintDelivery from "@/components/organisms/admin/AdminDealingPrintDelivery.vue"
import AdminDealingPrintReceipt from "@/components/organisms/admin/AdminDealingPrintReceipt.vue"
import AdminUserControl from "@/components/organisms/admin/AdminUserControl.vue"
import AdminUserDetail from "@/components/organisms/admin/AdminUserDetail.vue"
import AdminUserIndex from "@/components/organisms/admin/AdminUserIndex.vue"
import AdminWrittenEstimate from "@/components/organisms/admin/AdminWrittenEstimate.vue"
import AdminReport from "@/components/organisms/admin/AdminReport.vue"

const meta = {
  role: ["admin", "operator"],
}

const role = ["admin", "operator"]

const routes = [
  {
    path: "/admin",
    name: "admin",
    component: AdminDealingIndex,
    meta: { title: "商談一覧", role },
  },
  {
    path: "/admin/dealings/card",
    name: "adminDealingIndexCard",
    component: AdminDealingIndexCard,

    meta: { title: "商談一覧", minWidth: 1872, role },
  },
  {
    path: "/admin/dealings/card/print",
    name: "adminDealingIndexCardPrint",
    component: AdminDealingIndexCardPrint,
    meta: { title: "検品情報印刷", role },
  },
  {
    path: "/admin/dealings",
    name: "adminDealingIndex",
    component: AdminDealingIndex,
    meta: { title: "商談一覧", role },
  },
  {
    path: "/admin/dealings/new",
    name: "adminDealingNew",
    component: AdminDealingNew,
    meta: { title: "新規商談", role },
  },
  {
    path: "/admin/dealings/:deal_id",
    name: "adminDealingDetail",
    component: AdminDealingDetail,

    meta: { title: "商談詳細", minWidth: 1460, role },
  },
  {
    path: "/admin/dealings/:deal_id/print-transmittal",
    name: "adminDealingPrintTransmittal",
    component: AdminDealingPrintTransmittal,
    meta: { role, title: "サンプル送付状印刷", noHeader: true },
  },
  {
    path: "/admin/users/new",
    component: AdminUserControl,
    children: [
      {
        path: "",
        name: "adminUserNew",
        component: AdminUserDetail,
        meta: { title: "新規ユーザー", role },
      },
    ],
    meta: meta,
  },
  {
    path: "/admin/users/:user_id",
    component: AdminUserControl,
    children: [
      {
        path: "",
        name: "adminUserDetail",
        component: AdminUserDetail,
        meta: { title: "ユーザー詳細", role },
      },
    ],
    meta: meta,
  },
  {
    path: "/admin/users",
    component: AdminUserControl,
    children: [
      {
        path: "",
        name: "adminUserIndex",
        component: AdminUserIndex,
        meta: { title: "ユーザー一覧", role },
      },
    ],
    meta: meta,
  },
  {
    path: "/admin/defectives",
    name: "adminDefectiveIndex",
    component: AdminDefectIndex,
    meta: { title: "不良品一覧", role },
  },
  {
    path: "/admin/defectives/new/:deal_id",
    name: "adminDefectiveNew",
    component: AdminDefectDetail,
    meta: { title: "新規不良品", role },
  },
  {
    path: "/admin/dealings/:deal_id/defectives/new",
    name: "adminDealingDefectiveNew",
    component: AdminDefectDetail,
    meta: { title: "新規不良品", role },
  },
  {
    path: "/admin/defectives/:defect_id",
    name: "adminDefectiveDetail",
    component: AdminDefectDetail,
    meta: { title: "不良品詳細", role },
  },
  {
    path: "/admin/dealings/:deal_id/defectives/:defect_id",
    name: "adminDealingDefectiveDetail",
    component: AdminDefectDetail,
    meta: { title: "不良品詳細", role },
  },
  {
    path: "/admin/taxes",
    name: "adminTaxIndex",
    component: AdminTaxIndex,
    meta: { title: "税率一覧", role },
  },
  {
    path: "/admin/taxes/new",
    name: "adminTaxNew",
    component: AdminTaxDetail,
    meta: { title: "新規税率", role },
  },
  {
    path: "/admin/taxes/:tax_id",
    name: "adminTaxDetail",
    component: AdminTaxDetail,
    meta: { title: "税率詳細", role },
  },
  {
    path: "/admin/prices",
    name: "adminPriceIndex",
    component: AdminPriceIndex,
    meta: { title: "価格表一覧", role },
  },
  {
    path: "/admin/prices/new",
    name: "adminPriceNew",
    component: AdminPriceDetail,
    meta: { title: "新規価格表", role },
  },
  {
    path: "/admin/prices/:price_id",
    name: "adminPriceDetail",
    component: AdminPriceDetail,
    meta: { title: "価格表詳細", role },
  },
  {
    path: "/admin/suppliers",
    name: "adminSupplierIndex",
    component: AdminSupplierIndex,
    meta: { title: "仕入先一覧", role },
  },
  {
    path: "/admin/suppliers/new",
    name: "adminSupplierNew",
    component: AdminSupplierDetail,
    meta: { title: "新規仕入先", role },
  },
  {
    path: "/admin/suppliers/:supplier_id",
    name: "adminSupplierDetail",
    component: AdminSupplierDetail,
    meta: { title: "仕入先詳細", role },
  },
  {
    path: "/admin/dealings/:deal_id/suppliers/:supplier_id",
    name: "adminDealingsSupplierDetail",
    component: AdminSupplierDetail,
    meta: { title: "仕入先詳細", role },
  },

  {
    path: "/admin/customer-persons",
    name: "adminCustomerPersonIndex",
    component: AdminCustomerPersonIndex,
    meta: { title: "取引先責任者一覧", role },
  },
  {
    path: "/admin/customer-persons/new",
    name: "adminCustomerPersonNew",
    component: AdminCustomerPersonDetail,
    meta: { title: "新規取引先責任者", role },
  },
  {
    path: "/admin/customer-persons/:customer_person_id",
    name: "adminCustomerPersonDetail",
    component: AdminCustomerPersonDetail,
    meta: { title: "取引先責任者詳細", role },
  },
  {
    path: "/admin/dealings/:deal_id/customer-persons/:customer_person_id",
    name: "adminDealingsCustomerPersonDetail",
    component: AdminCustomerPersonDetail,
    meta: { title: "取引先責任者詳細", role },
  },

  {
    path: "/admin/customers",
    name: "adminCustomerIndex",
    component: AdminCustomerIndex,
    meta: { title: "取引先一覧", role },
  },
  {
    path: "/admin/customers/new",
    name: "adminCustomerNew",
    component: AdminCustomerDetail,
    meta: { title: "新規取引先", role },
  },
  {
    path: "/admin/dealings/:dealings_id/customers/:customer_id",
    name: "adminDealingCustomerDetail",
    component: AdminCustomerDetail,
    meta: { title: "取引先詳細", role },
  },
  {
    path: "/admin/customers/:customer_id",
    name: "adminCustomerDetail",
    component: AdminCustomerDetail,
    meta: { title: "取引先詳細", role },
  },
  {
    path: "/admin/items/new",
    name: "adminItemNew",
    component: AdminItemDetail,
    meta: { title: "商品詳細", role },
  },
  {
    path: "/admin/items/:item_id",
    name: "adminItemDetail",
    component: AdminItemDetail,
    meta: { title: "商品詳細", role },
  },
  {
    path: "/admin/items",
    name: "adminItemIndex",
    component: AdminItemIndex,
    meta: { title: "商品一覧", role },
  },
  {
    path: "/admin/items/csv/update",
    name: "adminItemCsvUpdate",
    component: AdminItemCsvUpdate,
    meta: { title: "商品CSV一括更新", role },
  },
  {
    path: "/admin/dealings/:deal_id/dealing-contracts",
    name: "adminDealingContractIndex",
    component: AdminDealingContractIndex,
    meta: { title: "商談見積一覧", role },
  },
  {
    path: "/admin/contracts",
    name: "adminContractIndex",
    component: AdminContractIndex,
    meta: { title: "見積一覧", role },
  },
  {
    path: "/admin/dealings/:deal_id/contracts/new",
    name: "adminContractNew",
    component: AdminContractDetail,

    meta: { title: "新規見積", minWidth: 1600, role },
  },
  {
    path: "/admin/contracts/:contract_id/dealings/:deal_id",
    name: "adminContractDetail",
    component: AdminContractDetail,

    meta: { title: "見積詳細", minWidth: 1600, role },
  },
  {
    path: "/admin/dealings/:deal_id/contracts/:contract_id",
    name: "adminDealingContractDetail",
    component: AdminContractDetail,

    meta: { title: "見積詳細", minWidth: 1600, role },
  },
  {
    path: "/admin/dealings/card/:deal_id/contracts/:contract_id",
    name: "adminDealingCardContractDetail",
    component: AdminContractDetail,

    meta: { title: "見積詳細", minWidth: 1600, role },
  },
  {
    path: "/admin/dealings/:deal_id/dealing-contracts/:contract_id",
    name: "adminDealingDealingContractDetail",
    component: AdminContractDetail,
    meta: { title: "見積詳細", minWidth: 1600, role },
  },
  {
    path: "/admin/om-contracts",
    name: "adminOMContractIndex",
    component: AdminOMContractIndex,

    meta: { title: "オーダーメイド見積一覧", minWidth: 1700, role },
  },
  {
    path: "/admin/om-contracts/:om_contract_id",
    name: "adminOMContractDetail",
    component: AdminOMContractDetail,
    meta: { title: "オーダーメイド見積詳細", minWidth: 1600, role },
  },
  {
    path: "/admin/dealings/:deal_id/delivery",
    name: "adminDealingDelivery",
    component: AdminDealingDelivery,
    meta: { title: "入金配送情報", role },
  },
  {
    path: "/admin/dealings/:deal_id/ordersheet/ky",
    name: "adminDealingOrdersheetKY",
    component: AdminDealingOrdersheetKY,
    meta: { title: "発注書", role },
  },
  {
    path: "/admin/dealings/:deal_id/ordersheet/ac",
    name: "adminDealingOrdersheetAC",
    component: AdminDealingOrdersheetAC,
    meta: { title: "発注書", role },
  },
  {
    path: "/admin/dealings/:deal_id/ordersheet/tn",
    name: "adminDealingOrdersheetTN",
    component: AdminDealingOrdersheetTN,
    meta: { title: "発注書", role },
  },
  {
    path: "/admin/dealings/:deal_id/print-invoice",
    name: "adminDealingPrintInvoice",
    component: AdminDealingPrintInvoice,
    meta: { title: "請求書", role },
  },
  {
    path: "/admin/dealings/:deal_id/print-delivery",
    name: "adminDealingPrintDelivery",
    component: AdminDealingPrintDelivery,
    meta: { title: "納品書", role },
  },
  {
    path: "/admin/dealings/:deal_id/print-receipt",
    name: "adminDealingPrintReceipt",
    component: AdminDealingPrintReceipt,
    meta: { title: "領収書", role },
  },
  {
    path: "/admin/dealings/written-estimate/:deal_id",
    name: "adminWrittenEstimate",
    component: AdminWrittenEstimate,
    meta: { title: "見積書", role },
  },
  {
    path: "/admin/reports",
    name: "adminReports",
    component: AdminReport,
    meta: { title: "実績帳票出力", role },
  },
]

export default routes
