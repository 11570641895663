<template>
  <div v-if="items !== null" :class="$style.wrapper">
    <section :class="$style.section">
      <DealingHeader :deal="items" :users="users" @updated="handleUpdate" />
    </section>
    <section :class="$style.section">
      <DealingPhase :items="items" @updated="handleUpdate" />
    </section>
    <div :class="$style.flex">
      <div :class="$style.flex_left">
        <section :class="$style.section">
          <DealingContract :items="items" @updated="handleUpdate" />
        </section>
        <section :class="$style.section">
          <DealingDelivery :items="items" />
        </section>
        <section :class="$style.section">
          <DealingContactSupplier :items="items" @updated="handleUpdate" />
        </section>
        <section :class="$style.section">
          <DealingContactCustomer :items="items" @updated="handleUpdate" />
        </section>
        <section :class="$style.section">
          <DealingInquiry :items="items" @updated="handleUpdate" />
        </section>
        <section :class="$style.section">
          <DealingSample :items="items" @updated="handleUpdate" />
        </section>
        <section :class="$style.section">
          <DealingOther :items="items" @updated="handleUpdate" />
        </section>
      </div>
      <div :class="$style.flex_right">
        <section :class="$style.history">
          <DealingHistory :items="items" />
        </section>
      </div>
    </div>
    <div :class="$style.flex">
      <section :class="$style.section">
        <DealingDefect :items="items" @updated="handleUpdate" />
      </section>
      <section :class="$style.section">
        <DealingCustomer :items="items" @updated="handleUpdate" />
      </section>
      <section :class="$style.section">
        <DealingSupplier :items="items" />
      </section>
    </div>
  </div>
</template>

<script>
import DealingHeader from "@/components/molecules/DealingHeader.vue"
import DealingPhase from "@/components/molecules/DealingPhase.vue"
import DealingContract from "@/components/molecules/DealingContract.vue"
import DealingDelivery from "@/components/molecules/DealingDelivery.vue"
import DealingContactSupplier from "@/components/molecules/DealingContactSupplier.vue"
import DealingContactCustomer from "@/components/molecules/DealingContactCustomer.vue"
import DealingInquiry from "@/components/molecules/DealingInquiry.vue"
import DealingSample from "@/components/molecules/DealingSample.vue"
import DealingOther from "@/components/molecules/DealingOther.vue"
import DealingDefect from "@/components/molecules/DealingDefect.vue"
import DealingCustomer from "@/components/molecules/DealingCustomer.vue"
import DealingSupplier from "@/components/molecules/DealingSupplier.vue"
import DealingHistory from "@/components/molecules/DealingHistory.vue"
import { routeReplaceError } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    DealingHeader,
    DealingPhase,
    DealingContract,
    DealingDelivery,
    DealingContactSupplier,
    DealingContactCustomer,
    DealingInquiry,
    DealingSample,
    DealingOther,
    DealingHistory,
    DealingDefect,
    DealingCustomer,
    DealingSupplier,
  },
  data() {
    return {
      items: null,
      users: null,
    }
  },
  methods: {
    handleUpdate(deal) {
      console.log("handleUpdate called")
      console.log("update daal ==", deal)
      this.items = deal
    },
  },
  async created() {
    const [
      { ok: iOk, body: iBody },
      { ok: uOk, body: uBody },
    ] = await Promise.all([
      api.get(
        paths.ADMIN_DEALINGS + "/" + this.$route.params.deal_id,
        this.$store.state.auth.token,
      ),
      api.post(
        paths.ADMIN_SEARCH_USERS,
        {
          or: [{ role: "admin" }, { role: "operator" }],
        },
        this.$store.state.auth.token,
      ),
    ])

    if (!iOk) {
      routeReplaceError(this, iBody.slice(-3))
      return
    }
    if (!uOk) {
      routeReplaceError(this, uBody.slice(-3))
      return
    }

    this.users = uBody
    this.items = iBody
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 1460px;
  margin: 20px auto;
}

.section {
  margin-bottom: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  &_left {
    width: 1115px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &_right {
    width: 330px;
  }
}
</style>
