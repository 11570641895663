<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template slot="activator" slot-scope="{ on }">
      <span><slot :on="on"/></span>
    </template>
    <v-card>
      <v-card-title class="headline">{{ texts.ttl }}</v-card-title>
      <v-card-text>{{ texts.msg }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat @click="handleYesClick">
          {{ texts.yas }}
        </v-btn>
        <v-btn color="green darken-1" flat @click="handleNoClick">
          {{ texts.no }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      required: true,
    },
    noClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    handleYesClick() {
      if (!this.noClose) {
        this.dialog = false
      }
      this.$emit("accept")
    },
    handleNoClick() {
      this.dialog = false
      this.$emit("refuse")
    },
  },
}
</script>
