var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "320px", margin: "0", center: "", block: "" } },
    [
      _c("template", { slot: "head" }, [
        _vm._v("\n    お客様（注文者）ご住所\n  "),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.wrapper }, [
        _c("p", [_vm._v("郵便番号")]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.deal.shipping.order_address1.zip_code))]),
        _vm._v(" "),
        _c("p", [_vm._v("住所")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " + _vm._s(_vm.deal.shipping.order_address1.location)
          ),
          _c("br"),
          _vm._v(
            "\n      " + _vm._s(_vm.deal.shipping.order_address1.address1)
          ),
          _c("br"),
          _vm._v(
            "\n      " + _vm._s(_vm.deal.shipping.order_address1.address2)
          ),
          _c("br"),
          _vm._v(
            "\n      " +
              _vm._s(_vm.deal.shipping.order_address2.location) +
              "\n    "
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }