var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { class: _vm.$style.select }, [
    _c(
      "select",
      {
        style: { background: _vm.background },
        domProps: { value: Number(_vm.value) },
        on: { change: (e) => _vm.$emit("input", e.currentTarget.value) },
      },
      _vm._l(_vm.options, function ({ key, value }, i) {
        return _c("option", { key: i, domProps: { value: Number(key) } }, [
          _vm._v(_vm._s(value)),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("span"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }