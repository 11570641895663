<template>
  <InfoBlock width="320px" margin="0" center block>
    <template slot="head">
      入金・配送・納品情報
    </template>
    <div :class="$style.wrapper">
      <p>お支払い情報</p>
      <p>
        {{
          $store.getters["defs/getOptionValueByKey"](
            "PaymentMethod",
            deal.contract.quantity4,
          )
        }}
      </p>
      <p>入金予定日</p>
      <p>{{ deal.contract.request_date3 | getDate("指定なし") }}</p>
      <p>納品希望日</p>
      <p>{{ deal.request_date2 | getDate("指定なし") }}</p>
      <p>受取可能曜日</p>
      <p>{{ deal.delivery.note1 || "指定なし" }}</p>
      <h3>お届け先情報</h3>
      <p>お届け先</p>
      <p>
        {{ deal.shipping.status == 0 ? "注文者と同じ" : "指定住所へ配送" }}
      </p>
      <template v-if="deal.shipping.status == 1">
        <p>会社名</p>
        <p>{{ deal.shipping.delivery_address2.address1 }}</p>
        <p>部署名</p>
        <p>{{ deal.shipping.delivery_address2.address2 }}</p>
        <p>お届け先名</p>
        <p>{{ deal.shipping.delivery_address3.address1 }}</p>
        <p>郵便番号</p>
        <p>{{ deal.shipping.delivery_address1.zip_code }}</p>
        <p>住所</p>
        <p>
          {{ deal.shipping.delivery_address1.location }}<br />
          {{ deal.shipping.delivery_address1.address1 }}<br />
          {{ deal.shipping.delivery_address1.address2 }}<br />
          {{ deal.shipping.delivery_address2.location }}
        </p>
        <p>電話番号</p>
        <p>{{ deal.shipping.delivery_address3.address2 }}</p>
      </template>

      <h3>送り主情報</h3>
      <p>送り主変更<br />オプション</p>
      <p>{{ deal.delivery.quantity2 == 0 ? "なし" : "変更する" }}</p>
      <template v-if="deal.delivery.quantity2 == 0">
        <p>送り主</p>
        <p>PR39</p>
      </template>
      <template v-else>
        <p>会社名</p>
        <p>{{ deal.shipping.sender_address2.address1 }}</p>
        <p>部署名</p>
        <p>{{ deal.shipping.sender_address2.address2 }}</p>
        <p>お届け先名</p>
        <p>{{ deal.shipping.sender_address3.address1 }}</p>
        <p>郵便番号</p>
        <p>{{ deal.shipping.sender_address1.zip_code }}</p>
        <p>住所</p>
        <p>
          {{ deal.shipping.sender_address1.location }}<br />
          {{ deal.shipping.sender_address1.address1 }}<br />
          {{ deal.shipping.sender_address1.address2 }}<br />
          {{ deal.shipping.sender_address2.location }}
        </p>
        <p>電話番号</p>
        <p>{{ deal.shipping.sender_address3.address2 }}</p>
      </template>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import { getDate } from "@/utils/shared.js"

export default {
  components: {
    InfoBlock,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  filters: {
    getDate,
  },
}
</script>

<style lang="scss" module>
@include clientParts();
</style>
