<template>
  <div v-if="items !== null" :class="$style.wrapper">
    <PageTitle>注文履歴詳細</PageTitle>
    <p :class="$style.userName">
      {{
        items.customer_person.last_name +
          " " +
          items.customer_person.first_name +
          " "
      }}
      様
    </p>
    <CustomerProductInfo :deal="items" :class-name="$style.inner" />
    <CustomerOrderAddress :deal="items" :class-name="$style.inner" />
    <CustomerPayment :deal="items" :class-name="$style.inner" />
    <CustomerOther :deal="items" :class-name="$style.inner" />
    <Button
      size="main"
      color="submit"
      style="margin:20px 0 50px"
      @click="
        $router.push(
          '/customer/ordered-histories/' + items.request_number + '/re-order',
        )
      "
      >再注文する</Button
    >
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import CustomerProductInfo from "@/components/molecules/CustomerProductInfo.vue"
import CustomerOrderAddress from "@/components/molecules/CustomerOrderAddress.vue"
import CustomerPayment from "@/components/molecules/CustomerPayment.vue"
import CustomerOther from "@/components/molecules/CustomerOther.vue"
import { routeReplaceError } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PageTitle,
    CustomerPayment,
    CustomerProductInfo,
    CustomerOrderAddress,
    CustomerOther,
    Button,
  },
  data() {
    return {
      items: null,
    }
  },
  async created() {
    const { ok, body } = await api.get(
      paths.CUSTOMER_ORDERED_HISTORIES + "/" + this.$route.params.deal_id,
      this.$store.state.auth.token,
    )
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }
    this.items = body
    this.$store.dispatch("customer/setReOrderInfo", null)
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.userName {
  margin-bottom: 20px;
  padding: 5px 10px;
  background-color: map-get($colors, orange);
}
.inner {
  padding: 1em 0 1em 1em;
  border-bottom: 1px dotted map-get($colors, darkDarkGray);
  display: flex;
  flex-wrap: wrap;
  &:last-child {
    border-bottom: none;
  }
  > h3 {
    margin: 10px 0 5px -1em;
    font-size: 0.18rem;
    width: 100%;
  }
  > p {
    margin: 5px 0;
    &:nth-of-type(odd) {
      width: 45%;
      color: map-get($colors, darkDarkGray);
    }
    &:nth-of-type(even) {
      width: 55%;
    }
  }
}
</style>
