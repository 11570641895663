<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="headers"
    :filter-func="filterFunc"
    page-id="customers"
  >
    <template slot="page-title">
      取引先一覧
    </template>

    <Button
      slot="create-link"
      size="main"
      color="submit"
      plus
      to="/admin/customers/new"
    >
      新しい取引先を追加する
    </Button>

    <div
      slot="filter"
      slot-scope="{
        filter,
        updateFilter,
        handleTextInput,
        handleFlagOn,
        handleSelectInput,
        filterClass,
      }"
      :class="filterClass"
    >
      <PrSelect
        label="業種"
        :pr-row="[100]"
        :value="filter.industory"
        :options="industoryOptions"
        @input="handleSelectInput($event, 'industory')"
      />
      <div :class="$style.dates">
        <PrDatePicker
          label="最終注文日"
          :pr-row="[100]"
          :value="filter.orderDate.start"
          :clearable="true"
          @input="handleDatePick($event, 'start', filter, updateFilter)"
          :disabled-dates="
            filter.orderDate.end === null
              ? {}
              : { from: new Date(filter.orderDate.end) }
          "
          :open-date="
            filter.orderDate.start !== null
              ? new Date(filter.orderDate.start)
              : filter.orderDate.end
              ? new Date(filter.orderDate.end)
              : new Date()
          "
        />
        <span>〜</span>
        <PrDatePicker
          :value="filter.orderDate.end"
          :clearable="true"
          @input="handleDatePick($event, 'end', filter, updateFilter)"
          :disabled-dates="
            filter.orderDate.start === null
              ? {}
              : { to: new Date(filter.orderDate.start) }
          "
          :open-date="
            filter.orderDate.end !== null
              ? new Date(filter.orderDate.end)
              : filter.orderDate.start
              ? new Date(filter.orderDate.start)
              : new Date()
          "
        />
      </div>
      <PrInput
        label="キーワード"
        :pr-row="[100, 85]"
        :value="keywordHandler.value"
        @input="keywordHandler.input"
        @keydown.native.enter="keywordHandler.keydown"
        @keyup.native.enter="
          keywordHandler.keyup($event, handleFlagOn, handleTextInput, filter)
        "
        style="justify-content:space-between;"
      >
        <SearchButton
          slot="after"
          @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="keywordHandler.isOn"
        />
      </PrInput>
    </div>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import Button from "@/components/atoms/Button.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import { getDate, routeReplaceError, deepCopy } from "@/utils/shared.js"
import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"

export default {
  components: {
    Button,
    PrInput,
    PrSelect,
    PrDatePicker,
    SearchButton,
    IndexTableProvider,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"]("customers")
    return {
      items: null,
      keywordHandler: newWordSearchHandler("keyword", filter),
      headers: [
        {
          text: "取引先名",
          value: "organization.name",
          link: ({ id }) => `/admin/customers/${id}`,
          width: 29,
        },
        { text: "略称", value: "abbreviated_name", width: 16 },
        {
          text: "都道府県",
          value: ({ organization: { addresses } }) =>
            addresses.find(({ subject }) => subject === "customer").location,
          width: 10,
        },
        { text: "電話番号", value: "organization.phone_number", width: 11 },
        {
          text: "業種",
          value: ({ industry }) =>
            this.$store.getters["defs/getOptionValueByKey"](
              "Industry",
              industry,
            ),
          width: 12,
        },
        {
          text: "最終注文日",
          value: ({ last_order_date }) => getDate(last_order_date),
          width: 11,
        },
        {
          text: "更新日付",
          value: ({ updated_at }) => getDate(updated_at),
          width: 11,
        },
      ],
      industoryOptions: [
        { key: "すべて", value: "すべて" },
        ...this.$store.getters["defs/getOptions"]("Industry").map(i => ({
          ...i,
          key: i.value,
        })),
      ],
    }
  },
  methods: {
    filterFunc: ({ industory, orderDate, keyword }) => item => {
      let test = true

      if (industory !== "すべて") {
        test = item[4] === industory
      }

      const isStart = orderDate.start !== null
      const isEnd = orderDate.end !== null
      if (test && (isStart || isEnd)) {
        if (item[5] === "未設定") {
          test = false
        } else if (isStart && !isEnd) {
          test = moment(item[5], "YYYY/MM/DD").isSameOrAfter(
            orderDate.start,
            "day",
          )
        } else if (!isStart && isEnd) {
          test = moment(item[5], "YYYY/MM/DD").isSameOrBefore(
            orderDate.end,
            "day",
          )
        } else {
          test = moment(item[5], "YYYY/MM/DD").isBetween(
            orderDate.start,
            orderDate.end,
            "day",
            [],
          )
        }
      }

      test = keywordFilter(keyword, test, item)

      return test
    },
    handleDatePick(e, key, filter, updateFilter) {
      const newFilter = deepCopy(filter)
      newFilter.orderDate[key] = e
      updateFilter(newFilter)
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const { ok, body } = await api.get(
        paths.ADMIN_CUSTOMERS,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>

<style lang="scss" module>
.dates {
  width: 350px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  > div[name="pr-datepicker"] {
    width: 160px;
  }
  > span {
    margin-bottom: 5px;
    font-weight: bold;
  }
}
</style>
