const getValueByKeyString = (item, path) => {
  const arr = path.split(".")
  try {
    if (arr.length === 1) {
      return item[arr[0]]
    }
    if (arr.length === 2) {
      return item[arr[0]][arr[1]]
    }
    if (arr.length === 3) {
      return item[arr[0]][arr[1]][arr[2]]
    }
  } catch {
    return ""
  }
}

const keywordFilter = (keyword, test, item) => {
  if (test && keyword.isOn) {
    return item.reduce((acc, val) => {
      if (val.includes("#link#")) {
        val = val.split("#link#")[0]
      }
      return acc || val.includes(keyword.value)
    }, false)
  }
  return test
}

const newWordSearchHandler = (key, filter) => {
  let keydownCode = 0
  const h = { isOn: filter[key].isOn, value: filter[key].value }

  h.input = e => {
    h.value = e
    if (h.isOn) {
      h.isOn = false
    }
  }

  h.click = (handleFlagOn, handleTextInput, f) => {
    if (!h.isOn) {
      handleTextInput(h.value, key)
      h.isOn = true
      if (!f[key].isOn) {
        handleFlagOn(key)
      }
    } else {
      h.value = ""
      h.isOn = false
      if (f[key].isOn) {
        handleFlagOn(key)
      }
    }
  }

  h.keydown = e => {
    console.log("keydown", e.which)
    keydownCode = e.which
  }

  h.keyup = (e, handleFlagOn, handleTextInput, f) => {
    console.log("keyup =", e.which, "keydownCode =", keydownCode)
    console.log(handleFlagOn, handleTextInput, f)
    if (e.which !== keydownCode || h.isOn) {
      return
    }
    h.click(handleFlagOn, handleTextInput, f)
  }

  return h
}

export { getValueByKeyString, keywordFilter, newWordSearchHandler }
