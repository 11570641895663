<template>
  <InfoBlock width="100%" block margin="0">
    <template slot="head">
      商品情報
    </template>
    <div :class="$style.row">
      <PrInput
        label="注文数"
        :pr-row="[30]"
        name="product.quantity1"
        :value="value.product.quantity1"
        @input="updateValue($event, 'product.quantity1')"
        validate="numeric"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="予備注文数"
        name="product.quantity2"
        :pr-row="[30]"
        :value="value.product.quantity2"
        @input="updateValue($event, 'product.quantity2')"
        validate="numeric"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="出庫商品総数"
        :pr-row="[30]"
        name="product.quantity4"
        :value="value.product.quantity4"
        @input="updateValue($event, 'product.quantity4')"
        validate="numeric"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="出庫箱数"
        :pr-row="[30]"
        name="product.quantity3"
        :value="value.product.quantity3"
        @input="updateValue($event, 'product.quantity3')"
        validate="numeric"
      />
    </div>
    <div :class="$style.row">
      <PrDatePicker
        label="出庫予定日"
        :pr-row="[30]"
        :value="value.product.request_date1"
        @input="updateValue($event, 'product.request_date1')"
      />
    </div>
    <div :class="$style.row">
      <PrDatePicker
        label="港受取日"
        :pr-row="[30]"
        :value="value.product.request_date2"
        @input="updateValue($event, 'product.request_date2')"
      />
    </div>
    <div :class="$style.row">
      <PrDatePicker
        label="検品日"
        :pr-row="[30]"
        :value="value.product.request_date3"
        @input="updateValue($event, 'product.request_date3')"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="保管商品個数"
        :pr-row="[30]"
        name="product.quantity6"
        :value="value.product.quantity6"
        @input="updateValue($event, 'product.quantity6')"
        validate="numeric"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="到着商品内容"
        :pr-row="[30]"
        name="product.description"
        :value="value.product.description"
        @input="updateValue($event, 'product.description')"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="発送箱数"
        :pr-row="[30]"
        name="product.quantity5"
        :value="value.product.quantity5"
        @input="updateValue($event, 'product.quantity5')"
        validate="numeric"
      />
    </div>
    <div :class="$style.row">
      <p :class="$style.distribution_item_info_row_name">
        発送商品総数
      </p>
      <div :class="$style.distribution_item_info_row_txt">
        {{ (value.product.quantity4 - value.product.quantity6) | checkNaN }}
      </div>
    </div>
    <div :class="$style.row">
      <PrCheckbox
        before-label="内職"
        :pr-row="[30]"
        :value="value.product.status"
        @input="updateValue($event, 'product.status')"
      />
    </div>
    <div :class="$style.row">
      <PrTextarea
        label="備考"
        :pr-row="[30]"
        :value="value.product.note2"
        @input="updateValue($event, 'product.note2')"
      />
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import { deepCopy, checkNaN } from "@/utils/shared.js"

export default {
  inject: ["$validator"],
  components: {
    PrTextarea,
    PrDatePicker,
    PrInput,
    PrCheckbox,
    InfoBlock,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  filters: {
    checkNaN,
  },
  methods: {
    updateValue(val, keys) {
      const arr = keys.split(".")
      const newValue = deepCopy(this.value)
      if (arr.length === 1) {
        newValue[arr[0]] = val
      }
      if (arr.length === 2) {
        newValue[arr[0]][arr[1]] = val
      }
      this.$emit("input", newValue)
    },
  },
}
</script>

<style lang="scss" module>
.row {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  &:nth-of-type(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  > div[name^="pr"] {
    width: 100%;
  }
  > p {
    &:nth-of-type(1) {
      color: map-get($colors, darkGlay);
      width: 30%;
    }
  }
}
</style>
