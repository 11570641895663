<template>
  <div :class="$style.wrapper" name="pr-datepicker">
    <span name="label" v-if="label" :style="labelStyle">{{ label }}</span>
    <div :style="datepickerStyle">
      <Datepicker
        v-if="validate"
        v-bind="$attrs"
        v-validate="validate"
        :value="date"
        :name="name"
        :input-class="$style.input"
        :language="ja"
        :format="format"
        :highlighted="{ dates: [new Date()] }"
        @input="$emit('input', $event)"
      />
      <Datepicker
        v-else
        v-bind="$attrs"
        :value="date"
        :input-class="$style.input"
        :language="ja"
        :format="format"
        :highlighted="{ dates: [new Date()] }"
        @input="$emit('input', $event)"
      />
      <v-icon
        :class="$style.iconCalendar"
        v-if="
          !clearable || value === '' || value === null || value === undefined
        "
        >event</v-icon
      >
      <v-icon v-else @click="$emit('input', clearOutput || null)">clear</v-icon>
    </div>
    <slot name="after" />
    <template v-if="validate">
      <PrErrorText v-show="errors.first(name)">
        {{ errors.first(name) }}
      </PrErrorText>
    </template>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import { ja } from "vuejs-datepicker/dist/locale"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import moment from "moment"

export default {
  inject: ["$validator"],
  inheritAttrs: false,
  components: {
    Datepicker,
    PrErrorText,
  },
  data() {
    return { ja }
  },
  props: {
    value: {
      required: true,
    },
    label: String,
    name: String,
    validate: String,
    clearable: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "yyyy/MM/dd",
    },
    clearOutput: null,
    prRow: {
      type: Array,
      default() {
        return [null, null]
      },
    },
  },
  computed: {
    date() {
      return moment(this.value).format("YYYY/MM/DD")
    },
    labelStyle() {
      const { prRow } = this
      return {
        width: prRow[0] ? `${prRow[0]}%` : "auto",
      }
    },
    datepickerStyle() {
      const { prRow } = this
      return {
        width: prRow[1] ? `${prRow[1]}%` : "auto",
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    display: inline-block;
    position: relative;
    > i {
      position: absolute;
      top: 0.25em;
      right: 0.2em;
      font-size: 1.65em;
    }
  }
  > span {
    &[name="label"] {
      display: inline-block;
      margin-bottom: 0.2em;
      color: map-get($colors, darkGlay);
    }
  }
  &.default {
    display: grid;
  }
}
.input {
  cursor: pointer;
  width: 100%;
  padding: 0.3em 0.5em;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 3px;
  background-color: #fff;
  outline: none;
  transition: 0.3s;
  &:focus {
    background-color: #fcfade;
    border-color: #00836b;
    box-shadow: 0 0 3px #00836b;
  }
  &:disabled {
    background-color: #eee !important;
    border-color: #ccc !important;
    box-shadow: none !important;
  }
}
.iconCalendar {
  pointer-events: none;
}
</style>
