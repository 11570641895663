<template>
  <InfoBlock width="320px" margin="0" center block>
    <template slot="head">
      その他
    </template>
    <div :class="$style.wrapper">
      <p>備考</p>
      <p style="white-space:pre-wrap;">{{ deal.contract.note1 }}</p>
      <template v-if="imagePath">
        <template v-if="deal.reusable">
          <p>印刷データ</p>
          <p>{{ deal.reusable === "1" ? "前回と同じ" : "新しく入稿する" }}</p>
          <template v-if="deal.reusable === '1'">
            <p>最終確認画像</p>
            <span><img :src="imagePath"/></span>
          </template>
        </template>
        <template v-else>
          <p>最終確認画像</p>
          <span><img :src="imagePath"/></span>
        </template>
      </template>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"

export default {
  components: {
    InfoBlock,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
    imagePath: String,
  },
}
</script>

<style lang="scss" module>
@include clientParts();
</style>
