<template>
  <InfoBlock block width="48%" margin="0">
    <template slot="head">
      総額
    </template>
    <div :class="$style.row">
      <p>注文総額</p>
      <p>
        {{
          values.contract.contract_details
            | calcFinalAllTotalPriceNoDiscount(discount)
            | formatPrice
        }}
      </p>
      <PrErrorText v-if="isTotalMinus"
        >注文総額を0円未満に設定することは出来ません。</PrErrorText
      >
    </div>
    <div :class="$style.row">
      <p>値引き</p>
      <p>
        {{
          values.contract.contract_details.find(
            ({ category2 }) => category2 == "discount",
          ).price | formatPrice
        }}
      </p>
    </div>
    <div :class="$style.row">
      <p>消費税</p>
      <p>
        {{
          values.contract.contract_details
            | calcFinalAllTotalTax(discount, taxs, values.request_date1)
            | formatPrice
        }}
      </p>
    </div>
    <div :class="$style.row">
      <p>注文総額（税込）</p>
      <p>
        {{
          values.contract.contract_details
            | calcFinalAllTotalTaxIncudedPrice(
              discount,
              taxs,
              values.request_date1,
            )
            | formatPrice
        }}
      </p>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import {
  calcFinalAllTotalPrice,
  calcFinalAllTotalTax,
  calcFinalAllTotalTaxIncudedPrice,
  calcFinalAllTotalPriceNoDiscount,
} from "@/helpers/QuotationDetailViewHelper.js"
import { formatPrice } from "@/utils/shared.js"

export default {
  components: {
    InfoBlock,
    PrErrorText,
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    taxs: {
      type: Array,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    isTotalMinus: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    formatPrice,
    calcFinalAllTotalPrice,
    calcFinalAllTotalTax,
    calcFinalAllTotalTaxIncudedPrice,
    calcFinalAllTotalPriceNoDiscount,
  },
}
</script>

<style lang="scss" module>
.row {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  > p:first-child {
    width: 25%;
    color: map-get($colors, darkGlay);
  }
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
}
</style>
