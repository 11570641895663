var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { block: "", width: "1115px", margin: "0" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    その他情報\n  ")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "head-right" },
        [
          _c(
            "ModalToggler",
            {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ toggle }) {
                    return [
                      _c(
                        "InfoBlock",
                        { attrs: { block: "", width: "700px" } },
                        [
                          _c("template", { slot: "head" }, [
                            _vm._v("その他情報編集"),
                          ]),
                          _vm._v(" "),
                          _vm.items.contract.some(({ status }) => status === 1)
                            ? _c(
                                "div",
                                { class: _vm.$style.info_row },
                                [
                                  _c("PrSelect", {
                                    attrs: {
                                      label: "公開情報",
                                      name: "note2",
                                      "pr-row": [30],
                                      options:
                                        _vm.$store.getters["defs/getOptions"](
                                          "PublicInformation"
                                        ),
                                      validate: "required",
                                    },
                                    model: {
                                      value: _vm.values.contract.note2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.values.contract,
                                          "note2",
                                          $$v
                                        )
                                      },
                                      expression: "values.contract.note2",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.items.contract.some(({ status }) => status === 1)
                            ? _c(
                                "div",
                                { class: _vm.$style.info_row },
                                [
                                  _c("PrTextarea", {
                                    attrs: {
                                      name: "contract.note2",
                                      label: "公開情報その他",
                                      "pr-row": [30],
                                    },
                                    model: {
                                      value: _vm.values.contract.note3,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.values.contract,
                                          "note3",
                                          $$v
                                        )
                                      },
                                      expression: "values.contract.note3",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [_vm.$style.info_row, _vm.$style.accsecc],
                            },
                            [
                              _c("p", [_vm._v("アクセス経路")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                _vm._l(
                                  [
                                    "ネット検索",
                                    "ネット広告",
                                    "ダイレクトメール",
                                    "展示会",
                                    "知人の紹介",
                                    "過去に注文したことがある",
                                    "その他",
                                  ],
                                  function (label, i) {
                                    return _c("PrCheckbox", {
                                      key: label,
                                      attrs: { "before-label": label },
                                      model: {
                                        value: _vm.accsecc[i],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.accsecc, i, $$v)
                                        },
                                        expression: "accsecc[i]",
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: _vm.$style.info_row },
                            [
                              _c("PrTextarea", {
                                attrs: {
                                  label: "アクセス経路その他",
                                  "pr-row": [30],
                                },
                                model: {
                                  value: _vm.values.note3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.values, "note3", $$v)
                                  },
                                  expression: "values.note3",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: _vm.$style.info_row },
                            [
                              _c("PrTextarea", {
                                attrs: { label: "備考", "pr-row": [30] },
                                model: {
                                  value: _vm.values.note1,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.values, "note1", $$v)
                                  },
                                  expression: "values.note1",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                margin: "30px 0 0",
                              },
                            },
                            [
                              _c(
                                "Button",
                                {
                                  attrs: { size: "main", color: "submit" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSaveClick(toggle)
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                              _vm._v(" "),
                              _c(
                                "Button",
                                {
                                  attrs: { size: "assist", color: "cancel" },
                                  on: {
                                    click: () => {
                                      _vm.initValues()
                                      toggle()
                                    },
                                  },
                                },
                                [_vm._v("キャンセル")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "button" },
                [
                  _c(
                    "v-btn",
                    { attrs: { flat: "", icon: "", color: "indigo" } },
                    [_c("v-icon", [_vm._v("edit")])],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.container }, [
        _c("div", { class: _vm.$style.info }, [
          _vm.items.contract.some(({ status }) => status === 1)
            ? _c("div", { class: _vm.$style.info_row }, [
                _c("p", { class: _vm.$style.info_name }, [_vm._v("公開情報")]),
                _vm._v(" "),
                _c("p", { class: _vm.$style.info_content }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.items.contract.find(({ status }) => status === 1)
                          .note2
                      ) +
                      "\n        "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.items.contract.some(({ status }) => status === 1)
            ? _c("div", { class: _vm.$style.info_row }, [
                _c("p", { class: _vm.$style.info_name }, [
                  _vm._v("公開情報その他"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { class: [_vm.$style.info_content, _vm.$style.preWrap] },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.items.contract.find(({ status }) => status === 1)
                          .note3
                      )
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { class: _vm.$style.info_row }, [
            _c("p", { class: _vm.$style.info_name }, [_vm._v("アクセス経路")]),
            _vm._v(" "),
            _c("p", { class: _vm.$style.info_content }, [
              _vm._v("\n          " + _vm._s(_vm.items.note2) + "\n        "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.info_row }, [
            _c("p", { class: _vm.$style.info_name }, [
              _vm._v("アクセス経路その他"),
            ]),
            _vm._v(" "),
            _c("p", { class: [_vm.$style.info_content, _vm.$style.preWrap] }, [
              _vm._v(_vm._s(_vm.items.note3)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.info_row }, [
            _c("p", { class: _vm.$style.info_name }, [_vm._v("備考")]),
            _vm._v(" "),
            _c("p", { class: [_vm.$style.info_content, _vm.$style.preWrap] }, [
              _vm._v(_vm._s(_vm.items.note1)),
            ]),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }