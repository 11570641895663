var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.wrapper },
    [
      _c("PageTitle", [_vm._v("パスワード再発行")]),
      _vm._v(" "),
      _c("div", [
        _vm.validMessage
          ? _c("p", { class: _vm.$style.message_error }, [
              _vm._v("\n      " + _vm._s(_vm.validMessage) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            class: _vm.$style.formAria,
            attrs: { novalidate: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleExec.apply(null, arguments)
              },
            },
          },
          [
            _c("p", { class: _vm.$style.desc }, [
              _vm._v(
                "\n        登録されているメールアドレスをご入力ください。\n        "
              ),
              _c("br"),
              _vm._v("パスワード変更用のURLを自動送信します。\n      "),
            ]),
            _vm._v(" "),
            _c(
              "InputForm",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                ],
                class: _vm.$style.form,
                attrs: { name: "email", type: "email" },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              },
              [_vm._v("メールアドレス")]
            ),
            _vm._v(" "),
            _c(
              "Button",
              { attrs: { type: "submit", color: "submit", size: "main" } },
              [_vm._v("送信")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }