var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.values !== null
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("DealingHeader", { attrs: { headerInfo: _vm.headerInfo } }),
          _vm._v(" "),
          _c("PageTitle", [_vm._v("見積・注文管理")]),
          _vm._v(" "),
          _c(
            "AdminContractDetailOutLine",
            _vm._b(
              {
                attrs: { deal: _vm.items },
                on: { input: _vm.watchIsEdited },
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              },
              "AdminContractDetailOutLine",
              { supplierOptions: _vm.supplierOptions },
              false
            )
          ),
          _vm._v(" "),
          _c(
            "AdminContractDetailMade",
            _vm._b(
              {
                on: { input: _vm.watchIsEdited },
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              },
              "AdminContractDetailMade",
              { productGroup: _vm.productGroup, discount: _vm.discount },
              false
            )
          ),
          _vm._v(" "),
          _vm.isSaveTried && !_vm.isIncludesMade
            ? _c(
                "PrErrorText",
                {
                  staticStyle: {
                    "margin-bottom": "20px",
                    "text-align": "center",
                  },
                },
                [_vm._v("一つ以上の見積品目を作成してください")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "section",
            { attrs: { name: "price" } },
            [
              _c(
                "AdminContractDetailOption",
                _vm._b(
                  {
                    on: { input: _vm.watchIsEdited },
                    model: {
                      value: _vm.values,
                      callback: function ($$v) {
                        _vm.values = $$v
                      },
                      expression: "values",
                    },
                  },
                  "AdminContractDetailOption",
                  { discount: _vm.discount },
                  false
                )
              ),
              _vm._v(" "),
              _c(
                "AdminContractDetailAmount",
                _vm._b(
                  {
                    attrs: { deal: _vm.items },
                    on: { input: _vm.watchIsEdited },
                    model: {
                      value: _vm.values,
                      callback: function ($$v) {
                        _vm.values = $$v
                      },
                      expression: "values",
                    },
                  },
                  "AdminContractDetailAmount",
                  {
                    discount: _vm.discount,
                    taxs: _vm.taxs,
                    values: _vm.values,
                    isTotalMinus: _vm.isTotalMinus,
                  },
                  false
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            { attrs: { name: "buttons" } },
            [
              _c(
                "Button",
                {
                  attrs: { size: "main", color: "submit" },
                  on: { click: _vm.handleSaveAccepted },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _vm.isNew
                ? _c(
                    "Button",
                    {
                      attrs: { size: "assist", color: "cancel" },
                      on: { click: _vm.backToPreviousPage },
                    },
                    [_vm._v("キャンセル")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "Button",
                    {
                      attrs: { size: "assist", color: "cancel" },
                      on: { click: _vm.handleClearClick },
                    },
                    [_vm._v("クリア")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew && _vm.values.quantity2 == "0" && _vm.items.status < 10
                ? _c("QuotationDetailDialog", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: "削除しますか？",
                        yas: "削除する",
                        no: "キャンセル",
                      },
                    },
                    on: { accept: _vm.handleDeleteAccept },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "Button",
                                _vm._g(
                                  { attrs: { size: "main", color: "alert" } },
                                  on
                                ),
                                [_vm._v("削除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2833742337
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew && !_vm.isEdited
                ? _c("QuotationDetailDialog", {
                    attrs: {
                      texts: {
                        ttl: _vm.isCopied ? "コピー完了" : "確認",
                        msg: _vm.isCopied
                          ? `見積管理No${_vm.copyContractNo}としてコピーしました。編集画面へ移動しますか？`
                          : `見積管理No${_vm.items.contract.contract.contract_no}をコピーしますか？。`,
                        yas: _vm.isCopied ? "移動する" : "コピーする",
                        no: "いいえ",
                      },
                      "no-close": !_vm.isCopied,
                    },
                    on: {
                      accept: _vm.handleCopyAccept,
                      refuse: function ($event) {
                        _vm.isCopied = false
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "Button",
                                _vm._g(
                                  { attrs: { size: "main", color: "submit" } },
                                  on
                                ),
                                [_vm._v("この見積をコピー")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      525666631
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }