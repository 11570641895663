var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c(
      "div",
      { class: _vm.$style.content },
      [
        _c("Anker", { attrs: { to: "/login" } }, [
          _vm._v("ログイン画面に戻る"),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.formAria }, [
          _c("p", { class: _vm.$style.formAria_text }, [
            _c("span", [_vm._v(_vm._s(_vm.message1))]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        " + _vm._s(_vm.message2) + "\n      "),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }