<template>
  <button
    v-if="to === undefined"
    :style="{ width: this.width }"
    :class="[$style.base, $style[color], $style[size]]"
    @click="handleClick"
    :disabled="disabled"
  >
    <span><slot /></span>
    <v-icon v-if="plus !== undefined" color="white" :class="$style.add"
      >add_circle_outline</v-icon
    >
  </button>
  <router-link
    v-else
    :to="to"
    :style="{ width: this.width }"
    :class="[$style.base, $style[color], $style[size]]"
    @click="handleClick"
    :disabled="disabled"
  >
    <span><slot /></span>
    <v-icon v-if="plus !== undefined" color="white" :class="$style.add"
      >add_circle_outline</v-icon
    >
  </router-link>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
    },
    size: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    width: {
      type: String,
    },
    plus: null,
    to: String,
  },
  methods: {
    handleClick(e) {
      this.$emit("click", e)
    },
  },
}
</script>

<style lang="scss" module>
.base {
  display: inline-flex;
  align-items: center;
  padding: 0.25em 1.45em;
  border-radius: 1em;
  box-shadow: 0 2px 2px rgba(#000, 0.3);
  font-weight: bold;
  color: #fff;
  outline: none;
  transition: 0.2s;
}

.add {
  margin-left: 0.2em;
  margin-right: -0.4em;
}

$colors: (
  submit: #ff6000,
  alert: #f00000,
  checked: #4d9627,
  cancel: #898989,
  link: #016cca,
);

$fontSizes: (
  main: 0.16rem,
  sub: 0.18rem,
  assist: 0.14rem,
);

@each $className, $size in $fontSizes {
  .#{$className} {
    font-size: #{$size};
  }
}

@each $classNmae, $color in $colors {
  .#{$classNmae} {
    background-color: $color;
    border: 2px solid $color;
    &:hover {
      background: white;
      color: $color;
      > .add {
        color: $color !important;
      }
      &:disabled {
        background-color: $color;
        color: white;
      }
    }
  }
}
</style>
