import * as types from "@/store/mutation-types"

const pageNo = 1
const sortObj = { colIdx: 0, isDesc: false }
const freeWordObj = { isOn: false, value: "" }

const state = {
  dealings: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      status: "0",
      phase: "すべて",
      staff: "すべて",
      customer: "0",
      //customerPerson: "0",
      keyword: { ...freeWordObj },
    },
  },
  contracts: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      keyword: { ...freeWordObj },
    },
  },
  omcontracts: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      contentStatus: "すべて",
      supplier: "すべて",
      keyword: { ...freeWordObj },
    },
  },
  customers: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      industory: "すべて",
      orderDate: {
        start: null,
        end: null,
      },
      keyword: { ...freeWordObj },
    },
  },
  customerPersons: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      keyword: { ...freeWordObj },
    },
  },
  suppliers: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      keyword: { ...freeWordObj },
    },
  },
  defectives: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      supplier: "すべて",
      keyword: { ...freeWordObj },
    },
  },
  items: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      keyword: { ...freeWordObj },
      quantity: { ...freeWordObj },
      family: "すべて",
    },
  },
  prices: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      keyword: { ...freeWordObj },
    },
  },
  taxs: {
    pageNo: pageNo,
    sort: { ...sortObj },
    filter: {
      keyword: { ...freeWordObj },
    },
  },
}

const getters = {
  getPageInfo: state => id => {
    if (id in state) {
      return state[id]
    }
    return null
  },
}

const actions = {
  setPageInfo: ({ commit }, payload) => {
    commit(types.SET_PAGE_INFO, payload)
  },
}

const mutations = {
  [types.SET_PAGE_INFO](state, { id, info }) {
    if (id in state) {
      info.forEach(val => {
        if (val.name in state[id]) {
          state[id][val.name] = val.value
        }
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
