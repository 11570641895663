<template>
  <img :class="$style.cursor" v-bind="$attrs" :src="src" @click="handleClick" />
  <!-- <span :class="$style.wrapper">
       <img v-show="isSubShow" :src="src" :class="$style.sub" ref="sub" />
       </span> -->
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    src: null,
  },
  // data() {
  //   return {
  //     isSubShow: false,
  //   }
  // },
  methods: {
    handleClick() {
      window.open(this.src)
    },
    // handleMousemove(e) {
    //   const { pageX, pageY } = e
    //   const offset = window.pageYOffset
    //   console.log("pageX, pageY", pageX, pageY)
    //
    //   this.$refs.sub.setAttribute(
    //     "style",
    //     `top:${pageY - offset}px;left:${pageX}`,
    //   )
    // },
    // handleMouseEnter() {
    //   console.log("のった！！")
    //   this.isSubShow = true
    //   document.body.addEventListener("mousemove", this.handleMousemove)
    // },
    // handleMouseLeave() {
    //   console.log("はずれた！！")
    //   document.body.removeEventListener("mousemove", this.handleMousemove)
    // },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: inline-block;
  max-width: 100%;
  position: relative;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

// .sub {
//   display: block;
//   position: fixed;
//   width: 500px;
// }
</style>
