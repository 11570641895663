<template>
  <div :class="$style.wrapper" name="pr-textarea">
    <span v-if="label" name="label" :style="labelStyle">{{ label }}</span>
    <textarea
      v-if="validate"
      v-bind="$attrs"
      v-validate="validate"
      :value="value"
      :name="name"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @blur="$emit('blur')"
      :class="$style.textarea"
      :style="inputStyle"
      :rows="rows"
    />
    <textarea
      v-else
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @blur="$emit('blur')"
      :class="$style.textarea"
      :style="inputStyle"
      :rows="rows"
    />
    <template v-if="validate">
      <PrErrorText v-show="errors.has(name)" :center="prRow[0] !== null">
        {{ errors.first(name) }}
      </PrErrorText>
    </template>
  </div>
</template>

<script>
import PrErrorText from "@/components/atoms/PrErrorText.vue"

export default {
  inject: ["$validator"],
  inheritAttrs: false,
  components: {
    PrErrorText,
  },
  props: {
    value: {
      required: true,
    },
    label: String,
    name: String,
    validate: String,
    rows: {
      type: String,
      default: "5",
    },
    prRow: {
      type: Array,
      default() {
        return [null, null]
      },
    },
  },
  computed: {
    labelStyle() {
      const { prRow } = this
      return {
        width: prRow[0] ? `${prRow[0]}%` : "auto",
      }
    },
    inputStyle() {
      const { prRow } = this
      return {
        width: prRow[1]
          ? `${prRow[1]}%`
          : prRow[0]
          ? `${100 - prRow[0]}%`
          : "auto",
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > span {
    &[name="label"] {
      display: inline-block;
      margin-bottom: 0.2em;
      color: map-get($colors, darkGlay);
    }
  }
}
.textarea {
  display: block;
  padding: 0.3em 0.5em;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 3px;
  background-color: #fff;
  outline: none;
  transition: 0.3s;
  &:focus {
    background-color: #fcfade;
    border-color: #00836b;
    box-shadow: 0 0 3px #00836b;
  }
  &:read-only,
  &:disabled {
    background-color: #eee;
    border-color: #ccc;
    box-shadow: none;
  }
}
</style>
