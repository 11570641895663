<template>
  <div v-if="isReady" :class="$style.wrapper">
    <h1 :class="$style.title">メールアドレス変更</h1>
    <div>
      <p :class="$style.message_error" v-if="validMessage" v-cloak>
        {{ validMessage }}
      </p>
      <form :class="$style.formAria" @submit.prevent="handleChange">
        <p :class="$style.desc">
          変更したいメールアドレスを入力してください。
        </p>
        <InputForm
          name="now_email"
          :class="$style.form"
          type="email"
          :value="nowEmail"
          readonly
          >現在のメールアドレス</InputForm
        >
        <InputForm
          name="new_email"
          :class="$style.form"
          type="email"
          v-model="newEmail"
          v-validate="'required|email'"
          >新しいメールアドレス</InputForm
        >
        <Button type="submit" color="submit" size="main">変更する</Button>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import InputForm from "@/components/molecules/InputForm.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError, showToast, showErrorToast } from "@/utils/shared.js"

export default {
  props: {
    styles: {
      type: Object,
    },
  },
  data() {
    return {
      isReady: false,
      nowEmail: null,
      newEmail: null,
      validMessage: null,
    }
  },
  components: {
    Button,
    InputForm,
  },
  async created() {
    const result = await this.getCustomerAccount()
    if (!result.ok) {
      routeReplaceError(this, result.body.slice(-3))
      return
    }
    this.nowEmail = result.body[0].user.email
    this.isReady = true
  },
  methods: {
    setValidMessage(message) {
      this.validMessage = message
    },
    validate() {
      return this.$validator.validateAll().then(result => {
        if (!result) {
          if (this.errors.firstByRule("new_email", "required")) {
            this.setValidMessage("新しいメールアドレスを入力してください。")
            return false
          }

          if (this.errors.firstByRule("new_email", "email")) {
            this.setValidMessage(
              "新しいメールアドレスを正しく入力してください。",
            )
            return false
          }

          return false
        } else {
          this.setValidMessage(null)
          return true
        }
      })
    },
    async getCustomerAccount() {
      const { ok: result, body: items } = await api.get(
        paths.CUSTOMER_USER,
        this.$store.state.auth.token,
      )

      return { ok: result, body: items }
    },
    async handleChange() {
      let result = await this.validate()
      if (!result) {
        return
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errors, errorMsg } = await api.put(
          paths.CUSTOMER_USER,
          {
            email: this.newEmail,
          },
          this.$store.state.auth.token,
        )

        if (!ok) {
          console.log(`CUSTOMER_USER ${body}`)
          if (errors && errors.email[0] === "has already been taken") {
            showToast(
              this,
              "error",
              `${this.newEmail}には、変更できません。`,
              2000,
              false,
            )
            return
          }

          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        showToast(
          this,
          "success",
          "メールアドレスを変更しました。",
          2000,
          false,
        )
        this.nowEmail = this.newEmail
        this.newEmail = ""
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include displayMessage();

.wrapper {
  min-height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  padding: 55px 0;
}

.formAria {
  width: 800px;
  margin: 0 auto;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  & > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form {
  width: 465px;
}

.desc {
  font-size: 0.18rem;
  color: #707070;
}
</style>
