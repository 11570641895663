<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>お客様登録情報編集</PageTitle>
    <div :class="$style.formArea">
      <PrInput label="部署" :pr-row="[100, 100]" v-model="items.department" />
      <PrInput label="役職" :pr-row="[100, 100]" v-model="items.title" />
      <PrInput
        label="氏名(姓)"
        :pr-row="[100, 100]"
        v-model="items.last_name"
        name="last_name"
        :validate="'required'"
      />
      <PrInput
        label="氏名（名）"
        :pr-row="[100, 100]"
        name="first_name"
        v-model="items.first_name"
      />
      <PrInput
        label="電話番号"
        :pr-row="[100, 100]"
        name="phone"
        v-model="items.phone"
        :validate="'required|telephone'"
      />
      <PrInput
        label="郵便番号"
        :pr-row="[100, 75]"
        name="zip"
        type="text"
        v-model="items.address.zip_code"
        :validate="'required|zipcode'"
        style="justify-content: space-between;"
      >
        <ButtonSerchZipCode
          slot="after"
          :zipcode="items.address.zip_code"
          @click="handleSearchZipCode"
        />
      </PrInput>
      <PrInput
        label="都道府県"
        :pr-row="[100, 100]"
        name="location"
        type="text"
        v-model="items.address.location"
        :validate="'required'"
      />
      <PrInput
        label="市区町村"
        :pr-row="[100, 100]"
        name="address1"
        v-model="items.address.address1"
        :validate="'required'"
      />
      <PrInput
        label="町名・番地"
        :pr-row="[100, 100]"
        name="address2"
        v-model="items.address.address2"
        :validate="'required'"
      />
      <PrInput
        label="建物名・部屋番号"
        :pr-row="[100, 100]"
        v-model="items.building"
      />
      <div class="button">
        <Button color="submit" size="main" @click="handleUpdate"
          >登録する</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import ButtonSerchZipCode from "@/components/molecules/ButtonSerchZipCode.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import api, { paths } from "@/utils/api.js"
import { showErrorToast, routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    Button,
    PageTitle,
    PrInput,
    ButtonSerchZipCode,
  },
  data() {
    return {
      isReady: false,
      id: null,
      items: null,
    }
  },
  async created() {
    this.id = this.$route.params.user_id

    const { ok: result, body: items } = await api.get(
      `${paths.CUSTOMER_USER}/${this.id}`,
      this.$store.state.auth.token,
    )

    if (!result) {
      routeReplaceError(this, items.slice(-3))
      return
    }
    if (items === null) {
      routeReplaceError(this, "400")
    }

    this.items = items
    this.isReady = true
  },
  methods: {
    handleSearchZipCode({ ok, address }) {
      if (!ok) {
        return
      }
      this.items.address = { ...this.items.address, ...address }
      for (const { name, scope } of ["location", "address1", "address2"].map(
        key => this.$validator.fields.find({ name: key }),
      )) {
        this.errors.remove(name, scope)
      }
    },
    async handleUpdate() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      if (result) {
        const data = {
          department: this.items.department,
          title: this.items.title,
          first_name: this.items.first_name,
          last_name: this.items.last_name,
          phone: this.items.phone,
          zip_code: this.items.address.zip_code,
          location: this.items.address.location,
          address1: this.items.address.address1,
          address2: this.items.address.address2,
          building: this.items.building,
          lock_version: this.items.lock_version,
          email: this.items.user.email,
        }

        const loader = this.$loading.show()

        try {
          const { ok, body, errorMsg } = await api.put(
            `${paths.CUSTOMER_USER}/${this.id}`,
            data,
            this.$store.state.auth.token,
          )
          if (!ok) {
            showErrorToast(this, body.slice(-3), errorMsg)
            return
          }

          this.$router.push({ path: "/customer/accounts" })
        } finally {
          loader.hide()
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formArea {
  width: 320px;
  background-color: map-get($colors, white);
  padding: 20px;
  > div[name^="pr"] {
    margin-bottom: 10px;
  }
  > div[class="button"] {
    text-align: center;
    margin: 20px auto;
  }
}
</style>
