import moment from "moment"

const getDate = (str, sub = "未設定", format = "YYYY/MM/DD") => {
  if (!str) {
    return sub
  }

  const date = moment(str, "YYYY-MM-DDTHH:mm:ssZ")
  return date.isValid() ? date.format(format) : sub
}

const getDateTime = (str, sub = "未設定", format = "YYYY/MM/DD HH:mm") => {
  if (!str) {
    return sub
  }

  const date = moment(str, "YYYY-MM-DDTHH:mm:ssZ")
  return date.isValid() ? date.format(format) : sub
}

function zeroPadding(num, len) {
  return (Array(len).join("0") + num).slice(-len)
}

function routeReplaceError(vm, statusCode) {
  vm.$router.replace({
    name: "errorView",
    params: {
      statusCode: statusCode,
      url: vm.$route.path,
    },
  })
}

const isLockVersionError = (status, data) =>
  status == 422 && data === "attempted to update a stale struct"

/* eslint-disable */
const showErrorToast = (
  vm,
  status,
  errData,
  message = "登録に失敗しました。",
  lang = "JP",
  duration = 3000,
  isClose = false,
) => {
  let msg = message

  const lockVersionMessage = {
    JP: "ブラウザを再読み込みして、もう一度やりなおしてください。",
    KR: "브라우저를 새로 고침하여 다시 시도하십시오",
  }

  msg += isLockVersionError(status, errData) ? lockVersionMessage[lang] : ""

  showToast(vm, "error", msg, duration, isClose)
}
/* eslint-enable */

const showToast = (vm, type, message, duration, isClose) => {
  let action = null
  if (isClose) {
    action = {
      text: "Close",
      class: "toast",
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      },
    }
  }

  const toastOption = {
    duration: duration,
    action: action,
  }

  switch (type) {
    case "info":
      vm.$toasted.info(message, toastOption)
      break
    case "error":
      vm.$toasted.error(message, toastOption)
      break
    case "success":
      vm.$toasted.success(message, toastOption)
      break
    case "show":
    default:
      vm.$toasted.show(message, toastOption)
      break
  }
}

const getAdress = zipCode =>
  new Promise(resolve => {
    // eslint-disable-next-line
    new YubinBango.Core(zipCode, function(addr) {
      resolve({
        location: addr.region,
        address1: addr.locality,
        address2: addr.street,
      })
    })
  })

/* eslint-disable */
const formatPrice = (num, yen = true, msg = "算出できません") =>
  isNaN(Number(num))
    ? msg
    : Math.floor(Number(num))
        .toString()
        .split("")
        .reverse()
        .map((v, i) => {
          if (i !== 0 && i % 3 === 0) {
            v = v + ","
          }
          return v
        })
        .reverse()
        .join("")
        .replace("-,", "-") + (yen ? "円" : "")

const formatDecimalPrice = (num, yen = true, msg = "算出できません") =>
  isNaN(Number(num))
    ? msg
    : Math.floor(Number(String(num).split(".")[0]))
        .toString()
        .split("")
        .reverse()
        .map((v, i) => {
          if (i !== 0 && i % 3 === 0) {
            v = v + ","
          }
          return v
        })
        .reverse()
        .join("")
        .replace("-,", "-") +
      (String(num).split(".")[1] ? `.${String(num).split(".")[1]}` : "") +
      (yen ? "円" : "")

/* eslint-enable */

// 小数点第３位以下切り捨て
const floor3 = num => Math.floor(num * 100) / 100

const deepCopy = any => JSON.parse(JSON.stringify(any))

const openNewWindow = path => {
  const { protocol, hostname, port } = location
  window.open(`${protocol}//${hostname}:${port}${path}`)
}

const sleepMillSec = ms => {
  return new Promise(resolve => {
    const id = window.setTimeout(() => {
      window.clearTimeout(id)
      resolve()
    }, ms)
  })
}

const checkNaN = num => (isNaN(num) ? "" : num)

const getUniqueStr = () => {
  const str =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$%&=~/*-+"
  const len = 8
  let uniStr = ""
  for (var i = 0; i < len; i++) {
    uniStr += str.charAt(Math.floor(Math.random() * str.length))
  }
  return uniStr
}

export {
  getDate,
  getDateTime,
  zeroPadding,
  routeReplaceError,
  getAdress,
  showToast,
  formatPrice,
  formatDecimalPrice,
  floor3,
  deepCopy,
  openNewWindow,
  sleepMillSec,
  showErrorToast,
  checkNaN,
  getUniqueStr,
}
