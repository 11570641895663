<template>
  <div style="margin-top:30px">
    <div :class="$style.head">
      <h3>マージ</h3>
      <template v-if="coupling">
        <DialogProvider
          :texts="{
            ttl: '確認',
            msg: `${seme.request_name}は削除されます。マージしますか？`,
            yas: 'はい',
            no: 'いいえ',
          }"
          @accept="handleMarge"
        >
          <template slot-scope="{ on }">
            <Button size="main" color="submit" v-on="on">
              マージする
            </Button>
          </template>
        </DialogProvider>
      </template>
    </div>
    <div :class="$style.inner">
      <template v-for="([ttl, num, men], idx) in collection">
        <div :class="$style.seme" :key="idx">
          <h4>
            <span>{{ ttl }}</span
            ><span>商談No: {{ num }}</span>
          </h4>
          <template v-if="men">
            <div :class="$style.sub">
              <p>問い合わせ</p>
              <template v-if="!idx">
                <PrCheckbox
                  v-model="flagInquiry"
                  before-label="Bに上書"
                  after-label="＞＞"
                />
              </template>
            </div>
            <ul>
              <li>
                <span>問合日</span>
                <span>{{ men.inquiry.request_date1 | getDate }}</span>
              </li>
              <li>
                <span>内容</span
                ><span>{{ men.inquiry.description | chackFalsiy }}</span>
              </li>
            </ul>

            <div :class="$style.sub">
              <p>サンプル請求</p>
              <template v-if="!idx">
                <PrCheckbox
                  v-model="flagSample"
                  before-label="Bに上書"
                  after-label="＞＞"
                />
              </template>
            </div>
            <ul>
              <li>
                <span>請求日</span>
                <span>{{ men.inquiry.request_date3 | getDate }}</span>
              </li>
              <li>
                <span>商品番号</span>
                <span>{{ men.inquiry.note3 | chackFalsiy }}</span>
              </li>
              <li>
                <span>商品備考</span>
                <span>{{ men.inquiry.note2 | chackFalsiy }}</span>
              </li>
            </ul>

            <div :class="$style.sub">
              <p>その他情報</p>
              <template v-if="!idx">
                <PrCheckbox
                  v-model="flagOther"
                  before-label="Bに上書"
                  after-label="＞＞"
                />
              </template>
            </div>
            <ul>
              <li>
                <span>備考</span>
                <span>{{ men.note1 | chackFalsiy }}</span>
              </li>
              <li>
                <span>アクセス経路</span>
                <span>{{ men.note2 | chackFalsiy }}</span>
              </li>
            </ul>

            <div :class="$style.sub">
              <p>見積</p>
              <template v-if="!idx">
                <PrCheckbox
                  v-model="flagContract"
                  before-label="Bに追加"
                  after-label="＞＞"
                />
              </template>
            </div>
            <template v-for="contract in men.contract">
              <ul :key="contract.id" :class="$style.contract">
                <li>
                  <span>見積日</span>
                  <span>{{ contract.request_date1 | getDate }}</span>
                </li>
                <li>
                  <span>見積名</span>
                  <span>{{ contract.request_name }}</span>
                </li>
                <li>
                  <span>見積総額</span>
                  <span>{{ contract.contract.total_amount }}</span>
                </li>
              </ul>
            </template>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import Button from "@/components/atoms/Button.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import api, { paths } from "@/utils/api.js"
import { getDate, deepCopy, routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    PrCheckbox,
    Button,
    DialogProvider,
  },
  props: {
    semeNum: {
      required: true,
    },
    ukeNum: {
      required: true,
    },
    init: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      seme: null,
      uke: null,
      flagInquiry: "0",
      flagSample: "0",
      flagOther: "0",
      flagContract: "0",
    }
  },
  computed: {
    collection() {
      const { seme, uke, coupling, semeNum, ukeNum } = this
      return [
        [`A（削除）`, semeNum || "", seme],
        [`B（更新前）`, ukeNum || "", uke],
        [`B'（更新後）`, ukeNum || "", coupling],
      ]
    },
    coupling() {
      const {
        seme,
        uke,
        flagInquiry,
        flagSample,
        flagContract,
        flagOther,
      } = this
      if (!seme || !uke) {
        return null
      }

      let coupling = deepCopy(uke)

      if (flagInquiry === "1") {
        coupling.inquiry = {
          ...coupling.inquiry,
          request_date1: seme.inquiry.request_date1,
          quantity2: seme.inquiry.quantity2,
          description: seme.inquiry.description,
        }
      }
      if (flagSample === "1") {
        coupling.inquiry = {
          ...coupling.inquiry,
          request_date3: seme.inquiry.request_date3,
          quantity3: seme.inquiry.quantity3,
          note1: seme.inquiry.note1,
          note2: seme.inquiry.note2,
          request_date4: seme.inquiry.request_date4,
          note3: seme.inquiry.note3,
          note4: seme.inquiry.note4,
          request_appendices: seme.inquiry.request_appendices,
        }
      }
      if (flagOther === "1") {
        coupling = {
          ...coupling,
          note1: seme.note1,
          note2: seme.note2,
          note3: seme.note3,
        }
      }
      if (flagContract === "1") {
        coupling.contract = [...coupling.contract, ...seme.contract]
      }

      return coupling
    },
  },
  filters: {
    getDate: d => getDate(d, ""),
    chackFalsiy: v => v || "",
  },
  methods: {
    async fetchDealing(requestNumber, men) {
      const loader = this.$loading.show()
      try {
        if (!requestNumber) {
          this[men] = null
          return
        }
        const { ok, body } = await api.get(
          paths.ADMIN_DEALINGS + "/" + requestNumber,
          this.$store.state.auth.token,
        )
        if (!ok) {
          routeReplaceError(this, body.slice(-3))
          return
        }
        this[men] = body
      } finally {
        loader.hide()
      }
    },
    async handleMarge() {
      const loader = this.$loading.show()
      try {
        const { ok, body } = await api.post(
          paths.DEALINGS_MERGE,
          { ...this.coupling, seme: this.seme.request_number },
          this.$store.state.auth.token,
        )
        if (!ok) {
          routeReplaceError(this, body.slice(-3))
          return
        }
        console.log("body", body)
        this.init(body)
      } finally {
        loader.hide()
      }
    },
  },
  watch: {
    semeNum(num) {
      this.fetchDealing(num, "seme")
    },
    ukeNum(num) {
      this.fetchDealing(num, "uke")
    },
  },
}
</script>

<style lang="scss" module>
.head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > h3 {
    margin-right: 30px;
  }
}
.inner {
  display: flex;
  justify-content: space-around;
  > div {
    width: 30%;
    padding: 10px 10px 10px;
    font-size: 0.14rem;
    border-radius: 4px;
    background-color: map-get($colors, lightGray);
    > h4 {
      display: flex;
      justify-content: center;
      > span {
        display: inline-block;
        &:last-of-type {
          width: 150px;
          border-radius: 10px;
          background-color: white;
          text-align: center;
        }
      }
    }
    .sub {
      height: 30px;
      padding-left: 10px;
      margin: 10px 0 5px;
      border-bottom: 2px solid map-get($colors, yellow);
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > p {
        font-weight: bold;
      }
      > div {
        border-top-right-radius: 20px;
        border-top-left-radius: 5px;

        padding: 3px 8px;
        background-color: map-get($colors, yellow);
        > p {
          color: #444;
        }
        > label {
          span {
            background-color: white;
          }
        }
      }
    }
    ul {
      font-size: 0.12rem;
      list-style: none;
      padding: 0;
      &.contract {
        margin: 0 0px 5px;
        border-radius: 8px;
        overflow: hidden;
      }
      > li {
        display: flex;
        /* stylelint-disable */
        > span {
          display: block;
          background-color: white;
          border: 1px solid map-get($colors, lightGray);
          &:first-child {
            text-align: center;
            width: 30%;
          }
          &:last-child {
            padding-left: 10px;
            width: 70%;
          }
        }
      }
    }
  }
}
</style>
