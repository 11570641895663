var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      class: [_vm.$style.wrapper, { [_vm.$style.dragMode]: _vm.dragMode }],
      attrs: { draggable: _vm.checkStatus(_vm.deal, 13) && _vm.dragMode },
      on: {
        dragstart: function ($event) {
          return _vm.$emit("dragstart", _vm.deal)
        },
        dragend: function ($event) {
          _vm.$emit("dragend")
          _vm.dragMode = false
        },
      },
    },
    [
      _c("h3", [_vm._v(_vm._s(_vm.deal.request_number))]),
      _vm._v(" "),
      _vm.checkStatus(_vm.deal, 13, 14)
        ? _c(
            "v-btn",
            {
              style: _vm.checkStatus(_vm.deal, 13)
                ? "margin:0 0 5px;width:100%;"
                : "margin:0;width:100%;",
              attrs: { outline: "", small: "", color: "indigo" },
              on: { click: _vm.handleEditClick },
            },
            [_c("v-icon", [_vm._v("edit")])],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.checkStatus(_vm.deal, 13)
        ? _c(
            "div",
            {
              class: _vm.$style.drag,
              on: {
                mousedown: function ($event) {
                  _vm.dragMode = true
                },
                mouseup: function ($event) {
                  _vm.dragMode = false
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: "indigo" } }, [
                _vm._v("drag_handle"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        [
          _c(
            "router-link",
            { attrs: { to: `/admin/dealings/${_vm.deal.request_number}` } },
            [_vm._v("\n      " + _vm._s(_vm.deal.request_name) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.checkStatus(_vm.deal, 1, 2, 3)
        ? [
            _vm.deal.contract
              ? [
                  _c(
                    "p",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: `/admin/dealings/card/${_vm.deal.request_number}/contracts/${_vm.deal.contract.request_key2}`,
                          },
                        },
                        [_vm._v(_vm._s(_vm.deal.contract.request_name))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.checkStatus(_vm.deal, 10)
                    ? _c("p", [
                        _vm._v(
                          "\n        入稿:" +
                            _vm._s(
                              _vm._f("getDate")(_vm.deal.contract.request_date2)
                            ) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkStatus(_vm.deal, 10)
                    ? _c("p", [
                        _vm._v(
                          "\n        注文:" +
                            _vm._s(
                              _vm._f("getDate")(
                                _vm.deal.contract.contract.contracted_date
                              )
                            ) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkStatus(_vm.deal, 11)
                    ? _c("p", [
                        _vm._v(
                          "\n        発注:" +
                            _vm._s(
                              _vm._f("getDate")(_vm.deal.contract.request_date5)
                            ) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 11, 13)
              ? _c("p", [
                  _vm._v(
                    "\n      出庫:" +
                      _vm._s(
                        _vm._f("getDate")(_vm.deal.product.request_date1)
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 13)
              ? _c("p", [
                  _vm._v(
                    "\n      港:" +
                      _vm._s(
                        _vm._f("getDate")(_vm.deal.product.request_date2)
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 14)
              ? _c("p", [
                  _vm._v(
                    "\n      検品:" +
                      _vm._s(
                        _vm._f("getDate")(_vm.deal.product.request_date3)
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 20)
              ? _c("p", [
                  _vm._v(
                    "\n      発送:" +
                      _vm._s(
                        _vm._f("getDate")(_vm.deal.delivery.request_date2)
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 10, 11, 13, 14, 20)
              ? _c("p", [
                  _vm._v(
                    "\n      納期:" +
                      _vm._s(_vm._f("getDate")(_vm.deal.request_date2)) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 13, 14, 20)
              ? _c("p", [
                  _vm._v(
                    "\n      *" +
                      _vm._s(_vm.deal.product.quantity3) +
                      "箱出庫\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 14, 20)
              ? _c("p", [
                  _vm._v("*" + _vm._s(_vm.deal.product.quantity4) + "個着"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 14, 20)
              ? _c("p", [
                  _vm._v(
                    "\n      *" +
                      _vm._s(
                        _vm.deal.product.quantity4 - _vm.deal.product.quantity6
                      ) +
                      "個送\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 14, 20)
              ? _c("p", [
                  _vm._v(
                    "\n      *" +
                      _vm._s(_vm.deal.product.quantity5) +
                      "箱発送\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 14, 20)
              ? _c("p", [
                  _vm._v(
                    "\n      *" +
                      _vm._s(_vm.deal.product.quantity6) +
                      "個保管\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.checkStatus(_vm.deal, 15)
              ? [
                  _vm.deal.defect.length !== 0
                    ? [
                        _c("p", [
                          _vm._v(
                            "対応期限:" +
                              _vm._s(
                                _vm._f("getDate")(
                                  _vm.deal.defect[0].request_date2
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.$style.defect }, [
                          _c("p", [_vm._v("不良原因:")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.deal.defect[0].note2))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.$style.defect }, [
                          _c("p", [_vm._v("不良概要:")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.deal.defect[0].note3))]),
                        ]),
                      ]
                    : _c("p", { class: _vm.$style.noDefect }, [
                        _vm._v("不良品情報を"),
                        _c("br"),
                        _vm._v("登録して下さい"),
                      ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.getVerificationImage(_vm.deal)
              ? _c("PrImg", {
                  attrs: { src: _vm.getVerificationImage(_vm.deal) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.checkFlags(_vm.deal), function ({ name, text }) {
              return _c("span", { key: name, attrs: { name: name } }, [
                _vm._v(_vm._s(text)),
              ])
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }