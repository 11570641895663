<template>
  <InfoBlock width="100%" block margin="0">
    <template slot="head">
      入金情報
    </template>
    <div :class="$style.row">
      <PrDatePicker
        label="入金予定日"
        :pr-row="[30]"
        :value="value.request_date3"
        @input="updateValue($event, 'request_date3')"
      />
    </div>
    <div :class="$style.row">
      <PrDatePicker
        label="入金日"
        :pr-row="[30]"
        :value="value.request_date4"
        @input="updateValue($event, 'request_date4')"
      />
    </div>
    <div :class="$style.row">
      <PrSelect
        name="quantity4"
        label="支払い方法"
        :pr-row="[30]"
        :value="value.quantity4"
        @input="updateValue($event, 'quantity4')"
        :options="$store.getters['defs/getOptions']('PaymentMethod')"
        validate="required"
      />
    </div>
    <div :class="$style.row">
      <PrCheckbox
        before-label="後払い"
        :pr-row="[30]"
        :value="value.quantity5"
        @input="updateValue($event, 'quantity5')"
      />
    </div>
    <div :class="$style.row">
      <p :class="$style.distribution_item_info_row_name">
        コレクト代金
      </p>
      <div :class="$style.distribution_item_info_row_txt">
        {{ collectFee | formatPrice }}
      </div>
    </div>
    <div :class="$style.row">
      <p :class="$style.distribution_item_info_row_name">
        請求金額(税込)
      </p>
      <div :class="$style.distribution_item_info_row_txt">
        {{ (billingAmount + collectFee) | formatPrice }}
      </div>
    </div>
  </InfoBlock>
</template>

<script>
import PrSelect from "@/components/molecules/PrSelect.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import { formatPrice } from "@/utils/shared.js"
import {
  calcBillingTotalPrice,
  calcBillingDeliveryFee,
} from "@/helpers/QuotationDetailViewHelper.js"

export default {
  inject: ["$validator"],
  components: {
    PrSelect,
    PrDatePicker,
    PrCheckbox,
    InfoBlock,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    contracts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    billingAmount() {
      const target = this.contracts.find(({ status }) => status === 1)
      const { total } = calcBillingTotalPrice(
        target.contract.contract_details,
        target.contract.quantity4,
      )

      return total
    },
    collectFee() {
      return calcBillingDeliveryFee(this.billingAmount, this.value.quantity4)
    },
  },
  filters: {
    formatPrice: num => formatPrice(num),
  },
  methods: {
    updateValue(val, key) {
      this.$emit("input", { ...this.value, [key]: val })
    },
  },
}
</script>

<style lang="scss" module>
.row {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  &:nth-of-type(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  > div[name^="pr"] {
    width: 100%;
  }
  > p {
    &:nth-of-type(1) {
      color: map-get($colors, darkGlay);
      width: 30%;
    }
  }
}
</style>
