var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items !== null
    ? _c(
        "IndexTableProvider",
        _vm._b(
          {
            staticStyle: { width: "1300px" },
            attrs: {
              items: _vm.items,
              headers: _vm.complateHeaders,
              "filter-func": _vm.filterFunc,
              "page-id": "dealings",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "filter",
                  fn: function ({
                    filter,
                    handleSelectInput,
                    handleTextInput,
                    handleFlagOn,
                    filterClass,
                  }) {
                    return [
                      _c(
                        "div",
                        { class: [_vm.$style.fil, filterClass] },
                        [
                          _c("PrSelect", {
                            attrs: {
                              label: "対応状況",
                              "pr-row": [100],
                              value: filter.status,
                              options: _vm.statusOptions,
                            },
                            on: {
                              input: function ($event) {
                                return handleSelectInput($event, "status")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("PrSelect", {
                            attrs: {
                              label: "フェーズ",
                              "pr-row": [100],
                              value: filter.phase,
                              options: _vm.phaseOptions,
                            },
                            on: {
                              input: function ($event) {
                                return handleSelectInput($event, "phase")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("PrSelect", {
                            attrs: {
                              label: "入金確認",
                              "pr-row": [100],
                              value: filter.is_payment,
                              options: _vm.paymentOption,
                            },
                            on: {
                              input: function ($event) {
                                return handleSelectInput($event, "is_payment")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("PrSelect", {
                            attrs: {
                              label: "担当者",
                              "pr-row": [100],
                              value: filter.staff,
                              options: _vm.staffOptions,
                            },
                            on: {
                              input: function ($event) {
                                return handleSelectInput($event, "staff")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("PrSelect", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              label: "取引先",
                              "pr-row": [100],
                              value: filter.customer,
                              options: _vm.customerOptions,
                              searchable: true,
                            },
                            on: {
                              input: function ($event) {
                                return handleSelectInput($event, "customer")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "PrInput",
                            {
                              staticStyle: {
                                "justify-content": "space-between",
                              },
                              attrs: {
                                label: "キーワード",
                                "pr-row": [100, 85],
                                value: _vm.keywordHandler.value,
                              },
                              on: { input: _vm.keywordHandler.input },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.keywordHandler.keydown.apply(
                                    null,
                                    arguments
                                  )
                                },
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.keywordHandler.keyup(
                                    $event,
                                    handleFlagOn,
                                    handleTextInput,
                                    filter
                                  )
                                },
                              },
                            },
                            [
                              _c("SearchButton", {
                                attrs: {
                                  slot: "after",
                                  close: _vm.keywordHandler.isOn,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.keywordHandler.click(
                                      handleFlagOn,
                                      handleTextInput,
                                      filter
                                    )
                                  },
                                },
                                slot: "after",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.margeMode
                        ? [
                            _c(
                              "DealingIndexMargeOperation",
                              _vm._b(
                                { attrs: { init: _vm.init } },
                                "DealingIndexMargeOperation",
                                _vm.coupling,
                                false
                              )
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.excelMode
                        ? [
                            _c("DealingIndexExcelOperation", {
                              attrs: {
                                excelTargets: _vm.excelTargets,
                                clearFlagExcel: _vm.clearFlagExcel,
                              },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              1690655237
            ),
          },
          "IndexTableProvider",
          {
            handleFlagInput: _vm.handleFlagInput,
            flagMarges: _vm.flagMarges,
            flagExcel: _vm.flagExcel,
          },
          false
        ),
        [
          _c("template", { slot: "page-title" }, [
            _vm._v("\n    商談一覧\n  "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "create-link" }, slot: "create-link" },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "align-self": "flex-end" },
                  on: {
                    click: function ($event) {
                      _vm.excelMode = !_vm.excelMode
                      _vm.margeMode = false
                    },
                  },
                },
                [
                  _vm._v("出荷伝票\n      "),
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.excelMode ? "close" : "more_horiz")),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticStyle: { "align-self": "flex-end" },
                  on: {
                    click: function ($event) {
                      _vm.margeMode = !_vm.margeMode
                      _vm.excelMode = false
                    },
                  },
                },
                [
                  _vm._v("マージ "),
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.margeMode ? "close" : "more_horiz")),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  attrs: {
                    size: "main",
                    color: "submit",
                    plus: "",
                    to: "/admin/dealings/new",
                  },
                },
                [_vm._v("\n      新しい商談を追加する\n    ")]
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }