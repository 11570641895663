var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.reports
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _vm._l(
            _vm.reports,
            function ({
              detailsProduct,
              detailsOther,
              detailsReserved,
              taxRate,
              taxTotalPrice,
              subTotalPrice,
              discountPrice,
              totalPrice,
            }) {
              return [
                _c("div", { key: totalPrice, class: _vm.$style.container }, [
                  _c("section", { class: _vm.$style.section1 }, [
                    _c("h1", [_vm._v("見積書")]),
                    _vm._v(" "),
                    _c("p", { class: _vm.$style.date }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.documentDate,
                            expression: "documentDate",
                          },
                        ],
                        class: _vm.$style.input,
                        domProps: { value: _vm.documentDate },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.documentDate = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("p", { class: _vm.$style.date }, [
                      _vm._v("商談番号：" + _vm._s(_vm.$route.params.deal_id)),
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.head }, [
                      _c("div", { class: _vm.$style.left }, [
                        _c("p", {}, [
                          _c("span", { class: _vm.$style.inputable }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.customerName) +
                                "\n                "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customerName,
                                  expression: "customerName",
                                },
                              ],
                              domProps: { value: _vm.customerName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.customerName = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", {}, [
                          _vm._v("\n              担当者："),
                          _c("span", { class: _vm.$style.inputable }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.personName) +
                                "\n                "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.personName,
                                  expression: "personName",
                                },
                              ],
                              domProps: { value: _vm.personName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.personName = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _vm._v("\n              様\n            "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { class: _vm.$style.right }, [
                        _c("div", { class: _vm.$style.logo_box }, [
                          _c("p", [
                            _vm._v(
                              "\n                付箋・販促物製作専門店\n                "
                            ),
                            _c("br"),
                            _vm._v("株式会社\n                "),
                            _c(
                              "span",
                              { class: [_vm.$style.fwb, _vm.$style.fzb] },
                              [_vm._v("ＰＲ３９")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [_c("Logo", { attrs: { width: 70 } })], 1),
                        ]),
                        _vm._v(" "),
                        _vm._m(0, true),
                        _vm._v(" "),
                        _c("img", {
                          ref: `img_company_seal`,
                          refInFor: true,
                          class: _vm.$style.seal,
                          attrs: {
                            src: require("@/images/company_seal.png"),
                            alt: "株式会社ピーアールサンキュー",
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("本見積書有効期限：右上の発行日から1ヶ月"),
                    ]),
                    _vm._v(" "),
                    _c("p", { class: _vm.$style.greeting }, [
                      _vm._v(
                        "\n          この度は弊社にお見積の機会を与えて下さいまして誠に有難うございます。\n          "
                      ),
                      _c("br"),
                      _vm._v("下記の通りお見積申し上げます。"),
                      _c("br"),
                      _vm._v("宜しくお願いいたします。\n        "),
                    ]),
                    _vm._v(" "),
                    _c("table", [
                      _vm._m(1, true),
                      _vm._v(" "),
                      _vm._m(2, true),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _vm._l(detailsProduct, function (detail, index) {
                            return _c("tr", { key: `first-${index}` }, [
                              _c("td", {}, [_vm._v(_vm._s(detail.index))]),
                              _vm._v(" "),
                              _c("td", {}, [_vm._v(_vm._s(detail.name))]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("formatNumber")(detail.amount)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("formatDecimalPrice")(detail.price)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }, [
                                _vm._v(
                                  "\n                ¥" +
                                    _vm._s(
                                      _vm._f("formatPrice")(detail.amountPrice)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ])
                          }),
                          _vm._v(" "),
                          _vm._l(detailsOther, function (detail, index) {
                            return _c("tr", { key: `second-${index}` }, [
                              _c("td", {}, [_vm._v(_vm._s(detail.index))]),
                              _vm._v(" "),
                              _c("td", {}, [_vm._v(_vm._s(detail.name))]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("formatNumber")(detail.amount)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("formatPrice")(detail.price)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }, [
                                _vm._v(
                                  "\n                ¥" +
                                    _vm._s(
                                      _vm._f("formatPrice")(detail.amountPrice)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ])
                          }),
                          _vm._v(" "),
                          _vm._l(detailsReserved, function (detail, index) {
                            return _c("tr", { key: `third-${index}` }, [
                              _c("td"),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.price }),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("tbody", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              class: [_vm.$style.bor0, _vm.$style.vat],
                              attrs: {
                                rowspan: discountPrice == null ? 3 : 4,
                                colspan: 2,
                              },
                            },
                            [
                              _c(
                                "div",
                                { class: _vm.$style.bikou },
                                [
                                  _c("div", [_vm._v("備考")]),
                                  _vm._v(" "),
                                  _c("AutoSizingTextarea"),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { class: _vm.$style.tac, attrs: { colspan: "2" } },
                            [_vm._v("小計")]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { class: [_vm.$style.price, _vm.$style.summary] },
                            [
                              _vm._v(
                                "\n                ¥" +
                                  _vm._s(
                                    _vm._f("formatPrice")(
                                      subTotalPrice + (discountPrice || 0)
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c(
                            "td",
                            { class: _vm.$style.tac, attrs: { colspan: "2" } },
                            [
                              _vm._v(
                                "\n                消費税（" +
                                  _vm._s(taxRate) +
                                  "%）\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { class: [_vm.$style.price, _vm.$style.summary] },
                            [
                              _vm._v(
                                "\n                ¥" +
                                  _vm._s(_vm._f("formatPrice")(taxTotalPrice)) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        discountPrice != null
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  class: _vm.$style.tac,
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v("値引き")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: [_vm.$style.price, _vm.$style.summary],
                                  staticStyle: { color: "red" },
                                },
                                [
                                  _vm._v(
                                    "\n                -¥" +
                                      _vm._s(
                                        _vm._f("formatPrice")(discountPrice)
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tr", [
                          _c(
                            "td",
                            { class: _vm.$style.tac, attrs: { colspan: "2" } },
                            [_vm._v("税込み金額")]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { class: [_vm.$style.price, _vm.$style.summary] },
                            [
                              _vm._v(
                                "\n                ¥" +
                                  _vm._s(_vm._f("formatPrice")(totalPrice)) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("\n          お振込み先\n          "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { class: _vm.$style.mr }, [
                        _vm._v("銀行名／福岡銀行"),
                      ]),
                      _vm._v(" "),
                      _c("span", { class: _vm.$style.mr }, [
                        _vm._v("店名／本店営業部"),
                      ]),
                      _vm._v(" "),
                      _c("span", { class: _vm.$style.mr }, [
                        _vm._v("口座／普通"),
                      ]),
                      _vm._v("\n          口座番号／6226598\n          "),
                      _c("br"),
                      _vm._v(
                        "名義／株式会社ＰＲ３９ カ）ピーアールサンキュー\n          "
                      ),
                      _c("br"),
                      _vm._v(
                        "恐れ入りますが、振込手数料は貴社にてご負担ください。\n        "
                      ),
                    ]),
                  ]),
                ]),
              ]
            }
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n              〒812-0895 "),
      _c("br"),
      _vm._v("福岡市博多区竹下1丁目11−23\n              "),
      _c("br"),
      _vm._v("TEL:092-409-6039\n            "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "8%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "55%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "17%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("項目")]),
        _vm._v(" "),
        _c("th", [_vm._v("商品番号・商品名")]),
        _vm._v(" "),
        _c("th", [_vm._v("数量")]),
        _vm._v(" "),
        _c("th", [_vm._v("単価")]),
        _vm._v(" "),
        _c("th", [_vm._v("金額")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }