var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", { class: _vm.$style.wrapper }, [
        _c("h1", { class: _vm.$style.pageTtl }, [_vm._v(" ")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.block }, [
          _c("h2", { class: _vm.$style.block_ttl }, [
            _vm._v("발송정보 입력화면"),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.gray }, [
            _c("p", { class: _vm.$style.gray_ttl }, [_vm._v("견적/주문 내용")]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.gray_row }, [
              _c("p", { class: _vm.$style.gray_row_name }, [
                _vm._v("견적관리번호"),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.gray_row_txt }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.item.contract.request_key2) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.gray_row }, [
              _c("p", { class: _vm.$style.gray_row_name }, [
                _vm._v("주문 (데이터) 이름"),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.gray_row_txt }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.item.contract.description) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.gray_row }, [
              _c("p", { class: _vm.$style.gray_row_name }, [_vm._v("발주일")]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.gray_row_txt }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("getDate")(_vm.item.contract.request_date5)) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.block_container },
            [
              _c("div", { class: _vm.$style.quotation }, [
                _c("p", { class: _vm.$style.quotation_name }, [
                  _vm._v("출고정보"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.quotation_row },
                  [
                    _c("PrInput", {
                      attrs: {
                        label: "출고박스수",
                        "pr-row": [45],
                        name: "delivery_quantity",
                        validate: "required|numeric",
                        korean: "",
                      },
                      model: {
                        value: _vm.item.product.quantity3,
                        callback: function ($$v) {
                          _vm.$set(_vm.item.product, "quantity3", $$v)
                        },
                        expression: "item.product.quantity3",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.quotation_row },
                  [
                    _c("PrDatePicker", {
                      attrs: {
                        label: "출고일",
                        name: "delivery_date",
                        "pr-row": [45],
                        validate: "required",
                        korean: "",
                      },
                      model: {
                        value: _vm.item.product.request_date1,
                        callback: function ($$v) {
                          _vm.$set(_vm.item.product, "request_date1", $$v)
                        },
                        expression: "item.product.request_date1",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.quotation_row },
                  [
                    _c("PrInput", {
                      attrs: {
                        label: "주문총수",
                        "pr-row": [45],
                        value: _vm.totalOrder,
                        disabled: true,
                        readonly: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.quotation_row },
                  [
                    _c("PrInput", {
                      attrs: {
                        label: "여분수",
                        "pr-row": [45],
                        name: "reserve_num",
                        validate: "required|numeric",
                        korean: "",
                      },
                      model: {
                        value: _vm.item.product.note1,
                        callback: function ($$v) {
                          _vm.$set(_vm.item.product, "note1", $$v)
                        },
                        expression: "item.product.note1",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.quotation_row },
                  [
                    _c("PrInput", {
                      attrs: {
                        label: "출고상품총개수",
                        "pr-row": [45],
                        value: _vm.totalGoodsIssues,
                        readonly: "",
                        disabled: true,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { class: _vm.$style.quotation_remarks }, [
                  _vm._v("※자동계산"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.quotation_row },
                  [
                    _c("PrTextarea", {
                      attrs: { label: "비고", "pr-row": [45] },
                      model: {
                        value: _vm.item.product.note2,
                        callback: function ($$v) {
                          _vm.$set(_vm.item.product, "note2", $$v)
                        },
                        expression: "item.product.note2",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticStyle: { margin: "0 auto", display: "block" },
                  attrs: { color: "submit", size: "main" },
                  on: { click: _vm.handleRegist },
                },
                [_vm._v("등록하기")]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }