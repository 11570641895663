<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="headers"
    :filter-func="filterFunc"
    page-id="contracts"
  >
    <template slot="page-title">
      見積一覧
    </template>
    <div
      slot="filter"
      slot-scope="{ filter, handleTextInput, handleFlagOn, filterClass }"
      :class="filterClass"
    >
      <PrInput
        label="キーワード"
        :pr-row="[100, 85]"
        :value="keywordHandler.value"
        @input="keywordHandler.input"
        @keydown.native.enter="keywordHandler.keydown"
        @keyup.native.enter="
          keywordHandler.keyup($event, handleFlagOn, handleTextInput, filter)
        "
        style="justify-content:space-between;"
      >
        <SearchButton
          slot="after"
          @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="keywordHandler.isOn"
        />
      </PrInput>
    </div>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import { formatPrice, getDate, routeReplaceError } from "@/utils/shared.js"
import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PrInput,
    SearchButton,
    IndexTableProvider,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"]("contracts")
    return {
      items: null,
      headers: null,
      keywordHandler: newWordSearchHandler("keyword", filter),
    }
  },
  methods: {
    filterFunc: ({ keyword }) => item => {
      let test = true

      test = keywordFilter(keyword, test, item)

      return test
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const { ok, body } = await api.get(
        paths.ADMIN_CONTRACTS,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      const maxRequestKey2Length = body.reduce(
        (acc, { request_key2 }) =>
          acc < request_key2.length ? request_key2.length : acc,
        1,
      )

      this.headers = [
        {
          text: "見積管理No",
          value: ({ request_key2 }) =>
            request_key2.padStart(maxRequestKey2Length, "0"),
          link: ({ request_key2, request_number }) =>
            `/admin/contracts/${request_key2}/dealings/${request_number}`,
          width: 9,
        },
        { text: "見積名", value: "request_name", width: 20 },
        {
          text: "商談名",
          value: "request.request_name",
          link: ({ request_number }) => `/admin/dealings/${request_number}`,
          width: 20,
        },
        { text: "担当者", value: "request.user.name", width: 8 },
        {
          text: "見積日",
          value: ({ request_date1 }) => getDate(request_date1),
          width: 8,
        },
        {
          text: "有効期限",
          value: ({ contract: { expiration_date } }) =>
            getDate(expiration_date),
          width: 10,
        },
        {
          text: "小計",
          value: ({ contract: { total_amount, discount } }) =>
            formatPrice(+total_amount + +discount),
          width: 7,
        },
        {
          text: "値引き",
          value: ({ contract: { discount } }) => formatPrice(discount),
          width: 7,
        },
        {
          text: "合計(税込)",
          value: ({ contract: { total_amount, tax_amount } }) =>
            formatPrice(Number(total_amount) + Number(tax_amount)),
          width: 11,
        },
      ]
      this.items = body
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>
