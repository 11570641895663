var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items !== null
    ? _c(
        "IndexTableProvider",
        {
          staticStyle: { width: "1800px" },
          attrs: {
            items: _vm.items,
            headers: _vm.headers,
            "filter-func": _vm.filterFunc,
            "page-id": "omcontracts",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "filter",
                fn: function ({
                  filter,
                  handleTextInput,
                  handleFlagOn,
                  handleSelectInput,
                  filterClass,
                }) {
                  return _c(
                    "div",
                    { class: filterClass },
                    [
                      _c("PrSelect", {
                        attrs: {
                          label: "見積内容",
                          "pr-row": [100],
                          value: filter.contentStatus,
                          options: _vm.contentStatusOptions,
                        },
                        on: {
                          input: function ($event) {
                            return handleSelectInput($event, "contentStatus")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("PrSelect", {
                        attrs: {
                          label: "見積先",
                          "pr-row": [100],
                          value: filter.supplier,
                          options: _vm.supplierOptions,
                        },
                        on: {
                          input: function ($event) {
                            return handleSelectInput($event, "supplier")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "PrInput",
                        {
                          staticStyle: { "justify-content": "space-between" },
                          attrs: {
                            label: "キーワード",
                            "pr-row": [100, 85],
                            value: _vm.keywordHandler.value,
                          },
                          on: { input: _vm.keywordHandler.input },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.keywordHandler.keydown.apply(
                                null,
                                arguments
                              )
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.keywordHandler.keyup(
                                $event,
                                handleFlagOn,
                                handleTextInput,
                                filter
                              )
                            },
                          },
                        },
                        [
                          _c("SearchButton", {
                            attrs: {
                              slot: "after",
                              close: _vm.keywordHandler.isOn,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.keywordHandler.click(
                                  handleFlagOn,
                                  handleTextInput,
                                  filter
                                )
                              },
                            },
                            slot: "after",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "data",
                fn: function ({
                  pagedItems,
                  pageNo,
                  dataTableClass,
                  thTextClass,
                  activeClass,
                  descClass,
                  sortClass,
                  sort,
                  handleSort,
                }) {
                  return _c("div", {}, [
                    _c(
                      "table",
                      { class: dataTableClass },
                      [
                        _c(
                          "tr",
                          [
                            _vm._l(
                              _vm.headers,
                              function ({ text, value, noSort, width }, i) {
                                return [
                                  text !== "not_for_display"
                                    ? _c(
                                        "th",
                                        {
                                          key: i,
                                          class: noSort ? false : sortClass,
                                          style: `width:${width}%`,
                                          on: {
                                            click: function ($event) {
                                              noSort ? false : handleSort(i)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: noSort
                                                ? thTextClass
                                                : [
                                                    thTextClass,
                                                    {
                                                      [activeClass]:
                                                        sort.colIdx == i,
                                                    },
                                                    {
                                                      [descClass]:
                                                        sort.colIdx == i &&
                                                        sort.isDesc,
                                                    },
                                                  ],
                                            },
                                            [_vm._v(_vm._s(text))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(pagedItems[pageNo], function (item, i) {
                          return _c(
                            "tr",
                            { key: i },
                            [
                              _vm._l(item, function (val, j) {
                                return [
                                  !val.startsWith("not_for_display")
                                    ? _c(
                                        "td",
                                        { key: j },
                                        [
                                          val.includes("#link#")
                                            ? _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: val.split("#link#")[1],
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        val.split("#link#")[0]
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ]
                                              )
                                            : val.includes("#editbutton#")
                                            ? [
                                                !_vm.isEdit
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          flat: "",
                                                          icon: "",
                                                          color: "indigo",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.handleEdit(
                                                                  val.split(
                                                                    "#editbutton#"
                                                                  )[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("edit")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm.isEdit &&
                                                    _vm.isEditTarget(
                                                      val.split(
                                                        "#editbutton#"
                                                      )[0]
                                                    )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              flat: "",
                                                              icon: "",
                                                              color: "indigo",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.handleEdit(
                                                                  val.split(
                                                                    "#editbutton#"
                                                                  )[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "done_outline"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              flat: "",
                                                              icon: "",
                                                              color: "indigo",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleEditCancel()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("close"),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          flat: "",
                                                          icon: "",
                                                          color: "indigo",
                                                          disabled: "",
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("edit"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            : val.includes("#editPurchase#")
                                            ? [
                                                _vm.isEditTarget(
                                                  val.split("#editPurchase#")[1]
                                                )
                                                  ? [
                                                      _c("PrInput", {
                                                        attrs: {
                                                          name:
                                                            "purchase_amount_" +
                                                            i,
                                                          "pr-row": [0, 100],
                                                          validate: "numeric",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.editValue
                                                              .purchase_amount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editValue,
                                                              "purchase_amount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editValue.purchase_amount",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.isPurchaseAmountValide
                                                        ? [
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _c("PrErrorText", [
                                                              _vm._v(
                                                                "仕入額を入力してください"
                                                              ),
                                                            ]),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("formatValue")(
                                                            val.split(
                                                              "#editPurchase#"
                                                            )[0]
                                                          )
                                                        )
                                                      ),
                                                    ],
                                              ]
                                            : val.includes("#editMerchandise#")
                                            ? [
                                                _vm.isEditTarget(
                                                  val.split(
                                                    "#editMerchandise#"
                                                  )[1]
                                                )
                                                  ? [
                                                      _c("PrInput", {
                                                        attrs: {
                                                          name:
                                                            "merchandise_cost_" +
                                                            i,
                                                          "pr-row": [0, 100],
                                                          validate: "numeric",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.editValue
                                                              .merchandise_cost,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editValue,
                                                              "merchandise_cost",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editValue.merchandise_cost",
                                                        },
                                                      }),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("formatValue")(
                                                            val.split(
                                                              "#editMerchandise#"
                                                            )[0]
                                                          )
                                                        )
                                                      ),
                                                    ],
                                              ]
                                            : val.includes("#editWeight1#")
                                            ? [
                                                _vm.isEditTarget(
                                                  val.split("#editWeight1#")[1]
                                                )
                                                  ? _c("PrInput", {
                                                      attrs: {
                                                        name: "weight1_" + i,
                                                        "pr-row": [0, 100],
                                                        validate: "decimal:3",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editValue.weight1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editValue,
                                                            "weight1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editValue.weight1",
                                                      },
                                                    })
                                                  : [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("formatValue")(
                                                            val.split(
                                                              "#editWeight1#"
                                                            )[0]
                                                          )
                                                        )
                                                      ),
                                                    ],
                                              ]
                                            : val.includes("#calcPrice#")
                                            ? [
                                                _vm.isEditTarget(
                                                  val.split("#calcPrice#")[1]
                                                )
                                                  ? [
                                                      !_vm.editValue.weight1 ||
                                                      _vm.editValue.weight1 ===
                                                        "0"
                                                        ? _c("PrInput", {
                                                            attrs: {
                                                              name:
                                                                "price_" + i,
                                                              "pr-row": [
                                                                0, 100,
                                                              ],
                                                              validate:
                                                                "required|decimal:3",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editValue
                                                                  .price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.editValue,
                                                                    "price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "editValue.price",
                                                            },
                                                          })
                                                        : [
                                                            _vm._v(
                                                              "\n                  " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatPrice"
                                                                  )(
                                                                    _vm.calcPrice()
                                                                  )
                                                                ) +
                                                                "\n                "
                                                            ),
                                                          ],
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("formatPrice")(
                                                            val.split(
                                                              "#calcPrice#"
                                                            )[0]
                                                          )
                                                        )
                                                      ),
                                                    ],
                                              ]
                                            : [_vm._v(_vm._s(val))],
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ])
                },
              },
            ],
            null,
            false,
            2023625839
          ),
        },
        [
          _c("template", { slot: "page-title" }, [
            _vm._v("\n    オーダーメイド見積一覧\n  "),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }