var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [
            _vm._v("取引先" + _vm._s(_vm.isNew ? "新規作成" : "詳細")),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.row },
            [
              _c(
                "InfoBlock",
                { attrs: { width: "49%", block: "" } },
                [
                  _c("template", { slot: "head" }, [_vm._v("取引先概要")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.forms },
                    [
                      _c("PrInput", {
                        attrs: {
                          label: "取引先名",
                          "pr-row": [30],
                          name: "name",
                          validate: "required",
                        },
                        model: {
                          value: _vm.values.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "name", $$v)
                          },
                          expression: "values.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "略称", "pr-row": [30] },
                        model: {
                          value: _vm.values.abbreviated_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "abbreviated_name", $$v)
                          },
                          expression: "values.abbreviated_name",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "取引先コード", "pr-row": [30] },
                        model: {
                          value: _vm.values.customer_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "customer_code", $$v)
                          },
                          expression: "values.customer_code",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrSelect", {
                        attrs: {
                          label: "業種",
                          "pr-row": [30],
                          options:
                            _vm.$store.getters["defs/getOptions"]("Industry"),
                        },
                        model: {
                          value: _vm.values.industry,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "industry", $$v)
                          },
                          expression: "values.industry",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "Webサイト", "pr-row": [30] },
                        model: {
                          value: _vm.values.hp_url,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "hp_url", $$v)
                          },
                          expression: "values.hp_url",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrCheckbox", {
                        attrs: { "before-label": "料金後払", "pr-row": [30] },
                        model: {
                          value: _vm.values.deferred_payment,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "deferred_payment", $$v)
                          },
                          expression: "values.deferred_payment",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "割引率(%)",
                          "pr-row": [30],
                          name: "discount",
                          validate: "decimal:1|between:0,100",
                        },
                        model: {
                          value: _vm.values.discount,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "discount", $$v)
                          },
                          expression: "values.discount",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "説明", "pr-row": [30] },
                        model: {
                          value: _vm.values.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "description", $$v)
                          },
                          expression: "values.description",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "特記事項", "pr-row": [30] },
                        model: {
                          value: _vm.values.notices,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "notices", $$v)
                          },
                          expression: "values.notices",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrCheckbox", {
                        attrs: { "before-label": "特定顧客", "pr-row": [30] },
                        model: {
                          value: _vm.values.specific,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "specific", $$v)
                          },
                          expression: "values.specific",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "InfoBlock",
                { attrs: { width: "49%", block: "" } },
                [
                  _c("template", { slot: "head" }, [_vm._v("所在地")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.forms },
                    [
                      _c("PrCheckbox", {
                        attrs: {
                          "before-label": "会社所在地不明",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.billing_unknown,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "billing_unknown", $$v)
                          },
                          expression: "values.billing_unknown",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "PrInput",
                        {
                          attrs: {
                            label: "郵便番号",
                            "pr-row": [30, 50],
                            name: "zip_code",
                            validate: "zipcode",
                          },
                          model: {
                            value: _vm.values.zip_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.values, "zip_code", $$v)
                            },
                            expression: "values.zip_code",
                          },
                        },
                        [
                          _c("ButtonSerchZipCode", {
                            attrs: {
                              slot: "after",
                              zipcode: _vm.values.zip_code,
                            },
                            on: { click: _vm.handleZipSearch },
                            slot: "after",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "都道府県", "pr-row": [30] },
                        model: {
                          value: _vm.values.location,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "location", $$v)
                          },
                          expression: "values.location",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "市区郡", "pr-row": [30] },
                        model: {
                          value: _vm.values.address1,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "address1", $$v)
                          },
                          expression: "values.address1",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "町名・番地", "pr-row": [30] },
                        model: {
                          value: _vm.values.address2,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "address2", $$v)
                          },
                          expression: "values.address2",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { label: "建物名", "pr-row": [30] },
                        model: {
                          value: _vm.values.building,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "building", $$v)
                          },
                          expression: "values.building",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "電話番号",
                          "pr-row": [30],
                          name: "phone_number",
                          validate: "telephone",
                        },
                        model: {
                          value: _vm.values.phone_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "phone_number", $$v)
                          },
                          expression: "values.phone_number",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "FAX",
                          "pr-row": [30],
                          name: "fax",
                          validate: "fax",
                        },
                        model: {
                          value: _vm.values.fax,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "fax", $$v)
                          },
                          expression: "values.fax",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isNew
            ? [
                _c(
                  "RelatedInfo",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { infoList: _vm.purchaseInfo },
                  },
                  [_vm._v("購買情報")]
                ),
                _vm._v(" "),
                _c(
                  "InfoBlock",
                  { staticStyle: { "margin-bottom": "20px" } },
                  [
                    _c("template", { slot: "head" }, [
                      _vm._v("取引先更新履歴"),
                    ]),
                    _vm._v(" "),
                    _c("InnerTable", {
                      attrs: {
                        head: _vm.histories.head,
                        body: _vm.histories.body,
                        "link-contain": true,
                      },
                    }),
                    _vm._v(" "),
                    _vm.histories.body.length === 0
                      ? _c("p", { class: _vm.$style.noParson }, [
                          _vm._v("\n        履歴がありません\n      "),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "InfoBlock",
                  { staticStyle: { "margin-bottom": "20px" } },
                  [
                    _c("template", { slot: "head" }, [_vm._v("取引先責任者")]),
                    _vm._v(" "),
                    _c("InnerTable", {
                      attrs: {
                        head: _vm.parson.head,
                        body: _vm.parson.body,
                        "link-contain": true,
                      },
                    }),
                    _vm._v(" "),
                    _vm.parson.body.length === 0
                      ? _c("p", { class: _vm.$style.noParson }, [
                          _vm._v(
                            "\n        登録された取引先責任者はありません\n      "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "InfoBlock",
                  { staticStyle: { "margin-bottom": "20px" } },
                  [
                    _c("template", { slot: "head" }, [_vm._v("商談")]),
                    _vm._v(" "),
                    _c("InnerTable", {
                      attrs: {
                        head: _vm.dealings.head,
                        body: _vm.dealings.body,
                        "link-contain": true,
                      },
                    }),
                    _vm._v(" "),
                    _vm.dealings.body.length === 0
                      ? _c("p", { class: _vm.$style.noParson }, [
                          _vm._v("\n        商談実績はありません\n      "),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "RelatedInfo",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { infoList: _vm.relaedInfo },
                  },
                  [_vm._v("関連情報")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.buttons_center },
            [
              _c(
                "Button",
                {
                  attrs: { color: "submit", size: "main" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSaveButtonClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "編集内容" + _vm._s(_vm.isNew ? "で追加する" : "を保存する")
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "Button",
                    {
                      attrs: { color: "cancel", size: "assist" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.initValues.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("クリア")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c("DialogProvider", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: `本当に${_vm.items.organization.name}を削除しますか？`,
                        yas: "はい",
                        no: "いいえ",
                      },
                    },
                    on: { accept: _vm.handleDelete },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return _c(
                              "Button",
                              _vm._g(
                                { attrs: { color: "alert", size: "assist" } },
                                on
                              ),
                              [_vm._v("削除")]
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2488080323
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }