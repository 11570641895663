<template>
  <div v-if="items !== null" :class="$style.wrapper">
    <PageTitle>税率{{ isNew ? "新規作成" : "詳細" }}</PageTitle>
    <InfoBlock>
      <template slot="head"
        >税率概要</template
      >
      <InfoList v-model="values" :list="outlineList" />
    </InfoBlock>
    <RelatedInfo v-if="!isNew" :infoList="relatedInfo">関連情報</RelatedInfo>
    <div :class="$style.buttons_center">
      <Button color="submit" size="main" @click.native="handleSaveButtonClick"
        >編集内容を保存する</Button
      >
      <Button
        v-if="!isNew"
        color="cancel"
        size="assist"
        @click.native="initValues"
        >クリア</Button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import InfoList from "@/components/molecules/InfoList.vue"
import RelatedInfo from "@/components/molecules/RelatedInfo.vue"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"

export default {
  components: {
    Button,
    PageTitle,
    InfoBlock,
    InfoList,
    RelatedInfo,
  },
  data() {
    return {
      isNew: false,
      items: null,
      values: null,
    }
  },
  computed: {
    relatedInfo() {
      if (this.isNew) {
        return [["更新者", ""], ["更新日", ""]]
      } else {
        const body = this.items
        const [updatedAt] = [body.updated_at].map(v =>
          moment(v, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD"),
        )
        return [["更新者", body.inserted.name], ["更新日", updatedAt]]
      }
    },
    outlineList() {
      return [
        {
          name: "税率名",
          key: "name",
          type: "text",
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
        {
          name: "適用開始日",
          key: "start_datetime",
          type: "datepicker",
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
        {
          name: "税区分",
          key: "tax_category",
          type: "text",
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
        {
          name: "税率(%)",
          key: "tax_rate",
          type: "text",
          validate: {
            rule: "required|between:0,100",
            message: {
              required: "必須項目です。",
              numeric: "数値を入力してください。",
            },
          },
        },
      ]
    },
  },
  methods: {
    async validate() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      console.log("_result=", result)
      return result
    },
    async handleSaveButtonClick() {
      const result = await this.validate()
      console.log("validate = ", result)
      if (!result) {
        return
      }
      const time = moment(this.values.start_datetime)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssZ")

      let data = {
        tax_category: this.values.tax_category,
        name: this.values.name,
        tax_rate: this.values.tax_rate,
        start_datetime: time,
      }
      if (!this.isNew) {
        data.lock_version = this.items.lock_version
      }

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.post(
          paths.ADMIN_TAXES,
          data,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$router.replace({ path: "/admin/taxes" })
      } finally {
        loader.hide()
      }
    },
    initValues() {
      this.$validator.reset()

      this.values = {
        name: this.items.name,
        start_datetime: moment(
          this.items.start_datetime,
          "YYYY-MM-DDTHH:mm:ssZ",
        ).format("YYYY/MM/DD"),
        tax_category: this.items.tax_category,
        tax_rate: this.items.tax_rate,
      }
    },
  },
  async created() {
    if (this.$route.name === "adminTaxNew") {
      this.isNew = true
      this.items = {
        name: "",
        start_datetime: moment(new Date(), "YYYY-MM-DDTHH:mm:ssZ").format(
          "YYYY/MM/DD",
        ),
        tax_category: "",
        tax_rate: "",
      }
      this.initValues()

      return
    }

    const { ok, body } = await api.get(
      `${paths.ADMIN_TAXES}/${this.$route.params.tax_id}`,
      this.$store.state.auth.token,
    )
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }

    this.items = body
    this.initValues()
  },
}
</script>

<style lang="scss" module>
@include dbDetailView();
</style>
