<template>
  <div v-if="items" :class="$style.wrapper">
    <p :class="$style.pageTtl">検品内容 {{ today }}</p>
    <table :class="$style.table">
      <thead :class="$style.table_thead">
        <tr :class="$style.table_thead_tr">
          <td :class="$style.table_thead_tr_td">商談名</td>
          <td :class="$style.table_thead_tr_td">最終確認画像</td>
          <td :class="$style.table_thead_tr_td">見積り名</td>
          <td :class="$style.table_thead_tr_td">出庫箱数</td>
          <td :class="$style.table_thead_tr_td" style="line-height:1.1em;">
            出庫商品 <br />総数
          </td>
          <td :class="$style.table_thead_tr_td">保管商品個数</td>
          <td :class="$style.table_thead_tr_td" style="line-height:1.1em;">
            発送商品 <br />総数
          </td>
          <td :class="$style.table_thead_tr_td">発送予定日</td>
          <td :class="$style.table_thead_tr_td">納品希望日</td>
          <td :class="$style.table_thead_tr_td">備考</td>
          <td :class="$style.table_thead_tr_td">公開</td>
        </tr>
      </thead>
      <tbody :class="$style.table_tbody">
        <tr
          v-for="deal in inspections"
          :key="deal.id"
          :class="$style.table_tbody_tr"
        >
          <td :class="$style.table_tbody_tr_td">{{ deal.request_name }}</td>
          <td :class="$style.table_tbody_tr_td">
            <img
              style="width:25mm;"
              @load="handleImgLoad"
              :ref="`img_${deal.id}`"
              :src="
                deal.request_appendices.find(
                  ({ appendix_category }) =>
                    appendix_category === 'verification_image',
                ).appendix_name
              "
            />
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ deal.contract.request_name }}
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ deal.product.quantity3 }}
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ deal.product.quantity4 }}
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ deal.product.quantity6 }}
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ +deal.product.quantity4 - +deal.product.quantity6 }}
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ deal.delivery.request_date2 | getDate }}
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ deal.request_date2 | getDate }}
          </td>
          <td :class="$style.table_tbody_tr_td">
            <p :class="$style.table_tbody_tr_td_p">
              <span
                v-for="{ name, text } in checkFlags(deal)"
                :class="$style.note"
                :name="name"
                :key="name"
                >{{ text }}</span
              >
              <br />
              {{ deal.product.note2 }}
            </p>
          </td>
          <td :class="$style.table_tbody_tr_td">
            {{ deal.contract.note2 | releaseInfo }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api, { paths } from "@/utils/api.js"
import { getDate, routeReplaceError } from "@/utils/shared.js"
import { checkFlags } from "@/helpers/AdminDealingIndexCardHelper.js"

export default {
  data() {
    return {
      items: null,
      isImgsComplete: false,
    }
  },
  computed: {
    today() {
      const date = new Date()
      const y = date.getFullYear()
      const m = `${date.getMonth() + 1}`.padStart(2, "0")
      const d = date.getDate()
      return `${y}/${m}/${d}`
    },
    inspections() {
      return this.items.filter(({ status }) => status === 14)
    },
  },
  filters: {
    getDate,
    releaseInfo: note2 => {
      if (note2 == "公開しても良い") {
        return "○"
      }
      if (note2 == "会社名、店舗名を記載の上、公開しても良い") {
        return "△"
      }
      if (note2 == "公開しないで欲しい") {
        return "☓"
      }
      if (note2 == "その他") {
        return "その他"
      }
      return "未設定"
    },
  },
  methods: {
    checkFlags,
    handleImgLoad() {
      console.log("img loaded")
      this.isImgsComplete = this.inspections.reduce(
        (acc, { id }) => acc && this.$refs[`img_${id}`][0].complete,
        true,
      )
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const { ok, body } = await api.get(
        paths.ADMIN_DEALINGS_INSPECT,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
    } finally {
      loader.hide()
    }
  },
  mounted() {
    const header = document.getElementById("header")
    header.style.display = "none"
  },
  // watch: {
  //   isImgsComplete(val) {
  //     if (!val) return
  //
  //     window.print()
  //     window.close()
  //   },
  // },
}
</script>

<style lang="scss" module>
.wrapper {
  background-color: map-get($colors, white);
}

.pageTtl {
  margin-bottom: 20px;
  text-align: center;
}

.table {
  width: 100%;
  padding-bottom: 20px;
  border-style: none;
  border-spacing: 0;
  font-size: 2mm;
  text-align: center;
  &_thead_tr_td {
    border-bottom: 1px solid map-get($colors, darkGlay);
  }
  &_tbody {
    &_tr {
      &_td {
        padding: 5px 0;
        border-bottom: 1px solid map-get($colors, darkGlay);
        &_p {
          width: 180px;
          display: inline-block;
          text-align: left;
        }
      }
    }
  }
}
.note {
  display: inline-block;
  margin: 2px;
  padding: 0.3em 0.5em;
  background-color: lime;
  border-radius: 5px;
  border: 1px solid black;
  font-weight: bold;
  &[name="draft"] {
    background-color: #00eb56;
  }
  &[name="data_check"] {
    background-color: #51e5e1;
  }
  &[name="noImage"] {
    background-color: #272727;
    color: #fff;
  }
  &[name="proofreading"] {
    background-color: #fdd11b;
  }
  &[name="cod"] {
    background-color: #fb3031;
  }
  &[name="separate"] {
    background-color: #20fb26;
  }
  &[name="invoice"] {
    background-color: #d703fc;
    color: #fff;
  }
  &[name="defective"] {
    background-color: #eb6304;
  }
  &[name="time_service"] {
    background-color: #00ff00;
  }
  &[name="internal_job"] {
    background-color: #2b5cfd;
    color: #fff;
  }
  &[name="note"] {
    background-color: #2b3ffb;
    color: #fff;
  }
}
</style>
