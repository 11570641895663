<template>
  <div v-if="customers && customerPersons" :class="$style.wrapper">
    <PageTitle>商談新規作成</PageTitle>
    <InfoBlock width="100%" block center>
      <template slot="head">
        商談情報
      </template>
      <div :class="$style.forms">
        <PrSelect
          label="取引先"
          :pr-row="[40]"
          :value="values.request_key2"
          @input="handleCustomerSelect"
          :options="customerOptions"
          :searchable="true"
          name="request_key2"
          :validate="'required'"
        />
        <PrSelect
          label="取引先責任者"
          :pr-row="[40]"
          :value="values.request_key3"
          @input="handleCustomerPersonSelect"
          :options="customerPersonOptions"
          :searchable="true"
          name="request_key3"
          :validate="'required'"
        />
        <PrSelect
          label="フェーズ"
          :pr-row="[40]"
          v-model="values.status"
          :options="
            $store.getters['defs/getOptionsByDefinition'](
              'Progress',
              'display_discernment_code',
              0,
            )
          "
          name="status"
          :validate="'required'"
        />
        <PrDatePicker
          label="完了予定日"
          :pr-row="[40]"
          v-model="values.request_date3"
        />
      </div>
    </InfoBlock>

    <InfoBlock width="100%" block center>
      <template slot="head">
        配送情報
      </template>
      <div :class="$style.forms">
        <PrSelect
          label="納品書情報"
          :pr-row="[40]"
          v-model="values.delivery.quantity3"
          :options="[{ key: 0, value: 'なし' }, { key: 1, value: 'あり' }]"
        />
        <PrCheckbox
          before-label="ヤマトタイムサービス"
          :pr-row="[40]"
          v-model="values.delivery.quantity4"
        />
      </div>
    </InfoBlock>

    <InfoBlock width="100%" block center>
      <template slot="head">
        サンプル送付情報
      </template>
      <div :class="$style.forms">
        <PrDatePicker
          label="サンプル請求受付日"
          :pr-row="[40]"
          v-model="values.inquiry.request_date3"
        />
        <PrInput
          label="サンプル請求内容"
          :pr-row="[40]"
          v-model="values.inquiry.note1"
        />
        <PrTextarea
          label="サンプル請求備考"
          :pr-row="[19]"
          v-model="values.inquiry.note2"
        />
      </div>
    </InfoBlock>

    <InfoBlock width="100%" block center>
      <template slot="head">
        問い合わせ情報
      </template>
      <div :class="$style.forms">
        <PrDatePicker
          label="問合せ日"
          :pr-row="[40]"
          v-model="values.inquiry.request_date1"
        />
        <PrSelect
          label="問合せトラフィック"
          :pr-row="[40]"
          v-model="values.inquiry.quantity1"
          :options="$store.getters['defs/getOptions']('InquiryRoute')"
        />
        <PrTextarea
          label="問合せ内容"
          :pr-row="[19]"
          v-model="values.inquiry.description"
        />
      </div>
    </InfoBlock>

    <InfoBlock width="100%" block center>
      <template slot="head">
        その他
      </template>
      <div :class="$style.formsBig">
        <div class="access">
          <p>アクセス経路</p>
          <div>
            <PrCheckbox
              v-for="(label, idx) in [
                'ネット検索',
                'ネット広告',
                'ダイレクトメール',
                '展示会',
                '知人の紹介',
                '過去に注文したことがある',
                'その他',
              ]"
              :after-label="label"
              :key="label"
              v-model="access[idx]"
            />
          </div>
        </div>
        <PrTextarea
          label="アクセス経路その他"
          :pr-row="[19]"
          v-model="values.note3"
        />
        <PrTextarea label="備考" :pr-row="[19]" v-model="values.note1" />
      </div>
    </InfoBlock>

    <Button
      color="submit"
      size="main"
      @click.native="handleSaveButtonClick"
      style="margin-top:30px"
      >登録する</Button
    >
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import { deepCopy, showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

const access = [
  "ネット検索",
  "ネット広告",
  "ダイレクトメール",
  "展示会",
  "知人の紹介",
  "過去に注文したことがある",
  "その他",
]

export default {
  components: {
    PageTitle,
    PrTextarea,
    PrInput,
    PrCheckbox,
    PrDatePicker,
    PrSelect,
    Button,
    InfoBlock,
  },
  data() {
    return {
      customers: null,
      customerPersons: null,
      access: ["0", "0", "0", "0", "0", "0", "0"],
      values: {
        status: "", // フェーズ
        request_key2: "", // 取引先ID
        request_key3: "", // 取引先責任者ID
        request_date3: "", // 完了予定日
        quantity1: "0", // 見積入力(ステータス)
        quantity2: "0", // 見積確認(ステータス)
        quantity3: "0", // 発送通知メール(ステータス)
        quantity4: "0", // 発注書公開(ステータス)
        note1: "", // 備考
        note2: "", // アクセス経路
        note3: "", // アクセス経路その他
        delivery: {
          quantity4: "", // ヤマトタイムサービス
        },
        inquiry: {
          request_date1: "", // 問合せ日
          request_date3: "", // サンプル請求受付日
          quantity1: "", // 問合せトラフィック
          quantity2: "0", // 問合ステータス
          quantity3: "0", // 納品書有無
          description: "", // 問合せ内容
          note1: "", // サンプル請求内容
          note2: "", // サンプル請求備考
        },
        contract: {
          note2: "", // 公開情報
          note3: "", // 公開情報その他
        },
        product: {},
      },
    }
  },
  computed: {
    customerOptions() {
      return this.customers.map(({ id, name }) => ({
        key: String(id),
        value: name,
      }))
    },
    customerPersonOptions() {
      const filtered =
        this.values.request_key2 === ""
          ? this.customerPersons
          : this.customerPersons.filter(
              ({ customer_id }) =>
                this.values.request_key2 === String(customer_id),
            )

      return filtered.map(({ id, last_name, first_name }) => ({
        key: String(id),
        value: `${last_name} ${first_name}`,
      }))
    },
  },
  methods: {
    handleCustomerPersonSelect(e) {
      this.values.request_key3 = e
      this.values.request_key2 = this.customerPersons
        .find(({ id }) => String(id) === e)
        .customer_id.toString()
    },
    handleCustomerSelect(e) {
      this.values.request_key2 = e
      this.values.request_key3 = ""
    },
    async handleSaveButtonClick() {
      const result = await this.$validator.validateAll().then(r => r)
      if (!result) {
        return
      }

      const vals = deepCopy(this.values)

      vals.note2 = access
        .reduce(
          (acc, str, idx) => (this.access[idx] === "1" ? `${acc}/${str}` : acc),
          "",
        )
        .slice(1)

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.post(
          paths.ADMIN_DEALINGS,
          vals,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$router.replace(`/admin/dealings/${body.request_number}`)
      } finally {
        loader.hide()
      }
    },
  },
  async created() {
    const { ok: Ok, body: Body } = await api.get(
      paths.ADMIN_CUSTOMERS_AND_CUSTOMER_PERSONS,
      this.$store.state.auth.token,
    )

    if (!Ok) {
      this.$router.replace({
        name: "notfound",
        params: {
          statusCode: Body.slice(-3),
          url: this.$route.path,
        },
      })
      return
    }

    this.customers = Body.customers
    this.customerPersons = Body.customer_persons
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  > button {
    margin-bottom: 30px;
  }
}

.forms {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: calc(50% - 20px);
    margin-bottom: 15px;
    &[name="pr-textarea"] {
      width: 100%;
    }
    &[name="ac"] {
      display: flex;
      align-items: center;
      > p {
        width: 40%;
        color: map-get($colors, darkGlay);
      }
      div[class~="v-autocomplete"] {
        width: 60%;
        padding: 0;
        margin: 0;
        div[class="v-text-field__details"] {
          display: none;
        }
      }
    }
  }
}

.formsBig {
  > div {
    width: 100%;
    margin-bottom: 15px;
    &[class="access"] {
      display: flex;
      align-items: center;
      > p {
        width: 19%;
        color: map-get($colors, darkGlay);
      }
      > div {
        width: 81%;
        > div {
          margin: 0 10px 5px 0;
        }
      }
    }
  }
}
</style>
