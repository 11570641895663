var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { block: "", width: "48%", margin: "0" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    総額\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("p", [_vm._v("注文総額")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("formatPrice")(
                    _vm._f("calcFinalAllTotalPriceNoDiscount")(
                      _vm.values.contract.contract_details,
                      _vm.discount
                    )
                  )
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.isTotalMinus
            ? _c("PrErrorText", [
                _vm._v("注文総額を0円未満に設定することは出来ません。"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", [_vm._v("値引き")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("formatPrice")(
                  _vm.values.contract.contract_details.find(
                    ({ category2 }) => category2 == "discount"
                  ).price
                )
              ) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", [_vm._v("消費税")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("formatPrice")(
                  _vm._f("calcFinalAllTotalTax")(
                    _vm.values.contract.contract_details,
                    _vm.discount,
                    _vm.taxs,
                    _vm.values.request_date1
                  )
                )
              ) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", [_vm._v("注文総額（税込）")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("formatPrice")(
                  _vm._f("calcFinalAllTotalTaxIncudedPrice")(
                    _vm.values.contract.contract_details,
                    _vm.discount,
                    _vm.taxs,
                    _vm.values.request_date1
                  )
                )
              ) +
              "\n    "
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }