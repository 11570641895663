<template>
  <div v-if="isReady" :class="$style.rootWrapper">
    <PageTitle>アカウント情報</PageTitle>

    <section :class="$style.ankers">
      <Anker to="/customer/accounts/new-email"
        >メールアドレスの変更はこちら</Anker
      >
      <Anker to="/password/edit">パスワードの変更はこちら</Anker>
      <Anker to="/customer/accounts/mail-magazine"
        >メールマガジンの設定はこちら</Anker
      >
    </section>

    <section :class="$style.accounts">
      <div
        v-for="(item, index) in itemsAccount"
        :key="item.id"
        :class="$style.inner"
      >
        <h3 v-cloak>お客様情報{{ index + 1 }}</h3>

        <p>氏名(性)</p>
        <p v-cloak>
          {{ item.last_name }}
        </p>
        <p>氏名(名)</p>
        <p v-cloak>
          {{ item.first_name }}
        </p>
        <p>電話番号</p>
        <p v-cloak>{{ item.phone }}</p>
        <p>郵便番号</p>
        <p>
          {{ item.address.zip_code }}
        </p>
        <p>住所</p>
        <p v-cloak>
          {{ item.address.location }}
          {{ item.address.address1 }}
          {{ item.address.address2 }}
          {{ item.building }}
        </p>
        <Button color="submit" size="assist" @click="handleChangeUser(item.id)"
          >登録内容を変更する</Button
        >
      </div>
    </section>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import Anker from "@/components/atoms/Anker.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    Button,
    Anker,
    PageTitle,
  },
  data() {
    return {
      isReady: false,
      itemsAccount: [],
      receiveMail: "1",
    }
  },
  async created() {
    //------------------------
    // アカウント情報
    //------------------------
    // メールアドレス([0].user.email)
    // メールマガジン([0].mail_magazine_members)
    // 氏名(性)([x].last_name)
    // 氏名(名)([x].first_name)
    // 電話番号([x].phone)
    // 郵便番号([x].address.zip_code)
    // 住所([x].address.location + [x].address.address1 + [x].address.address2 + [x].building)
    let result = await this.getAccount()
    if (!result.ok) {
      routeReplaceError(this, result.body.slice(-3))
      return
    }
    this.itemsAccount = result.body

    this.receiveMail = this.itemsAccount[0].mail_magazine_members

    this.isReady = true
  },
  methods: {
    async getAccount() {
      const { ok: result, body: items } = await api.get(
        paths.CUSTOMER_USER,
        this.$store.state.auth.token,
      )

      return { ok: result, body: items }
    },
    handleChangeUser(id) {
      this.$router.push({ path: `/customer/accounts/users/${id}` })
    },
  },
}
</script>

<style lang="scss" module>
@include clientParts();

.rootWrapper {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.ankers {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > a {
    padding: 0 20px;
  }
}

.accounts {
  width: 100%;
  max-width: 1080px;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: map-get($colors, white);
  > div {
    width: 320px;
    margin: 10px;
    padding-bottom: 0;
    &:last-child {
      border-bottom: 1px dotted #000;
    }
    > h3 {
      text-align: center;
    }
    > button {
      margin: 10px auto;
    }
  }
}
</style>
