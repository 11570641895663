<template>
  <div v-if="isReady" :class="$style.wrapper">
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">確認</v-card-title>
          <v-card-text>削除しますか？</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="indigo darken-3" flat @click="deleteUser">OK</v-btn>
            <v-btn color="indigo darken-3" flat @click="dialog = false"
              >キャンセル</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <table :class="$style.table">
      <tr :class="$style.head">
        <td :class="$style.no">No.</td>
        <td :class="$style.name">表示名（名前）</td>
        <td :class="$style.mail">メールアドレス</td>
        <td :class="$style.authority">権限</td>
        <td :class="$style.edit"></td>
      </tr>
      <tr v-for="(item, index) in items" :key="index" :class="$style.body">
        <td>
          <Button color="alert" size="assist" @click="handleDelete(item.id)"
            >削除</Button
          >
          <span :class="$style.pl10" v-cloak>{{ index + 1 }}</span>
        </td>
        <td v-cloak>{{ item.name }}</td>
        <td v-cloak>{{ item.email }}</td>
        <td v-cloak>{{ toRoleString(item.role) }}</td>
        <td>
          <Anker :to="'/admin/users/' + item.id">編集する</Anker>
        </td>
      </tr>
    </table>

    <div :class="$style.newButton">
      <Button color="submit" size="main" @click="handleNew"
        >新しいユーザーを追加する</Button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import Anker from "@/components/atoms/Anker.vue"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    Anker,
    Button,
  },
  data() {
    return {
      isReady: false,
      items: null,
      dialog: false,
      deleteUserId: null,
    }
  },
  methods: {
    toRoleString(value) {
      return this.$store.getters["defs/getOptionValueByKey"]("Role", value)
    },
    handleNew() {
      this.$router.push({ path: "/admin/users/new" })
    },
    handleDelete(id) {
      this.deleteUserId = id
      this.dialog = true
    },
    async deleteUser() {
      this.dialog = false

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_LOGICAL_DELETE}/${this.deleteUserId}`,
          null,
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg, "削除に失敗しました。")
          return
        }

        await this.init()
      } finally {
        loader.hide()
      }
    },
    async init() {
      const { ok, body } = await api.post(
        paths.ADMIN_SEARCH_USERS,
        {
          and: [{ status: "1" }],
          or: [{ role: "admin" }, { role: "operator" }],
        },
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      await this.init()
      this.isReady = true
    } finally {
      loader.hide()
    }
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 1100px;
  padding: 50px 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}
.table {
  margin: 0 auto;
  text-align: center;
  padding-top: 45px;
  display: block;
  border-collapse: collapse;
  position: relative;
  &::before {
    content: "登録済みユーザー";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.2rem;
    font-weight: bold;
    color: #212121;
  }
}
.head {
  text-align: center;
  background-color: #8f8f8f;
  color: #fff;
}
.body {
  height: 50px;

  &:nth-child(even) {
    background-color: #f9f9f9;
  }
}
.no {
  min-width: 130px;
}
.pl10 {
  padding-left: 10px;
}
.name {
  min-width: 140px;
}
.mail {
  min-width: 300px;
}
.authority {
  min-width: 210px;
}
.edit {
  min-width: 120px;
}

.newButton {
  margin-top: 20px;
  text-align: center;
}
</style>
