exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".AdminDealingDetail__wrapper--3Z0R9 {\n  width: 1460px;\n  margin: 20px auto;\n}\n.AdminDealingDetail__section--33g8R {\n  margin-bottom: 20px;\n}\n.AdminDealingDetail__flex--2CxOy {\n  display: flex;\n  justify-content: space-between;\n}\n.AdminDealingDetail__flex_left--29KSQ {\n    width: 1115px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n.AdminDealingDetail__flex_right--lmpMy {\n    width: 330px;\n}\n", "",{"version":3,"sources":["/client/src/components/organisms/admin/AdminDealingDetail.vue"],"names":[],"mappings":"AAKA;EACE,aAAa;EACb,iBAAiB;AAAA;AAGnB;EACE,mBAAmB;AAAA;AAGrB;EACE,aAAa;EACb,8BAA8B;AAAA;AAC9B;IACE,aAAa;IACb,aAAa;IACb,eAAe;IACf,8BAA8B;AAAA;AAEhC;IACE,YAAY;AAAA","file":"AdminDealingDetail.vue?vue&type=style&index=0&id=771cbb36&lang=scss&module=true","sourcesContent":["\n                @import \"@/scss/_variables.scss\";\n                @import \"@/scss/_mixin_function.scss\";\n              \n\n.wrapper {\n  width: 1460px;\n  margin: 20px auto;\n}\n\n.section {\n  margin-bottom: 20px;\n}\n\n.flex {\n  display: flex;\n  justify-content: space-between;\n  &_left {\n    width: 1115px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n  &_right {\n    width: 330px;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"wrapper": "AdminDealingDetail__wrapper--3Z0R9",
	"section": "AdminDealingDetail__section--33g8R",
	"flex": "AdminDealingDetail__flex--2CxOy",
	"flex_left": "AdminDealingDetail__flex_left--29KSQ",
	"flex_right": "AdminDealingDetail__flex_right--lmpMy"
};