<template>
  <InfoBlock width="100%" margin="20px 0 0" block>
    <template slot="head">
      見積品目
    </template>

    <div :class="$style.estimates">
      <h3>既成品見積品目一覧</h3>
      <QuotationDetailMadeModalRM
        :productGroup="productGroup"
        @saved="handeleAdd"
      />
      <p v-if="readyMades.length === 0" :class="$style.nodata">
        未登録
      </p>
      <table v-else :class="$style.table">
        <thead :class="$style.table_head">
          <tr :class="$style.table_head_tr">
            <th>商品ファミリ</th>
            <th>商品名</th>
            <th>カバー・台紙サイズ</th>
            <th>付箋サイズ</th>
            <th>付箋印刷</th>
            <th>付箋枚数</th>
            <th>価格表個数</th>
            <th>注文個数</th>
            <th>単価</th>
            <th>総額</th>
            <th style="width:110px;"></th>
          </tr>
        </thead>
        <tbody :class="$style.table_body">
          <tr v-for="cd in readyMades" :key="cd.id">
            <td>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "ProductFamily",
                  cd.category1,
                )
              }}
            </td>
            <td>{{ cd.name }}</td>
            <td>{{ cd.size1 }}</td>
            <td>{{ cd.size2 }}</td>
            <td>
              {{
                $store.getters["defs/getOptionValueByKey"]("TagPrint", cd.color)
              }}
            </td>
            <td>{{ cd.size3 }}</td>
            <td>{{ cd.size4 }}</td>
            <td>{{ cd.amount }}</td>
            <td>{{ cd | calcReadyUnitPrice(discount) }}</td>
            <td>{{ cd | calcReadyTotalPrice(discount) }}</td>
            <td style="display:inline-flex;width:110px;">
              <QuotationDetailMadeModalRM
                :productGroup="productGroup"
                :contructDetail="cd"
                @saved="handleEdit"
              />
              <QuotationDetailDialog
                :texts="{
                  ttl: '確認',
                  msg: `見積品目を削除しますか？`,
                  yas: '削除する',
                  no: 'キャンセル',
                }"
                @accept="handleDeleteAccept(cd.id)"
              >
                <template slot-scope="{ on }">
                  <v-btn flat icon color="black" v-on="on">
                    <v-icon>delete_forever</v-icon>
                  </v-btn>
                </template>
              </QuotationDetailDialog>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div :class="$style.estimates">
      <h3>オーダーメイド見積品目一覧</h3>
      <QuotationDetailMadeModalOM @saved="handeleAdd" />
      <p v-if="orderMades.length === 0" :class="$style.nodata">
        未登録
      </p>
      <table v-else :class="$style.table">
        <thead :class="$style.table_head">
          <tr :class="$style.table_head_tr">
            <th>商品ファミリ</th>
            <th>カバー・台紙サイズ</th>
            <th>カバー・台紙型抜き</th>
            <th>付箋サイズ</th>
            <th>付箋型抜き</th>
            <th>付箋印刷</th>
            <th>付箋枚数</th>
            <th>個数</th>
            <th>単価</th>
            <th>総額</th>
            <th style="width:110px;"></th>
          </tr>
        </thead>
        <tbody :class="$style.table_body">
          <tr v-for="cdom in orderMades" :key="cdom.id">
            <td>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "ProductFamily",
                  cdom.category1,
                )
              }}
            </td>
            <td>{{ cdom.size1 }}</td>
            <td>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "Cuttings",
                  cdom.category3,
                )
              }}
            </td>
            <td>{{ cdom.size2 }}</td>
            <td>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "Cuttings",
                  cdom.category4,
                )
              }}
            </td>
            <td>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "TagPrint",
                  cdom.color,
                )
              }}
            </td>
            <td>{{ cdom.size3 }}</td>
            <td>{{ cdom.amount }}</td>
            <td>{{ cdom | calcOrderUnitPrice }}</td>
            <td>{{ cdom | calcOrderTotalPrice }}</td>
            <td style="display:inline-flex;width:110px;">
              <QuotationDetailMadeModalOM
                :contructDetail="cdom"
                @saved="handleEdit"
                :key="cdom.id"
              />
              <QuotationDetailDialog
                :texts="{
                  ttl: '確認',
                  msg: `見積品目を削除しますか？`,
                  yas: '削除する',
                  no: 'キャンセル',
                }"
                @accept="handleDeleteAccept(cdom.id)"
              >
                <template slot-scope="{ on }">
                  <v-btn flat icon color="black" v-on="on">
                    <v-icon>delete_forever</v-icon>
                  </v-btn>
                </template>
              </QuotationDetailDialog>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import QuotationDetailMadeModalRM from "@/components/molecules/QuotationDetailMadeModalRM.vue"
import QuotationDetailMadeModalOM from "@/components/molecules/QuotationDetailMadeModalOM.vue"
import QuotationDetailDialog from "@/components/molecules/QuotationDetailDialog.vue"
import {
  calcReadyUnitPrice,
  calcReadyTotalPrice,
  calcOrderUnitPrice,
  calcOrderTotalPrice,
} from "@/helpers/QuotationDetailViewHelper.js"
import { deepCopy } from "@/utils/shared.js"

export default {
  components: {
    InfoBlock,
    QuotationDetailMadeModalRM,
    QuotationDetailMadeModalOM,
    QuotationDetailDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    productGroup: {
      type: Array,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    readyMades() {
      return this.value.contract.contract_details.filter(
        ({ category2 }) => category2 === "ready_made",
      )
    },
    orderMades() {
      return this.value.contract.contract_details.filter(
        ({ category2 }) => category2 === "order_made",
      )
    },
  },
  filters: {
    calcReadyUnitPrice,
    calcReadyTotalPrice,
    calcOrderUnitPrice,
    calcOrderTotalPrice,
  },
  methods: {
    handeleAdd(contructDetail) {
      console.log("contructDetail", contructDetail)
      const newValue = deepCopy(this.value)
      newValue.contract.contract_details.push(contructDetail)
      this.$emit("input", newValue)
    },
    handleEdit(contructDetail) {
      const newValue = deepCopy(this.value)
      const idx = newValue.contract.contract_details.findIndex(
        ({ id }) => id === contructDetail.id,
      )
      newValue.contract.contract_details[idx] = contructDetail
      this.$emit("input", newValue)
    },
    handleDeleteAccept(contructDetailId) {
      const newValue = deepCopy(this.value)
      const idx = newValue.contract.contract_details.findIndex(
        ({ id }) => id === contructDetailId,
      )
      newValue.contract.contract_details.splice(idx, 1)
      this.$emit("input", newValue)
    },
  },
}
</script>

<style lang="scss" module>
.estimates {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  > * {
    margin-bottom: 10px;
  }
}
.nodata {
  width: 100%;
  color: map-get($colors, white);
  background-color: #8f8f8f;
  text-align: center;
}
.table {
  width: 100%;
  text-align: center;
  border-style: none;
  border-spacing: 0;
  &_head {
    color: map-get($colors, white);
    background-color: #8f8f8f;
    &_tr_th {
      padding: 10px 0;
    }
  }
  &_body_tr {
    &:nth-child(even) {
      background-color: map-get($colors, lightlightGray);
    }
    &_td {
      padding: 15px 0;
    }
  }
}
</style>
