<template>
  <table :class="$style.table">
    <thead :class="$style.table_head">
      <tr :class="$style.table_head_tr">
        <th v-for="(th, i) in head" :class="$style.table_head_tr_th" :key="i">
          {{ th }}
        </th>
      </tr>
    </thead>
    <tbody :class="$style.table_body">
      <template v-if="!linkContain">
        <tr v-for="(tr, i) in body" :class="$style.table_body_tr" :key="i">
          <td v-for="(td, j) in tr" :class="$style.table_body_tr_td" :key="j">
            {{ td }}
          </td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="(tr, i) in body" :class="$style.table_body_tr" :key="i">
          <td v-for="(td, j) in tr" :class="$style.table_body_tr_td" :key="j">
            <router-link
              v-if="td && td.startsWith('|>')"
              :to="td.split(`|>`)[1]"
            >
              {{ td.split(`|>`)[2] }}
            </router-link>
            <template v-else>
              {{ td }}
            </template>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    head: {
      type: Array,
      required: true,
    },
    body: {
      type: Array,
      required: true,
    },
    linkContain: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" module>
.table {
  width: 100%;
  text-align: center;
  border-style: none;
  border-spacing: 0;
  &_head {
    color: map-get($colors, white);
    background-color: #8f8f8f;
    &_tr_th {
      padding: 10px 0;
    }
  }
  &_body_tr {
    &:nth-child(even) {
      background-color: map-get($colors, lightlightGray);
    }
    &_td {
      padding: 15px 0;
    }
  }
}
</style>
