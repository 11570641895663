var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ModalToggler",
    { attrs: { "close-button": true } },
    [
      _c(
        "v-btn",
        { attrs: { slot: "button", flat: "", color: "blue" }, slot: "button" },
        [
          _c("v-icon", [_vm._v("mail_outline")]),
          _vm._v("\n    メール用テンプレート表示\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _vm.items !== null
        ? _c(
            "InfoBlock",
            {
              staticStyle: { position: "relative" },
              attrs: { width: "750px", block: "" },
            },
            [
              _c(
                "template",
                { slot: "head" },
                [
                  _vm._v(
                    "メールテンプレート " + _vm._s(_vm.headLine) + "\n      "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "black" },
                      on: { click: _vm.writeToClipboard },
                    },
                    [_c("v-icon", [_vm._v("content_copy")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.mail }, [
                _c("p", [_vm._v(_vm._s(_vm.body))]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }