var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c("div", { class: _vm.$style.basicInfo }, [
        _c("div", { class: _vm.$style.basicInfo_top }, [
          _c("div", { class: _vm.$style.basicInfo_topLeft }, [
            _c(
              "div",
              { class: _vm.$style.basicInfo_item },
              [
                _c("span", { class: _vm.$style.basicInfo_name }, [
                  _vm._v("商談管理No"),
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    class: _vm.$style.basicInfo_txt,
                    attrs: {
                      to: `/admin/dealings/${_vm.items.request_number}`,
                    },
                  },
                  [_vm._v(_vm._s(_vm.items.request_number) + "\n        ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: _vm.$style.basicInfo_item }, [
              _c("span", { class: _vm.$style.basicInfo_name }, [
                _vm._v("商談名"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { class: [_vm.$style.basicInfo_txt, _vm.$style.dealName] },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.deal
                          ? _vm.deal.request_name
                          : _vm.items.request_name
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.basicInfo_topRight },
            [
              _c("div", { class: _vm.$style.basicInfo_item }, [
                _c("span", { class: _vm.$style.basicInfo_name }, [
                  _vm._v("発注先"),
                ]),
                _vm._v(" "),
                _vm.items.supplier
                  ? _c(
                      "p",
                      { class: [_vm.$style.basicInfo_txt, _vm.$style.bold] },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$store.getters["defs/getOptionValueByKey"](
                                "SupplierCode",
                                _vm.items.supplier.supplier_code
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _c(
                      "p",
                      { class: [_vm.$style.basicInfo_txt, _vm.$style.bold] },
                      [_vm._v("\n          未確定\n        ")]
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.basicInfo_item },
                [
                  _c("span", { class: _vm.$style.basicInfo_name }, [
                    _vm._v("担当者"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { class: [_vm.$style.basicInfo_txt, _vm.$style.bold] },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.items.user ? _vm.items.user.name : "未設定"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.deal
                    ? _c(
                        "ModalToggler",
                        {
                          attrs: { scroll: false },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ toggle }) {
                                  return [
                                    _c(
                                      "InfoBlock",
                                      {
                                        attrs: {
                                          width: "auto",
                                          block: "",
                                          center: "",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "head" }, [
                                          _vm._v(
                                            "\n                担当者変更\n              "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { class: _vm.$style.flex },
                                          [
                                            _c("PrSelect", {
                                              staticStyle: { width: "260px" },
                                              attrs: {
                                                "pr-row": [100],
                                                options: _vm.userOptions,
                                                name: "user_id",
                                                validate: "required",
                                                searchable: true,
                                              },
                                              model: {
                                                value: _vm.user_id,
                                                callback: function ($$v) {
                                                  _vm.user_id = $$v
                                                },
                                                expression: "user_id",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              [
                                                _c(
                                                  "Button",
                                                  {
                                                    attrs: {
                                                      size: "main",
                                                      color: "submit",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleStaffChange(
                                                          toggle
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "変更\n                  "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "Button",
                                                  {
                                                    attrs: {
                                                      size: "assist",
                                                      color: "cancel",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleCancelClick(
                                                          toggle
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "キャンセル\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3389824059
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "button" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { margin: "0 0 0 10px" },
                                  attrs: {
                                    round: "",
                                    outline: "",
                                    color: "indigo",
                                  },
                                },
                                [_vm._v("変更\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.deal
                ? _c("DialogProvider", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: `「${_vm.deal.request_name}」をコピーしますか？`,
                        yas: "はい",
                        no: "いいえ",
                      },
                    },
                    on: { accept: _vm.handleCopyDealing },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return _c(
                              "v-btn",
                              _vm._g({ attrs: { icon: "" } }, on),
                              [_c("v-icon", [_vm._v("content_copy")])],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1094281141
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.deal
                ? _c("DialogProvider", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: `本当に「${_vm.deal.request_name}」を削除しますか？`,
                        yas: "はい",
                        no: "いいえ",
                      },
                    },
                    on: { accept: _vm.handleDeleteDealing },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return _c(
                              "v-btn",
                              _vm._g({ attrs: { icon: "" } }, on),
                              [_c("v-icon", [_vm._v("delete_forever")])],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1031066783
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.basicInfo_bottom },
          [
            _c("div", { class: _vm.$style.basicInfo_bottomLeft }, [
              _c("div", { class: _vm.$style.basicInfo_item }, [
                _c("span", { class: _vm.$style.basicInfo_ttl }, [
                  _vm._v("取引先名"),
                ]),
                _vm._v(" "),
                _vm.isCustomerExists
                  ? _c(
                      "p",
                      { class: [_vm.$style.basicInfo_txt] },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/admin/dealings/" +
                                _vm.items.request_number +
                                "/customers/" +
                                (_vm.deal
                                  ? _vm.deal.customer.id
                                  : _vm.items.customer_id),
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.deal
                                    ? _vm.deal.customer.organization.name
                                    : _vm.items.customer_name
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("p", { class: [_vm.$style.basicInfo_txt] }, [
                      _vm._v("\n          未設定\n        "),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style.basicInfo_item }, [
                _c("span", { class: _vm.$style.basicInfo_ttl }, [
                  _vm._v("取引先責任者名"),
                ]),
                _vm._v(" "),
                _vm.isCustomerPersonExists
                  ? _c(
                      "p",
                      { class: [_vm.$style.basicInfo_txt] },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/admin/dealings/" +
                                _vm.items.request_number +
                                "/customer-persons/" +
                                (_vm.deal
                                  ? _vm.deal.customer_person.id
                                  : _vm.items.customer_person_id),
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.deal
                                    ? _vm.deal.customer_person.last_name +
                                        _vm.deal.customer_person.first_name
                                    : _vm.items.customer_person_name
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("p", { class: [_vm.$style.basicInfo_txt] }, [
                      _vm._v("\n          未設定\n        "),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style.basicInfo_item }, [
                _c("span", { class: _vm.$style.basicInfo_ttl }, [
                  _vm._v("発注日"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm._f("getDate")(_vm.items.order_date))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style.basicInfo_item }, [
                _c("span", { class: _vm.$style.basicInfo_ttl }, [
                  _vm._v("納品希望日"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm._f("getDate")(_vm.items.delivery_dates))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style.basicInfo_item }, [
                _c("span", { class: _vm.$style.basicInfo_ttl }, [
                  _vm._v("注文総額（税込）"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm._f("formatPrice")(_vm.items.total_order_amount))
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.items.alert_message.length
              ? _c("DealingHeaderAlert", {
                  attrs: { msgs: _vm.items.alert_message },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }