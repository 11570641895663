var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.wrapper },
    [
      _c("PageTitle", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("p", { class: _vm.$style.userName }, [
        _vm._v(_vm._s(_vm.customerAddr.name) + "様"),
      ]),
      _vm._v(" "),
      _c(
        "InfoBlock",
        { attrs: { block: "", width: "320px", margin: "0 auto", center: "" } },
        [
          _c("template", { slot: "head" }, [
            _vm._v("\n      お客様（注文者）ご住所\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.formsAria },
            [
              _c(
                "PrInput",
                {
                  attrs: {
                    label: "郵便番号",
                    name: "c-zip",
                    validate: "required|zipcode",
                    "pr-row": [100, 75],
                    placeholder: "例) 812-0895",
                    requiredIcon: "",
                  },
                  model: {
                    value: _vm.customerAddr.zip,
                    callback: function ($$v) {
                      _vm.$set(_vm.customerAddr, "zip", $$v)
                    },
                    expression: "customerAddr.zip",
                  },
                },
                [
                  _c("ButtonSerchZipCode", {
                    attrs: { slot: "after", zipcode: _vm.customerAddr.zip },
                    on: { click: _vm.handleSearchCustomerZipCode },
                    slot: "after",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "都道府県",
                  name: "c-region",
                  validate: "required",
                  "pr-row": [100, 100],
                  placeholder: "例) 福岡県",
                  requiredIcon: "",
                },
                model: {
                  value: _vm.customerAddr.region,
                  callback: function ($$v) {
                    _vm.$set(_vm.customerAddr, "region", $$v)
                  },
                  expression: "customerAddr.region",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "市区町村",
                  "pr-row": [100, 100],
                  name: "c-locality",
                  validate: "required",
                  placeholder: "例) 福岡市博多区",
                  requiredIcon: "",
                },
                model: {
                  value: _vm.customerAddr.locality,
                  callback: function ($$v) {
                    _vm.$set(_vm.customerAddr, "locality", $$v)
                  },
                  expression: "customerAddr.locality",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "町名・番地",
                  "pr-row": [100, 100],
                  name: "c-street",
                  validate: "required",
                  placeholder: "例) 竹下1丁目11-23",
                  requiredIcon: "",
                },
                model: {
                  value: _vm.customerAddr.street,
                  callback: function ($$v) {
                    _vm.$set(_vm.customerAddr, "street", $$v)
                  },
                  expression: "customerAddr.street",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "建物名・部屋番号",
                  "pr-row": [100, 100],
                  placeholder: "例) アクアサイドGEN1F",
                },
                model: {
                  value: _vm.customerAddr.building,
                  callback: function ($$v) {
                    _vm.$set(_vm.customerAddr, "building", $$v)
                  },
                  expression: "customerAddr.building",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "InfoBlock",
        { attrs: { block: "", width: "320px", margin: "0 auto", center: "" } },
        [
          _c("template", { slot: "head" }, [
            _vm._v("\n      入金・配送・納品\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.formsAria },
            [
              _c("p", { class: _vm.$style.formsAria_label }, [
                _vm._v("\n        お支払い方法\n      "),
              ]),
              _vm._v(" "),
              _c("PrInputRadio", {
                attrs: {
                  label: "銀行振込",
                  name: "payment",
                  id: "cash",
                  value: "0",
                },
                model: {
                  value: _vm.payment,
                  callback: function ($$v) {
                    _vm.payment = $$v
                  },
                  expression: "payment",
                },
              }),
              _vm._v(" "),
              _c("PrInputRadio", {
                attrs: {
                  label: "代引き",
                  name: "payment",
                  id: "bank",
                  value: "1",
                },
                model: {
                  value: _vm.payment,
                  callback: function ($$v) {
                    _vm.payment = $$v
                  },
                  expression: "payment",
                },
              }),
              _vm._v(" "),
              _c("PrDatePicker", {
                attrs: {
                  label: "入金予定日",
                  "pr-row": [100],
                  disabledDates: _vm.schedulePicker.disabledDates,
                },
                model: {
                  value: _vm.scheduleDate,
                  callback: function ($$v) {
                    _vm.scheduleDate = $$v
                  },
                  expression: "scheduleDate",
                },
              }),
              _vm._v(" "),
              _c("PrDatePicker", {
                attrs: {
                  label: "納品希望日",
                  "pr-row": [100],
                  disabledDates: _vm.prefPicker.disabledDates,
                },
                model: {
                  value: _vm.prefDate,
                  callback: function ($$v) {
                    _vm.prefDate = $$v
                  },
                  expression: "prefDate",
                },
              }),
              _vm._v(" "),
              _c("p", { class: _vm.$style.formsAria_label }, [
                _vm._v("受取可能曜日"),
              ]),
              _vm._v(" "),
              _vm._l(
                ["月", "火", "水", "木", "金", "土", "日・祝"],
                function (day, idx) {
                  return _c("PrCheckbox", {
                    key: idx,
                    attrs: { "before-label": day },
                    model: {
                      value: _vm.receiveDates[idx],
                      callback: function ($$v) {
                        _vm.$set(_vm.receiveDates, idx, $$v)
                      },
                      expression: "receiveDates[idx]",
                    },
                  })
                }
              ),
              _vm._v(" "),
              _c("p", { class: _vm.$style.formsAria_label }, [
                _vm._v("商品お届け先"),
              ]),
              _vm._v(" "),
              _c("PrInputRadio", {
                attrs: {
                  label: "注文者と同じ",
                  name: "destination",
                  id: "same",
                  value: "0",
                },
                model: {
                  value: _vm.destination,
                  callback: function ($$v) {
                    _vm.destination = $$v
                  },
                  expression: "destination",
                },
              }),
              _vm._v(" "),
              _c("PrInputRadio", {
                attrs: {
                  label: "指定住所へ配送",
                  name: "destination",
                  id: "specify",
                  value: "1",
                },
                model: {
                  value: _vm.destination,
                  callback: function ($$v) {
                    _vm.destination = $$v
                  },
                  expression: "destination",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isValidDestination,
                      expression: "isValidDestination",
                    },
                  ],
                  class: _vm.$style.formsAria,
                },
                [
                  _c("PrInput", {
                    attrs: {
                      label: "会社名・団体名",
                      "pr-row": [100, 100],
                      name: "d-company",
                      placeholder: "例) 株式会社PR39",
                    },
                    model: {
                      value: _vm.destAddr.company,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "company", $$v)
                      },
                      expression: "destAddr.company",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: {
                      label: "部署名",
                      "pr-row": [100, 100],
                      name: "d-division",
                      placeholder: "例) 企画販促課",
                    },
                    model: {
                      value: _vm.destAddr.division,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "division", $$v)
                      },
                      expression: "destAddr.division",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: {
                      label: "ご担当者氏名(お届け先名)",
                      "pr-row": [100, 100],
                      name: "d-name",
                      validate: _vm.isValidDestination ? "required" : "",
                      placeholder: "例) 比井有 三九",
                      requiredIcon: "",
                    },
                    model: {
                      value: _vm.destAddr.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "name", $$v)
                      },
                      expression: "destAddr.name",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "PrInput",
                    {
                      attrs: {
                        label: "郵便番号",
                        "pr-row": [100, 75],
                        name: "d-zip",
                        validate: _vm.isValidDestination
                          ? "required|zipcode"
                          : "",
                        placeholder: "例) 812-0895",
                        requiredIcon: "",
                      },
                      model: {
                        value: _vm.destAddr.zip,
                        callback: function ($$v) {
                          _vm.$set(_vm.destAddr, "zip", $$v)
                        },
                        expression: "destAddr.zip",
                      },
                    },
                    [
                      _c("ButtonSerchZipCode", {
                        attrs: { slot: "after", zipcode: _vm.destAddr.zip },
                        on: { click: _vm.handleSearchDestinationZipCode },
                        slot: "after",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: {
                      label: "都道府県",
                      "pr-row": [100, 100],
                      name: "d-region",
                      validate: _vm.isValidDestination ? "required" : "",
                      placeholder: "例) 福岡県",
                      requiredIcon: "",
                    },
                    model: {
                      value: _vm.destAddr.region,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "region", $$v)
                      },
                      expression: "destAddr.region",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: {
                      label: "市区町村",
                      "pr-row": [100, 100],
                      name: "d-locality",
                      validate: _vm.isValidDestination ? "required" : "",
                      placeholder: "例) 福岡市博多区",
                      requiredIcon: "",
                    },
                    model: {
                      value: _vm.destAddr.locality,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "locality", $$v)
                      },
                      expression: "destAddr.locality",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: {
                      label: "町名・番地",
                      "pr-row": [100, 100],
                      name: "d-street",
                      validate: _vm.isValidDestination ? "required" : "",
                      placeholder: "例) 竹下1丁目11-23",
                      requiredIcon: "",
                    },
                    model: {
                      value: _vm.destAddr.street,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "street", $$v)
                      },
                      expression: "destAddr.street",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: {
                      label: "建物名・部屋番号",
                      "pr-row": [100, 100],
                      placeholder: "例) アクアサイドGEN1F",
                    },
                    model: {
                      value: _vm.destAddr.building,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "building", $$v)
                      },
                      expression: "destAddr.building",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: {
                      label: "電話番号",
                      "pr-row": [100, 100],
                      name: "d-tel",
                      validate: _vm.isValidDestination
                        ? "required|telephone"
                        : "",
                      placeholder: "例) 092-409-6039",
                      requiredIcon: "",
                    },
                    model: {
                      value: _vm.destAddr.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.destAddr, "tel", $$v)
                      },
                      expression: "destAddr.tel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { class: _vm.$style.formsAria_label }, [
                _vm._v("送り主変更オプション"),
              ]),
              _vm._v(" "),
              _c("PrCheckbox", {
                attrs: {
                  "after-label": "変更する",
                  value: _vm.isSenderChange ? "1" : "0",
                },
                on: {
                  input: function ($event) {
                    _vm.isSenderChange = $event == "1" ? true : false
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { class: _vm.$style.explain }, [
                _vm._v(
                  "\n        送り状に表示する送り主を、弊社からご注文者または任意の情報に変更することができます。変更された場合、弊社の領収証や広告物など一切同封いたしません。ご安心のうえ、クライアント様に直接発送が可能です。\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.formsAria_label }, [
                _vm._v("\n        送り主 "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isSenderChange,
                        expression: "!isSenderChange",
                      },
                    ],
                  },
                  [_vm._v("PR39")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSenderChange,
                      expression: "isSenderChange",
                    },
                  ],
                },
                [
                  _c("PrInputRadio", {
                    attrs: {
                      label: "注文者",
                      name: "sender",
                      id: "sender",
                      value: "0",
                    },
                    model: {
                      value: _vm.sender,
                      callback: function ($$v) {
                        _vm.sender = $$v
                      },
                      expression: "sender",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInputRadio", {
                    attrs: {
                      label: "下記の情報",
                      name: "sender",
                      id: "other",
                      value: "1",
                    },
                    model: {
                      value: _vm.sender,
                      callback: function ($$v) {
                        _vm.sender = $$v
                      },
                      expression: "sender",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isValidSender,
                          expression: "isValidSender",
                        },
                      ],
                      class: _vm.$style.formsAria,
                    },
                    [
                      _c("PrInput", {
                        attrs: {
                          label: "会社名・団体名",
                          "pr-row": [100, 100],
                          name: "s-company",
                          placeholder: "例) 株式会社PR39",
                        },
                        model: {
                          value: _vm.senderAddr.company,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "company", $$v)
                          },
                          expression: "senderAddr.company",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "部署名",
                          "pr-row": [100, 100],
                          name: "s-division",
                          placeholder: "例) 企画販促課",
                        },
                        model: {
                          value: _vm.senderAddr.division,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "division", $$v)
                          },
                          expression: "senderAddr.division",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "ご担当者氏名(送り主名)",
                          "pr-row": [100, 100],
                          name: "s-name",
                          validate: _vm.isValidSender ? "required" : "",
                          placeholder: "例) 比井有 三九",
                          requiredIcon: "",
                        },
                        model: {
                          value: _vm.senderAddr.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "name", $$v)
                          },
                          expression: "senderAddr.name",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "PrInput",
                        {
                          attrs: {
                            label: "郵便番号",
                            "pr-row": [100, 75],
                            name: "s-zip",
                            validate: _vm.isValidSender
                              ? "required|zipcode"
                              : "",
                            placeholder: "例) 812-0895",
                            requiredIcon: "",
                          },
                          model: {
                            value: _vm.senderAddr.zip,
                            callback: function ($$v) {
                              _vm.$set(_vm.senderAddr, "zip", $$v)
                            },
                            expression: "senderAddr.zip",
                          },
                        },
                        [
                          _c("ButtonSerchZipCode", {
                            attrs: {
                              slot: "after",
                              zipcode: _vm.senderAddr.zip,
                            },
                            on: { click: _vm.handleSearchSenderZipCode },
                            slot: "after",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "都道府県",
                          "pr-row": [100, 100],
                          name: "s-region",
                          validate: _vm.isValidSender ? "required" : "",
                          placeholder: "例) 福岡県",
                          requiredIcon: "",
                        },
                        model: {
                          value: _vm.senderAddr.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "region", $$v)
                          },
                          expression: "senderAddr.region",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "市区町村",
                          "pr-row": [100, 100],
                          name: "s-locality",
                          validate: _vm.isValidSender ? "required" : "",
                          placeholder: "例) 福岡市博多区",
                          requiredIcon: "",
                        },
                        model: {
                          value: _vm.senderAddr.locality,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "locality", $$v)
                          },
                          expression: "senderAddr.locality",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "町名・番地",
                          "pr-row": [100, 100],
                          name: "s-street",
                          validate: _vm.isValidSender ? "required" : "",
                          placeholder: "例) 竹下1丁目11-23",
                          requiredIcon: "",
                        },
                        model: {
                          value: _vm.senderAddr.street,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "street", $$v)
                          },
                          expression: "senderAddr.street",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "建物名・部屋番号",
                          "pr-row": [100, 100],
                          placeholder: "例) アクアサイドGEN1F",
                        },
                        model: {
                          value: _vm.senderAddr.building,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "building", $$v)
                          },
                          expression: "senderAddr.building",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "電話番号",
                          "pr-row": [100, 100],
                          name: "s-tel",
                          validate: _vm.isValidSender
                            ? "required|telephone"
                            : "",
                          placeholder: "例) 092-409-6039",
                          requiredIcon: "",
                        },
                        model: {
                          value: _vm.senderAddr.tel,
                          callback: function ($$v) {
                            _vm.$set(_vm.senderAddr, "tel", $$v)
                          },
                          expression: "senderAddr.tel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "InfoBlock",
        { attrs: { block: "", width: "320px", margin: "0 auto", center: "" } },
        [
          _c("template", { slot: "head" }, [_vm._v("\n      その他\n    ")]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.formsAria },
            [
              _c("p", { class: _vm.$style.formsAria_label }, [_vm._v("備考")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.explain, _vm.$style.dark] }, [
                _vm._v(
                  "\n        その他、ご注文に関してご希望などありましたら こちらにご入力ください。\n      "
                ),
              ]),
              _vm._v(" "),
              _c("PrTextarea", {
                attrs: { "pr-row": [0, 100] },
                model: {
                  value: _vm.remarks,
                  callback: function ($$v) {
                    _vm.remarks = $$v
                  },
                  expression: "remarks",
                },
              }),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.explain, _vm.$style.dark] }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.pr39.com/terms/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("「PR39」利用規約")]
                ),
                _vm._v(
                  "の内容をご確認のうえ、 「同意する」チェックボックスにチェックを入れ\n        てください。\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("PrCheckbox", {
                    staticStyle: { "justify-content": "center" },
                    attrs: {
                      "after-label": "同意する",
                      name: "isAgree",
                      validate: "required",
                      value: _vm.isAgree ? "1" : "0",
                    },
                    on: {
                      input: function ($event) {
                        _vm.isAgree = $event == "1" ? true : false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.button },
                [
                  _c(
                    "Button",
                    {
                      attrs: { color: "submit", size: "main" },
                      on: { click: _vm.handleNext },
                    },
                    [_vm._v("確認画面へ進む")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }