var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "menu" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShow,
            expression: "isShow",
          },
        ],
        class: _vm.$style.wrapper,
      },
      [
        _c("div", { class: _vm.$style.btnWrapper }, [
          _c("div", {
            class: _vm.$style.close,
            on: { click: _vm.toggleSlideMenuShow },
          }),
        ]),
        _vm._v(" "),
        _c("h3", { class: _vm.$style.head }, [_vm._v("メニュー")]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/dealings" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("商談一覧（テーブル表示）")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/dealings/card" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("商談一覧（カード表示）")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/contracts" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("見積一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/om-contracts" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("オーダーメイド見積一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/customers" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("取引先一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/customer-persons" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("取引先責任者一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/suppliers" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("仕入先一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/defectives" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("不良品一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/items" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("商品一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/items/csv/update" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("商品CSV一括更新")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/prices" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("価格表一覧")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/reports" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("実績帳票出力")]
        ),
        _vm._v(" "),
        _c("IconUser", { class: _vm.$style.icon, attrs: { height: "80" } }),
        _vm._v(" "),
        _c("h3", { class: _vm.$style.head }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: _vm.$style.anker,
            attrs: { to: "/admin/users" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSlideMenuShow.apply(null, arguments)
              },
            },
          },
          [_vm._v("アカウント管理")]
        ),
        _vm._v(" "),
        _c("a", { class: _vm.$style.anker, on: { click: _vm.handleLogout } }, [
          _vm._v("ログアウト"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }