<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="complateHeaders"
    :filter-func="filterFunc"
    page-id="dealings"
    v-bind="{ handleFlagInput, flagMarges, flagExcel }"
    style="width:1300px;"
  >
    <template slot="page-title">
      商談一覧
    </template>
    <div slot="create-link">
      <v-btn
        @click="
          excelMode = !excelMode
          margeMode = false
        "
        style="align-self:flex-end;"
        >出荷伝票
        <v-icon>{{ excelMode ? "close" : "more_horiz" }}</v-icon></v-btn
      >
      <v-btn
        @click="
          margeMode = !margeMode
          excelMode = false
        "
        style="align-self:flex-end;"
        >マージ <v-icon>{{ margeMode ? "close" : "more_horiz" }}</v-icon></v-btn
      >
      <Button size="main" color="submit" plus to="/admin/dealings/new">
        新しい商談を追加する
      </Button>
    </div>

    <template
      slot="filter"
      slot-scope="{
        filter,
        handleSelectInput,
        handleTextInput,
        handleFlagOn,
        filterClass,
      }"
    >
      <div :class="[$style.fil, filterClass]">
        <PrSelect
          label="対応状況"
          :pr-row="[100]"
          :value="filter.status"
          :options="statusOptions"
          @input="handleSelectInput($event, 'status')"
        />
        <PrSelect
          label="フェーズ"
          :pr-row="[100]"
          :value="filter.phase"
          :options="phaseOptions"
          @input="handleSelectInput($event, 'phase')"
        />
        <PrSelect
          label="入金確認"
          :pr-row="[100]"
          :value="filter.is_payment"
          :options="paymentOption"
          @input="handleSelectInput($event, 'is_payment')"
        />
        <PrSelect
          label="担当者"
          :pr-row="[100]"
          :value="filter.staff"
          :options="staffOptions"
          @input="handleSelectInput($event, 'staff')"
        />
        <PrSelect
          label="取引先"
          :pr-row="[100]"
          :value="filter.customer"
          :options="customerOptions"
          :searchable="true"
          @input="handleSelectInput($event, 'customer')"
          style="width:250px"
        />
        <PrInput
          label="キーワード"
          :pr-row="[100, 85]"
          :value="keywordHandler.value"
          @input="keywordHandler.input"
          @keydown.native.enter="keywordHandler.keydown"
          @keyup.native.enter="
            keywordHandler.keyup($event, handleFlagOn, handleTextInput, filter)
          "
          style="justify-content:space-between;"
        >
          <SearchButton
            slot="after"
            @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
            :close="keywordHandler.isOn"
          />
        </PrInput>
      </div>
      <template v-if="margeMode">
        <DealingIndexMargeOperation v-bind="coupling" :init="init" />
      </template>
      <template v-if="excelMode">
        <DealingIndexExcelOperation
          :excelTargets="excelTargets"
          :clearFlagExcel="clearFlagExcel"
        />
      </template>
    </template>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import DealingIndexMargeOperation from "@/components/molecules/DealingIndexMargeOperation.vue"
import DealingIndexExcelOperation from "@/components/molecules/DealingIndexExcelOperation.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import Button from "@/components/atoms/Button.vue"
import { getDate, getDateTime, routeReplaceError } from "@/utils/shared.js"
import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    DealingIndexMargeOperation,
    DealingIndexExcelOperation,
    PrInput,
    PrSelect,
    SearchButton,
    Button,
    IndexTableProvider,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"]("dealings")
    return {
      items: null,
      staffs: null,
      customers: null,
      headers: null,
      keywordHandler: newWordSearchHandler("keyword", filter),
      statusOptions: [
        { key: "0", value: "未対応" },
        { key: "1", value: "対応中" },
        { key: "2", value: "すべて" },
      ],
      phaseOptions: this.$store.getters["defs/getOptions"]("Progress").reduce(
        (acc, { key, value }) =>
          key !== "99" ? [...acc, { key: value, value }] : acc,
        [{ key: "すべて", value: "すべて" }],
      ),
      paymentOption: [
        { key: "0", value: "未入金" },
        { key: "1", value: "入金済" },
        { key: "2", value: "すべて" },
      ],
      flagMarges: null,
      margeMode: false,
      flagExcel: null,
      excelMode: false,
    }
  },
  computed: {
    staffOptions() {
      return [
        { key: "すべて", value: "すべて" },
        { key: "未設定", value: "未設定" },
        ...this.staffs.map(({ name }) => ({ key: name, value: name })),
      ]
    },
    customerOptions() {
      return [
        { key: "0", value: "すべて" },
        ...this.customers.customers.map(({ id, name }) => ({
          key: String(id),
          value: name,
        })),
      ]
    },
    customerObj() {
      return this.customers.customers.reduce(
        (acc, { id, name }) => ({
          ...acc,
          [id]: name,
        }),
        {},
      )
    },
    coupling() {
      const seme = Object.entries(this.flagMarges).find(
        ([_, { seme }]) => seme === "1",
      )
      const uke = Object.entries(this.flagMarges).find(
        ([_, { uke }]) => uke === "1",
      )
      return {
        semeNum: seme && seme[0],
        ukeNum: uke && uke[0],
      }
    },
    excelTargets() {
      return Object.entries(this.flagExcel)
        .filter(([_, val]) => val == "1")
        .map(([rn]) => {
          const t = this.items.find(
            ({ request_number }) => request_number == rn,
          )

          return { request_number: rn, name: t ? t.request_name : "" }
        })
    },
    complateHeaders() {
      const { margeMode, excelMode, headers } = this
      if (margeMode) {
        return [
          {
            noSort: true,
            text: "A→B",
            value: ({ request_number }) => `${request_number}#marge#`,
          },
          ...headers,
        ]
      }
      if (excelMode) {
        return [
          {
            noSort: true,
            text: "出力",
            value: ({ request_number }) => `${request_number}#excel#`,
            width: 5,
          },
          ...headers,
        ]
      }
      return headers
    },
  },
  methods: {
    filterFunc({ status, phase, staff, customer, keyword, is_payment }) {
      const { margeMode, excelMode, customerObj } = this

      return item => {
        let test = true

        if (margeMode || excelMode) {
          item = item.slice(1)
        }

        if (status !== "2") {
          test = item[9].slice(-1) === status
        }

        if (test && phase !== "すべて") {
          test = item[3] === phase
        }

        if (test && staff !== "すべて") {
          test = item[8] === staff
        }

        if (test && customer !== "0") {
          test = item[2] === customerObj[customer]
        }

        if (test && is_payment !== "2") {
          test = item[10].slice(-1) === is_payment
        }

        test = keywordFilter(keyword, test, item)

        return test
      }
    },
    handleFlagInput(e, requestNumber, men) {
      if (e === "1") {
        const fm = Object.entries(this.flagMarges).reduce(
          (acc, [key, val]) => ({ ...acc, [key]: { ...val, [men]: "0" } }),
          {},
        )
        fm[requestNumber][men] = e
        this.flagMarges = fm
      } else {
        this.flagMarges[requestNumber][men] = e
      }
    },
    clearFlagExcel() {
      this.flagExcel = Object.keys(this.flagExcel).reduce(
        (acc, key) => ({ ...acc, [key]: "0" }),
        {},
      )
    },
    init(dealings) {
      const maxlength = dealings.reduce(
        (acc, { request_number }) =>
          acc > request_number.length ? acc : request_number.length,
        1,
      )

      this.headers = [
        {
          text: "商談管理No",
          value: ({ request_number }) =>
            request_number.padStart(maxlength, "0"),
          link: ({ request_number }) => `/admin/dealings/${request_number}`,
          width: 10,
        },
        { text: "商談名", value: "request_name", width: 20 },
        { text: "取引先名", value: "customer.organization.name", width: 26 },
        {
          text: "フェーズ",
          value: ({ status }) =>
            this.$store.getters["defs/getOptionValueByKey"]("Progress", status),
          width: 7,
        },
        { text: "都道府県", value: "prefecture", width: 5 },
        {
          text: "発生日",
          value: ({ request_date1 }) => getDateTime(request_date1),
          width: 9,
        },
        {
          text: "発送予定日",
          value: ({ delivery: { request_date2 } }) => getDate(request_date2),
          width: 8,
        },
        {
          text: "納品希望日",
          value: ({ request_date2 }) => getDate(request_date2),
          width: 8,
        },
        {
          text: "担当者",
          value: ({ user }) => (!user ? null : user.name),
          width: 7,
        },
        {
          text: "not_for_display",
          value: ({ is_supported }) => "not_for_display" + is_supported,
        },
        {
          text: "not_for_display",
          value: ({ is_payment }) => "not_for_display_is_payment" + is_payment,
        },
      ]

      this.flagMarges = dealings.reduce(
        (acc, { request_number, status }) =>
          status > 3
            ? acc
            : {
                ...acc,
                [request_number]: { seme: "0", uke: "0" },
              },
        {},
      )

      this.flagExcel = dealings.reduce(
        (acc, { request_number, status }) =>
          ["14", "20", "90"].includes(String(status))
            ? {
                ...acc,
                [request_number]: "0",
              }
            : acc,
        {},
      )

      this.margeMode = false
      this.items = dealings
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const [
        { ok, body },
        { ok: sOk, body: sBody },
        { ok: cOk, body: cBody },
      ] = await Promise.all([
        api.get(paths.ADMIN_DEALINGS, this.$store.state.auth.token),
        api.post(
          paths.ADMIN_SEARCH_USERS,
          {
            and: [{ status: "1" }],
            or: [{ role: "admin" }, { role: "operator" }, { role: "system" }],
          },
          this.$store.state.auth.token,
        ),
        api.get(
          paths.ADMIN_CUSTOMERS_AND_CUSTOMER_PERSONS,
          this.$store.state.auth.token,
        ),
      ])

      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }
      if (!sOk) {
        routeReplaceError(this, sBody.slice(-3))
        return
      }
      if (!cOk) {
        routeReplaceError(this, cBody.slice(-3))
        return
      }

      this.staffs = sBody
      this.customers = cBody

      this.init(body)
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>

<style lang="scss" module>
.fil {
  > div[name="pr-select"] {
    width: 150px;
  }
}
</style>
