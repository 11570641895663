var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [_vm._v("お客様登録情報編集")]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.formArea },
            [
              _c("PrInput", {
                attrs: { label: "部署", "pr-row": [100, 100] },
                model: {
                  value: _vm.items.department,
                  callback: function ($$v) {
                    _vm.$set(_vm.items, "department", $$v)
                  },
                  expression: "items.department",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: { label: "役職", "pr-row": [100, 100] },
                model: {
                  value: _vm.items.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.items, "title", $$v)
                  },
                  expression: "items.title",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "氏名(姓)",
                  "pr-row": [100, 100],
                  name: "last_name",
                  validate: "required",
                },
                model: {
                  value: _vm.items.last_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.items, "last_name", $$v)
                  },
                  expression: "items.last_name",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "氏名（名）",
                  "pr-row": [100, 100],
                  name: "first_name",
                },
                model: {
                  value: _vm.items.first_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.items, "first_name", $$v)
                  },
                  expression: "items.first_name",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "電話番号",
                  "pr-row": [100, 100],
                  name: "phone",
                  validate: "required|telephone",
                },
                model: {
                  value: _vm.items.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.items, "phone", $$v)
                  },
                  expression: "items.phone",
                },
              }),
              _vm._v(" "),
              _c(
                "PrInput",
                {
                  staticStyle: { "justify-content": "space-between" },
                  attrs: {
                    label: "郵便番号",
                    "pr-row": [100, 75],
                    name: "zip",
                    type: "text",
                    validate: "required|zipcode",
                  },
                  model: {
                    value: _vm.items.address.zip_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.items.address, "zip_code", $$v)
                    },
                    expression: "items.address.zip_code",
                  },
                },
                [
                  _c("ButtonSerchZipCode", {
                    attrs: {
                      slot: "after",
                      zipcode: _vm.items.address.zip_code,
                    },
                    on: { click: _vm.handleSearchZipCode },
                    slot: "after",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "都道府県",
                  "pr-row": [100, 100],
                  name: "location",
                  type: "text",
                  validate: "required",
                },
                model: {
                  value: _vm.items.address.location,
                  callback: function ($$v) {
                    _vm.$set(_vm.items.address, "location", $$v)
                  },
                  expression: "items.address.location",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "市区町村",
                  "pr-row": [100, 100],
                  name: "address1",
                  validate: "required",
                },
                model: {
                  value: _vm.items.address.address1,
                  callback: function ($$v) {
                    _vm.$set(_vm.items.address, "address1", $$v)
                  },
                  expression: "items.address.address1",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  label: "町名・番地",
                  "pr-row": [100, 100],
                  name: "address2",
                  validate: "required",
                },
                model: {
                  value: _vm.items.address.address2,
                  callback: function ($$v) {
                    _vm.$set(_vm.items.address, "address2", $$v)
                  },
                  expression: "items.address.address2",
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: { label: "建物名・部屋番号", "pr-row": [100, 100] },
                model: {
                  value: _vm.items.building,
                  callback: function ($$v) {
                    _vm.$set(_vm.items, "building", $$v)
                  },
                  expression: "items.building",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "button" },
                [
                  _c(
                    "Button",
                    {
                      attrs: { color: "submit", size: "main" },
                      on: { click: _vm.handleUpdate },
                    },
                    [_vm._v("登録する")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }