var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "350px", margin: "0", block: "" } },
    [
      _c("template", { slot: "head" }, [_vm._v("発注先")]),
      _vm._v(" "),
      !_vm.orderdContract
        ? [_c("p", { class: _vm.$style.not }, [_vm._v("注文未確定")])]
        : _vm.orderdContract && !_vm.orderdContract.supplier
        ? [
            _c("p", { class: _vm.$style.not }, [
              _vm._v("\n      発注先未確定\n    "),
            ]),
          ]
        : [
            _c(
              "router-link",
              {
                class: _vm.$style.name,
                attrs: {
                  to: `/admin/dealings/${_vm.items.request_number}/suppliers/${_vm.orderdContract.supplier.id}`,
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$store.getters["defs/getOptionValueByKey"](
                        "SupplierCode",
                        _vm.orderdContract.supplier.supplier_code
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }