import api, { paths } from "@/utils/api.js"
import moment from "moment"
import {
  routeReplaceError,
  formatPrice,
  formatDecimalPrice,
} from "@/utils/shared.js"
import {
  calcBillingAmountPrice,
  calcBillingAmountDiscountPrice,
  calcBillingTaxPrice,
  calcBillingUnTaxPrice,
  calcBillingDeliveryFee,
} from "@/helpers/QuotationDetailViewHelper.js"

export default {
  data() {
    return {
      isReady: false,
      id: null,
      taxRate: null,
      taxTotalPrice: null,
      subTotalPrice: null,
      discountPrice: null,
      totalPrice: null,
      items: [],
      detailsProduct: [],
      detailsOther: [],
      detailsReserved: [],
      isCompanySeal: true,
      customerName: null,
      personName: null,
      documentDate: this.getTodayString(),
      documentNote: null,
    }
  },
  filters: {
    formatNumber: num => formatPrice(num, false, ""),
    formatPrice: price => formatPrice(price, false, ""),
    formatDecimalPrice: price => formatDecimalPrice(price, false, ""),
  },
  async mounted() {
    this.id = this.$route.params.deal_id
    const { ok, body } = await api.get(
      `${paths.ADMIN_INVOICE}/${this.id}`,
      this.$store.state.auth.token,
    )
    if (!ok) {
      console.log("データ取得失敗", body)
      routeReplaceError(this, body.slice(-3))
      return
    }
    this.items = body
    const category2_Table = {
      proofreading: "色校正代金",
      design_fee: "デザイン料",
      mount_fee: "MF台紙代",
      type_fee: "型代",
      shipping_fee: "その他送料",
      other_fee: "その他代金",
      discount: "値引き",
    }

    let index = 1
    let companyDiscount = 0 // 企業値引き
    let subTotalPrice = 0 // 小計
    let procutTotalDiscount = 0 // 商品金額(企業値引き後)
    let taxTotalPrice = 0 // 消費税合計
    let totalPrice = 0 // 税込金額
    let taxRate = this.items.contract.contract.contract_details[0].tax.tax_rate

    // 商品
    this.detailsProduct = this.items.contract.contract.contract_details.reduce(
      (prev, curr) => {
        if (curr.category2 == "ready_made" || curr.category2 == "order_made") {
          const amountPrice = calcBillingAmountPrice(curr.price, curr.amount)
          const taxPrice = calcBillingTaxPrice(
            curr.price,
            curr.amount,
            curr.thumbnail,
            curr.tax.tax_rate,
          )

          const name = this.getProductName(
            curr.name,
            curr.category1,
            curr.color,
            curr.size3,
            curr.jan_code,
            curr.size1,
          )

          prev.push({
            index: index++,
            name: name,
            amount: curr.amount,
            price: curr.price,
            amountPrice: amountPrice,
            taxPrice: taxPrice,
          })

          procutTotalDiscount += calcBillingAmountDiscountPrice(
            curr.price,
            curr.amount,
            curr.thumbnail,
          )

          taxTotalPrice += taxPrice
          subTotalPrice += amountPrice
        }
        return prev
      },
      [],
    )

    companyDiscount = subTotalPrice - procutTotalDiscount

    // その他諸費用
    this.detailsOther = this.items.contract.contract.contract_details.reduce(
      (prev, curr) => {
        if (curr.category2 in category2_Table) {
          const amountPrice = calcBillingAmountPrice(curr.price, curr.amount)
          const taxPrice = calcBillingTaxPrice(
            curr.price,
            curr.amount,
            curr.thumbnail,
            curr.tax.tax_rate,
          )

          if (amountPrice !== 0) {
            // 値引きの場合は、値引き金額を取得しておく
            if (curr.category2 == "discount") {
              if (curr.category3 == "old") {
                // 旧ロジックの場合は、税込み金額から値引きする
                this.discountPrice = amountPrice
              } else {
                // 新ロジックの場合は、値引き金額を項目に表示する
                prev.push({
                  index: index++,
                  name: category2_Table[curr.category2],
                  amount: curr.amount,
                  price: -1 * curr.price,
                  amountPrice: -1 * amountPrice,
                  taxPrice: taxPrice,
                })

                taxTotalPrice -= taxPrice
                subTotalPrice -= amountPrice
              }
            } else {
              prev.push({
                index: index++,
                name: category2_Table[curr.category2],
                amount: curr.amount,
                price: curr.price,
                amountPrice: amountPrice,
                taxPrice: taxPrice,
              })

              taxTotalPrice += taxPrice
              subTotalPrice += amountPrice
            }
          }
        }
        return prev
      },
      [],
    )
    if (this.discountPrice !== null) {
      subTotalPrice -= this.discountPrice
    }

    if (companyDiscount !== 0) {
      this.detailsOther.push({
        index: index++,
        name: "御社特別値引き",
        amount: 1,
        price: -1 * companyDiscount,
        amountPrice: -1 * companyDiscount,
        taxPrice: 0,
      })

      subTotalPrice -= companyDiscount
    }

    // 代引きの場合、代引き手数料取得
    let deliveryFee = calcBillingDeliveryFee(
      subTotalPrice,
      this.items.contract.quantity4,
    )
    if (deliveryFee != 0) {
      // 代引き手数料は消費税が含まれているので、税抜き金額を計算する
      // 代引き手数料の消費税は10%固定で計算する
      deliveryFee = calcBillingUnTaxPrice(deliveryFee, 1, 0, 10)

      this.detailsOther.push({
        index: index++,
        name: "代引き手数料",
        amount: 1,
        price: deliveryFee,
        amountPrice: deliveryFee,
        taxPrice: 0,
      })

      subTotalPrice += deliveryFee
      taxTotalPrice += calcBillingTaxPrice(deliveryFee, 1, 0, taxRate)
    }

    // 行数が少ない場合、表に余白行を作る
    for (let i = 0; i < 11 - (index - 1); i++) {
      this.detailsReserved.push({})
    }

    // 税込金額 = 小計 + 消費税
    totalPrice = subTotalPrice + taxTotalPrice

    this.taxRate = taxRate
    this.subTotalPrice = subTotalPrice
    this.taxTotalPrice = taxTotalPrice
    this.totalPrice = totalPrice

    this.customerName = body.customer ? body.customer.organization.name : ""
    this.personName = body.customer_person
      ? body.customer_person.last_name + " " + body.customer_person.first_name
      : ""

    this.isReady = true

    document.title = `【${this.$route.meta.title}】${
      body.customer.organization.name
    }様_${this.getTodayString()}`
  },
  methods: {
    getProductName(name, family, color, size, jan_code, coverSize) {
      // マイクロファイバーの場合、カバーサイズを利用する
      const displaySize = jan_code === "1" ? coverSize : size + "枚"
      let data =
        name +
        " " +
        this.getProductFamily(family) +
        " " +
        this.getTagPrint(color) +
        " " +
        displaySize

      return data
    },
    getTodayString() {
      return moment(new Date(), "YYYY-MM-DDTHH:mm:ssZ").format("YYYY/MM/DD")
    },
    getProductFamily(val) {
      return this.$store.getters["defs/getOptionValueByKey"](
        "ProductFamily",
        val,
      )
    },
    getTagPrint(val) {
      return this.$store.getters["defs/getOptionValueByKey"]("TagPrint", val)
    },
    toggleCompanySeal() {
      this.isCompanySeal = !this.isCompanySeal
    },
  },
}
