var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$route.meta.noHeader
    ? _c(
        "div",
        { class: [_vm.$style.wrapper] },
        [
          _c(
            "router-link",
            { attrs: { to: "/" } },
            [
              _c("Logo", {
                class: _vm.$style.Logo,
                staticStyle: { "vertical-align": "bottom" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isLoggedIn || _vm.isCustomer
            ? _c("div", { class: _vm.$style.contact }, [
                _c(
                  "p",
                  [
                    _c("v-icon", { attrs: { color: "#ef9013" } }, [
                      _vm._v("phone"),
                    ]),
                    _c("span", [_vm._v("092-409-6039")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.pr39.com/contact/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { color: "#fff", size: "18px" } }, [
                      _vm._v("mail_outline"),
                    ]),
                    _c("span", [_vm._v("info@pr39.com")]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$route.meta.headerError
            ? _c("div", [
                _vm.isLoggedIn && _vm.isPR39
                  ? _c("div", { class: _vm.$style.leftBlock }, [
                      _c("div", {
                        class: _vm.$style.open,
                        on: { click: _vm.toggleSlideMenuShow },
                      }),
                    ])
                  : _vm.isLoggedIn
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          class: _vm.$style.anker,
                          on: { click: _vm.handleLogout },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "indigo", large: "" } },
                            [_vm._v("exit_to_app")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }