var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c("h1", { class: _vm.$style.title }, [_vm._v(_vm._s(_vm.words.title))]),
    _vm._v(" "),
    _c("div", [
      _vm.validMessage
        ? _c("p", { class: _vm.$style.message_error }, [
            _vm._v("\n      " + _vm._s(_vm.validMessage) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          class: _vm.$style.formAria,
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handlePassChange.apply(null, arguments)
            },
          },
        },
        [
          _c("p", { class: _vm.$style.desc }, [
            _vm._v(_vm._s(_vm.words.description)),
          ]),
          _vm._v(" "),
          _c(
            "InputForm",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:8|max:20|password",
                  expression: "'required|min:8|max:20|password'",
                },
              ],
              ref: "password",
              class: _vm.$style.form,
              attrs: { name: "password", type: "password" },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            },
            [_vm._v(_vm._s(_vm.words.password))]
          ),
          _vm._v(" "),
          _c(
            "InputForm",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:8|max:20|confirmed:password",
                  expression: "'required|min:8|max:20|confirmed:password'",
                },
              ],
              class: _vm.$style.form,
              attrs: {
                name: "repassword",
                type: "password",
                "data-vv-as": "password",
              },
              model: {
                value: _vm.repassword,
                callback: function ($$v) {
                  _vm.repassword = $$v
                },
                expression: "repassword",
              },
            },
            [_vm._v(_vm._s(_vm.words.repassword))]
          ),
          _vm._v(" "),
          _c(
            "Button",
            { attrs: { type: "submit", color: "submit", size: "main" } },
            [_vm._v(_vm._s(_vm.words.submit))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }