import WebpageFileupload from "@/components/organisms/webpage/FileUpload.vue"
// どうやら使ってないらしい
// import FileUploadWithOrder from "@/components/organisms/webpage/FileUploadWithOrder.vue"

const routes = [
  {
    path: "/webpage/fileupload",
    name: "webpageFileupload",
    component: WebpageFileupload,
    meta: {
      isWebpage: true,
      isPublic: true,
      title: "ファイルアップロード",
      noHeader: true,
    },
  },
  // {
  //   path: "/webpage/fileupload-with-order",
  //   name: "webpageFileuploadWithOrder",
  //   component: FileUploadWithOrder,
  //   meta: { isPublic: true, title: "ファイルアップロード", noHeader: true },
  // },
]

export default routes
