<template>
  <label :class="$style.checkbox">
    <input
      v-if="disabled !== undefined"
      disabled
      type="checkbox"
      :checked="value == 1 ? true : false"
    />
    <input
      v-else-if="NotSync !== undefined"
      disabled
      type="checkbox"
      :checked="value == '1' ? true : false"
    />
    <input
      v-else
      type="checkbox"
      :checked="value == '1' ? true : false"
      @input="updateValue"
    />
    <span
      v-if="NotSync !== undefined"
      @click="notUpdateValue"
      :class="{ [$style.disabled]: disabled !== undefined }"
    ></span>
    <span v-else :class="{ [$style.disabled]: disabled !== undefined }"></span>
  </label>
</template>

<script>
export default {
  $_veeValidate: {
    value() {
      return this.value
    },
  },
  props: {
    value: {
      type: [String, Number],
    },
    disabled: null,
    NotSync: null,
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.checked ? "1" : "0")
    },
    notUpdateValue() {
      this.$emit("click", this.value == "0" ? "1" : "0")
    },
  },
}
</script>

<style lang="scss" module>
.checkbox {
  cursor: pointer;
  > span {
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    background-color: map-get($colors, lightGray);
    position: relative;
    &.disabled {
      background-color: map-get($colors, darkGlay);
    }
  }
  > input {
    display: none;
    &:checked + span::after {
      content: "";
      width: 13px;
      height: 20px;
      position: absolute;
      top: -1px;
      left: 6px;
      border-right: 5px solid map-get($colors, red);
      border-bottom: 5px solid map-get($colors, red);
      transform: rotate(45deg);
    }
  }
}
</style>
