<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="headers"
    :filter-func="filterFunc"
    page-id="omcontracts"
    style="width:1800px;"
  >
    <template slot="page-title">
      オーダーメイド見積一覧
    </template>

    <div
      slot="filter"
      slot-scope="{
        filter,
        handleTextInput,
        handleFlagOn,
        handleSelectInput,
        filterClass,
      }"
      :class="filterClass"
    >
      <PrSelect
        label="見積内容"
        :pr-row="[100]"
        :value="filter.contentStatus"
        :options="contentStatusOptions"
        @input="handleSelectInput($event, 'contentStatus')"
      />
      <PrSelect
        label="見積先"
        :pr-row="[100]"
        :value="filter.supplier"
        :options="supplierOptions"
        @input="handleSelectInput($event, 'supplier')"
      />
      <PrInput
        label="キーワード"
        :pr-row="[100, 85]"
        :value="keywordHandler.value"
        @input="keywordHandler.input"
        @keydown.native.enter="keywordHandler.keydown"
        @keyup.native.enter="
          keywordHandler.keyup($event, handleFlagOn, handleTextInput, filter)
        "
        style="justify-content:space-between;"
      >
        <SearchButton
          slot="after"
          @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="keywordHandler.isOn"
        />
      </PrInput>
    </div>
    <div
      slot="data"
      slot-scope="{
        pagedItems,
        pageNo,
        dataTableClass,
        thTextClass,
        activeClass,
        descClass,
        sortClass,
        sort,
        handleSort,
      }"
    >
      <table :class="dataTableClass">
        <tr>
          <template v-for="({ text, value, noSort, width }, i) in headers">
            <th
              v-if="text !== 'not_for_display'"
              @click="noSort ? false : handleSort(i)"
              :class="noSort ? false : sortClass"
              :key="i"
              :style="`width:${width}%`"
            >
              <span
                :class="
                  noSort
                    ? thTextClass
                    : [
                        thTextClass,
                        { [activeClass]: sort.colIdx == i },
                        { [descClass]: sort.colIdx == i && sort.isDesc },
                      ]
                "
                >{{ text }}</span
              >
            </th>
          </template>
        </tr>
        <tr v-for="(item, i) in pagedItems[pageNo]" :key="i">
          <template v-for="(val, j) in item">
            <td v-if="!val.startsWith('not_for_display')" :key="j">
              <router-link
                v-if="val.includes('#link#')"
                :to="val.split('#link#')[1]"
              >
                {{ val.split("#link#")[0] }}
              </router-link>

              <template v-else-if="val.includes('#editbutton#')">
                <v-btn v-if="!isEdit" flat icon color="indigo">
                  <v-icon @click="handleEdit(val.split('#editbutton#')[0])"
                    >edit</v-icon
                  >
                </v-btn>
                <div
                  v-else-if="
                    isEdit && isEditTarget(val.split('#editbutton#')[0])
                  "
                  style="display: flex;"
                >
                  <v-btn
                    flat
                    icon
                    color="indigo"
                    @click="handleEdit(val.split('#editbutton#')[0])"
                  >
                    <v-icon>done_outline</v-icon>
                  </v-btn>
                  <v-btn flat icon color="indigo" @click="handleEditCancel()">
                    <v-icon>close</v-icon>
                  </v-btn>
                </div>
                <v-btn v-else flat icon color="indigo" disabled>
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>

              <template v-else-if="val.includes('#editPurchase#')">
                <template v-if="isEditTarget(val.split('#editPurchase#')[1])">
                  <PrInput
                    :name="'purchase_amount_' + i"
                    :pr-row="[0, 100]"
                    v-model="editValue.purchase_amount"
                    :validate="'numeric'"
                  />
                  <template v-if="isPurchaseAmountValide">
                    <br />
                    <PrErrorText>仕入額を入力してください</PrErrorText>
                  </template>
                </template>
                <template v-else>{{
                  val.split("#editPurchase#")[0] | formatValue
                }}</template>
              </template>

              <template v-else-if="val.includes('#editMerchandise#')">
                <template
                  v-if="isEditTarget(val.split('#editMerchandise#')[1])"
                >
                  <PrInput
                    :name="'merchandise_cost_' + i"
                    :pr-row="[0, 100]"
                    v-model="editValue.merchandise_cost"
                    :validate="'numeric'"
                  />
                </template>
                <template v-else>{{
                  val.split("#editMerchandise#")[0] | formatValue
                }}</template>
              </template>

              <template v-else-if="val.includes('#editWeight1#')">
                <PrInput
                  :name="'weight1_' + i"
                  v-if="isEditTarget(val.split('#editWeight1#')[1])"
                  :pr-row="[0, 100]"
                  v-model="editValue.weight1"
                  :validate="'decimal:3'"
                >
                </PrInput>
                <template v-else>{{
                  val.split("#editWeight1#")[0] | formatValue
                }}</template>
              </template>

              <template v-else-if="val.includes('#calcPrice#')">
                <template v-if="isEditTarget(val.split('#calcPrice#')[1])">
                  <PrInput
                    :name="'price_' + i"
                    v-if="!editValue.weight1 || editValue.weight1 === '0'"
                    :pr-row="[0, 100]"
                    v-model="editValue.price"
                    :validate="'required|decimal:3'"
                  ></PrInput>
                  <template v-else>
                    {{ calcPrice() | formatPrice }}
                  </template>
                </template>
                <template v-else>{{
                  val.split("#calcPrice#")[0] | formatPrice
                }}</template>
              </template>

              <template v-else>{{ val }}</template>
            </td>
          </template>
        </tr>
      </table>
    </div>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import {
  getDate,
  routeReplaceError,
  deepCopy,
  showToast,
  showErrorToast,
} from "@/utils/shared.js"
import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"
import { calcOrderUnitPrice } from "@/helpers/QuotationDetailViewHelper.js"

export default {
  components: {
    PrInput,
    PrSelect,
    PrErrorText,
    SearchButton,
    IndexTableProvider,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"](
      "omcontracts",
    )
    return {
      items: null,
      isEdit: false,
      editValue: {
        contract_name: null,
        purchase_amount: "",
        weight1: "",
        merchandise_cost: "",
        price: "",
      },
      keywordHandler: newWordSearchHandler("keyword", filter),
      headers: [
        {
          text: "",
          value: () => "詳細",
          link: ({ contract_name }) => `/admin/om-contracts/${contract_name}`,
          noSort: true,
          width: 2,
        },
        {
          text: "見積内容",
          value: ({ weight3 }) =>
            this.$store.getters["defs/getOptionValueByKey"](
              "EstimatesContentStatus",
              weight3,
            ),
          width: 5,
        },
        {
          text: "商談名",
          value: "request.request_name",
          link: ({ request: { request_number } }) =>
            `/admin/dealings/${request_number}`,
          width: 8,
        },
        {
          text: "見積依頼日",
          value: ({ datetime1 }) => getDate(datetime1),
          width: 6,
        },
        {
          text: "取引先名",
          value: "customer.organization.name",
          width: 6,
        },
        { text: "見積先", value: "supplier.user.name", width: 5 },
        {
          text: "商品ファミリ",
          value: ({ category1 }) =>
            this.$store.getters["defs/getOptionValueByKey"](
              "ProductFamily",
              category1,
              "JP",
            ),
          width: 10,
        },
        { text: "生産個数", value: "amount", width: 5 },
        { text: "カバー・台紙サイズ", value: "size1", width: 9 },
        { text: "付箋サイズ", value: "size2", width: 6 },
        {
          text: "付箋印刷",
          value: ({ color }) =>
            this.$store.getters["defs/getOptionValueByKey"]("TagPrint", color),
          width: 6,
        },
        { text: "付箋枚数", value: "size3", width: 4 },
        {
          text: "仕入額",
          value: ({ contract_name, purchase_amount }) =>
            `${purchase_amount}#editPurchase#${contract_name}`,
          width: 5,
        },
        {
          text: "輸送費",
          value: ({ contract_name, merchandise_cost }) =>
            `${merchandise_cost}#editMerchandise#${contract_name}`,
          width: 5,
        },
        {
          text: "掛け率",
          value: ({ contract_name, weight1 }) =>
            `${weight1}#editWeight1#${contract_name}`,
          width: 4,
        },
        {
          text: "単価",
          value: ({ contract_name, price }) =>
            `${price}#calcPrice#${contract_name}`,
          width: 4,
        },
        {
          text: "更新日時",
          value: ({ updated_at }) =>
            moment(updated_at).format("YYYY/MM/DD HH:mm:ss"),
          width: 7,
        },
        {
          text: "備考",
          value: ({ description }) => description || " ",
          width: 14,
        },
        {
          text: "",
          width: 5,
          value: ({ contract_name }) => `${contract_name}#editbutton#`,
          noSort: true,
        },
      ],
      contentStatusOptions: [
        { key: "すべて", value: "すべて" },
        ...this.$store.getters["defs/getOptions"]("EstimatesContentStatus").map(
          ecs => ({
            ...ecs,
            key: ecs.value,
          }),
        ),
      ],
      supplierOptions: null,
    }
  },
  filters: {
    formatValue: value => (value == "null" || value == "" ? "未設定" : value),
    formatPrice: value => (value != "算出不可" ? value + "円" : value),
  },
  computed: {
    isPurchaseAmountValide() {
      return !this.editValue.purchase_amount && this.editValue.weight1
    },
  },
  methods: {
    filterFunc: ({ contentStatus, supplier, keyword }) => item => {
      let test = true

      if (contentStatus !== "すべて") {
        test = item[1] === contentStatus
      }

      if (test && supplier !== "すべて") {
        test = item[5] === supplier
      }

      test = keywordFilter(keyword, test, item)

      return test
    },
    clearEditValue() {
      this.editValue = {
        contract_name: null,
        purchase_amount: "",
        weight1: "",
        merchandise_cost: "",
        price: "",
      }
    },
    calcPrice() {
      const cd = {
        purchase_amount: this.editValue.purchase_amount,
        weight1: this.editValue.weight1,
        price: this.editValue.price,
      }

      this.editValue.price = String(calcOrderUnitPrice(cd))
      return this.editValue.price
    },
    async handleEdit(_contract_name) {
      const result = await this.$validator.validateAll().then(r => r)
      if (!result || this.isPurchaseAmountValide) {
        return false
      }

      this.isEdit = !this.isEdit

      if (!this.isEdit) {
        await this.saveValue()
        this.clearEditValue()
        return
      }

      this.editValue = deepCopy(
        this.items.find(({ contract_name }) => contract_name == _contract_name),
      )
    },
    async saveValue() {
      const {
        id,
        lock_version,
        contract_name,
        contract_no,
        purchase_amount,
        merchandise_cost,
        weight1,
        price,
        amount,
        category2,
        tax_category,
      } = this.editValue

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.post(
          paths.ADMIN_OM_CONTRACTS,
          {
            id: id,
            contract_no: contract_no,
            contract_name: contract_name,
            lock_version: lock_version,
            purchase_amount: purchase_amount,
            merchandise_cost: merchandise_cost,
            weight1: weight1,
            price: price,
            amount: amount,
            category2: category2,
            tax_category: tax_category,
          },
          this.$store.state.auth.token,
        )
        if (!ok) {
          if (body.slice(-3) === String(400)) {
            showErrorToast(this, body.slice(-3), errorMsg, errorMsg)
            return
          }
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        showToast(this, "success", "更新しました。", 2000, false)

        // データ書き換え
        const index = this.items.findIndex(
          item => item.contract_name == contract_name,
        )
        this.$set(this.items, index, {
          ...this.items[index],
          id: body.id,
          lock_version: body.lock_version,
          purchase_amount: body.purchase_amount,
          weight1: body.weight1,
          merchandise_cost: body.merchandise_cost,
          price: body.price,
        })
      } finally {
        loader.hide()
      }
    },
    handleEditCancel() {
      this.isEdit = false
      this.clearEditValue()
    },
    isEditTarget(contract_name) {
      return this.editValue.contract_name == contract_name
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const { ok, body } = await api.get(
        paths.ADMIN_OM_CONTRACTS,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.supplierOptions = body.reduce(
        (acc, curr) => {
          const { supplier } = curr
          if (!supplier) {
            return acc
          }

          const {
            user: { name },
          } = supplier

          return acc.some(({ key }) => key === name)
            ? acc
            : [...acc, { key: name, value: name }]
        },
        [{ key: "すべて", value: "すべて" }],
      )

      this.items = body
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>
