exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".UserPasswordReminderDone__wrapper--3ccvl {\n  min-height: 100vh;\n  background-color: #f6f6f6;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.UserPasswordReminderDone__content--3JhzH {\n  width: 100%;\n  padding: 0 20px;\n  max-width: 800px;\n  margin-top: 50px;\n}\n.UserPasswordReminderDone__title--2Kz9p {\n  padding: 55px 0;\n}\n.UserPasswordReminderDone__formAria--e8SYe {\n  margin: 0 auto;\n  padding: 90px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #fff;\n}\n.UserPasswordReminderDone__formAria_text--2cMdb {\n    padding: 30px;\n}\n.UserPasswordReminderDone__formAria_text--2cMdb > span {\n      font-weight: bold;\n}\n", "",{"version":3,"sources":["/client/src/components/molecules/UserPasswordReminderDone.vue"],"names":[],"mappings":"AAKA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AAAA;AAErB;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAAA;AAGlB;EACE,eAAe;AAAA;AAGjB;EAEE,cAAc;EACd,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;AAAA;AAEtB;IACE,aAAa;AAAA;AADd;MAGG,iBAAiB;AAAA","file":"UserPasswordReminderDone.vue?vue&type=style&index=0&id=2acbbf0c&lang=scss&module=true","sourcesContent":["\n                @import \"@/scss/_variables.scss\";\n                @import \"@/scss/_mixin_function.scss\";\n              \n\n.wrapper {\n  min-height: 100vh;\n  background-color: #f6f6f6;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.content {\n  width: 100%;\n  padding: 0 20px;\n  max-width: 800px;\n  margin-top: 50px;\n}\n\n.title {\n  padding: 55px 0;\n}\n\n.formAria {\n  //width: 800px;\n  margin: 0 auto;\n  padding: 90px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #fff;\n\n  &_text {\n    padding: 30px;\n    > span {\n      font-weight: bold;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"wrapper": "UserPasswordReminderDone__wrapper--3ccvl",
	"content": "UserPasswordReminderDone__content--3JhzH",
	"title": "UserPasswordReminderDone__title--2Kz9p",
	"formAria": "UserPasswordReminderDone__formAria--e8SYe",
	"formAria_text": "UserPasswordReminderDone__formAria_text--2cMdb"
};