var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c("h3", [_vm._v("商談管理No : " + _vm._s(_vm.deal.request_number))]),
    _vm._v(" "),
    _c("div", { class: _vm.$style.inner }, [
      _c(
        "div",
        { class: _vm.$style.buttons },
        [
          _c(
            "Button",
            {
              attrs: { size: "assist", color: "link" },
              on: {
                click: function ($event) {
                  return _vm.$router.push(
                    `/customer/ordered-histories/${_vm.deal.request_number}`
                  )
                },
              },
            },
            [_vm._v("詳細を確認")]
          ),
          _c("br"),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: { size: "assist", color: "submit" },
              on: {
                click: function ($event) {
                  return _vm.$router.push(
                    `/customer/ordered-histories/${_vm.deal.request_number}/re-order`
                  )
                },
              },
            },
            [_vm._v("再注文")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { class: _vm.$style.txt }, [
        _vm._v(
          "\n      注文日 : " +
            _vm._s(
              _vm._f("getDate")(_vm.deal.contract.contract.contracted_date)
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { class: _vm.$style.txt }, [
        _vm._v("お支払 : " + _vm._s(_vm._f("formatPrice")(_vm.deal.payment))),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.img }, [
        _c(
          "p",
          { class: _vm.$style.txt, staticStyle: { "margin-bottom": "0" } },
          [_vm._v("最終確認画像:")]
        ),
        _vm._v(" "),
        _c("div", [
          _c("img", { attrs: { src: _vm.deal.verification_image_path } }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { class: _vm.$style.ul },
        [
          _c("li", [_vm._v("詳細品目")]),
          _vm._v(" "),
          _vm._l(_vm.contractDetailMade, function (contractDetail) {
            return _c("li", { key: contractDetail.id }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.getProductName(contractDetail)) +
                  "\n      "
              ),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.deal.display_ordered_status != null
        ? _c("p", { class: _vm.$style.status }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$store.getters["defs/getOptionValueByKey"](
                    "OrderedStatus",
                    _vm.deal.display_ordered_status
                  )
                ) +
                "\n    "
            ),
          ])
        : _c("p", { class: _vm.$style.status }, [
            _vm._v("\n      生産中\n    "),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }