<template>
  <div :class="$style.modalWrapper">
    <div :class="$style.block">
      <h2 :class="$style.block_ttl">업로드데이터 수정의뢰</h2>
      <div :class="$style.block_container">
        <div :class="$style.modal">
          <p :class="$style.modal_lightGray" v-cloak>
            견적/주문No.{{ values.request_number }}
            <br />
            {{ values.order_data_name }}
          </p>

          <PrTextarea
            label="아래 수정내용을 입력해주세요"
            name="modify_content"
            v-model="textariaValue"
            :validate="'required'"
            :pr-row="[100, 100]"
          />
          <div :class="$style.buttons">
            <Button color="submit" size="assist" @click="handleModifyRequest()"
              >수정 의뢰하기</Button
            >
            <Button color="cancel" size="assist" @click="handleClose()"
              >취소</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    Button,
    PrTextarea,
  },
  data() {
    return {
      textariaValue: "",
    }
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("changed", false)
    },
    async handleModifyRequest() {
      // バリデーション
      const result = await this.$validator
        .validate("modify_content")
        .then(result => {
          return result
        })

      if (!result) {
        return
      }

      const item = this.values.request_appendices.find(data => {
        return data.appendix_category === "draft"
      })

      // API実行
      const data = {
        id: item.id,
        lock_version: item.lock_version,
        request_key1: item.request_key1,
        request_number: item.request_number,
        appendix_description: this.textariaValue,
      }

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.SUPPLIER_DRAFT_CHECK}/${item.request_number}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          console.log(`put SUPPLIER_DRAFT_CHECK ${body}`)
          showErrorToast(
            this,
            body.slice(-3),
            errorMsg,
            "등록에 실패했습니다",
            "KR",
          )

          return
        }

        this.$emit("changed", true)
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include clientPage();

.modal {
  &Wrapper {
    z-index: map-get($zIndexs, formBox);
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(map-get($colors, lightGray), 0.8);
    .block {
      width: 100%;
      max-width: 600px;
      position: relative;
      &_container {
        display: block;
        margin: 0;
      }
    }
  }
  max-width: 400px;
  margin: 0 auto;
  &_lightGray {
    margin-bottom: 15px;
    padding: 10px;
    text-align: center;
    background-color: map-get($colors, lightGray);
  }
}

.buttons {
  margin-top: 20px;
  text-align: center;
}
</style>
