<template>
  <ModalToggler>
    <template slot="button">
      <Button
        v-if="this.contructDetail === null"
        size="main"
        color="submit"
        plus
        >新規オーダーメイド見積</Button
      >
      <v-btn v-else flat icon color="black">
        <v-icon>edit</v-icon>
      </v-btn>
    </template>
    <template slot-scope="{ toggle }">
      <OMContractDetailEditer
        v-bind="{ contructDetail }"
        @saved="$emit('saved', $event)"
      >
        <div
          slot="buttons"
          slot-scope="{ handleAddButtonClick, init }"
          :class="$style.buttons"
        >
          <Button
            size="main"
            color="submit"
            @click="handleSaveClick(handleAddButtonClick, init, toggle)"
            >保存</Button
          >
          <Button
            size="assist"
            color="cancel"
            @click="
              () => {
                init()
                toggle()
              }
            "
            >キャンセル</Button
          >
        </div>
      </OMContractDetailEditer>
    </template>
  </ModalToggler>
</template>

<script>
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import OMContractDetailEditer from "@/components/molecules/OMContractDetailEditer.vue"
import Button from "@/components/atoms/Button.vue"

export default {
  components: {
    ModalToggler,
    OMContractDetailEditer,
    Button,
  },
  props: {
    contructDetail: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async handleSaveClick(handleAddButtonClick, init, toggle) {
      const result = await handleAddButtonClick()
      console.log("result", result)
      if (!result) {
        return
      }
      init()
      toggle()
    },
  },
}
</script>

<style lang="scss" module>
.buttons {
  margin-top: 30px;
  text-align: center;
  width: 100%;
}
</style>
