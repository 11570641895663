var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [_vm._v("見積確認")]),
          _vm._v(" "),
          _c("CustomerUserName", {
            attrs: { "customer-person": _vm.items.customer_person },
          }),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "100%", margin: "0", center: "" } },
            [
              _c("template", { slot: "head" }, [
                _vm._v("\n      お客様（注文者）ご住所\n    "),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.explain }, [
                _vm._v(
                  "\n      下記のお客様の見積内容から、注文を確定したい内容を選択して下さい。\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.contracts },
                _vm._l(_vm.details, function (detail, index) {
                  return _c(
                    "div",
                    { key: index, class: _vm.$style.quotation },
                    [
                      _c("PrInputRadio", {
                        attrs: {
                          label: `見積NO.${detail.no}`,
                          name: "quotation",
                          value: index,
                        },
                        model: {
                          value: _vm.orderVal,
                          callback: function ($$v) {
                            _vm.orderVal = $$v
                          },
                          expression: "orderVal",
                        },
                      }),
                      _vm._v(" "),
                      detail.productPeriod != null &&
                      detail.productPeriod != "0"
                        ? _c(
                            "p",
                            { class: _vm.$style.quotation_productionPeriod },
                            [
                              _vm._v(
                                "\n          製作期間：ご注文（データ入稿）から\n          "
                              ),
                              _c("span", [
                                _vm._v(_vm._s(detail.productPeriod) + "日後"),
                              ]),
                              _vm._v("に発送予定（土日祝を除く）\n        "),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(detail.data, function (item, index2) {
                        return _c(
                          "div",
                          { key: index2, class: _vm.$style.quotation_detail },
                          [
                            item.name.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.name.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.name.value) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.size1.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.size1.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.size1.value) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.category3.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.category3.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.category3.value) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.size2.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.size2.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.size2.value) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.category4.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.category4.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.category4.value) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.color.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.color.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.color.value) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.size3.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.size3.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.size3.value) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.amount.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.amount.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("formatDecimalAmount")(
                                                item.amount.value
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.price.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.price.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("formatDecimalPrice")(
                                                item.price.value
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.total.visible
                              ? _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.total.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("formatDecimalPrice")(
                                                item.total.value
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {
                              class: _vm.$style.quotation_detail_divider,
                            }),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(detail.options, function (option, index) {
                        return _c(
                          "div",
                          {
                            key: `option-${index}`,
                            class: _vm.$style.quotation_detail,
                          },
                          [
                            _c(
                              "div",
                              { class: _vm.$style.quotation_detail_row },
                              [
                                _c(
                                  "p",
                                  {
                                    class: _vm.$style.quotation_detail_row_name,
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(option.name) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    class: _vm.$style.quotation_detail_row_txt,
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("formatDecimalPrice")(
                                            option.price
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      detail.options.length !== 0
                        ? _c("div", { class: _vm.$style.quotation_detail }, [
                            _c("div", {
                              class: _vm.$style.quotation_detail_divider,
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.quotation_detail },
                        [
                          _c(
                            "div",
                            { class: _vm.$style.quotation_detail_row },
                            [
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_name },
                                [_vm._v("備考")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_note },
                                [_vm._v(_vm._s(detail.note1))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: _vm.$style.quotation_detail_row },
                            [
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_name },
                                [_vm._v("見積総額(税別)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_txt },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("formatDecimalPrice")(
                                          +detail.total_amount +
                                            +detail.discount
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: _vm.$style.quotation_detail_row },
                            [
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_name },
                                [_vm._v("消費税")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_txt },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("formatDecimalPrice")(
                                          detail.tax_amount
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          detail.discount != "0"
                            ? [
                                _c(
                                  "div",
                                  { class: _vm.$style.quotation_detail_row },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_name,
                                      },
                                      [_vm._v("お値引き")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        class:
                                          _vm.$style.quotation_detail_row_txt,
                                      },
                                      [
                                        _vm._v(
                                          "\n                -" +
                                            _vm._s(
                                              _vm._f("formatDecimalPrice")(
                                                detail.discount
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: _vm.$style.quotation_detail_row },
                            [
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_name },
                                [_vm._v("見積総額(税込)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { class: _vm.$style.quotation_detail_row_txt },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("formatDecimalPrice")(
                                          Number(detail.total_amount) +
                                            Number(detail.tax_amount)
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    2
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.bottomAria }, [
                _c("p", { class: [_vm.$style.explain, _vm.$style.rice] }, [
                  _vm._v(
                    "\n        ※カバーへのフルカラー印刷無料。 OPP個包装・送料無料\n        但し、送料は一括納品 に限ります。\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { class: [_vm.$style.explain, _vm.$style.rice] }, [
                  _vm._v(
                    "\n        ※お見積価格はご入稿いただくデザインの内容に\n        よっては変動がある場合がございます。\n        お見積の有効期限は1ヵ月となっております。\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.button },
                  [
                    _c(
                      "Button",
                      {
                        attrs: { color: "submit", size: "main" },
                        on: { click: _vm.handleMoveOrder },
                      },
                      [_vm._v("注文画面へ進む")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { class: _vm.$style.proof }, [
                  _c("p", { class: _vm.$style.proof_txt }, [
                    _vm._v(
                      "\n          注文前に色校正を希望される方はこちらから\n          （色校正の場合、仕様により代金が変わります\n          ので、受付後弊社からメールでお知らせいた します）\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.button },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { color: "submit", size: "main" },
                          on: { click: _vm.handleMoveProof },
                        },
                        [_vm._v("色校正へ進む")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }