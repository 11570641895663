<template>
  <div v-if="items" :class="$style.basicInfo">
    <div :class="$style.basicInfo_top">
      <div :class="$style.basicInfo_topLeft">
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_name">商談管理No</span>
          <router-link
            :to="`/admin/dealings/${items.request_number}`"
            :class="$style.basicInfo_txt"
            >{{ items.request_number }}
          </router-link>
        </div>
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_name">商談名</span>
          <p :class="[$style.basicInfo_txt, $style.dealName]">
            {{ deal ? deal.request_name : items.request_name }}
          </p>
        </div>
      </div>
      <div :class="$style.basicInfo_topRight">
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_name">発注先</span>
          <p v-if="items.supplier" :class="[$style.basicInfo_txt, $style.bold]">
            {{
              $store.getters["defs/getOptionValueByKey"](
                "SupplierCode",
                items.supplier.supplier_code,
              )
            }}
          </p>
          <p v-else :class="[$style.basicInfo_txt, $style.bold]">
            未確定
          </p>
        </div>
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_name">担当者</span>
          <p :class="[$style.basicInfo_txt, $style.bold]">
            {{ items.user ? items.user.name : "未設定" }}
          </p>
          <ModalToggler v-if="deal" :scroll="false">
            <template slot="button">
              <v-btn round outline color="indigo" style="margin:0 0 0 10px;"
                >変更
              </v-btn>
            </template>
            <template slot-scope="{ toggle }">
              <InfoBlock width="auto" block center>
                <template slot="head">
                  担当者変更
                </template>
                <div :class="$style.flex">
                  <PrSelect
                    :pr-row="[100]"
                    v-model="user_id"
                    :options="userOptions"
                    name="user_id"
                    validate="required"
                    :searchable="true"
                    style="width:260px"
                  />
                  <p>
                    <Button
                      size="main"
                      color="submit"
                      @click.native="handleStaffChange(toggle)"
                      >変更
                    </Button>
                    <Button
                      size="assist"
                      color="cancel"
                      @click="handleCancelClick(toggle)"
                      >キャンセル
                    </Button>
                  </p>
                </div>
              </InfoBlock>
            </template>
          </ModalToggler>
        </div>
        <DialogProvider
          v-if="deal"
          :texts="{
            ttl: '確認',
            msg: `「${deal.request_name}」をコピーしますか？`,
            yas: 'はい',
            no: 'いいえ',
          }"
          @accept="handleCopyDealing"
        >
          <v-btn slot-scope="{ on }" v-on="on" icon>
            <v-icon>content_copy</v-icon>
          </v-btn>
        </DialogProvider>
        <DialogProvider
          v-if="deal"
          :texts="{
            ttl: '確認',
            msg: `本当に「${deal.request_name}」を削除しますか？`,
            yas: 'はい',
            no: 'いいえ',
          }"
          @accept="handleDeleteDealing"
        >
          <v-btn slot-scope="{ on }" v-on="on" icon>
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </DialogProvider>
      </div>
    </div>
    <div :class="$style.basicInfo_bottom">
      <div :class="$style.basicInfo_bottomLeft">
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_ttl">取引先名</span>
          <p v-if="isCustomerExists" :class="[$style.basicInfo_txt]">
            <router-link
              :to="
                '/admin/dealings/' +
                  items.request_number +
                  '/customers/' +
                  (deal ? deal.customer.id : items.customer_id)
              "
            >
              {{ deal ? deal.customer.organization.name : items.customer_name }}
            </router-link>
          </p>

          <p v-else :class="[$style.basicInfo_txt]">
            未設定
          </p>
        </div>
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_ttl">取引先責任者名</span>
          <p v-if="isCustomerPersonExists" :class="[$style.basicInfo_txt]">
            <router-link
              :to="
                '/admin/dealings/' +
                  items.request_number +
                  '/customer-persons/' +
                  (deal ? deal.customer_person.id : items.customer_person_id)
              "
            >
              {{
                deal
                  ? deal.customer_person.last_name +
                    deal.customer_person.first_name
                  : items.customer_person_name
              }}
            </router-link>
          </p>
          <p v-else :class="[$style.basicInfo_txt]">
            未設定
          </p>
        </div>
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_ttl">発注日</span>
          <p>{{ items.order_date | getDate }}</p>
        </div>
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_ttl">納品希望日</span>
          <p>{{ items.delivery_dates | getDate }}</p>
        </div>
        <div :class="$style.basicInfo_item">
          <span :class="$style.basicInfo_ttl">注文総額（税込）</span>
          <p>{{ items.total_order_amount | formatPrice }}</p>
        </div>
      </div>
      <DealingHeaderAlert
        v-if="items.alert_message.length"
        :msgs="items.alert_message"
      />
    </div>
  </div>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import DealingHeaderAlert from "@/components/molecules/DealingHeaderAlert.vue"
import Button from "@/components/atoms/Button.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import api, { paths } from "@/utils/api.js"
import { getDate, formatPrice } from "@/utils/shared.js"
import { showErrorToast, showToast } from "@/utils/shared.js"

export default {
  components: {
    DealingHeaderAlert,
    DialogProvider,
    ModalToggler,
    PrSelect,
    InfoBlock,
    Button,
  },
  props: {
    deal: {
      type: Object,
    },
    headerInfo: {
      type: Object,
    },
    users: Array,
  },
  data() {
    return {
      items: null,
      isAallMessage: false,
      userOptions: null,
      user_id: null,
    }
  },
  filters: {
    getDate,
    formatPrice,
  },
  computed: {
    isCustomerExists() {
      if (this.deal && this.deal.customer) {
        return true
      } else if (this.items.customer_id) {
        return true
      }
      return false
    },
    isCustomerPersonExists() {
      if (this.deal && this.deal.customer_person) {
        return true
      } else if (this.items.customer_person_id) {
        return true
      }
      return false
    },
  },
  methods: {
    async handleStaffChange(toggle) {
      const result = await this.$validator.validateAll().then(r => r)
      if (!result) {
        return
      }
      const {
        $store,
        init,
        user_id,
        deal: { request_key1, request_number, lock_version },
      } = this

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          paths.ADMIN_DEALINGS_ETC + "/" + request_number,
          {
            request_key1,
            request_number,
            lock_version,
            user_id,
            description: "担当者が変更されました。",
          },
          $store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$emit("updated", body)
        toggle()
        init()
      } finally {
        loader.hide()
      }
    },
    handleCancelClick(toggle) {
      toggle()
      this.init()
    },
    async handleDeleteDealing() {
      const { ok, body, errorMsg } = await api.DELETE(
        paths.ADMIN_DEALINGS + "/" + this.deal.request_number,
        this.$store.state.auth.token,
      )
      if (!ok) {
        showErrorToast(this, body.slice(-3), errorMsg, "削除に失敗しました")
        return
      }

      showToast(
        this,
        "success",
        `「${this.deal.request_name}」を削除しました。`,
        3000,
      )
      this.$router.push("/admin/dealings")
    },
    async handleCopyDealing() {
      const { ok, body, errorMsg } = await api.post(
        paths.ADMIN_DEALINGS_COPY + "/" + this.deal.request_number,
        null,
        this.$store.state.auth.token,
      )
      if (!ok) {
        showErrorToast(this, body.slice(-3), errorMsg, "コピーに失敗しました")
        return
      }
      showToast(
        this,
        "success",
        `「${this.deal.request_name}」をコピーしました。`,
        3000,
      )
      this.$router.push("/admin/dealings")
    },
    async init() {
      if (this.users) {
        this.userOptions = this.users
          .filter(({ status }) => status !== 9)
          .map(({ id: key, name: value }) => ({
            key,
            value,
          }))
      }

      if (this.headerInfo) {
        this.items = this.headerInfo
        this.user_id = this.headerInfo.user ? this.headerInfo.user.id : null
        return
      }

      const { ok, body } = await api.get(
        paths.ADMIN_DEALINGS_HEADER + "/" + this.$route.params.deal_id,
        this.$store.state.auth.token,
      )
      if (!ok) {
        return
      }
      this.items = body
      this.user_id = body.user ? body.user.id : null
    },
  },
  async created() {
    this.init()
  },
}
</script>

<style lang="scss" module>
.basicInfo_top {
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  background-color: map-get($colors, lightYellow);
}

.basicInfo_bottom {
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  background-color: map-get($colors, white);
}

.basicInfo_topLeft {
  display: flex;
}

.basicInfo_topRight {
  display: flex;
}

.basicInfo_bottomLeft {
  display: flex;
  align-items: center;
}

.basicInfo_bottomRight {
  display: flex;
  cursor: pointer;
}

.basicInfo_item {
  margin-right: 35px;
  display: flex;
  align-items: center;
}

.basicInfo_name {
  padding: 10px 15px;
  margin-right: 15px;
  border-radius: 20px;
  font-size: 0.14rem;
  color: map-get($colors, darkDarkGray);
  background-color: map-get($colors, white);
}

.basicInfo_ttl {
  margin-right: 10px;
  font-size: 0.14rem;
  color: map-get($colors, darkDarkGray);
}

.dealName {
  font-size: 0.2rem;
  font-weight: bold;
}

.alert {
  width: 250px;
  height: calc(1em + 10px);
  margin-left: 10px;
  position: relative;
  font-weight: bold;
  color: map-get($colors, red);

  &_msgs {
    background-color: map-get($colors, white);
    position: absolute;
    top: 0px;
    left: 0px;

    &_msg {
      padding: 5px 0;
    }
  }
}

.bold {
  font-weight: bold;
}

.flex {
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
