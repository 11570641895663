import * as types from "@/store/mutation-types"

const state = {
  message: null,
}

const getters = {}

const actions = {
  setMessage: ({ commit }, payload) => {
    commit(types.SET_MESSAGE, payload)
  },
  clearMessage: ({ commit }) => {
    commit(types.SET_MESSAGE, { message: null })
  },
}

const mutations = {
  [types.SET_MESSAGE](state, { message }) {
    state.message = message
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
