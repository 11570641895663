<template>
  <div v-if="items !== null" :class="$style.wrapper">
    <PageTitle>商談一覧</PageTitle>

    <div :class="$style.button">
      <Button
        to="/admin/dealings/card/print"
        target="_blank"
        size="main"
        color="submit"
      >
        検品一覧印刷</Button
      >
    </div>

    <main :class="$style.phase">
      <div v-for="name in phaseNames" :key="name" :class="$style.phase_head">
        <span>{{ name }}</span>
      </div>
      <div
        v-for="(deals, i) in phaseDeals"
        :key="i"
        :class="$style.phase_deals"
        v-on="i === 7 ? dragHandler.Inspect : {}"
      >
        <DealingIndexCardItem
          v-for="deal in deals"
          :deal="deal"
          :key="deal.request_number"
          @edit="handleModalToggle"
          v-on="deal.status === 13 ? dragHandler.goodsIssue : {}"
        />
      </div>
    </main>

    <DealingIndexCardModal
      v-if="selectedDeal !== null"
      :deal="selectedDeal"
      :position="modalPosition"
      @save="handleSave"
      @cancel="handleModalClose"
    />
  </div>
</template>

<script>
import DealingIndexCardItem from "@/components/molecules/DealingIndexCardItem.vue"
import DealingIndexCardModal from "@/components/molecules/DealingIndexCardModal.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"
import { newDragHandler } from "@/helpers/AdminDealingIndexCardHelper.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"

const theFirstDay = moment("0000-00-00")

export default {
  components: {
    PageTitle,
    DealingIndexCardItem,
    DealingIndexCardModal,
    Button,
  },
  data() {
    return {
      items: null,
      selectedDeal: null,
      modalPosition: null,
      dragHandler: newDragHandler(this.updateStatus, this.$style.dragover),
    }
  },
  computed: {
    filteredPhases() {
      return this.$store.getters["defs/getOptions"]("Progress").filter(
        ({ key }) => !["9", "99"].includes(key),
      )
    },
    phaseDeals() {
      return this.filteredPhases.map(({ key }) => {
        const items = this.items
          .filter(({ status }) => Number(key) === status)
          .sort((a, b) => +a.request_number - +b.request_number)
        if ([1, 2, 3].includes(status)) {
          return items
        }
        return items.sort((a, b) =>
          moment(a.request_date2 || theFirstDay).isBefore(
            b.request_date2 || theFirstDay,
          )
            ? -1
            : 1,
        )
      })
    },
    phaseNames() {
      return this.filteredPhases.map(
        ({ value }, idx) => `${value}（${this.phaseDeals[idx].length}）`,
      )
    },
  },
  methods: {
    async updateStatus(deal) {
      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_DEALINGS_PROGRESS}/${deal.request_number}`,
          { lock_version: deal.lock_version, status: 14 },
          this.$store.state.auth.token,
        )
        if (!ok) {
          console.log("body", body)
          if (body.slice(-3) === String(400)) {
            showErrorToast(this, body.slice(-3), errorMsg, errorMsg)
            return
          }
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        await this.init()
      } finally {
        loader.hide()
      }
    },
    handleModalToggle({ deal, top, right }) {
      if (Object.is(this.selectedDeal, deal)) {
        this.handleModalClose()
      } else {
        this.selectedDeal = deal
        this.modalPosition = { top, right }
      }
    },
    handleModalClose() {
      this.selectedDeal = null
      this.modalPosition = null
    },
    async handleSave(putValues) {
      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_DEALINGS_INSPECT}/${putValues.request_number}`,
          putValues,
          this.$store.state.auth.token,
        )
        if (!ok) {
          console.log("body", body)
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        this.handleModalClose()
        await this.init()
      } finally {
        loader.hide()
      }
    },
    async init() {
      const { ok, body } = await api.get(
        paths.DEALINGS_CARDS,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }
      this.items = body
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      await this.init()
    } finally {
      loader.hide()
    }
  },
}
</script>

<style lang="scss" module>
/* autoprefixer: off */
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.phase {
  margin: 0 auto 30px;
  display: grid;
  grid-template-columns: repeat(11, 170px);
  grid-template-rows: repeat(2, auto);
  border-left: 1px solid map-get($colors, lightYellow);
  border-right: 1px solid map-get($colors, lightYellow);
  border-bottom: 2px solid map-get($colors, lightYellow);
  &_head {
    height: 40px;
    background-color: map-get($colors, lightYellow);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  &_deals {
    padding-top: 10px;
    border-left: 1px solid map-get($colors, lightYellow);
    border-right: 1px solid map-get($colors, lightYellow);
    display: grid;
    grid-template-columns: 160px;
    grid-auto-rows: auto;
    grid-row-gap: 10px;
    align-content: baseline;
    justify-content: center;
    &.dragover {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(map-get($colors, green), 0.4);
        pointer-events: none;
      }
    }
  }
}
.button {
  display: grid;
  grid-template-columns: repeat(10, 170px);
  margin: -50px auto 20px;
  > a {
    display: block;
    grid-column: 7;
    text-align: center;
  }
}
</style>
