var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { block: "", width: "48%", margin: "0" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    オプション代金\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              value:
                _vm.value.contract.contract_details[_vm.optionIdxs.proofreading]
                  .price,
              label: "色校正代金",
              "pr-row": [60],
              name: "proofreading",
              validate: "required|numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, _vm.optionIdxs.proofreading)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              value:
                _vm.value.contract.contract_details[_vm.optionIdxs.design_fee]
                  .price,
              label: "デザイン料",
              "pr-row": [60],
              name: "design_fee",
              validate: "required|numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, _vm.optionIdxs.design_fee)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              value:
                _vm.value.contract.contract_details[_vm.optionIdxs.mount_fee]
                  .price,
              label: "MF台紙代",
              "pr-row": [60],
              name: "mount_fee",
              validate: "required|numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, _vm.optionIdxs.mount_fee)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              value:
                _vm.value.contract.contract_details[_vm.optionIdxs.type_fee]
                  .price,
              label: "型代",
              "pr-row": [60],
              name: "type_fee",
              validate: "required|numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, _vm.optionIdxs.type_fee)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              value:
                _vm.value.contract.contract_details[_vm.optionIdxs.discount]
                  .price,
              label: "値引き",
              "pr-row": [60],
              name: "discount",
              validate: "required|numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, _vm.optionIdxs.discount)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              value:
                _vm.value.contract.contract_details[_vm.optionIdxs.shipping_fee]
                  .price,
              label: "追加送料",
              "pr-row": [60],
              name: "shipping_fee",
              validate: "required|numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, _vm.optionIdxs.shipping_fee)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              value:
                _vm.value.contract.contract_details[_vm.optionIdxs.other_fee]
                  .price,
              label: "その他代金",
              "pr-row": [60],
              name: "other_fee",
              validate: "required|numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, _vm.optionIdxs.other_fee)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", [
          _c("span", [_vm._v("企業値引き")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm._f("calcTotalCompanyDiscount")(
                  _vm.value.contract.contract_details,
                  _vm.discount
                )
              )
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }