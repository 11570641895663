export const SET_PAGE_INFO = "SET_PAGE_INFO"
export const AUTH_LOGIN = "AUTH_LOGIN"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS"
export const FETCH_SEARCH_DEFINITIONS = "FETCH_SEARCH_DEFINITIONS"
export const SET_MESSAGE = "SET_MESSAGE"
export const SET_CUSTOMER_DEALING = "SET_CUSTOMER_DEALING"
export const SET_CUSTOMER_REQUEST_ID = "SET_CUSTOMER_REQUEST_ID"
export const SET_CUSTOMER_ESTIMATE_NO = "SET_CUSTOMER_ESTIMATE_NO"
export const SET_CUSTOMER_ORDER_INFO = "SET_CUSTOMER_ORDER_INFO"
export const SET_PASSWORD_REMINDER_EMAIL = "SET_PASSWORD_REMINDER_EMAIL"
export const SET_CUSTOMER_REORDER_INFO = "SET_CUSTOMER_REORDER_INFO"
