var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OrdersheetProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ values, onChanged, isSupplier, isEdit }) {
          return [
            _c(
              "div",
              { class: _vm.$style.wrapper },
              _vm._l(
                values.contract.contract.contract_details,
                function (detail, index) {
                  return _c(
                    "div",
                    { key: index, class: _vm.$style.container },
                    [
                      _c("h1", [_vm._v("PR39 주문의뢰서")]),
                      _vm._v(" "),
                      _c("section", [
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  class: _vm.$style.title,
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v("의 뢰 자 정 보")]
                              ),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                              _vm._v(" "),
                              _c("th", { class: _vm.$style.highlight }, [
                                _vm._v("작 성 일"),
                              ]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.highlight }, [
                                _vm._v("작성날짜"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("의 뢰 명")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "description",
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: values.contract.description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.contract.description",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(values.contract.description)
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("th", [_vm._v("의 뢰 일")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrDatePicker", {
                                          attrs: {
                                            name: "contracted_date",
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contracted_date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract,
                                                "contracted_date",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.contract.contract.contracted_date",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("getDate")(
                                                values.contract.contract
                                                  .contracted_date
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("의 뢰 자")]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "2" } }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      values.user ? values.user.name : "미 설정"
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [_vm._v("납 기 희 망 일")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrDatePicker", {
                                          attrs: {
                                            name: "request_date1",
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: values.product.request_date1,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.product,
                                                "request_date1",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.product.request_date1",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("getDate")(
                                                values.product.request_date1
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("품 목")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "category1_" + index,
                                            validate: "required",
                                            options: _vm.productFamilyOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: detail.category1,
                                            callback: function ($$v) {
                                              _vm.$set(detail, "category1", $$v)
                                            },
                                            expression: "detail.category1",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "ProductFamily",
                                                detail.category1,
                                                "KR"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("th", [_vm._v("납 기 일")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrDatePicker", {
                                          attrs: {
                                            name: "request_date2",
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: values.product.request_date2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.product,
                                                "request_date2",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.product.request_date2",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("getDate")(
                                                values.product.request_date2
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("T E L")]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "2" } }, [
                                _vm._v("+81-92-409-6039"),
                              ]),
                              _vm._v(" "),
                              _c("th", [_vm._v("F A X")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("+81-92-409-6097")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("배 송 주 소")]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "4" } }, [
                                _vm._v(
                                  "\n                  부산시동구초량동충장대로206 국제여객터미널 탁송화물 검사장\n                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", [
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  class: _vm.$style.title,
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v("작 업 상 세 내 역")]
                              ),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("원 단 종 류")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "quantity1",
                                            validate: "required",
                                            options: _vm.fabricTypeOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order.fabric_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "fabric_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.fabric_type\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "FabricType",
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order.fabric_type
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("th", [_vm._v("원 단 크 기")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "size1_" + index,
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: detail.size1,
                                            callback: function ($$v) {
                                              _vm.$set(detail, "size1", $$v)
                                            },
                                            expression: "detail.size1",
                                          },
                                        }),
                                      ]
                                    : [_vm._v(_vm._s(detail.size1))],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("원 단 색 상")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "quantity2",
                                            validate: "required",
                                            options: _vm.fabricColorOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order.fabric_color,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "fabric_color",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.fabric_color\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "FabricColor",
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order.fabric_color
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("th", [_vm._v("작 업 수 량")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "amount_" + index,
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: detail.amount,
                                            callback: function ($$v) {
                                              _vm.$set(detail, "amount", $$v)
                                            },
                                            expression: "detail.amount",
                                          },
                                        }),
                                      ]
                                    : [_vm._v(_vm._s(detail.amount))],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("재 단 형 태")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "quantity3",
                                            validate: "required",
                                            options: _vm.cuttingMethodOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order.cutting_method,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "cutting_method",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.cutting_method\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "CuttingMethod",
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order
                                                  .cutting_method,
                                                "KR"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("th"),
                              _vm._v(" "),
                              _c("td"),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("포 장 내 역")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: [
                                    {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                    _vm.$style.tac,
                                  ],
                                  attrs: { colspan: "4" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "quantity4",
                                            validate: "required",
                                            options: _vm.oppPackagingOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order
                                                .packaging_contents,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "packaging_contents",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.packaging_contents\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "OppPackaging",
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order
                                                  .packaging_contents
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("특 기 사 항")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "4" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "note1",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order.description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.description\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            values.contract.contract
                                              .contract_details[index]
                                              .purchase_order.description
                                          )
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", [
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  class: _vm.$style.title,
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v("단 가 내 역")]
                              ),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  class: _vm.$style.tac,
                                  attrs: { colspan: "5" },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.calcTotalPrice(
                                          detail.purchase_amount,
                                          detail.weight2
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", [
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "12.5%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "25%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  class: _vm.$style.title,
                                  staticStyle: { background: "orange" },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  _vm._v(
                                    "\n                  그 림 참 조\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.btrf0 }),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  class: _vm.$style.h200,
                                  attrs: { colspan: "5" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getVerificationImageUrl(
                                        values.request_appendices
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }
              ),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }