<template>
  <div :class="$style.wrapper">
    <div :class="$style.noticeWrappe">
      <div :class="$style.notice">
        <p :class="$style.notice_head">お知らせ</p>
        <div>
          <div :class="$style.notice_content">
            <p :class="$style.notice_content_date">2019/10/11</p>
            <p :class="$style.notice_content_txt">
              「ご商談一覧」ページにてお取引先様全員分の商談が表示されるようになりました。注文月/担当者/ステータスで商談を絞り込むことができます。
            </p>
          </div>
        </div>
      </div>
    </div>
    <PageTitle>マイページ</PageTitle>
    <section :class="$style.index">
      <router-link to="/customer/dealings">
        <h3>
          <span>
            <IconKarter height="36" width="36" />
            <span>ご商談一覧</span>
          </span>
        </h3>
        <p>
          現在進行中の商談の一覧が確認できます。また、お見積りの確認やご注文、データの入稿もこちらから行うことができます。
        </p>
      </router-link>
      <router-link to="/customer/ordered-histories">
        <h3>
          <span>
            <IconClock height="36" width="36" />
            <span>ご注文履歴</span>
          </span>
        </h3>
        <p>
          ご注文の履歴が確認できます。また、履歴情報をもとに再注文を行うことができます｡
        </p>
      </router-link>
      <router-link to="/customer/accounts">
        <h3>
          <span>
            <IconUser height="36" width="36" />
            <span>アカウント情報</span>
          </span>
        </h3>
        <p>
          メールアドレス・パスワードの変更や、ご登録のメールアドレスに紐づく会員情報の確認・変更、メールマガジンの設定を行うことができます。
        </p>
      </router-link>
    </section>

    <div :class="$style.HPLinks">
      <h2 :class="$style.ttl">ホームページリンク</h2>
      <a href="https://www.pr39.com/contact/" target="_blank">
        <v-icon>launch</v-icon>
        <h3>お問い合わせ</h3>
        <p>お気軽にお問い合わせください。</p>
      </a>
      <a href="https://www.pr39.com/sample/" target="_blank">
        <v-icon>launch</v-icon>
        <h3>サンプル請求</h3>
        <p>紙質、色、糊位置などをご確認いただけます。</p>
      </a>
      <a href="https://www.pr39.com/printorder/" target="_blank">
        <v-icon>launch</v-icon>
        <h3>既製品見積</h3>
        <p>カバーなしふせん、バラエティふせん、その他ノベルティ等</p>
      </a>
      <a href="https://www.pr39.com/original-form/" target="_blank">
        <v-icon>launch</v-icon>
        <h3>オーダーメイド見積</h3>
        <p>
          オリジナルふせんのお見積依頼はこちらから。お見積後、商品の注文が可能となります。
        </p>
      </a>
      <a href="https://www.pr39.com/printorder/" target="_blank">
        <v-icon>launch</v-icon>
        <h3>既製品注文</h3>
        <p>カバーなしふせん、バラエティふせん、その他ノベルティ等</p>
      </a>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import IconUser from "@/components/atoms/IconUser.vue"
import IconClock from "@/components/atoms/IconClock.vue"
import IconKarter from "@/components/atoms/IconKarter.vue"

export default {
  components: {
    PageTitle,
    IconUser,
    IconClock,
    IconKarter,
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.index {
  width: 100%;
  max-width: 1020px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > a {
    width: 320px;
    padding: 20px 15px;
    margin: 10px;
    margin-bottom: 25px;
    background-color: map-get($colors, white);
    color: #343333;
    &:hover {
      opacity: 0.7;
    }
    > h3 {
      margin-bottom: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.18rem;
      border-bottom: 1px dotted #707070;
      > span {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > svg {
          margin-right: 0.5em;
        }
        > span {
          display: block;
        }
      }
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-top: 2px solid #707070;
        border-right: 2px solid #707070;
        transform: rotate(45deg);
      }
    }
  }
}
.HPLinks {
  border-radius: 5px;
  width: 100%;
  padding: 30px 10px 20px;
  margin: 10px 10px 25px 10px;
  > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  /* stylelint-disable */
  > a {
    margin: 0 auto 10px;
    width: 1000px;
    @media screen and (max-width: 1019px) {
      width: 660px;
    }
    @media screen and (max-width: 678px) {
      margin-bottom: 30px;
      width: 320px;
    }
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 15px 30px 15px 50px;

    background-color: map-get($colors, white);
    color: #343333;

    position: relative;
    @media screen and (max-width: 678px) {
      display: block;
      padding: 15px 10px;
    }
    &:hover {
      color: lighten(#343333, 20%);
    }
    > i {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      @media screen and (max-width: 678px) {
        top: 28px;
      }
    }
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid #707070;
      border-right: 2px solid #707070;
      position: absolute;
      top: calc(50% - 2px);
      right: 15px;
      transform: rotate(45deg) translateY(-50%);
      @media screen and (max-width: 678px) {
        top: 26px;
      }
    }
    > h3 {
      font-size: 0.18rem;
      border-right: 1px dotted #707070;
      padding-right: 20px;
      margin-right: 20px;
      @media screen and (max-width: 678px) {
        border-right: none;
        border-bottom: 1px dotted #707070;
        padding: 0 0 10px;
        margin: 0 0 10px;
        padding-left: 35px;
      }
    }
  }
}
.noticeWrappe {
  max-width: 1020px;
  margin: 20px auto 0;
  padding: 0 10px;
}
.notice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 0 10px;
  @media screen and (max-width: 880px) {
    display: block;
  }
  &_head {
    width: 100px;
    @media screen and (max-width: 880px) {
      font-size: 16px;
      width: auto;
      text-align: center;
    }
  }
  &_content {
    font-size: 14px;
    display: flex;
    margin: 10px 0;
    align-items: center;
    border-left: 1px solid #000;
    @media screen and (max-width: 880px) {
      border-left: none;
    }

    &_date {
      padding: 0 20px 0 20px;
      @media screen and (max-width: 880px) {
        padding: 0 10px 0 0;
      }
    }
    &_txt {
      flex-grow: 1;
    }
  }
}
</style>
