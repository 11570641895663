<template>
  <div v-if="isReady" :class="$style.wrapper">
    <h1 :class="$style.pageTtl">&nbsp;</h1>
    <div :class="$style.block">
      <h2 :class="$style.block_ttl">발송정보 입력화면</h2>
      <div :class="$style.gray">
        <p :class="$style.gray_ttl">견적/주문 내용</p>
        <!-- 見積管理番号 -->
        <div :class="$style.gray_row">
          <p :class="$style.gray_row_name">견적관리번호</p>
          <p :class="$style.gray_row_txt" v-cloak>
            {{ item.contract.request_key2 }}
          </p>
        </div>
        <!-- 発注（データ）名 -->
        <div :class="$style.gray_row">
          <p :class="$style.gray_row_name">주문 (데이터) 이름</p>
          <p :class="$style.gray_row_txt" v-cloak>
            {{ item.contract.description }}
          </p>
        </div>
        <!-- 発生日 -->
        <div :class="$style.gray_row">
          <p :class="$style.gray_row_name">발주일</p>
          <p :class="$style.gray_row_txt" v-cloak>
            {{ item.contract.request_date5 | getDate }}
          </p>
        </div>
      </div>
      <div :class="$style.block_container">
        <div :class="$style.quotation">
          <p :class="$style.quotation_name">출고정보</p>
          <!-- 出庫箱数 -->
          <div :class="$style.quotation_row">
            <PrInput
              label="출고박스수"
              :pr-row="[45]"
              name="delivery_quantity"
              v-model="item.product.quantity3"
              :validate="'required|numeric'"
              korean
            />
          </div>
          <!-- 出庫日 -->
          <div :class="$style.quotation_row">
            <PrDatePicker
              label="출고일"
              name="delivery_date"
              v-model="item.product.request_date1"
              :pr-row="[45]"
              :validate="'required'"
              korean
            />
          </div>
          <!-- 注文総数（変更不可） -->
          <div :class="$style.quotation_row">
            <PrInput
              label="주문총수"
              :pr-row="[45]"
              :value="totalOrder"
              :disabled="true"
              readonly
            />
          </div>
          <!-- 予備数 -->
          <div :class="$style.quotation_row">
            <PrInput
              label="여분수"
              :pr-row="[45]"
              name="reserve_num"
              v-model="item.product.note1"
              :validate="'required|numeric'"
              korean
            />
          </div>

          <!-- 出庫商品総数（変更不可） -->
          <div :class="$style.quotation_row">
            <PrInput
              label="출고상품총개수"
              :pr-row="[45]"
              :value="totalGoodsIssues"
              readonly
              :disabled="true"
            />
          </div>
          <!-- 備考 -->
          <p :class="$style.quotation_remarks">※자동계산</p>
          <div :class="$style.quotation_row">
            <PrTextarea
              label="비고"
              :pr-row="[45]"
              v-model="item.product.note2"
            />
          </div>
        </div>
        <Button
          color="submit"
          size="main"
          @click="handleRegist"
          style="margin:0 auto;display:block"
          >등록하기</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import moment from "moment"
import PrInput from "@/components/molecules/PrInput.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"

import { ja } from "vuejs-datepicker/dist/locale"
import { routeReplaceError, getDate, showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    Button,
    PrInput,
    PrDatePicker,
    PrTextarea,
  },
  data() {
    return {
      isReady: false,
      inputText: "",
      id: null,
      item: null,
      picker: {
        format: "yyyy/MM/dd",
        lang: ja,
      },
    }
  },
  async created() {
    this.id = this.$route.params.deal_id

    const { ok, body } = await api.get(
      `${paths.SUPPLIER_GOODS_ISSUE}/${this.id}`,
      this.$store.state.auth.token,
    )

    if (!ok) {
      console.log(`get SUPPLIER_GOODS_ISSUE ${body}`)
      routeReplaceError(this, body.slice(-3))
      return
    }

    // 未入力でない場合
    if (body.delivery_status != 1) {
      routeReplaceError(this, "900")
      return
    }

    this.item = body
    this.isReady = true
  },
  filters: {
    getDate(dateStr) {
      return getDate(dateStr, "미 설정")
    },
  },
  computed: {
    // 注文総数
    totalOrder() {
      const val = this.item.product.quantity1 + this.item.product.quantity2

      return isNaN(val) ? "" : val
    },
    // 出庫商品総数
    totalGoodsIssues() {
      const note1 = Number(this.item.product.note1)
      const val = this.totalOrder + note1

      return isNaN(val) ? "" : val
    },
  },
  methods: {
    async handleRegist() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      if (!result) {
        return
      }

      const item = this.item

      // 出庫日の日付フォーマットを変換
      let deliveryDate = null
      if (item.product.request_date1) {
        deliveryDate = moment(item.product.request_date1)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      const data = {
        request_key1: item.request_key1,
        request_number: item.request_number,
        product: {
          lock_version: item.product.lock_version,
          request_key1: item.product.request_key1,
          request_number: item.product.request_number,
          quantity3: item.product.quantity3, // 出庫箱数
          request_date1: deliveryDate, // 出庫日
          quantity1: item.product.quantity1, // 注文総数
          quantity2: item.product.quantity2,
          note1: item.product.note1, // 予備数
          quantity4: this.totalGoodsIssues, // 出庫商品総数
          note2: item.product.note2, // 備考
        },
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.SUPPLIER_GOODS_ISSUE}/${this.id}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          console.log(`put SUPPLIER_GOODS_ISSUE ${body}`)

          showErrorToast(
            this,
            body.slice(-3),
            errorMsg,
            "등록에 실패했습니다",
            "KR",
          )
          return
        }

        this.$router.push({ path: "/supplier" })
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include clientPage();
.wrapper {
  max-width: 750px;
}

.gray {
  margin: 30px 10px 0;
  padding: 15px;
  background-color: map-get($colors, lightlightGray);
  &_ttl {
    margin-bottom: 20px;
    font-size: 0.18rem;
    font-weight: bold;
  }
  &_row {
    margin-bottom: 10px;
    display: flex;
    &_name {
      width: 45%;
    }
  }
}

.quotation {
  width: 100%;
  margin-bottom: 20px;
  &_row {
    margin-bottom: 10px;
    input:disabled {
      background-color: #eee;
    }
  }
  &_name {
    margin-bottom: 20px;
    font-size: 0.18rem;
    font-weight: bold;
  }
  &_remarks {
    margin: -5px 0 10px;
    text-align: right;
    color: map-get($colors, red);
    font-size: 0.12rem;
  }
}
</style>
