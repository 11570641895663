const getVerificationImage = deal =>
  deal.request_appendices.find(
    ({ appendix_category }) => appendix_category === "verification_image",
  ).appendix_name

const checkStatus = (deal, ...statuses) => statuses.includes(deal.status)

const checkFlags = deal => {
  let flags = []
  if (deal.is_draft === 1) {
    flags.push({ name: "draft", text: "入稿待" })
  }
  if (deal.is_data_check === 1) {
    flags.push({ name: "data_check", text: "データ確認中" })
  }
  if (!getVerificationImage(deal)) {
    flags.push({ name: "noImage", text: "画像未入力" })
  }
  if (deal.is_proofreading === 1) {
    flags.push({ name: "proofreading", text: "色校正中" })
  }
  if (deal.is_cod === 1) {
    flags.push({ name: "cod", text: "代引き" })
  }
  if (deal.is_separate === 1) {
    flags.push({ name: "separate", text: "分納" })
  }
  if (deal.is_invoice === 1) {
    flags.push({ name: "invoice", text: "納品書" })
  }
  if (deal.is_defective === 1) {
    flags.push({ name: "defective", text: "不良対応中" })
  }
  if (deal.is_time_service === 1) {
    flags.push({ name: "time_service", text: "タイムサービス" })
  }
  if (deal.is_internal_job === 1) {
    flags.push({ name: "internal_job", text: "内職" })
  }
  if (deal.is_note === 1) {
    flags.push({ name: "note", text: "備考" })
  }
  return flags
}

const setValues = deal => {
  const {
    note1,
    id,
    request_number,
    request_key1,
    lock_version,
    product,
    delivery,
  } = deal
  return {
    note1: note1, //備考
    id: id,
    request_number: request_number,
    request_key1: request_key1,
    lock_version: lock_version,
    product: {
      quantity3: product.quantity3, //出庫箱数
      request_date2: product.request_date2, //港受取日
      quantity4: product.quantity4, //出庫商品総数
      quantity6: product.quantity6, //保管商品個数
      quantity5: product.quantity5, //発送箱数
      request_key1: product.request_key1,
      lock_version: product.lock_version,
      request_number: product.request_number,
      note2: product.note2,
    },
    delivery: {
      request_date2: delivery.request_date2, //発送予定日
      request_date3: delivery.request_date3, //お届け予定日
      request_key1: delivery.request_key1,
      lock_version: delivery.lock_version,
      request_number: delivery.request_number,
    },
  }
}

// TODO
const newValuesStore = () => {
  let deal = null
  return {
    setValue() {
      const {
        id,
        request_number,
        request_key1,
        lock_version,
        product,
        delivery,
      } = deal
      return {
        id: id,
        request_number: request_number,
        request_key1: request_key1,
        lock_version: lock_version,
        product: {
          quantity3: product.quantity3, //出庫箱数
          request_date2: product.request_date2, //港受取日
          quantity4: product.quantity4, //出庫商品総数
          quantity6: product.quantity6, //保管商品個数
          quantity5: product.quantity5, //発送箱数
          note2: product.note2, //備考
          request_key1: product.request_key1,
          lock_version: product.lock_version,
          request_number: product.request_number,
        },
        delivery: {
          request_date2: delivery.request_date2, //発送予定日
          request_date3: delivery.request_date3, //お届け予定日
          request_key1: delivery.request_key1,
          lock_version: delivery.lock_version,
          request_number: delivery.request_number,
        },
      }
    },
    getDeal(d) {
      deal = d
    },
  }
}

const newDragHandler = (updateStatus, dragover) => {
  let counter = 0
  let draggedDeal = null
  return {
    goodsIssue: {
      dragstart(deal) {
        console.log("どらっぐすたーと", deal)
        draggedDeal = deal
      },
      dragend() {
        console.log("どらっぐえんど")
        draggedDeal = null
      },
    },
    Inspect: {
      dragenter(e) {
        if (counter === 0) {
          e.currentTarget.classList.add(dragover)
        }
        counter++
      },
      dragover(e) {
        e.preventDefault()
      },
      dragleave(e) {
        counter--
        if (counter === 0) {
          e.currentTarget.classList.remove(dragover)
        }
      },
      drop(e) {
        console.log("どろっぷ", draggedDeal)
        counter = 0
        e.currentTarget.classList.remove(dragover)
        updateStatus(draggedDeal)
      },
    },
  }
}

export {
  getVerificationImage,
  checkStatus,
  checkFlags,
  setValues,
  newDragHandler,
  newValuesStore,
}
