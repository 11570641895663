var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.wrapper },
    [
      _c("PageTitle", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("CustomerUserName", {
        attrs: { "customer-person": _vm.items.customer_person },
      }),
      _vm._v(" "),
      _c(
        "InfoBlock",
        { attrs: { block: "", width: "320px", margin: "0", center: "" } },
        [
          _c("template", { slot: "head" }, [_vm._v("\n      商品概要\n    ")]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.inner }, [
            _c("h3", {}, [_vm._v("見積NO." + _vm._s(_vm.detail.no))]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.detail.data, function (item, index) {
            return _c(
              "div",
              { key: index, class: _vm.$style.inner },
              [
                item.name.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.name.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.name.value) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.size1.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.size1.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.size1.value) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.category3.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.category3.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.category3.value) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.size2.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.size2.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.size2.value) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.category4.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.category4.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.category4.value) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.color.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.color.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.color.value) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.size3.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.size3.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.size3.value) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.amount.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.amount.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("formatDecimalAmount")(item.amount.value)
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.price.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.price.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("formatDecimalPrice")(item.price.value)
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                item.total.visible
                  ? [
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.total.label) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("formatDecimalPrice")(item.total.value)
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { class: _vm.$style.quotation_detail_divider }),
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm.detail.options.length !== 0
            ? _c(
                "div",
                { class: _vm.$style.inner },
                _vm._l(_vm.detail.options, function (option, index) {
                  return _c(
                    "div",
                    { key: `option-${index}`, class: _vm.$style.priceOptions },
                    [
                      _c("p", {}, [_vm._v(_vm._s(option.name))]),
                      _vm._v(" "),
                      _c("p", {}, [
                        _vm._v(_vm._s(_vm._f("formatPrice")(option.price))),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { class: _vm.$style.inner }, [
            _c("p", [_vm._v("見積総額(税別)")]),
            _vm._v(" "),
            _c("p", {}, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("formatPrice")(
                      +_vm.detail.total_amount + +_vm.detail.discount
                    )
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("消費税")]),
            _vm._v(" "),
            _c("p", {}, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("formatPrice")(_vm.detail.tax_amount)) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("値引き")]),
            _vm._v(" "),
            _c("p", {}, [
              _vm._v("-" + _vm._s(_vm._f("formatPrice")(_vm.detail.discount))),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("見積総額(税込)")]),
            _vm._v(" "),
            _c("p", {}, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("formatPrice")(
                      Number(_vm.detail.total_amount) +
                        Number(_vm.detail.tax_amount)
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "InfoBlock",
        { attrs: { block: "", width: "320px", margin: "0", center: "" } },
        [
          _c("template", { slot: "head" }, [
            _vm._v("\n      お客様（注文者）ご住所\n    "),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.inner }, [
            _c("p", [_vm._v("郵便番号")]),
            _vm._v(" "),
            _c("p", {}, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.orderInfo.customerAddr.zip) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("住所")]),
            _vm._v(" "),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.customerAddr) } }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "InfoBlock",
        { attrs: { block: "", width: "320px", margin: "0", center: "" } },
        [
          _c("template", { slot: "head" }, [
            _vm._v("\n      入金・発送・納品情報\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.inner },
            [
              _c("p", [_vm._v("お支払い方法")]),
              _vm._v(" "),
              _c("p", {}, [_vm._v(_vm._s(_vm.payment))]),
              _vm._v(" "),
              _c("p", [_vm._v("入金予定日")]),
              _vm._v(" "),
              _c("p", {}, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("getDate")(_vm.orderInfo.scheduleDate, "指定なし")
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("納品希望日")]),
              _vm._v(" "),
              _c("p", {}, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("getDate")(_vm.orderInfo.prefDate, "指定なし")
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("受取可能日")]),
              _vm._v(" "),
              _c("p", {}, [_vm._v(_vm._s(_vm.receiveDates || "指定なし"))]),
              _vm._v(" "),
              _c("h3", {}, [_vm._v("お届け先情報")]),
              _vm._v(" "),
              _vm.orderInfo.destination == 0
                ? [
                    _c("p", [_vm._v("商品お届け先")]),
                    _vm._v(" "),
                    _c("p", {}, [_vm._v(_vm._s(_vm.destination))]),
                  ]
                : [
                    _c("p", [_vm._v("商品お届け先")]),
                    _vm._v(" "),
                    _c("p", {}, [_vm._v(_vm._s(_vm.destination))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("会社名・団体名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.destAddr.company) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("部署名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.destAddr.division) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("お届け先名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.destAddr.name) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("郵便番号")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.destAddr.zip) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("住所")]),
                    _vm._v(" "),
                    _c("p", { domProps: { innerHTML: _vm._s(_vm.destAddr) } }),
                    _vm._v(" "),
                    _c("p", [_vm._v("電話番号")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.destAddr.tel) +
                          "\n        "
                      ),
                    ]),
                  ],
              _vm._v(" "),
              _c("h3", {}, [_vm._v("送り主情報")]),
              _vm._v(" "),
              _vm.orderInfo.isSenderChange == 0
                ? [
                    _c("p", [_vm._v("送り主変更オプション")]),
                    _vm._v(" "),
                    _c("p", {}, [_vm._v(_vm._s(_vm.senderChange))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("送り主名")]),
                    _vm._v(" "),
                    _c("p", {}, [_vm._v("PR39")]),
                  ]
                : _vm.orderInfo.isSenderChange == 1 && _vm.orderInfo.sender == 1
                ? [
                    _c("p", [_vm._v("送り主変更オプション")]),
                    _vm._v(" "),
                    _c("p", {}, [_vm._v(_vm._s(_vm.senderChange))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("会社名・団体名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.senderAddr.company) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("部署名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.senderAddr.division) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("送り主名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.senderAddr.name) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("郵便番号")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.senderAddr.zip) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("住所")]),
                    _vm._v(" "),
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.senderAddr) },
                    }),
                    _vm._v(" "),
                    _c("p", [_vm._v("電話番号")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.senderAddr.tel) +
                          "\n        "
                      ),
                    ]),
                  ]
                : [
                    _c("p", [_vm._v("送り主変更オプション")]),
                    _vm._v(" "),
                    _c("p", {}, [_vm._v(_vm._s(_vm.senderChange))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("会社名・団体名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.customerAddr.company) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("部署名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.customerAddr.division) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("送り主名")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.customerAddr.name) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("郵便番号")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.customerAddr.zip) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("住所")]),
                    _vm._v(" "),
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.customerAddr) },
                    }),
                    _vm._v(" "),
                    _c("p", [_vm._v("電話番号")]),
                    _vm._v(" "),
                    _c("p", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderInfo.customerAddr.tel) +
                          "\n        "
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "InfoBlock",
        { attrs: { block: "", width: "320px", margin: "0", center: "" } },
        [
          _c("template", { slot: "head" }, [_vm._v("\n      その他\n    ")]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.inner }, [
            _c("p", [_vm._v("備考")]),
            _vm._v(" "),
            _c("p", { staticStyle: { "white-space": "pre-wrap" } }, [
              _vm._v(_vm._s(_vm.orderInfo.remarks)),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "section",
        { class: _vm.$style.button },
        [
          _c(
            "Button",
            {
              attrs: { color: "submit", size: "main" },
              on: { click: _vm.handleConfirmed },
            },
            [_vm._v("注文を確定する")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }