var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { class: _vm.$style.checkbox }, [
    _vm.disabled !== undefined
      ? _c("input", {
          attrs: { disabled: "", type: "checkbox" },
          domProps: { checked: _vm.value == 1 ? true : false },
        })
      : _vm.NotSync !== undefined
      ? _c("input", {
          attrs: { disabled: "", type: "checkbox" },
          domProps: { checked: _vm.value == "1" ? true : false },
        })
      : _c("input", {
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.value == "1" ? true : false },
          on: { input: _vm.updateValue },
        }),
    _vm._v(" "),
    _vm.NotSync !== undefined
      ? _c("span", {
          class: { [_vm.$style.disabled]: _vm.disabled !== undefined },
          on: { click: _vm.notUpdateValue },
        })
      : _c("span", {
          class: { [_vm.$style.disabled]: _vm.disabled !== undefined },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }