var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "350px", margin: "0", block: "" } },
    [
      _c("template", { slot: "head" }, [_vm._v("不良品詳細")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "head-right" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                icon: "",
                color: "indigo",
                to: `/admin/dealings/${_vm.items.request_number}/defectives/new`,
              },
            },
            [_c("v-icon", [_vm._v("add_circle_outline")])],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.items.defect.length !== 0
        ? _c(
            "ul",
            { class: _vm.$style.wrapper },
            _vm._l(_vm.items.defect, function (defect) {
              return _c(
                "li",
                { key: defect.id },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: `/admin/dealings/${_vm.items.request_number}/defectives/${defect.request_key2}`,
                      },
                    },
                    [_vm._v(_vm._s(defect.request_key2))]
                  ),
                  _vm._v(" "),
                  _c("TheSelect", {
                    attrs: {
                      value: defect.status,
                      options:
                        _vm.$store.getters["defs/getOptions"](
                          "DefectiveStatus"
                        ),
                    },
                    on: {
                      input: (status) =>
                        _vm.handleStatusChnge(status, defect.request_key2),
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c("p", { class: _vm.$style.noitem }, [_vm._v("不良品はありません")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }