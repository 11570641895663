<template>
  <div v-if="values !== null" :class="$style.wrapper">
    <DealingHeader />
    <PageTitle>入金・商品配送情報</PageTitle>
    <main :class="$style.container">
      <AdminDealingDeliveryProductInfo v-model="values" />
      <AdminDealingDeliveryDeliveryInfo v-model="values" :deal="items" />
      <AdminDealingDeliveryAddressInfo
        v-model="values.shipping"
        whom="sender"
        head="送り主情報"
        :deal="items"
        :customer-persons="customerPersons"
        :customerPersonOptions="customerPersonOptions.sender"
      />
      <AdminDealingDeliveryAddressInfo
        v-model="values.shipping"
        whom="delivery"
        head="配送先情報"
        :deal="items"
        :customer-persons="customerPersons"
        :customerPersonOptions="customerPersonOptions.delivery"
      />

      <AdminDealingDeliveryPaymentInfo
        v-model="values.contract"
        :contracts="items.contract"
      />
    </main>
    <div :class="$style.buttons">
      <DialogProvider
        :texts="{
          ttl: '確認',
          msg: `お客様に入金確認完了のメールが送信されます。`,
          yas: 'OK',
          no: 'キャンセル',
        }"
        @accept="handleSaveButtonClick"
      >
        <template slot-scope="{ on }">
          <Button
            size="main"
            color="submit"
            @click="handleSaveButtonClick(on, $event)"
            >保存</Button
          >
        </template>
      </DialogProvider>
      <!-- <DialogProvider>
           <Button size="main" color="submit" @click="handleSaveButtonClick"
           >保存</Button
           >
	   </DialogProvider> -->
      <Button size="assist" color="cancel" @click="initValues">クリア</Button>
    </div>
  </div>
</template>

<script>
import DealingHeader from "@/components/molecules/DealingHeader.vue"
import AdminDealingDeliveryProductInfo from "@/components/molecules/AdminDealingDeliveryProductInfo.vue"
import AdminDealingDeliveryDeliveryInfo from "@/components/molecules/AdminDealingDeliveryDeliveryInfo.vue"
import AdminDealingDeliveryAddressInfo from "@/components/molecules/AdminDealingDeliveryAddressInfo.vue"
import AdminDealingDeliveryPaymentInfo from "@/components/molecules/AdminDealingDeliveryPaymentInfo.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"
import { setValues } from "@/helpers/AdminDealingDeliveryHelper.js"

export default {
  components: {
    DealingHeader,
    AdminDealingDeliveryProductInfo,
    AdminDealingDeliveryDeliveryInfo,
    AdminDealingDeliveryAddressInfo,
    AdminDealingDeliveryPaymentInfo,
    PageTitle,
    Button,
    DialogProvider,
  },
  data() {
    return {
      items: null,
      values: null,
      customerPersons: null,
    }
  },
  computed: {
    customerPersonOptions() {
      const { items, customerPersons } = this
      const cpos = customerPersons
        .filter(({ customer_id }) => customer_id === items.customer.id)
        .map(({ id, last_name, first_name }) => ({
          key: id,
          value: `${last_name} ${first_name}`,
        }))
      return { sender: [{ key: 0, value: "PR39" }, ...cpos], delivery: cpos }
    },
  },
  methods: {
    async handleSaveButtonClick(on, e) {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      if (!result) {
        return
      }

      if (
        !!this.values.is_send_mail &&
        this.values.contract.request_date4 !== null &&
        !!on
      ) {
        // this.values.is_send_mail =
        //   this.values.contract.request_date4 == null ? "0" : "1"
        this.values.is_send_mail = "1"
        on.click(e)
        return
      }

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.put(
          `admin/dealings-payment/${this.items.request_number}`,
          JSON.parse(JSON.stringify(this.values)),
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.items = body
        this.initValues()
      } finally {
        loader.hide()
      }
    },
    initValues() {
      this.$validator.reset()
      this.values = setValues(this.items)
      if (this.values.contract.request_date4 == null) {
        this.values.is_send_mail = "0"
      }
    },
  },
  async created() {
    const [
      { ok: dOk, body: dBody },
      { ok: pOk, body: pBody },
    ] = await Promise.all([
      api.get(
        `admin/dealings/${this.$route.params.deal_id}`,
        this.$store.state.auth.token,
      ),
      api.get(
        paths.ADMIN_CUSTOMERS_AND_CUSTOMER_PERSONS,
        this.$store.state.auth.token,
      ),
    ])

    if (!dOk) {
      routeReplaceError(this, dBody.slice(-3))
      return
    }

    if (!pOk) {
      routeReplaceError(this, pBody.slice(-3))
      return
    }

    this.customerPersons = pBody.customer_persons
    this.items = dBody
    this.initValues()
  },
}
</script>

<style lang="scss" module>
.wrapper {
  padding-top: 30px;
  width: 1400px;
  margin: 0 auto;
}
/* autoprefixer: off */
.container {
  display: grid;
  grid-template-columns: 500px 500px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
}

.row {
  padding: 10px 20px;
  width: 100%;
  &:nth-of-type(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  > div[name^="pr"] {
    width: 100%;
  }
}
.buttons {
  margin: 30px 0;
  text-align: center;
}
</style>
