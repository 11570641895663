<template>
  <div :class="$style.wrapper">
    <PageTitle>{{ tilte }}</PageTitle>
    <router-view :styles="childStyles" />
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"

const titles = {
  userLogin: "ログイン画面",
  adminUserIndex: "PR39ユーザー管理",
  adminUserNew: "PR39ユーザー新規登録",
  adminUserDetail: "PR39ユーザー編集",
}

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      title: "",
    }
  },
  computed: {
    childStyles() {
      const { formAria, form, desc, inner } = this.$style
      return { formAria, form, desc, inner }
    },
  },
  created() {
    this.tilte = titles[this.$router.history.current.name]
  },
  watch: {
    $route(to) {
      this.tilte = titles[to.name]
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  padding: 0 10px;
  min-height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  padding: 55px 0;
}

.inner {
  max-width: 800px;
  width: 100%;
}

.formAria {
  max-width: 100%;
  margin: 0 auto;
  padding: 90px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  & > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  > div[name^="pr-text"],
  > div[name^="pr-select"] {
    width: 465px;
    > span[name="label"] {
      font-size: 0.16rem;
      font-weight: bold;
      color: map-get($colors, darkGlay);
      margin-bottom: 10px;
    }
    input {
      font-size: 0.18rem;
    }
  }
  @include media(sp) {
    width: auto;
    padding: 45px 10px;
  }
}

.form {
  width: 100%;
  max-width: 465px;
}

.desc {
  font-size: 0.18rem;
  color: #707070;
}
</style>
