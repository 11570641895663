<template>
  <OrdersheetProvider>
    <template slot-scope="{ values, onChanged, isSupplier, isEdit }">
      <div :class="$style.wrapper">
        <div
          :class="$style.container"
          v-for="(detail, index) in values.contract.contract.contract_details"
          :key="index"
        >
          <section :class="$style.section1">
            <table>
              <colgroup>
                <col style="width: 10%;" />
                <col style="width: 18%;" />
                <col style="width: 18%;" />
                <col style="width: 18%;" />
                <col style="width: 18%;" />
                <col style="width: 18%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th colspan="6">발 주 서</th>
                </tr>
                <tr>
                  <td>수 신</td>
                  <td :class="$style.tal">아크로 엠엔비</td>
                  <td>사업자등록번호</td>
                  <td colspan="3"></td>
                </tr>
                <tr>
                  <td>일 자</td>
                  <td :class="$style.tal"></td>
                  <td>상호</td>
                  <td>pr39</td>
                  <td>대표</td>
                  <td>임과창</td>
                </tr>
                <tr>
                  <td>전 화</td>
                  <td :class="$style.tal">02-2272-5012</td>
                  <td>사 업 장 주소</td>
                  <td colspan="3">
                    후쿠오카현 후쿠오카시 하카타쿠 타케시타1-11-23
                  </td>
                </tr>
                <tr>
                  <td>팩스</td>
                  <td :class="$style.tal">02-2272-1006</td>
                  <td>업태</td>
                  <td></td>
                  <td>종목</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td>전화</td>
                  <td>092-409-6039</td>
                  <td>팩스</td>
                  <td>092-409-6097</td>
                </tr>
                <tr>
                  <td colspan="2" rowspan="2"></td>
                  <td>담당자</td>
                  <td>{{ values.user ? values.user.name : "미 설정" }}</td>
                  <td>담당전화(휴대폰)</td>
                  <td>070-8234-2510</td>
                </tr>
                <tr>
                  <td>세금계산서 <br />(E-maill주소)</td>
                  <td colspan="3">
                    <a href="mailto:info@pr39.com">info@pr39.com</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>- 아래와 같이 포스트잇을 발주 드립니다.</p>
          </section>

          <!-- section2 -->
          <section :class="$style.section2">
            <table>
              <colgroup>
                <col style="width: 16%;" />
                <col style="width: 26%;" />
                <col style="width: 16%;" />
                <col style="width: 16%;" />
                <col style="width: 26%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th>1. 발주 내용 :</th>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrSelect
                        :name="'category1_' + index"
                        v-model="detail.category1"
                        :validate="'required'"
                        @input="onChanged"
                        :options="productFamilyOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString("ProductFamily", detail.category1, "KR")
                      }}
                    </template>
                  </td>
                  <th>6.포장</th>
                  <td>OPP개별포장</td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrSelect
                        name="quantity6"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.packaging_individual
                        "
                        :validate="'required'"
                        @input="onChanged"
                        :options="oppPackagingOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString(
                          "OppPackaging",
                          detail.purchase_order.packaging_individual,
                        )
                      }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>2. 발주 일자 :</th>
                  <td
                    :class="[
                      { [$style.inputAbailable]: !isSupplier },
                      $style.tac,
                    ]"
                  >
                    <template v-if="isEdit">
                      <PrDatePicker
                        name="request_date5"
                        v-model="values.contract.request_date5"
                        :validate="'required'"
                        @input="onChanged"
                        :pr-row="[0, 100]"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{ values.contract.request_date5 | getDate }}
                    </template>
                  </td>
                  <th></th>
                  <td>박스포장</td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrSelect
                        name="quantity5"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.packaging_bundle
                        "
                        :validate="'required'"
                        @input="onChanged"
                        :options="bundlePackagingOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString(
                          "BundlePackaging",
                          detail.purchase_order.packaging_bundle,
                          "KR",
                        )
                      }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>3. 견적 세부 사항</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="4"
                  >
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        name="note1"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.description
                        "
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      detail.purchase_order.description
                    }}</template>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <!-- section3 -->
          <section :class="$style.section3">
            <p>(단위:원, VAT별도)</p>
            <table>
              <colgroup>
                <col style="width: 10%;" />
                <col style="width: 16%;" />
                <col style="width: 10%;" />
                <col style="width: 16%;" />
                <col style="width: 16%;" />
                <col style="width: 16%;" />
                <col style="width: 16%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th>번 호</th>
                  <th>품 명</th>
                  <th>매수(장)</th>
                  <th>주문수량 (부)</th>
                  <th>공급단가 (원)</th>
                  <th>합계</th>
                  <th>계산서 발행여부</th>
                </tr>
                <tr>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        name="request_name"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.data_name
                        "
                        :validate="'required|numeric'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      detail.purchase_order.data_name
                    }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        name="description"
                        v-model="values.contract.description"
                        :validate="'required'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      values.contract.description
                    }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'size3_' + index"
                        v-model="detail.size3"
                        :validate="'required|numeric'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.size3 }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'amount_' + index"
                        v-model="detail.amount"
                        :validate="'required|numeric'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.amount }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'purchase_amount_' + index"
                        v-model="detail.purchase_amount"
                        :validate="'required|numeric'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      detail.purchase_amount
                    }}</template>
                  </td>
                  <td>
                    {{ calcPrice(detail.amount, detail.purchase_amount) }}
                  </td>
                  <td>유, 무</td>
                </tr>
              </tbody>
            </table>
          </section>

          <!-- section4 -->
          <section :class="$style.section4">
            <p>4. 납품처</p>
            <div :class="$style.delivery">
              <table>
                <colgroup>
                  <col style="width: 20%;" />
                  <col style="width: 30%;" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>보내는곳</th>
                    <td>아크로 엠엔비</td>
                  </tr>
                  <tr>
                    <th>받는곳</th>
                    <td>
                      부산시동구초량동충장대로206 <br />국제여객터미널 탁송화물
                      검사장
                    </td>
                  </tr>
                  <tr>
                    <th>받는사람</th>
                    <td>김광애</td>
                  </tr>
                  <tr>
                    <th>받는곳전화 <br />(휴대폰)</th>
                    <td>011-835-8045</td>
                  </tr>
                  <tr>
                    <th>납품일 <br />(희망일)</th>
                    <td :class="{ [$style.inputAbailable]: !isSupplier }">
                      <template v-if="isEdit">
                        <PrDatePicker
                          name="request_date1"
                          v-model="values.product.request_date1"
                          :validate="'required'"
                          @input="onChanged"
                          :pr-row="[0, 100]"
                        />
                      </template>
                      <template v-else v-cloak>
                        {{ values.product.request_date1 | getDate }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th>배송비</th>
                    <td>선불</td>
                  </tr>
                </tbody>
              </table>
              <div :class="$style.imgArea">
                <img
                  :src="getVerificationImageUrl(values.request_appendices)"
                />
              </div>
            </div>
          </section>

          <!-- section5 -->
          <section :class="$style.section5">
            <p>5. 비 고</p>
            <p>― 세부사항</p>
            <h2>상기와 같이 발주서를 제출합니다. 끝.</h2>
          </section>
        </div>
      </div>
    </template>
  </OrdersheetProvider>
</template>

<script>
import OrdersheetProvider from "@/components/molecules/OrdersheetProvider.vue"
import { getDate } from "@/utils/shared.js"

import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"

export default {
  components: {
    OrdersheetProvider,
    PrDatePicker,
    PrInput,
    PrSelect,
  },
  filters: {
    getDate(dateStr) {
      return getDate(dateStr, "미 설정")
    },
  },
  computed: {
    productFamilyOptions() {
      return this.$store.getters["defs/getOptions"]("ProductFamily", "KR")
    },
    oppPackagingOptions() {
      return this.$store.getters["defs/getOptions"]("OppPackaging")
    },
    bundlePackagingOptions() {
      const bundlePackagingOptions = this.$store.getters["defs/getOptions"](
        "BundlePackaging",
        "KR",
      )

      return [
        {
          key: "-",
          value: "-",
        },
        ...bundlePackagingOptions,
      ]
    },
  },
  methods: {
    toDefsString(category, key, lang = null) {
      const str = this.$store.getters["defs/getOptionValueByKey"](
        category,
        key,
        lang,
      )
      return str
    },
    calcPrice(amount, purchase_amount) {
      const price = amount * purchase_amount
      if (isNaN(price)) {
        return ""
      }
      return price
    },
    getVerificationImageUrl(request_appendices) {
      const image = request_appendices.find(
        ({ appendix_category }) => appendix_category === "verification_image",
      )

      return image.appendix_name
    },
  },
}
</script>

<style lang="scss" module>
.inputAbailable {
  background-color: #fde9d9;
}

.wrapper {
  .container {
    table {
      table-layout: fixed;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
    padding: 60px 30px;
    background-color: #fff;
    max-width: 900px;
    margin: 50px auto;
    section {
      margin-bottom: 40px;
      display: block;
      table {
        border: 2px solid #000;
        border-collapse: collapse;
        width: 100%;
        input,
        select {
          width: 100%;
        }

        td,
        th {
          padding: 8px;
          height: 25px;
          box-sizing: border-box;
          border: 1px solid #595959;
        }
        .title {
          background-color: #bbd090 !important;
        }
        .highlight {
          background-color: #d48b89 !important;
        }
        .btrf0 {
          border-top: 0;
          border-right: 0;
          border-left: 0;
        }
      }
    }
    .section1 {
      table {
        th {
          border: 2px solid #000;
          font-size: 1.5em;
        }
        td {
          text-align: center;
          &.tal {
            text-align: left;
          }
          a {
            color: #0000ff;
            text-decoration: underline;
          }
        }
      }
    }
    .section2 {
      table {
        border: 0;
        td,
        th {
          border: 0;
          text-align: left;
          font-weight: normal;
        }
      }
    }
    .section3 {
      p {
        text-align: right;
        font-weight: bold;
      }
      table {
        td,
        th {
          text-align: center;
          font-weight: normal;
        }
        td {
          padding: 13px;
          &:nth-last-child(-n + 2) {
            font-weight: bold;
          }
        }
        input {
          width: 100%;
        }
      }
    }
    .section4 {
      p {
        text-align: left;
        font-weight: bold;
      }

      .delivery {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > * {
          width: 50%;
        }
        table {
          td,
          th {
            text-align: center;
            font-weight: normal;
          }
          th {
            background-color: #f0f0f0;
          }
          td {
            padding: 13px;
          }
        }
        .imgArea {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 90%;
            max-height: 90%;
          }
        }
      }
    }
    .section5 {
      h2 {
        text-align: center;
        margin: 20px 0 0;
        font-size: 20px;
      }
      p {
        text-align: left;
      }
    }
    th.tac,
    td.tac {
      text-align: center !important;
    }
  }
}

@media print {
  @page {
    size: A4;
    margin: 0mm 10mm;
  }
  .inputAbailable {
    background-color: transparent !important;
  }
  body {
    height: auto;
    display: block !important;
    .wrapper {
      .container {
        margin: 0 auto !important;
        top: 0 !important;
        left: 0 !important;
        width: 95% !important;
        background-color: #fff;
        position: relative;
        font-size: 10.5pt;
        color: #000000;
        -webkit-print-color-adjust: exact;
        zoom: 0.9 !important;
        &:not(:last-child) {
          page-break-after: always;
        }
        section {
          margin-bottom: 25px;
          display: block;
          td,
          th {
            height: 12px !important;
            padding: 5px !important;
          }

          img {
            width: 50%;
            max-height: 300px;
          }
        }
      }
    }
  }
}
</style>
