<template>
  <div v-if="isReady" :class="$style.rootWrapper">
    <PageTitle>견적 입력화면</PageTitle>

    <InfoBlock
      v-for="(detail, index1) in items.contract"
      :key="index1"
      block
      margin="0 auto 30px"
      center
      :class="$style.infoBlock"
    >
      <template slot="head">
        <!-- 管理番号 -->
        관리 번호 {{ detail.contract.contract_no }}
      </template>
      <div :class="$style.contracts">
        <div
          v-for="(item, index2) in detail.contract.contract_details"
          :key="index2"
          :class="$style.inner"
        >
          <!-- 商品ファミリ -->
          <p>상품분류</p>
          <p v-cloak>{{ getProductFamily(item.category1) }}</p>
          <!-- カバーサイズ -->
          <PrInput
            label="커버 사이즈"
            :pr-row="[45, 55]"
            v-model="item.size1"
          />

          <!-- カバー型抜き -->
          <p>커버 도무송</p>
          <p v-cloak>{{ getCuttings(item.category3) }}</p>
          <!-- 付箋サイズ -->
          <PrInput
            label="내지 사이즈"
            :pr-row="[45, 55]"
            :name="'size2_' + index1 + index2"
            v-model="item.size2"
            :validate="'required'"
            korean
          />

          <!-- 付箋型抜き -->
          <p>내지 도무송</p>
          <p v-cloak>{{ getCuttings(item.category4) }}</p>
          <!-- 付箋印刷 -->
          <PrSelect
            :name="'color_' + index1 + index2"
            v-model="item.color"
            :options="$store.getters['defs/getOptions']('TagPrint', 'KR')"
            label="인쇄"
            :pr-row="[45, 55]"
            :validate="'required'"
            korean
          />

          <!-- 付箋枚数 -->
          <PrInput
            label="매수"
            :pr-row="[45, 55]"
            :name="'size3' + index1 + index2"
            v-model="item.size3"
            :validate="'required|numeric'"
            korean
          />
          <!-- 個数 -->
          <PrInput
            label="개수"
            :pr-row="[45, 55]"
            :name="'amount' + index1 + index2"
            v-model="item.amount"
            :validate="'required|numeric'"
            korean
          />
          <!-- その他 -->
          <PrTextarea
            v-model="item.description"
            label="기타"
            :pr-row="[45, 55]"
          />

          <!-- 仕入額 -->
          <PrInput
            label="단가"
            :pr-row="[45, 55]"
            :name="'purchase_amount_' + index1 + index2"
            v-model="item.purchase_amount"
            :validate="'required|numeric'"
            korean
          />
          <a
            v-if="item.image_url"
            :href="item.image_url"
            target="_blank"
            :class="$style.img"
          >
            <img :src="item.image_url" />
          </a>
        </div>
      </div>
    </InfoBlock>
    <Button
      color="submit"
      size="main"
      @click="handleRegist"
      style="margin:0 auto;display:block"
      >등록하기</Button
    >
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    PageTitle,
    PrInput,
    PrSelect,
    Button,
    InfoBlock,
    PrTextarea,
  },
  data() {
    return {
      isReady: false,
      id: null,
      items: null,
    }
  },
  async created() {
    this.id = this.$route.params.deal_id

    const { ok, body } = await api.get(
      `${paths.SUPPLIER_DEALINGS}/${this.id}`,
      this.$store.state.auth.token,
    )

    if (!ok) {
      console.log(`get SUPPLIER_DEALINGS ${body}`)
      routeReplaceError(this, body.slice(-3))
      return
    }

    this.items = body
    this.isReady = true
  },
  methods: {
    getCuttings(val) {
      return this.$store.getters["defs/getOptionValueByKey"](
        "Cuttings",
        val,
        "KR",
      )
    },
    getProductFamily(val) {
      return this.$store.getters["defs/getOptionValueByKey"](
        "ProductFamily",
        val,
        "KR",
      )
    },
    getTagPrint(val) {
      return this.$store.getters["defs/getOptionValueByKey"](
        "TagPrint",
        val,
        "KR",
      )
    },
    async handleRegist() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      if (!result) {
        return
      }

      let contract_details = []
      this.items.contract.forEach(item => {
        item.contract.contract_details.forEach(detail => {
          const data = {
            id: detail.id,
            lock_version: detail.lock_version,
            contract_name: detail.contract_name,
            contract_no: detail.contract_no,
            purchase_amount: detail.purchase_amount,
            category2: detail.category2,
            size1: detail.size1,
            size2: detail.size2,
            color: detail.color,
            size3: detail.size3,
            amount: detail.amount,
            description: detail.description,
          }
          contract_details.push(JSON.parse(JSON.stringify(data)))
        })
      })

      const data = {
        contract_details: contract_details,
      }

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.SUPPLIER_DEALINGS}/${this.id}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          console.log(`put SUPPLIER_DEALINGS ${body}`)
          showErrorToast(
            this,
            body.slice(-3),
            errorMsg,
            "등록에 실패했습니다",
            "KR",
          )
          return
        }

        this.$router.push({ path: "/supplier" })
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
.rootWrapper {
  padding: 10px;
}
@include clientParts();
.infoBlock {
  max-width: 1080px;
  width: 100% !important;
}
.inner {
  align-content: flex-start;
  width: calc(50% - 40px);
  margin: 20px;
  @each $media, $_ in $breakpoints {
    @include media($media) {
      min-width: 300px;
      max-width: 600px;
      width: 100%;
      margin: 20px 0;
    }
  }
  &:last-child {
    padding-bottom: 10px;
    border-bottom: 1px dotted #666;
    margin-bottom: 10px;
  }
  div[name^="pr-"] {
    width: 100%;
    margin-bottom: 5px;
  }
}

.contracts {
  display: flex;
  flex-wrap: wrap;
  @each $media, $_ in $breakpoints {
    @include media($media) {
      justify-content: center;
    }
  }
}
.img {
  display: block;
  width: 100%;
  text-align: center;
}
</style>
