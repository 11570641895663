<template>
  <InfoBlock width="320px" margin="0" center block>
    <template slot="head">
      お客様（注文者）ご住所
    </template>
    <div :class="$style.wrapper">
      <p>郵便番号</p>
      <p>{{ deal.shipping.order_address1.zip_code }}</p>
      <p>住所</p>
      <p>
        {{ deal.shipping.order_address1.location }}<br />
        {{ deal.shipping.order_address1.address1 }}<br />
        {{ deal.shipping.order_address1.address2 }}<br />
        {{ deal.shipping.order_address2.location }}
      </p>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"

export default {
  components: {
    InfoBlock,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
@include clientParts();
</style>
