<template>
  <div style="margin-top:30px">
    <div :class="$style.head">
      <h3>出荷伝票</h3>
      <template v-if="excelTargets.length != 0">
        <Button size="main" color="submit" @click="handleDownload">
          ダウンロード
        </Button>
        <Button size="assist" color="cancel" @click="clearFlagExcel">
          クリア
        </Button>
      </template>
    </div>
    <div :class="$style.inner">
      <template v-for="{ request_number, name } in excelTargets">
        <p :key="request_number">{{ request_number }}:{{ name }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api.js"
import Button from "@/components/atoms/Button.vue"
import { routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    Button,
  },
  props: {
    excelTargets: {
      type: Array,
      required: true,
    },
    clearFlagExcel: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async handleDownload() {
      const loader = this.$loading.show()
      try {
        const rns = this.excelTargets.map(
          ({ request_number }) => request_number,
        )

        const { ok, body } = await api.post(
          "admin/report/slip-report",
          { request_numbers: rns },
          this.$store.state.auth.token,
        )

        if (!ok) {
          routeReplaceError(this, body.slice(-3))
          return
        }

        const anker = document.createElement("a")
        anker.setAttribute("href", body.report_path)
        anker.click()
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
.head {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  > h3 {
    margin-right: 30px;
  }
  > button {
    margin-right: 15px;
  }
}
.inner {
  display: flex;
  flex-wrap: wrap;
  > p {
    background: #eee;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 20px 10px 0;
  }
}
</style>
