<template>
  <InfoBlock width="700px" block>
    <template slot="head"
      >取引先 / 取引先責任者</template
    >
    <template slot="head-right">
      <ModalToggler :scroll="false">
        <template slot="button">
          <v-btn round outline color="indigo" style="margin:0 20px 0 0;"
            >変更</v-btn
          >
        </template>
        <template slot-scope="{ toggle }">
          <InfoBlock
            v-if="customers !== null && customerPersons !== null"
            width="800px"
            block
            center
          >
            <template slot="head">
              取引先変更
            </template>
            <div :class="$style.flex">
              <PrSelect
                label="取引先"
                :pr-row="[100]"
                :value="selectedCustomer"
                @input="handleCustomerInput"
                :options="customerOptions"
                name="selectedCustomer"
                validate="required"
                :searchable="true"
                style="width:300px"
              />
              <PrSelect
                label="取引先責任者"
                :pr-row="[100]"
                :value="selectedCustomerPerson"
                @input="handleCustomerPersonInput"
                :options="customerPersonOptions"
                name="selectedCustomerPerson"
                validate="required"
                :searchable="true"
                style="width:150px"
              />
              <p>
                <Button
                  size="main"
                  color="submit"
                  @click.native="handleSaveClick(toggle)"
                  style="margin-top:20px"
                  >変更</Button
                >
                <Button
                  size="assist"
                  color="cancel"
                  @click="handleCancelClick(toggle)"
                  >キャンセル</Button
                >
              </p>
            </div>
          </InfoBlock>
        </template>
      </ModalToggler>
    </template>
    <div :class="$style.rowrow">
      <div>
        <p>取引先名</p>
        <template v-if="!items.customer">
          未設定
        </template>
        <template v-else>
          <router-link
            :to="
              `/admin/dealings/${items.request_number}/customers/${
                items.customer.id
              }`
            "
            :class="$style.name"
            >{{ items.customer.organization.name }}</router-link
          >
        </template>
      </div>
      <div>
        <p>取引先責任者名</p>
        <template v-if="!items.customer_person">
          未設定
        </template>
        <template v-else>
          <router-link
            :to="
              `/admin/dealings/${items.request_number}/customer-persons/${
                items.customer_person.id
              }`
            "
            :class="$style.name"
          >
            {{
              items.customer_person.last_name +
                " " +
                items.customer_person.first_name
            }}
          </router-link>
        </template>
      </div>
    </div>
  </InfoBlock>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    Button,
    PrSelect,
    InfoBlock,
    ModalToggler,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customers: null,
      customerPersons: null,
      selectedCustomer: this.items.customer
        ? String(this.items.customer.id)
        : null,
      selectedCustomerPerson: this.items.customer_person
        ? String(this.items.customer_person.id)
        : null,
      isSaveTried: false,
    }
  },
  computed: {
    customerOptions() {
      return this.customers.map(({ id, name }) => ({
        key: String(id),
        value: name,
      }))
    },
    customerPersonOptions() {
      return this.customerPersons
        .filter(
          ({ customer_id }) =>
            this.selectedCustomer === null ||
            String(customer_id) === this.selectedCustomer,
        )
        .map(({ id, last_name, first_name }) => ({
          key: String(id),
          value: last_name + "" + first_name,
        }))
    },
    values() {
      const { request_key1, request_number, lock_version } = this.items
      return {
        request_key1,
        request_number,
        lock_version,
      }
    },
  },
  methods: {
    handleCustomerInput(e) {
      this.selectedCustomer = e
      this.selectedCustomerPerson = null
    },
    handleCustomerPersonInput(e) {
      this.selectedCustomerPerson = e
      this.selectedCustomer = null
      this.selectedCustomer = this.customerPersons
        .find(({ id }) => String(id) === e)
        .customer_id.toString()
    },
    async handleSaveClick(toggle) {
      if (!(await this.$validator.validateAll().then(r => r))) {
        return
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          paths.ADMIN_DEALINGS_ETC + "/" + this.items.request_number,
          {
            ...this.values,
            request_key2: String(this.selectedCustomer),
            request_key3: String(this.selectedCustomerPerson),
            description: "取引先を変更しました。",
          },
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$emit("updated", body)
        this.isSaveTried = false
        toggle()
      } finally {
        loader.hide()
      }
    },
    handleCancelClick(toggle) {
      this.selectedCustomer = this.items.customer
        ? String(this.items.customer.id)
        : null
      this.selectedCustomerPerson = this.items.customer_person
        ? String(this.items.customer_person.id)
        : null
      this.isSaveTried = false
      toggle()
    },
  },
  async created() {
    const { ok, body } = await api.get(
      paths.ADMIN_CUSTOMERS_AND_CUSTOMER_PERSONS,
      this.$store.state.auth.token,
    )
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }

    this.customers = body.customers
    this.customerPersons = body.customer_persons
  },
}
</script>

<style lang="scss" module>
.name {
  display: block;
  background-color: map-get($colors, lightlightGray);
  color: map-get($colors, blue);
  text-align: center;
  padding: 5px 0;
}
.row {
  text-align: center;
  width: 50%;
  padding: 0 10px;
}
.flex {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
}
.rowrow {
  display: flex;
  justify-content: space-around;
  > div {
    padding: 0 20px;
    background-color: map-get($colors, lightlightGray);
    display: flex;
    align-items: center;
    > p {
      margin-right: 10px;
      padding: 0 10px;
      background-color: map-get($colors, white);
    }
  }
}
</style>
