<template>
  <div :class="$style.wrapper" v-if="formattedItems != null">
    <div :class="$style.totalCount">
      <table :class="$style.totalTable">
        <tr>
          <th>Salesforce</th>
          <td v-cloak>{{ totalCounter.sf }}</td>
        </tr>
        <tr>
          <th>Google Drive</th>
          <td v-cloak>{{ totalCounter.gd }}</td>
        </tr>
        <tr>
          <th>GCS(移行済み)</th>
          <td v-cloak>{{ totalCounter.gcs }}</td>
        </tr>
        <tr>
          <th>Unkown</th>
          <td v-cloak>{{ totalCounter.unkown }}</td>
        </tr>
      </table>
    </div>
    <h1 :class="$style.title">画像データ移行</h1>
    <div :class="$style.explain">
      <p><b>＜手順＞</b></p>
      <p>
        ① 実行前にブラウザのクロスドメイン制約を一時的に無効にしてください。
      </p>
      <p>
        ②
        ダウンロードボタンをクリックしてください。(ブラウザ依存のダウンロードディレクトリにファイルがダウンロードされます。)
      </p>
      <p>
        ③
        すべてのダウンロードが完了したら、ファイル選択で、画像がダウンロードされたディレクトリを選択してください。
      </p>
      <p>④ 移行開始ボタンをクリックしてください。</p>
    </div>
    <div :class="$style.operation">
      <input type="file" @change="handleInputChange" webkitdirectory />
      <div>
        <Button size="sub" color="submit" @click="handleDownload"
          >ダウンロード</Button
        >
        <Button size="sub" color="alert" @click="handleMigrate"
          >移行開始</Button
        >
      </div>
    </div>
    <div v-if="resultMessage != null" :class="$style.execResultMessage">
      <p>{{ resultMessage }}</p>
    </div>
    <table :class="$style.tableDataList">
      <thead :class="$style.header">
        <tr>
          <th>商談No</th>
          <th>品目No</th>
          <th>種別</th>
          <th>格納されている場所</th>
          <th>画像</th>
          <th>画像URL</th>
          <th>ダウンロード結果</th>
          <th>送信結果</th>
          <th></th>
        </tr>
      </thead>
      <tbody :class="$style.body">
        <tr v-for="(item, index) in execItems" :key="index">
          <td>{{ item.request_number }}</td>
          <td>{{ item.contract_detail_no }}</td>
          <td>{{ item.image_category }}</td>
          <td>{{ item.image_place }}</td>
          <td><img :src="item.image_path" /></td>
          <td style="text-align: left;">{{ item.image_path }}</td>
          <td>
            <template v-if="item.isDownload != null">
              <v-icon color="orange" v-if="item.isDownload == -1"
                >remove</v-icon
              >
              <v-icon color="green" v-else-if="item.isDownload == 1"
                >check_circle</v-icon
              >
              <v-icon color="red" v-else-if="item.isDownload == 0"
                >cancel</v-icon
              >
            </template>
          </td>
          <td>
            <template v-if="item.sendResult != null">
              <v-icon color="orange" v-if="item.sendResult.status < 0"
                >error</v-icon
              >
              <v-icon color="green" v-else-if="item.sendResult.status == 1"
                >check_circle</v-icon
              >
              <v-icon color="red" v-else-if="item.sendResult.status == 0"
                >cancel</v-icon
              >
            </template>
          </td>
          <td>
            <template v-if="item.sendResult != null">
              {{ item.sendResult.message }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { uploadFile } from "@/utils/fileUpload.js"
import { showToast } from "@/utils/shared.js"

const sleep = msec => new Promise(resolve => setTimeout(resolve, msec))
const Salesforce = "Salesforce"
const GoogleDrive = "Google Drive"
const GoogleCloudStrage = "GCS(移行済み)"
const Unkown = "Unkown"

export default {
  components: {
    Button,
  },
  data() {
    return {
      items: null,
      downloadResults: null,
      sendResults: null,
      files: null,
      resultMessage: null,
    }
  },
  computed: {
    execItems() {
      let param = JSON.parse(JSON.stringify(this.formattedItems))
      for (const v of param) {
        if (this.downloadResults == null) {
          v.isDownload = null
        } else {
          v.isDownload = this.downloadResults[v.key]
        }

        if (this.sendResults == null) {
          v.sendResult = null
        } else {
          v.sendResult = this.sendResults[v.key]
        }
      }
      return param
    },
    totalCounter() {
      return this.formattedItems.reduce(
        (acc, curr) => {
          if (curr.image_place == Salesforce) {
            acc.sf++
          } else if (curr.image_place == GoogleDrive) {
            acc.gd++
          } else if (curr.image_place == GoogleCloudStrage) {
            acc.gcs++
          } else if (curr.image_place == Unkown) {
            acc.unkown++
          }

          return acc
        },
        {
          sf: 0,
          gd: 0,
          gcs: 0,
          unkown: 0,
        },
      )
    },
    formattedItems() {
      if (this.items == null) {
        return null
      }

      return this.items.body.map(v => {
        let imagePlace = Unkown
        let isTarget = true
        if (v.image_path.includes("drive.google")) {
          imagePlace = GoogleDrive
        } else if (v.image_path.includes("force.com")) {
          imagePlace = Salesforce
        } else if (v.image_path.includes("storage.googleapis")) {
          imagePlace = GoogleCloudStrage
          isTarget = false
        }

        let key
        if (v.request_number) {
          key = v.request_number
        }
        key += "_" + v.image_category + "_"
        if (v.contract_detail_no) {
          key = v.contract_detail_no
        }

        return {
          key: key,
          request_number: v.request_number,
          image_category: v.image_category,
          image_path: v.image_path,
          contract_detail_no: v.contract_detail_no,
          image_place: imagePlace,
          lock_version: v.lock_version,
          isTarget: isTarget,
        }
      })
    },
  },
  methods: {
    handleInputChange(e) {
      this.files = e.target.files
      console.log(this.files)
    },
    async handleDownload() {
      this.resultMessage = null
      const loader = this.$loading.show()
      try {
        this.downloadResults = {}
        for (const v of this.formattedItems) {
          if (!v.isTarget) {
            this.$set(this.downloadResults, v.key, -1)
            continue
          }

          const { result, url } = await this.loadImgAsBase64(v.image_path)
          if (result) {
            const fileName = `${v.key}.jpg`
            this.downloadImage(url, fileName)
            await sleep(2000)
          }
          this.$set(this.downloadResults, v.key, result == true ? 1 : 0)
        }
      } finally {
        const count = { ok: 0, ng: 0, other: 0 }
        for (let key in this.downloadResults) {
          const val = this.downloadResults[key]
          if (val == 0) {
            count.ng++
          } else if (val == 1) {
            count.ok++
          } else if (val == -1) {
            count.other++
          }
        }

        this.resultMessage = `[ダウンロード] OK: ${count.ok}件、NG: ${
          count.ng
        }件、 対象外: ${count.other}件`

        loader.hide()
      }
    },
    async handleMigrate() {
      if (this.files == null || this.files.length == 0) {
        showToast(this, "error", "フォルダを選択してください。", 2000, false)
        return null
      }

      this.resultMessage = null
      const fileNames = []
      for (let i = 0; i < this.files.length; i++) {
        fileNames.push(this.files[i].name)
      }

      const loader = this.$loading.show()
      try {
        this.sendResults = {}

        for (const item of this.formattedItems) {
          // 送信対象外
          if (!item.isTarget) {
            this.$set(this.sendResults, item.key, {
              status: -1,
              message: "移行済み",
            })
            continue
          }

          const fileIndex = fileNames.findIndex(v => {
            const fileName = item.key + ".jpg"
            if (v == fileName) {
              return true
            }
            return false
          })

          // ファイルが存在しない
          if (fileIndex == -1) {
            this.$set(this.sendResults, item.key, {
              status: -2,
              message: "ファイルが存在しません",
            })
            continue
          }

          // ファイルアップロード
          const { ok: uploadResult, body: uploadBody } = await uploadFile(
            this.files[fileIndex],
            this.$store.state.auth.userId,
            this.$store.state.auth.token,
          )

          if (!uploadResult) {
            this.$set(this.sendResults, item.key, {
              status: 0,
              message: "アップロードエラー",
            })
            continue
          }
          const url = uploadBody.path

          const { ok: putResult } = await api.put(
            paths.ADMIN_MIGRATE_IMAGES,
            {
              request_number: item.request_number,
              image_category: item.image_category,
              image_path: url,
              contract_detail_no: item.contract_detail_no,
              lock_version: item.lock_version,
            },
            this.$store.state.auth.token,
          )

          if (putResult) {
            this.$set(this.sendResults, item.key, {
              status: 1,
              message: "",
            })
          } else {
            this.$set(this.sendResults, item.key, {
              status: 0,
              message: "更新エラー",
            })
          }
        }
        await this.init()
      } finally {
        const count = { ok: 0, ng: 0, other: 0, fileNone: 0 }
        for (let key in this.sendResults) {
          const val = this.sendResults[key].status
          if (val == 0) {
            count.ng++
          } else if (val == 1) {
            count.ok++
          } else if (val == -1) {
            count.other++
          } else if (val == -2) {
            count.fileNone++
          }
        }

        this.resultMessage = `[移行] OK: ${count.ok}件、NG: ${
          count.ng
        }件、 ファイルなし: ${count.fileNone}件、 対象外: ${count.other}件`

        loader.hide()
      }
    },
    downloadImage(url, filename) {
      let link = document.createElement("a")
      link.download = filename
      link.href = url
      link.click()
    },
    loadImgAsBase64(url) {
      return new Promise((resolve, _) => {
        try {
          let canvas = document.createElement("CANVAS")
          let img = document.createElement("img")
          img.setAttribute("crossorigin", "anonymous")
          img.src = url

          img.onload = () => {
            canvas.height = img.height
            canvas.width = img.width
            let context = canvas.getContext("2d")
            context.drawImage(img, 0, 0)
            let dataURL = canvas.toDataURL("image/jpeg")
            canvas = null
            resolve({ result: true, url: dataURL })
          }

          img.onerror = () => {
            resolve({ result: false, url: null })
          }
        } catch (err) {
          resolve({ result: false, url: null })
        }
      })
    },
    async init() {
      this.items = await api.get(
        paths.ADMIN_MIGRATE_IMAGES,
        this.$store.state.auth.token,
      )
    },
  },
  async mounted() {
    await this.init()
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  position: relative;
}

.totalCount {
  position: absolute;
  top: 10px;
  right: 10px;

  .totalTable {
    th {
      text-align: left;
      color: map-get($colors, white);
      background-color: #4286f4;
      padding: 0px 10px;
    }

    td {
      text-align: center;
      padding: 0px 30px;
    }
  }
}

.explain {
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-color: #fff4f2;
  padding: 20px;
}

.operation {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  align-items: center;
}

.title {
  text-align: center;
  margin: 25px 0;
}

.execResultMessage {
  text-align: right;
  > p {
    display: inline-block;
    margin: 0px 0px 10px auto;
    background-color: #f4ad42;
    padding: 10px;
  }
}

.tableDataList {
  width: 100%;
  text-align: center;
  border-style: none;
  border-spacing: 0;

  .header {
    color: map-get($colors, white);
    background-color: #8f8f8f;

    th {
      padding: 10px 5px;
    }
  }

  .body {
    tr {
      &:nth-of-type(even) {
        background-color: #f7efe6;
      }
    }
    td {
      padding: 10px 5px;
    }
  }
}
</style>
