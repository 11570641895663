import Vue from "vue"
import VueRouter from "vue-router"
import customerRoutes from "@/router/customer"
import pr39Routes from "@/router/pr39"
import supplierRoutes from "@/router/supplier"
import webpageRoutes from "@/router/webpage"
import AdminUserControl from "@/components/organisms/admin/AdminUserControl.vue"
import migrateImageToolRoutes from "@/router/migrateImageTool"
import UserLogin from "@/components/molecules/UserLogin.vue"

import PasswordControlView from "@/components/organisms/PasswordControlView.vue"
import UserPasswordNew from "@/components/molecules/UserPasswordNew.vue"
import UserPasswordEdit from "@/components/molecules/UserPasswordEdit.vue"
import UserPasswordEditDone from "@/components/molecules/UserPasswordEditDone.vue"
import UserPasswordReminder from "@/components/molecules/UserPasswordReminder.vue"
import UserPasswordReminderDone from "@/components/molecules/UserPasswordReminderDone.vue"
import ErrorView from "@/components/organisms/ErrorView.vue"

import { authorizeToken } from "@/router/guards"

Vue.use(VueRouter)

const role = ["admin", "operator", "customer", "supplier"]

const routes = [
  {
    path: "/login",
    component: AdminUserControl,
    children: [
      {
        path: "",
        name: "userLogin",
        component: UserLogin,
        meta: { isPublic: true, title: "ログイン" },
      },
    ],
    meta: { isPublic: true },
  },
  {
    path: "/password",
    component: PasswordControlView,
    children: [
      {
        path: "new/done",
        component: UserPasswordEditDone,
        name: "userPasswordNewDone",
        meta: { isPublic: true, title: "新規パスワード" },
      },
      {
        path: "new",
        name: "userPasswordNew",
        component: UserPasswordNew,
        meta: { isPublic: true, title: "新規パスワード" },
        props: route => ({ token: route.query.params }),
      },
      {
        path: "reminder/done",
        name: "userPasswordReminderDone",
        component: UserPasswordReminderDone,
        meta: { isPublic: true, title: "パスワード再発行" },
      },
      {
        path: "reminder",
        name: "userPasswordReminder",
        component: UserPasswordReminder,
        meta: { isPublic: true, title: "パスワード再発行" },
      },
      {
        path: "edit/done",
        name: "userPasswordEditDone",
        component: UserPasswordEditDone,
        meta: { isPublic: true, title: "パスワード再発行" },
      },
      {
        path: "edit/reminder",
        name: "userPasswordReminderEdit",
        component: UserPasswordEdit,
        meta: { isPublic: true, title: "パスワード再発行" },
        props: route => ({ token: route.query.params }),
      },
      {
        path: "edit",
        name: "userPasswordEdit",
        component: UserPasswordEdit,
        meta: { role, title: "パスワード変更" },
      },
      {
        path: "",
        redirect: { name: "errorView" },
      },
      {
        path: "*",
        redirect: { name: "errorView" },
      },
    ],
    meta: { isPublic: true },
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "",
    redirect: "/login",
  },
  {
    path: "*",
    name: "errorView",
    component: ErrorView,
    props: true,
    meta: {
      isPublic: true,
      headerError: true,
      title: "エラー",
    },
  },
]

const router = new VueRouter({
  mode: "history",
  routes: [
    ...pr39Routes,
    ...customerRoutes,
    ...supplierRoutes,
    ...webpageRoutes,
    ...migrateImageToolRoutes,
    ...routes,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
router.beforeEach(authorizeToken)
router.afterEach((to, _) => {
  if (to.meta.title) {
    document.title = `${document.title.split("|")[0]}  | ${to.meta.title}`
  } else {
    document.title = `${document.title.split("|")[0]}`
  }
})

export default router
