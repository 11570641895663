<template>
  <label :class="$style.radio" name="pr-radio">
    <input
      type="radio"
      v-bind="$attrs"
      :value="value"
      :checked="Number(value) === Number(checked)"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
    />
    <span />
    <span v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    checked: {
      type: [String, Number],
      required: true,
    },
    label: String,
  },
}
</script>

<style lang="scss" module>
.radio {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  > span:first-of-type {
    width: 1.1em;
    height: 1.1em;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid map-get($colors, black);
    border-radius: 10px;
    position: relative;
  }
  > input {
    display: none;
    & + span::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0em;
      height: 0em;
      border-radius: 50%;
      background-color: #ff0000;
      transition: 0.3s;
    }
    &:checked + span::after {
      width: 0.6em;
      height: 0.6em;
      top: 20%;
      left: 20%;
    }
  }
}
</style>
