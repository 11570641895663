<template>
  <div v-if="isReady" :class="$style.wrapper">
    <h1 :class="$style.pageTtl">データ入稿</h1>
    <p :class="$style.userName" v-cloak>{{ customerName }}様</p>
    <div :class="$style.block">
      <h2 :class="$style.block_ttl">入稿データ</h2>
      <div :class="$style.block_container">
        <div :class="$style.upload">
          <div :class="$style.upload_form">
            <InputFile
              :file.sync="uploadFile"
              :accept-type="['zip', 'ai']"
              style="width:100%"
            />
            <PrErrorText
              v-show="saveTried && uploadFile === null"
              style="width:100%"
              >ファイルを選択してください。</PrErrorText
            >
          </div>
          <p :class="$style.upload_how">データ入稿の方法</p>
          <p :class="[$style.explain, $style.dark, $style.rice]">
            ①
            必要なデータは1つのフォルダにまとめてフォルダごと圧縮してください。
          </p>
          <p :class="[$style.explain, $style.dark, $style.rice]">
            ② ファイル名、フォルダ名は半角英数字で制作してください。
          </p>
          <p :class="[$style.explain, $style.dark, $style.rice]">
            ③
            データは完全入稿となります。スムーズに印刷工程に進むため、入稿前には必ず<a
              href="https://www.pr39.com/technicalguide/"
              target="_blank"
              >テクニカルガイド</a
            >をチェックしてください。
          </p>
          <p :class="[$style.explain, $style.dark, $style.rice]">
            ④ フォルダを選択したら送信ボタンを押してアップロードしてください。
          </p>
          <p :class="[$style.explain, $style.dark, $style.rice]">
            ⑤ アップロード中はページを閉じないでください。
          </p>
          <div :class="$style.button">
            <Button color="submit" size="main" @click="handleUpload"
              >送信する</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import InputFile from "@/components/atoms/InputFile.vue"
import api, { paths } from "@/utils/api.js"
import { uploadFile } from "@/utils/fileUpload.js"
import { routeReplaceError, showToast, showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    Button,
    InputFile,
    PrErrorText,
  },
  data() {
    return {
      isReady: false,
      id: null,
      items: null,
      customerName: "",
      info: null,
      uploadFile: null,
      saveTried: false,
    }
  },
  async created() {
    this.id = this.$route.params.deal_id
    await this.init()
    this.isReady = true
  },
  methods: {
    async init() {
      this.saveTried = false
      this.uploadFile = null
      const { ok, body } = await this.getDealing()
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      // 入稿するでない場合
      if (
        !this.items &&
        body.request_appendices.find(
          ({ appendix_category }) => appendix_category === "draft",
        ).request_key4 != 1
      ) {
        routeReplaceError(this, 900)
        return
      }

      this.items = body
      const { customer_person, request_appendices } = this.items

      this.customerName =
        customer_person.last_name + " " + customer_person.first_name

      this.info = request_appendices.find(
        data => data.appendix_category === "draft",
      )
    },
    async getDealing() {
      const { ok: result, body: items } = await api.get(
        `${paths.CUSTOMER_DEALINGS}/${this.id}`,
        this.$store.state.auth.token,
      )

      return { ok: result, body: items }
    },
    async handleUpload() {
      if (this.uploadFile == null) {
        this.saveTried = true
        return
      }

      const loader = this.$loading.show()

      try {
        const {
          ok: uploadResult,
          body: uploadBody,
          errorMsg: uploadErrorMsg,
        } = await uploadFile(
          this.uploadFile,
          this.$store.state.auth.userId,
          this.$store.state.auth.token,
        )

        if (!uploadResult) {
          showErrorToast(
            this,
            uploadBody.slice(-3),
            uploadErrorMsg,
            "アップロードに失敗しました。",
            "JP",
            0,
            true,
          )

          return
        }

        const data = {
          request_key1: this.info.request_key1,
          lock_version: this.info.lock_version,
          appendix_category: this.info.appendix_category,
          appendix_name: uploadBody.path,
        }

        const { ok, body, errorMsg } = await api.put(
          `${paths.CUSTOMER_DRAFT_UPLOAD}/${this.id}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(
            this,
            body.slice(-3),
            errorMsg,
            "アップロードに失敗しました。",
            "JP",
            0,
            true,
          )
        } else {
          showToast(this, "success", "アップロード完了しました。", 0, true)
          await this.init()
        }
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include clientPage();

.upload {
  width: 100%;
  &_form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &_input {
      display: block;
      width: 15%;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 0.15rem;
      background-color: map-get($colors, glay);
      > input {
        display: none;
      }
    }
    &_fileNmae {
      width: 83%;
      height: 32px;
      line-height: 32px;
      padding-left: 0.5em;
      font-size: 0.12rem;
      border: 1px solid map-get($colors, glay);
      white-space: nowrap;
    }
  }
  &_how {
    margin: 15px 0 10px;
    font-weight: bold;
    color: map-get($colors, darkGlay);
  }
}

.button {
  text-align: center;
}
</style>
