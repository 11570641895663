<template>
  <div v-if="values" :class="$style.wrapper">
    <div :class="$style.printHidden">
      <h1 style="padding-top:30px;text-align:center;">発注書</h1>
      <div :class="$style.buttonArea">
        <div v-if="!isSupplier" :class="$style.buttonContent">
          <div v-if="isEdit">
            <Button size="main" color="submit" @click.native="handleSave"
              >変更内容を保存</Button
            >
            <Button size="assist" color="cancel" @click.native="initValues"
              >キャンセル</Button
            >
          </div>
          <div v-else>
            <Button size="main" color="alert" @click.native="handleEdit"
              >編集する</Button
            >
          </div>
        </div>
        <div :class="$style.buttonPrint">
          <Button
            size="main"
            :color="isPrint ? 'submit' : 'cancel'"
            :disabled="!isPrint"
            @click.native="handlePrint"
            >この内容で印刷する</Button
          >
        </div>
      </div>
    </div>

    <slot
      :values="values"
      :onChanged="onChanged"
      :isSupplier="isSupplier"
      :isEdit="isEdit"
    />

    <div
      :class="$style.printHidden"
      style="margin:-20px auto 0;padding-bottom:100px;text-align:center;width:900px"
    >
      <div :class="$style.buttonArea">
        <div v-if="!isSupplier" :class="$style.buttonContent">
          <div v-if="isEdit">
            <Button size="main" color="submit" @click.native="handleSave"
              >変更内容を保存</Button
            >
            <Button size="assist" color="cancel" @click.native="initValues"
              >キャンセル</Button
            >
          </div>
          <div v-else>
            <Button size="main" color="alert" @click.native="handleEdit"
              >編集する</Button
            >
          </div>
        </div>
        <div :class="$style.buttonPrint">
          <Button
            size="main"
            :color="isPrint ? 'submit' : 'cancel'"
            :disabled="!isPrint"
            @click.native="handlePrint"
            >この内容で印刷する</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"

import api, { paths } from "@/utils/api.js"
import { showToast, showErrorToast, routeReplaceError } from "@/utils/shared.js"
import moment from "moment"

export default {
  components: {
    Button,
  },
  data() {
    return {
      items: null,
      values: null,
      isPrint: true,
      isSupplier: false,
      isEdit: false,
    }
  },
  inject: {
    $validator: "$validator",
  },
  methods: {
    initValues() {
      this.$validator.reset()
      this.values = JSON.parse(JSON.stringify(this.items))
      this.isPrint = true
      this.isEdit = false
    },
    onChanged() {},
    handleEdit() {
      this.isPrint = false
      this.isEdit = true
    },
    async handlePrint() {
      window.print()
      window.close()
    },
    async handleSave() {
      const result = await this.$validator.validateAll().then(result => result)
      if (!result) {
        showToast(this, "error", "必須項目を入力してください。", 2000, false)
        return
      }

      const contract_details = this.values.contract.contract.contract_details.map(
        v => {
          return {
            id: v.id,
            contract_name: v.contract_name,
            contract_no: v.contract_no,
            category1: v.category1,
            category2: v.category2,
            tax_category: v.tax_category,
            price: v.price,
            size1: v.size1,
            size2: v.size2,
            size3: v.size3,
            amount: v.amount,
            purchase_amount: v.purchase_amount,
            color: v.color,
            lock_version: v.lock_version,
            is_special_unit_price: v.is_special_unit_price,
            purchase_order: {
              id: v.purchase_order.id,
              request_number: v.purchase_order.request_number,
              contract_no: v.purchase_order.contract_no,
              contract_detail_no: v.purchase_order.contract_detail_no,
              data_name: v.purchase_order.data_name,
              fabric_type: v.purchase_order.fabric_type,
              fabric_color: v.purchase_order.fabric_color,
              cutting_method: v.purchase_order.cutting_method,
              packaging_contents: v.purchase_order.packaging_contents,
              packaging_bundle: v.purchase_order.packaging_bundle,
              packaging_individual: v.purchase_order.packaging_individual,
              description: v.purchase_order.description,
              lock_version: v.purchase_order.lock_version,
            },
          }
        },
      )

      const data = {
        request_key1: this.values.request_key1,
        request_number: this.values.request_number,
        lock_version: this.values.lock_version,
        contract: {
          request_key1: this.values.contract.request_key1,
          request_key2: this.values.contract.request_key2,
          request_number: this.values.contract.request_number,
          description: this.values.contract.description,
          request_date5: this.convertDate(this.values.contract.request_date5),
          lock_version: this.values.contract.lock_version,
          contract: {
            contract_no: this.values.contract.contract.contract_no,
            contracted_date: this.convertDate(
              this.values.contract.contract.contracted_date,
            ),
            lock_version: this.values.contract.contract.lock_version,
            contract_details: contract_details,
          },
        },
        product: {
          request_key1: this.values.product.request_key1,
          request_number: this.values.product.request_number,
          lock_version: this.values.product.lock_version,
          request_date1: this.convertDate(this.values.product.request_date1),
          request_date2: this.convertDate(this.values.product.request_date2),
        },
      }

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_PURCHASE}/${this.$route.params.deal_id}`,
          data,
          this.$store.state.auth.token,
        )
        if (!ok) {
          console.log("通信失敗", body)
          showErrorToast(this, body.slice(-3), errorMsg, "通信に失敗しました。")
          return
        }
        showToast(this, "success", "保存しました。", 2000, false)
        this.isPrint = true
        this.isEdit = false

        await this.fetch()
      } finally {
        loader.hide()
      }
    },
    convertDate(date) {
      return date == null
        ? null
        : moment(date)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ssZ")
    },
    async fetch() {
      if (this.$route.name.indexOf("supplier") !== -1) {
        const { ok, body } = await api.get(
          `${paths.SUPPLIER_PURCHASE}/${this.$route.params.deal_id}`,
          this.$store.state.auth.token,
        )
        if (!ok) {
          routeReplaceError(this, body.slice(-3))
          return
        }

        this.items = body
        this.isSupplier = true
        this.isEdit = false
      } else {
        const { ok, body } = await api.get(
          `${paths.ADMIN_PURCHASE}/${this.$route.params.deal_id}`,
          this.$store.state.auth.token,
        )
        if (!ok) {
          routeReplaceError(this, body.slice(-3))
          return
        }

        const table = {
          "CL-D": "0",
          "CL-S": "0",
          "CL-E": "0",
          "MP-D": "1",
          "ST-D": "2",
        }

        body.contract.contract.contract_details = body.contract.contract.contract_details.map(
          v => {
            const category1 = this.$store.getters["defs/getDefinitionByKey"](
              "ProductFamily",
              "definition_abbreviated",
              v.category1,
              "KR",
            )

            let purchase_order = {
              ...v.purchase_order,
              data_name:
                v.purchase_order.data_name || v.purchase_order.request_number,
            }
            if (category1 in table) {
              console.log(
                "v.purchase_order.cutting_method",
                v.purchase_order.cutting_method,
                table[category1],
              )

              purchase_order = {
                ...purchase_order,
                cutting_method:
                  v.purchase_order.cutting_method || table[category1],
              }
            }

            return {
              ...v,
              purchase_order,
            }
          },
        )

        this.items = body
      }

      this.initValues()
    },
  },
  async created() {
    await this.fetch()
  },
}
</script>

<style>
@media print {
  .toasted {
    display: none !important;
  }
}
</style>

<style lang="scss" module>
.wrapper {
  max-width: 900px;
  margin: 0 auto;
  .buttonArea {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px -20px;
    .buttonPrint {
      text-align: right;
    }
    .buttonContent {
      text-align: left;
      flex: 1;
    }
  }
}

@media print {
  .printHidden {
    display: none;
  }
}
</style>
