var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.to === undefined
    ? _c(
        "button",
        {
          class: [_vm.$style.base, _vm.$style[_vm.color], _vm.$style[_vm.size]],
          style: { width: this.width },
          attrs: { disabled: _vm.disabled },
          on: { click: _vm.handleClick },
        },
        [
          _c("span", [_vm._t("default")], 2),
          _vm._v(" "),
          _vm.plus !== undefined
            ? _c(
                "v-icon",
                { class: _vm.$style.add, attrs: { color: "white" } },
                [_vm._v("add_circle_outline")]
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "router-link",
        {
          class: [_vm.$style.base, _vm.$style[_vm.color], _vm.$style[_vm.size]],
          style: { width: this.width },
          attrs: { to: _vm.to, disabled: _vm.disabled },
          on: { click: _vm.handleClick },
        },
        [
          _c("span", [_vm._t("default")], 2),
          _vm._v(" "),
          _vm.plus !== undefined
            ? _c(
                "v-icon",
                { class: _vm.$style.add, attrs: { color: "white" } },
                [_vm._v("add_circle_outline")]
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }