<template>
  <div :class="$style.wrapper">
    <PrErrorText v-if="showError" :class="$style.error"
      >見つかりませんでした</PrErrorText
    >
    <p v-else :class="$style.msg">住所検索</p>
    <SearchButton @click.native="handleClick" />
  </div>
</template>

<script>
import SearchButton from "@/components/atoms/SearchButton.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import { getAdress, sleepMillSec } from "@/utils/shared.js"

export default {
  components: {
    SearchButton,
    PrErrorText,
  },
  data() {
    return {
      showError: false,
    }
  },
  props: {
    zipcode: {
      required: true,
    },
  },
  methods: {
    async handleClick() {
      console.log("handleClick called")
      const address = await getAdress(this.zipcode)
      this.$emit("click", { ok: address.location !== "", address })

      if (address.location === "") {
        this.showError = true
        console.log("this.showError = true")
        await sleepMillSec(3000)
        this.showError = false
        console.log("this.showError = false")
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: inline-flex;
  margin-left: 20px;
  position: relative;
  > .msg {
    position: absolute;
    top: -40px;
    right: -10px;
    background-color: map-get($colors, white);
    border: 2px solid map-get($colors, yellow);
    white-space: nowrap;
    padding: 0.2em 0.3em;
    border-radius: 4px;
    font-size: 0.14rem;
    opacity: 0;
    transition: 0.5s;
    &::after {
      content: "";
      position: absolute;
      bottom: -14px;
      right: 20px;
      width: 10px;
      border: 5px solid transparent;
      border-top: 0.6em solid map-get($colors, yellow);
    }
  }
  > .error {
    width: 13em;
    border-width: 2px;
    position: absolute;
    top: -40px;
    right: -10px;
    &::after {
      content: "";
      position: absolute;
      bottom: -14px;
      right: 20px;
      width: 10px;
      border: 5px solid transparent;
      border-top: 0.6em solid #fcc4b7;
    }
  }
  &:hover {
    > .msg {
      opacity: 1;
    }
  }
}
</style>
