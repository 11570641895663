var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items !== null
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [_vm._v("商談一覧")]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.button },
            [
              _c(
                "Button",
                {
                  attrs: {
                    to: "/admin/dealings/card/print",
                    target: "_blank",
                    size: "main",
                    color: "submit",
                  },
                },
                [_vm._v("\n      検品一覧印刷")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "main",
            { class: _vm.$style.phase },
            [
              _vm._l(_vm.phaseNames, function (name) {
                return _c("div", { key: name, class: _vm.$style.phase_head }, [
                  _c("span", [_vm._v(_vm._s(name))]),
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.phaseDeals, function (deals, i) {
                return _c(
                  "div",
                  _vm._g(
                    { key: i, class: _vm.$style.phase_deals },
                    i === 7 ? _vm.dragHandler.Inspect : {}
                  ),
                  _vm._l(deals, function (deal) {
                    return _c(
                      "DealingIndexCardItem",
                      _vm._g(
                        {
                          key: deal.request_number,
                          attrs: { deal: deal },
                          on: { edit: _vm.handleModalToggle },
                        },
                        deal.status === 13 ? _vm.dragHandler.goodsIssue : {}
                      )
                    )
                  }),
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.selectedDeal !== null
            ? _c("DealingIndexCardModal", {
                attrs: { deal: _vm.selectedDeal, position: _vm.modalPosition },
                on: { save: _vm.handleSave, cancel: _vm.handleModalClose },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }