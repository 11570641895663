var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "400px", margin: "0", block: "" } },
    [
      _c("template", { slot: "head" }, [_vm._v("入金／商品配送情報")]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.deliveryInfo }, [
        _c("div", { class: _vm.$style.deliveryInfo_row }, [
          _c("p", { class: _vm.$style.deliveryInfo_name }, [
            _vm._v("入金方法"),
          ]),
          _vm._v(" "),
          _c("p", { class: _vm.$style.deliveryInfo_text }, [
            _vm._v("\n        " + _vm._s(_vm.payment) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.deliveryInfo_row }, [
          _c("p", { class: _vm.$style.deliveryInfo_name }, [
            _vm._v("入金予定日"),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { class: _vm.$style.deliveryInfo_text },
            [
              _vm.orderedContract
                ? [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("getDate")(_vm.orderedContract.request_date3)
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("入金確認")]),
                    _vm._v(" "),
                    _c("InputCheckBox", {
                      attrs: {
                        value: _vm.orderedContract.request_date4 ? "1" : "0",
                        disabled: "",
                      },
                    }),
                  ]
                : _c("span", [_vm._v("未注文")]),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.deliveryInfo_row }, [
          _c("p", { class: _vm.$style.deliveryInfo_name }, [
            _vm._v("納品希望日"),
          ]),
          _vm._v(" "),
          _vm.orderedContract
            ? _c("p", { class: _vm.$style.deliveryInfo_text }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("getDate")(_vm.items.request_date2)) +
                    "\n      "
                ),
              ])
            : _c("p", { class: _vm.$style.deliveryInfo_text }, [
                _vm._v("未注文"),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.deliveryInfo_row }, [
          _c("p", { class: _vm.$style.deliveryInfo_name }, [
            _vm._v("出庫予定日"),
          ]),
          _vm._v(" "),
          _vm.orderedContract
            ? _c("p", { class: _vm.$style.deliveryInfo_text }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("getDate")(_vm.items.product.request_date1)) +
                    "\n      "
                ),
              ])
            : _c("p", { class: _vm.$style.deliveryInfo_text }, [
                _vm._v("未注文"),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.deliveryInfo_row }, [
          _c("p", { class: _vm.$style.deliveryInfo_name }, [
            _vm._v("発送予定日"),
          ]),
          _vm._v(" "),
          _vm.orderedContract
            ? _c("p", { class: _vm.$style.deliveryInfo_text }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("getDate")(_vm.items.delivery.request_date2)
                    ) +
                    "\n      "
                ),
              ])
            : _c("p", { class: _vm.$style.deliveryInfo_text }, [
                _vm._v("未注文"),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.deliveryInfo_row }, [
          _c("p", { class: _vm.$style.deliveryInfo_name }, [
            _vm._v("納品書有無"),
          ]),
          _vm._v(" "),
          _c("p", { class: _vm.$style.deliveryInfo_text }, [
            _vm._v("\n        " + _vm._s(_vm.deliveryNote) + "\n      "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.button },
        [
          _vm.orderedContract
            ? _c(
                "Button",
                {
                  attrs: { color: "submit", size: "main" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$router.push(
                        `/admin/dealings/${_vm.items.request_number}/delivery`
                      )
                    },
                  },
                },
                [_vm._v("編集する")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }