<template>
  <div :class="$style.wrapper">
    <h1 :class="$style.title" v-cloak>{{ words.title }}</h1>
    <div>
      <p :class="$style.message_error" v-if="validMessage" v-cloak>
        {{ validMessage }}
      </p>
      <form :class="$style.formAria" @submit.prevent="handlePassChange">
        <p :class="$style.desc" v-cloak>{{ words.description }}</p>
        <InputForm
          name="password"
          :class="$style.form"
          type="password"
          v-model="password"
          v-validate="'required|min:8|max:20|password'"
          ref="password"
          v-cloak
          >{{ words.password }}</InputForm
        >
        <InputForm
          name="repassword"
          :class="$style.form"
          type="password"
          v-model="repassword"
          v-validate="'required|min:8|max:20|confirmed:password'"
          v-cloak
          data-vv-as="password"
          >{{ words.repassword }}</InputForm
        >
        <Button type="submit" color="submit" size="main" v-cloak>{{
          words.submit
        }}</Button>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import InputForm from "@/components/molecules/InputForm.vue"
import api, { paths } from "@/utils/api.js"
import { showToast, showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    Button,
    InputForm,
  },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      lang: "JP",
      isSupplier: false,
      password: "",
      repassword: "",
      validMessage: null,
    }
  },
  computed: {
    words() {
      const table = {
        JP: {
          title: "パスワード変更",
          description: "変更したいパスワードを入力してください。",
          password: "新しいパスワード",
          repassword: "新しいパスワード(確認用)",
          submit: "変更する",
          message: {
            success: "パスワードが変更されました。",
            error: "パスワードの変更に失敗しました。",
            passRequired: "新しいパスワードを入力してください。",
            passLength:
              "新しいパスワードは8文字以上20文字以下で入力してください。",
            repassRequired: "新しいパスワード(確認用)を入力してください。",
            repassLength:
              "新しいパスワード(確認用)は8文字以上20文字以下で入力してください。",
            passMismatch:
              "新しいパスワードと新しいパスワード(確認用)が一致していません。",
          },
        },
        KR: {
          title: "암호 변경",
          description: "변경하려는 암호를 입력하십시오",
          password: "새 암호",
          repassword: "새 암호 (확인 용)",
          submit: "변경",
          message: {
            success: "비밀번호가 변경되었습니다",
            error: "암호 변경에 실패했습니다",
            passRequired: "새 암호를 입력하십시오",
            passLength: "새 암호는 8 자 이상 20 자 이하로 입력하십시오",
            repassRequired: "새 암호 (확인 용)를 입력하십시오",
            repassLength:
              "새 암호 (확인 용)는 8 자 이상 20 자 이하로 입력하십시오",
            passMismatch: "새 암호와 새 암호 (확인 용)가 일치하지 않습니다",
          },
        },
      }

      return table[this.lang]
    },
  },
  created() {
    this.lang = this.$store.state.auth.role === "supplier" ? "KR" : "JP"
    this.isSupplier = this.$store.state.auth.role === "supplier" ? true : false
  },
  methods: {
    setValidMessage(message) {
      this.validMessage = message
    },
    validate() {
      return this.$validator.validateAll().then(result => {
        if (!result) {
          if (this.errors.firstByRule("password", "required")) {
            this.setValidMessage(this.words.message.passRequired)
            return false
          }
          if (
            this.errors.firstByRule("password", "min") ||
            this.errors.firstByRule("password", "max")
          ) {
            this.setValidMessage(this.words.message.passLength)
            return false
          }
          if (this.errors.firstByRule("repassword", "required")) {
            this.setValidMessage(this.words.message.repassRequired)
            return false
          }
          if (
            this.errors.firstByRule("repassword", "min") ||
            this.errors.firstByRule("repassword", "max")
          ) {
            this.setValidMessage(this.words.message.repassLength)

            return false
          }
          if (this.errors.firstByRule("repassword", "confirmed")) {
            this.setValidMessage(this.words.message.passMismatch)

            return false
          }

          return false
        } else {
          this.setValidMessage(null)
          return true
        }
      })
    },
    async handlePassChange() {
      let result = await this.validate()
      if (!result) {
        return
      }
      const loader = this.$loading.show()

      try {
        if (this.$route.name === "userPasswordReminderEdit") {
          // パスワード再発行 → パスワード変更
          const { ok, body, errorMsg } = await api.post(
            paths.RESET_PASSWORD,
            {
              password: this.password,
            },
            this.token,
          )

          if (!ok) {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              this.words.message.error,
              this.lang,
            )
            return
          }

          this.$router.replace({ path: "/password/edit/done" })
        } else {
          const path =
            this.isSupplier === true ? paths.SUPPLIER_USER : paths.CUSTOMER_USER

          // 通常のパスワード変更
          const { ok, body, errorMsg } = await api.put(
            path,
            {
              password: this.password,
            },
            this.$store.state.auth.token,
          )

          if (!ok) {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              this.words.message.error,
              this.lang,
            )
            return
          }

          showToast(this, "success", this.words.message.success, 2000, false)
        }
      } finally {
        loader.hide()
      }
    },
  },
}
</script>
<style lang="scss" module>
@include displayMessage();

.wrapper {
  min-height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  padding: 55px 0;
}

.formAria {
  width: 800px;
  margin: 0 auto;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  & > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form {
  width: 465px;
}

.desc {
  font-size: 0.18rem;
  color: #707070;
}
</style>
