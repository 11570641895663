var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.rootWrapper },
        [
          _c("PageTitle", [_vm._v("견적 입력화면")]),
          _vm._v(" "),
          _vm._l(_vm.items.contract, function (detail, index1) {
            return _c(
              "InfoBlock",
              {
                key: index1,
                class: _vm.$style.infoBlock,
                attrs: { block: "", margin: "0 auto 30px", center: "" },
              },
              [
                _c("template", { slot: "head" }, [
                  _vm._v(
                    "\n      관리 번호 " +
                      _vm._s(detail.contract.contract_no) +
                      "\n    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.contracts },
                  _vm._l(
                    detail.contract.contract_details,
                    function (item, index2) {
                      return _c(
                        "div",
                        { key: index2, class: _vm.$style.inner },
                        [
                          _c("p", [_vm._v("상품분류")]),
                          _vm._v(" "),
                          _c("p", {}, [
                            _vm._v(
                              _vm._s(_vm.getProductFamily(item.category1))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: { label: "커버 사이즈", "pr-row": [45, 55] },
                            model: {
                              value: item.size1,
                              callback: function ($$v) {
                                _vm.$set(item, "size1", $$v)
                              },
                              expression: "item.size1",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("커버 도무송")]),
                          _vm._v(" "),
                          _c("p", {}, [
                            _vm._v(_vm._s(_vm.getCuttings(item.category3))),
                          ]),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: {
                              label: "내지 사이즈",
                              "pr-row": [45, 55],
                              name: "size2_" + index1 + index2,
                              validate: "required",
                              korean: "",
                            },
                            model: {
                              value: item.size2,
                              callback: function ($$v) {
                                _vm.$set(item, "size2", $$v)
                              },
                              expression: "item.size2",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("내지 도무송")]),
                          _vm._v(" "),
                          _c("p", {}, [
                            _vm._v(_vm._s(_vm.getCuttings(item.category4))),
                          ]),
                          _vm._v(" "),
                          _c("PrSelect", {
                            attrs: {
                              name: "color_" + index1 + index2,
                              options: _vm.$store.getters["defs/getOptions"](
                                "TagPrint",
                                "KR"
                              ),
                              label: "인쇄",
                              "pr-row": [45, 55],
                              validate: "required",
                              korean: "",
                            },
                            model: {
                              value: item.color,
                              callback: function ($$v) {
                                _vm.$set(item, "color", $$v)
                              },
                              expression: "item.color",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: {
                              label: "매수",
                              "pr-row": [45, 55],
                              name: "size3" + index1 + index2,
                              validate: "required|numeric",
                              korean: "",
                            },
                            model: {
                              value: item.size3,
                              callback: function ($$v) {
                                _vm.$set(item, "size3", $$v)
                              },
                              expression: "item.size3",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: {
                              label: "개수",
                              "pr-row": [45, 55],
                              name: "amount" + index1 + index2,
                              validate: "required|numeric",
                              korean: "",
                            },
                            model: {
                              value: item.amount,
                              callback: function ($$v) {
                                _vm.$set(item, "amount", $$v)
                              },
                              expression: "item.amount",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrTextarea", {
                            attrs: { label: "기타", "pr-row": [45, 55] },
                            model: {
                              value: item.description,
                              callback: function ($$v) {
                                _vm.$set(item, "description", $$v)
                              },
                              expression: "item.description",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: {
                              label: "단가",
                              "pr-row": [45, 55],
                              name: "purchase_amount_" + index1 + index2,
                              validate: "required|numeric",
                              korean: "",
                            },
                            model: {
                              value: item.purchase_amount,
                              callback: function ($$v) {
                                _vm.$set(item, "purchase_amount", $$v)
                              },
                              expression: "item.purchase_amount",
                            },
                          }),
                          _vm._v(" "),
                          item.image_url
                            ? _c(
                                "a",
                                {
                                  class: _vm.$style.img,
                                  attrs: {
                                    href: item.image_url,
                                    target: "_blank",
                                  },
                                },
                                [_c("img", { attrs: { src: item.image_url } })]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(
            "Button",
            {
              staticStyle: { margin: "0 auto", display: "block" },
              attrs: { color: "submit", size: "main" },
              on: { click: _vm.handleRegist },
            },
            [_vm._v("등록하기")]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }