var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [
            _vm._v("商品" + _vm._s(_vm.isNew ? "新規作成" : "詳細")),
          ]),
          _vm._v(" "),
          _c(
            "InfoBlock",
            [
              _c("template", { slot: "head" }, [_vm._v("商品概要")]),
              _vm._v(" "),
              _c("InfoList", {
                attrs: { list: _vm.outLineOverview },
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "InfoBlock",
            [
              _c("template", { slot: "head" }, [_vm._v("商品仕様")]),
              _vm._v(" "),
              _c("InfoList", {
                attrs: { list: _vm.outLineSpec },
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.isNew
            ? _c(
                "InfoBlock",
                [
                  _c("template", { slot: "head" }, [_vm._v("価格情報")]),
                  _vm._v(" "),
                  _c("table", { class: _vm.$style.table }, [
                    _c("thead", { class: _vm.$style.table_head }, [
                      _c("tr", { class: _vm.$style.table_head_tr }, [
                        _c("th", { class: _vm.$style.table_head_tr_th }),
                        _vm._v(" "),
                        _c("th", { class: _vm.$style.table_head_tr_th }, [
                          _vm._v("リスト価格"),
                        ]),
                        _vm._v(" "),
                        _c("th", { class: _vm.$style.table_head_tr_th }, [
                          _vm._v("特別価格"),
                        ]),
                        _vm._v(" "),
                        _c("th", { class: _vm.$style.table_head_tr_th }, [
                          _vm._v("仕入値"),
                        ]),
                        _vm._v(" "),
                        _c("th", { class: _vm.$style.table_head_tr_th }, [
                          _vm._v("掛率"),
                        ]),
                        _vm._v(" "),
                        _c("th", { class: _vm.$style.table_head_tr_th }, [
                          _vm._v("適用開始"),
                        ]),
                        _vm._v(" "),
                        _c("th", { class: _vm.$style.table_head_tr_th }, [
                          _vm._v("適用終了"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      { class: _vm.$style.table_body },
                      _vm._l(_vm.items.prices, function (item, index) {
                        return _c(
                          "tr",
                          { key: index, class: _vm.$style.table_body_tr },
                          [
                            index === 0 && _vm.priceEditflag
                              ? [
                                  _c(
                                    "td",
                                    { class: [_vm.$style.table_body_tr_td] },
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { text: "", icon: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "#8f8f8f" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.priceEditflag =
                                                    !_vm.priceEditflag
                                                },
                                              },
                                            },
                                            [_vm._v("close")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        { attrs: { text: "", icon: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "#8f8f8f" },
                                              on: {
                                                click:
                                                  _vm.handlePriceSaveButtonClick,
                                              },
                                            },
                                            [_vm._v("save")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.$style.table_body_tr_td,
                                        _vm.$style.input,
                                      ],
                                    },
                                    [
                                      _c("PrInput", {
                                        attrs: {
                                          name: "unit_price",
                                          "pr-row": [100, 100],
                                          disabled: !!Number(
                                            _vm.priceValues.description
                                          ),
                                          validate: "decimal:3",
                                        },
                                        model: {
                                          value: _vm.priceValues.unit_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.priceValues,
                                              "unit_price",
                                              $$v
                                            )
                                          },
                                          expression: "priceValues.unit_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.$style.table_body_tr_td,
                                        _vm.$style.input,
                                      ],
                                    },
                                    [
                                      _c("PrInput", {
                                        attrs: {
                                          name: "special_unit_price",
                                          "pr-row": [100, 100],
                                          validate: "decimal:3",
                                        },
                                        model: {
                                          value:
                                            _vm.priceValues.special_unit_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.priceValues,
                                              "special_unit_price",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "priceValues.special_unit_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.$style.table_body_tr_td,
                                        _vm.$style.input,
                                      ],
                                    },
                                    [
                                      _c("PrInput", {
                                        attrs: {
                                          name: "purchase_amount",
                                          "pr-row": [100, 100],
                                          validate: "numeric",
                                        },
                                        on: {
                                          input:
                                            _vm.handlePurchaseAmountDescriptionChange,
                                        },
                                        model: {
                                          value:
                                            _vm.priceValues.purchase_amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.priceValues,
                                              "purchase_amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "priceValues.purchase_amount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.$style.table_body_tr_td,
                                        _vm.$style.input,
                                      ],
                                    },
                                    [
                                      _c("PrInput", {
                                        attrs: {
                                          name: "description",
                                          "pr-row": [100, 100],
                                          validate: "decimal:3",
                                        },
                                        on: {
                                          input:
                                            _vm.handlePurchaseAmountDescriptionChange,
                                        },
                                        model: {
                                          value: _vm.priceValues.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.priceValues,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "priceValues.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.$style.table_body_tr_td,
                                        _vm.$style.input,
                                      ],
                                    },
                                    [
                                      _c("PrDatePicker", {
                                        attrs: { name: "start_datetime" },
                                        model: {
                                          value: _vm.priceValues.start_datetime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.priceValues,
                                              "start_datetime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "priceValues.start_datetime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "td",
                                    { class: _vm.$style.table_body_tr_td },
                                    [
                                      index == 0
                                        ? [
                                            _c(
                                              "v-btn",
                                              { attrs: { text: "", icon: "" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "#8f8f8f" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.priceEditflag =
                                                          !_vm.priceEditflag
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("edit")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { class: _vm.$style.table_body_tr_td },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.unit_price) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { class: _vm.$style.table_body_tr_td },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.special_unit_price) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { class: _vm.$style.table_body_tr_td },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.purchase_amount) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { class: _vm.$style.table_body_tr_td },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.description) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { class: _vm.$style.table_body_tr_td },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.start_datetime
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                            _vm._v(" "),
                            _c("td", { class: _vm.$style.table_body_tr_td }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm._f("moment")(item.end_datetime)) +
                                  "\n          "
                              ),
                            ]),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNew
            ? _c("RelatedInfo", { attrs: { infoList: _vm.relatedInfo } }, [
                _vm._v("関連情報"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.buttons_center },
            [
              !_vm.isNew
                ? _c("QuotationDetailDialog", {
                    attrs: {
                      texts: {
                        ttl: _vm.isCopied ? "コピー完了" : "確認",
                        msg: _vm.isCopied
                          ? `商品Id:${_vm.copyItemId}としてコピーしました。編集画面へ移動しますか？`
                          : `商品Id:${_vm.items.id}をコピーしますか？`,
                        yas: _vm.isCopied ? "移動する" : "コピーする",
                        no: "いいえ",
                      },
                      "no-close": !_vm.isCopied,
                    },
                    on: {
                      accept: _vm.handleCopyAccept,
                      refuse: function ($event) {
                        _vm.isCopied = false
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "Button",
                                {
                                  attrs: { color: "submit", size: "main" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSaveButtonClick.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("編集内容を保存する")]
                              ),
                              _vm._v(" "),
                              !_vm.isNew
                                ? _c(
                                    "Button",
                                    {
                                      attrs: {
                                        color: "cancel",
                                        size: "assist",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.initValues.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("クリア")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "Button",
                                _vm._g(
                                  { attrs: { size: "main", color: "submit" } },
                                  on
                                ),
                                [_vm._v("この商品をコピー")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      431173033
                    ),
                  })
                : [
                    _c(
                      "Button",
                      {
                        attrs: { color: "submit", size: "main" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSaveButtonClick.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("編集内容を保存する")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }