var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { class: _vm.$style.app },
    [
      _c("Header", {
        attrs: { id: "header", toggleSlideMenuShow: _vm.toggleSlideMenuShow },
      }),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      !_vm.$route.meta.isWebpage
        ? _c("SlideMenu", {
            attrs: {
              isShow: _vm.isSlideMenuShow,
              toggleSlideMenuShow: _vm.toggleSlideMenuShow,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSlideMenuShow,
              expression: "isSlideMenuShow",
            },
          ],
          class: _vm.$style.overray,
          on: { click: _vm.toggleSlideMenuShow },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }