<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="headers"
    :filter-func="filterFunc"
    page-id="customerPersons"
  >
    <template slot="page-title">
      取引先責任者一覧
    </template>
    <Button
      slot="create-link"
      size="main"
      color="submit"
      plus
      to="/admin/customer-persons/new"
    >
      新しい取引先責任者を追加する
    </Button>

    <div
      slot="filter"
      slot-scope="{ filter, handleTextInput, handleFlagOn, filterClass }"
      :class="filterClass"
    >
      <PrInput
        label="キーワード"
        :pr-row="[100, 85]"
        :value="keywordHandler.value"
        @input="keywordHandler.input"
        @keydown.native.enter="keywordHandler.keydown"
        @keyup.native.enter="
          keywordHandler.keyup($event, handleFlagOn, handleTextInput, filter)
        "
        style="justify-content:space-between;"
      >
        <SearchButton
          slot="after"
          @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="keywordHandler.isOn"
        />
      </PrInput>
    </div>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import Button from "@/components/atoms/Button.vue"
import { getDate, routeReplaceError } from "@/utils/shared.js"
import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PrInput,
    SearchButton,
    Button,
    IndexTableProvider,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"](
      "customerPersons",
    )
    return {
      items: null,
      keywordHandler: newWordSearchHandler("keyword", filter),
      headers: [
        {
          text: "取引先責任者名",
          value: ({ last_name, first_name }) => last_name + " " + first_name,
          link: ({ id }) => `customer-persons/${id}`,
          width: 14,
        },
        { text: "取引先名", value: "customer.organization.name", width: 28 },
        { text: "役職", value: "title", width: 17 },
        { text: "電話番号", value: "phone", width: 8 },
        { text: "メールアドレス", value: "user.email", width: 25 },
        {
          text: "更新日付",
          value: ({ updated_at }) => getDate(updated_at),
          width: 8,
        },
      ],
    }
  },
  methods: {
    filterFunc: ({ keyword }) => item => {
      let test = true

      test = keywordFilter(keyword, test, item)

      return test
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const { ok, body } = await api.get(
        paths.ADMIN_CUSTOMER_PERSONS,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>
