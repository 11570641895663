import { getDate, formatPrice } from "@/utils/shared.js"
import {
  calcBillingTotalPrice,
  calcBillingDeliveryFee,
} from "@/helpers/QuotationDetailViewHelper.js"
import store from "@/store"

const setValues = deal => {
  const dealContruct = deal.contract.find(({ status }) => status === 1)
  return {
    quantity3: deal.quantity3, // 自動発送メール
    request_number: deal.request_number,
    request_key1: deal.request_key1,
    request_date2: deal.request_date2,
    lock_version: deal.lock_version,
    product: {
      quantity1: deal.product.quantity1, // 注文数
      quantity2: deal.product.quantity2, // 注文予備数
      quantity4: deal.product.quantity4, // 出庫商品総数
      quantity3: deal.product.quantity3, // 出庫箱数
      request_date1: deal.product.request_date1, // 出庫予定日
      request_date2: deal.product.request_date2, // 港受取日
      request_date3: deal.product.request_date3, // 検品日
      quantity6: deal.product.quantity6, // 保管商品箱数
      description: deal.product.description, // 到着商品内容
      quantity5: deal.product.quantity5, // 発送箱数
      note2: deal.product.note2, // 備考
      status: deal.product.status, // 内職
      request_number: deal.product.request_number,
      request_key1: deal.product.request_key1,
      lock_version: deal.product.lock_version,
    },
    delivery: {
      request_date2: deal.delivery.request_date2, // 発送日（予定日）
      quantity1: deal.delivery.quantity1, // 納品形態
      quantity2: deal.delivery.quantity2, // 発送代行有無
      request_date3: deal.delivery.request_date3, // お届け予定日
      note1: deal.delivery.note1, // 受取可能曜日
      note2: deal.delivery.note2, // 時間
      quantity3: deal.delivery.quantity3, // 納品書有無
      quantity4: deal.delivery.quantity4, // ヤマトタイムサービス
      note3: deal.delivery.note3, // 伝票番号
      request_number: deal.delivery.request_number,
      request_key1: deal.delivery.request_key1,
      lock_version: deal.delivery.lock_version,
    },
    shipping: {
      // 送り主情報
      sender_address1: {
        id: deal.shipping.sender_address1.id,
        zip_code: deal.shipping.sender_address1.zip_code, // 郵便番号
        location: deal.shipping.sender_address1.location, // 都道府県名
        address1: deal.shipping.sender_address1.address1, // 市区郡
        address2: deal.shipping.sender_address1.address2, // 町名・番地
      },
      sender_address2: {
        id: deal.shipping.sender_address2.id,
        location: deal.shipping.sender_address2.location, // 建物名
        address1: deal.shipping.sender_address2.address1, // 会社名
        address2: deal.shipping.sender_address2.address2, // 部署
      },
      sender_address3: {
        id: deal.shipping.sender_address3.id,
        address1: deal.shipping.sender_address3.address1, // 担当者
        address2: deal.shipping.sender_address3.address2, // 電話
      },
      // 納品先情報
      delivery_address1: {
        id: deal.shipping.delivery_address1.id,
        zip_code: deal.shipping.delivery_address1.zip_code, // 郵便番号
        location: deal.shipping.delivery_address1.location, // 都道府県名
        address1: deal.shipping.delivery_address1.address1, // 市区郡
        address2: deal.shipping.delivery_address1.address2, // 町名・番地
      },
      delivery_address2: {
        id: deal.shipping.delivery_address2.id,
        location: deal.shipping.delivery_address2.location, // 建物名
        address1: deal.shipping.delivery_address2.address1, // 会社名
        address2: deal.shipping.delivery_address2.address2, // 部署
      },
      delivery_address3: {
        id: deal.shipping.delivery_address3.id,
        address1: deal.shipping.delivery_address3.address1, // 担当者
        address2: deal.shipping.delivery_address3.address2, // 電話
      },
      order_address1: {
        id: deal.shipping.order_address1.id,
      },
      order_address2: {
        id: deal.shipping.order_address2.id,
      },
      order_address3: {
        id: deal.shipping.order_address3.id,
      },
      request_number: deal.shipping.request_number,
      request_key1: deal.shipping.request_key1,
      lock_version: deal.shipping.lock_version,
    },
    contract: {
      request_date3: dealContruct.request_date3, // 入金予定日
      request_date4: dealContruct.request_date4, // 入金日
      quantity4: dealContruct.quantity4, // 支払方法
      quantity5: dealContruct.quantity5, // 後払い
      request_number: dealContruct.request_number,
      request_key1: dealContruct.request_key1,
      request_key2: dealContruct.request_key2,
      lock_version: dealContruct.lock_version,
    },
  }
}

const setMailTemplateValue = deal => {
  const { customer, customer_person, user, contract, delivery, shipping } = deal
  const orderedContract = contract.find(({ status }) => status === 1)
  const productCategory =
    orderedContract.supplier.supplier_code === "3"
      ? "マイクロファイバー"
      : "オリジナル付箋"

  let orderAmount = calcBillingTotalPrice(
    orderedContract.contract.contract_details,
  ).total

  const cod = calcBillingDeliveryFee(orderAmount, 1)
  orderAmount += cod

  return {
    name: customer.organization.name,
    last_name: customer_person.last_name,
    first_name: customer_person.first_name,
    user_name: user ? user.name : null,
    product_category: productCategory,
    delivery_note3: delivery.note3,
    delivery_note2: store.getters["defs/getOptionValueByKey"](
      "Times",
      delivery.note2,
    ),
    delivery_request_date3: getDate(delivery.request_date3),
    shipping_delivery1_zip_code: shipping.delivery_address1.zip_code
      ? `${shipping.delivery_address1.zip_code}\n`
      : `\n`,
    shipping_delivery1_location:
      shipping.delivery_address1.location &&
      `     ${shipping.delivery_address1.location}\n`,
    shipping_delivery1_address1:
      shipping.delivery_address1.address1 &&
      `     ${shipping.delivery_address1.address1}\n`,
    shipping_delivery1_address2:
      shipping.delivery_address1.address2 &&
      `     ${shipping.delivery_address1.address2}\n`,
    shipping_delivery2_location:
      shipping.delivery_address2.location &&
      `     ${shipping.delivery_address2.location}\n`,
    shipping_delivery2_address1:
      shipping.delivery_address2.address1 &&
      `${shipping.delivery_address2.address1}\n`,
    shipping_delivery2_address2: shipping.delivery_address2.address1
      ? shipping.delivery_address2.address2 &&
        `     ${shipping.delivery_address2.address2}\n`
      : shipping.delivery_address2.address2 &&
        `${shipping.delivery_address2.address2}\n`,
    shipping_delivery3_address1:
      shipping.delivery_address2.address1 || shipping.delivery_address2.address2
        ? shipping.delivery_address3.address1 &&
          `     ${shipping.delivery_address3.address1}`
        : shipping.delivery_address3.address1 &&
          `${shipping.delivery_address3.address1}`,
    shipping_delivery3_address2:
      shipping.delivery_address3.address2 &&
      `${shipping.delivery_address3.address2}\n`,
    shipping_sender1_zip_code: shipping.sender_address1.zip_code
      ? `${shipping.sender_address1.zip_code}\n`
      : `\n`,
    shipping_sender1_location:
      shipping.sender_address1.location &&
      `     ${shipping.sender_address1.location}\n`,
    shipping_sender1_address1:
      shipping.sender_address1.address1 &&
      `     ${shipping.sender_address1.address1}\n`,
    shipping_sender1_address2:
      shipping.sender_address1.address2 &&
      `     ${shipping.sender_address1.address2}\n`,
    shipping_sender2_location:
      shipping.sender_address2.location &&
      `     ${shipping.sender_address2.location}\n`,
    shipping_sender2_address1:
      shipping.sender_address2.address1 &&
      `${shipping.sender_address2.address1}\n`,
    shipping_sender2_address2: shipping.sender_address2.address1
      ? shipping.sender_address2.address2 &&
        `     ${shipping.sender_address2.address2}\n`
      : shipping.sender_address2.address2 &&
        `${shipping.sender_address2.address2}\n`,
    shipping_sender3_address1:
      shipping.sender_address2.address1 || shipping.sender_address2.address2
        ? shipping.sender_address3.address1 &&
          `     ${shipping.sender_address3.address1}`
        : shipping.sender_address3.address1 &&
          `${shipping.sender_address3.address1}`,
    shipping_sender3_address2:
      shipping.sender_address3.address2 &&
      `${shipping.sender_address3.address2}\n`,
    cod: cod,
    order_amount: formatPrice(orderAmount, false),
  }
}

export { setValues, setMailTemplateValue }
