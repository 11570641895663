<template>
  <div :class="$style.inner">
    <PrInput
      label="会社名・団体名"
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address2`].address1"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) 株式会社PR39"
    />
    <PrInput
      label="部署名"
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address2`].address2"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) 企画販促課"
    />
    <PrInput
      :label="
        whom === 'sender'
          ? 'ご担当者氏名(送り主名)'
          : 'ご担当者氏名(お届け先名)'
      "
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address3`].address1"
      :name="`custom_${whom}._address3.address1`"
      :validate="'required'"
      :data-vv-scope="'custom_' + whom"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) 比井有 三九"
      requiredIcon
    />
    <PrInput
      label="郵便番号"
      :pr-row="[100, 75]"
      v-model="shipping[whom][`${whom}_address1`].zip_code"
      :name="`custom_${whom}._address1.zip_code`"
      :validate="'required|zipcode'"
      :data-vv-scope="'custom_' + whom"
      @change="setCustomAddress(shipping[whom])"
      :label-style="{ position: 'relative' }"
      placeholder="例) 812-0895"
      requiredIcon
    >
      <ButtonSerchZipCode
        slot="after"
        :zipcode="shipping[whom][`${whom}_address1`].zip_code"
        @click="handleZipSearch"
      />
    </PrInput>
    <PrInput
      label="都道府県"
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address1`].location"
      :name="`custom_${whom}._address1.location`"
      :validate="'required'"
      :data-vv-scope="'custom_' + whom"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) 福岡県"
      requiredIcon
    />
    <PrInput
      label="市区町村"
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address1`].address1"
      :name="`custom_${whom}._address1.address1`"
      :validate="'required'"
      :data-vv-scope="'custom_' + whom"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) 福岡市博多区"
      requiredIcon
    />
    <PrInput
      label="町名・番地"
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address1`].address2"
      :name="`custom_${whom}._address1.address2`"
      :validate="'required'"
      :data-vv-scope="'custom_' + whom"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) 竹下1丁目11-23"
      requiredIcon
    />
    <PrInput
      label="建物名・部屋番号"
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address2`].location"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) アクアサイドGEN1F"
    />
    <PrInput
      label="電話番号"
      :pr-row="[100, 100]"
      v-model="shipping[whom][`${whom}_address3`].address2"
      :name="`custom_${whom}._address3.address2`"
      :validate="'required|telephone'"
      :data-vv-scope="'custom_' + whom"
      @change="setCustomAddress(shipping[whom])"
      placeholder="例) 092-409-6039"
      requiredIcon
    />
  </div>
</template>

<script>
import PrInput from "@/components/molecules/PrInput.vue"
import ButtonSerchZipCode from "@/components/molecules/ButtonSerchZipCode.vue"

export default {
  inject: ["$validator"],
  components: {
    PrInput,
    ButtonSerchZipCode,
  },
  props: {
    whom: {
      type: String,
      required: true,
    },
    setCustomAddress: {
      type: Function,
      required: true,
    },
    flag: {
      type: [String, Number],
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shipping: {
        delivery: {
          delivery_address1: {
            zip_code: "",
            location: "",
            address1: "",
            address2: "",
          },
          delivery_address2: {
            location: "",
            address1: "",
            address2: "",
          },
          delivery_address3: {
            address1: "",
            address2: "",
          },
        },
        sender: {
          sender_address1: {
            zip_code: "",
            location: "",
            address1: "",
            address2: "",
          },
          sender_address2: {
            location: "",
            address1: "",
            address2: "",
          },
          sender_address3: {
            address1: "",
            address2: "",
          },
        },
      },
    }
  },
  methods: {
    handleZipSearch({ ok, address }) {
      if (!ok) {
        return
      }
      const { shipping, whom, setCustomAddress } = this
      const currentAddress = shipping[whom][`${whom}_address1`]
      this.shipping[whom][`${whom}_address1`] = {
        ...currentAddress,
        ...address,
      }
      setCustomAddress(this.shipping[whom])

      for (const { name, scope } of [
        `custom_${this.whom}._address1.location`,
        `custom_${this.whom}._address1.address1`,
        `custom_${this.whom}._address1.address2`,
      ].map(key => this.$validator.fields.find({ name: key }))) {
        this.errors.remove(name, scope)
      }
    },
  },
  created() {
    if (this.flag == "1") {
      const { whom, address } = this
      this.shipping[whom] = {
        [`${whom}_address1`]: address[`${whom}_address1`],
        [`${whom}_address2`]: address[`${whom}_address2`],
        [`${whom}_address3`]: address[`${whom}_address3`],
      }
    }
  },
  watch: {
    flag(newFlag) {
      if (Number(newFlag) === 1) {
        this.setCustomAddress(this.shipping[this.whom])
      }
    },
  },
}
</script>

<style lang="scss" module>
.inner {
  width: 100% !important;
  > div[name="pr-text"] {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
