<template>
  <ModalToggler :close-button="true">
    <v-btn slot="button" flat color="blue">
      <v-icon>mail_outline</v-icon>
      メール用テンプレート表示
    </v-btn>
    <InfoBlock
      v-if="items !== null"
      width="750px"
      style="position: relative"
      block
    >
      <template slot="head"
        >メールテンプレート {{ headLine }}
        <v-btn flat color="black" @click="writeToClipboard">
          <v-icon>content_copy</v-icon>
        </v-btn>
      </template>
      <div :class="$style.mail">
        <p>{{ body }}</p>
      </div>
    </InfoBlock>
  </ModalToggler>
</template>

<script>
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import { showToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

const headLines = {
  estimates: "見積確認依頼",
  verification_image: "最終確認画像確認依頼",
  product_start: "本製作開始連絡",
  shipment1: "発送通知",
  shipment2: "発送通知",
}

export default {
  components: {
    ModalToggler,
    InfoBlock,
  },
  props: {
    MailType: {
      type: String,
      required: true,
    },
    ReplaceValues: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: null,
    }
  },
  computed: {
    subject() {
      return this.makeTemplateStr("subject")
    },
    body() {
      return this.makeTemplateStr("body")
    },
    headLine() {
      return headLines[this.MailType]
    },
  },

  methods: {
    makeTemplateStr(place) {
      return Object.entries(this.ReplaceValues).reduce(
        (acc, [key, val]) => acc.replace(`{!${key}}`, val || ""),
        this.items[place],
      )
    },
    writeToClipboard() {
      navigator.clipboard
        .writeText(this.body)
        .then(() => {
          showToast(
            this,
            "success",
            "クリップボードにテキストをコピーしました",
            2000,
            false,
          )
        })
        .catch(() => {
          showToast(this, "error", "コピーできませんでした。", 2000, false)
        })
    },
  },
  async created() {
    const { ok, body } = await api.post(
      paths.ADMIN_MAIL_TEMPLATE,
      { mail_template_type: `mail_${this.MailType}` },
      this.$store.state.auth.token,
    )
    if (!ok) {
      console.log(body)
      return
    }

    this.items = body
  },
}
</script>

<style lang="scss" module>
.copy {
  position: absolute;
  top: 0;
  left: 0;
}
.mail {
  > p {
    white-space: pre-wrap;
  }
}
.close {
  top: 10px;
  right: 10px;
}
</style>
