var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.infoBlock, style: _vm.infoBlockStyle }, [
    _c(
      "div",
      {
        class: [
          _vm.$style.infoBlock_ttlWrapper,
          {
            [_vm.$style.center]: _vm.center !== undefined,
          },
        ],
      },
      [
        _c("h2", { class: _vm.$style.infoBlock_ttl }, [_vm._t("head")], 2),
        _vm._v(" "),
        _c("div", { class: _vm.$style.headRight }, [_vm._t("head-right")], 2),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          _vm.$style.infoBlock_Aria,
          { [_vm.$style.block]: _vm.block !== undefined },
        ],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }