<template>
  <div v-if="reports" :class="$style.wrapper">
    <template
      v-for="{
        detailsProduct,
        detailsOther,
        detailsReserved,
        taxRate,
        taxTotalPrice,
        subTotalPrice,
        discountPrice,
        totalPrice,
      } in reports"
    >
      <div :class="$style.container" :key="totalPrice">
        <section :class="$style.section1">
          <h1>見積書</h1>
          <p :class="$style.date" v-cloak>
            <input :class="$style.input" v-model="documentDate" />
          </p>
          <p :class="$style.date">商談番号：{{ $route.params.deal_id }}</p>
          <div :class="$style.head">
            <div :class="$style.left">
              <p v-cloak>
                <span :class="$style.inputable">
                  {{ customerName }}
                  <input v-model="customerName" />
                </span>
              </p>
              <p v-cloak>
                担当者：<span :class="$style.inputable">
                  {{ personName }}
                  <input v-model="personName" />
                </span>
                様
              </p>
            </div>
            <div :class="$style.right">
              <div :class="$style.logo_box">
                <p>
                  付箋・販促物製作専門店
                  <br />株式会社
                  <span :class="[$style.fwb, $style.fzb]">ＰＲ３９</span>
                </p>
                <div>
                  <Logo :width="70" />
                </div>
              </div>
              <p>
                〒812-0895 <br />福岡市博多区竹下1丁目11−23
                <br />TEL:092-409-6039
              </p>
              <img
                :class="$style.seal"
                src="@/images/company_seal.png"
                alt="株式会社ピーアールサンキュー"
                :ref="`img_company_seal`"
              />
            </div>
          </div>
          <span>本見積書有効期限：右上の発行日から1ヶ月</span>
          <p :class="$style.greeting">
            この度は弊社にお見積の機会を与えて下さいまして誠に有難うございます。
            <br />下記の通りお見積申し上げます。<br />宜しくお願いいたします。
          </p>
          <table>
            <colgroup>
              <col style="width: 8%" />
              <col style="width: 55%" />
              <col style="width: 10%" />
              <col style="width: 10%" />
              <col style="width: 17%" />
            </colgroup>
            <thead>
              <tr>
                <th>項目</th>
                <th>商品番号・商品名</th>
                <th>数量</th>
                <th>単価</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(detail, index) in detailsProduct"
                :key="`first-${index}`"
              >
                <td v-cloak>{{ detail.index }}</td>
                <td v-cloak>{{ detail.name }}</td>
                <td :class="$style.price" v-cloak>
                  {{ detail.amount | formatNumber }}
                </td>
                <td :class="$style.price" v-cloak>
                  {{ detail.price | formatDecimalPrice }}
                </td>
                <td :class="$style.price" v-cloak>
                  ¥{{ detail.amountPrice | formatPrice }}
                </td>
              </tr>
              <tr
                v-for="(detail, index) in detailsOther"
                :key="`second-${index}`"
              >
                <td v-cloak>{{ detail.index }}</td>
                <td v-cloak>{{ detail.name }}</td>
                <td :class="$style.price" v-cloak>
                  {{ detail.amount | formatNumber }}
                </td>
                <td :class="$style.price" v-cloak>
                  {{ detail.price | formatPrice }}
                </td>
                <td :class="$style.price" v-cloak>
                  ¥{{ detail.amountPrice | formatPrice }}
                </td>
              </tr>
              <tr
                v-for="(detail, index) in detailsReserved"
                :key="`third-${index}`"
              >
                <td></td>
                <td></td>
                <td :class="$style.price"></td>
                <td :class="$style.price"></td>
                <td :class="$style.price"></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td
                  :class="[$style.bor0, $style.vat]"
                  :rowspan="discountPrice == null ? 3 : 4"
                  :colspan="2"
                >
                  <div :class="$style.bikou">
                    <div>備考</div>
                    <AutoSizingTextarea></AutoSizingTextarea>
                  </div>
                </td>
                <td :class="$style.tac" colspan="2">小計</td>
                <td :class="[$style.price, $style.summary]" v-cloak>
                  ¥{{ (subTotalPrice + (discountPrice || 0)) | formatPrice }}
                </td>
              </tr>
              <tr>
                <td :class="$style.tac" colspan="2" v-cloak>
                  消費税（{{ taxRate }}%）
                </td>
                <td :class="[$style.price, $style.summary]" v-cloak>
                  ¥{{ taxTotalPrice | formatPrice }}
                </td>
              </tr>
              <tr v-if="discountPrice != null">
                <td :class="$style.tac" colspan="2" v-cloak>値引き</td>
                <td
                  :class="[$style.price, $style.summary]"
                  style="color: red"
                  v-cloak
                >
                  -¥{{ discountPrice | formatPrice }}
                </td>
              </tr>
              <tr>
                <td :class="$style.tac" colspan="2">税込み金額</td>
                <td :class="[$style.price, $style.summary]" v-cloak>
                  ¥{{ totalPrice | formatPrice }}
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            お振込み先
            <br />
            <span :class="$style.mr">銀行名／福岡銀行</span>
            <span :class="$style.mr">店名／本店営業部</span>
            <span :class="$style.mr">口座／普通</span>
            口座番号／6226598
            <br />名義／株式会社ＰＲ３９ カ）ピーアールサンキュー
            <br />恐れ入りますが、振込手数料は貴社にてご負担ください。
          </p>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment"
import Logo from "@/components/atoms/Logo.vue"
import api, { paths } from "@/utils/api.js"
import { formatPrice, formatDecimalPrice } from "@/utils/shared.js"
import { parseContract } from "@/helpers/ReportHelper.js"
import AutoSizingTextarea from "@/components/atoms/AutoSizingTextarea.vue"

export default {
  components: {
    Logo,
    AutoSizingTextarea,
  },
  data() {
    return {
      reports: null,
      customerName: null,
      personName: null,
      documentDate: moment(new Date(), "YYYY-MM-DDTHH:mm:ssZ").format(
        "YYYY/MM/DD",
      ),
    }
  },
  filters: {
    formatNumber: num => formatPrice(num, false, ""),
    formatPrice: price => formatPrice(price, false, ""),
    formatDecimalPrice: price => formatDecimalPrice(price, false, ""),
  },
  async created() {
    const { query, params } = this.$route
    const { body } = await api.post(
      `${paths.REPORT_QUOTATIONS}/${params.deal_id}`,
      { contract_numbers: query.contracts.split(",") },
      this.$store.state.auth.token,
    )

    this.reports = body.contract.map(c =>
      parseContract(c, this.$store.getters["defs/getOptionValueByKey"]),
    )

    this.customerName = body.customer ? body.customer.organization.name : ""
    this.personName = body.customer_person
      ? body.customer_person.last_name + " " + body.customer_person.first_name
      : ""

    document.title = `【見積書】${this.customerName}様_${this.documentDate}`
  },
}
</script>

<style lang="scss" module>
@include reportPirnt();
</style>
