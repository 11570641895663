exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".InputForm__wrapper--LAGLS {\n  font-weight: bold;\n  color: #707070;\n}\n.InputForm__label--2BxSU {\n  display: block;\n  margin-bottom: 10px;\n}\n.InputForm__input--1Igje {\n  display: block;\n  width: 100%;\n  height: 45px;\n  padding-left: 1em;\n  border: 1px solid #afafaf;\n  outline: none;\n}\n.InputForm__input--1Igje:focus {\n    background-color: #e3e3e3;\n}\n.InputForm__block--1Stkk {\n  display: block;\n}\n", "",{"version":3,"sources":["/client/src/components/molecules/InputForm.vue"],"names":[],"mappings":"AAKA;EAEE,iBAAiB;EACjB,cAAc;AAAA;AAGhB;EACE,cAAc;EACd,mBAAmB;AAAA;AAGrB;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;AAAA;AANf;IAQI,yBAAyB;AAAA;AAI7B;EACE,cAAc;AAAA","file":"InputForm.vue?vue&type=style&index=0&id=df0f1a68&lang=scss&module=true","sourcesContent":["\n                @import \"@/scss/_variables.scss\";\n                @import \"@/scss/_mixin_function.scss\";\n              \n\n.wrapper {\n  //font-size: 0.18rem;\n  font-weight: bold;\n  color: #707070;\n}\n\n.label {\n  display: block;\n  margin-bottom: 10px;\n}\n\n.input {\n  display: block;\n  width: 100%;\n  height: 45px;\n  padding-left: 1em;\n  border: 1px solid #afafaf;\n  outline: none;\n  &:focus {\n    background-color: #e3e3e3;\n  }\n}\n\n.block {\n  display: block;\n}\n"]}]);

// Exports
exports.locals = {
	"wrapper": "InputForm__wrapper--LAGLS",
	"label": "InputForm__label--2BxSU",
	"input": "InputForm__input--1Igje",
	"block": "InputForm__block--1Stkk"
};