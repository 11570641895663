var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [_c("DealingHeader")],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.items.contract, function (contract) {
            return _c(
              "div",
              { key: contract.id, class: _vm.$style.quotation },
              [
                _c(
                  "div",
                  { class: _vm.$style.quotation_head },
                  [
                    _c("p", { class: _vm.$style.quotation_head_name }, [
                      _vm._v("見積管理No"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        class: [
                          _vm.$style.quotation_head_item,
                          _vm.$style.small,
                        ],
                        attrs: {
                          to: `/admin/dealings/${contract.request_number}/dealing-contracts/${contract.contract.contract_no}`,
                        },
                      },
                      [_vm._v(_vm._s(contract.contract.contract_no))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.contractDetails[contract.id].readyMade.body.length !== 0
                  ? [
                      _c("h3", { class: _vm.$style.quotation_made }, [
                        _vm._v("既成品見積一覧"),
                      ]),
                      _vm._v(" "),
                      _c("InnerTable", {
                        attrs: {
                          head: _vm.contractDetails[contract.id].readyMade.head,
                          body: _vm.contractDetails[contract.id].readyMade.body,
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.contractDetails[contract.id].orderMade.body.length !== 0
                  ? [
                      _c("h3", { class: _vm.$style.quotation_made }, [
                        _vm._v("\n        オーダーメイド見積一覧\n      "),
                      ]),
                      _vm._v(" "),
                      _c("InnerTable", {
                        attrs: {
                          head: _vm.contractDetails[contract.id].orderMade.head,
                          body: _vm.contractDetails[contract.id].orderMade.body,
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }