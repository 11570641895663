<template>
  <div v-if="values !== null" :class="$style.wrapper">
    <DealingHeader :headerInfo="headerInfo" />
    <PageTitle>見積・注文管理</PageTitle>
    <AdminContractDetailOutLine
      :deal="items"
      v-bind="{ supplierOptions }"
      v-model="values"
      @input="watchIsEdited"
    />
    <AdminContractDetailMade
      v-model="values"
      v-bind="{ productGroup, discount }"
      @input="watchIsEdited"
    />
    <PrErrorText
      v-if="isSaveTried && !isIncludesMade"
      style="margin-bottom:20px;text-align:center;"
      >一つ以上の見積品目を作成してください</PrErrorText
    >
    <section name="price">
      <AdminContractDetailOption
        v-model="values"
        v-bind="{ discount }"
        @input="watchIsEdited"
      />
      <AdminContractDetailAmount
        :deal="items"
        v-model="values"
        v-bind="{ discount, taxs, values, isTotalMinus }"
        @input="watchIsEdited"
      />
    </section>
    <section name="buttons">
      <Button size="main" color="submit" @click="handleSaveAccepted"
        >保存</Button
      >

      <Button
        v-if="isNew"
        size="assist"
        color="cancel"
        @click="backToPreviousPage"
        >キャンセル</Button
      >

      <Button
        v-if="!isNew"
        size="assist"
        color="cancel"
        @click="handleClearClick"
        >クリア</Button
      >

      <QuotationDetailDialog
        v-if="!isNew && values.quantity2 == '0' && items.status < 10"
        :texts="{
          ttl: '確認',
          msg: '削除しますか？',
          yas: '削除する',
          no: 'キャンセル',
        }"
        @accept="handleDeleteAccept"
      >
        <template slot-scope="{ on }">
          <Button size="main" color="alert" v-on="on">削除</Button>
        </template>
      </QuotationDetailDialog>

      <QuotationDetailDialog
        v-if="!isNew && !isEdited"
        :texts="{
          ttl: isCopied ? 'コピー完了' : '確認',
          msg: isCopied
            ? `見積管理No${copyContractNo}としてコピーしました。編集画面へ移動しますか？`
            : `見積管理No${
                items.contract.contract.contract_no
              }をコピーしますか？。`,
          yas: isCopied ? '移動する' : 'コピーする',
          no: 'いいえ',
        }"
        @accept="handleCopyAccept"
        @refuse="isCopied = false"
        :no-close="!isCopied"
      >
        <template slot-scope="{ on }">
          <Button size="main" color="submit" v-on="on">この見積をコピー</Button>
        </template>
      </QuotationDetailDialog>
    </section>
  </div>
</template>

<script>
import DealingHeader from "@/components/molecules/DealingHeader.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import AdminContractDetailMade from "@/components/molecules/AdminContractDetailMade.vue"
import AdminContractDetailOutLine from "@/components/molecules/AdminContractDetailOutLine.vue"
import AdminContractDetailOption from "@/components/molecules/AdminContractDetailOption.vue"
import AdminContractDetailAmount from "@/components/molecules/AdminContractDetailAmount.vue"
import QuotationDetailDialog from "@/components/molecules/QuotationDetailDialog.vue"
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import {
  setValues,
  calcFinalAllTotalPrice,
} from "@/helpers/QuotationDetailViewHelper.js"
import {
  routeReplaceError,
  showToast,
  deepCopy,
  showErrorToast,
} from "@/utils/shared.js"

export default {
  components: {
    DealingHeader,
    PageTitle,
    PrErrorText,
    AdminContractDetailMade,
    AdminContractDetailOutLine,
    AdminContractDetailAmount,
    AdminContractDetailOption,
    QuotationDetailDialog,
    Button,
  },
  data() {
    return {
      items: null,
      productGroup: null,
      suppliers: null,
      taxs: null,
      headerInfo: null,
      values: null,
      copyContractNo: "",
      isCopied: false,
      isEdited: false,
      isSaveTried: false,
    }
  },
  computed: {
    supplierOptions() {
      return [
        { key: "", value: "未設定" },
        ...this.suppliers.map(({ id, user: { name } }) => ({
          key: id,
          value: name,
        })),
      ]
    },
    discount() {
      return Number(this.headerInfo.customer.discount)
    },
    isNew() {
      return this.$route.name === "adminContractNew"
    },
    isTotalMinus() {
      return (
        0 >
        calcFinalAllTotalPrice(
          this.values.contract.contract_details,
          Number(this.headerInfo.customer.discount),
        )
      )
    },
    isIncludesMade() {
      return this.values.contract.contract_details.some(({ category2 }) =>
        category2.endsWith("_made"),
      )
    },
  },
  methods: {
    async handleSaveAccepted() {
      const { isIncludesMade, isTotalMinus, isNew, values, items } = this
      const method = isNew ? "post" : "put"
      const path = isNew ? "" : `/${items.contract.contract.contract_no}`
      const postValues = deepCopy(values)

      const result = await this.$validator.validateAll().then(r => r)
      if (!result || isTotalMinus || !isIncludesMade) {
        this.isSaveTried = true
        return
      }

      postValues.contract.contract_details = postValues.contract.contract_details.map(
        cd => {
          if (String(cd.id).startsWith("new_")) {
            delete cd.id
          }
          return cd
        },
      )
      const loader = this.$loading.show()

      try {
        console.log("postValues", postValues)
        const { ok, body, errorMsg } = await api[method](
          `${paths.ADMIN_CONTRACTS}${path}`,
          postValues,
          this.$store.state.auth.token,
        )
        if (!ok) {
          if (body.slice(-3) === String(400)) {
            showErrorToast(this, body.slice(-3), errorMsg, errorMsg)
            return
          }
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        this.backOrStayPage(isNew, body)
      } finally {
        loader.hide()
      }
    },
    backOrStayPage(isNew, body) {
      if (isNew) {
        this.$router.replace(
          `/admin/dealings/${this.$route.params.deal_id}/contracts/${
            body.contract.request_key2
          }`,
        )
      }
      this.items = body
      this.values = setValues(body)
      this.isEdited = false
      this.isSaveTried = false
    },
    backToPreviousPage() {
      const froms = {
        adminContractDetail: "/admin/contracts",
        adminContractNew: `/admin/dealings/${this.$route.params.deal_id}`,
        adminDealingContractDetail: `/admin/dealings/${
          this.$route.params.deal_id
        }`,
        adminDealingDealingContractDetail: `/admin/dealings/${
          this.$route.params.deal_id
        }/dealing-contracts`,
        adminDealingCardContractDetail: "/admin/dealings/card",
      }
      this.$router.push(froms[this.$route.name])
    },
    async handleCopyAccept(close) {
      const { isCopied, $router, items, $store } = this
      if (isCopied) {
        $router.push(
          `/admin/contracts/${this.copyContractNo}/dealings/${
            items.request_number
          }/`,
        )
        this.copyContractNo = ""
        this.isCopied = false
        this.init()
        return
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.post(
          `${paths.ADMIN_CONTRACTS_COPY}/${
            items.contract.contract.contract_no
          }`,
          null,
          $store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          close()
          return
        }
        showToast(this, "success", "コピーしました。", 5000)
        this.copyContractNo = body.request_key2
        this.isCopied = true
      } finally {
        loader.hide()
      }
    },
    async handleDeleteAccept() {
      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.DELETE(
          `${paths.ADMIN_CONTRACTS}/${
            this.items.contract.contract.contract_no
          }`,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg, "削除に失敗しました。")
          return
        }
        this.backToPreviousPage()
      } finally {
        loader.hide()
      }
    },
    handleClearClick() {
      for (const field of this.$validator.fields) {
        field.reset()
        this.errors.remove(field.name)
      }
      this.values = setValues(this.items)
      this.isEdited = false
      this.isSaveTried = false
    },
    watchIsEdited() {
      this.isEdited = true
    },
    async init() {
      const loader = this.$loading.show()
      try {
        const { isNew, $store } = this
        const [
          { ok: pOk, body: pBody },
          { ok: tOk, body: tBody },
          { ok: sOk, body: sBody },
          { ok: iOk, body: iBody },
        ] = await Promise.all([
          api.get(paths.ADMIN_PRODUCT_CATEGORY_GROUP, $store.state.auth.token),
          api.get(paths.ADMIN_TAXES, $store.state.auth.token),
          api.get(paths.ADMIN_SUPPLIERS, $store.state.auth.token),
          isNew
            ? Promise.resolve({ ok: null, body: null })
            : api.get(
                `${paths.ADMIN_CONTRACTS}/${this.$route.params.contract_id}`,
                $store.state.auth.token,
              ),
        ])
        if (!pOk || !tOk || !sOk) {
          for (const body of [pBody, tBody, hBody]) {
            if (typeof body === "string") {
              routeReplaceError(this, body.slice(-3))
              return
            }
          }
        }

        const { ok: hOk, body: hBody } = await api.get(
          paths.ADMIN_DEALINGS_HEADER +
            "/" +
            (isNew ? this.$route.params.deal_id : iBody.request_number),
          this.$store.state.auth.token,
        )
        if (!hOk) {
          routeReplaceError(this, hBody.slice(-3))
        }

        this.taxs = tBody
        this.productGroup = pBody
        this.suppliers = sBody
        this.headerInfo = hBody

        if (isNew) {
          this.values = setValues()
          return
        }

        if (!iOk) {
          routeReplaceError(this, iBody.slice(-3))
          return
        }

        this.items = iBody
        this.values = setValues(iBody)
      } finally {
        loader.hide()
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 1600px;
  margin: 0 auto;
  > section {
    &[name="price"] {
      display: flex;
      justify-content: space-between;
      > * {
        width: 48%;
      }
    }
    &[name="buttons"] {
      margin: 30px 0;
      text-align: center;
      > * {
        display: inline-block;
      }
    }
  }
}
</style>
