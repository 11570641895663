var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formattedItems != null
    ? _c("div", { class: _vm.$style.wrapper }, [
        _c("div", { class: _vm.$style.totalCount }, [
          _c("table", { class: _vm.$style.totalTable }, [
            _c("tr", [
              _c("th", [_vm._v("Salesforce")]),
              _vm._v(" "),
              _c("td", {}, [_vm._v(_vm._s(_vm.totalCounter.sf))]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Google Drive")]),
              _vm._v(" "),
              _c("td", {}, [_vm._v(_vm._s(_vm.totalCounter.gd))]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("GCS(移行済み)")]),
              _vm._v(" "),
              _c("td", {}, [_vm._v(_vm._s(_vm.totalCounter.gcs))]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Unkown")]),
              _vm._v(" "),
              _c("td", {}, [_vm._v(_vm._s(_vm.totalCounter.unkown))]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("h1", { class: _vm.$style.title }, [_vm._v("画像データ移行")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.explain }, [
          _vm._m(0),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      ① 実行前にブラウザのクロスドメイン制約を一時的に無効にしてください。\n    "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      ②\n      ダウンロードボタンをクリックしてください。(ブラウザ依存のダウンロードディレクトリにファイルがダウンロードされます。)\n    "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      ③\n      すべてのダウンロードが完了したら、ファイル選択で、画像がダウンロードされたディレクトリを選択してください。\n    "
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("④ 移行開始ボタンをクリックしてください。")]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.operation }, [
          _c("input", {
            attrs: { type: "file", webkitdirectory: "" },
            on: { change: _vm.handleInputChange },
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "Button",
                {
                  attrs: { size: "sub", color: "submit" },
                  on: { click: _vm.handleDownload },
                },
                [_vm._v("ダウンロード")]
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  attrs: { size: "sub", color: "alert" },
                  on: { click: _vm.handleMigrate },
                },
                [_vm._v("移行開始")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.resultMessage != null
          ? _c("div", { class: _vm.$style.execResultMessage }, [
              _c("p", [_vm._v(_vm._s(_vm.resultMessage))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("table", { class: _vm.$style.tableDataList }, [
          _c("thead", { class: _vm.$style.header }, [_vm._m(1)]),
          _vm._v(" "),
          _c(
            "tbody",
            { class: _vm.$style.body },
            _vm._l(_vm.execItems, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(item.request_number))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.contract_detail_no))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.image_category))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.image_place))]),
                _vm._v(" "),
                _c("td", [_c("img", { attrs: { src: item.image_path } })]),
                _vm._v(" "),
                _c("td", { staticStyle: { "text-align": "left" } }, [
                  _vm._v(_vm._s(item.image_path)),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    item.isDownload != null
                      ? [
                          item.isDownload == -1
                            ? _c("v-icon", { attrs: { color: "orange" } }, [
                                _vm._v("remove"),
                              ])
                            : item.isDownload == 1
                            ? _c("v-icon", { attrs: { color: "green" } }, [
                                _vm._v("check_circle"),
                              ])
                            : item.isDownload == 0
                            ? _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v("cancel"),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    item.sendResult != null
                      ? [
                          item.sendResult.status < 0
                            ? _c("v-icon", { attrs: { color: "orange" } }, [
                                _vm._v("error"),
                              ])
                            : item.sendResult.status == 1
                            ? _c("v-icon", { attrs: { color: "green" } }, [
                                _vm._v("check_circle"),
                              ])
                            : item.sendResult.status == 0
                            ? _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v("cancel"),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    item.sendResult != null
                      ? [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.sendResult.message) +
                              "\n          "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("＜手順＞")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("商談No")]),
      _vm._v(" "),
      _c("th", [_vm._v("品目No")]),
      _vm._v(" "),
      _c("th", [_vm._v("種別")]),
      _vm._v(" "),
      _c("th", [_vm._v("格納されている場所")]),
      _vm._v(" "),
      _c("th", [_vm._v("画像")]),
      _vm._v(" "),
      _c("th", [_vm._v("画像URL")]),
      _vm._v(" "),
      _c("th", [_vm._v("ダウンロード結果")]),
      _vm._v(" "),
      _c("th", [_vm._v("送信結果")]),
      _vm._v(" "),
      _c("th"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }