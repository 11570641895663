<template>
  <div :class="$style.relatedInfo">
    <div>
      <p :class="$style.relatedInfo_p"><slot /></p>
      <div :class="$style.relatedInfo_infoAria">
        <div
          v-for="([name, val], idx) in infoList"
          :class="$style.relatedInfo_item"
          :key="idx"
        >
          <p :class="$style.relatedInfo_ttl">{{ name }}</p>
          <p :class="$style.relatedInfo_txt">{{ val }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infoList: Array,
  },
}
</script>

<style lang="scss" module>
.relatedInfo {
  width: 1000px;
  padding: 20px 20px;
  background-color: map-get($colors, white);
  &_p {
    font-size: 0.18rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &_infoAria {
    padding: 22px 0 0;
    display: flex;
    flex-wrap: wrap;
    background-color: map-get($colors, lightGray);
  }
  &_item {
    display: flex;
    align-items: center;
    padding: 0.8em 20px;
    &:nth-child(odd) {
      width: 55%;
    }
    &:nth-child(even) {
      width: 45%;
    }
  }
  &_ttl {
    width: 30%;
    color: map-get($colors, darkGlay);
  }
  &_txt {
    color: map-get($colors, black);
  }
}
</style>
