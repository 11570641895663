<template>
  <div :class="$style.infoBlock" :style="infoBlockStyle">
    <div
      :class="[
        $style.infoBlock_ttlWrapper,
        {
          [$style.center]: center !== undefined,
        },
      ]"
    >
      <h2 :class="$style.infoBlock_ttl">
        <slot name="head" />
      </h2>
      <div :class="$style.headRight"><slot name="head-right" /></div>
    </div>
    <div
      :class="[$style.infoBlock_Aria, { [$style.block]: block !== undefined }]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: null,
    center: null,
    width: {
      type: String,
      default: "1000px",
    },
    margin: {
      type: String,
      default: "0",
    },
  },
  inject: {
    $validator: "$validator",
  },
  computed: {
    infoBlockStyle() {
      return {
        width: this.width,
        margin: this.margin,
      }
    },
  },
}
</script>

<style lang="scss" module>
.infoBlock_ttlWrapper {
  min-height: 60px;
  display: flex;
  padding: 5px 0 5px 40px;
  background-color: map-get($colors, lightYellow);
  align-items: center;
  justify-content: space-between;
  &.center {
    min-height: auto;
    padding: 5px 0 5px;
    justify-content: center;
  }
}

.headRight {
  display: flex;
  align-items: center;
}

.infoBlock {
  margin: 20px 0;
  background-color: map-get($colors, white);
  &_ttl {
    font-size: 0.18rem;
  }
  &_Aria {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    &.block {
      display: block;
    }
  }
}
</style>
