var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "700px", block: "" } },
    [
      _c("template", { slot: "head" }, [_vm._v("取引先 / 取引先責任者")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "head-right" },
        [
          _c(
            "ModalToggler",
            {
              attrs: { scroll: false },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ toggle }) {
                    return [
                      _vm.customers !== null && _vm.customerPersons !== null
                        ? _c(
                            "InfoBlock",
                            {
                              attrs: { width: "800px", block: "", center: "" },
                            },
                            [
                              _c("template", { slot: "head" }, [
                                _vm._v("\n            取引先変更\n          "),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { class: _vm.$style.flex },
                                [
                                  _c("PrSelect", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      label: "取引先",
                                      "pr-row": [100],
                                      value: _vm.selectedCustomer,
                                      options: _vm.customerOptions,
                                      name: "selectedCustomer",
                                      validate: "required",
                                      searchable: true,
                                    },
                                    on: { input: _vm.handleCustomerInput },
                                  }),
                                  _vm._v(" "),
                                  _c("PrSelect", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      label: "取引先責任者",
                                      "pr-row": [100],
                                      value: _vm.selectedCustomerPerson,
                                      options: _vm.customerPersonOptions,
                                      name: "selectedCustomerPerson",
                                      validate: "required",
                                      searchable: true,
                                    },
                                    on: {
                                      input: _vm.handleCustomerPersonInput,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "Button",
                                        {
                                          staticStyle: { "margin-top": "20px" },
                                          attrs: {
                                            size: "main",
                                            color: "submit",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleSaveClick(toggle)
                                            },
                                          },
                                        },
                                        [_vm._v("変更")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "Button",
                                        {
                                          attrs: {
                                            size: "assist",
                                            color: "cancel",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCancelClick(
                                                toggle
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("キャンセル")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "button" },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { margin: "0 20px 0 0" },
                      attrs: { round: "", outline: "", color: "indigo" },
                    },
                    [_vm._v("変更")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.rowrow }, [
        _c(
          "div",
          [
            _c("p", [_vm._v("取引先名")]),
            _vm._v(" "),
            !_vm.items.customer
              ? [_vm._v("\n        未設定\n      ")]
              : [
                  _c(
                    "router-link",
                    {
                      class: _vm.$style.name,
                      attrs: {
                        to: `/admin/dealings/${_vm.items.request_number}/customers/${_vm.items.customer.id}`,
                      },
                    },
                    [_vm._v(_vm._s(_vm.items.customer.organization.name))]
                  ),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("p", [_vm._v("取引先責任者名")]),
            _vm._v(" "),
            !_vm.items.customer_person
              ? [_vm._v("\n        未設定\n      ")]
              : [
                  _c(
                    "router-link",
                    {
                      class: _vm.$style.name,
                      attrs: {
                        to: `/admin/dealings/${_vm.items.request_number}/customer-persons/${_vm.items.customer_person.id}`,
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.items.customer_person.last_name +
                              " " +
                              _vm.items.customer_person.first_name
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }