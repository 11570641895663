var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.Phases },
    [
      _vm._l(_vm.phases, function ({ key, value, status }) {
        return [
          _c("DialogProvider", {
            key: key,
            class: _vm.$style.phase,
            attrs: {
              texts: {
                ttl: "確認",
                msg: _vm.setDialogMsg(key),
                yas: "はい",
                no: "いいえ",
              },
            },
            on: {
              accept: function ($event) {
                return _vm.handleChangePhase(key)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ on }) {
                    return _c("PhaseBox", {
                      style:
                        key == _vm.items.status ? { cursor: "default" } : null,
                      attrs: { text: value, status: status },
                      nativeOn: {
                        click: function ($event) {
                          return (() => {
                            if (key != _vm.items.status) {
                              on.click($event)
                            }
                          }).apply(null, arguments)
                        },
                      },
                    })
                  },
                },
              ],
              null,
              true
            ),
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }