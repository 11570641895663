<template>
  <label :class="$style.wapper">
    <span :class="$style.button">{{ labels[0] }}</span>
    <input
      type="file"
      @change="handleInputChange"
      :accept="accept"
      ref="input"
    />
    <span :class="$style.fileName"
      >{{ fileName ? fileName : labels[1] }}
      <v-icon
        v-if="fileName !== ''"
        color="white"
        :class="$style.clear"
        @click.stop.prevent="handleClearClick"
        >clear</v-icon
      >
    </span>
  </label>
</template>

<script>
import { showErrorToast } from "@/utils/shared.js"

export default {
  props: {
    accept: {
      type: String,
    },
    acceptType: {
      type: Array,
    },
    korean: null,
  },
  data() {
    return {
      fileName: "",
    }
  },
  created() {
    console.log("accept=", this.accept)
  },
  computed: {
    labels() {
      const jp = ["参照", "ファイルが選択されていません"]
      const kr = ["참조", "파일이 선택되어 있지 않습니다"]
      return this.korean === undefined ? jp : kr
    },
  },
  methods: {
    handleInputChange(e) {
      console.log("ファイル", e.target.files)
      const files = e.target.files
      if (files.length === 0) {
        return
      }

      if (this.acceptType) {
        if (
          !this.acceptType.some(t => files[0].name.toLowerCase().endsWith(t))
        ) {
          showErrorToast(
            this,
            "error",
            null,
            `${this.acceptType.join(
              "/",
            )}ファイル以外はアップロードできません。`,
            "JP",
            5000,
          )
          return
        }
      }

      if (files[0].size > 200000000) {
        showErrorToast(
          this,
          "error",
          null,
          `200メガバイト以上のファイル以外はアップロードできません。`,
          "JP",
          5000,
        )
        return
      }

      this.fileName = files[0].name
      this.$emit("update:file", files[0])
      this.$emit("change", files[0])
    },
    handleClearClick() {
      this.$refs.input.value = ""
      this.fileName = ""
      this.$emit("update:file", null)
      this.$emit("change", null)
      this.$emit("clear")
    },
  },
  watch: {
    $attrs(newval) {
      if (newval.file === null) {
        this.fileName = ""
      }
    },
  },
}
</script>

<style lang="scss" module>
.wapper {
  display: flex;
  > input {
    display: none;
  }
}

.button {
  width: 50px;
  height: 32px;
  margin-right: 5px;
  display: block;
  line-height: 32px;
  text-align: center;
  font-size: 0.15rem;
  background-color: map-get($colors, glay);
}

.fileName {
  display: inline-block;
  position: relative;
  flex-grow: 1;
  height: 32px;
  line-height: 32px;
  padding-left: 0.5em;
  font-size: 0.13rem;
  border: 1px solid map-get($colors, glay);
  white-space: nowrap;
  text-align: left !important;
  //overflow-x: hidden;
}

.clear {
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  background-color: map-get($colors, darkGlay);
  opacity: 1;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
}
</style>
