var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [
            _vm._v("価格表" + _vm._s(_vm.isNew ? "新規作成" : "詳細")),
          ]),
          _vm._v(" "),
          _c(
            "InfoBlock",
            [
              _c("template", { slot: "head" }, [_vm._v("価格表概要")]),
              _vm._v(" "),
              _c("InfoList", {
                ref: "infoList",
                attrs: { list: _vm.outlineList },
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("RelatedInfo", { attrs: { infoList: _vm.relatedInfo } }, [
            _vm._v("関連情報"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.buttons_center },
            [
              _c(
                "Button",
                {
                  attrs: { color: "submit", size: "main" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSaveButtonClick.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("編集内容を保存する")]
              ),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "Button",
                    {
                      attrs: { color: "cancel", size: "assist" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.initValues.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("クリア")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }