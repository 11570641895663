var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.$style.alert,
      on: {
        mouseover: function ($event) {
          _vm.isActive = true
        },
        mouseleave: function ($event) {
          _vm.isActive = false
        },
      },
    },
    [
      _c("IconExclamation", {
        attrs: {
          width: "27px",
          height: "27px",
          "data-iconexclamation": "true",
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.msgs.length))]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isActive,
                expression: "isActive",
              },
            ],
            class: _vm.$style.msgs,
          },
          [
            _vm._l(_vm.msgs, function (msg, i) {
              return [
                _c(
                  "li",
                  { key: i, class: _vm.$style.alert_msgs_msg },
                  [
                    _c("v-icon", { attrs: { color: "#ff0000" } }, [
                      _vm._v("error_outline"),
                    ]),
                    _c("span", [_vm._v(_vm._s(msg))]),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }