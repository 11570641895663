var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.contactBlock }, [
    _c("h2", { class: _vm.$style.contactBlock_ttl }, [
      _vm._v("\n    お客様コンタクト情報\n  "),
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style.contactBlock_table }, [
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("見積確認")]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.contactBlock_content },
          [
            _c(
              "div",
              { class: _vm.$style.contactBlock_firstRow },
              [
                _c("PrSelect", {
                  attrs: {
                    value: _vm.items.quantity2,
                    options:
                      _vm.$store.getters["defs/getOptions"]("CheckEstimates"),
                  },
                  on: { input: _vm.handleContruntStatusChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("ModalMailTemplate", {
              staticStyle: { display: "none" },
              attrs: {
                "mail-type": "estimates",
                "replace-values": _vm.mailConvertCustomerInfo,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("注文")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contactBlock_content }, [
          _c("div", { class: _vm.$style.contactBlock_firstRow }, [
            _c("span", { class: _vm.$style.contactBlock_text }, [
              _vm._v(
                _vm._s(_vm.items.order_status === 0 ? "未確定" : "確定済")
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [
          _vm._v("入稿データ"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.contactBlock_content },
          [
            _c("div", { class: _vm.$style.contactBlock_firstRow }, [
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$store.getters["defs/getOptionValueByKey"](
                        "DraftStatus_PR",
                        _vm.draft.request_key3
                      )
                    ) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              Number(_vm.draft.request_key4) > 1
                ? _c("p", { staticStyle: { "margin-left": "20px" } }, [
                    _vm.draft.appendix_name
                      ? _c("a", { attrs: { href: _vm.draft.appendix_name } }, [
                          _vm._v("ダウンロード"),
                        ])
                      : _c("span", [_vm._v("入稿済み")]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            Number(_vm.draft.request_key3) === 2
              ? [
                  _c("DialogProvider", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: "入稿データをOKにしますか",
                        yas: "はい",
                        no: "いいえ",
                      },
                    },
                    on: {
                      accept: function ($event) {
                        return _vm.handleOKNGclick(true)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return _c(
                              "span",
                              _vm._g(
                                {
                                  class: [
                                    _vm.$style.contactBlock_minButton,
                                    _vm.$style.ok,
                                  ],
                                },
                                on
                              ),
                              [_vm._v("OK")]
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      948207370
                    ),
                  }),
                  _vm._v(" "),
                  _c("DialogProvider", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: "入稿データをNGにしますか",
                        yas: "はい",
                        no: "いいえ",
                      },
                    },
                    on: {
                      accept: function ($event) {
                        return _vm.handleOKNGclick(false)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return _c(
                              "span",
                              _vm._g(
                                {
                                  class: [
                                    _vm.$style.contactBlock_minButton,
                                    _vm.$style.ng,
                                  ],
                                },
                                on
                              ),
                              [_vm._v("NG")]
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      442059786
                    ),
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            Number(_vm.draft.request_key3) > 0 &&
            Number(_vm.draft.request_key3) < 4
              ? _c(
                  "div",
                  { class: _vm.$style.superZeroButton },
                  [
                    _c(
                      "ModalToggler",
                      {
                        attrs: { closeButton: true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ toggle }) {
                                return _c(
                                  "div",
                                  { class: _vm.$style.draft },
                                  [
                                    _c("p", [_vm._v("入稿データアップロード")]),
                                    _vm._v(" "),
                                    _c("InputFile", {
                                      attrs: { "accept-type": ["zip", "ai"] },
                                      on: {
                                        change: function ($event) {
                                          _vm.file = $event
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "Button",
                                      {
                                        attrs: {
                                          size: "main",
                                          color: "submit",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDraftUpdate(toggle)
                                          },
                                        },
                                      },
                                      [_vm._v("保存")]
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3295177808
                        ),
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              slot: "button",
                              flat: "",
                              color: "primary",
                            },
                            slot: "button",
                          },
                          [_vm._v("アップロード")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.draft.request_key3 === "1"
                      ? _c("DialogProvider", {
                          attrs: {
                            texts: {
                              ttl: "確認",
                              msg: "入稿したことにしますか？",
                              yas: "はい",
                              no: "いいえ",
                            },
                          },
                          on: {
                            accept: function ($event) {
                              return _vm.handleOKNGclick(true, true)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ on }) {
                                  return _c(
                                    "v-btn",
                                    _vm._g(
                                      { attrs: { flat: "", color: "primary" } },
                                      on
                                    ),
                                    [_vm._v("入稿済み")]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1464553026
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [
          _vm._v("最終確認画像"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.contactBlock_content },
          [
            _c("div", { class: _vm.$style.contactBlock_firstRow }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$store.getters["defs/getOptionValueByKey"](
                      "VerificationStatus_PR",
                      _vm.verification_image.request_key3
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            Number(_vm.verification_image.request_key3) > 0 &&
            Number(_vm.items.quantity4) === 0
              ? _c(
                  "div",
                  { class: _vm.$style.superZeroButton },
                  [
                    _c("DialogProvider", {
                      attrs: {
                        texts: {
                          ttl: "確認",
                          msg: "入稿前に戻ります｡よろしいですか？",
                          yas: "はい",
                          no: "いいえ",
                        },
                      },
                      on: {
                        accept: function ($event) {
                          return _vm.handleForceResetClick()
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ on }) {
                              return _c(
                                "v-btn",
                                _vm._g(
                                  { attrs: { flat: "", color: "primary" } },
                                  on
                                ),
                                [_vm._v("リセット")]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2915190325
                      ),
                    }),
                    _vm._v(" "),
                    _vm.verification_image.request_key4 === "1"
                      ? _c("DialogProvider", {
                          attrs: {
                            texts: {
                              ttl: "確認",
                              msg: "お客様確認したことにしますか？",
                              yas: "はい",
                              no: "いいえ",
                            },
                          },
                          on: {
                            accept: function ($event) {
                              return _vm.handleForceVerificationOKClick()
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ on }) {
                                  return _c(
                                    "v-btn",
                                    _vm._g(
                                      { attrs: { flat: "", color: "primary" } },
                                      on
                                    ),
                                    [_vm._v("強制OK")]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1592505994
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("ModalMailTemplate", {
              staticStyle: { display: "none" },
              attrs: {
                "mail-type": "verification_image",
                "replace-values": _vm.mailConvertCustomerInfo,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("請求書")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contactBlock_content }, [
          _c("div", { class: _vm.$style.contactBlock_firstRow }, [
            _vm.items.order_status === 0
              ? _c("div", { class: _vm.$style.contactBlock_firstRow }, [
                  _vm._v("\n            注文未確定\n          "),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$style.contactBlock_firstRow,
                          _vm.$style.gap10,
                        ],
                      },
                      [
                        _vm._l(_vm.invoiceStatus, function (status) {
                          return [
                            _c("DialogProvider", {
                              key: status.value,
                              attrs: {
                                texts: {
                                  ttl: "確認",
                                  msg: _vm.hasInvoiceStatus(
                                    _vm.items.quantity5,
                                    status.value
                                  )
                                    ? `${status.label}のチェックをはずしますか？`
                                    : `${status.label}にしますか?`,
                                  yas: "はい",
                                  no: "いいえ",
                                },
                              },
                              on: {
                                accept: function ($event) {
                                  _vm.handleSendInvoiceStatusChange(
                                    _vm.hasInvoiceStatus(
                                      _vm.items.quantity5,
                                      status.value
                                    )
                                      ? -1 * status.value
                                      : status.value
                                  )
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ on }) {
                                      return _c(
                                        "span",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            "!click": function ($event) {
                                              $event.stopPropagation()
                                              return on.click.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("PrCheckbox", {
                                            staticStyle: {
                                              "pointer-events": "none",
                                            },
                                            attrs: {
                                              "before-label": status.label,
                                              value: _vm.hasInvoiceStatus(
                                                _vm.items.quantity5,
                                                status.value
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "Button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "assist", color: "submit" },
                        on: {
                          click: function ($event) {
                            return _vm.handlePrint("print-invoice")
                          },
                        },
                      },
                      [_vm._v("請求書印刷")]
                    ),
                  ],
                  1
                ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [
          _vm._v("本製作開始"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.contactBlock_content },
          [
            _c("div", { class: _vm.$style.contactBlock_firstRow }, [
              _c("div", { class: _vm.$style.contactBlock_text }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.items.quantity4 === 0 ? "未対応" : "本製作開始済"
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("ModalMailTemplate", {
              staticStyle: { display: "none" },
              attrs: {
                "mail-type": "product_start",
                "replace-values": _vm.mailConvertProductStartInfo,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("発送")]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.contactBlock_content },
          [
            _c(
              "div",
              { class: _vm.$style.contactBlock_firstRow },
              [
                _c("PrSelect", {
                  attrs: {
                    value: _vm.items.quantity3,
                    options:
                      _vm.$store.getters["defs/getOptions"]("SendStatus"),
                  },
                  on: { input: _vm.handleSendStatusChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Button",
              {
                attrs: { size: "assist", color: "submit" },
                on: {
                  click: function ($event) {
                    return _vm.handlePrint("print-delivery")
                  },
                },
              },
              [_vm._v("納品書印刷")]
            ),
            _vm._v(" "),
            _c(
              "Button",
              {
                attrs: { size: "assist", color: "submit" },
                on: {
                  click: function ($event) {
                    return _vm.handlePrint("print-receipt")
                  },
                },
              },
              [_vm._v("領収書印刷")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }