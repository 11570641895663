<template>
  <div :class="$style.contactBlock">
    <h2 :class="$style.contactBlock_ttl">
      仕入先コンタクト情報
    </h2>
    <div :class="$style.contactBlock_table">
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">見積入力</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <PrSelect
              :value="items.quantity1"
              :options="$store.getters['defs/getOptions']('InputEstimates')"
              @input="handleContruntStatusChange"
            />
          </div>
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">見積</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <span :class="$style.contactBlock_text"
              >{{ items.estimate_status }} 入力済</span
            >
          </div>
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">入稿データ</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            {{
              $store.getters["defs/getOptionValueByKey"](
                "DraftStatus_PR",
                draft.request_key3,
              )
            }}
          </div>
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">最終確認画像</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <span>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "VerificationStatus_PR",
                  verification_image.request_key3,
                )
              }}
            </span>
            <template v-if="verification_image.request_key3 === '2'">
              <DialogProvider
                :texts="{
                  ttl: '確認',
                  msg: '最終確認画像をOKにしますか',
                  yas: 'はい',
                  no: 'いいえ',
                }"
                @accept="
                  handleOKNGclick('verification_image', {
                    request_key4: '1',
                    request_key3: '3',
                    appendix_status: '2',
                  })
                "
              >
                <span
                  slot-scope="{ on }"
                  :class="[$style.contactBlock_minButton, $style.ok]"
                  v-on="on"
                  >OK</span
                >
              </DialogProvider>
              <DialogProvider
                :texts="{
                  ttl: '確認',
                  msg: '最終確認画像をNGにしますか',
                  yas: 'はい',
                  no: 'いいえ',
                }"
                @accept="handleRefuseVerificationImage"
              >
                <span
                  slot-scope="{ on }"
                  :class="[$style.contactBlock_minButton, $style.ng]"
                  v-on="on"
                  >NG</span
                >
              </DialogProvider>
            </template>
          </div>
          <PrImg
            v-if="verification_image.appendix_status > 1"
            :class="$style.contactBlock_img"
            :src="verification_image.appendix_name"
          />
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">発注書</p>
        <div :class="$style.contactBlock_content">
          <div v-if="!orderedContract" :class="$style.contactBlock_firstRow">
            注文未確定
          </div>
          <div v-else-if="orderedContract && !orderedContract.supplier">
            仕入先未確定
          </div>
          <div v-else :class="$style.contactBlock_firstRow">
            <template
              v-if="
                ['1', '2', '3', '5'].includes(
                  orderedContract.supplier.supplier_code,
                )
              "
            >
              <div style="width:120px;">
                <DialogProvider
                  style="justify-content:flex-start;"
                  :texts="{
                    ttl: '確認',
                    msg:
                      items.quantity4 === 0
                        ? '公開しますか？'
                        : '公開停止しますか?',
                    yas: 'はい',
                    no: 'いいえ',
                  }"
                  @accept="
                    handleOrderSheetRelease(items.quantity4 === 0 ? '1' : '0')
                  "
                >
                  <span
                    slot-scope="{ on }"
                    @click.capture.stop="
                      e => {
                        if (items.status >= 12) {
                          return
                        }
                        on.click(e)
                      }
                    "
                    style="cursor: pointer;"
                  >
                    <PrCheckbox
                      before-label="公開する"
                      :value="items.quantity4"
                      style="pointer-events: none;"
                      :disabled="items.status >= 12"
                    />
                  </span>
                </DialogProvider>
              </div>
              <Anker
                style="display:inline-block;margin-left:10px;"
                :to="ordersheetPath"
                target="_blank"
                >内容確認</Anker
              >
            </template>
            <template v-else
              >対象外</template
            >
          </div>
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">生産/商品写真</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <span>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "ProduceStatus",
                  product_image.request_key3 || "0",
                )
              }}
            </span>
            <template v-if="product_image.request_key3 == '3'">
              <DialogProvider
                :texts="{
                  ttl: '確認',
                  msg: '商品画像をOKにしますか',
                  yas: 'はい',
                  no: 'いいえ',
                }"
                @accept="
                  handleOKNGclick('product_image', {
                    request_key3: '4',
                    appendix_status: '2',
                  })
                "
              >
                <span
                  slot-scope="{ on }"
                  :class="[$style.contactBlock_minButton, $style.ok]"
                  v-on="on"
                  >OK</span
                >
              </DialogProvider>
              <DialogProvider
                :texts="{
                  ttl: '確認',
                  msg: '商品画像をNGにしますか',
                  yas: 'はい',
                  no: 'いいえ',
                }"
                @accept="
                  handleOKNGclick('product_image', {
                    request_key3: '6',
                    appendix_status: '1',
                  })
                "
              >
                <span
                  slot-scope="{ on }"
                  :class="[$style.contactBlock_minButton, $style.ng]"
                  v-on="on"
                  >NG</span
                >
              </DialogProvider>
            </template>
          </div>
          <PrImg
            v-if="
              product_image.request_key3 == '3' ||
                product_image.request_key3 == '4'
            "
            :class="$style.contactBlock_img"
            :src="product_image.appendix_name"
          />
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">発送情報</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            {{
              $store.getters["defs/getOptionValueByKey"](
                "GoodsIssue",
                items.delivery_status,
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import Anker from "@/components/atoms/Anker.vue"
import PrImg from "@/components/atoms/PrImg.vue"
import api, { paths } from "@/utils/api.js"
import { showErrorToast, deepCopy } from "@/utils/shared.js"

export default {
  components: {
    DialogProvider,
    PrImg,
    PrSelect,
    PrCheckbox,
    Anker,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  computed: {
    requestAppendices() {
      return this.items.request_appendices.reduce((acc, ra) => {
        if (ra.appendix_category === "draft") {
          acc = { ...acc, draft: ra.appendix_status } // 入稿データ
        }
        if (ra.appendix_category === "verification_image") {
          acc = {
            ...acc,
            verification_image: ra.appendix_status, // 最終確認画像
            appendix_name: ra.appendix_name, // 最終確認画像
          }
        }
        if (ra.appendix_category === "product_image") {
          acc = { ...acc, project_key4: ra.request_key4 } // 生産
        }
        return acc
      }, {})
    },
    draft() {
      return this.items.request_appendices.find(
        ({ appendix_category }) => appendix_category === "draft",
      )
    },
    verification_image() {
      return this.items.request_appendices.find(
        ({ appendix_category }) => appendix_category === "verification_image",
      )
    },
    product_image() {
      return this.items.request_appendices.find(
        ({ appendix_category }) => appendix_category === "product_image",
      )
    },
    orderedContract() {
      return this.items.contract.find(({ status }) => status === 1)
    },
    ordersheetPath() {
      const supplierCode = this.$store.getters["defs/getOptionValueByKey"](
        "SupplierCode",
        this.orderedContract.supplier.supplier_code,
      )
      return `/admin/dealings/${
        this.items.request_number
      }/ordersheet/${supplierCode.slice(0, 2).toLowerCase()}`
    },
    values() {
      const {
        request_key1,
        request_number,
        lock_version,
        request_appendices,
      } = this.items
      return {
        request_key1,
        request_number,
        lock_version,
        request_appendices: request_appendices.map(
          ({ id, request_number, lock_version, appendix_category }) => ({
            id,
            request_number,
            lock_version,
            appendix_category,
          }),
        ),
      }
    },
  },
  methods: {
    async putApi(putValues) {
      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_DEALINGS_CONTACT}/${putValues.request_number}`,
          putValues,
          this.$store.state.auth.token,
        )
        if (!ok) {
          console.log("ADMIN_DEALINGS_CONTACTが失敗")
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        this.$emit("updated", body)
      } finally {
        loader.hide()
      }
    },
    async handleContruntStatusChange(quantity1) {
      const putValues = deepCopy(this.values)
      const statusText = this.$store.getters["defs/getOptionValueByKey"](
        "InputEstimates",
        quantity1,
      )

      await this.putApi({
        ...putValues,
        quantity1,
        description: `見積入力が[${statusText}]に更新されました。`,
      })
    },
    handleOKNGclick(category, obj) {
      const putValues = deepCopy(this.values)
      const description = [
        null,
        null,
        null,
        "最終確認画像に[OK]を出しました。",
        "商品画像に[OK]を出しました。",
        null,
        "商品画像に[NG]を出しました。",
      ][Number(obj.request_key3)]
      putValues.request_appendices = putValues.request_appendices.map(ra =>
        ra.appendix_category === category ? { ...ra, ...obj } : ra,
      )

      this.putApi({ ...putValues, description })
    },
    handleRefuseVerificationImage() {
      const putValues = deepCopy(this.values)
      putValues.request_appendices = putValues.request_appendices.map(ra => {
        if (ra.appendix_category === "verification_image") {
          return {
            ...ra,
            request_key4: "0",
            request_key3: "0",
            appendix_status: "0",
          }
        }
        if (ra.appendix_category === "draft") {
          return {
            ...ra,
            request_key4: "1",
            request_key3: "1",
            appendix_status: "0",
          }
        }
        return ra
      })

      this.putApi({
        ...putValues,
        description: "最終確認画像に[NG]を出しました。",
      })
    },
    async handleOrderSheetRelease(statusNum) {
      let putValues = deepCopy(this.values)
      const description = [
        "発注書を[非公開]にしました。",
        "発注書を[公開]しました。",
      ][Number(statusNum)]

      const loader = this.$loading.show()

      try {
        if (statusNum === "1") {
          const { ok, body, errorMsg } = await api.put(
            paths.ADMIN_DEALINGS_PROGRESS + "/" + this.items.request_number,
            {
              request_number: this.items.request_number,
              request_key1: this.items.request_key1,
              lock_version: this.items.lock_version,
              status: 11,
            },
            this.$store.state.auth.token,
          )

          if (!ok) {
            if (body.slice(-3) === String(400)) {
              showErrorToast(this, body.slice(-3), errorMsg, errorMsg)
              return
            }
            showErrorToast(this, body.slice(-3), errorMsg)
            return
          }

          const {
            request_key1,
            request_number,
            lock_version,
            request_appendices,
          } = body

          putValues = {
            request_key1,
            request_number,
            lock_version,
            request_appendices: request_appendices.map(
              ({ id, request_number, lock_version, appendix_category }) => ({
                id,
                request_number,
                lock_version,
                appendix_category,
              }),
            ),
          }

          putValues.request_appendices = putValues.request_appendices.map(ra =>
            ra.appendix_category === "product_image"
              ? { ...ra, request_key3: statusNum }
              : ra,
          )
        }

        const { ok, body, errorMsg } = await api.put(
          paths.ADMIN_PUBLISH_PURCHASE + "/" + this.items.request_number,
          {
            ...putValues,
            quantity4: statusNum,
            description,
            supplier_email: this.orderedContract.supplier.user.email,
            data_name: this.orderedContract.description,
          },
          this.$store.state.auth.token,
        )

        if (!ok) {
          if (body.slice(-3) === String(400)) {
            showErrorToast(this, body.slice(-3), errorMsg, errorMsg)
            return
          }
          showErrorToast(this, body.slice(-3), errorMsg)
        }
        this.$emit("updated", body)
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include contactBlock();
</style>
