<template>
  <div v-if="items" :class="$style.wrapper">
    <PageTitle>ご注文履歴</PageTitle>
    <div v-if="items.length !== 0" :class="$style.select">
      <p>注文月を選択</p>
      <TheSelect v-model="selectedMonth" :options="options" background="#fff" />
    </div>
    <div v-else :class="$style.nodata">
      ご注文履歴がありません
    </div>
    <div v-if="items" :class="$style.historys">
      <CustomerHistoryInfoBox
        v-for="deal in filteredItems"
        :deal="deal"
        :key="deal.id"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import TheSelect from "@/components/molecules/TheSelect.vue"
import CustomerHistoryInfoBox from "@/components/molecules/CustomerHistoryInfoBox.vue"
import { routeReplaceError } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PageTitle,
    TheSelect,
    CustomerHistoryInfoBox,
  },
  data() {
    return {
      items: null,
      selectedMonth: 0,
    }
  },
  computed: {
    orderedMonths() {
      return this.items.reduce((acc, deal) => {
        const date = new Date(deal.contract.contract.contracted_date)
        const key =
          String(date.getFullYear()) +
          String(date.getMonth() + 1).padStart(2, "0")

        if (acc[key]) {
          acc[key] = [...acc[key], deal.request_number]
        } else {
          acc[key] = [deal.request_number]
        }

        return acc
      }, {})
    },
    options() {
      return Object.keys(this.orderedMonths).reduce(
        (acc, m) => [
          ...acc,
          {
            key: m,
            value: m.slice(0, 4) + "年" + m.slice(-2) + "月の注文",
          },
        ],
        [{ key: 0, value: "すべての注文" }],
      )
    },
    filteredItems() {
      const { selectedMonth, items, orderedMonths } = this
      return selectedMonth == 0
        ? items
        : orderedMonths[selectedMonth].map(rn =>
            items.find(({ request_number }) => request_number === rn),
          )
    },
  },
  methods: {
    handleMonthClick(month) {
      this.targetMonth = month
    },
  },
  async created() {
    const { ok, body } = await api.get(
      paths.CUSTOMER_ORDERED_HISTORIES,
      this.$store.state.auth.token,
    )
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }
    this.items = body
    this.$store.dispatch("customer/setReOrderInfo", null)
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.select {
  width: 320px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: map-get($colors, lightGray);
  border-radius: 5px;
  > p {
    font-weight: bold;
    font-size: 0.18rem;
    width: 180px;
  }
}
.historys {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.nodata {
  font-size: 0.3rem;
  padding: 100px;
  border-radius: 20px;
  background-color: white;
}
</style>
