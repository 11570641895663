<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>取引先{{ isNew ? "新規作成" : "詳細" }}</PageTitle>

    <div :class="$style.row">
      <InfoBlock width="49%" block>
        <template slot="head"
          >取引先概要</template
        >
        <div :class="$style.forms">
          <PrInput
            label="取引先名"
            v-model="values.name"
            :pr-row="[30]"
            name="name"
            validate="required"
          />
          <PrInput
            label="略称"
            v-model="values.abbreviated_name"
            :pr-row="[30]"
          />
          <PrInput
            label="取引先コード"
            v-model="values.customer_code"
            :pr-row="[30]"
          />
          <PrSelect
            label="業種"
            v-model="values.industry"
            :pr-row="[30]"
            :options="$store.getters['defs/getOptions']('Industry')"
          />
          <PrInput label="Webサイト" v-model="values.hp_url" :pr-row="[30]" />
          <PrCheckbox
            before-label="料金後払"
            v-model="values.deferred_payment"
            :pr-row="[30]"
          />
          <PrInput
            label="割引率(%)"
            v-model="values.discount"
            :pr-row="[30]"
            name="discount"
            validate="decimal:1|between:0,100"
          />
          <PrInput label="説明" v-model="values.description" :pr-row="[30]" />
          <PrInput label="特記事項" v-model="values.notices" :pr-row="[30]" />
          <PrCheckbox
            before-label="特定顧客"
            v-model="values.specific"
            :pr-row="[30]"
          />
        </div>
      </InfoBlock>

      <InfoBlock width="49%" block>
        <template slot="head"
          >所在地</template
        >
        <div :class="$style.forms">
          <PrCheckbox
            before-label="会社所在地不明"
            v-model="values.billing_unknown"
            :pr-row="[30]"
          />
          <PrInput
            label="郵便番号"
            v-model="values.zip_code"
            :pr-row="[30, 50]"
            name="zip_code"
            validate="zipcode"
          >
            <ButtonSerchZipCode
              slot="after"
              :zipcode="values.zip_code"
              @click="handleZipSearch"
            />
          </PrInput>
          <PrInput label="都道府県" v-model="values.location" :pr-row="[30]" />
          <PrInput label="市区郡" v-model="values.address1" :pr-row="[30]" />
          <PrInput
            label="町名・番地"
            v-model="values.address2"
            :pr-row="[30]"
          />
          <PrInput label="建物名" v-model="values.building" :pr-row="[30]" />
          <PrInput
            label="電話番号"
            v-model="values.phone_number"
            :pr-row="[30]"
            name="phone_number"
            validate="telephone"
          />
          <PrInput
            label="FAX"
            v-model="values.fax"
            :pr-row="[30]"
            name="fax"
            validate="fax"
          />
        </div>
      </InfoBlock>
    </div>

    <template v-if="!isNew">
      <RelatedInfo :infoList="purchaseInfo" style="margin-bottom:20px;"
        >購買情報</RelatedInfo
      >
      <InfoBlock style="margin-bottom:20px;">
        <template slot="head"
          >取引先更新履歴</template
        >
        <InnerTable
          :head="histories.head"
          :body="histories.body"
          :link-contain="true"
        />
        <p v-if="histories.body.length === 0" :class="$style.noParson">
          履歴がありません
        </p>
      </InfoBlock>

      <InfoBlock style="margin-bottom:20px;">
        <template slot="head"
          >取引先責任者</template
        >
        <InnerTable
          :head="parson.head"
          :body="parson.body"
          :link-contain="true"
        />
        <p v-if="parson.body.length === 0" :class="$style.noParson">
          登録された取引先責任者はありません
        </p>
      </InfoBlock>

      <InfoBlock style="margin-bottom:20px;">
        <template slot="head"
          >商談</template
        >
        <InnerTable
          :head="dealings.head"
          :body="dealings.body"
          :link-contain="true"
        />
        <p v-if="dealings.body.length === 0" :class="$style.noParson">
          商談実績はありません
        </p>
      </InfoBlock>
      <RelatedInfo :infoList="relaedInfo" style="margin-bottom:20px;"
        >関連情報</RelatedInfo
      >
    </template>

    <div :class="$style.buttons_center">
      <Button color="submit" size="main" @click.native="handleSaveButtonClick"
        >編集内容{{ isNew ? "で追加する" : "を保存する" }}</Button
      >
      <Button
        v-if="!isNew"
        color="cancel"
        size="assist"
        @click.native="initValues"
        >クリア</Button
      >
      <DialogProvider
        v-if="!isNew"
        :texts="{
          ttl: '確認',
          msg: `本当に${items.organization.name}を削除しますか？`,
          yas: 'はい',
          no: 'いいえ',
        }"
        @accept="handleDelete"
      >
        <Button slot-scope="{ on }" color="alert" size="assist" v-on="on"
          >削除</Button
        >
      </DialogProvider>
    </div>
  </div>
</template>

<script>
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import ButtonSerchZipCode from "@/components/molecules/ButtonSerchZipCode.vue"
import Button from "@/components/atoms/Button.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import RelatedInfo from "@/components/molecules/RelatedInfo.vue"
import InnerTable from "@/components/molecules/InnerTable.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"

import api, { paths } from "@/utils/api.js"
import {
  showToast,
  showErrorToast,
  routeReplaceError,
  getDate,
  getDateTime,
  formatPrice,
} from "@/utils/shared.js"

export default {
  components: {
    DialogProvider,
    ButtonSerchZipCode,
    PrCheckbox,
    PrInput,
    PrSelect,
    Button,
    PageTitle,
    RelatedInfo,
    InnerTable,
    InfoBlock,
  },
  data() {
    return {
      isReady: false,
      items: null,
      values: null,
      isZipCodeElmMounted: false,
    }
  },
  computed: {
    isNew() {
      return this.$route.name === "adminCustomerNew"
    },
    parson() {
      return {
        head: [
          "取引先責任者名",
          "役職",
          "メールアドレス",
          "電話番号",
          "更新日時",
        ],
        body: this.items.customer_persons.map(
          ({
            id,
            first_name,
            last_name,
            title,
            user,
            phone,
            updated_at_datetime,
          }) => [
            `|>/admin/customer-persons/${id}|>${last_name} ${first_name}`,
            title,
            user != null ? user.email : "",
            phone,
            getDateTime(updated_at_datetime),
          ],
        ),
      }
    },
    dealings() {
      return {
        head: [
          "商談名",
          "商談管理No",
          "注文日",
          "進捗状況",
          "見積総額",
          "注文総額(税込)",
        ],
        body: this.items.customer_dealings.map(
          ({
            request_name,
            request_number,
            contracted_date,
            status,
            estimated_total_amount,
            total_order_amount,
          }) => [
            `|>/admin/dealings/${request_number}|>${request_name}`,
            request_number,
            getDate(contracted_date),
            this.$store.getters["defs/getOptionValueByKey"]("Progress", status),
            estimated_total_amount || "未注文",
            total_order_amount || "未注文",
          ],
        ),
      }
    },
    relaedInfo() {
      const { inserted_at, updated_at, inserted, updated } = this.items
      return [
        ["作成者", inserted.name],
        ["作成日", getDate(inserted_at)],
        ["更新者", updated.name],
        ["更新日", getDate(updated_at)],
      ]
    },
    purchaseInfo() {
      const {
        accumulated_amount,
        purchase_frequency,
        last_order_date,
        r_f_m_point,
        customer_rank,
      } = this.items
      return [
        ["累計購買金額", formatPrice(accumulated_amount)],
        ["購買頻度", purchase_frequency],
        ["最終注文日", getDate(last_order_date, "取引実績なし")],
        ["RFMPoint", r_f_m_point],
        ["顧客ランク", customer_rank],
      ]
    },
    histories() {
      return {
        head: [
          "取引先名",
          "郵便番号",
          "都道府県",
          "市区郡",
          "町名・番地",
          "建物名",
          "更新日時",
        ],
        body: this.items.customer_histories.map(history => [
          history.name,
          history.zip_code,
          history.location,
          history.address1,
          history.address2,
          history.building,
          getDateTime(history.updated_at),
        ]),
      }
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const result = await this.$validator.validateAll().then(result => result)
      if (!result) {
        return
      }

      const method = this.isNew ? "post" : "put"
      const path = this.isNew ? "" : `/${this.$route.params.customer_id}`

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api[method](
          `${paths.ADMIN_CUSTOMERS}${path}`,
          { ...this.values },
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        if (this.isNew) {
          this.$router.replace(`/admin/customers/${body.id}`)
          this.items = body
          this.init()
        } else if (this.$route.name === "adminDealingCustomerDetail") {
          this.$router.push(`/admin/dealings/${this.$route.params.dealings_id}`)
        } else {
          this.items = body
          this.init()
        }
      } finally {
        loader.hide()
      }
    },
    async handleDelete() {
      const { ok, body, errorMsg } = await api.DELETE(
        paths.ADMIN_CUSTOMERS + "/" + this.items.id,
        this.$store.state.auth.token,
      )
      if (!ok) {
        showErrorToast(this, body.slice(-3), errorMsg, "削除に失敗しました")
        return
      }

      showToast(
        this,
        "success",
        `「${this.items.organization.name}」を削除しました。`,
        3000,
      )

      this.$router.replace(
        this.$route.name === "adminDealingCustomerDetail"
          ? `/admin/dealings/${this.$route.params.dealings_id}`
          : `/admin/customers`,
      )
    },
    initValues() {
      const { items, isNew } = this
      this.values = {
        name: isNew ? "" : items.organization.name,
        abbreviated_name: isNew ? "" : items.abbreviated_name || "",
        industry: isNew ? "0" : items.industry || "0",
        customer_code: isNew ? "" : items.customer_code || "",
        hp_url: isNew ? "" : items.organization.hp_url || "",
        deferred_payment: isNew ? "" : items.deferred_payment || "",
        billing_unknown: isNew ? "" : items.billing_unknown || "",
        discount: isNew ? "" : items.discount || "",
        zip_code: isNew ? "" : items.organization.addresses[0].zip_code || "",
        location: isNew ? "" : items.organization.addresses[0].location || "",
        address1: isNew ? "" : items.organization.addresses[0].address1 || "",
        address2: isNew ? "" : items.organization.addresses[0].address2 || "",
        building: isNew ? "" : items.building || "",
        phone_number: isNew ? "" : items.organization.phone_number || "",
        fax: isNew ? "" : items.fax || "",
        description: isNew ? "" : items.description || "",
        notices: isNew ? "" : items.notices || "",
        specific: isNew ? "0" : items.specific || "",
      }
      this.$validator.reset()
    },
    handleZipSearch({ ok, address }) {
      if (!ok) {
        return
      }
      this.values = { ...this.values, ...address }
    },
    async init() {
      if (this.isNew) {
        this.initValues()
        return
      }

      const { ok, body } = await api.get(
        `${paths.ADMIN_CUSTOMERS}/${this.$route.params.customer_id}`,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
      this.initValues()
    },
  },
  async created() {
    await this.init()
    this.isReady = true
  },
}
</script>

<style lang="scss" module>
@include dbDetailView();

.noParson {
  width: 100%;
  padding: 1em 0;
  text-align: center;
  background-color: map-get($colors, lightlightGray);
}

.row {
  width: 1000px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.forms {
  > * {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
