import * as types from "@/store/mutation-types"

const state = {
  itemDealing: null, // 商談一覧
  selectedRequestId: null, // 選択中の商談ID
  selectedEstimateNo: null, // 選択中の見積No
  orderInfo: null, // 注文情報(アドレスなど)
  reOrderInfo: null, // 再注文情報
}

const getters = {
  getCustomerName: state => {
    if (state.itemDealing === null) {
      return "未設定"
    }
    const name =
      state.itemDealing.customer_person.last_name +
      " " +
      state.itemDealing.customer_person.first_name
    return name
  },
  getCustomerAddress: state => {
    if (state.itemDealing === null) {
      return "未設定"
    }
    const customer = state.itemDealing.customer
    const customerPerson = state.itemDealing.customer_person
    const data = {
      company: customer.organization.name,
      division: customerPerson.department,
      name: customerPerson.last_name + " " + customerPerson.first_name,
      tel: customerPerson.phone,
      address1: customerPerson.address.address1,
      address2: customerPerson.address.address2,
      location: customerPerson.address.location,
      zip_code: customerPerson.address.zip_code,
      building: customerPerson.building,
    }

    return data
  },
  getCustomerInfo: state => {
    if (state.itemDealing === null) {
      return "未設定"
    }
    const customer = state.itemDealing.customer
    const data = {
      specific: customer.specific,
    }
    return data
  },
}

const actions = {
  setDealing: ({ commit }, payload) => {
    commit(types.SET_CUSTOMER_DEALING, payload)
  },
  setRequestId: ({ commit }, payload) => {
    commit(types.SET_CUSTOMER_REQUEST_ID, payload)
  },
  setEstimateNo: ({ commit }, payload) => {
    commit(types.SET_CUSTOMER_ESTIMATE_NO, payload)
  },
  setOrderInfo: ({ commit }, payload) => {
    commit(types.SET_CUSTOMER_ORDER_INFO, payload)
  },
  clearDealing: ({ commit }) => {
    commit(types.SET_CUSTOMER_DEALING, null)
  },
  clearRequestId: ({ commit }) => {
    commit(types.SET_CUSTOMER_REQUEST_ID, null)
  },
  clearEstimateNo: ({ commit }) => {
    commit(types.SET_CUSTOMER_ESTIMATE_NO, null)
  },
  clearOrderInfo: ({ commit }) => {
    commit(types.SET_CUSTOMER_ORDER_INFO, null)
  },
  setReOrderInfo: ({ commit }, payload) => {
    commit(types.SET_CUSTOMER_REORDER_INFO, payload)
  },
}

const mutations = {
  [types.SET_CUSTOMER_DEALING](state, itemDealing) {
    state.itemDealing = itemDealing
  },
  [types.SET_CUSTOMER_REQUEST_ID](state, id) {
    state.selectedRequestId = id
  },
  [types.SET_CUSTOMER_ESTIMATE_NO](state, no) {
    state.selectedEstimateNo = no
  },
  [types.SET_CUSTOMER_ORDER_INFO](state, order) {
    state.orderInfo = order
  },
  [types.SET_CUSTOMER_REORDER_INFO](state, reOrder) {
    state.reOrderInfo = reOrder
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
