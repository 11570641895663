exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".UserPasswordEditDone__wrapper--5xqq5 {\n  min-height: 100vh;\n  background-color: #f6f6f6;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.UserPasswordEditDone__content--2I7Hv {\n  margin-top: 50px;\n}\n.UserPasswordEditDone__title--2ut5M {\n  padding: 55px 0;\n}\n.UserPasswordEditDone__formAria--21KKg {\n  width: 800px;\n  margin: 0 auto;\n  padding: 90px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #fff;\n}\n.UserPasswordEditDone__formAria_text--bwFWu {\n    padding: 30px;\n}\n.UserPasswordEditDone__formAria_text--bwFWu > span {\n      font-weight: bold;\n}\n", "",{"version":3,"sources":["/client/src/components/molecules/UserPasswordEditDone.vue"],"names":[],"mappings":"AAKA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AAAA;AAErB;EACE,gBAAgB;AAAA;AAGlB;EACE,eAAe;AAAA;AAGjB;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;AAAA;AAEtB;IACE,aAAa;AAAA;AADd;MAGG,iBAAiB;AAAA","file":"UserPasswordEditDone.vue?vue&type=style&index=0&id=27e404a4&lang=scss&module=true","sourcesContent":["\n                @import \"@/scss/_variables.scss\";\n                @import \"@/scss/_mixin_function.scss\";\n              \n\n.wrapper {\n  min-height: 100vh;\n  background-color: #f6f6f6;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.content {\n  margin-top: 50px;\n}\n\n.title {\n  padding: 55px 0;\n}\n\n.formAria {\n  width: 800px;\n  margin: 0 auto;\n  padding: 90px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #fff;\n\n  &_text {\n    padding: 30px;\n    > span {\n      font-weight: bold;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"wrapper": "UserPasswordEditDone__wrapper--5xqq5",
	"content": "UserPasswordEditDone__content--2I7Hv",
	"title": "UserPasswordEditDone__title--2ut5M",
	"formAria": "UserPasswordEditDone__formAria--21KKg",
	"formAria_text": "UserPasswordEditDone__formAria_text--bwFWu"
};