var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { class: _vm.$style.table }, [
    _c("thead", { class: _vm.$style.table_head }, [
      _c(
        "tr",
        { class: _vm.$style.table_head_tr },
        _vm._l(_vm.head, function (th, i) {
          return _c("th", { key: i, class: _vm.$style.table_head_tr_th }, [
            _vm._v("\n        " + _vm._s(th) + "\n      "),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      { class: _vm.$style.table_body },
      [
        !_vm.linkContain
          ? _vm._l(_vm.body, function (tr, i) {
              return _c(
                "tr",
                { key: i, class: _vm.$style.table_body_tr },
                _vm._l(tr, function (td, j) {
                  return _c(
                    "td",
                    { key: j, class: _vm.$style.table_body_tr_td },
                    [_vm._v("\n          " + _vm._s(td) + "\n        ")]
                  )
                }),
                0
              )
            })
          : _vm._l(_vm.body, function (tr, i) {
              return _c(
                "tr",
                { key: i, class: _vm.$style.table_body_tr },
                _vm._l(tr, function (td, j) {
                  return _c(
                    "td",
                    { key: j, class: _vm.$style.table_body_tr_td },
                    [
                      td && td.startsWith("|>")
                        ? _c(
                            "router-link",
                            { attrs: { to: td.split(`|>`)[1] } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(td.split(`|>`)[2]) +
                                  "\n          "
                              ),
                            ]
                          )
                        : [
                            _vm._v(
                              "\n            " + _vm._s(td) + "\n          "
                            ),
                          ],
                    ],
                    2
                  )
                }),
                0
              )
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }