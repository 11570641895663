const days = ["月", "火", "水", "木", "金", "土", "日・祝"]

const extractAddress = (shipping, from, to) => ({
  [to + "_address1"]: {
    zip_code: shipping[from + 1].zip_code,
    location: shipping[from + 1].location,
    address1: shipping[from + 1].address1,
    address2: shipping[from + 1].address2,
  },
  [to + "_address2"]: {
    location: shipping[from + 2].location,
    address1: shipping[from + 2].address1,
    address2: shipping[from + 2].address2,
  },
  [to + "_address3"]: {
    address1: shipping[from + 3].address1,
    address2: shipping[from + 3].address2,
  },
})

const checkAddressEquality = (shipping, keyAStart, keyBStart) => {
  return Object.entries(shipping).reduce((acc, [key, val]) => {
    if (key == `${keyAStart}1`) {
      return (
        acc &&
        Object.entries(val).reduce((iacc, [iKey, iVal]) => {
          if (
            !["zip_code", "location", "address1", "address2"].includes(iKey)
          ) {
            return iacc
          }
          return iacc && iVal === shipping[keyBStart + "1"][iKey]
        }, true)
      )
    }
    if (key == `${keyAStart}2`) {
      return (
        acc &&
        Object.entries(val).reduce((iacc, [iKey, iVal]) => {
          if (!["location", "address1", "address2"].includes(iKey)) {
            return iacc
          }
          return iacc && iVal === shipping[keyBStart + "2"][iKey]
        }, true)
      )
    }
    if (key == `${keyAStart}3`) {
      return (
        acc &&
        Object.entries(val).reduce((iacc, [iKey, iVal]) => {
          if (!["address1", "address2"].includes(iKey)) {
            return iacc
          }
          return iacc && iVal === shipping[keyBStart + "3"][iKey]
        }, true)
      )
    }
    return acc
  }, true)
}

const mergeAddress = (shipping, address) => ({
  ...shipping,
  ...Object.entries(address).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: { ...shipping[key], ...val },
    }),
    {},
  ),
})

const makeReceiveDates = note1 =>
  note1
    ? days.map(day => (note1.includes(day) ? "1" : "0"))
    : ["0", "0", "0", "0", "0", "0", "0"]

const setValues = ({
  request_key1,
  request_key2,
  request_key3,
  user_id,
  request_appendices,
  delivery,
  shipping,
  contract,
}) => ({
  request_key1,
  request_key2,
  request_key3,
  user_id,
  reusable: "0",
  request_date2: "",
  request_appendices: request_appendices.map(val => ({
    request_key1: val.request_key1,
    request_key3: val.request_key3,
    request_key4: val.request_key4,
    appendix_category: val.appendix_category,
    appendix_name: val.appendix_name,
    appendix_status: val.appendix_status,
  })),
  delivery: {
    request_key1: delivery.request_key1,
    quantity2: delivery.quantity2,
    note1: delivery.note1,
  },
  shipping: {
    request_key1: shipping.request_key1,
    quantity1: shipping.quantity1,
    quantity2: shipping.quantity2,
    quantity3: shipping.quantity3,
    quantity4: shipping.quantity4,
    quantity5: shipping.quantity5,
    quantity6: shipping.quantity6,
    note1: shipping.note1,
    note2: shipping.note2,
    note3: shipping.note3,
    status: shipping.status,
    ...Object.entries(shipping).reduce((acc, [key, val]) => {
      if (key.endsWith("address1"))
        acc[key] = {
          id: val.id,
          zip_code: val.zip_code,
          location: val.location,
          address1: val.address1,
          address2: val.address2,
          lock_version: val.lock_version,
        }
      if (key.endsWith("address2"))
        acc[key] = {
          id: val.id,
          location: val.location,
          address1: val.address1,
          address2: val.address2,
          lock_version: val.lock_version,
        }
      if (key.endsWith("address3"))
        acc[key] = {
          id: val.id,
          address1: val.address1,
          address2: val.address2,
          lock_version: val.lock_version,
        }
      return acc
    }, {}),
  },
  contract: {
    request_key1: contract.request_key1,
    request_key4: contract.request_key4,
    request_date2: contract.request_date2,
    request_date3: contract.request_date3,
    quantity3: contract.quantity3,
    quantity4: contract.quantity4 || "0",
    quantity5: contract.quantity5,
    note1: contract.note1 || "",
    contract: {
      shipping_fee: contract.contract.shipping_fee,
      tax_amount: contract.contract.tax_amount,
      total_amount: contract.contract.total_amount,
      expiration_date: contract.contract.expiration_date,
      contract_details: contract.contract.contract_details.map(val => ({
        amount: val.amount,
        price: val.price,
        purchase_amount: val.purchase_amount,
        merchandise_cost: val.merchandise_cost,
        description: val.description,
        name: val.name,
        category1: val.category1,
        category2: val.category2,
        category3: val.category3,
        category4: val.category4,
        item_code: val.item_code,
        model_number: val.model_number,
        jan_code: val.jan_code,
        thumbnail: val.thumbnail,
        image_url: val.image_url,
        size1: val.size1,
        size2: val.size2,
        size3: val.size3,
        size4: val.size4,
        weight1: val.weight1,
        weight2: val.weight2,
        weight3: val.weight3,
        weight4: val.weight4,
        datetime1: val.datetime1,
        delivery_area: val.delivery_area,
        //manufacturer: val.manufacturer,
        color: val.color,
        tax_category: val.tax_category,
      })),
    },
  },
})

export {
  setValues,
  days,
  extractAddress,
  mergeAddress,
  makeReceiveDates,
  checkAddressEquality,
}
