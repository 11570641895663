<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="headers"
    :filter-func="filterFunc"
    page-id="defectives"
  >
    <template slot="page-title">
      不良品一覧
    </template>

    <div
      slot="filter"
      slot-scope="{
        filter,
        handleSelectInput,
        handleTextInput,
        handleFlagOn,
        filterClass,
      }"
      :class="filterClass"
    >
      <PrSelect
        label="発注先"
        :pr-row="[100]"
        :value="filter.supplier"
        :options="SupplierOptions"
        @input="handleSelectInput($event, 'supplier')"
      />
      <PrInput
        label="キーワード"
        :pr-row="[100, 85]"
        :value="keywordHandler.value"
        @input="keywordHandler.input"
        @keydown.native.enter="keywordHandler.keydown"
        @keyup.native.enter="
          keywordHandler.keyup($event, handleFlagOn, handleTextInput, filter)
        "
        style="justify-content:space-between;"
      >
        <SearchButton
          slot="after"
          @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="keywordHandler.isOn"
        />
      </PrInput>
    </div>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import { getDate, routeReplaceError } from "@/utils/shared.js"
import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PrInput,
    PrSelect,
    SearchButton,
    IndexTableProvider,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"]("defectives")
    return {
      items: null,
      keywordHandler: newWordSearchHandler("keyword", filter),
      headers: [
        {
          text: "不良品管理No",
          value: "request_key2",
          link: ({ request_key2 }) => `/admin/defectives/${request_key2}`,
          width: 12,
        },

        { text: "関連商談名", value: "request.request_name", width: 27 },
        { text: "不良商品", value: "contract_detail.name", width: 9 },
        {
          text: "発注先",
          value: value => {
            const { supplier } = value
            if (!supplier) {
              return "未設定"
            }
            const { supplier_code } = supplier
            return this.$store.getters["defs/getOptionValueByKey"](
              "SupplierCode",
              supplier_code,
            )
          },
          width: 7,
        },
        {
          text: "対応期限",
          value: ({ request_date2 }) => getDate(request_date2),
          width: 9,
        },
        {
          text: "不良種別",
          value: value => {
            const { quantity2 } = value
            if (!quantity2) {
              return "未設定"
            }
            return this.$store.getters["defs/getOptionValueByKey"](
              "DefectiveReason",
              String(quantity2),
            )
          },
          width: 9,
        },
        { text: "不良詳細", value: "note1", width: 36 },
      ],
      SupplierOptions: [
        { key: "すべて", value: "すべて" },
        ...this.$store.getters["defs/getOptions"]("SupplierCode").map(sc => ({
          ...sc,
          key: sc.value,
        })),
      ],
    }
  },
  methods: {
    filterFunc: ({ supplier, keyword }) => item => {
      let test = true

      if (supplier !== "すべて") {
        test = item[3] === supplier
      }

      test = keywordFilter(keyword, test, item)

      return test
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const { ok, body } = await api.get(
        paths.ADMIN_DEFECTIVES,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>
