var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "290" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v("確認"),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", [_vm._v("削除しますか？")]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "indigo darken-3", flat: "" },
                              on: { click: _vm.deleteUser },
                            },
                            [_vm._v("OK")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "indigo darken-3", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_vm._v("キャンセル")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "table",
            { class: _vm.$style.table },
            [
              _c("tr", { class: _vm.$style.head }, [
                _c("td", { class: _vm.$style.no }, [_vm._v("No.")]),
                _vm._v(" "),
                _c("td", { class: _vm.$style.name }, [
                  _vm._v("表示名（名前）"),
                ]),
                _vm._v(" "),
                _c("td", { class: _vm.$style.mail }, [
                  _vm._v("メールアドレス"),
                ]),
                _vm._v(" "),
                _c("td", { class: _vm.$style.authority }, [_vm._v("権限")]),
                _vm._v(" "),
                _c("td", { class: _vm.$style.edit }),
              ]),
              _vm._v(" "),
              _vm._l(_vm.items, function (item, index) {
                return _c("tr", { key: index, class: _vm.$style.body }, [
                  _c(
                    "td",
                    [
                      _c(
                        "Button",
                        {
                          attrs: { color: "alert", size: "assist" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(item.id)
                            },
                          },
                        },
                        [_vm._v("削除")]
                      ),
                      _vm._v(" "),
                      _c("span", { class: _vm.$style.pl10 }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", {}, [_vm._v(_vm._s(item.name))]),
                  _vm._v(" "),
                  _c("td", {}, [_vm._v(_vm._s(item.email))]),
                  _vm._v(" "),
                  _c("td", {}, [_vm._v(_vm._s(_vm.toRoleString(item.role)))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "Anker",
                        { attrs: { to: "/admin/users/" + item.id } },
                        [_vm._v("編集する")]
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.newButton },
            [
              _c(
                "Button",
                {
                  attrs: { color: "submit", size: "main" },
                  on: { click: _vm.handleNew },
                },
                [_vm._v("新しいユーザーを追加する")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }