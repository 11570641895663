var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "100%", block: "", margin: "0" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    納品情報\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "発送予定日",
              "pr-row": [30],
              value: _vm.value.delivery.request_date2,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.request_date2")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "納品希望日",
              "pr-row": [30],
              value: _vm.value.request_date2,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "request_date2")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrSelect", {
            attrs: {
              name: "quantity1",
              label: "納品形態",
              "pr-row": [30],
              value: _vm.value.delivery.quantity1,
              options: _vm.$store.getters["defs/getOptions"]("Delivery"),
              validate: "required",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.quantity1")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrCheckbox", {
            attrs: {
              "before-label": "発送代行有無",
              "pr-row": [30],
              value: _vm.value.delivery.quantity2,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.quantity2")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "お届け予定日",
              "pr-row": [30],
              value: _vm.value.delivery.request_date3,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.request_date3")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", [_vm._v("受取可能曜日")]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { width: "70%" } },
          _vm._l(
            ["月", "火", "水", "木", "金", "土", "日・祝"],
            function (label, i) {
              return _c("PrCheckbox", {
                key: label,
                staticStyle: { margin: "0 10px 5px 0" },
                attrs: { "before-label": label, value: _vm.days[i] },
                on: {
                  input: function ($event) {
                    return _vm.handleDaysInput($event, i)
                  },
                },
              })
            }
          ),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrSelect", {
            attrs: {
              label: "時間",
              "pr-row": [30],
              value: _vm.value.delivery.note2,
              options: _vm.$store.getters["defs/getOptions"]("Times"),
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.note2")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrSelect", {
            attrs: {
              label: "納品書有無",
              "pr-row": [30],
              value: _vm.value.delivery.quantity3,
              options: _vm.$store.getters["defs/getOptions"]("DeliveryNote"),
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.quantity3")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrCheckbox", {
            attrs: {
              "before-label": "ヤマトタイムサービス",
              "pr-row": [30],
              value: _vm.value.delivery.quantity4,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.quantity4")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrTextarea", {
            attrs: {
              label: "伝票番号",
              "pr-row": [30],
              value: _vm.value.delivery.note3,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "delivery.note3")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", { class: _vm.$style.distribution_item_info_row_name }, [
          _vm._v("\n      自動発送メール\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.distribution_item_info_row_txt },
          [
            _vm.deal.contract.find(({ status }) => status === 1).supplier
              ? [
                  _c("ModalMailTemplate", {
                    attrs: {
                      "mail-type":
                        _vm.value.contract.quantity4 === 1
                          ? "shipment2"
                          : "shipment1",
                      "replace-values": _vm._f("setMailTemplateValue")(
                        _vm.deal
                      ),
                    },
                  }),
                ]
              : [_vm._v("発注先を設定してください")],
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }