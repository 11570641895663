<template>
  <InfoBlock width="350px" margin="0" block>
    <template slot="head"
      >発注先</template
    >
    <template v-if="!orderdContract">
      <p :class="$style.not">注文未確定</p>
    </template>
    <template v-else-if="orderdContract && !orderdContract.supplier">
      <p :class="$style.not">
        発注先未確定
      </p>
    </template>
    <template v-else>
      <router-link
        :to="
          `/admin/dealings/${items.request_number}/suppliers/${
            orderdContract.supplier.id
          }`
        "
        :class="$style.name"
      >
        {{
          $store.getters["defs/getOptionValueByKey"](
            "SupplierCode",
            orderdContract.supplier.supplier_code,
          )
        }}
      </router-link>
    </template>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"

export default {
  components: {
    InfoBlock,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orderdContract() {
      return this.items.contract.find(({ status }) => status === 1)
    },
  },
}
</script>

<style lang="scss" module>
.name {
  display: block;
  background-color: map-get($colors, lightlightGray);
  color: map-get($colors, blue);
  text-align: center;
  padding: 5px 0;
}
.not {
  text-align: center;
  padding: 5px 0;
  background-color: map-get($colors, lightlightGray);
}
</style>
