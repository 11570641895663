var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", { class: _vm.$style.wrapper }, [
        _c("h1", { class: _vm.$style.pageTtl }, [_vm._v("データ入稿")]),
        _vm._v(" "),
        _c("p", { class: _vm.$style.userName }, [
          _vm._v(_vm._s(_vm.customerName) + "様"),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.block }, [
          _c("h2", { class: _vm.$style.block_ttl }, [_vm._v("入稿データ")]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.block_container }, [
            _c("div", { class: _vm.$style.upload }, [
              _c(
                "div",
                { class: _vm.$style.upload_form },
                [
                  _c("InputFile", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      file: _vm.uploadFile,
                      "accept-type": ["zip", "ai"],
                    },
                    on: {
                      "update:file": function ($event) {
                        _vm.uploadFile = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "PrErrorText",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.saveTried && _vm.uploadFile === null,
                          expression: "saveTried && uploadFile === null",
                        },
                      ],
                      staticStyle: { width: "100%" },
                    },
                    [_vm._v("ファイルを選択してください。")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { class: _vm.$style.upload_how }, [
                _vm._v("データ入稿の方法"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  class: [_vm.$style.explain, _vm.$style.dark, _vm.$style.rice],
                },
                [
                  _vm._v(
                    "\n          ①\n          必要なデータは1つのフォルダにまとめてフォルダごと圧縮してください。\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  class: [_vm.$style.explain, _vm.$style.dark, _vm.$style.rice],
                },
                [
                  _vm._v(
                    "\n          ② ファイル名、フォルダ名は半角英数字で制作してください。\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  class: [_vm.$style.explain, _vm.$style.dark, _vm.$style.rice],
                },
                [
                  _vm._v(
                    "\n          ③\n          データは完全入稿となります。スムーズに印刷工程に進むため、入稿前には必ず"
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.pr39.com/technicalguide/",
                        target: "_blank",
                      },
                    },
                    [_vm._v("テクニカルガイド")]
                  ),
                  _vm._v("をチェックしてください。\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  class: [_vm.$style.explain, _vm.$style.dark, _vm.$style.rice],
                },
                [
                  _vm._v(
                    "\n          ④ フォルダを選択したら送信ボタンを押してアップロードしてください。\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  class: [_vm.$style.explain, _vm.$style.dark, _vm.$style.rice],
                },
                [
                  _vm._v(
                    "\n          ⑤ アップロード中はページを閉じないでください。\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.button },
                [
                  _c(
                    "Button",
                    {
                      attrs: { color: "submit", size: "main" },
                      on: { click: _vm.handleUpload },
                    },
                    [_vm._v("送信する")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }