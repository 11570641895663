var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "100%", block: "", margin: "0" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    商品情報\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              label: "注文数",
              "pr-row": [30],
              name: "product.quantity1",
              value: _vm.value.product.quantity1,
              validate: "numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.quantity1")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              label: "予備注文数",
              name: "product.quantity2",
              "pr-row": [30],
              value: _vm.value.product.quantity2,
              validate: "numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.quantity2")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              label: "出庫商品総数",
              "pr-row": [30],
              name: "product.quantity4",
              value: _vm.value.product.quantity4,
              validate: "numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.quantity4")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              label: "出庫箱数",
              "pr-row": [30],
              name: "product.quantity3",
              value: _vm.value.product.quantity3,
              validate: "numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.quantity3")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "出庫予定日",
              "pr-row": [30],
              value: _vm.value.product.request_date1,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.request_date1")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "港受取日",
              "pr-row": [30],
              value: _vm.value.product.request_date2,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.request_date2")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "検品日",
              "pr-row": [30],
              value: _vm.value.product.request_date3,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.request_date3")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              label: "保管商品個数",
              "pr-row": [30],
              name: "product.quantity6",
              value: _vm.value.product.quantity6,
              validate: "numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.quantity6")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              label: "到着商品内容",
              "pr-row": [30],
              name: "product.description",
              value: _vm.value.product.description,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.description")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrInput", {
            attrs: {
              label: "発送箱数",
              "pr-row": [30],
              name: "product.quantity5",
              value: _vm.value.product.quantity5,
              validate: "numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.quantity5")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", { class: _vm.$style.distribution_item_info_row_name }, [
          _vm._v("\n      発送商品総数\n    "),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.distribution_item_info_row_txt }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("checkNaN")(
                  _vm.value.product.quantity4 - _vm.value.product.quantity6
                )
              ) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrCheckbox", {
            attrs: {
              "before-label": "内職",
              "pr-row": [30],
              value: _vm.value.product.status,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.status")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrTextarea", {
            attrs: {
              label: "備考",
              "pr-row": [30],
              value: _vm.value.product.note2,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "product.note2")
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }