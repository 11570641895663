var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.values !== null
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [
            _vm._v(_vm._s(_vm.isConfirm ? "再注文確認" : "再注文")),
          ]),
          _vm._v(" "),
          _c("CustomerUserName", {
            attrs: { "customer-person": _vm.items.customer_person },
          }),
          _vm._v(" "),
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isConfirm,
                  expression: "!isConfirm",
                },
              ],
            },
            [
              _c(
                "InfoBlock",
                {
                  attrs: { width: "320px", margin: "0", center: "", block: "" },
                },
                [
                  _c("template", { slot: "head" }, [
                    _vm._v("\n        お客様（注文者）ご住所\n      "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.inner },
                    [
                      _c(
                        "PrInput",
                        {
                          attrs: {
                            label: "郵便番号",
                            "pr-row": [100, 75],
                            name: "default.order_address1.zip_code",
                            validate: "required|zipcode",
                            "data-vv-scope": "default",
                            placeholder: "例) 812-0895",
                            requiredIcon: "",
                          },
                          on: { change: _vm.handleOrderAddressChange },
                          model: {
                            value: _vm.values.shipping.order_address1.zip_code,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.values.shipping.order_address1,
                                "zip_code",
                                $$v
                              )
                            },
                            expression:
                              "values.shipping.order_address1.zip_code",
                          },
                        },
                        [
                          _c("ButtonSerchZipCode", {
                            attrs: {
                              slot: "after",
                              zipcode:
                                _vm.values.shipping.order_address1.zip_code,
                            },
                            on: { click: _vm.handleZipSearch },
                            slot: "after",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "都道府県",
                          "pr-row": [100, 100],
                          name: "default.order_address1.location",
                          validate: "required",
                          "data-vv-scope": "default",
                          placeholder: "例) 福岡県",
                          requiredIcon: "",
                        },
                        on: { change: _vm.handleOrderAddressChange },
                        model: {
                          value: _vm.values.shipping.order_address1.location,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.values.shipping.order_address1,
                              "location",
                              $$v
                            )
                          },
                          expression: "values.shipping.order_address1.location",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "市区町村",
                          "pr-row": [100, 100],
                          name: "default.order_address1.address1",
                          validate: "required",
                          "data-vv-scope": "default",
                          placeholder: "例) 福岡市博多区",
                          requiredIcon: "",
                        },
                        on: { change: _vm.handleOrderAddressChange },
                        model: {
                          value: _vm.values.shipping.order_address1.address1,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.values.shipping.order_address1,
                              "address1",
                              $$v
                            )
                          },
                          expression: "values.shipping.order_address1.address1",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "町名・番地",
                          "pr-row": [100, 100],
                          name: "default.order_address1.address2",
                          validate: "required",
                          "data-vv-scope": "default",
                          placeholder: "例) 竹下1丁目11-23",
                          requiredIcon: "",
                        },
                        on: { change: _vm.handleOrderAddressChange },
                        model: {
                          value: _vm.values.shipping.order_address1.address2,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.values.shipping.order_address1,
                              "address2",
                              $$v
                            )
                          },
                          expression: "values.shipping.order_address1.address2",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          label: "建物名・部屋番号",
                          "pr-row": [100, 100],
                          placeholder: "例) アクアサイドGEN1F",
                        },
                        on: { change: _vm.handleOrderAddressChange },
                        model: {
                          value: _vm.values.shipping.order_address2.location,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.values.shipping.order_address2,
                              "location",
                              $$v
                            )
                          },
                          expression: "values.shipping.order_address2.location",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "InfoBlock",
                {
                  attrs: { width: "320px", margin: "0", center: "", block: "" },
                },
                [
                  _c("template", { slot: "head" }, [
                    _vm._v("\n        入金・配送・納品\n      "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.inner },
                    [
                      _c(
                        "div",
                        { class: _vm.$style.formGroup },
                        [
                          _c("p", [_vm._v("お支払方法")]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.$store.getters["defs/getOptions"](
                              "PaymentMethod"
                            ),
                            function ({ key, value }, idx) {
                              return [
                                idx < 2
                                  ? _c("PrInputRadio", {
                                      key: key,
                                      attrs: { label: value, value: key },
                                      model: {
                                        value: _vm.values.contract.quantity4,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.values.contract,
                                            "quantity4",
                                            $$v
                                          )
                                        },
                                        expression: "values.contract.quantity4",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "PrDatePicker",
                        _vm._b(
                          {
                            attrs: {
                              label: "入金予定日",
                              "pr-row": [100, 100],
                            },
                            model: {
                              value: _vm.values.contract.request_date3,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.values.contract,
                                  "request_date3",
                                  $$v
                                )
                              },
                              expression: "values.contract.request_date3",
                            },
                          },
                          "PrDatePicker",
                          _vm.paymentDateRegulation,
                          false
                        )
                      ),
                      _vm._v(" "),
                      _c(
                        "PrDatePicker",
                        _vm._b(
                          {
                            attrs: {
                              label: "納品希望日",
                              "pr-row": [100, 100],
                            },
                            model: {
                              value: _vm.values.request_date2,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "request_date2", $$v)
                              },
                              expression: "values.request_date2",
                            },
                          },
                          "PrDatePicker",
                          _vm.draftDateRegulation,
                          false
                        )
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.formGroup },
                        [
                          _c("p", [_vm._v("受取可能曜日")]),
                          _vm._v(" "),
                          _vm._l(
                            ["月", "火", "水", "木", "金", "土", "日・祝"],
                            function (day, i) {
                              return _c("PrCheckbox", {
                                key: i,
                                attrs: { "before-label": day },
                                model: {
                                  value: _vm.receiveDates[i],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.receiveDates, i, $$v)
                                  },
                                  expression: "receiveDates[i]",
                                },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.formGroup },
                        [
                          _c("p", [_vm._v("商品お届け先")]),
                          _vm._v(" "),
                          _vm._l(
                            [
                              ["注文者と同じ", "0"],
                              ["指定住所へ配送", "1"],
                            ],
                            function ([label, value]) {
                              return _c("PrInputRadio", {
                                key: value,
                                attrs: { label: label, value: value },
                                on: { change: _vm.handleDeliveryRadioChange },
                                model: {
                                  value: _vm.values.shipping.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.values.shipping, "status", $$v)
                                  },
                                  expression: "values.shipping.status",
                                },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("CustomerOrderAddressInputs", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.values.shipping.status == "1",
                            expression: "values.shipping.status == '1'",
                          },
                        ],
                        attrs: {
                          setCustomAddress: _vm.setCustomAddress,
                          flag: _vm.values.shipping.status,
                          address: _vm.values.shipping,
                          whom: "delivery",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.formGroup },
                        [
                          _c("p", [_vm._v("送り主変更オプション")]),
                          _vm._v(" "),
                          _c("PrCheckbox", {
                            attrs: { "after-label": "変更する" },
                            on: { change: _vm.handleSenderCheckChange },
                            model: {
                              value: _vm.values.delivery.quantity2,
                              callback: function ($$v) {
                                _vm.$set(_vm.values.delivery, "quantity2", $$v)
                              },
                              expression: "values.delivery.quantity2",
                            },
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { attrs: { name: "red" } }, [
                            _vm._v(
                              "\n            送り状に表示する送り主を、弊社からご注文者または任意の情報に変更することができます。変更された場合、弊社の領収証や広告物など一切同封いたしません。ご安心のうえ、クライアント様に直接発送が可能です。\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.formGroup },
                        [
                          _c("p", [
                            _vm._v("\n            送り主"),
                            _vm.values.delivery.quantity2 == "0"
                              ? _c("span", [_vm._v("PR39")])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.values.delivery.quantity2 == "1"
                            ? _vm._l(
                                [
                                  ["注文者", "0"],
                                  ["下記の情報", "1"],
                                ],
                                function ([lab, val]) {
                                  return _c("PrInputRadio", {
                                    key: val,
                                    attrs: { label: lab, value: val },
                                    on: { change: _vm.handleSenderRadioChange },
                                    model: {
                                      value: _vm.isSenderChange,
                                      callback: function ($$v) {
                                        _vm.isSenderChange = $$v
                                      },
                                      expression: "isSenderChange",
                                    },
                                  })
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("CustomerOrderAddressInputs", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.values.delivery.quantity2 == "1" &&
                                  _vm.isSenderChange == "1",
                                expression:
                                  "values.delivery.quantity2 == '1' && isSenderChange == '1'",
                              },
                            ],
                            attrs: {
                              setCustomAddress: _vm.setCustomAddress,
                              flag: _vm.isSenderChange,
                              whom: "sender",
                              address: _vm.values.shipping,
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "InfoBlock",
                {
                  attrs: { width: "320px", margin: "0", center: "", block: "" },
                },
                [
                  _c("template", { slot: "head" }, [
                    _vm._v("\n        その他\n      "),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$style.inner }, [
                    _vm.items.verification_image_path
                      ? _c(
                          "div",
                          { class: _vm.$style.formGroup },
                          [
                            _c("p", [_vm._v("印刷データ")]),
                            _vm._v(" "),
                            _vm._l(
                              [
                                ["新しく入稿する", "0"],
                                ["前回と同じ", "1"],
                              ],
                              function ([lab, val]) {
                                return _c("PrInputRadio", {
                                  key: val,
                                  attrs: { label: lab, value: val },
                                  model: {
                                    value: _vm.values.reusable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.values, "reusable", $$v)
                                    },
                                    expression: "values.reusable",
                                  },
                                })
                              }
                            ),
                            _vm._v(" "),
                            _c("span", { attrs: { name: "caption" } }, [
                              _vm._v("前回の最終確認画像"),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: { src: _vm.items.verification_image_path },
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.$style.formGroup },
                      [
                        _c("p", [_vm._v("備考")]),
                        _vm._v(" "),
                        _c("span", { attrs: { name: "gray" } }, [
                          _vm._v(
                            "\n            その他、ご注文に関してご希望などありましたらこちらにご入力ください。\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("PrTextarea", {
                          attrs: { "pr-row": [0, 100], rows: "5" },
                          model: {
                            value: _vm.values.contract.note1,
                            callback: function ($$v) {
                              _vm.$set(_vm.values.contract, "note1", $$v)
                            },
                            expression: "values.contract.note1",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.formGroup }, [
                      _c("span", { attrs: { name: "gray" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.pr39.com/terms/",
                              target: "_brank",
                            },
                          },
                          [_vm._v("「PR39」利用規約")]
                        ),
                        _vm._v(
                          "の内容をご確認のうえ、「同意する」チェックボックスにチェックを入れてください。\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { name: "confirm" } },
                        [
                          _c("PrCheckbox", {
                            staticStyle: { "justify-content": "center" },
                            attrs: {
                              "after-label": "同意する",
                              name: "default.isAgree",
                              validate: "required",
                              "data-vv-scope": "default",
                            },
                            model: {
                              value: _vm.isAgree,
                              callback: function ($$v) {
                                _vm.isAgree = $$v
                              },
                              expression: "isAgree",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isConfirm
            ? _c(
                "section",
                [
                  _c("CustomerProductInfo", { attrs: { deal: _vm.items } }),
                  _vm._v(" "),
                  _c("CustomerOrderAddress", { attrs: { deal: _vm.values } }),
                  _vm._v(" "),
                  _c("CustomerPayment", { attrs: { deal: _vm.values } }),
                  _vm._v(" "),
                  _c("CustomerOther", {
                    attrs: {
                      deal: _vm.values,
                      "image-path": _vm.items.verification_image_path,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isConfirm
            ? _c(
                "div",
                [
                  _c(
                    "Button",
                    {
                      attrs: { size: "assist", color: "cancel" },
                      nativeOn: {
                        click: function ($event) {
                          return (() => {
                            _vm.initValues()
                            _vm.removeValidation()
                          }).apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("クリア")]
                  ),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      attrs: { size: "main", color: "submit" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleGoConfirm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("確認画面へ進む")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "Button",
                    {
                      attrs: { size: "assist", color: "alert" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.back()
                        },
                      },
                    },
                    [_vm._v("戻る")]
                  ),
                  _vm._v(" "),
                  _vm.isConfirm
                    ? _c(
                        "Button",
                        {
                          attrs: { size: "main", color: "submit" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("再注文を確定する")]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }