<template>
  <div :class="$style.wrapper" ref="wrapper" :style="styleObj">
    <div :class="$style.head">
      <p>{{ deal.request_name }}</p>
      <p>{{ deal.customer.organization.name }}</p>
    </div>
    <div :class="$style.forms">
      <PrInput
        label="出庫箱数"
        v-model="values.product.quantity3"
        :pr-row="[50, 50]"
        name="product.quantity3"
        validate="required|numeric"
      />
      <PrDatePicker
        label="港受取日"
        v-model="values.product.request_date2"
        :pr-row="[50, 50]"
      />
      <PrInput
        label="出庫商品総数"
        v-model="values.product.quantity4"
        :pr-row="[50, 50]"
        name="product.quantity4"
        validate="required|numeric"
      />
      <PrInput
        label="保管商品個数"
        v-model="values.product.quantity6"
        :pr-row="[50, 50]"
        name="product.quantity6"
        validate="required|numeric"
      />
      <div :class="$style.sendGoodsAmount">
        <p>発送商品総数</p>
        <p>
          {{ (values.product.quantity4 - values.product.quantity6) | checkNaN }}
        </p>
        <PrErrorText v-if="!isAmountValide"
          >発送商品総数が0以上となるようにしてください。</PrErrorText
        >
      </div>
      <PrInput
        label="発送箱数"
        v-model="values.product.quantity5"
        :pr-row="[50, 50]"
        name="product.quantity5"
        validate="required|numeric"
      />
      <PrDatePicker
        label="発送予定日"
        v-model="values.delivery.request_date2"
        :pr-row="[50, 50]"
      />
      <PrDatePicker
        label="お届け予定日"
        v-model="values.delivery.request_date3"
        :pr-row="[50, 50]"
      />
      <PrTextarea
        label="備考"
        v-model="values.product.note2"
        :pr-row="[50, 50]"
      />
    </div>
    <div :class="$style.buttons">
      <Button size="main" color="submit" @click="handleSave">保存</Button>
      <Button size="assist" color="cancel" @click="$emit('cancel')"
        >キャンセル</Button
      >
    </div>
  </div>
</template>

<script>
import PrInput from "@/components/molecules/PrInput.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import Button from "@/components/atoms/Button.vue"
import { deepCopy, checkNaN } from "@/utils/shared.js"
import { setValues } from "@/helpers/AdminDealingIndexCardHelper.js"

export default {
  components: {
    PrInput,
    PrDatePicker,
    PrTextarea,
    PrErrorText,
    Button,
  },
  props: {
    deal: {
      required: true,
    },
    position: {
      required: true,
    },
  },
  data() {
    return {
      values: setValues(this.deal),
    }
  },
  computed: {
    styleObj() {
      const { right, top } = this.position
      const yOffset = window.pageYOffset
      const xOffset = window.pageXOffset
      return {
        transform: `translate(${right + xOffset}px, ${top + yOffset}px)`,
      }
    },
    isAmountValide() {
      return this.values.product.quantity4 - this.values.product.quantity6 >= 0
    },
  },
  filters: {
    checkNaN,
  },
  watch: {
    deal(newDeal) {
      this.values = setValues(newDeal)
    },
  },
  methods: {
    async handleSave() {
      const result = await this.$validator.validateAll().then(r => r)
      if (!result || !this.isAmountValide) {
        return
      }
      this.$emit("save", deepCopy(this.values))
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 270px;
  margin: 0 auto;
  padding: 0 0 10px;
  background-color: map-get($colors, white);
  border: 1px solid map-get($colors, yellow);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  transition: 0.3s;
}
.head {
  padding: 10px 0;
  background-color: map-get($colors, yellow);
  text-align: center;
  font-weight: bold;
}
.forms {
  padding: 10px 10px 0 10px;
  font-size: 12px;
  > div[name^="pr-"] {
    margin-bottom: 10px;
  }
}
.sendGoodsAmount {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  > p {
    &:nth-of-type(1) {
      width: 50%;
      color: map-get($colors, darkGlay);
    }
    &:nth-of-type(2) {
      width: 50%;
      padding-left: 0.5em;
      color: map-get($colors, darkGlay);
    }
    &:nth-of-type(3) {
      width: 1000%;
    }
  }
}
.buttons {
  font-size: 0.12rem;
  text-align: center;
}
</style>
