var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { opacity: "1" },
      attrs: {
        version: "1.1",
        id: "_x32_",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st0",
          staticStyle: { fill: "rgb(75, 75, 75)" },
          attrs: {
            d: "M449.891,87.953c-3.766-8.906-10.031-16.438-17.922-21.781c-7.891-5.328-17.5-8.469-27.719-8.469h-42.656\n\t\t   v-7.359h-61.828c0.281-2,0.438-4.063,0.438-6.141C300.203,19.828,280.375,0,256,0s-44.203,19.828-44.203,44.203\n\t\t   c0,2.078,0.156,4.141,0.438,6.141h-61.828v7.359H107.75c-6.797,0-13.359,1.391-19.281,3.906\n\t\t   c-8.922,3.766-16.453,10.031-21.797,17.922c-5.328,7.906-8.469,17.5-8.469,27.719v355.219c0,6.797,1.391,13.344,3.891,19.281\n\t\t   c3.781,8.906,10.047,16.438,17.938,21.781S97.531,512,107.75,512h296.5c6.797,0,13.359-1.375,19.297-3.906\n\t\t   c8.906-3.75,16.438-10.031,21.781-17.922c5.328-7.891,8.469-17.5,8.469-27.703V107.25\n\t\t   C453.797,100.438,452.406,93.891,449.891,87.953z M256,27.797c9.047,0,16.406,7.359,16.406,16.406c0,2.172-0.438,4.234-1.219,6.141\n\t\t   h-30.391c-0.766-1.906-1.203-3.969-1.203-6.141C239.594,35.156,246.969,27.797,256,27.797z M424.328,462.469\n\t\t   c0,2.813-0.563,5.406-1.578,7.797c-1.5,3.578-4.078,6.672-7.281,8.859c-3.219,2.156-7,3.406-11.219,3.406h-296.5\n\t\t   c-2.813,0-5.422-0.563-7.813-1.563c-3.578-1.516-6.672-4.094-8.844-7.297c-2.172-3.219-3.422-7-3.422-11.203V107.25\n\t\t   c0-2.813,0.563-5.422,1.578-7.813c1.516-3.594,4.063-6.672,7.281-8.844c3.219-2.156,7-3.406,11.219-3.422h42.656v6.141\n\t\t   c0,11.531,9.344,20.875,20.891,20.875h169.406c11.547,0,20.891-9.344,20.891-20.875v-6.141h42.656c2.813,0,5.422,0.563,7.813,1.578\n\t\t   c3.578,1.516,6.672,4.063,8.844,7.281c2.156,3.234,3.406,7,3.422,11.219V462.469z",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          staticClass: "st0",
          staticStyle: { fill: "rgb(75, 75, 75)" },
          attrs: {
            x: "149.172",
            y: "173.125",
            width: "213.641",
            height: "24.547",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          staticClass: "st0",
          staticStyle: { fill: "rgb(75, 75, 75)" },
          attrs: {
            x: "149.172",
            y: "240.656",
            width: "213.641",
            height: "24.547",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          staticClass: "st0",
          staticStyle: { fill: "rgb(75, 75, 75)" },
          attrs: {
            x: "149.172",
            y: "308.188",
            width: "213.641",
            height: "24.547",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          staticClass: "st0",
          staticStyle: { fill: "rgb(75, 75, 75)" },
          attrs: { x: "256", y: "394.125", width: "106.813", height: "24.563" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }