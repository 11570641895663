import * as types from "@/store/mutation-types"
import api, { paths } from "@/utils/api.js"

const state = {
  userId: 0,
  token: null,
  role: null,
}

const getters = {}

const actions = {
  login: async ({ commit }, authInfo) => {
    // login
    const { ok: resSignin, body: data } = await api.post(
      paths.SIGN_IN,
      authInfo,
    )
    if (!resSignin) {
      console.log(`SIGN_IN ${data}`)
      return resSignin
    }

    // user info
    const { ok: resUser, body: user } = await api.get(
      paths.USER,
      data.access_token,
    )
    if (!resUser) {
      console.log(`USER ${user}`)
      return resUser
    }

    commit(types.AUTH_LOGIN, {
      token: data.access_token,
      userId: data.id,
      role: user.role,
    })
    return resUser
  },
  logout: async ctx => {
    const { ok } = await api.post(
      paths.SIGN_OUT,
      null,
      ctx.rootState.auth.token,
    )

    ctx.commit(types.AUTH_LOGOUT, {
      token: null,
      userId: null,
      role: null,
      name: null,
    })
    return ok
  },
}

const mutations = {
  [types.AUTH_LOGIN](state, payload) {
    state.userId = payload.userId
    state.token = payload.token
    state.role = payload.role
  },
  [types.AUTH_LOGOUT](state, payload) {
    state.userId = payload.userId
    state.token = payload.token
    state.role = payload.role
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
