<template>
  <div :class="$style.wrapper">
    <PageTitle>パスワード再発行</PageTitle>
    <div>
      <p :class="$style.message_error" v-if="validMessage" v-cloak>
        {{ validMessage }}
      </p>
      <form :class="$style.formAria" @submit.prevent="handleExec" novalidate>
        <p :class="$style.desc">
          登録されているメールアドレスをご入力ください。
          <br />パスワード変更用のURLを自動送信します。
        </p>
        <InputForm
          name="email"
          v-model="email"
          :class="$style.form"
          type="email"
          v-validate="'required|email'"
          >メールアドレス</InputForm
        >
        <Button type="submit" color="submit" size="main">送信</Button>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import InputForm from "@/components/molecules/InputForm.vue"
import api, { paths } from "@/utils/api.js"
import PageTitle from "@/components/atoms/PageTitle.vue"
import { routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    Button,
    InputForm,
    PageTitle,
  },
  data() {
    return {
      validMessage: null,
      email: "",
    }
  },
  methods: {
    setValidMessage(message) {
      this.validMessage = message
    },
    validate() {
      return this.$validator.validateAll().then(result => {
        if (!result) {
          if (this.errors.firstByRule("email", "required")) {
            this.setValidMessage("メールアドレスを入力してください。")
            return false
          }

          if (this.errors.firstByRule("email", "email")) {
            this.setValidMessage("メールアドレスを正しく入力してください。")
            return false
          }

          return false
        } else {
          this.setValidMessage(null)
          return true
        }
      })
    },
    async handleExec() {
      let result = await this.validate()
      if (!result) {
        return
      }

      const loader = this.$loading.show()

      try {
        const { ok, body } = await api.post(paths.REQUEST_RESET_PASSWORD, {
          email: this.email,
        })

        if (!ok) {
          console.log(`REQUEST_RESET_PASSWORD ${body}`)
          routeReplaceError(this, body.slice(-3))
          return
        }

        this.$store.dispatch("common/setPasswordReminderEmail", this.email)

        this.$router.push({ path: "/password/reminder/done" })
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include displayMessage();

.wrapper {
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    max-width: 800px;
    width: 100%;
  }
}

.formAria {
  width: 100%;
  margin: 0 auto;
  padding: 90px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  & > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form {
  width: 100%;
  max-width: 465px;
}

.desc {
  font-size: 0.18rem;
  color: #707070;
}
</style>
