<template>
  <OrdersheetProvider>
    <template slot-scope="{ values, onChanged, isSupplier, isEdit }">
      <div :class="$style.wrapper">
        <div
          :class="$style.container"
          v-for="(detail, index) in values.contract.contract.contract_details"
          :key="index"
        >
          <section :class="$style.section">
            <table :class="$style.tac">
              <colgroup>
                <col style="width: 10%;" />
                <col style="width: 40%;" />
                <col style="width: 10%;" />
                <col style="width: 40%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th :class="$style.fwb" colspan="4" valign="middle">
                    <div :class="$style.title">
                      <img :class="$style.logo" src="@/images/TN.png" alt />
                      <p :class="$style.text">
                        더 네 이 처 발 주 서
                      </p>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td colspan="4"></td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>상 호</th>
                  <td :class="$style.fwb">PR39</td>
                  <th>전화번호</th>
                  <td>070-8234-2510</td>
                </tr>
                <tr>
                  <th>담 당 자</th>
                  <td v-cloak>
                    {{ values.user ? values.user.name : "미 설정" }}
                  </td>
                  <th>발 주 일</th>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrDatePicker
                        name="request_date5"
                        v-model="values.contract.request_date5"
                        :validate="'required'"
                        @input="onChanged"
                        format="yyyy년 MM월 dd일"
                        :pr-row="[0, 100]"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{ values.contract.request_date5 | getDate }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th rowspan="2">주 소</th>
                  <td>일본 후쿠오카시하카타쿠타케시타1-11-23</td>
                  <th>출 고 날</th>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrDatePicker
                        name="request_date1"
                        v-model="values.product.request_date1"
                        :validate="'required'"
                        @input="onChanged"
                        format="yyyy년 MM월 dd일"
                        :pr-row="[0, 100]"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{ values.product.request_date1 | getDate }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <th>배송방법</th>
                  <td>택배 (선불)</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td colspan="4">
                    * 제품 주문시
                    <span :class="$style.fwb">특이사항은</span> 반드시 주문란에
                    별도 표기해 주시기 바랍니다.
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <colgroup>
                <col style="width: 12%;" />
                <col style="width: 26%;" />
                <col style="width: 14%;" />
                <col style="width: 14%;" />
                <col style="width: 8%;" />
                <col style="width: 10%;" />
                <col style="width: 8%;" />
                <col style="width: 8%;" />
              </colgroup>
              <tbody :class="[$style.bt0, $style.tac]">
                <tr>
                  <th :class="$style.bt0">데이터명</th>
                  <th :class="$style.bt0">제품명</th>
                  <th :class="$style.bt0">표지사이즈</th>
                  <th :class="$style.bt0">내지사이즈</th>
                  <th :class="$style.bt0">매수</th>
                  <th :class="$style.bt0">인쇄도수</th>
                  <th :class="$style.bt0">수량</th>
                  <th :class="$style.bt0">단가</th>
                </tr>
                <tr>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        name="description"
                        v-model="values.contract.description"
                        :validate="'required'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      values.contract.description
                    }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrSelect
                        :name="'category1_' + index"
                        v-model="detail.category1"
                        :validate="'required'"
                        @input="onChanged"
                        :options="productFamilyOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString("ProductFamily", detail.category1, "KR")
                      }}
                    </template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'size1_' + index"
                        v-model="detail.size1"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.size1 }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'size1_' + index"
                        v-model="detail.size2"
                        :validate="'required'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.size2 }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'size3_' + index"
                        v-model="detail.size3"
                        :validate="'required|numeric'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.size3 }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrSelect
                        :name="'color_' + index"
                        v-model="detail.color"
                        :validate="'required'"
                        @input="onChanged"
                        :options="tagPrintOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{ toDefsString("TagPrint", detail.color, "KR") }}
                    </template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'amount_' + index"
                        v-model="detail.amount"
                        :validate="'required|numeric'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.amount }}</template>
                  </td>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'purchase_amount_' + index"
                        v-model="detail.purchase_amount"
                        :validate="'required|numeric'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      detail.purchase_amount
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td :class="[$style.fwb, $style.tac]" rowspan="2">
                    기재요망
                  </td>
                  <td :class="[$style.fwb, $style.tac]">포장:OPP봉투</td>
                  <td
                    :class="[
                      { [$style.inputAbailable]: !isSupplier },
                      $style.fwb,
                      $style.highlight,
                      $style.tac,
                    ]"
                    colspan="6"
                    rowspan="2"
                  >
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        name="note1"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.description
                        "
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      detail.purchase_order.description
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td :class="[$style.fwb, $style.tac]">고급품질</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td rowspan="2">제품받는 <br />주 소</td>
                  <td colspan="5" :class="$style.tac">
                    부산시동구초량동충장대로206 국제여객터미널 탁송화물 검사장
                  </td>
                  <td :class="$style.tac">받는사람</td>
                  <td :class="$style.tac">김광애</td>
                </tr>
                <tr>
                  <td colspan="5"></td>
                  <td :class="$style.tac">전화번호</td>
                  <td :class="$style.tac">011-835-8045</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td :class="$style.tac">디자인</td>
                  <td :class="$style.tal" colspan="7">
                    <img
                      :src="getVerificationImageUrl(values.request_appendices)"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td colspan="2" :class="$style.tac">은행명</td>
                  <td :class="[$style.fwb, $style.tac]">신한은행</td>
                  <td rowspan="2" colspan="1" :class="$style.tac">계좌번호</td>
                  <td
                    rowspan="2"
                    colspan="4"
                    :class="[$style.tac, $style.highlight]"
                  >
                    110-420-498320
                  </td>
                </tr>
                <tr>
                  <td colspan="2" :class="$style.tac">예금주</td>
                  <td :class="[$style.fwb, $style.tac]">박미리</td>
                </tr>
              </tbody>
            </table>
            <p>♣ 세금계산서 발행시는 사업자등록증 사본 송부요망.</p>
          </section>
        </div>
      </div>
    </template>
  </OrdersheetProvider>
</template>

<script>
import OrdersheetProvider from "@/components/molecules/OrdersheetProvider.vue"
import { getDate } from "@/utils/shared.js"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"

export default {
  components: {
    OrdersheetProvider,
    PrDatePicker,
    PrInput,
    PrSelect,
  },
  filters: {
    getDate(dateStr) {
      return getDate(dateStr, "미 설정", "YYYY년 MM월 DD일")
    },
  },
  computed: {
    productFamilyOptions() {
      return this.$store.getters["defs/getOptions"]("ProductFamily", "KR")
    },
    tagPrintOptions() {
      return this.$store.getters["defs/getOptions"]("TagPrint", "KR")
    },
  },
  methods: {
    toDefsString(category, key, lang = null) {
      const str = this.$store.getters["defs/getOptionValueByKey"](
        category,
        key,
        lang,
      )
      return str
    },
    getVerificationImageUrl(request_appendices) {
      const image = request_appendices.find(
        ({ appendix_category }) => appendix_category === "verification_image",
      )

      return image.appendix_name
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  .inputAbailable {
    background-color: #fde9d9;
  }
  .container {
    padding: 60px 30px;
    background-color: #fff;
    max-width: 900px;
    margin: 50px auto;
    section {
      margin-bottom: 40px;
      display: block;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .logo {
          width: 70px;
        }
        .text {
          font-size: 2em;
          margin-left: 10px;
        }
      }
      .fwb {
        font-weight: bold !important;
      }
      .tac {
        text-align: center !important;
      }
      .tal {
        text-align: center !important;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
        overflow-wrap: break-word;
        input,
        select {
          width: 100%;
        }

        tbody {
          border: 1px solid #000;
        }
        .bt0 {
          border-top: 0;
        }
        td,
        th {
          padding: 8px;
          height: 25px;
          box-sizing: border-box;
          border: 1px dotted #595959;
          &.dot_b {
            border-bottom: 1px dotted #000;
          }
          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            max-height: 300px;
          }
          &.dot_r {
            border-right: 1px dotted #000;
          }
        }

        .highlight {
          font-size: 1.5em;
        }
        .btrf0 {
          border-top: 0;
          border-right: 0;
          border-left: 0;
        }
      }
    }
  }
}
@media print {
  @page {
    size: A4;
    margin: 0mm 10mm;
  }
  .inputAbailable {
    background-color: transparent !important;
  }
  html,
  body {
    display: block !important;
    height: auto;
    .wrapper {
      .container {
        margin: 0 auto !important;
        top: 0 !important;
        left: 0 !important;
        width: 95% !important;
        background-color: #fff;
        position: relative;
        font-size: 10.5pt;
        color: #000000;
        -webkit-print-color-adjust: exact;
        zoom: 0.9 !important;
        &:not(:last-child) {
          page-break-after: always;
        }
        section {
          margin-bottom: 25px;
          display: block;
          table {
            width: 100%;
            td,
            th {
              box-sizing: border-box;
            }
          }
          img {
            max-height: 400px;
          }
        }
      }
    }
  }
}
</style>
