<template>
  <div
    :class="$style.alert"
    @mouseover="isActive = true"
    @mouseleave="isActive = false"
  >
    <IconExclamation width="27px" height="27px" data-iconexclamation="true" />
    <span>{{ msgs.length }}</span>
    <transition name="fade">
      <ul v-show="isActive" :class="$style.msgs">
        <template v-for="(msg, i) in msgs">
          <li :key="i" :class="$style.alert_msgs_msg">
            <v-icon color="#ff0000">error_outline</v-icon><span>{{ msg }}</span>
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>

<script>
import IconExclamation from "@/components/atoms/IconExclamation.vue"

export default {
  components: {
    IconExclamation,
  },
  props: {
    msgs: Array,
  },
  data() {
    return {
      isActive: false,
    }
  },
}
</script>

<style lang="scss" module>
.alert {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > svg {
    cursor: pointer;
  }
  > span {
    font-size: 0.22rem;
    color: red;
  }
  .msgs {
    background-color: white;
    //border: 3px solid map-get($colors, glay);
    border-radius: 10px;
    position: absolute;
    top: -10px;
    left: -270px;
    width: 255px;
    list-style: none;
    padding: 5px 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 18px;
    //   left: 250px;
    //   border: 10px solid transparent;
    //   border-left-color: map-get($colors, glay);
    // }
    > li {
      padding: 10px 0;
      font-weight: bold;
      color: map-get($colors, red);
      display: flex;
      align-content: center;
      > span {
        margin-left: 5px;
      }
    }
  }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
