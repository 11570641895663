var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "320px", margin: "0", center: "", block: "" } },
    [
      _c("template", { slot: "head" }, [
        _vm._v("\n    入金・配送・納品情報\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("p", [_vm._v("お支払い情報")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$store.getters["defs/getOptionValueByKey"](
                    "PaymentMethod",
                    _vm.deal.contract.quantity4
                  )
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("入金予定日")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm._f("getDate")(_vm.deal.contract.request_date3, "指定なし")
              )
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("納品希望日")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm._f("getDate")(_vm.deal.request_date2, "指定なし"))
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("受取可能曜日")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.deal.delivery.note1 || "指定なし"))]),
          _vm._v(" "),
          _c("h3", [_vm._v("お届け先情報")]),
          _vm._v(" "),
          _c("p", [_vm._v("お届け先")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.deal.shipping.status == 0
                    ? "注文者と同じ"
                    : "指定住所へ配送"
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.deal.shipping.status == 1
            ? [
                _c("p", [_vm._v("会社名")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.delivery_address2.address1)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("部署名")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.delivery_address2.address2)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("お届け先名")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.delivery_address3.address1)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("郵便番号")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.delivery_address1.zip_code)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("住所")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.delivery_address1.location)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.delivery_address1.address1)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.delivery_address1.address2)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.delivery_address2.location) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("電話番号")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.delivery_address3.address2)),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("h3", [_vm._v("送り主情報")]),
          _vm._v(" "),
          _c("p", [_vm._v("送り主変更"), _c("br"), _vm._v("オプション")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.deal.delivery.quantity2 == 0 ? "なし" : "変更する")
            ),
          ]),
          _vm._v(" "),
          _vm.deal.delivery.quantity2 == 0
            ? [
                _c("p", [_vm._v("送り主")]),
                _vm._v(" "),
                _c("p", [_vm._v("PR39")]),
              ]
            : [
                _c("p", [_vm._v("会社名")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.sender_address2.address1)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("部署名")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.sender_address2.address2)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("お届け先名")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.sender_address3.address1)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("郵便番号")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.sender_address1.zip_code)),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("住所")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.sender_address1.location)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.sender_address1.address1)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.sender_address1.address2)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.deal.shipping.sender_address2.location) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("電話番号")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.deal.shipping.sender_address3.address2)),
                ]),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }