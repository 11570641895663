exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".OrdersheetProvider__wrapper--3GDfR {\n  max-width: 900px;\n  margin: 0 auto;\n}\n.OrdersheetProvider__wrapper--3GDfR .OrdersheetProvider__buttonArea--Momy5 {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    margin: 10px 0px -20px;\n}\n.OrdersheetProvider__wrapper--3GDfR .OrdersheetProvider__buttonArea--Momy5 .OrdersheetProvider__buttonPrint--2W0sS {\n      text-align: right;\n}\n.OrdersheetProvider__wrapper--3GDfR .OrdersheetProvider__buttonArea--Momy5 .OrdersheetProvider__buttonContent--1SWTK {\n      text-align: left;\n      flex: 1;\n}\n@media print {\n.OrdersheetProvider__printHidden--2Wvqb {\n    display: none;\n}\n}\n", "",{"version":3,"sources":["/client/src/components/molecules/OrdersheetProvider.vue"],"names":[],"mappings":"AAKA;EACE,gBAAgB;EAChB,cAAc;AAAA;AAFhB;IAII,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;AAAA;AAP1B;MASM,iBAAiB;AAAA;AATvB;MAYM,gBAAgB;MAChB,OAAO;AAAA;AAKb;AACE;IACE,aAAa;AAAA;AACd","file":"OrdersheetProvider.vue?vue&type=style&index=1&id=fadb3698&lang=scss&module=true","sourcesContent":["\n                @import \"@/scss/_variables.scss\";\n                @import \"@/scss/_mixin_function.scss\";\n              \n\n.wrapper {\n  max-width: 900px;\n  margin: 0 auto;\n  .buttonArea {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    margin: 10px 0px -20px;\n    .buttonPrint {\n      text-align: right;\n    }\n    .buttonContent {\n      text-align: left;\n      flex: 1;\n    }\n  }\n}\n\n@media print {\n  .printHidden {\n    display: none;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"wrapper": "OrdersheetProvider__wrapper--3GDfR",
	"buttonArea": "OrdersheetProvider__buttonArea--Momy5",
	"buttonPrint": "OrdersheetProvider__buttonPrint--2W0sS",
	"buttonContent": "OrdersheetProvider__buttonContent--1SWTK",
	"printHidden": "OrdersheetProvider__printHidden--2Wvqb"
};