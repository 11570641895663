<template>
  <InfoBlock width="350px" margin="0" block>
    <template slot="head"
      >不良品詳細</template
    >
    <template slot="head-right">
      <v-btn
        flat
        icon
        color="indigo"
        :to="`/admin/dealings/${items.request_number}/defectives/new`"
      >
        <v-icon>add_circle_outline</v-icon>
      </v-btn>
    </template>
    <ul v-if="items.defect.length !== 0" :class="$style.wrapper">
      <li v-for="defect in items.defect" :key="defect.id">
        <router-link
          :to="
            `/admin/dealings/${items.request_number}/defectives/${
              defect.request_key2
            }`
          "
          >{{ defect.request_key2 }}</router-link
        >
        <TheSelect
          :value="defect.status"
          :options="$store.getters['defs/getOptions']('DefectiveStatus')"
          @input="status => handleStatusChnge(status, defect.request_key2)"
        />
      </li>
    </ul>
    <p v-else :class="$style.noitem">不良品はありません</p>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import TheSelect from "@/components/molecules/TheSelect.vue"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    InfoBlock,
    TheSelect,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getValues(requestKey2) {
      const {
        request_key1,
        request_key2,
        request_number,
        lock_version,
        request_appendices,
      } = this.items.defect.find(
        ({ request_key2 }) => request_key2 === requestKey2,
      )
      return {
        request_key1,
        request_key2,
        request_number,
        lock_version,
        request_appendices: request_appendices.map(
          ({
            id,
            request_key1,
            request_key2,
            request_number,
            appendix_category,
            appendix_name,
            lock_version,
          }) => ({
            id,
            request_key1,
            request_key2,
            request_number,
            appendix_category,
            appendix_name,
            lock_version,
          }),
        ),
      }
    },
    async handleStatusChnge(status, requestKey2) {
      const putValues = this.getValues(requestKey2)

      const loader = this.$loading.show()

      try {
        const { ok, body } = await api.put(
          paths.ADMIN_DEALINGS_DEFECTIVE + "/" + putValues.request_number,
          { ...putValues, status },
          this.$store.state.auth.token,
        )
        if (!ok) {
          return
        }

        this.$emit("updated", body)
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  list-style: none;
  padding: 0;
  > li {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    background-color: map-get($colors, lightlightGray);
    &:nth-child(even) {
      background-color: map-get($colors, white);
    }
    > a {
      width: 60%;
      text-align: center;
      color: map-get($colors, blue);
    }
  }
}
.noitem {
  padding: 5px 20px;
  text-align: center;
  background-color: map-get($colors, lightlightGray);
}
</style>
