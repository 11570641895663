<template>
  <div :class="$style.wrapper">
    <PageTitle><slot name="page-title"/></PageTitle>
    <div :class="$style.button"><slot name="create-link" /></div>
    <main :class="$style.inner">
      <h3>検索条件</h3>

      <slot
        name="filter"
        :filterClass="$style.filter"
        v-bind="{
          filter,
          updateFilter,
          handleTextInput,
          handleFlagOn,
          handleSelectInput,
        }"
      />

      <div :class="$style.before">
        <p>{{ pageInfoText }}</p>
        <div :class="$style.page">
          <button
            v-if="pageNo !== 1"
            @click="handlePageChange(pageNo - 1)"
            :class="$style.to"
          >
            <v-icon small>navigate_before</v-icon>
            <span>前へ</span>
          </button>
          <button
            v-for="(btnNo, i) in pages"
            :key="i"
            @click="handlePageChange(btnNo)"
            :class="{ [$style.active]: btnNo == pageNo }"
          >
            {{ btnNo }}
          </button>
          <button
            v-if="pageNo !== Object.keys(pagedItems).length"
            @click="handlePageChange(pageNo + 1)"
          >
            <span>次へ</span>
            <v-icon small>navigate_next</v-icon>
          </button>
        </div>
      </div>

      <slot
        name="data"
        :pagedItems="pagedItems"
        :pageNo="pageNo"
        :dataTableClass="$style.dataTable"
        :thTextClass="$style.thText"
        :activeClass="$style.active"
        :descClass="$style.desc"
        :sortClass="$style.sort"
        :sort="sort"
        :handleSort="handleSort"
      >
        <table :class="$style.dataTable">
          <tr>
            <template v-for="({ text, value, noSort, width }, i) in headers">
              <th
                v-if="text !== 'not_for_display'"
                @click="noSort ? false : handleSort(i)"
                :class="noSort ? false : $style.sort"
                :key="i"
                :style="`width:${width}%`"
              >
                <span
                  :class="
                    noSort
                      ? $style.thText
                      : [
                          $style.thText,
                          { [$style.active]: sort.colIdx == i },
                          { [$style.desc]: sort.colIdx == i && sort.isDesc },
                        ]
                  "
                  >{{ text }}</span
                >
              </th>
            </template>
          </tr>
          <tr v-for="(item, i) in pagedItems[pageNo]" :key="i">
            <template v-for="(val, j) in item">
              <td v-if="!val.startsWith('not_for_display')" :key="j">
                <router-link
                  v-if="val.includes('#link#')"
                  :to="val.split('#link#')[1]"
                >
                  {{ val.split("#link#")[0] }}
                </router-link>
                <template v-else-if="val.includes('#marge#')">
                  <template v-if="flagMarges[val.split('#marge#')[0]]">
                    <div :class="$style.couple">
                      <PrCheckbox
                        :value="flagMarges[val.split('#marge#')[0]].seme"
                        @input="
                          handleFlagInput(
                            $event,
                            val.split('#marge#')[0],
                            'seme',
                          )
                        "
                        :disabled="
                          flagMarges[val.split('#marge#')[0]].uke === '1'
                        "
                      />
                      <v-icon small>arrow_forward</v-icon>
                      <PrCheckbox
                        :value="flagMarges[val.split('#marge#')[0]].uke"
                        @input="
                          handleFlagInput(
                            $event,
                            val.split('#marge#')[0],
                            'uke',
                          )
                        "
                        :disabled="
                          flagMarges[val.split('#marge#')[0]].seme === '1'
                        "
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="val.includes('#excel#')">
                  <template v-if="flagExcel[val.split('#excel#')[0]]">
                    <div :class="$style.excel">
                      <PrCheckbox
                        v-model="flagExcel[val.split('#excel#')[0]]"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>{{ val }}</template>
              </td>
            </template>
          </tr>
        </table>
      </slot>

      <div :class="$style.page">
        <button
          v-if="pageNo !== 1"
          @click="handlePageChange(pageNo - 1)"
          :class="$style.to"
        >
          <v-icon small>navigate_before</v-icon>
          <span>前へ</span>
        </button>
        <button
          v-for="(btnNo, i) in pages"
          :key="i"
          @click="handlePageChange(btnNo)"
          :class="{ [$style.active]: btnNo == pageNo }"
        >
          {{ btnNo }}
        </button>
        <button
          v-if="pageNo !== Object.keys(pagedItems).length"
          @click="handlePageChange(pageNo + 1)"
        >
          <span>次へ</span>
          <v-icon small>navigate_next</v-icon>
        </button>
      </div>
    </main>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import { deepCopy } from "@/utils/shared.js"
import { getValueByKeyString } from "@/helpers/IndexTableHelper.js"

export default {
  inject: ["$validator"],
  components: {
    PrCheckbox,
    PageTitle,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    filterFunc: {
      type: Function,
      required: true,
    },
    pageId: {
      type: String,
      required: true,
    },
    flagMarges: Object,
    handleFlagInput: Function,
    flagExcel: Object,
    handleFlagExcelInput: Function,
  },
  data() {
    return {
      pageNo: null,
      sort: null,
      filter: null,
    }
  },
  watch: {
    $route(to) {
      if (to.fullPath.includes("?page=")) {
        this.init()
      } else {
        this.$router.back()
      }
    },
  },
  computed: {
    basicItems() {
      return this.items.map(item =>
        this.headers.map(({ value, link }) => {
          let val = ""
          if (typeof value === "function") {
            val = value(item)
          }
          if (typeof value === "string") {
            val = getValueByKeyString(item, value)
          }
          if (typeof link === "function") {
            val = `${val}#link#${link(item)}`
          }
          if (typeof link === "string") {
            val = `${val}#link#${getValueByKeyString(item, link)}`
          }
          val = val || "未設定"
          return typeof val === "string" ? val : String(val)
        }),
      )
    },
    filteredItems() {
      return this.basicItems.filter(this.filterFunc(this.filter))
    },
    sortedItems() {
      const { colIdx, isDesc } = this.sort
      if (colIdx === null) {
        return this.filteredItems
      }

      const filteredItemsCopy = this.filteredItems.map(item => ({ ...item }))

      switch (this.headers[colIdx] && this.headers[colIdx].sortType) {
        case "number": {
          return filteredItemsCopy.sort((a, b) => {
            a = +a[colIdx]
            b = +b[colIdx]
            if (isDesc) {
              return a === b ? 0 : a < b ? 1 : -1
            } else {
              return a === b ? 0 : a > b ? 1 : -1
            }
          })
        }
        default: {
          return filteredItemsCopy.sort((a, b) => {
            a = a[colIdx]
            b = b[colIdx]
            if (isDesc) {
              return a === b ? 0 : a < b ? 1 : -1
            } else {
              return a === b ? 0 : a > b ? 1 : -1
            }
          })
        }
      }
    },
    pages() {
      const { pageNo, pagedItems } = this
      const pagedItemsLength = Object.keys(pagedItems).length
      const length = Math.floor(pagedItemsLength / 5)

      let start
      let end

      if (length === 0) {
        start = 1
        end = pagedItemsLength
      } else {
        start = pageNo - 2
        end = pageNo + 2

        if (start <= 0) {
          start = 1
          end = 5
        }

        if (end > pagedItemsLength) {
          start = pagedItemsLength - 4
          end = pagedItemsLength
        }
      }

      let pages = []
      for (let i = start; i <= end; i++) {
        pages.push(i)
      }

      return pages
    },
    pagedItems() {
      return this.sortedItems.reduce((acc, item, i) => {
        const pgaeNo = Math.floor(i / 20 + 1)
        if (acc[pgaeNo] === undefined) {
          return { ...acc, [pgaeNo]: [item] }
        }
        return { ...acc, [pgaeNo]: [...acc[pgaeNo], item] }
      }, {})
    },
    pageInfoText() {
      const all = this.filteredItems.length
      const max = this.pageNo * 20
      const start = max - 19
      const end = max < all ? max : all
      return `全 ${all}中 ${start}件 〜 ${end}件 を表示`
    },
  },
  methods: {
    setPageNo() {
      this.$router.push({
        path: this.$route.path,
        query: {
          page: this.pageNo,
        },
      })
      this.$store.dispatch("pageinfo/setPageInfo", {
        id: this.pageId,
        info: [{ name: "pageNo", value: this.pageNo }],
      })
    },
    updateFilter(newFilter, filterUpdateOnly = false) {
      this.filter = newFilter
      if (filterUpdateOnly) {
        return
      }
      this.pageNo = 1
      this.$store.dispatch("pageinfo/setPageInfo", {
        id: this.pageId,
        info: [{ name: "filter", value: newFilter }],
      })
    },
    handleTextInput(e, key) {
      const newFilter = deepCopy(this.filter)
      newFilter[key].value = e
      this.updateFilter(newFilter, !newFilter[key].isOn)
      this.setPageNo()
    },
    handleFlagOn(key) {
      const newFilter = deepCopy(this.filter)
      if (newFilter[key].isOn) {
        newFilter[key].value = ""
      }
      newFilter[key].isOn = !newFilter[key].isOn
      this.updateFilter(newFilter)
      this.setPageNo()
    },
    handleSelectInput(e, key) {
      const newFilter = deepCopy(this.filter)
      newFilter[key] = e
      this.updateFilter(newFilter)
      this.setPageNo()
    },
    handleSort(idx) {
      this.sort = { colIdx: idx, isDesc: !this.sort.isDesc }
      this.pageNo = 1
      this.$store.dispatch("pageinfo/setPageInfo", {
        id: this.pageId,
        info: [{ name: "sort", value: this.sort }],
      })
      this.setPageNo()
    },
    handlePageChange(nextPage) {
      this.pageNo = Number(nextPage)
      this.setPageNo()
    },
    init() {
      const { sort, filter, pageNo } = this.$store.getters[
        "pageinfo/getPageInfo"
      ](this.pageId)
      this.sort = sort
      this.filter = filter

      const pageLength = Object.keys(this.pagedItems).length

      const QueryPageNo = Number(this.$route.query.page)
      if (
        !isNaN(QueryPageNo) &&
        QueryPageNo !== 0 &&
        QueryPageNo <= pageLength
      ) {
        this.pageNo = QueryPageNo
        this.setPageNo()
        return
      }

      if (pageNo <= pageLength) {
        this.pageNo = pageNo
        this.setPageNo()
        return
      }

      this.pageNo = 1
      this.setPageNo()
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 1080px;
  margin: 0 auto 30px;
  position: relative;
  > .button {
    position: absolute;
    top: 5px;
    right: 0px;
  }
}

.filter {
  display: flex;
  span[name~="label"] {
    font-weight: bold;
    color: map-get($colors, black) !important;
  }
  > div {
    margin-right: 20px;
    &[name="pr-select"] {
      width: 200px;
    }
    &[name="pr-text"] {
      width: 250px;
    }
  }
}

.inner {
  padding: 20px;
  background-color: map-get($colors, white);
  > h3 {
    margin-bottom: 15px;
  }
  > .before {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 0.14rem;
  }
  .page {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > button {
      outline: none;
      display: inline-flex;
      align-items: center;
      margin: 0 2px;
      padding: 5px 12px;
      border-radius: 15px;
      background-color: map-get($colors, white);
      box-shadow: 0px 1px 3px rgba(#000, 0.2);
      &:hover,
      &.active {
        background-color: map-get($colors, lightYellow);
      }
    }
  }

  .dataTable {
    margin: 5px 0;
    width: 100%;
    border-style: none;
    border-spacing: 0;
    text-align: center;
    font-size: 0.12rem;
    > tr {
      &:nth-of-type(even) {
        background-color: map-get($colors, lightGray);
      }
      > th {
        background-color: map-get($colors, lightYellow);
        padding: 5px 15px;
        &.sort {
          cursor: pointer;
          &:hover {
            background-color: map-get($colors, yellow);
          }
        }
        > .thText {
          position: relative;
          &::after {
            display: none;
            content: "";
            position: absolute;
            top: 5px;
            right: -20px;
            width: 13px;
            height: 0;
            border: 6px solid transparent;
          }
          &.active::after {
            display: block;
            border-top: 7px solid map-get($colors, black);
            border-bottom: none;
          }
          &.active.desc::after {
            border-top: none;
            border-bottom: 7px solid map-get($colors, black);
          }
        }
      }
      > td {
        padding: 10px 0;
        position: relative;
      }
    }
  }
}
.couple {
  width: 90px;
  margin-left: 3px;
  padding: 5px 0;
  display: flex;
  justify-content: space-evenly;
  border-radius: 5px;
  border: 1px solid map-get($colors, lightGray);
  background-color: map-get($colors, white);
}
.excel {
  width: 40px;
  margin-left: 3px;
  padding: 5px 0;
  /* display: flex;
     justify-content: space-evenly;*/
  border-radius: 5px;

  border: 1px solid map-get($colors, lightGray);
  background-color: map-get($colors, white);
}
</style>
