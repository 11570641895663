<template>
  <InfoBlock width="320px" margin="0" center block>
    <template slot="head">
      商品情報
    </template>

    <div :class="$style.wrapper">
      <h3>見積No.{{ deal.contract.request_key2 }}</h3>
    </div>
    <div
      v-for="cd in deal.contract.contract.contract_details.filter(
        ({ category2 }) => category2.endsWith('_made'),
      )"
      :key="cd.id"
      :class="$style.wrapper"
    >
      <p>商品名</p>
      <p>
        {{
          $store.getters["defs/getOptionValueByKey"](
            "ProductFamily",
            cd.category1,
            "JP",
          )
        }}{{ cd.name }}
      </p>

      <template v-if="cd.jan_code == '0'">
        <p>カバーサイズ</p>
        <p>{{ cd.size1 }}</p>
        <p>付箋サイズ</p>
        <p>{{ cd.size2 }}</p>
        <p>付箋印刷</p>
        <p>
          {{ $store.getters["defs/getOptionValueByKey"]("TagPrint", cd.color) }}
        </p>
        <p>付箋枚数</p>
        <p>{{ cd.size3 }}</p>
      </template>

      <template v-if="cd.jan_code == '1'">
        <p>サイズ</p>
        <p>{{ cd.size1 }}</p>
        <p>印刷</p>
        <p>
          {{ $store.getters["defs/getOptionValueByKey"]("TagPrint", cd.color) }}
        </p>
      </template>

      <template v-if="cd.jan_code === '2'">
        <p>カバーサイズ</p>
        <p>{{ cd.size1 }}</p>
        <p>カバー型抜き</p>
        <p>
          {{
            $store.getters["defs/getOptionValueByKey"](
              "Cuttings",
              cd.category3,
              "JP",
            )
          }}
        </p>
        <p>付箋サイズ</p>
        <p>{{ cd.size2 }}</p>
        <p>付箋型抜き</p>
        <p>
          {{
            $store.getters["defs/getOptionValueByKey"](
              "Cuttings",
              cd.category4,
              "JP",
            )
          }}
        </p>
        <p>付箋印刷</p>
        <p>
          {{ $store.getters["defs/getOptionValueByKey"]("TagPrint", cd.color) }}
        </p>
        <p>付箋枚数</p>
        <p>{{ cd.size3 }}</p>
      </template>

      <p>生産個数</p>
      <p>{{ cd.amount | formatDecimalAmount }}</p>
      <p>単価</p>
      <p>{{ calcPrice(cd.price, cd.thumbnail) | formatDecimalPrice }}</p>
      <p>総額</p>
      <p>
        {{
          calcTotalPrice(cd.price, cd.thumbnail, cd.amount) | formatDecimalPrice
        }}
      </p>
    </div>

    <div :class="$style.wrapper" v-if="priceOption.length !== 0">
      <div
        v-for="cd in priceOption"
        :key="`option-${cd.id}`"
        :class="$style.priceOptions"
      >
        <p>{{ cd.name }}</p>
        <p>{{ cd.price | formatPrice }}</p>
      </div>
    </div>

    <div :class="$style.wrapper">
      <p>見積総額</p>
      <p>
        {{ (+deal.contract.contract.total_amount + discount) | formatPrice }}
      </p>
      <p>消費税</p>
      <p>
        {{ deal.contract.contract.tax_amount | formatPrice }}
      </p>
      <template v-if="discount != 0">
        <p>値引き</p>
        <p>-{{ discount | formatPrice }}</p>
      </template>
      <p>見積総額(税込)</p>
      <p>
        {{
          (Number(deal.contract.contract.total_amount) +
            Number(deal.contract.contract.tax_amount))
            | formatPrice
        }}
      </p>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import { formatPrice, formatDecimalPrice } from "@/utils/shared.js"
import * as math from "mathjs"

export default {
  components: {
    InfoBlock,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatPrice: num => formatPrice(num),
    formatDecimalPrice: num => formatDecimalPrice(num),
    formatDecimalAmount: num => formatDecimalPrice(num, false, ""),
  },
  computed: {
    priceOption() {
      const priceTable = {
        proofreading: { sort: 0, name: "色校正代金(税別)" },
        design_fee: { sort: 1, name: "デザイン料(税別)" },
        mount_fee: { sort: 2, name: "MF台紙代(税別)" },
        type_fee: { sort: 3, name: "型代(税別)" },
        shipping_fee: { sort: 4, name: "その他送料(税別)" },
        other_fee: { sort: 5, name: "その他代金(税別)" },
      }

      return this.deal.contract.contract.contract_details
        .filter(
          ({ category2, price }) =>
            !category2.endsWith("_made") &&
            Number(price) !== 0 &&
            category2 != "discount",
        )
        .map(({ category2, price }) => {
          return {
            sortIndex: priceTable[category2].sort,
            name: priceTable[category2].name,
            price: price,
          }
        })
        .sort((a, b) => a.sortIndex - b.sortIndex)
    },
    discount() {
      return +this.deal.contract.contract.contract_details.find(
        ({ category2 }) => category2 == "discount",
      ).price
    },
  },
  methods: {
    calcPrice(price, thumbnail) {
      return math
        .chain(math.bignumber(Number(price)))
        .subtract(math.bignumber(Number(thumbnail)))
        .done()
        .toNumber()
    },
    calcTotalPrice(price, thumbnail, amount) {
      return math
        .chain(math.bignumber(this.calcPrice(price, thumbnail)))
        .multiply(math.bignumber(Number(amount)))
        .floor()
        .done()
        .toNumber()
    },
  },
}
</script>

<style lang="scss" module>
@include clientParts();
</style>
