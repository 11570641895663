<template>
  <div :class="$style.wrapper">
    <PageTitle>{{ title }}</PageTitle>
    <CustomerUserName :customer-person="items.customer_person" />

    <InfoBlock block width="320px" margin="0" center>
      <template slot="head">
        商品概要
      </template>

      <div :class="$style.inner">
        <h3 v-cloak>見積NO.{{ detail.no }}</h3>
      </div>

      <div
        :class="$style.inner"
        v-for="(item, index) in detail.data"
        :key="index"
      >
        <template v-if="item.name.visible">
          <p v-cloak>
            {{ item.name.label }}
          </p>
          <p v-cloak>
            {{ item.name.value }}
          </p>
        </template>

        <template v-if="item.size1.visible">
          <p v-cloak>
            {{ item.size1.label }}
          </p>
          <p v-cloak>
            {{ item.size1.value }}
          </p>
        </template>

        <template v-if="item.category3.visible">
          <p v-cloak>
            {{ item.category3.label }}
          </p>
          <p v-cloak>
            {{ item.category3.value }}
          </p>
        </template>

        <template v-if="item.size2.visible">
          <p v-cloak>
            {{ item.size2.label }}
          </p>
          <p v-cloak>
            {{ item.size2.value }}
          </p>
        </template>

        <template v-if="item.category4.visible">
          <p v-cloak>
            {{ item.category4.label }}
          </p>
          <p v-cloak>
            {{ item.category4.value }}
          </p>
        </template>

        <template v-if="item.color.visible">
          <p v-cloak>
            {{ item.color.label }}
          </p>
          <p v-cloak>
            {{ item.color.value }}
          </p>
        </template>

        <template v-if="item.size3.visible">
          <p v-cloak>
            {{ item.size3.label }}
          </p>
          <p v-cloak>
            {{ item.size3.value }}
          </p>
        </template>

        <template v-if="item.amount.visible">
          <p v-cloak>
            {{ item.amount.label }}
          </p>
          <p v-cloak>
            {{ item.amount.value | formatDecimalAmount }}
          </p>
        </template>

        <template v-if="item.price.visible">
          <p v-cloak>
            {{ item.price.label }}
          </p>
          <p v-cloak>
            {{ item.price.value | formatDecimalPrice }}
          </p>
        </template>

        <template v-if="item.total.visible">
          <p v-cloak>
            {{ item.total.label }}
          </p>
          <p v-cloak>
            {{ item.total.value | formatDecimalPrice }}
          </p>
        </template>

        <div :class="$style.quotation_detail_divider" />
      </div>

      <div :class="$style.inner" v-if="detail.options.length !== 0">
        <div
          v-for="(option, index) in detail.options"
          :key="`option-${index}`"
          :class="$style.priceOptions"
        >
          <p v-cloak>{{ option.name }}</p>
          <p v-cloak>{{ option.price | formatPrice }}</p>
        </div>
      </div>

      <div :class="$style.inner">
        <p>見積総額(税別)</p>
        <p v-cloak>
          {{ (+detail.total_amount + +detail.discount) | formatPrice }}
        </p>
        <p>消費税</p>
        <p v-cloak>
          {{ detail.tax_amount | formatPrice }}
        </p>
        <p>値引き</p>
        <p v-cloak>-{{ detail.discount | formatPrice }}</p>
        <p>見積総額(税込)</p>
        <p v-cloak>
          {{
            (Number(detail.total_amount) + Number(detail.tax_amount))
              | formatPrice
          }}
        </p>
      </div>
    </InfoBlock>

    <InfoBlock block width="320px" margin="0" center>
      <template slot="head">
        お客様（注文者）ご住所
      </template>
      <div :class="$style.inner">
        <p>郵便番号</p>
        <p v-cloak>
          {{ orderInfo.customerAddr.zip }}
        </p>
        <p>住所</p>
        <p v-cloak v-html="customerAddr" />
      </div>
    </InfoBlock>

    <InfoBlock block width="320px" margin="0" center>
      <template slot="head">
        入金・発送・納品情報
      </template>
      <div :class="$style.inner">
        <p>お支払い方法</p>
        <p v-cloak>{{ payment }}</p>
        <p>入金予定日</p>
        <p v-cloak>
          {{ orderInfo.scheduleDate | getDate("指定なし") }}
        </p>
        <p>納品希望日</p>
        <p v-cloak>
          {{ orderInfo.prefDate | getDate("指定なし") }}
        </p>
        <p>受取可能日</p>
        <p v-cloak>{{ receiveDates || "指定なし" }}</p>

        <h3 v-cloak>お届け先情報</h3>

        <template v-if="orderInfo.destination == 0">
          <p>商品お届け先</p>
          <p v-cloak>{{ destination }}</p>
        </template>
        <template v-else>
          <p>商品お届け先</p>
          <p v-cloak>{{ destination }}</p>
          <p>会社名・団体名</p>
          <p v-cloak>
            {{ orderInfo.destAddr.company }}
          </p>
          <p>部署名</p>
          <p v-cloak>
            {{ orderInfo.destAddr.division }}
          </p>
          <p>お届け先名</p>
          <p v-cloak>
            {{ orderInfo.destAddr.name }}
          </p>
          <p>郵便番号</p>
          <p v-cloak>
            {{ orderInfo.destAddr.zip }}
          </p>
          <p>住所</p>
          <p v-cloak v-html="destAddr" />
          <p>電話番号</p>
          <p v-cloak>
            {{ orderInfo.destAddr.tel }}
          </p>
        </template>

        <h3 v-cloak>送り主情報</h3>

        <template v-if="orderInfo.isSenderChange == 0">
          <p>送り主変更オプション</p>
          <p v-cloak>{{ senderChange }}</p>
          <p>送り主名</p>
          <p v-cloak>PR39</p>
        </template>
        <template
          v-else-if="orderInfo.isSenderChange == 1 && orderInfo.sender == 1"
        >
          <p>送り主変更オプション</p>
          <p v-cloak>{{ senderChange }}</p>
          <p>会社名・団体名</p>
          <p v-cloak>
            {{ orderInfo.senderAddr.company }}
          </p>
          <p>部署名</p>
          <p v-cloak>
            {{ orderInfo.senderAddr.division }}
          </p>
          <p>送り主名</p>
          <p v-cloak>
            {{ orderInfo.senderAddr.name }}
          </p>
          <p>郵便番号</p>
          <p>
            {{ orderInfo.senderAddr.zip }}
          </p>
          <p>住所</p>
          <p v-cloak v-html="senderAddr" />
          <p>電話番号</p>
          <p v-cloak>
            {{ orderInfo.senderAddr.tel }}
          </p>
        </template>
        <template v-else>
          <p>送り主変更オプション</p>
          <p v-cloak>{{ senderChange }}</p>
          <p>会社名・団体名</p>
          <p v-cloak>
            {{ orderInfo.customerAddr.company }}
          </p>
          <p>部署名</p>
          <p v-cloak>
            {{ orderInfo.customerAddr.division }}
          </p>
          <p>送り主名</p>
          <p v-cloak>
            {{ orderInfo.customerAddr.name }}
          </p>
          <p>郵便番号</p>
          <p>
            {{ orderInfo.customerAddr.zip }}
          </p>
          <p>住所</p>
          <p v-cloak v-html="customerAddr" />
          <p>電話番号</p>
          <p v-cloak>
            {{ orderInfo.customerAddr.tel }}
          </p>
        </template>
      </div>
    </InfoBlock>

    <InfoBlock block width="320px" margin="0" center>
      <template slot="head">
        その他
      </template>
      <div :class="$style.inner">
        <p>備考</p>
        <p v-cloak style="white-space:pre-wrap;">{{ orderInfo.remarks }}</p>
      </div>
    </InfoBlock>

    <section :class="$style.button">
      <Button color="submit" size="main" @click="handleConfirmed"
        >注文を確定する</Button
      >
    </section>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import CustomerUserName from "@/components/atoms/CustomerUserName.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import Button from "@/components/atoms/Button.vue"
import moment from "moment"
import api, { paths } from "@/utils/api.js"
import * as math from "mathjs"

import {
  showErrorToast,
  routeReplaceError,
  getDate,
  formatPrice,
  formatDecimalPrice,
} from "@/utils/shared.js"

export default {
  components: {
    PageTitle,
    CustomerUserName,
    Button,
    InfoBlock,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const id = vm.$route.params.deal_id
      if (vm.$store.state.customer.selectedRequestId != id) {
        routeReplaceError(vm, 900)
        return
      }
      if (vm.$store.state.customer.selectedEstimateNo == null) {
        routeReplaceError(vm, 900)
        return
      }
      if (vm.$store.state.customer.orderInfo == null) {
        routeReplaceError(vm, 900)
        return
      }
    })
  },
  data() {
    return {
      id: null,
      title: "色校正依頼内容確認",
      detail: [],
      items: null,
    }
  },
  created() {
    this.id = this.$route.params.deal_id
    this.customerName = this.$store.getters["customer/getCustomerName"]

    const table = {
      hs: {
        // 付箋
        name: { label: "商品名", visible: true, value: null },
        size1: { label: "カバーサイズ", visible: true, value: null },
        category3: { label: "dummy", visible: false, value: null },
        size2: { label: "付箋サイズ", visible: true, value: null },
        category4: { label: "dummy", visible: false, value: null },
        color: { label: "付箋印刷", visible: true, value: null },
        size3: { label: "付箋枚数", visible: true, value: null },
        amount: { label: "生産個数", visible: true, value: null },
        price: { label: "単価(税別)", visible: true, value: null },
        total: { label: "総額(税別)", visible: true, value: null },
      },
      mf: {
        // マイクロファイバー
        name: { label: "商品名", visible: true, value: null },
        size1: { label: "サイズ", visible: true, value: null },
        category3: { label: "dummy", visible: false, value: null },
        size2: { label: "dummy", visible: false, value: null },
        category4: { label: "dummy", visible: false, value: null },
        color: { label: "印刷", visible: true, value: null },
        size3: { label: "dummy", visible: false, value: null },
        amount: { label: "生産個数", visible: true, value: null },
        price: { label: "単価(税別)", visible: true, value: null },
        total: { label: "総額(税別)", visible: true, value: null },
      },
      om: {
        // オーダーメイド
        name: { label: "商品名", visible: true, value: null },
        size1: { label: "カバーサイズ", visible: true, value: null },
        category3: { label: "カバー型抜き", visible: true, value: null },
        size2: { label: "付箋サイズ", visible: true, value: null },
        category4: { label: "付箋型抜き", visible: true, value: null },
        color: { label: "付箋印刷", visible: true, value: null },
        size3: { label: "付箋枚数", visible: true, value: null },
        amount: { label: "生産個数", visible: true, value: null },
        price: { label: "単価(税別)", visible: true, value: null },
        total: { label: "総額(税別)", visible: true, value: null },
      },
    }

    this.items = this.$store.state.customer.itemDealing

    const contract = this.items.contract.find(
      contract => contract.contract.contract_no === this.estimateNo,
    )

    let arrayData = []
    let options = []
    contract.contract.contract_details.forEach(detail => {
      let data = {}
      if (["ready_made", "order_made"].indexOf(detail.category2) >= 0) {
        switch (detail.jan_code) {
          case "0":
            data = JSON.parse(JSON.stringify(table.hs))
            break
          case "1":
            data = JSON.parse(JSON.stringify(table.mf))
            break
          case "2":
            data = JSON.parse(JSON.stringify(table.om))
            break
          default:
            data = JSON.parse(JSON.stringify(table.hs))
            break
        }

        data.name.value =
          this.getProductFamily(detail.category1) + " " + detail.name
        data.size1.value = detail.size1
        data.category3.value = this.getCuttings(detail.category3)
        data.size2.value = detail.size2
        data.category4.value = this.getCuttings(detail.category4)
        data.color.value = this.getTagPrint(detail.color)
        data.size3.value = detail.size3
        data.amount.value = detail.amount
        data.price.value = math
          .chain(math.bignumber(Number(detail.price)))
          .subtract(math.bignumber(Number(detail.thumbnail)))
          .done()
          .toNumber()
        data.total.value = math
          .chain(math.bignumber(Number(detail.amount)))
          .multiply(math.bignumber(Number(data.price.value)))
          .floor()
          .done()
          .toNumber()

        arrayData.push(JSON.parse(JSON.stringify(data)))
      } else {
        if (Number(detail.price) !== 0 && detail.category2 != "discount") {
          const priceTable = {
            proofreading: { sort: 0, name: "色校正代金(税別)" },
            design_fee: { sort: 1, name: "デザイン料(税別)" },
            mount_fee: { sort: 2, name: "MF台紙代(税別)" },
            type_fee: { sort: 3, name: "型代(税別)" },
            shipping_fee: { sort: 4, name: "その他送料(税別)" },
            other_fee: { sort: 5, name: "その他代金(税別)" },
            discount: { sort: 9, name: "値引(税別)" },
          }
          options.push({
            sort: priceTable[detail.category2].sort,
            name: priceTable[detail.category2].name,
            price: Number(detail.price),
          })
        }
      }
    })

    this.detail = {
      no: contract.contract.contract_no,
      tax_amount: contract.contract.tax_amount,
      total_amount: contract.contract.total_amount,
      discount: contract.contract.contract_details.find(
        ({ category2 }) => category2 == "discount",
      ).price,
      lock_version: contract.lock_version,
      options: options.sort((a, b) => a.sort - b.sort),
      data: arrayData,
    }

    if (!this.orderInfo.isProof) {
      this.title = "注文内容確認画面"
    }
  },
  filters: {
    getDate,
    formatPrice,
    formatDecimalPrice: num => formatDecimalPrice(num),
    formatDecimalAmount: num => formatDecimalPrice(num, false, ""),
  },
  computed: {
    orderInfo() {
      return this.$store.state.customer.orderInfo
    },
    estimateNo() {
      return this.$store.state.customer.selectedEstimateNo
    },
    customerAddr() {
      const { region, locality, street, building } = this.orderInfo.customerAddr
      return this.toAddrString(region, locality, street, building)
    },
    destAddr() {
      const { region, locality, street, building } = this.orderInfo.destAddr
      return this.toAddrString(region, locality, street, building)
    },
    senderAddr() {
      const { region, locality, street, building } = this.orderInfo.senderAddr
      return this.toAddrString(region, locality, street, building)
    },
    payment() {
      return this.orderInfo.payment == 0 ? "銀行振込" : "代引き"
    },
    destination() {
      return this.orderInfo.destination == 0 ? "注文者と同じ" : "指定住所へ配送"
    },
    senderChange() {
      return this.orderInfo.isSenderChange == 0 ? "なし" : "変更する"
    },
    receiveDates() {
      const day = ["月", "火", "水", "木", "金", "土", "日・祝"]
      const str = this.orderInfo.receiveDates
        .reduce((prev, curr, i) => {
          if (curr == "1") {
            prev += `${day[i]}/`
          }
          return prev
        }, "")
        .slice(0, -1)

      return str
    },
  },
  methods: {
    toAddrString(region, locality, street, building) {
      return (
        region +
        "<br/>" +
        locality +
        "<br/>" +
        street +
        (building ? "<br/>" + building : "")
      )
    },
    getCuttings(val) {
      return this.$store.getters["defs/getOptionValueByKey"]("Cuttings", val)
    },
    getProductFamily(val) {
      return this.$store.getters["defs/getOptionValueByKey"](
        "ProductFamily",
        val,
      )
    },
    getTagPrint(val) {
      return this.$store.getters["defs/getOptionValueByKey"]("TagPrint", val)
    },
    async handleConfirmed() {
      let orderAddress = this.orderInfo.customerAddr
      let deliveryAddress = this.orderInfo.customerAddr
      let senderAddress = null

      // 商品お届け先 = 指定住所へ配送
      if (this.orderInfo.destination == 1) {
        deliveryAddress = this.orderInfo.destAddr
      }

      // 送り主変更オプション（変更する）
      if (this.orderInfo.isSenderChange) {
        // 注文者
        if (this.orderInfo.sender == 0) {
          senderAddress = this.orderInfo.customerAddr
        }
        // 下記の情報
        else {
          senderAddress = this.orderInfo.senderAddr
        }
      } else {
        senderAddress = {
          zip: this.items.pr39_address_1.zip_code,
          region: this.items.pr39_address_1.location,
          locality: this.items.pr39_address_1.address1,
          street: this.items.pr39_address_1.address2,
          building: this.items.pr39_address_2.location,
          company: this.items.pr39_address_2.address1,
          division: this.items.pr39_address_2.address2,
          name: this.items.pr39_address_3.address1,
          tel: this.items.pr39_address_3.address2,
        }
      }

      // 納品希望日
      let prefDate = null
      if (this.orderInfo.prefDate) {
        prefDate = moment(this.orderInfo.prefDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      // 入金予定日
      let scheduleDate = null
      if (this.orderInfo.scheduleDate) {
        scheduleDate = moment(this.orderInfo.scheduleDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      let data = {
        request_key1: "request", // request
        request_number: this.id, //  商談管理No
        request_date2: prefDate, //  納品希望日
        lock_version: this.items.lock_version, //  利用:
        is_proofed_order: this.items.display_status,
        delivery: {
          request_key1: "delivery", //    delivery
          request_number: this.id, //    商談管理No
          quantity2: this.orderInfo.isSenderChange ? 1 : 0, //    発送代行有無
          note1: this.receiveDates, //    受取可能曜日
          lock_version: this.items.delivery.lock_version,
        },
        shipping: {
          // 注文者
          order_address1: {
            id: this.items.shipping.order_address1.id,
            subject: this.items.shipping.order_address1.subject,
            zip_code: orderAddress.zip, // 郵便番号
            location: orderAddress.region, // 都道府県
            address1: orderAddress.locality, // 市区郡
            address2: orderAddress.street, // 町名・番地
            lock_version: 0,
          },
          order_address2: {
            id: this.items.shipping.order_address2.id,
            subject: this.items.shipping.order_address2.subject,
            location: orderAddress.building, // 建物名
            address1: orderAddress.company, // 会社名
            address2: orderAddress.division, // 部署
            lock_version: 0,
          },
          order_address3: {
            id: this.items.shipping.order_address3.id,
            subject: this.items.shipping.order_address3.subject,
            address1: orderAddress.name, // 担当者名
            address2: orderAddress.tel, // 電話番号
            lock_version: 0,
          },
          // 送り主
          sender_address1: {
            id: this.items.shipping.sender_address1.id,
            subject: this.items.shipping.sender_address1.subject,
            zip_code: senderAddress.zip, // 郵便番号
            location: senderAddress.region, // 都道府県
            address1: senderAddress.locality, // 市区郡
            address2: senderAddress.street, // 町名・番地
            lock_version: 0,
          },
          sender_address2: {
            id: this.items.shipping.sender_address2.id,
            subject: this.items.shipping.sender_address2.subject,
            location: senderAddress.building, // 建物名
            address1: senderAddress.company, // 会社名
            address2: senderAddress.division, // 部署
            lock_version: 0,
          },
          sender_address3: {
            id: this.items.shipping.sender_address3.id,
            subject: this.items.shipping.sender_address3.subject,
            address1: senderAddress.name, // 担当者名
            address2: senderAddress.tel, // 電話番号
            lock_version: 0,
          },
          // お届け先
          delivery_address1: {
            id: this.items.shipping.delivery_address1.id,
            subject: this.items.shipping.delivery_address1.subject,
            zip_code: deliveryAddress.zip, // 郵便番号
            location: deliveryAddress.region, // 都道府県
            address1: deliveryAddress.locality, // 市区郡
            address2: deliveryAddress.street, // 町名・番地
            lock_version: 0,
          },
          delivery_address2: {
            id: this.items.shipping.delivery_address2.id,
            subject: this.items.shipping.delivery_address2.subject,
            location: deliveryAddress.building, // 建物名
            address1: deliveryAddress.company, // 会社名
            address2: deliveryAddress.division, // 部署
            lock_version: 0,
          },
          delivery_address3: {
            id: this.items.shipping.delivery_address3.id,
            subject: this.items.shipping.delivery_address3.subject,
            address1: deliveryAddress.name, // 担当者名
            address2: deliveryAddress.tel, // 電話番号
            lock_version: 0,
          },
          request_key1: "shipping",
          request_number: this.id, // request_number
          status: this.orderInfo.destination, //  商品お届け先
          lock_version: this.items.shipping.lock_version, // 利用
        },
        contract: {
          request_key1: "contract",
          request_key2: this.detail.no, // 契約No(見積管理No)
          request_number: this.id, // request_number
          request_date3: scheduleDate, // 入金予定日
          quantity3: this.orderInfo.isProof ? 1 : 0, // 0:注文、1:色校正(ステータス)
          quantity4: this.orderInfo.payment, // 0:銀行振込, 1:代引き支払方法(ステータス)
          note1: this.orderInfo.remarks, // 備考
          lock_version: this.detail.lock_version,
        },
      }

      console.log(JSON.parse(JSON.stringify(data)))

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.CUSTOMER_ORDER}/${this.id}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          if (body.slice(-3) === String(400)) {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              errorMsg + "　再度、お問合せください｡",
            )
            return
          }
          showErrorToast(this, body.slice(-3), errorMsg, "通信に失敗しました。")
          return
        }

        this.$router.push({ path: "/customer/dealings" })
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin: 30px;
  text-align: center;
}

@include clientParts();
</style>
