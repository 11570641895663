<template>
  <InfoBlock block width="1115px" margin="0">
    <template slot="head">
      サンプル送付情報
    </template>
    <template slot="head-right">
      <Button
        size="assist"
        color="submit"
        style="margin-right:10px"
        @click="handlePrintClick"
        >送付状印刷</Button
      >
      <PrSelect
        :options="$store.getters['defs/getOptions']('SampleStatus')"
        :value="items.inquiry.quantity3"
        @input="handleStatusChnge"
      />
      <ModalToggler>
        <template slot="button">
          <v-btn flat icon color="indigo">
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
        <template slot-scope="{ toggle }">
          <InfoBlock block width="700px" margin="0">
            <template slot="head"
              >サンプル送付情報編集</template
            >

            <div :class="$style.info_row">
              <PrDatePicker
                v-model="values.request_date3"
                label="サンプル請求受付日"
                :pr-row="[30]"
              />
            </div>
            <div :class="$style.info_row">
              <PrTextarea
                v-model="values.note2"
                label="サンプル請求備考"
                :pr-row="[30]"
              />
            </div>
            <div :class="$style.info_row">
              <PrDatePicker
                v-model="values.request_date4"
                :pr-row="[30]"
                label="サンプル送付日"
              />
            </div>
            <div :class="$style.info_row">
              <PrInput
                v-model="values.note3"
                label="希望サンプル商品番号"
                :pr-row="[30]"
                :validate="'required'"
                name="note3"
              />
            </div>
            <div :class="$style.info_row">
              <PrTextarea
                v-model="values.note4"
                label="サンプル送付内容"
                :pr-row="[30]"
                rows="8"
              />
            </div>
            <div :class="$style.info_row">
              <p :class="$style.info_name" style="width:30%;">サンプル写真</p>
              <div :class="$style.info_content" style="width:70%;">
                <div
                  :class="$style.imgWrapper"
                  v-if="
                    values.request_appendices[getAppendiceIndex].appendix_name
                  "
                >
                  <img
                    style="width:80%;margin-bottom:10px"
                    :src="
                      values.request_appendices[getAppendiceIndex].appendix_name
                    "
                  />
                  <Button
                    size="assist"
                    color="cancel"
                    @click.native="handleImgDelete"
                    >登録済みの画像を削除</Button
                  >
                </div>
                <PrImgInput v-else v-model="file" />
              </div>
            </div>
            <div style="text-align:center;margin:30px 0 0;">
              <Button
                size="main"
                color="submit"
                @click="handleSaveClick(toggle)"
                >保存</Button
              >
              <Button
                size="assist"
                color="cancel"
                @click="handleCancelClick(toggle)"
                >キャンセル</Button
              >
            </div>
          </InfoBlock>
        </template>
      </ModalToggler>
    </template>

    <div :class="$style.container">
      <div :class="$style.info">
        <div :class="$style.info_row">
          <p :class="$style.info_name">サンプル請求受付日</p>
          <p :class="$style.info_content">
            <span v-if="items.inquiry.request_date3">
              {{ items.inquiry.request_date3 | getDate }}
            </span>
          </p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">サンプル請求備考</p>
          <!-- prettier-ignore -->
          <p :class="[$style.info_content, $style.preWrap]">{{ items.inquiry.note2 }}</p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">サンプル送付日</p>
          <p :class="$style.info_content">
            <span v-if="items.inquiry.request_date4">
              {{ items.inquiry.request_date4 | getDate }}
            </span>
          </p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">希望サンプル商品番号</p>
          <p :class="$style.info_content">
            {{ items.inquiry.note3 }}
          </p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">サンプル送付内容</p>
          <!-- prettier-ignore -->
          <p :class="[$style.info_content, $style.preWrap]">{{ items.inquiry.note4 }}</p>
        </div>
        <div :class="$style.info_row">
          <p :class="$style.info_name">サンプル写真</p>
          <p :class="$style.info_content">
            <PrImg
              v-if="
                items.inquiry.request_appendices[getAppendiceIndex]
                  .appendix_name
              "
              :src="
                items.inquiry.request_appendices[getAppendiceIndex]
                  .appendix_name
              "
              style="max-width:80%;max-height:350px;"
            />
          </p>
        </div>
      </div>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrImgInput from "@/components/molecules/PrImgInput.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import Button from "@/components/atoms/Button.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrImg from "@/components/atoms/PrImg.vue"
import {
  showToast,
  showErrorToast,
  getDate,
  openNewWindow,
} from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"
import { uploadFile } from "@/utils/fileUpload.js"

export default {
  components: {
    PrImg,
    InfoBlock,
    PrSelect,
    PrInput,
    PrTextarea,
    Button,
    PrImgInput,
    ModalToggler,
    PrDatePicker,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      values: null,
      thumbnailPath: "",
    }
  },
  computed: {
    getAppendiceIndex() {
      return this.items.inquiry.request_appendices.findIndex(
        ({ appendix_category }) => appendix_category === "sample_image1",
      )
    },
  },
  filters: {
    getDate,
  },
  methods: {
    showThumbnail(file) {
      const reader = new FileReader()
      reader.addEventListener("load", e => {
        this.thumbnailPath = e.target.result
      })
      reader.readAsDataURL(file)
    },
    initValues() {
      const inquiry = this.items.inquiry
      this.values = {
        request_key1: "inquiry",
        request_number: inquiry.request_number,
        request_appendices: inquiry.request_appendices.map(ra => ({ ...ra })),
        lock_version: inquiry.lock_version,
        request_date3: inquiry.request_date3,
        note1: inquiry.note1,
        note2: inquiry.note2,
        request_date4: inquiry.request_date4,
        note3: inquiry.note3,
        note4: inquiry.note4,
      }
    },
    handleImgDelete() {
      this.values = {
        ...this.values,
        request_appendices: this.values.request_appendices.map(ac =>
          ac.appendix_category === "sample_image1"
            ? {
                ...ac,
                appendix_name: "",
              }
            : ac,
        ),
      }
      this.file = null
    },
    handlePrintClick() {
      openNewWindow(
        `/admin/dealings/${this.items.request_number}/print-transmittal`,
      )
    },
    async putApi(putValues) {
      const { ok, body, errorMsg } = await api.put(
        `${paths.ADMIN_DEALINGS_INQUIRY}/${putValues.request_number}`,
        putValues,
        this.$store.state.auth.token,
      )
      if (!ok) {
        showErrorToast(this, body.slice(-3), errorMsg)
        return false
      }
      this.$emit("updated", body)
      return true
    },
    async handleSaveClick(toggle) {
      const result = await this.$validator.validateAll().then(result => result)
      if (!result) {
        return
      }

      const { putApi, values, file, $store } = this
      const putValues = JSON.parse(JSON.stringify(values))

      const loader = this.$loading.show()

      try {
        if (file !== null) {
          const { ok, body } = await uploadFile(
            file,
            $store.state.auth.userId,
            $store.state.auth.token,
          )
          console.log("ファイルアップロード〜〜〜")
          if (!ok) {
            showToast(this, "error", "登録に失敗しました。", 2000, false)
            return
          }

          putValues.request_appendices = putValues.request_appendices.map(ac =>
            ac.appendix_category === "sample_image1"
              ? {
                  ...ac,
                  appendix_name: body.path,
                }
              : ac,
          )
          this.file = null
        }
        const isOK = await putApi({
          ...putValues,
          history_message: "サンプル送付情報が更新されました。",
        })
        if (isOK) {
          toggle()
        }
      } finally {
        loader.hide()
      }
    },
    async handleStatusChnge(quantity3) {
      const {
        $store,
        putApi,
        items: { inquiry },
      } = this

      const loader = this.$loading.show()

      try {
        await putApi({
          request_key1: "inquiry",
          request_number: inquiry.request_number,
          request_appendices: inquiry.request_appendices.map(ra => ({ ...ra })),
          lock_version: inquiry.lock_version,
          quantity3,
          history_message: `サンプル送付情報が[${$store.getters[
            "defs/getOptionValueByKey"
          ]("SampleStatus", quantity3)}]に更新されました。`,
        })
      } finally {
        loader.hide()
      }
    },
    handleCancelClick(toggle) {
      this.initValues()
      toggle()
    },
  },
  created() {
    this.initValues()
  },
  watch: {
    items() {
      console.log("items changed")
      this.initValues()
    },
  },
}
</script>

<style lang="scss" module>
@include InquirySampleOther();
.imgWrapper {
  text-align: center;
}
</style>
