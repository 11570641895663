<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>商品{{ isNew ? "新規作成" : "詳細" }}</PageTitle>
    <InfoBlock>
      <template slot="head"
        >商品概要</template
      >
      <InfoList v-model="values" :list="outLineOverview" />
    </InfoBlock>
    <InfoBlock>
      <template slot="head"
        >商品仕様</template
      >
      <InfoList v-model="values" :list="outLineSpec" />
    </InfoBlock>
    <InfoBlock v-if="!isNew">
      <template slot="head"
        >価格情報</template
      >
      <table :class="$style.table">
        <thead :class="$style.table_head">
          <tr :class="$style.table_head_tr">
            <th :class="$style.table_head_tr_th"></th>
            <th :class="$style.table_head_tr_th">リスト価格</th>
            <th :class="$style.table_head_tr_th">特別価格</th>
            <th :class="$style.table_head_tr_th">仕入値</th>
            <th :class="$style.table_head_tr_th">掛率</th>
            <th :class="$style.table_head_tr_th">適用開始</th>
            <th :class="$style.table_head_tr_th">適用終了</th>
          </tr>
        </thead>
        <tbody :class="$style.table_body">
          <tr
            :class="$style.table_body_tr"
            v-for="(item, index) in items.prices"
            :key="index"
          >
            <template v-if="index === 0 && priceEditflag">
              <td :class="[$style.table_body_tr_td]" v-cloak>
                <v-btn text icon>
                  <v-icon
                    color="#8f8f8f"
                    @click="priceEditflag = !priceEditflag"
                    >close</v-icon
                  >
                </v-btn>
                <v-btn text icon>
                  <v-icon color="#8f8f8f" @click="handlePriceSaveButtonClick"
                    >save</v-icon
                  >
                </v-btn>
              </td>
              <td :class="[$style.table_body_tr_td, $style.input]" v-cloak>
                <PrInput
                  name="unit_price"
                  v-model="priceValues.unit_price"
                  :pr-row="[100, 100]"
                  :disabled="!!Number(priceValues.description)"
                  :validate="'decimal:3'"
                />
              </td>
              <td :class="[$style.table_body_tr_td, $style.input]" v-cloak>
                <PrInput
                  name="special_unit_price"
                  v-model="priceValues.special_unit_price"
                  :pr-row="[100, 100]"
                  :validate="'decimal:3'"
                />
              </td>
              <td :class="[$style.table_body_tr_td, $style.input]" v-cloak>
                <PrInput
                  name="purchase_amount"
                  v-model="priceValues.purchase_amount"
                  :pr-row="[100, 100]"
                  @input="handlePurchaseAmountDescriptionChange"
                  :validate="'numeric'"
                />
              </td>
              <td :class="[$style.table_body_tr_td, $style.input]" v-cloak>
                <PrInput
                  name="description"
                  v-model="priceValues.description"
                  :pr-row="[100, 100]"
                  @input="handlePurchaseAmountDescriptionChange"
                  :validate="'decimal:3'"
                />
              </td>
              <td :class="[$style.table_body_tr_td, $style.input]" v-cloak>
                <PrDatePicker
                  name="start_datetime"
                  v-model="priceValues.start_datetime"
                />
              </td>
            </template>
            <template v-else>
              <td :class="$style.table_body_tr_td" v-cloak>
                <template v-if="index == 0">
                  <v-btn text icon>
                    <v-icon
                      color="#8f8f8f"
                      @click="priceEditflag = !priceEditflag"
                      >edit</v-icon
                    >
                  </v-btn>
                </template>
              </td>
              <td :class="$style.table_body_tr_td" v-cloak>
                {{ item.unit_price }}
              </td>
              <td :class="$style.table_body_tr_td" v-cloak>
                {{ item.special_unit_price }}
              </td>
              <td :class="$style.table_body_tr_td" v-cloak>
                {{ item.purchase_amount }}
              </td>
              <td :class="$style.table_body_tr_td" v-cloak>
                {{ item.description }}
              </td>
              <td :class="$style.table_body_tr_td" v-cloak>
                {{ item.start_datetime | moment }}
              </td>
            </template>

            <td :class="$style.table_body_tr_td" v-cloak>
              {{ item.end_datetime | moment }}
            </td>
          </tr>
        </tbody>
      </table>
    </InfoBlock>

    <RelatedInfo v-if="!isNew" :infoList="relatedInfo">関連情報</RelatedInfo>
    <div :class="$style.buttons_center">
      <QuotationDetailDialog
        v-if="!isNew"
        :texts="{
          ttl: isCopied ? 'コピー完了' : '確認',
          msg: isCopied
            ? `商品Id:${copyItemId}としてコピーしました。編集画面へ移動しますか？`
            : `商品Id:${items.id}をコピーしますか？`,
          yas: isCopied ? '移動する' : 'コピーする',
          no: 'いいえ',
        }"
        @accept="handleCopyAccept"
        @refuse="isCopied = false"
        :no-close="!isCopied"
      >
        <template slot-scope="{ on }">
          <Button
            color="submit"
            size="main"
            @click.native="handleSaveButtonClick"
            >編集内容を保存する</Button
          >
          <Button
            v-if="!isNew"
            color="cancel"
            size="assist"
            @click.native="initValues"
            >クリア</Button
          >
          <Button size="main" color="submit" v-on="on">この商品をコピー</Button>
        </template>
      </QuotationDetailDialog>
      <template v-else>
        <Button color="submit" size="main" @click.native="handleSaveButtonClick"
          >編集内容を保存する</Button
        >
      </template>
    </div>
  </div>
</template>

<script>
import PrInput from "@/components/molecules/PrInput.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import Button from "@/components/atoms/Button.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import InfoList from "@/components/molecules/InfoList.vue"
import RelatedInfo from "@/components/molecules/RelatedInfo.vue"
import QuotationDetailDialog from "@/components/molecules/QuotationDetailDialog.vue"
import { routeReplaceError, showErrorToast, showToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"
import * as math from "mathjs"

const calcUnitPrice = (purchase_amount, description) => {
  const p = math
    .chain(math.bignumber(Number(purchase_amount)))
    .multiply(math.bignumber(Number(description)))
    .round(3)
    .done()
    .toNumber()

  return math.isNaN(p) ? "算出不可" : p
}

export default {
  components: {
    Button,
    PageTitle,
    InfoBlock,
    InfoList,
    RelatedInfo,
    QuotationDetailDialog,
    PrInput,
    PrDatePicker,
  },
  data() {
    return {
      isReady: false,
      isNew: false,
      items: null,
      values: null,
      taxes: null,
      isCopied: false,
      copyItemId: "",
      priceValues: null,
      priceEditflag: false,
    }
  },
  filters: {
    moment(dateStr) {
      if (!dateStr) return ""
      return moment(dateStr, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY/MM/DD")
    },
  },
  computed: {
    relatedInfo() {
      if (this.isNew) {
        return [["更新者", ""], ["更新日", ""]]
      } else {
        const body = this.items
        const [updatedAt] = [body.updated_at].map(v =>
          moment(v, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD"),
        )
        return [["更新者", body.inserted.name], ["更新日", updatedAt]]
      }
    },
    // 商品概要
    outLineOverview() {
      return [
        {
          name: "商品名",
          key: "name",
          type: "text",
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
        {
          name: "型番",
          key: "model_number",
          type: "text",
          validate: null,
        },
        {
          name: "商品ファミリ",
          key: "category1",
          type: "select",
          options: this.$store.getters["defs/getOptions"](
            "ProductFamily",
            "JP",
          ),
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
        {
          name: "適用開始日",
          key: "start_datetime",
          type: "datepicker",
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
      ]
    },
    // 商品仕様
    outLineSpec() {
      return [
        {
          name: "カバー台紙サイズ",
          key: "size1",
          type: "text",
          validate: null,
        },
        {
          name: "付箋サイズ",
          key: "size2",
          type: "text",
          validate: null,
        },
        {
          name: "付箋印刷",
          key: "color",
          type: "select",
          options: this.$store.getters["defs/getOptions"]("TagPrint", "JP"),
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
        {
          name: "付箋枚数",
          key: "size3",
          type: "text",
          validate: {
            rule: "numeric",
            message: {
              numeric: "数値を入力してください。",
            },
          },
        },
        {
          name: "生産個数",
          key: "size4",
          type: "text",
          validate: {
            rule: "required|numeric",
            message: {
              required: "必須項目です。",
              numeric: "数値を入力してください。",
            },
          },
        },
        {
          name: "ホームページURL",
          key: "image_url",
          type: "text",
          validate: null,
        },
        {
          name: "納期日数",
          key: "delivery_area",
          type: "text",
          validate: {
            rule: "numeric",
            message: {
              numeric: "数値を入力してください。",
            },
          },
        },
        {
          name: "OPP代金",
          key: "weight2",
          type: "text",
          validate: {
            rule: "numeric",
            message: {
              numeric: "数値を入力してください。",
            },
          },
        },
        {
          name: "税区分",
          key: "tax_category",
          type: "select",
          options: this.taxes,
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
      ]
    },
  },
  methods: {
    async handleCopyAccept(close) {
      const { isCopied, $router, items, $store } = this
      if (isCopied) {
        $router.push(`/admin/items/${this.copyItemId}/`)
        this.copyItemId = ""
        this.isCopied = false
        this.initPage()
        return
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.post(
          `${paths.ADMIN_COPY_ITEM}/${items.item_code}`,
          null,
          $store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          close()
          return
        }
        showToast(this, "success", "コピーしました。", 5000)
        this.copyItemId = body.id
        this.isCopied = true
      } finally {
        loader.hide()
      }
    },
    async validate() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      console.log("_result=", result)
      return result
    },
    handlePurchaseAmountDescriptionChange() {
      const { purchase_amount, description } = this.priceValues
      if (!Number(description)) {
        return
      }

      this.priceValues.unit_price = calcUnitPrice(purchase_amount, description)
    },
    async handlePriceSaveButtonClick() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      if (!result) {
        return
      }

      const {
        unit_price,
        special_unit_price,
        purchase_amount,
        description,
      } = this.priceValues
      if (
        Number.isNaN(Number(unit_price)) ||
        Number.isNaN(Number(purchase_amount)) ||
        Number.isNaN(Number(description)) ||
        Number.isNaN(Number(special_unit_price))
      ) {
        showToast(this, "warn", "数値を入力してください", 3000)
        return
      }

      const loader = this.$loading.show()

      const start_datetime = moment(this.priceValues.start_datetime)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssZ")

      try {
        const { ok, body, errorMsg } = await api.post(
          paths.ADMIN_PRICES,
          { ...this.priceValues, start_datetime },
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        await this.initPage()
        this.priceEditflag = false
      } finally {
        loader.hide()
      }
    },
    async handleSaveButtonClick() {
      const result = await this.validate()
      console.log("validate = ", result)
      if (!result) {
        return
      }
      const time = moment(this.values.start_datetime)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssZ")

      let data = {
        name: this.values.name,
        category1: this.values.category1,
        model_number: this.values.model_number,
        image_url: this.values.image_url,
        size1: this.values.size1,
        size2: this.values.size2,
        size3: this.values.size3,
        size4: this.values.size4,
        weight2: this.values.weight2,
        delivery_area: this.values.delivery_area,
        color: this.values.color,
        tax_category: this.values.tax_category,
        start_datetime: time,
      }
      if (!this.isNew) {
        data.lock_version = this.items.lock_version
        data.item_code = this.items.item_code
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.post(
          paths.ADMIN_ITEMS,
          data,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$router.replace({ path: "/admin/items" })
      } finally {
        loader.hide()
      }
    },
    initValues() {
      this.$validator.reset()
      this.values = {
        name: this.items.name,
        model_number: this.items.model_number,
        category1: this.items.category1,
        size1: this.items.size1,
        size2: this.items.size2,
        color: this.items.color,
        size3: this.items.size3,
        size4: this.items.size4,
        image_url: this.items.image_url,
        delivery_area: this.items.delivery_area,
        weight2: this.items.weight2,
        tax_category: this.items.tax_category,
        start_datetime: moment(
          this.items.start_datetime,
          "YYYY-MM-DDTHH:mm:ssZ",
        ).format("YYYY/MM/DD"),
      }
    },
    async initPage() {
      const { ok: resTaxes, body: bodyTaxes } = await api.post(
        paths.ADMIN_SEARCH_TAXES,
        null,
        this.$store.state.auth.token,
      )
      if (!resTaxes) {
        routeReplaceError(this, bodyTaxes.slice(-3))
        return
      }
      this.taxes = bodyTaxes.map(v => {
        return {
          key: v.tax_category,
          value: v.tax_category,
        }
      })

      if (this.$route.name === "adminItemNew") {
        this.isNew = true
        this.items = {
          name: "",
          model_number: "",
          category1: "",
          size1: "",
          size2: "",
          color: "",
          size3: "",
          size4: "",
          image_url: "",
          delivery_area: "",
          weight2: "",
          tax_category: "",
          start_datetime: moment(new Date(), "YYYY-MM-DDTHH:mm:ssZ").format(
            "YYYY/MM/DD",
          ),
        }
      } else {
        const { ok, body } = await api.get(
          `${paths.ADMIN_ITEMS}/${this.$route.params.item_id}`,
          this.$store.state.auth.token,
        )
        if (!ok) {
          routeReplaceError(this, body.slice(-3))
          return
        }

        this.items = body
        const [currentPrice] = body.prices.reverse()
        this.priceValues = {
          unit_price: currentPrice.unit_price,
          special_unit_price: currentPrice.special_unit_price,
          start_datetime: currentPrice.start_datetime,
          purchase_amount: currentPrice.purchase_amount,
          merchandise_cost: currentPrice.merchandise_cost,
          description: currentPrice.description,
          lock_version: currentPrice.lock_version,
          item_code: currentPrice.item_code,
        }
      }

      this.initValues()
      this.isReady = true
    },
  },
  created() {
    this.initPage()
  },
}
</script>

<style lang="scss" module>
@include dbDetailView();

.table {
  width: 100%;
  text-align: center;
  border-style: none;
  border-spacing: 0;
  &_head {
    color: map-get($colors, white);
    background-color: #8f8f8f;
    &_tr_th {
      padding: 10px 0;
    }
  }
  &_body_tr {
    &:nth-child(even) {
      background-color: map-get($colors, lightlightGray);
    }
    &_td {
      padding: 15px 0;
      &.input {
        text-align: center;
        > div[name="pr-text"] {
          width: 100px;
          display: inline-flex;
        }
        > div[name="pr-datepicker"] {
          width: 150px;
          display: inline-flex;
        }
      }
    }
  }
}
</style>
