var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.values
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("div", { class: _vm.$style.printHidden }, [
            _c(
              "h1",
              {
                staticStyle: { "padding-top": "30px", "text-align": "center" },
              },
              [_vm._v("発注書")]
            ),
            _vm._v(" "),
            _c("div", { class: _vm.$style.buttonArea }, [
              !_vm.isSupplier
                ? _c("div", { class: _vm.$style.buttonContent }, [
                    _vm.isEdit
                      ? _c(
                          "div",
                          [
                            _c(
                              "Button",
                              {
                                attrs: { size: "main", color: "submit" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleSave.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("変更内容を保存")]
                            ),
                            _vm._v(" "),
                            _c(
                              "Button",
                              {
                                attrs: { size: "assist", color: "cancel" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.initValues.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("キャンセル")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "Button",
                              {
                                attrs: { size: "main", color: "alert" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleEdit.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("編集する")]
                            ),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.buttonPrint },
                [
                  _c(
                    "Button",
                    {
                      attrs: {
                        size: "main",
                        color: _vm.isPrint ? "submit" : "cancel",
                        disabled: !_vm.isPrint,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlePrint.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("この内容で印刷する")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._t("default", null, {
            values: _vm.values,
            onChanged: _vm.onChanged,
            isSupplier: _vm.isSupplier,
            isEdit: _vm.isEdit,
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.$style.printHidden,
              staticStyle: {
                margin: "-20px auto 0",
                "padding-bottom": "100px",
                "text-align": "center",
                width: "900px",
              },
            },
            [
              _c("div", { class: _vm.$style.buttonArea }, [
                !_vm.isSupplier
                  ? _c("div", { class: _vm.$style.buttonContent }, [
                      _vm.isEdit
                        ? _c(
                            "div",
                            [
                              _c(
                                "Button",
                                {
                                  attrs: { size: "main", color: "submit" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSave.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("変更内容を保存")]
                              ),
                              _vm._v(" "),
                              _c(
                                "Button",
                                {
                                  attrs: { size: "assist", color: "cancel" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.initValues.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("キャンセル")]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "Button",
                                {
                                  attrs: { size: "main", color: "alert" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleEdit.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("編集する")]
                              ),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style.buttonPrint },
                  [
                    _c(
                      "Button",
                      {
                        attrs: {
                          size: "main",
                          color: _vm.isPrint ? "submit" : "cancel",
                          disabled: !_vm.isPrint,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handlePrint.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("この内容で印刷する")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }