<template>
  <div :class="$style.Phases">
    <template v-for="{ key, value, status } in phases">
      <DialogProvider
        :class="$style.phase"
        :key="key"
        :texts="{
          ttl: '確認',
          msg: setDialogMsg(key),
          yas: 'はい',
          no: 'いいえ',
        }"
        @accept="handleChangePhase(key)"
      >
        <PhaseBox
          slot-scope="{ on }"
          :text="value"
          :status="status"
          @click.native="
            () => {
              if (key != items.status) {
                on.click($event)
              }
            }
          "
          :style="key == items.status ? { cursor: 'default' } : null"
        />
      </DialogProvider>
    </template>
  </div>
</template>

<script>
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import PhaseBox from "@/components/atoms/PhaseBox.vue"
import { showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PhaseBox,
    DialogProvider,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  computed: {
    history() {
      return this.items.history.reduce(
        (acc, { status }) => (acc.includes(status) ? acc : [...acc, status]),
        [],
      )
    },
    phases() {
      return this.$store.getters["defs/getOptions"]("Progress")
        .reduce((acc, { key, value }) => {
          if (key === "99") {
            return acc
          }

          let status
          if (key == this.items.status) {
            status = "progress"
          } else if (this.history.includes(Number(key))) {
            status = "done"
          } else {
            status = "none"
          }

          return [...acc, { key, value, status: status }]
        }, [])
        .reverse()
    },
  },
  methods: {
    setDialogMsg(nextStatus) {
      const { status, contract } = this.items

      if (
        nextStatus == 10 &&
        status === 20 &&
        contract.find(({ status }) => status == 1).quantity3 == 1
      ) {
        return `登録済み最終確認画像などが消去されます。進捗状況を[注文]に戻しますか？`
      }

      const nextPhaseStr = this.$store.getters["defs/getOptionValueByKey"](
        "Progress",
        nextStatus,
      )

      return `進捗状況を[${nextPhaseStr}]に変更しますか？`
    },
    async handleChangePhase(status) {
      const { request_number, request_key1, lock_version } = this.items

      const loader = this.$loading.show()
      try {
        const response = await api.put(
          paths.ADMIN_DEALINGS_PROGRESS + "/" + this.items.request_number,
          {
            request_number,
            request_key1,
            lock_version,
            status,
          },
          this.$store.state.auth.token,
        )

        if (!response.ok) {
          const errStatus = response.body.slice(-3)
          const errMsg = response.errorMsg
          if (errStatus === String(400)) {
            showErrorToast(this, errStatus, errMsg, errMsg)
            return
          }
          showErrorToast(this, errStatus, errMsg)
          return
        }
        this.$emit("updated", response.body)
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
.Phases {
  width: 1460px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.phase {
  margin-left: -23px;
  &:last-child {
    margin-left: 0;
  }
}
</style>
