var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.rootWrapper },
        [
          _c("PageTitle", [_vm._v("アカウント情報")]),
          _vm._v(" "),
          _c(
            "section",
            { class: _vm.$style.ankers },
            [
              _c("Anker", { attrs: { to: "/customer/accounts/new-email" } }, [
                _vm._v("メールアドレスの変更はこちら"),
              ]),
              _vm._v(" "),
              _c("Anker", { attrs: { to: "/password/edit" } }, [
                _vm._v("パスワードの変更はこちら"),
              ]),
              _vm._v(" "),
              _c(
                "Anker",
                { attrs: { to: "/customer/accounts/mail-magazine" } },
                [_vm._v("メールマガジンの設定はこちら")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            { class: _vm.$style.accounts },
            _vm._l(_vm.itemsAccount, function (item, index) {
              return _c(
                "div",
                { key: item.id, class: _vm.$style.inner },
                [
                  _c("h3", {}, [_vm._v("お客様情報" + _vm._s(index + 1))]),
                  _vm._v(" "),
                  _c("p", [_vm._v("氏名(性)")]),
                  _vm._v(" "),
                  _c("p", {}, [
                    _vm._v("\n        " + _vm._s(item.last_name) + "\n      "),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("氏名(名)")]),
                  _vm._v(" "),
                  _c("p", {}, [
                    _vm._v("\n        " + _vm._s(item.first_name) + "\n      "),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("電話番号")]),
                  _vm._v(" "),
                  _c("p", {}, [_vm._v(_vm._s(item.phone))]),
                  _vm._v(" "),
                  _c("p", [_vm._v("郵便番号")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " + _vm._s(item.address.zip_code) + "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("住所")]),
                  _vm._v(" "),
                  _c("p", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(item.address.location) +
                        "\n        " +
                        _vm._s(item.address.address1) +
                        "\n        " +
                        _vm._s(item.address.address2) +
                        "\n        " +
                        _vm._s(item.building) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      attrs: { color: "submit", size: "assist" },
                      on: {
                        click: function ($event) {
                          return _vm.handleChangeUser(item.id)
                        },
                      },
                    },
                    [_vm._v("登録内容を変更する")]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }