<template>
  <ModalToggler :noOpen="true">
    <template slot="button" slot-scope="{ toggleActive }">
      <Button
        v-if="isNew"
        size="main"
        color="submit"
        @click="handleOpen(toggleActive)"
        plus
        >新規既成品見積</Button
      >
      <v-btn v-else flat icon color="black" @click="handleOpen(toggleActive)">
        <v-icon>edit</v-icon>
      </v-btn>
    </template>
    <div slot-scope="{ toggle }" class="vld-parent" ref="modalContainer">
      <InfoBlock block width="1500px">
        <template slot="head">{{
          isNew ? "既成品見積新規作成" : "既成品見積編集"
        }}</template>
        <div :class="$style.wrapper">
          <div :class="$style.searchProduct">
            <h4>商品を検索</h4>
            <div :class="$style.row">
              <PrSelect
                :class="$style.rowItem"
                label="商品ファミリ"
                :pr-row="[100]"
                :value="selectedSearchProductFaimily"
                :options="productFamilyOptions"
                :searchable="true"
                @input="handleChangeProductFamily"
              />
              <PrSelect
                :class="$style.rowItem"
                label="商品名"
                :pr-row="[100]"
                v-model="selectedSearchProduct"
                :options="productOptions"
                :searchable="true"
              />
              <Button color="submit" size="main" @click="handleSearchProduct"
                >この条件で検索
              </Button>
            </div>
          </div>
          <div :class="$style.searchKeyword">
            <v-text-field
              :class="$style.textField"
              v-model="search"
              append-icon="search"
              label="検索ワード"
              hide-details
            ></v-text-field>
          </div>
          <div :class="$style.tableWrapper">
            <PrErrorText
              v-if="isSaveClicked && radioValue === ''"
              :center="true"
              >商品を選択してください。</PrErrorText
            >
            <v-radio-group v-model="radioValue">
              <v-data-table
                :headers="headers"
                :items="modifiedProducts"
                :search="search"
                :disable-initial-sort="true"
                no-data-text="データなし"
                no-results-text="データなし"
                :pagination.sync="pagination"
              >
                <template slot="items" slot-scope="{ item }">
                  <td>
                    <v-radio :value="item.item_code"></v-radio>
                  </td>
                  <td>{{ item.productFamily }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.model_number }}</td>
                  <td>{{ item.size1 }}</td>
                  <td>{{ item.size2 }}</td>
                  <td>{{ item.tagPrint }}</td>
                  <td>{{ item.size3 }}</td>
                  <td>{{ item.size4 }}</td>
                  <td>{{ item.price ? item.price.unit_price : "" }}</td>
                  <td>{{ item.price ? item.price.special_unit_price : "" }}</td>
                </template>
              </v-data-table>
            </v-radio-group>
          </div>

          <h4>選択された商品</h4>

          <table :class="$style.table">
            <thead :class="$style.table_head">
              <tr :class="$style.table_head_tr">
                <th :class="$style.table_head_tr_th">商品ファミリ</th>
                <th :class="$style.table_head_tr_th">商品名</th>
                <th :class="$style.table_head_tr_th">型番</th>
                <th :class="$style.table_head_tr_th">カバー・台紙サイズ</th>
                <th :class="$style.table_head_tr_th">付箋サイズ</th>
                <th :class="$style.table_head_tr_th">付箋印刷</th>
                <th :class="$style.table_head_tr_th">付箋枚数</th>
                <th :class="$style.table_head_tr_th">価格表個数</th>
                <th :class="$style.table_head_tr_th">単価</th>
                <th :class="$style.table_head_tr_th">特別単価</th>
              </tr>
            </thead>
            <tbody :class="$style.table_body">
              <tr v-if="selectedProduct" :class="$style.table_body_tr">
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.productFamily }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.name }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.model_number }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.size1 }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.size2 }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.tagPrint }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.size3 }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{ selectedProduct.size4 }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{
                    selectedProduct.price
                      ? selectedProduct.price.unit_price
                      : "なし"
                  }}
                </td>
                <td :class="$style.table_body_tr_td" v-cloak>
                  {{
                    selectedProduct.price
                      ? selectedProduct.price.special_unit_price
                      : "なし"
                  }}
                </td>
              </tr>
              <tr v-else>
                <td :class="$style.table_body_tr_td" v-cloak colspan="9">
                  選択なし
                </td>
              </tr>
            </tbody>
          </table>

          <div :class="$style.editable">
            <PrInput
              label="注文数量"
              v-model="amount"
              name="amount"
              style="width: 15%;"
              :validate="'required|numeric'"
              :pr-row="[0, 100]"
            />
            <PrInput
              label="編集用単価"
              v-model="unitPrice"
              name="unitPrice"
              style="width: 15%;"
              :validate="'required|decimal:3'"
              :pr-row="[0, 100]"
              :disabled="useSpecialUnitPrice === '1'"
            />
            <PrCheckbox
              style="justify-content: center"
              after-label="特別価格を使用する"
              name="useSpecialUnitPrice"
              v-model="useSpecialUnitPrice"
              @input="handleSpecialUnitPriceChange"
            />
          </div>

          <div :class="$style.buttons">
            <Button
              color="submit"
              size="main"
              @click.native="handleAddButtonClick(toggle)"
              >保存</Button
            >
            <Button
              color="cancel"
              size="assist"
              @click.native="handleCancelClick(toggle)"
              >キャンセル</Button
            >
          </div>
        </div>
      </InfoBlock>
    </div>
  </ModalToggler>
</template>

<script>
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import Button from "@/components/atoms/Button.vue"
import { getUniqueStr } from "@/helpers/QuotationDetailViewHelper.js"
import api, { paths } from "@/utils/api.js"
import { showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    PrInput,
    Button,
    ModalToggler,
    InfoBlock,
    PrErrorText,
    PrSelect,
    PrCheckbox,
  },
  props: {
    productGroup: {
      type: Array,
      required: true,
    },
    contructDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: -1,
        sortBy: null,
        totalItems: 0,
      },
      products: null,
      radioValue: "",
      amount: "",
      search: "",
      unitPrice: "",
      useSpecialUnitPrice: "0",
      selectedSearchProductFaimily: null,
      selectedSearchProduct: null,
      selectedProduct: null,
      headers: [
        {
          text: "選択",
          value: "check",
        },
        { text: "商品ファミリ", value: "productFamily" },
        {
          text: "商品名",
          value: "name",
        },
        { text: "型番", value: "model_number" },
        { text: "カバー・台紙サイズ", value: "size1" },
        { text: "付箋サイズ", value: "size2" },
        {
          text: "付箋印刷",
          value: "tagPrint",
        },
        { text: "付箋枚数", value: "size3" },
        { text: "価格表個数", value: "size4" },
        {
          text: "単価",
          value: "price.unit_price",
        },
        {
          text: "特別単価",
          value: "price.special_unit_price",
        },
      ],
      isSaveClicked: false,
    }
  },
  computed: {
    isNew() {
      return this.contructDetail === null
    },
    productFamilyOptions() {
      const items = [
        { key: -1, value: "すべて" },
        ...this.$store.getters["defs/getOptions"]("ProductFamily", "JP"),
      ]

      return items
    },
    productOptions() {
      return this.createProductOptions(this.selectedSearchProductFaimily)
    },
    initializedProducts() {
      return this.products == null
        ? []
        : this.products.reduce(
            (acc, product) =>
              (this.contructDetail != null && this.contructDetail.item_code) ===
              product.item_code
                ? [product, ...acc]
                : [...acc, product],
            [],
          )
    },
    modifiedProducts() {
      return this.initializedProducts.map(product => {
        const productFamily = this.$store.getters["defs/getOptionValueByKey"](
          "ProductFamily",
          product.category1,
        )

        const tagPrint = this.$store.getters["defs/getOptionValueByKey"](
          "TagPrint",
          product.color,
        )

        return product.item_code === this.radioValue
          ? { ...product, tagPrint, productFamily, check: 0 }
          : { ...product, tagPrint, productFamily, check: 1 }
      })
    },
    selectedProductPre() {
      return this.modifiedProducts.find(
        ({ item_code }) => item_code === this.radioValue,
      )
    },
  },
  methods: {
    async init() {
      this.isSaveClicked = false
      this.search = ""
      this.selectedProduct = null
      this.selectedSearchProductFaimily = -1
      this.selectedSearchProduct = -1
      this.products = null

      if (this.isNew) {
        this.radioValue = ""
        this.amount = ""
        this.unitPrice = ""
      } else {
        if (this.contructDetail.category1) {
          this.selectedSearchProductFaimily = this.contructDetail.category1
        }

        if (this.contructDetail.name) {
          const options = this.createProductOptions(
            this.selectedSearchProductFaimily,
          )

          const nameValue = options.find(
            v => v.value == this.contructDetail.name,
          )
          if (nameValue) {
            this.selectedSearchProduct = nameValue.key
          }
        }

        this.radioValue = this.contructDetail.item_code
        this.amount = this.contructDetail.amount
        this.unitPrice = this.contructDetail.price
        this.useSpecialUnitPrice = this.contructDetail.is_special_unit_price
      }
    },
    async handleOpen(toggleActive) {
      toggleActive()

      if (!this.isNew) {
        const loader = this.$loading.show({
          container: this.$refs.modalContainer,
        })

        try {
          await this.searchProduct()
        } finally {
          loader.hide()
        }
      }
    },
    async searchProduct() {
      let category =
        this.selectedSearchProductFaimily == -1
          ? null
          : this.selectedSearchProductFaimily
      let name =
        this.selectedSearchProduct == -1 ? [] : [this.selectedSearchProduct]

      const { ok, body, errorMsg } = await api.post(
        paths.ADMIN_SEARCH_ITEMS,
        {
          category: category,
          name: name,
        },
        this.$store.state.auth.token,
      )

      if (!ok) {
        showErrorToast(this, body.slice(-3), errorMsg, "検索に失敗しました。")
        return
      }
      this.products = body
    },
    handleSpecialUnitPriceChange(flag) {
      if (!this.selectedProduct) {
        return
      }
      this.unitPrice =
        flag === "0"
          ? this.selectedProduct.price.unit_price
          : this.selectedProduct.price.special_unit_price
    },
    handleChangeProductFamily(value) {
      if (this.selectedSearchProductFaimily != value) {
        this.selectedSearchProduct = -1
      }
      this.selectedSearchProductFaimily = value
    },
    createProductOptions(selectedFamily) {
      const item = this.productGroup
        .find(v => {
          if (selectedFamily == -1 || v.category == null) {
            return true
          }

          if (v.category == selectedFamily) {
            return true
          }
        })
        .name.map(v => {
          return {
            key: v,
            value: v,
          }
        })

      return [{ key: -1, value: "すべて" }, ...item]
    },
    async handleSearchProduct() {
      const loader = this.$loading.show({
        container: this.$refs.modalContainer,
      })

      try {
        await this.searchProduct()
      } finally {
        loader.hide()
      }
    },
    async handleCancelClick(toggle) {
      toggle()
      await this.init()
    },
    async handleAddButtonClick(toggle) {
      const {
        contructDetail,
        amount,
        radioValue,
        $store,
        isNew,
        selectedProduct,
        useSpecialUnitPrice,
        init,
      } = this

      this.isSaveClicked = true
      const result = await this.$validator.validateAll().then(r => r)

      if (!result || !radioValue) {
        return
      }

      const isAmontChange = !isNew && contructDetail.amount !== amount
      const isRadioValueChange =
        !isNew && contructDetail.category1 !== radioValue

      // 編集で
      if (!isNew) {
        // 数量も商品も変わってない場合
        if (!isAmontChange && !isRadioValueChange) {
          toggle()
          return
        }

        // 数量だけ変わってる場合
        if (isAmontChange && !isRadioValueChange) {
          this.$emit("saved", { ...contructDetail, amount })
          toggle()
          return
        }
      }

      const newContructDetail = {
        price: this.unitPrice,
        purchase_amount: Math.floor(
          Number(selectedProduct.price.purchase_amount),
        ),
        merchandise_cost: selectedProduct.price.merchandise_cost,
        name: selectedProduct.name,
        category1: selectedProduct.category1,
        item_code: selectedProduct.item_code,
        model_number: selectedProduct.model_number,
        jan_code: $store.getters["defs/getDefinitionByKey"](
          "ProductFamily",
          "display_discernment_code",
          selectedProduct.category1,
        ),
        image_url: selectedProduct.image_url,
        size1: selectedProduct.size1,
        size2: selectedProduct.size2,
        size3: selectedProduct.size3,
        size4: selectedProduct.size4,
        weight2: selectedProduct.weight2,
        delivery_area: selectedProduct.delivery_area,
        color: selectedProduct.color,
        tax_category: selectedProduct.tax_category,
        is_special_unit_price: useSpecialUnitPrice,
      }

      // 編集で
      if (!isNew) {
        // 商品だけ変わってる場合
        if (!isAmontChange && isRadioValueChange) {
          this.$emit("saved", { ...contructDetail, ...newContructDetail })
          toggle()
          return
        }

        // 数量も商品も変わってる場合
        if (isAmontChange && isRadioValueChange) {
          this.$emit("saved", {
            ...contructDetail,
            ...newContructDetail,
            amount,
          })
          toggle()
          return
        }
      }

      // 新規の場合
      this.$emit("saved", {
        ...newContructDetail,
        amount,
        id: `new_${getUniqueStr()}`,
        category2: "ready_made",
      })

      init()
      toggle()
    },
  },
  watch: {
    async contructDetail() {
      await this.init()
    },
    selectedProductPre(value) {
      if (value) {
        if (this.isNew) {
          this.amount = value.size4
          this.unitPrice =
            this.useSpecialUnitPrice === "1"
              ? value.price.special_unit_price
              : value.price.unit_price
        } else {
          // 編集の場合、初回（モーダル表示直後）はラジオボタンを変更せずに、この処理を通るため、
          // そのときは、注文数量と編集用単価は更新しない
          if (this.selectedProduct != null) {
            this.amount = value.size4
            this.unitPrice =
              this.useSpecialUnitPrice === "1"
                ? value.price.special_unit_price
                : value.price.unit_price
          }
        }

        this.selectedProduct = value
      }
    },
  },
  async created() {
    await this.init()
  },
}
</script>

<style>
div[class~="v-input__control"] {
  width: 100% !important;
}
</style>

<style lang="scss" module>
.wrapper {
  text-align: left;
  > label {
    width: 150px;
  }
}

.editable {
  display: flex;
  justify-content: flex-start;

  > div[name="pr-text"] {
    margin-right: 15px;
  }
}

.searchProduct {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  > h4 {
    position: absolute;
    display: inline-block;
    top: -12px;
    left: 5px;
    background-color: white;
  }
  margin-bottom: 5px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  &Item {
    margin-right: 10px;
    width: 40%;
  }
}

.searchKeyword {
  text-align: right;
  .textField {
    display: inline-block;
    width: 40%;
  }
}

.tableWrapper {
  margin: 10px 0;
  max-height: 60vh;
  overflow-y: scroll;
}

.buttons {
  text-align: center;
}

.table {
  width: 100%;
  text-align: center;
  border-style: none;
  border-spacing: 0;
  margin-bottom: 20px;
  &_head {
    color: map-get($colors, white);
    background-color: #8f8f8f;
    &_tr_th {
      padding: 5px 0;
    }
  }
  &_body_tr {
    &:nth-child(even) {
      background-color: map-get($colors, lightlightGray);
    }
    &_td {
      padding: 5px 0;
    }
  }
}
</style>
