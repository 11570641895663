var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      class: _vm.$style.base,
      attrs: { to: _vm.to },
      nativeOn: {
        click: function ($event) {
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }