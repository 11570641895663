<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :class="$style.wrapper"
    :style="styleObj"
    viewBox="0 0 106.86 40.61"
  >
    <defs>
      <clipPath id="clip-path">
        <rect x="17.26" height="38.55" fill="none" width="81.86" />
      </clipPath>
    </defs>
    <g id="layer_2" data-name="layer 2">
      <g id="layer_6" data-name="layer 6">
        <rect width="17.26" fill="#f29019" height="38.55" />
        <rect x="99.12" height="38.55" fill="#1b98b8" width="7.74" />
        <rect x="17.26" height="38.55" fill="#fdd11b" width="81.86" />
        <g clip-path="url(#clip-path)">
          <path
            fill="#231815"
            d="m67.38 23.17l0.84-4a6.36 6.36 0 0 1 2.36 -1.82 7.54 7.54 0 0 1 3.08 -0.6 5.89 5.89 0 0 1 4.16 1.41 4.56 4.56 0 0 1 1.53 3.49 5.39 5.39 0 0 1 -0.66 2.61 11.9 11.9 0 0 1 -2.69 2.97 5.75 5.75 0 0 1 2.06 0.41 4.53 4.53 0 0 1 2.22 1.71 4.88 4.88 0 0 1 0.76 2.75 7.91 7.91 0 0 1 -2.7 5.85 9 9 0 0 1 -6.45 2.58 8.81 8.81 0 0 1 -1.89 -0.21 10.06 10.06 0 0 1 -1.9 -0.64 32.45 32.45 0 0 0 -1.26 -4.87l0.86-0.37a14.28 14.28 0 0 0 1.58 3.1 4.19 4.19 0 0 0 1.57 1.17 5.67 5.67 0 0 0 2.33 0.43 4.59 4.59 0 0 0 3.65 -1.5 6.21 6.21 0 0 0 1.31 -4.22 5.2 5.2 0 0 0 -1.14 -3.55 3.72 3.72 0 0 0 -2.93 -1.26 6.35 6.35 0 0 0 -3 0.83l-0.22-0.16 0.3-1.85a11.61 11.61 0 0 0 1.23 0.1 4.17 4.17 0 0 0 3.11 -1.23 4.42 4.42 0 0 0 1.21 -3.23 4 4 0 0 0 -1.06 -2.92 3.65 3.65 0 0 0 -2.69 -1.15 4.84 4.84 0 0 0 -2.17 0.51 4.3 4.3 0 0 0 -1.54 1.16 5.5 5.5 0 0 0 -0.53 1.33l-0.36 1.14z"
          />
          <path
            fill="#231815"
            d="m85.62 40.61-1.06-0.61v-0.32a13.5 13.5 0 0 0 5 -2.19 11.25 11.25 0 0 0 3.21 -3.65 14.34 14.34 0 0 0 1.55 -5.36l-1.61 1.33a6.31 6.31 0 0 1 -1.71 1.25 5 5 0 0 1 -1.83 0.31 5.44 5.44 0 0 1 -4.22 -1.78 7.07 7.07 0 0 1 -1.62 -4.93 8.09 8.09 0 0 1 2 -5.71 6.69 6.69 0 0 1 5.15 -2.19 6.19 6.19 0 0 1 5.1 2.31q1.86 2.32 1.87 6.83a16.08 16.08 0 0 1 -0.94 5.7 13.72 13.72 0 0 1 -2.88 4.56 15.5 15.5 0 0 1 -4.35 3.28 13.75 13.75 0 0 1 -3.66 1.17zm0.39-17a6.9 6.9 0 0 0 1.2 4.35 3.76 3.76 0 0 0 3.09 1.53 3.82 3.82 0 0 0 3 -1.32 6 6 0 0 0 1.14 -4 7.47 7.47 0 0 0 -1.24 -4.56 3.7 3.7 0 0 0 -3.05 -1.66 3.62 3.62 0 0 0 -2.08 0.64 4.43 4.43 0 0 0 -1.52 2 7.52 7.52 0 0 0 -0.55 2.98z"
          />
          <path
            fill="#231815"
            d="m32.9 18.95q-0.11 2.34-0.11 5v9.28a25.71 25.71 0 0 0 0.28 5.47q0.28 0.59 1.82 0.58h1.11v1q-2.73-0.11-4.31-0.11-1.39 0-4.93 0.11v-1h1.39a2 2 0 0 0 1.21 -0.28 1.61 1.61 0 0 0 0.35 -1q0.08-0.77 0.17-4.72v-8.68-3.36a5 5 0 0 0 -0.42 -2.57q-0.39-0.47-1.92-0.47h-0.77v-1q1.93 0.08 4.9 0.08 1.75 0 3.88-0.1 1.4-0.06 2.13-0.06 3.74 0 5.44 1.43a4.81 4.81 0 0 1 1.68 3.87 6.56 6.56 0 0 1 -1 3.4 7.21 7.21 0 0 1 -2.8 2.6 7.72 7.72 0 0 1 -3.81 1 11.08 11.08 0 0 1 -2.32 -0.31l-0.31-1.22a9.25 9.25 0 0 0 2.27 0.36 4.38 4.38 0 0 0 3.38 -1.36 5.26 5.26 0 0 0 1.25 -3.71 4.67 4.67 0 0 0 -1.34 -3.55 5.56 5.56 0 0 0 -4 -1.28 10.29 10.29 0 0 0 -3.22 0.6z"
          />
          <path
            fill="#231815"
            d="m57.32 28.56q1.51 2.29 3.75 5.33l3 4a5.66 5.66 0 0 0 1.05 1.19 1.1 1.1 0 0 0 0.54 0.15h0.34v1q-1.28-0.11-2.24-0.11a15.91 15.91 0 0 0 -1.85 0.11l-5.47-7.76q-1.53-2.14-3.28-4.19l0.15-0.55q0.9 0.08 1.33 0.08a5.6 5.6 0 0 0 4 -1.4 4.93 4.93 0 0 0 1.46 -3.74 4.31 4.31 0 0 0 -1.24 -3.27 5 5 0 0 0 -3.57 -1.18 9 9 0 0 0 -3.26 0.63q-0.19 2.58-0.19 5.52v7.85a36.06 36.06 0 0 0 0.26 6.36q0.26 0.63 1.7 0.63h1.3v1q-2.56-0.11-4.25-0.11-1.33 0-4.8 0.11v-1h1.15q1.33 0 1.58-0.61a38.55 38.55 0 0 0 0.22 -6.35v-7.81q0-5-0.29-5.64t-1.86-0.6h-0.85v-1q2.87 0.11 4.39 0.11 1 0 2.93-0.08 2.23-0.08 3.2-0.08a10.74 10.74 0 0 1 4.05 0.59 4.54 4.54 0 0 1 2.1 1.71 4.86 4.86 0 0 1 0.72 2.68 5.89 5.89 0 0 1 -1.57 4 8.25 8.25 0 0 1 -4.5 2.43z"
          />
        </g>
        <path
          fill="#231815"
          d="m33.64 12h-3.53a1.77 1.77 0 0 1 -1.27 -0.38 1.57 1.57 0 0 1 -0.39 -1.18v-4.34h1v2a15.37 15.37 0 0 0 3.59 -1l0.48 0.85a9.41 9.41 0 0 1 -1.72 0.56 21.25 21.25 0 0 1 -2.31 0.49v1.4a0.71 0.71 0 0 0 0.15 0.49 0.86 0.86 0 0 0 0.62 0.16h3.39zm0.47-5.57a0.69 0.69 0 0 1 -0.22 0.52 0.84 0.84 0 0 1 -1.1 0 0.74 0.74 0 0 1 0 -1 0.84 0.84 0 0 1 1.1 0 0.69 0.69 0 0 1 0.22 0.46zm-0.46-0.31a0.44 0.44 0 0 0 -0.62 0 0.44 0.44 0 0 0 0 0.62 0.44 0.44 0 0 0 0.62 0 0.44 0.44 0 0 0 0 -0.62z"
        />
        <path fill="#231815" d="m40.71 9.65h-6.28v-0.91h6.28z" />
        <path
          fill="#231815"
          d="m47.14 6.64a3.68 3.68 0 0 1 -0.36 0.91 4.59 4.59 0 0 1 -0.95 1.24 6 6 0 0 1 -1.23 0.94l-0.52-0.73a4.29 4.29 0 0 0 1 -0.78 4.5 4.5 0 0 0 0.72 -1h-4.95v-0.83h6zm-3 0.94v0.17q0 0.7 0 1.16a4.1 4.1 0 0 1 -0.19 1 3 3 0 0 1 -0.52 1 3.28 3.28 0 0 1 -0.88 0.77 4.77 4.77 0 0 1 -1.23 0.52l-0.37-0.83a3 3 0 0 0 1.72 -1.12 3.73 3.73 0 0 0 0.45 -2v-0.66z"
        />
        <path fill="#231815" d="m53.7 9.65h-6.28v-0.91h6.28z" />
        <path
          fill="#231815"
          d="m56.41 8.27a4.92 4.92 0 0 1 -0.53 2.52 3.62 3.62 0 0 1 -1.65 1.43l-0.4-0.83a2.6 2.6 0 0 0 1.17 -1.09 4.62 4.62 0 0 0 0.4 -2.19v-1.61h1zm4.4 1.1a5.29 5.29 0 0 1 -1.32 1.75 4.4 4.4 0 0 1 -1.85 1l-0.41-0.33v-5.49h1v4.46a4.07 4.07 0 0 0 1.76 -2z"
        />
        <path
          fill="#231815"
          d="m66.77 7.57h1.23v0.9h-1.23v0.63a2.62 2.62 0 0 1 -1 2.23 5.13 5.13 0 0 1 -3 0.81l-0.15-0.9a4.25 4.25 0 0 0 2.49 -0.63 1.86 1.86 0 0 0 0.69 -1.51v-0.63h-2.16v1.66h-1v-1.66h-1.27v-0.9h1.24v-1.41h1v1.41h2.11v-1.51h1z"
        />
        <path
          fill="#231815"
          d="m72.08 7.74l-0.63 0.85q-0.51-0.32-0.9-0.55t-1.77-0.92l0.54-0.78a22.36 22.36 0 0 1 2.76 1.4zm2.77 0.83a6.24 6.24 0 0 1 -2.26 2.43 8.39 8.39 0 0 1 -3.54 1l-0.23-1a9.25 9.25 0 0 0 1.95 -0.36 5.16 5.16 0 0 0 1.37 -0.65 6.91 6.91 0 0 0 1 -0.82 6.65 6.65 0 0 0 0.86 -1.17z"
        />
        <path
          fill="#231815"
          d="m82 9.86l-2.68 0.28 0.18 2-1.05 0.08-0.17-2-2.76 0.28-0.1-0.91 2.78-0.28-0.2-1.19-2.36 0.21-0.07-0.91 2.43-0.2-0.1-1.12 1-0.09 0.1 1.12 2.29-0.2 0.09 0.9-2.29 0.17 0.1 1.2 2.67-0.29z"
        />
        <path
          fill="#231815"
          d="m88 12h-5.72v-0.76h3.24l0.39-2.91h-2.91v-0.8h3.9l-0.47 3.67h1.57z"
        />
        <path fill="#231815" d="m94.91 9.65h-6.27v-0.91h6.28z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 150,
    },
  },
  computed: {
    styleObj() {
      if (this.width === null) {
        return {}
      }
      return { width: `${this.width}px`, height: `${this.width * 0.38}px` }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 150px;

  @include media(sp) {
    width: 100px;
  }
}
</style>
