import MigrateImage from "@/migrate_image_tool/MigrateImage.vue"

const routes = [
  {
    path: "/migrate-image",
    name: "migrateImage",
    component: MigrateImage,
    meta: {
      role: ["admin"],
    },
  },
]

export default routes
