<template>
  <transition name="menu">
    <div v-show="isShow" :class="$style.wrapper">
      <div :class="$style.btnWrapper">
        <div :class="$style.close" @click="toggleSlideMenuShow"></div>
      </div>
      <h3 :class="$style.head">メニュー</h3>
      <router-link
        to="/admin/dealings"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >商談一覧（テーブル表示）</router-link
      >
      <router-link
        to="/admin/dealings/card"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >商談一覧（カード表示）</router-link
      >
      <router-link
        to="/admin/contracts"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >見積一覧</router-link
      >
      <router-link
        to="/admin/om-contracts"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >オーダーメイド見積一覧</router-link
      >
      <router-link
        to="/admin/customers"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >取引先一覧</router-link
      >
      <router-link
        to="/admin/customer-persons"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >取引先責任者一覧</router-link
      >
      <router-link
        to="/admin/suppliers"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >仕入先一覧</router-link
      >
      <router-link
        to="/admin/defectives"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >不良品一覧</router-link
      >
      <router-link
        to="/admin/items"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >商品一覧</router-link
      >
      <router-link
        to="/admin/items/csv/update"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >商品CSV一括更新</router-link
      >
      <router-link
        to="/admin/prices"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >価格表一覧</router-link
      >
      <router-link
        to="/admin/reports"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >実績帳票出力</router-link
      >
      <IconUser :class="$style.icon" height="80" />
      <h3 :class="$style.head">{{ name }}</h3>
      <router-link
        to="/admin/users"
        @click.native="toggleSlideMenuShow"
        :class="$style.anker"
        >アカウント管理</router-link
      >
      <a :class="$style.anker" @click="handleLogout">ログアウト</a>
    </div>
  </transition>
</template>

<script>
import IconUser from "@/components/atoms/IconUser.vue"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    IconUser,
  },
  props: {
    isShow: {
      type: Boolean,
    },
    toggleSlideMenuShow: {
      type: Function,
    },
  },
  data() {
    return {
      name: "",
    }
  },
  methods: {
    async handleLogout() {
      let result = await this.$store.dispatch("auth/logout")

      if (!result) {
        console.log(`auth/logout result = ${result}`)
      }

      this.toggleSlideMenuShow()
      this.$router.push({ path: "/login" })
    },
  },
  async created() {
    if (!this.$store.state.auth.token) {
      return
    }

    const { ok, body } = await api.get(paths.USER, this.$store.state.auth.token)

    if (ok) {
      this.name = body.name
    }
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 390px;
  height: 100%;
  margin-bottom: auto;
  padding: 35px 30px 0;
  box-shadow: 0 0 30px rgba(#000, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: map-get($zIndexs, slideMenu);
  background-color: #fff;
}

.icon {
  margin: 50px 0 20px;
}
.btnWrapper {
  width: 100%;
  display: flex;
  height: 28px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: flex-end;
}

.close {
  width: 30px;
  height: 28px;
  position: relative;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    background-color: map-get($colors, black);
  }
  &::before {
    top: 0;
    transform: rotate(45deg);
  }
  &::after {
    top: 0;
    transform: rotate(-45deg);
  }
}

.head {
  text-align: center;
  font-size: 0.18rem;
  font-weight: bold;
}

.anker {
  width: 100%;
  padding: 10px 20px;
  display: block;
  font-size: 0.16rem;
  cursor: pointer;
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  &:hover {
    background-color: map-get($colors, yellow);
  }
}
</style>

<style>
.menu-enter-active,
.menu-leave-active {
  transition: transform 0.5s;
}
.menu-enter,
.menu-leave-to {
  transform: translateX(400px);
}
</style>
