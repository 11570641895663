<template>
  <div v-if="isReady" :class="$style.wrapper">
    <h1 :class="$style.pageTtl">メールマガジン設定</h1>
    <div :class="$style.block">
      <div :class="$style.block_container">
        <div :class="$style.formsAria">
          <div :class="$style.account">
            <p :class="$style.account_ttlTxt" v-cloak>
              <span>メールアドレス</span>
              {{ email }}
            </p>
            <p :class="$style.account_ttlTxt">
              <span>メールマガジン</span>
              <label :class="$style.radio">
                <input
                  type="radio"
                  name="mailmagazine"
                  value="1"
                  v-model="receiveMail"
                />
                <span />
                <span>受け取る</span>
              </label>
              <label :class="$style.radio">
                <input
                  type="radio"
                  name="mailmagazine"
                  value="0"
                  v-model="receiveMail"
                />
                <span />
                <span>受け取らない</span>
              </label>
            </p>
          </div>
          <div :class="$style.button">
            <Button color="submit" size="main" @click="handleChange"
              >変更する</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError, showToast, showErrorToast } from "@/utils/shared.js"

export default {
  components: {
    Button,
  },
  data() {
    return {
      isReady: false,
      items: [],
      receiveMail: 1,
      email: "",
    }
  },
  async created() {
    const result = await this.getAccount()
    if (!result.ok) {
      routeReplaceError(this, result.body.slice(-3))
      return
    }
    this.items = result.body

    this.receiveMail = this.items[0].mail_magazine_members
    this.email = this.items[0].user.email

    this.isReady = true
  },
  methods: {
    async getAccount() {
      const { ok: result, body: items } = await api.get(
        paths.CUSTOMER_USER,
        this.$store.state.auth.token,
      )

      return { ok: result, body: items }
    },
    async handleChange() {
      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.put(
          paths.CUSTOMER_USER_BULK,
          {
            mail_magazine_members: this.receiveMail,
          },
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        showToast(this, "success", "変更しました。", 2000, false)
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include clientPage();
@include radio();

.formsAria {
  width: 100%;
}

.account {
  padding: 15px 10px;
  &_ttlTxt {
    margin-bottom: 25px;
    > span {
      display: block;
    }
  }
}

.button {
  text-align: center;
}
</style>
