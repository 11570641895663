<template>
  <OrdersheetProvider>
    <template slot-scope="{ values, onChanged, isSupplier, isEdit }">
      <div :class="$style.wrapper">
        <div
          :class="$style.container"
          v-for="(detail, index) in values.contract.contract.contract_details"
          :key="index"
        >
          <h1>PR39 주문의뢰서</h1>
          <section>
            <table>
              <colgroup>
                <col style="width: 25%;" />
                <col style="width: 12.5%;" />
                <col style="width: 12.5%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th :class="$style.title" colspan="2">의 뢰 자 정 보</th>
                  <td :class="$style.btrf0"></td>
                  <th :class="$style.highlight">작 성 일</th>
                  <td :class="$style.highlight">작성날짜</td>
                </tr>
                <tr>
                  <th>의 뢰 명</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="2"
                  >
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        name="description"
                        v-model="values.contract.description"
                        :validate="'required'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      values.contract.description
                    }}</template>
                  </td>
                  <th>의 뢰 일</th>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrDatePicker
                        name="contracted_date"
                        v-model="values.contract.contract.contracted_date"
                        :validate="'required'"
                        @input="onChanged"
                        :pr-row="[0, 100]"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{ values.contract.contract.contracted_date | getDate }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>의 뢰 자</th>
                  <td colspan="2">
                    {{ values.user ? values.user.name : "미 설정" }}
                  </td>
                  <th>납 기 희 망 일</th>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrDatePicker
                        name="request_date1"
                        v-model="values.product.request_date1"
                        :validate="'required'"
                        @input="onChanged"
                        :pr-row="[0, 100]"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{ values.product.request_date1 | getDate }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>품 목</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="2"
                  >
                    <template v-if="isEdit">
                      <PrSelect
                        :name="'category1_' + index"
                        v-model="detail.category1"
                        :validate="'required'"
                        @input="onChanged"
                        :options="productFamilyOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString("ProductFamily", detail.category1, "KR")
                      }}
                    </template>
                  </td>
                  <th>납 기 일</th>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrDatePicker
                        name="request_date2"
                        v-model="values.product.request_date2"
                        :validate="'required'"
                        @input="onChanged"
                        :pr-row="[0, 100]"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{ values.product.request_date2 | getDate }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>T E L</th>
                  <td colspan="2">+81-92-409-6039</td>
                  <th>F A X</th>
                  <td>+81-92-409-6097</td>
                </tr>
                <tr>
                  <th>배 송 주 소</th>
                  <td colspan="4">
                    부산시동구초량동충장대로206 국제여객터미널 탁송화물 검사장
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <!-- section2 -->
          <section>
            <table>
              <colgroup>
                <col style="width: 25%;" />
                <col style="width: 12.5%;" />
                <col style="width: 12.5%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th :class="$style.title" colspan="2">작 업 상 세 내 역</th>
                  <td :class="$style.btrf0"></td>
                </tr>
                <tr>
                  <th>원 단 종 류</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="2"
                  >
                    <template v-if="isEdit">
                      <PrSelect
                        name="quantity1"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.fabric_type
                        "
                        :validate="'required'"
                        @input="onChanged"
                        :options="fabricTypeOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString(
                          "FabricType",
                          values.contract.contract.contract_details[index]
                            .purchase_order.fabric_type,
                        )
                      }}
                    </template>
                  </td>
                  <th>원 단 크 기</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="2"
                  >
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'size1_' + index"
                        v-model="detail.size1"
                        :validate="'required'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.size1 }}</template>
                  </td>
                </tr>
                <tr>
                  <th>원 단 색 상</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="2"
                  >
                    <template v-if="isEdit">
                      <PrSelect
                        name="quantity2"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.fabric_color
                        "
                        :validate="'required'"
                        @input="onChanged"
                        :options="fabricColorOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString(
                          "FabricColor",
                          values.contract.contract.contract_details[index]
                            .purchase_order.fabric_color,
                        )
                      }}
                    </template>
                  </td>
                  <th>작 업 수 량</th>
                  <td :class="{ [$style.inputAbailable]: !isSupplier }">
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        :name="'amount_' + index"
                        v-model="detail.amount"
                        :validate="'required'"
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{ detail.amount }}</template>
                  </td>
                </tr>
                <tr>
                  <th>재 단 형 태</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="2"
                  >
                    <template v-if="isEdit">
                      <PrSelect
                        name="quantity3"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.cutting_method
                        "
                        :validate="'required'"
                        @input="onChanged"
                        :options="cuttingMethodOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString(
                          "CuttingMethod",
                          values.contract.contract.contract_details[index]
                            .purchase_order.cutting_method,
                          "KR",
                        )
                      }}
                    </template>
                  </td>
                  <th></th>
                  <td></td>
                </tr>

                <tr>
                  <th>포 장 내 역</th>
                  <td
                    :class="[
                      { [$style.inputAbailable]: !isSupplier },
                      $style.tac,
                    ]"
                    colspan="4"
                  >
                    <template v-if="isEdit">
                      <PrSelect
                        name="quantity4"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.packaging_contents
                        "
                        :validate="'required'"
                        @input="onChanged"
                        :options="oppPackagingOptions"
                        :pr-row="[0, 100]"
                        :autoWidth="false"
                      />
                    </template>
                    <template v-else v-cloak>
                      {{
                        toDefsString(
                          "OppPackaging",
                          values.contract.contract.contract_details[index]
                            .purchase_order.packaging_contents,
                        )
                      }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>특 기 사 항</th>
                  <td
                    :class="{ [$style.inputAbailable]: !isSupplier }"
                    colspan="4"
                  >
                    <template v-if="isEdit">
                      <PrInput
                        type="text"
                        name="note1"
                        v-model="
                          values.contract.contract.contract_details[index]
                            .purchase_order.description
                        "
                        :pr-row="[0, 100]"
                        @input="onChanged"
                      />
                    </template>
                    <template v-else v-cloak>{{
                      values.contract.contract.contract_details[index]
                        .purchase_order.description
                    }}</template>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <!-- section3 -->
          <section>
            <table>
              <colgroup>
                <col style="width: 25%;" />
                <col style="width: 12.5%;" />
                <col style="width: 12.5%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th :class="$style.title" colspan="2">단 가 내 역</th>
                  <td :class="$style.btrf0"></td>
                  <td :class="$style.btrf0"></td>
                  <td :class="$style.btrf0"></td>
                </tr>
                <tr>
                  <td :class="$style.tac" colspan="5">
                    {{ calcTotalPrice(detail.purchase_amount, detail.weight2) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <!-- section4 -->
          <section>
            <table>
              <colgroup>
                <col style="width: 25%;" />
                <col style="width: 12.5%;" />
                <col style="width: 12.5%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th
                    :class="$style.title"
                    style="background: orange;"
                    colspan="2"
                  >
                    그 림 참 조
                  </th>
                  <td :class="$style.btrf0"></td>
                  <td :class="$style.btrf0"></td>
                  <td :class="$style.btrf0"></td>
                </tr>
                <tr>
                  <td :class="$style.h200" colspan="5">
                    <img
                      :src="getVerificationImageUrl(values.request_appendices)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </template>
  </OrdersheetProvider>
</template>

<script>
import OrdersheetProvider from "@/components/molecules/OrdersheetProvider.vue"
import { getDate } from "@/utils/shared.js"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"

export default {
  components: {
    OrdersheetProvider,
    PrDatePicker,
    PrInput,
    PrSelect,
  },
  filters: {
    getDate(dateStr) {
      return getDate(dateStr, "미 설정")
    },
  },
  computed: {
    productFamilyOptions() {
      return this.$store.getters["defs/getOptions"]("ProductFamily", "KR")
    },
    fabricTypeOptions() {
      return this.$store.getters["defs/getOptions"]("FabricType")
    },
    fabricColorOptions() {
      return this.$store.getters["defs/getOptions"]("FabricColor")
    },
    cuttingMethodOptions() {
      return this.$store.getters["defs/getOptions"]("CuttingMethod", "KR")
    },
    oppPackagingOptions() {
      return this.$store.getters["defs/getOptions"]("OppPackaging")
    },
  },
  methods: {
    toDefsString(category, key, lang = null) {
      const str = this.$store.getters["defs/getOptionValueByKey"](
        category,
        key,
        lang,
      )
      return str
    },
    calcTotalPrice(amount, weight2) {
      const price = amount / 10
      const total = price + Number(weight2)
      return `단가 ${price}엔 + OPP${weight2}엔 = ${total}엔`
    },
    getVerificationImageUrl(request_appendices) {
      const image = request_appendices.find(
        ({ appendix_category }) => appendix_category === "verification_image",
      )

      return image.appendix_name
    },
  },
}
</script>

<style lang="scss" module>
.inputAbailable {
  background-color: #fde9d9;
}
.wrapper {
  .container {
    padding: 60px 30px;
    background-color: #fff;
    max-width: 900px;
    margin: 50px auto;
    h1 {
      text-align: center;
      padding: 0 0 20px;
    }
    section {
      margin-bottom: 50px;
      display: block;
      table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
        overflow-wrap: break-word;
        input,
        select {
          width: 100%;
        }
        td,
        th {
          padding: 8px;
          width: 200px;
          height: 25px;
          box-sizing: border-box;
          border: 1px solid #595959;
          text-align: center;
        }
        .title {
          background-color: #bbd090 !important;
        }
        .highlight {
          background-color: #d48b89 !important;
        }
        .btrf0 {
          border-top: 0;
          border-right: 0;
          border-left: 0;
        }
        .h200 {
          height: 200px;
          > img {
            max-height: 100%;
          }
        }
      }
    }
  }
}

@media print {
  @page {
    size: A4;
    margin: 0mm 10mm;
  }
  .inputAbailable {
    background-color: transparent !important;
  }
  html,
  body {
    height: auto;
    display: block !important;
    .wrapper {
      .container {
        margin: 0 auto !important;
        top: 0 !important;
        left: 0 !important;
        width: 95% !important;
        background-color: #fff;
        position: relative;
        font-size: 10.5pt;
        color: #000000;
        -webkit-print-color-adjust: exact;
        zoom: 0.9 !important;
        &:not(:last-child) {
          page-break-after: always;
        }
        section {
          margin-bottom: 25px;
          display: block;
          td,
          th {
            padding: 5px;
          }
          .title {
            background-color: #bbd090 !important;
          }
          .highlight {
            background-color: #d48b89 !important;
          }
          img {
            max-height: 400px;
          }
        }
      }
    }
  }
}
</style>
