var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: [_vm.$style.error, { [_vm.$style.center]: _vm.center }] },
    [
      _c("v-icon", { attrs: { size: "1.4em", color: "red" } }, [
        _vm._v("error"),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }