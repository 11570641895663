<template>
  <div :class="$style.wrapper" name="pr-text">
    <span v-if="label" name="label" :style="labelStyle"
      >{{ label
      }}<span
        v-if="
          requiredIcon !== undefined &&
            (validate ? validate.includes('required') : false)
        "
        :class="$style.required"
        >必須</span
      ></span
    >
    <input
      v-if="validate !== undefined && validate !== null"
      v-bind="$attrs"
      v-validate="validate"
      :data-vv-as="korean === undefined ? 'japanese' : 'korean'"
      :name="name"
      :type="type || 'text'"
      :value="value"
      :class="$style.input"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @blur="$emit('blur')"
      :style="inputStyle"
    />
    <input
      v-else
      v-bind="$attrs"
      :type="type || 'text'"
      :value="value"
      :class="$style.input"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @blur="$emit('blur')"
      :style="inputStyle"
    />
    <slot name="after" />
    <template v-if="validate">
      <PrErrorText v-show="errors.has(name)" :center="prRow[0] !== null">
        {{ errors.first(name) }}
      </PrErrorText>
    </template>
  </div>
</template>

<script>
import PrErrorText from "@/components/atoms/PrErrorText.vue"

export default {
  inject: ["$validator"],
  inheritAttrs: false,
  components: {
    PrErrorText,
  },
  props: {
    value: {
      required: true,
    },
    type: String,
    label: String,
    name: String,
    validate: String,
    vvas: String,
    korean: null,
    requiredIcon: null,
    prRow: {
      type: Array,
      default() {
        return [null, null]
      },
    },
  },
  computed: {
    labelStyle() {
      const { prRow } = this
      return {
        width: prRow[0] ? `${prRow[0]}%` : "auto",
      }
    },
    inputStyle() {
      const { prRow } = this
      return {
        width: prRow[1]
          ? `${prRow[1]}%`
          : prRow[0]
          ? `${100 - prRow[0]}%`
          : "auto",
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > span {
    &[name="label"] {
      display: inline-block;
      margin-bottom: 0.2em;
      color: map-get($colors, darkGlay);
    }
  }
}
.input {
  display: block;
  padding: 0.3em 0.5em;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 3px;
  background-color: #fff;
  outline: none;
  transition: 0.3s;
  &:focus {
    background-color: #fcfade;
    border-color: #00836b;
    box-shadow: 0 0 3px #00836b;
  }
  &:read-only,
  &:disabled {
    background-color: #eee;
    border-color: #ccc;
    box-shadow: none;
  }
}
.required {
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.5em;
  font-size: 0.1rem;
  font-weight: bold;
  margin-left: 0.6em;
  padding: 0.1em 0.3em;
  color: map-get($colors, white);
  background-color: map-get($colors, red);
}
</style>
