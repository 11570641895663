var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.sheet }, [
    _c("p", { class: _vm.$style.right }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.documentDate,
            expression: "documentDate",
          },
        ],
        class: _vm.$style.input,
        domProps: { value: _vm.documentDate },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.documentDate = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("p", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.customerName,
            expression: "customerName",
          },
        ],
        class: _vm.$style.nameInput,
        style: _vm.customerNameWidth,
        domProps: { value: _vm.customerName },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.customerName = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("span", { ref: "customerName", class: _vm.$style.customer }, [
        _vm._v(_vm._s(_vm.customerName)),
      ]),
    ]),
    _vm._v(" "),
    _c("p", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.personName,
            expression: "personName",
          },
        ],
        class: _vm.$style.nameInput,
        style: _vm.personNameWidth,
        domProps: { value: _vm.personName },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.personName = $event.target.value
          },
        },
      }),
      _vm._v("\n    様\n    "),
      _c("span", { ref: "personName", class: _vm.$style.person }, [
        _vm._v(_vm._s(_vm.personName)),
      ]),
    ]),
    _vm._v(" "),
    _c("p", { class: _vm.$style.right }, [_vm._v("株式会社ＰＲ３９")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("p", { class: _vm.$style.center }, [_vm._v("商品サンプル送付のご案内")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("p", [
      _c("span", { class: _vm.$style.tab1 }, [_vm._v("拝啓")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.kisya,
            expression: "kisya",
          },
        ],
        class: _vm.$style.nameInput,
        style: _vm.kisyaWidth,
        domProps: { value: _vm.kisya },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.kisya = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("span", { ref: "kisya", class: _vm.$style.kisya }, [
        _vm._v(_vm._s(_vm.kisya)),
      ]),
      _vm._v(
        "\n    ますますご清祥のこととお慶び申し上げます。平素は格別のご高配を賜り、厚くお礼申し上げます。\n  "
      ),
    ]),
    _vm._v(" "),
    _c("p", { class: _vm.$style.indent }, [
      _vm._v(
        "\n    さて、この度は下記弊社商品のサンプル送付をご依願いただきまして、誠にありがとうございます。商品に関してご不明な点等ございましたら、下記連絡先までお問い合わせいただければ、弊社社員より詳細にご説明させていただきます。\n  "
      ),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("p", { class: _vm.$style.indent }, [
      _vm._v("以上、取り急ぎ、ご案内まで。"),
    ]),
    _vm._v(" "),
    _c("p", { class: _vm.$style.right }, [_vm._v("敬具")]),
    _vm._v(" "),
    _c("p", { class: _vm.$style.center }, [_vm._v("記")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { class: _vm.$style.tab3 }, [
      _c("div", { class: _vm.$style.content }, [
        _c("p", { class: _vm.$style.tab2 }, [_vm._v("商品サンプル")]),
        _vm._v(" "),
        _c("p", { class: _vm.$style.preWrap }, [
          _vm._v(_vm._s(_vm.items.inquiry.note4)),
        ]),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { class: _vm.$style.content }, [
        _c("p", { class: _vm.$style.tab1 }, [_vm._v("連絡先")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contact }, [
          _c("p", [_vm._v("電話番号：092-409-6039")]),
          _vm._v(" "),
          _c("p", [_vm._v("ファックス番号：092-409-6097")]),
          _vm._v(" "),
          _c("p", [_vm._v("Ｅメールアドレス：info@pr39.com")]),
          _vm._v(" "),
          _c("p", [_vm._v("ホームページアドレス：www.pr39.com")]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("p", { class: _vm.$style.right }, [_vm._v("以上")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }