<template>
  <textarea
    ref="autoSizingTextarea"
    :value="value"
    @input="handleInput"
  ></textarea>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    handleInput(e) {
      this.$refs.autoSizingTextarea.style.height = "125px"
      this.$refs.autoSizingTextarea.style.height = `${
        this.$refs.autoSizingTextarea.scrollHeight
      }px`

      this.$emit("input", e.target.value)
    },
  },
}
</script>

<style>
textarea {
  overflow: hidden;
}
</style>
