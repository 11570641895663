var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$style.wrapper, attrs: { name: "pr-textarea" } },
    [
      _vm.label
        ? _c("span", { style: _vm.labelStyle, attrs: { name: "label" } }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.validate
        ? _c(
            "textarea",
            _vm._b(
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.validate,
                    expression: "validate",
                  },
                ],
                class: _vm.$style.textarea,
                style: _vm.inputStyle,
                attrs: { name: _vm.name, rows: _vm.rows },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", $event.target.value)
                  },
                  change: function ($event) {
                    return _vm.$emit("change", $event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$emit("blur")
                  },
                },
              },
              "textarea",
              _vm.$attrs,
              false
            )
          )
        : _c(
            "textarea",
            _vm._b(
              {
                class: _vm.$style.textarea,
                style: _vm.inputStyle,
                attrs: { rows: _vm.rows },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", $event.target.value)
                  },
                  change: function ($event) {
                    return _vm.$emit("change", $event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$emit("blur")
                  },
                },
              },
              "textarea",
              _vm.$attrs,
              false
            )
          ),
      _vm._v(" "),
      _vm.validate
        ? [
            _c(
              "PrErrorText",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has(_vm.name),
                    expression: "errors.has(name)",
                  },
                ],
                attrs: { center: _vm.prRow[0] !== null },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.errors.first(_vm.name)) + "\n    "
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }