var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c("div", { class: _vm.$style.inner }, [
      _c("h3", {}, [
        _vm._v("\n      " + _vm._s(_vm.code) + "\n      "),
        _c("br"),
        _vm._v("\n      " + _vm._s(_vm.message) + "\n    "),
      ]),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.subMessage) } }),
      _vm._v(" "),
      _c("div", { class: _vm.$style.base, on: { click: _vm.browserBack } }, [
        _vm._v("前のページへ戻る"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }