import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import common from "@/store/modules/common"
import auth from "@/store/modules/auth"
import pageinfo from "@/store/modules/pageinfo"
import searchDefinitions from "@/store/modules/searchDefinitions"
import messages from "@/store/modules/messages"
import customer from "@/store/modules/customer"

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    common: common,
    auth: auth,
    pageinfo: pageinfo,
    defs: searchDefinitions,
    messages: messages,
    customer: customer,
  },
  plugins: [
    createPersistedState({
      key: "pr39",
      paths: ["auth", "pageinfo"],
      storage: window.localStorage,
    }),
    createPersistedState({
      key: "pr39",
      paths: ["common", "messages", "customer"],
      storage: window.sessionStorage,
    }),
  ],
})

export default store
