<template>
  <InfoBlock width="100%" block margin="0">
    <template slot="head">
      納品情報
    </template>
    <div :class="$style.row">
      <PrDatePicker
        label="発送予定日"
        :pr-row="[30]"
        :value="value.delivery.request_date2"
        @input="updateValue($event, 'delivery.request_date2')"
      />
    </div>
    <div :class="$style.row">
      <PrDatePicker
        label="納品希望日"
        :pr-row="[30]"
        :value="value.request_date2"
        @input="updateValue($event, 'request_date2')"
      />
    </div>
    <div :class="$style.row">
      <PrSelect
        name="quantity1"
        label="納品形態"
        :pr-row="[30]"
        :value="value.delivery.quantity1"
        @input="updateValue($event, 'delivery.quantity1')"
        :options="$store.getters['defs/getOptions']('Delivery')"
        validate="required"
      />
    </div>
    <div :class="$style.row">
      <PrCheckbox
        before-label="発送代行有無"
        :pr-row="[30]"
        :value="value.delivery.quantity2"
        @input="updateValue($event, 'delivery.quantity2')"
      />
    </div>
    <div :class="$style.row">
      <PrDatePicker
        label="お届け予定日"
        :pr-row="[30]"
        :value="value.delivery.request_date3"
        @input="updateValue($event, 'delivery.request_date3')"
      />
    </div>
    <div :class="$style.row">
      <p>受取可能曜日</p>
      <div style="width:70%">
        <PrCheckbox
          v-for="(label, i) in ['月', '火', '水', '木', '金', '土', '日・祝']"
          :before-label="label"
          :value="days[i]"
          :key="label"
          @input="handleDaysInput($event, i)"
          style="margin: 0 10px 5px 0"
        />
      </div>
    </div>
    <div :class="$style.row">
      <PrSelect
        label="時間"
        :pr-row="[30]"
        :value="value.delivery.note2"
        @input="updateValue($event, 'delivery.note2')"
        :options="$store.getters['defs/getOptions']('Times')"
      />
    </div>
    <div :class="$style.row">
      <PrSelect
        label="納品書有無"
        :pr-row="[30]"
        :value="value.delivery.quantity3"
        @input="updateValue($event, 'delivery.quantity3')"
        :options="$store.getters['defs/getOptions']('DeliveryNote')"
      />
    </div>
    <div :class="$style.row">
      <PrCheckbox
        before-label="ヤマトタイムサービス"
        :pr-row="[30]"
        :value="value.delivery.quantity4"
        @input="updateValue($event, 'delivery.quantity4')"
      />
    </div>
    <div :class="$style.row">
      <PrTextarea
        label="伝票番号"
        :pr-row="[30]"
        :value="value.delivery.note3"
        @input="updateValue($event, 'delivery.note3')"
      />
    </div>
    <div :class="$style.row">
      <p :class="$style.distribution_item_info_row_name">
        自動発送メール
      </p>
      <div :class="$style.distribution_item_info_row_txt">
        <template
          v-if="deal.contract.find(({ status }) => status === 1).supplier"
        >
          <ModalMailTemplate
            :mail-type="
              value.contract.quantity4 === 1 ? 'shipment2' : 'shipment1'
            "
            :replace-values="deal | setMailTemplateValue"
          />
        </template>
        <template v-else
          >発注先を設定してください</template
        >
      </div>
    </div>
  </InfoBlock>
</template>

<script>
import PrSelect from "@/components/molecules/PrSelect.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import ModalMailTemplate from "@/components/molecules/ModalMailTemplate.vue"
import { setMailTemplateValue } from "@/helpers/AdminDealingDeliveryHelper.js"
import { deepCopy } from "@/utils/shared.js"

const days = ["月", "火", "水", "木", "金", "土", "日・祝"]

export default {
  inject: ["$validator"],
  components: {
    PrSelect,
    PrDatePicker,
    PrCheckbox,
    PrTextarea,
    InfoBlock,
    ModalMailTemplate,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    deal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      days: null,
    }
  },
  filters: {
    setMailTemplateValue,
  },
  methods: {
    updateValue(val, keys) {
      const arr = keys.split(".")
      const newValue = deepCopy(this.value)
      if (arr.length === 1) {
        newValue[arr[0]] = val
      }
      if (arr.length === 2) {
        newValue[arr[0]][arr[1]] = val
      }
      this.$emit("input", newValue)
    },
    handleDaysInput(e, idx) {
      this.days[idx] = e
      this.$emit("input", {
        ...this.value,
        delivery: {
          ...this.value.delivery,
          note1: days
            .reduce(
              (acc, day, idx) =>
                this.days[idx] === "1" ? `${acc}/${day}` : acc,
              "",
            )
            .slice(1),
        },
      })
    },
  },
  created() {
    this.days =
      this.value.delivery.note1 === null
        ? ["0", "0", "0", "0", "0", "0", "0"]
        : days.map(day => (this.value.delivery.note1.includes(day) ? "1" : "0"))
  },
}
</script>

<style lang="scss" module>
.row {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  &:nth-of-type(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  > div[name^="pr"] {
    width: 100%;
  }
  > p {
    &:nth-of-type(1) {
      color: map-get($colors, darkGlay);
      width: 30%;
    }
  }
}
</style>
