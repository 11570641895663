var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items !== null
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [_vm._v("注文履歴詳細")]),
          _vm._v(" "),
          _c("p", { class: _vm.$style.userName }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.items.customer_person.last_name +
                    " " +
                    _vm.items.customer_person.first_name +
                    " "
                ) +
                "\n    様\n  "
            ),
          ]),
          _vm._v(" "),
          _c("CustomerProductInfo", {
            attrs: { deal: _vm.items, "class-name": _vm.$style.inner },
          }),
          _vm._v(" "),
          _c("CustomerOrderAddress", {
            attrs: { deal: _vm.items, "class-name": _vm.$style.inner },
          }),
          _vm._v(" "),
          _c("CustomerPayment", {
            attrs: { deal: _vm.items, "class-name": _vm.$style.inner },
          }),
          _vm._v(" "),
          _c("CustomerOther", {
            attrs: { deal: _vm.items, "class-name": _vm.$style.inner },
          }),
          _vm._v(" "),
          _c(
            "Button",
            {
              staticStyle: { margin: "20px 0 50px" },
              attrs: { size: "main", color: "submit" },
              on: {
                click: function ($event) {
                  return _vm.$router.push(
                    "/customer/ordered-histories/" +
                      _vm.items.request_number +
                      "/re-order"
                  )
                },
              },
            },
            [_vm._v("再注文する")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }