<template>
  <InfoBlock width="100%" block margin="0">
    <template slot="head">
      {{ head }}
    </template>
    <div :class="$style.row">
      <PrInput
        :name="whom + '_address1.zip_code'"
        label="郵便番号"
        :value="value[whom + '_address1'].zip_code"
        @input="updateValue($event, '_address1', 'zip_code')"
        :validate="'zipcode'"
        :pr-row="[30, 30]"
      >
        <template slot="after">
          <ButtonSerchZipCode
            :zipcode="value[whom + '_address1'].zip_code"
            @click="handleZipSearch"
          />
        </template>
      </PrInput>
    </div>
    <div :class="$style.row">
      <PrInput
        :name="whom + '_address1.location'"
        label="都道府県名"
        :value="value[whom + '_address1'].location"
        @input="updateValue($event, '_address1', 'location')"
        :pr-row="[30]"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :name="whom + '_address1.address1'"
        label="市区町村"
        :value="value[whom + '_address1'].address1"
        @input="updateValue($event, '_address1', 'address1')"
        :pr-row="[30]"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :name="whom + '_address1.address2'"
        label="町名・番地"
        :value="value[whom + '_address1'].address2"
        @input="updateValue($event, '_address1', 'address2')"
        :pr-row="[30]"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="建物名"
        :value="value[whom + '_address2'].location"
        @input="updateValue($event, '_address2', 'location')"
        :pr-row="[30]"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="会社名"
        :value="value[whom + '_address2'].address1"
        @input="updateValue($event, '_address2', 'address1')"
        :pr-row="[30]"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        label="部署／役職"
        :value="value[whom + '_address2'].address2"
        @input="updateValue($event, '_address2', 'address2')"
        :pr-row="[30]"
      />
    </div>
    <div :class="$style.row">
      <p>担当者</p>
      <div :class="$style.person">
        <PrInput
          v-if="!isCPSearchOpen"
          :name="whom + '_address3.address1'"
          :value="value[whom + '_address3'].address1"
          @input="updateValue($event, '_address3', 'address1')"
          :pr-row="[0, 100]"
        />
        <PrSelect
          v-if="isCPSearchOpen"
          :value="value[whom + '_address3'].address1"
          :options="customerPersonOptions"
          @input="handleCustomerPersonSelect"
        />
        <SearchButton
          @click.native="isCPSearchOpen = !isCPSearchOpen"
          :close="isCPSearchOpen"
        />
      </div>
    </div>
    <div :class="$style.row">
      <PrInput
        :name="whom + '_address3.address2'"
        label="電話"
        :value="value[whom + '_address3'].address2"
        @input="updateValue($event, '_address3', 'address2')"
        :validate="'telephone'"
        :pr-row="[30]"
      />
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import ButtonSerchZipCode from "@/components/molecules/ButtonSerchZipCode.vue"
import api, { paths } from "@/utils/api.js"
import { deepCopy, showErrorToast } from "@/utils/shared.js"

export default {
  inject: ["$validator"],
  components: {
    PrInput,
    PrSelect,
    InfoBlock,
    SearchButton,
    ButtonSerchZipCode,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    deal: {
      type: Object,
      required: true,
    },
    customerPersonOptions: {
      type: Array,
      required: true,
    },

    whom: {
      type: String,
      required: true,
    },
    head: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCPSearchOpen: false,
    }
  },
  methods: {
    updateValue(val, key1, key2) {
      const newValue = deepCopy(this.value)
      newValue[this.whom + key1][key2] = val
      this.$emit("input", newValue)
    },
    async handleCustomerPersonSelect(id) {
      const newValue = deepCopy(this.value)

      if (id == 0) {
        const { pr39_address_1, pr39_address_2, pr39_address_3 } = this.deal
        newValue[this.whom + "_address1"] = {
          ...newValue[this.whom + "_address1"],
          zip_code: pr39_address_1.zip_code,
          location: pr39_address_1.location,
          address1: pr39_address_1.address1,
          address2: pr39_address_1.address2,
        }
        newValue[this.whom + "_address2"] = {
          ...newValue[this.whom + "_address2"],
          location: pr39_address_2.location,
          address1: pr39_address_2.address1,
          address2: pr39_address_2.address2,
        }
        newValue[this.whom + "_address3"] = {
          ...newValue[this.whom + "_address3"],
          address1: pr39_address_3.address1,
          address2: pr39_address_3.address2,
        }
      } else {
        const loader = this.$loading.show()
        try {
          const { ok, body, errorMsg } = await api.get(
            paths.ADMIN_CUSTOMER_PERSONS + "/" + id,
            this.$store.state.auth.token,
          )

          if (!ok) {
            showErrorToast(this, body.slice(-3), errorMsg)
            return
          }

          const title = (body.department || "") + " " + (body.title || "")

          newValue[this.whom + "_address1"] = {
            ...newValue[this.whom + "_address1"],
            zip_code: body.address.zip_code,
            location: body.address.location,
            address1: body.address.address1,
            address2: body.address.address2,
          }
          newValue[this.whom + "_address2"] = {
            ...newValue[this.whom + "_address2"],
            location: body.building,
            address1: body.customer.organization.name,
            address2: title.trim(),
          }
          newValue[this.whom + "_address3"] = {
            ...newValue[this.whom + "_address3"],
            address1: `${body.last_name} ${body.first_name}`,
            address2: body.phone,
          }
        } finally {
          loader.hide()
        }
      }

      this.$emit("input", newValue)
      this.isCPSearchOpen = !this.isCPSearchOpen
    },
    handleZipSearch({ ok, address }) {
      if (!ok) {
        return
      }
      const newValue = deepCopy(this.value)
      newValue[this.whom + "_address1"] = {
        ...newValue[this.whom + "_address1"],
        ...address,
      }
      this.$emit("input", newValue)

      for (const { name, scope } of [
        `${this.whom}_address1.location`,
        `${this.whom}_address1.address1`,
        `${this.whom}_address1.address2`,
      ].map(key => this.$validator.fields.find({ name: key }))) {
        this.errors.remove(name, scope)
      }
    },
  },
}
</script>

<style lang="scss" module>
.row {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  &:nth-of-type(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  > div[name^="pr"] {
    width: 100%;
  }
  > p {
    &:nth-of-type(1) {
      color: map-get($colors, darkGlay);
      width: 30%;
    }
  }
}

.person {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div[name^="pr"] {
    width: 80%;
  }
  > div[class~="v-autocomplete"] {
    width: 80%;
    padding: 0;
    margin: 0;
    div[class="v-text-field__details"] {
      display: none;
    }
  }
}
</style>
