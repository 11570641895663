<template>
  <IndexTableProvider
    v-if="items !== null"
    :items="items"
    :headers="headers"
    :filter-func="filterFunc"
    page-id="taxs"
  >
    <template slot="page-title">
      税率一覧
    </template>
    <Button
      slot="create-link"
      size="main"
      color="submit"
      plus
      to="/admin/taxes/new"
    >
      新しい税率を追加する
    </Button>

    <div
      slot="filter"
      slot-scope="{ filter, handleTextInput, handleFlagOn, filterClass }"
      :class="filterClass"
    >
      <PrInput
        label="キーワード"
        :pr-row="[100, 85]"
        :value="keywordHandler.value"
        @input="keywordHandler.input"
        style="justify-content:space-between;"
      >
        <SearchButton
          slot="after"
          @click="keywordHandler.click(handleFlagOn, handleTextInput, filter)"
          :close="keywordHandler.isOn"
        />
      </PrInput>
    </div>
  </IndexTableProvider>
</template>

<script>
import IndexTableProvider from "@/components/molecules/IndexTableProvider.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import SearchButton from "@/components/atoms/SearchButton.vue"
import Button from "@/components/atoms/Button.vue"
import { getDate, routeReplaceError } from "@/utils/shared.js"
import {
  keywordFilter,
  newWordSearchHandler,
} from "@/helpers/IndexTableHelper.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    PrInput,
    SearchButton,
    Button,
    IndexTableProvider,
  },
  data() {
    const { filter } = this.$store.getters["pageinfo/getPageInfo"]("taxs")
    return {
      items: null,
      keywordHandler: newWordSearchHandler("keyword", filter),
      headers: [
        {
          text: "税率名",
          value: "name",
          link: ({ id }) => `/admin/taxes/${id}`,
        },
        { text: "税区分", value: "tax_category" },
        { text: "税率(%)", value: ({ tax_rate }) => tax_rate.padStart(2, "0") },
        {
          text: "適用開始",
          value: ({ start_datetime }) => getDate(start_datetime),
        },
        {
          text: "適用終了",
          value: ({ end_datetime }) => getDate(end_datetime),
        },
        { text: "更新日付", value: ({ updated_at }) => getDate(updated_at) },
      ],
    }
  },
  methods: {
    filterFunc: ({ keyword }) => item => {
      let test = true

      test = keywordFilter(keyword, test, item)

      return test
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      const { ok, body } = await api.get(
        paths.ADMIN_TAXES,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.items = body
    } finally {
      loader.hide()
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>
