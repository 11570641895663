<template>
  <InfoBlock width="400px" margin="0" block>
    <template slot="head"
      >入金／商品配送情報</template
    >
    <div :class="$style.deliveryInfo">
      <div :class="$style.deliveryInfo_row">
        <p :class="$style.deliveryInfo_name">入金方法</p>
        <p :class="$style.deliveryInfo_text">
          {{ payment }}
        </p>
      </div>
      <div :class="$style.deliveryInfo_row">
        <p :class="$style.deliveryInfo_name">入金予定日</p>
        <p :class="$style.deliveryInfo_text">
          <template v-if="orderedContract">
            <span>{{ orderedContract.request_date3 | getDate }}</span>
            <span>入金確認</span>
            <InputCheckBox
              :value="orderedContract.request_date4 ? '1' : '0'"
              disabled
            />
          </template>
          <span v-else>未注文</span>
        </p>
      </div>
      <div :class="$style.deliveryInfo_row">
        <p :class="$style.deliveryInfo_name">納品希望日</p>
        <p v-if="orderedContract" :class="$style.deliveryInfo_text">
          {{ items.request_date2 | getDate }}
        </p>
        <p v-else :class="$style.deliveryInfo_text">未注文</p>
      </div>
      <div :class="$style.deliveryInfo_row">
        <p :class="$style.deliveryInfo_name">出庫予定日</p>
        <p v-if="orderedContract" :class="$style.deliveryInfo_text">
          {{ items.product.request_date1 | getDate }}
        </p>
        <p v-else :class="$style.deliveryInfo_text">未注文</p>
      </div>
      <div :class="$style.deliveryInfo_row">
        <p :class="$style.deliveryInfo_name">発送予定日</p>
        <p v-if="orderedContract" :class="$style.deliveryInfo_text">
          {{ items.delivery.request_date2 | getDate }}
        </p>
        <p v-else :class="$style.deliveryInfo_text">未注文</p>
      </div>
      <div :class="$style.deliveryInfo_row">
        <p :class="$style.deliveryInfo_name">納品書有無</p>
        <p :class="$style.deliveryInfo_text">
          {{ deliveryNote }}
        </p>
      </div>
    </div>
    <div :class="$style.button">
      <Button
        v-if="orderedContract"
        color="submit"
        size="main"
        @click.native="
          $router.push(`/admin/dealings/${items.request_number}/delivery`)
        "
        >編集する</Button
      >
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import InputCheckBox from "@/components/atoms/InputCheckBox.vue"
import Button from "@/components/atoms/Button.vue"
import { getDate } from "@/utils/shared.js"

export default {
  components: {
    InfoBlock,
    InputCheckBox,
    Button,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orderedContract() {
      return this.items.contract.find(({ status }) => status === 1)
    },
    payment() {
      if (!this.orderedContract) {
        return "未注文"
      }

      const { quantity4 } = this.orderedContract
      if (quantity4 == null || quantity4 == undefined) {
        return "未設定"
      }

      return this.$store.getters["defs/getOptionValueByKey"](
        "PaymentMethod",
        quantity4,
      )
    },
    deliveryNote() {
      if (!this.orderedContract) {
        return "未注文"
      }

      const { quantity3 } = this.items.delivery
      if (quantity3 == null || quantity3 == undefined) {
        return "未設定"
      }

      return quantity3 == 0 ? "無し" : "有り"
    },
  },
  filters: {
    getDate: str => getDate(str),
  },
}
</script>

<style lang="scss" module>
.deliveryInfo_row {
  display: flex;
  height: 40px;
  align-items: center;
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
}

.deliveryInfo_name {
  width: 140px;
  padding-left: 10px;
  font-size: 0.14rem;
  color: map-get($colors, darkGlay);
}

.deliveryInfo_text {
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
}

.button {
  padding: 20px 15px 15px;
  display: flex;
  justify-content: flex-end;
}
</style>
