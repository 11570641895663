<template>
  <div :class="$style.wrapper">
    <div :class="$style.content">
      <Anker to="/login">ログイン画面に戻る</Anker>
      <div :class="$style.formAria">
        <p :class="$style.formAria_text" v-cloak>
          <span>{{ email }}</span>
          へパスワード変更手続きについてのメールを送信しました。
          <br />メールの内容をご確認ください。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Anker from "@/components/atoms/Anker.vue"

export default {
  components: {
    Anker,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.state.common.password.reminder.email) {
        vm.$router.replace({ path: "/password/reminder" })
      }
    })
  },
  data() {
    return {
      email: null,
    }
  },
  created() {
    this.email = this.$store.state.common.password.reminder.email
  },
}
</script>

<style lang="scss" module>
.wrapper {
  min-height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  width: 100%;
  padding: 0 20px;
  max-width: 800px;
  margin-top: 50px;
}

.title {
  padding: 55px 0;
}

.formAria {
  //width: 800px;
  margin: 0 auto;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  &_text {
    padding: 30px;
    > span {
      font-weight: bold;
    }
  }
}
</style>
