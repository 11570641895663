var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "685px", margin: "0", block: "" } },
    [
      _c("template", { slot: "head" }, [_vm._v("見積り／注文")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "head-right" },
        [
          _vm.printContracts.length != 0
            ? _c(
                "v-btn",
                {
                  attrs: { flat: "", icon: "", color: "indigo" },
                  on: { click: _vm.handlePrintClick },
                },
                [_c("v-icon", [_vm._v("print")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.tableWrapper }, [
        _vm.contracts.length !== 0
          ? _c("table", { class: _vm.$style.table }, [
              _c("thead", { class: _vm.$style.thead }, [
                _c("tr", [
                  _c("td", [_vm._v("印刷")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("見積名")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("見積総額(税別)")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("単価")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("見積先")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("見積日")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("色校")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("注文")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("公開")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                { class: _vm.$style.tbody },
                _vm._l(_vm.contracts, function (contract) {
                  return _c("tr", { key: contract.id, class: _vm.$style.tr }, [
                    _c(
                      "td",
                      [
                        _c("PrCheckbox", {
                          model: {
                            value: _vm.printTargets[contract.request_key2],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.printTargets,
                                contract.request_key2,
                                $$v
                              )
                            },
                            expression: "printTargets[contract.request_key2]",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { class: _vm.$style.td },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: `/admin/dealings/${contract.request_number}/contracts/${contract.contract.contract_no}`,
                            },
                          },
                          [_vm._v(_vm._s(contract.request_name || "未設定"))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { class: _vm.$style.td }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("formatPrice")(
                              contract.contract.total_amount
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { class: _vm.$style.td }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("getUnitPrice")(
                              contract.contract.contract_details
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { class: _vm.$style.td }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            contract.supplier
                              ? contract.supplier.user.name
                              : "未設定"
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { class: _vm.$style.td }, [
                      _vm._v(_vm._s(_vm._f("getDate")(contract.request_date1))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { class: _vm.$style.td },
                      [
                        _c("PrCheckbox", {
                          attrs: { value: contract.quantity3, disabled: "" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { class: _vm.$style.td },
                      [
                        _c("PrCheckbox", {
                          attrs: { value: contract.status, disabled: "" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { class: _vm.$style.td },
                      [
                        _c("DialogProvider", {
                          attrs: {
                            texts: {
                              ttl: "確認",
                              msg: `${contract.request_name || "名称未設定"}を${
                                contract.quantity2 == 0 ? "公開" : "非公開に"
                              }しますか？`,
                              yas: "OK",
                              no: "キャンセル",
                            },
                          },
                          on: {
                            accept: function ($event) {
                              return _vm.handleReleaseAccept(contract)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          "!click": function ($event) {
                                            $event.stopPropagation()
                                            return on.click.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("PrCheckbox", {
                                          staticStyle: {
                                            "pointer-events": "none",
                                          },
                                          attrs: { value: contract.quantity2 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ])
          : _c("p", { class: _vm.$style.not }, [
              _vm._v("登録された見積りはありません"),
            ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.buttons },
          [
            _vm.contracts.length !== 0
              ? _c(
                  "Anker",
                  {
                    attrs: {
                      to: `/admin/dealings/${_vm.items.request_number}/dealing-contracts`,
                    },
                  },
                  [_vm._v("見積品目一覧")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.items.status <= 3
              ? _c(
                  "Button",
                  {
                    attrs: { color: "submit", size: "main" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$router.push(
                          `/admin/dealings/${_vm.items.request_number}/contracts/new`
                        )
                      },
                    },
                  },
                  [_vm._v("新規見積")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }