<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>ご商談一覧</PageTitle>

    <div v-if="itemsDealings.length !== 0" :class="$style.dealings">
      <div :class="$style.select">
        <p>注文月</p>
        <TheSelect
          v-model="selectedMonth"
          :options="options"
          background="#fff"
        />
      </div>
      <div :class="$style.select">
        <p>担当者</p>
        <TheSelect
          v-model="selectedPerson"
          :options="personsOptions"
          background="#fff"
        />
      </div>
      <div :class="$style.select">
        <p>ステータス</p>
        <TheSelect
          v-model="selectedStatus"
          :options="statusOptions"
          background="#fff"
        />
      </div>
    </div>
    <div v-if="itemsDealings.length !== 0" :class="$style.dealings">
      <template v-if="filteredItems.length !== 0">
        <div v-for="item in filteredItems" :key="item.request_number">
          <div :class="$style.deal">
            <h3>商談管理No : {{ item.request_number }}</h3>
            <h3>担当者 : {{ item.contact_name }}</h3>
            <div
              :class="[
                $style.dealInner,
                {
                  [$style.deal_active]:
                    status[item.request_number].display.status === 1,
                },
              ]"
            >
              <div :class="$style.deal_ns">
                <p :class="$style.deal_ns_name" v-cloak>
                  {{ item.request_name }}
                </p>
                <p :class="$style.deal_ns_status" v-cloak>
                  {{ status[item.request_number].display.label }}
                </p>
              </div>
              <div :class="$style.deal_dp">
                <p :class="$style.deal_dp_txt" v-cloak>
                  発生日：{{ item.request_date1 | getDate }}
                </p>
                <p
                  v-if="!item.customer_person"
                  :class="$style.deal_dp_txt"
                  v-cloak
                >
                  御社担当者が不明です
                </p>
                <p v-else :class="$style.deal_dp_txt" v-cloak>
                  {{ item.customer_person.last_name }}
                  {{ item.customer_person.first_name }} 様
                </p>
              </div>
              <p
                v-if="status[item.request_number].estimate.status === 2"
                :class="$style.deal_deliveryDate"
                v-cloak
              >
                発送予定日：{{ item.delivery.request_date2 | getDate }}
              </p>
              <div :class="$style.deal_action">
                <p :class="$style.deal_action_txt">・見積確認/注文</p>
                <Button
                  width="120px"
                  :color="status[item.request_number].estimate.color"
                  size="main"
                  :disabled="status[item.request_number].estimate.disabled"
                  @click="handleCheckQuotation(item.request_number)"
                  v-cloak
                  >{{ status[item.request_number].estimate.label }}</Button
                >
              </div>
              <div :class="$style.deal_action">
                <p :class="$style.deal_action_txt">・データ入稿画面</p>
                <Button
                  width="120px"
                  :color="status[item.request_number].draft.color"
                  size="main"
                  :disabled="status[item.request_number].draft.disabled"
                  @click="handleUpload(item.request_number)"
                  v-cloak
                >
                  {{ status[item.request_number].draft.label }}
                </Button>
              </div>
              <div :class="$style.deal_action">
                <p :class="$style.deal_action_txt">・最終確認画像</p>
                <Button
                  width="120px"
                  :color="status[item.request_number].verify.color"
                  size="main"
                  :disabled="status[item.request_number].verify.disabled"
                  @click="handleCheckImage(item.request_number)"
                  v-cloak
                >
                  {{ status[item.request_number].verify.label }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else :class="$style.nodata">
        該当するご商談がありません
      </div>
    </div>
    <div v-else :class="$style.nodata">
      ご商談がありません
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import TheSelect from "@/components/molecules/TheSelect.vue"
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { getDate, routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    Button,
    PageTitle,
    TheSelect,
  },
  data() {
    return {
      isReady: false,
      itemsDealings: [],
      status: {},
      selectedMonth: 0,
      selectedPerson: 0,
      selectedStatus: 0,
    }
  },
  computed: {
    orderedMonths() {
      return this.itemsDealings.reduce((acc, deal) => {
        const date = new Date(deal.request_date1)
        const key =
          String(date.getFullYear()) +
          String(date.getMonth() + 1).padStart(2, "0")

        if (acc[key]) {
          acc[key] = [...acc[key], deal.request_number]
        } else {
          acc[key] = [deal.request_number]
        }

        return acc
      }, {})
    },
    options() {
      return Object.keys(this.orderedMonths).reduce(
        (acc, m) => [
          ...acc,
          {
            key: m,
            value: m.slice(0, 4) + "年" + m.slice(-2) + "月の注文",
          },
        ],
        [{ key: 0, value: "すべて" }],
      )
    },
    persons() {
      let i = 1
      let names = []
      let keys = []
      const spareter = "____"
      return this.itemsDealings.reduce(
        (acc, { customer_person, request_number }) => {
          let last_name = customer_person ? customer_person.last_name : ""
          let first_name = customer_person ? customer_person.first_name : ""
          let name
          if (last_name || first_name) {
            name = `${last_name} ${first_name}`
          } else {
            name = "-- 不明 --"
          }
          if (names.includes(name)) {
            const key = keys.find(val => val.endsWith(name || "未設定"))
            acc[key] = [...acc[key], request_number]
          } else {
            const key = name
              ? `${i}${spareter}${name}`
              : `${i}${spareter}未設定`
            names.push(name)
            keys.push(key)
            i++
            acc[key] = [request_number]
          }

          return acc
        },
        {},
      )
    },
    personsOptions() {
      return Object.keys(this.persons).reduce(
        (acc, m) => [
          ...acc,
          {
            key: m.split("____")[0],
            value: m.split("____")[1],
          },
        ],
        [{ key: 0, value: "すべて" }],
      )
    },
    statusArr() {
      return this.itemsDealings.reduce(
        (acc, { request_number, display_status }) => {
          const key = +display_status + 1
          if (acc[key]) {
            acc[key] = [...acc[key], request_number]
          } else {
            acc[key] = [request_number]
          }

          return acc
        },
        {},
      )
    },
    statusOptions() {
      const sa = Object.keys(this.statusArr)

      return this.$store.getters["defs/getOptions"]("CustomerOpportunity")
        .filter(({ key }) => sa.includes(String(+key + 1)))
        .reduce((acc, { key, value }) => [...acc, { key: +key + 1, value }], [
          { key: 0, value: "すべて" },
        ])
    },
    filteredItems() {
      const {
        selectedMonth,
        itemsDealings,
        orderedMonths,
        selectedPerson,
        persons,
        selectedStatus,
        statusArr,
      } = this

      if (selectedMonth != 0 && selectedPerson == 0 && selectedStatus == 0) {
        return orderedMonths[selectedMonth].map(rn =>
          itemsDealings.find(({ request_number }) => request_number === rn),
        )
      }

      if (selectedMonth == 0 && selectedPerson != 0 && selectedStatus == 0) {
        return persons[
          Object.keys(persons).find(key => key.startsWith(selectedPerson))
        ].map(rn =>
          itemsDealings.find(({ request_number }) => request_number === rn),
        )
      }

      if (selectedMonth == 0 && selectedPerson == 0 && selectedStatus != 0) {
        const status = statusArr[selectedStatus]
        return itemsDealings.filter(({ request_number }) =>
          status.includes(request_number),
        )
      }

      if (selectedMonth != 0 && selectedPerson != 0 && selectedStatus == 0) {
        const oms = orderedMonths[selectedMonth]
        const ps =
          persons[
            Object.keys(persons).find(key => key.startsWith(selectedPerson))
          ]

        return itemsDealings.filter(
          ({ request_number }) =>
            ps.includes(request_number) && oms.includes(request_number),
        )
      }

      if (selectedMonth != 0 && selectedPerson == 0 && selectedStatus != 0) {
        const oms = orderedMonths[selectedMonth]
        const status = statusArr[selectedStatus]

        return itemsDealings.filter(
          ({ request_number }) =>
            status.includes(request_number) && oms.includes(request_number),
        )
      }

      if (selectedMonth == 0 && selectedPerson != 0 && selectedStatus != 0) {
        const ps =
          persons[
            Object.keys(persons).find(key => key.startsWith(selectedPerson))
          ]
        const status = statusArr[selectedStatus]

        return itemsDealings.filter(
          ({ request_number }) =>
            status.includes(request_number) && ps.includes(request_number),
        )
      }

      if (selectedMonth != 0 && selectedPerson != 0 && selectedStatus != 0) {
        const oms = orderedMonths[selectedMonth]
        const ps =
          persons[
            Object.keys(persons).find(key => key.startsWith(selectedPerson))
          ]
        const status = statusArr[selectedStatus]

        return itemsDealings.filter(
          ({ request_number }) =>
            status.includes(request_number) &&
            ps.includes(request_number) &&
            oms.includes(request_number),
        )
      }

      return itemsDealings
    },
  },
  async created() {
    const loader = this.$loading.show()
    try {
      this.$store.dispatch("customer/clearDealing")
      this.$store.dispatch("customer/clearRequestId")
      this.$store.dispatch("customer/clearEstimateNo")
      this.$store.dispatch("customer/clearOrderInfo")

      //------------------------
      // 商談一覧
      //------------------------
      // 商談ID([x].request_number)
      // 商談名([x].request_name)
      // ステータス([x].display_status)
      // 発生日([x].request_date1)
      // 発送予定日([x].product.request_date2)
      // 責任者([x].customer_person.last_name + [x].customer_person.first_name)
      // 見積確認/注文([x].contract_status)
      // データ入稿画面([x].request_appendices[y].request_key4, appendix_category="draft"のもの)
      // 最終確認画像([x].request_appendices[y].request_key4, appendix_category="verification_image")
      let result = await this.getDealings()
      if (!result.ok) {
        routeReplaceError(this, result.body.slice(-3))
        return
      }
      this.itemsDealings = result.body
      this.itemsDealings.forEach(item => {
        let status = {
          display: {},
          estimate: {},
          draft: {},
          verify: {},
        }

        // ステータス
        status.display = this.getDisplayStatus(item.display_status)

        // 見積確認・注文
        status.estimate = this.getEstimateStatus(item.contract_status)

        // データ入稿画面、最終確認画像
        item.request_appendices.forEach(data => {
          switch (data.appendix_category) {
            case "draft":
              // データ入稿画面
              status.draft = this.getDraftStatus(data.request_key4)
              break
            case "verification_image":
              // 最終確認画像
              status.verify = this.getVerifyStatus(data.request_key4)
              break
          }
        })
        this.status[item.request_number] = JSON.parse(JSON.stringify(status))
      })
      this.isReady = true
    } finally {
      loader.hide()
    }
  },
  filters: {
    getDate(dateStr) {
      return getDate(dateStr)
    },
  },
  methods: {
    async getDealings() {
      const { ok: result, body: items } = await api.get(
        paths.CUSTOMER_DEALINGS,
        this.$store.state.auth.token,
      )

      return { ok: result, body: items }
    },
    toDefsString(category, key) {
      const str = this.$store.getters["defs/getOptionValueByKey"](category, key)
      return str
    },
    getDisplayStatus(value) {
      let data = {}

      data.status = value
      data.label = this.toDefsString("CustomerOpportunity", value)

      return data
    },
    getEstimateStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 未確認
        1: {
          color: "alert",
          disabled: false,
        },
        // 確認済み
        2: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = value
      data.label = this.toDefsString("CustomerEstimates", value)
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    getDraftStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 入稿する
        1: {
          color: "alert",
          disabled: false,
        },
        // 入稿済み
        2: {
          color: "checked",
          disabled: true,
        },
        // 入稿済み
        3: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = value
      data.label = this.toDefsString("DraftStatus_C", value)
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    getVerifyStatus(value) {
      let data = {}

      const table = {
        // 準備中
        0: {
          color: "cancel",
          disabled: true,
        },
        // 未確認
        1: {
          color: "alert",
          disabled: false,
        },
        // 確認済み
        2: {
          color: "checked",
          disabled: true,
        },
      }

      data.status = value
      data.label = this.toDefsString("VerificationStatus_C", value)
      data.color = table[value].color
      data.disabled = table[value].disabled

      return data
    },
    handleCheckQuotation(id) {
      this.$store.dispatch("customer/setRequestId", id)
      this.$router.push({ path: `/customer/dealings/${id}/contract` })
    },
    handleCheckImage(id) {
      this.$router.push({
        path: `/customer/dealings/${id}/verification-image`,
      })
    },
    handleUpload(id) {
      this.$router.push({ path: `/customer/dealings/${id}/draft-upload` })
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 100vw;
  padding: 0 10px;
}

.dealings {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.deal {
  width: 320px;
  margin: 10px;
  > h3 {
    background-color: map-get($colors, lightYellow);
    font-size: 0.16rem;

    &:nth-of-type(1) {
      padding: 5px 5px;
      text-align: left;
    }
    &:nth-of-type(2) {
      padding: 0 5px 5px 5px;
      text-align: right;
    }
  }

  &Inner {
    width: 100%;
    padding: 10px;
    border: 1px solid map-get($colors, lightYellow);
    background-color: map-get($colors, white);
    position: relative;
    > * {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &_active {
    background-color: #fff4f2;
  }
  &_ns {
    display: flex;
    justify-content: space-between;
    &_name {
      width: 50%;
      font-size: 0.18rem;
      font-weight: bold;
    }
    &_status {
      margin-bottom: 15px;
      width: 50%;
      font-size: 0.18rem;
      font-weight: bold;
      text-align: right;
      color: map-get($colors, red);
    }
  }
  &_dp {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
  }
  &_deliveryDate {
    width: 100%;
    margin-bottom: 10px;
    padding: 3px 0;
    text-align: center;
    font-size: 0.14rem;
    background-color: map-get($colors, lightlightGray);
  }
  &_action {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.nodata {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  font-size: 0.3rem;
  padding: 100px 0;
  border-radius: 20px;
  background-color: white;
}
.select {
  width: 320px;
  padding: 5px 10px;
  margin: 0 10px 10px;
  display: flex;
  align-items: center;
  background-color: map-get($colors, lightGray);
  border-radius: 5px;
  > p {
    font-weight: bold;
    font-size: 0.16rem;
    width: 150px;
  }
}
</style>
