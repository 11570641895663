var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OrdersheetProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ values, onChanged, isSupplier, isEdit }) {
          return [
            _c(
              "div",
              { class: _vm.$style.wrapper },
              _vm._l(
                values.contract.contract.contract_details,
                function (detail, index) {
                  return _c(
                    "div",
                    { key: index, class: _vm.$style.container },
                    [
                      _c("section", { class: _vm.$style.section1 }, [
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "18%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "18%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "18%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "18%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "18%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", { attrs: { colspan: "6" } }, [
                                _vm._v("발 주 서"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("수 신")]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tal }, [
                                _vm._v("아크로 엠엔비"),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v("사업자등록번호")]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "3" } }),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("일 자")]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tal }),
                              _vm._v(" "),
                              _c("td", [_vm._v("상호")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("pr39")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("대표")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("임과창")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("전 화")]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tal }, [
                                _vm._v("02-2272-5012"),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v("사 업 장 주소")]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v(
                                  "\n                  후쿠오카현 후쿠오카시 하카타쿠 타케시타1-11-23\n                "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("팩스")]),
                              _vm._v(" "),
                              _c("td", { class: _vm.$style.tal }, [
                                _vm._v("02-2272-1006"),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v("업태")]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td", [_vm._v("종목")]),
                              _vm._v(" "),
                              _c("td"),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { attrs: { colspan: "2" } }),
                              _vm._v(" "),
                              _c("td", [_vm._v("전화")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("092-409-6039")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("팩스")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("092-409-6097")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", {
                                attrs: { colspan: "2", rowspan: "2" },
                              }),
                              _vm._v(" "),
                              _c("td", [_vm._v("담당자")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    values.user ? values.user.name : "미 설정"
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v("담당전화(휴대폰)")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("070-8234-2510")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v("세금계산서 "),
                                _c("br"),
                                _vm._v("(E-maill주소)"),
                              ]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c(
                                  "a",
                                  { attrs: { href: "mailto:info@pr39.com" } },
                                  [_vm._v("info@pr39.com")]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("- 아래와 같이 포스트잇을 발주 드립니다."),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", { class: _vm.$style.section2 }, [
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "16%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "26%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "16%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "16%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "26%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("1. 발주 내용 :")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "category1_" + index,
                                            validate: "required",
                                            options: _vm.productFamilyOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: detail.category1,
                                            callback: function ($$v) {
                                              _vm.$set(detail, "category1", $$v)
                                            },
                                            expression: "detail.category1",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "ProductFamily",
                                                detail.category1,
                                                "KR"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("th", [_vm._v("6.포장")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("OPP개별포장")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "quantity6",
                                            validate: "required",
                                            options: _vm.oppPackagingOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order
                                                .packaging_individual,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "packaging_individual",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.packaging_individual\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "OppPackaging",
                                                detail.purchase_order
                                                  .packaging_individual
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("2. 발주 일자 :")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: [
                                    {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                    _vm.$style.tac,
                                  ],
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrDatePicker", {
                                          attrs: {
                                            name: "request_date5",
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.request_date5,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract,
                                                "request_date5",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.contract.request_date5",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("getDate")(
                                                values.contract.request_date5
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("th"),
                              _vm._v(" "),
                              _c("td", [_vm._v("박스포장")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrSelect", {
                                          attrs: {
                                            name: "quantity5",
                                            validate: "required",
                                            options: _vm.bundlePackagingOptions,
                                            "pr-row": [0, 100],
                                            autoWidth: false,
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order
                                                .packaging_bundle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "packaging_bundle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.packaging_bundle\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.toDefsString(
                                                "BundlePackaging",
                                                detail.purchase_order
                                                  .packaging_bundle,
                                                "KR"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("3. 견적 세부 사항")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                  attrs: { colspan: "4" },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "note1",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order.description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.description\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            detail.purchase_order.description
                                          )
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", { class: _vm.$style.section3 }, [
                        _c("p", [_vm._v("(단위:원, VAT별도)")]),
                        _vm._v(" "),
                        _c("table", [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "16%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "16%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "16%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "16%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "16%" } }),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("번 호")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("품 명")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("매수(장)")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("주문수량 (부)")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("공급단가 (원)")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("합계")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("계산서 발행여부")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "request_name",
                                            validate: "required|numeric",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value:
                                              values.contract.contract
                                                .contract_details[index]
                                                .purchase_order.data_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract.contract
                                                  .contract_details[index]
                                                  .purchase_order,
                                                "data_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        values.contract.contract.contract_details[index]\n                          .purchase_order.data_name\n                      ",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            detail.purchase_order.data_name
                                          )
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "description",
                                            validate: "required",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: values.contract.description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                values.contract,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values.contract.description",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(values.contract.description)
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "size3_" + index,
                                            validate: "required|numeric",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: detail.size3,
                                            callback: function ($$v) {
                                              _vm.$set(detail, "size3", $$v)
                                            },
                                            expression: "detail.size3",
                                          },
                                        }),
                                      ]
                                    : [_vm._v(_vm._s(detail.size3))],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "amount_" + index,
                                            validate: "required|numeric",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: detail.amount,
                                            callback: function ($$v) {
                                              _vm.$set(detail, "amount", $$v)
                                            },
                                            expression: "detail.amount",
                                          },
                                        }),
                                      ]
                                    : [_vm._v(_vm._s(detail.amount))],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    [_vm.$style.inputAbailable]: !isSupplier,
                                  },
                                },
                                [
                                  isEdit
                                    ? [
                                        _c("PrInput", {
                                          attrs: {
                                            type: "text",
                                            name: "purchase_amount_" + index,
                                            validate: "required|numeric",
                                            "pr-row": [0, 100],
                                          },
                                          on: { input: onChanged },
                                          model: {
                                            value: detail.purchase_amount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                detail,
                                                "purchase_amount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detail.purchase_amount",
                                          },
                                        }),
                                      ]
                                    : [_vm._v(_vm._s(detail.purchase_amount))],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.calcPrice(
                                        detail.amount,
                                        detail.purchase_amount
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v("유, 무")]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", { class: _vm.$style.section4 }, [
                        _c("p", [_vm._v("4. 납품처")]),
                        _vm._v(" "),
                        _c("div", { class: _vm.$style.delivery }, [
                          _c("table", [
                            _c("colgroup", [
                              _c("col", { staticStyle: { width: "20%" } }),
                              _vm._v(" "),
                              _c("col", { staticStyle: { width: "30%" } }),
                            ]),
                            _vm._v(" "),
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v("보내는곳")]),
                                _vm._v(" "),
                                _c("td", [_vm._v("아크로 엠엔비")]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("받는곳")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    부산시동구초량동충장대로206 "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "국제여객터미널 탁송화물\n                    검사장\n                  "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("받는사람")]),
                                _vm._v(" "),
                                _c("td", [_vm._v("김광애")]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [
                                  _vm._v("받는곳전화 "),
                                  _c("br"),
                                  _vm._v("(휴대폰)"),
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v("011-835-8045")]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [
                                  _vm._v("납품일 "),
                                  _c("br"),
                                  _vm._v("(희망일)"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      [_vm.$style.inputAbailable]: !isSupplier,
                                    },
                                  },
                                  [
                                    isEdit
                                      ? [
                                          _c("PrDatePicker", {
                                            attrs: {
                                              name: "request_date1",
                                              validate: "required",
                                              "pr-row": [0, 100],
                                            },
                                            on: { input: onChanged },
                                            model: {
                                              value:
                                                values.product.request_date1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  values.product,
                                                  "request_date1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "values.product.request_date1",
                                            },
                                          }),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm._f("getDate")(
                                                  values.product.request_date1
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("배송비")]),
                                _vm._v(" "),
                                _c("td", [_vm._v("선불")]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$style.imgArea }, [
                            _c("img", {
                              attrs: {
                                src: _vm.getVerificationImageUrl(
                                  values.request_appendices
                                ),
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", { class: _vm.$style.section5 }, [
                        _c("p", [_vm._v("5. 비 고")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("― 세부사항")]),
                        _vm._v(" "),
                        _c("h2", [
                          _vm._v("상기와 같이 발주서를 제출합니다. 끝."),
                        ]),
                      ]),
                    ]
                  )
                }
              ),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }