<template>
  <label :class="$style.select">
    <select
      :style="{ background: background }"
      :value="Number(value)"
      @change="e => $emit('input', e.currentTarget.value)"
    >
      <option
        v-for="({ key, value }, i) in options"
        :value="Number(key)"
        :key="i"
        >{{ value }}</option
      >
    </select>
    <span></span>
  </label>
</template>

<script>
export default {
  $_veeValidate: {
    value() {
      return this.value
    },
  },
  props: {
    options: {
      type: Array,
    },
    value: {
      type: [String, Number],
    },
    background: {
      type: String,
      default: "inhert",
    },
  },
}
</script>

<style lang="scss" module>
@include theSelect();
</style>
