<template>
  <router-link :to="to" :class="$style.base" @click.native="handleClick">
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click")
    },
  },
}
</script>

<style lang="scss" module>
.base {
  display: block;
  padding-left: 0.8em;
  color: #016cca;
  font-size: 0.18rem;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 0.4em);
    left: 0;
    border: 0.4em solid transparent;
    border-left-color: #016cca;
  }
}
</style>
