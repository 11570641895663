var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._g(
      {
        class: [
          _vm.$style.searchButton,
          { [_vm.$style.nobg]: _vm.nobg, [_vm.$style.close]: _vm.close },
        ],
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }