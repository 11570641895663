var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("p", { class: _vm.$style.userName }, [
    _vm._v(
      "\n  " +
        _vm._s(
          _vm.customerPerson.last_name +
            " " +
            _vm.customerPerson.first_name +
            " "
        ) +
        "様\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }