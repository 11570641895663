var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.values !== null
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [_vm._v("詳細品目 オーダーメイド見積")]),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "1600px", block: "", margin: "0 0 20px" } },
            [
              _c("template", { slot: "head" }, [_vm._v("見積概要")]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.row },
                [
                  _c("div", { class: _vm.$style.custom }, [
                    _c("p", [_vm._v("見積名")]),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: `/admin/contracts/${this.items.contract.request_key2}/dealings/${this.items.request_number}`,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(this.items.contract.request_name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("PrDatePicker", {
                    attrs: { label: "見積依頼日", "pr-row": [35] },
                    model: {
                      value: _vm.values.datetime1,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "datetime1", $$v)
                      },
                      expression: "values.datetime1",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrSelect", {
                    attrs: {
                      label: "見積確認",
                      "pr-row": [35],
                      options: _vm.$store.getters["defs/getOptions"](
                        "EstimatesContentStatus"
                      ),
                    },
                    model: {
                      value: _vm.values.weight3,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "weight3", $$v)
                      },
                      expression: "values.weight3",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "OMContractDetailEditer",
            _vm._b(
              {
                attrs: { contructDetail: _vm.values },
                on: { saved: _vm.handleSave },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function ({ handleAddButtonClick, resetValidator }) {
                        return _c(
                          "div",
                          { class: _vm.$style.buttons },
                          [
                            _c(
                              "Button",
                              {
                                attrs: { color: "submit", size: "main" },
                                on: { click: handleAddButtonClick },
                              },
                              [_vm._v("編集内容保存する")]
                            ),
                            _vm._v(" "),
                            _c(
                              "Button",
                              {
                                attrs: { color: "cancel", size: "assist" },
                                on: {
                                  click: () => {
                                    _vm.initValues()
                                    resetValidator()
                                  },
                                },
                              },
                              [_vm._v("クリア")]
                            ),
                            _vm._v(" "),
                            _c("DialogProvider", {
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                texts: {
                                  ttl: "確認",
                                  msg: "削除しますか？",
                                  yas: "削除する",
                                  no: "キャンセル",
                                },
                              },
                              on: { accept: _vm.handleDeleteAccept },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ on }) {
                                      return _c(
                                        "Button",
                                        _vm._g(
                                          {
                                            attrs: {
                                              size: "main",
                                              color: "alert",
                                            },
                                          },
                                          on
                                        ),
                                        [_vm._v("削除")]
                                      )
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  4254031761
                ),
              },
              "OMContractDetailEditer",
              { supplierOptions: _vm.supplierOptions },
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }