var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "img",
    _vm._b(
      {
        class: _vm.$style.cursor,
        attrs: { src: _vm.src },
        on: { click: _vm.handleClick },
      },
      "img",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }