var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items !== null
    ? _c(
        "IndexTableProvider",
        {
          attrs: {
            items: _vm.items,
            headers: _vm.headers,
            "filter-func": _vm.filterFunc,
            "page-id": "prices",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "filter",
                fn: function ({
                  filter,
                  handleTextInput,
                  handleFlagOn,
                  filterClass,
                }) {
                  return _c(
                    "div",
                    { class: filterClass },
                    [
                      _c(
                        "PrInput",
                        {
                          staticStyle: { "justify-content": "space-between" },
                          attrs: {
                            label: "キーワード",
                            "pr-row": [100, 85],
                            value: _vm.keywordHandler.value,
                          },
                          on: { input: _vm.keywordHandler.input },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.keywordHandler.keydown.apply(
                                null,
                                arguments
                              )
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.keywordHandler.keyup(
                                $event,
                                handleFlagOn,
                                handleTextInput,
                                filter
                              )
                            },
                          },
                        },
                        [
                          _c("SearchButton", {
                            attrs: {
                              slot: "after",
                              close: _vm.keywordHandler.isOn,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.keywordHandler.click(
                                  handleFlagOn,
                                  handleTextInput,
                                  filter
                                )
                              },
                            },
                            slot: "after",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ],
            null,
            false,
            3608763339
          ),
        },
        [
          _c("template", { slot: "page-title" }, [
            _vm._v("\n    価格表一覧\n  "),
          ]),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: {
                slot: "create-link",
                size: "main",
                color: "submit",
                plus: "",
                to: "/admin/prices/new",
              },
              slot: "create-link",
            },
            [_vm._v("\n    新しい価格を追加する\n  ")]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }