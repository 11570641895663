<template>
  <InfoBlock block width="100%" margin="0">
    <template slot="head">
      見積概要
    </template>
    <div v-if="!isNew" :class="[$style.row, $style.threeColumn]">
      <div>
        <p>見積管理No</p>
        <p>{{ deal.contract.request_key2 }}</p>
      </div>
      <div>
        <p>見積名</p>
        <p>{{ deal.contract.request_name }}</p>
      </div>
    </div>
    <div :class="[$style.row, $style.fourColumn]">
      <PrDatePicker
        :value="value.request_date1"
        @input="updateValue($event, 'request_date1')"
        label="見積日"
        :pr-row="[30]"
      />
      <PrDatePicker
        :value="value.contract.expiration_date"
        @input="updateValue($event, 'contract.expiration_date')"
        label="有効期限"
        :pr-row="[30]"
      />
      <PrCheckbox
        :value="value.quantity2"
        @input="handlePublishChange"
        before-label="見積公開"
        :disabled="value.status === '1'"
        :pr-row="[40]"
      />
      <PrInput
        :value="value.contract.shipping_fee"
        @input="updateValue($event, 'contract.shipping_fee')"
        label="納期日数"
        :pr-row="[40]"
        name="contract.shipping_fee"
        :validate="'numeric'"
      />
    </div>
    <div :class="[$style.row, $style.twoColumn]">
      <PrSelect
        :value="value.request_key4"
        @input="updateValue($event, 'request_key4')"
        :options="supplierOptions"
        label="見積先"
        :pr-row="[30, 30]"
      />
      <PrTextarea
        :value="value.note1"
        @input="updateValue($event, 'note1')"
        label="見積備考"
        :pr-row="[15]"
      />
    </div>
    <div v-if="!isNew" :class="[$style.row, $style.fourColumn]">
      <PrDatePicker
        :value="value.request_date5"
        @input="updateValue($event, 'request_date5')"
        label="発注日"
        :pr-row="[30]"
      />
      <PrInput
        :value="value.description"
        @input="updateValue($event, 'description')"
        label="発注データ名"
        :pr-row="[30]"
      />
    </div>
    <div v-if="!isNew" :class="[$style.row, $style.fourColumn]">
      <PrDatePicker
        :value="value.contract.contracted_date"
        @input="updateValue($event, 'contract.contracted_date')"
        label="注文日"
        :pr-row="[30]"
      />
      <PrDatePicker
        :value="value.request_date2"
        @input="updateValue($event, 'request_date2')"
        label="入稿日"
        :pr-row="[30]"
      />
      <PrCheckbox
        :value="value.status"
        @input="handleOrderChange"
        before-label="注文"
        :pr-row="[40]"
        :disabled="value.quantity3 === '1'"
      />
      <PrCheckbox
        :value="value.quantity3"
        @input="handleProofingChange"
        before-label="色校正"
        :pr-row="[40]"
      />
    </div>
    <div :class="[$style.row, $style.oneColumn]">
      <PrTextarea
        :value="value.note4"
        @input="updateValue($event, 'note4')"
        label="備考(内部確認用)"
        :pr-row="[10, 40]"
        rows="3"
      />
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import { getDate } from "@/utils/shared.js"

export default {
  inject: ["$validator"],
  components: {
    InfoBlock,
    PrDatePicker,
    PrCheckbox,
    PrInput,
    PrTextarea,
    PrSelect,
  },
  props: {
    deal: {
      type: Object,
      require: true,
    },
    supplierOptions: {
      type: Array,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  computed: {
    isNew() {
      return this.$route.name === "adminContractNew"
    },
  },
  filters: {
    getDate,
  },
  methods: {
    updateValue(val, keys) {
      const newValue = JSON.parse(JSON.stringify(this.value))
      const keyArray = keys.split(".")
      const updater = {
        1([key1]) {
          newValue[key1] = val
        },
        2([key1, key2]) {
          newValue[key1][key2] = val
        },
      }
      updater[keyArray.length](keyArray)
      this.$emit("input", newValue)
    },
    handlePublishChange(quantity2) {
      const newValue = JSON.parse(JSON.stringify(this.value))
      if (quantity2 === "0") {
        this.$emit("input", {
          ...newValue,
          quantity2,
          quantity3: "0",
          status: "0",
        })
      } else {
        this.$emit("input", { ...newValue, quantity2 })
      }
    },
    handleOrderChange(status) {
      const newValue = JSON.parse(JSON.stringify(this.value))
      if (status === "0") {
        this.$emit("input", { ...newValue, status, quantity3: status })
      } else {
        this.$emit("input", {
          ...newValue,
          status,
          quantity2: "1",
          contract: {
            ...newValue.contract,
            contracted_date: new Date().toJSON(),
          },
        })
      }
    },
    handleProofingChange(quantity3) {
      const newValue = JSON.parse(JSON.stringify(this.value))
      if (quantity3 === "0") {
        this.$emit("input", { ...newValue, quantity3 })
      } else {
        this.$emit("input", {
          ...newValue,
          quantity3,
          quantity2: "1",
          status: "1",
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
.row {
  padding: 10px 20px;
  display: flex;
  > div {
    display: flex;
    p:first-child {
      width: 30%;
      color: map-get($colors, darkGlay);
    }
  }
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  &.oneColumn {
    > label,
    > div {
      width: 100%;
    }
  }
  &.threeColumn {
    > label,
    > div {
      width: calc(100% / 3);
    }
  }
  /* stylelint-disable */
  &.twoColumn {
    > label,
    > div {
      &:nth-child(1) {
        width: calc(100% / 3 * 1);
      }
      &:nth-child(2) {
        width: calc(100% / 3 * 2);
      }
    }
  }
  &.fourColumn {
    > label,
    > div {
      &:nth-child(1),
      &:nth-child(2) {
        width: calc(100% / 3);
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: calc(100% / 6);
      }
    }
  }
}
</style>
