<template>
  <span :class="[$style.error, { [$style.center]: center }]">
    <v-icon size="1.4em" color="red">error</v-icon>
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" module>
.error {
  //white-space: nowrap;
  width: 100%;
  display: inline-block;
  font-size: 0.14rem;
  padding: 0.2em 0.3em;
  color: #833611;
  border: solid 1px rgba(234, 44, 0, 0.15);
  background-color: #ffe4dc;
  border-radius: 4px;
  text-align: center;
}
</style>
