var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", { class: _vm.$style.wrapper }, [
        _c("h1", { class: _vm.$style.pageTtl }, [_vm._v("メールマガジン設定")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.block }, [
          _c("div", { class: _vm.$style.block_container }, [
            _c("div", { class: _vm.$style.formsAria }, [
              _c("div", { class: _vm.$style.account }, [
                _c("p", { class: _vm.$style.account_ttlTxt }, [
                  _c("span", [_vm._v("メールアドレス")]),
                  _vm._v("\n            " + _vm._s(_vm.email) + "\n          "),
                ]),
                _vm._v(" "),
                _c("p", { class: _vm.$style.account_ttlTxt }, [
                  _c("span", [_vm._v("メールマガジン")]),
                  _vm._v(" "),
                  _c("label", { class: _vm.$style.radio }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.receiveMail,
                          expression: "receiveMail",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "mailmagazine",
                        value: "1",
                      },
                      domProps: { checked: _vm._q(_vm.receiveMail, "1") },
                      on: {
                        change: function ($event) {
                          _vm.receiveMail = "1"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span"),
                    _vm._v(" "),
                    _c("span", [_vm._v("受け取る")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { class: _vm.$style.radio }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.receiveMail,
                          expression: "receiveMail",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "mailmagazine",
                        value: "0",
                      },
                      domProps: { checked: _vm._q(_vm.receiveMail, "0") },
                      on: {
                        change: function ($event) {
                          _vm.receiveMail = "0"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span"),
                    _vm._v(" "),
                    _c("span", [_vm._v("受け取らない")]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.button },
                [
                  _c(
                    "Button",
                    {
                      attrs: { color: "submit", size: "main" },
                      on: { click: _vm.handleChange },
                    },
                    [_vm._v("変更する")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }