var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-top": "30px" } }, [
    _c(
      "div",
      { class: _vm.$style.head },
      [
        _c("h3", [_vm._v("マージ")]),
        _vm._v(" "),
        _vm.coupling
          ? [
              _c("DialogProvider", {
                attrs: {
                  texts: {
                    ttl: "確認",
                    msg: `${_vm.seme.request_name}は削除されます。マージしますか？`,
                    yas: "はい",
                    no: "いいえ",
                  },
                },
                on: { accept: _vm.handleMarge },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "Button",
                            _vm._g(
                              { attrs: { size: "main", color: "submit" } },
                              on
                            ),
                            [_vm._v("\n            マージする\n          ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1754000957
                ),
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.inner },
      [
        _vm._l(_vm.collection, function ([ttl, num, men], idx) {
          return [
            _c(
              "div",
              { key: idx, class: _vm.$style.seme },
              [
                _c("h4", [
                  _c("span", [_vm._v(_vm._s(ttl))]),
                  _c("span", [_vm._v("商談No: " + _vm._s(num))]),
                ]),
                _vm._v(" "),
                men
                  ? [
                      _c(
                        "div",
                        { class: _vm.$style.sub },
                        [
                          _c("p", [_vm._v("問い合わせ")]),
                          _vm._v(" "),
                          !idx
                            ? [
                                _c("PrCheckbox", {
                                  attrs: {
                                    "before-label": "Bに上書",
                                    "after-label": "＞＞",
                                  },
                                  model: {
                                    value: _vm.flagInquiry,
                                    callback: function ($$v) {
                                      _vm.flagInquiry = $$v
                                    },
                                    expression: "flagInquiry",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _c("span", [_vm._v("問合日")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("getDate")(men.inquiry.request_date1)
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("内容")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("chackFalsiy")(men.inquiry.description)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.sub },
                        [
                          _c("p", [_vm._v("サンプル請求")]),
                          _vm._v(" "),
                          !idx
                            ? [
                                _c("PrCheckbox", {
                                  attrs: {
                                    "before-label": "Bに上書",
                                    "after-label": "＞＞",
                                  },
                                  model: {
                                    value: _vm.flagSample,
                                    callback: function ($$v) {
                                      _vm.flagSample = $$v
                                    },
                                    expression: "flagSample",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _c("span", [_vm._v("請求日")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("getDate")(men.inquiry.request_date3)
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("商品番号")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("chackFalsiy")(men.inquiry.note3))
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("商品備考")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("chackFalsiy")(men.inquiry.note2))
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.sub },
                        [
                          _c("p", [_vm._v("その他情報")]),
                          _vm._v(" "),
                          !idx
                            ? [
                                _c("PrCheckbox", {
                                  attrs: {
                                    "before-label": "Bに上書",
                                    "after-label": "＞＞",
                                  },
                                  model: {
                                    value: _vm.flagOther,
                                    callback: function ($$v) {
                                      _vm.flagOther = $$v
                                    },
                                    expression: "flagOther",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _c("span", [_vm._v("備考")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("chackFalsiy")(men.note1))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("アクセス経路")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("chackFalsiy")(men.note2))),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.sub },
                        [
                          _c("p", [_vm._v("見積")]),
                          _vm._v(" "),
                          !idx
                            ? [
                                _c("PrCheckbox", {
                                  attrs: {
                                    "before-label": "Bに追加",
                                    "after-label": "＞＞",
                                  },
                                  model: {
                                    value: _vm.flagContract,
                                    callback: function ($$v) {
                                      _vm.flagContract = $$v
                                    },
                                    expression: "flagContract",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._l(men.contract, function (contract) {
                        return [
                          _c(
                            "ul",
                            { key: contract.id, class: _vm.$style.contract },
                            [
                              _c("li", [
                                _c("span", [_vm._v("見積日")]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("getDate")(contract.request_date1)
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", [_vm._v("見積名")]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(contract.request_name)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", [_vm._v("見積総額")]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(contract.contract.total_amount)
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }