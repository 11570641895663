var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "290" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_c("span", [_vm._t("default", null, { on: on })], 2)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(_vm._s(_vm.texts.ttl)),
          ]),
          _vm._v(" "),
          _c("v-card-text", [_vm._v(_vm._s(_vm.texts.msg))]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", flat: "" },
                  on: { click: _vm.handleYesClick },
                },
                [_vm._v("\n        " + _vm._s(_vm.texts.yas) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", flat: "" },
                  on: { click: _vm.handleNoClick },
                },
                [_vm._v("\n        " + _vm._s(_vm.texts.no) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }