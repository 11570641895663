<template>
  <div :class="$style.infoBlock_Aria">
    <div v-for="(item, i) in list" :class="$style.infoBlock_item" :key="i">
      <PrInput
        v-if="item.type === 'text'"
        :label="item.name"
        :pr-row="[35]"
        :name="item.key"
        :value="value[item.key]"
        @input="handleValueChange($event, item.key, item.func)"
        :readonly="item.readonly"
        :validate="item.validate ? item.validate.rule : ''"
      />

      <PrInput
        v-if="item.type === 'password'"
        :label="item.name"
        :pr-row="[35]"
        :name="item.key"
        type="password"
        :value="value[item.key]"
        @input="handleValueChange($event, item.key, item.func)"
        :readonly="item.readonly"
        :validate="item.validate ? item.validate.rule : ''"
      />

      <PrSelect
        v-if="item.type === 'select'"
        :label="item.name"
        :pr-row="[35]"
        :name="item.key"
        :value="value[item.key]"
        @input="e => handleValueChange(e, item.key, item.func)"
        :validate="item.validate ? item.validate.rule : ''"
        :options="item.options"
        :searchable="item.searchable || false"
      />

      <PrDatePicker
        v-if="item.type === 'datepicker'"
        :label="item.name"
        :pr-row="[35]"
        :name="item.key"
        :value="value[item.key]"
        @input="handleValueChange($event, item.key, item.func)"
        :validate="item.validate ? item.validate.rule : ''"
      />

      <PrCheckbox
        v-if="item.type === 'checkbox'"
        :before-label="item.name"
        :pr-row="[35]"
        :value="value[item.key]"
        @input="handleValueChange($event, item.key, item.func)"
      />

      <template v-if="item.type === 'autocomplete'">
        <p>{{ item.name }}</p>
        <v-autocomplete
          :name="item.key"
          :value="value[item.key]"
          :item-text="item.items.text"
          :item-value="item.items.value"
          :items="item.items.data"
          :clearable="true"
          @input="e => handleAutoCompleteChange(e, item.key, item.func)"
          v-validate="item.validate ? item.validate.rule : ''"
        />
        <template v-if="item.validate && item.validate.message">
          <span
            :class="$style.error"
            v-show="
              errors.firstByRule(item.key, 'required') || errRequired[item.key]
            "
            >{{ item.validate.message["required"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'numeric')"
            >{{ item.validate.message["numeric"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'decimal')"
            >{{ item.validate.message["decimal"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'email')"
            >{{ item.validate.message["email"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'password')"
            >{{ item.validate.message["password"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'min')"
            >{{ item.validate.message["min"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'max')"
            >{{ item.validate.message["max"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'zipcode')"
            >{{ item.validate.message["zipcode"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'telephone')"
            >{{ item.validate.message["telephone"] }}</span
          >
          <span
            :class="$style.error"
            v-show="errors.firstByRule(item.key, 'between')"
            >{{ item.validate.message["between"] }}</span
          >
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"

export default {
  components: {
    PrInput,
    PrSelect,
    PrCheckbox,
    PrDatePicker,
  },
  props: {
    list: Array,
    value: Object,
  },
  inject: {
    $validator: "$validator",
  },
  data() {
    return {
      errRequired: [],
    }
  },
  methods: {
    clearErrorRequired() {
      this.errRequired = []
    },
    handleValueChange(e, key, func) {
      const newValue = JSON.parse(JSON.stringify(this.value))
      newValue[key] = e
      this.$emit("input", newValue)
      if (func) {
        func()
      }
    },
    handleAutoCompleteChange(value, key, func) {
      const newValue = JSON.parse(JSON.stringify(this.value))

      // v-autocompleteがクリアボタン押下がvee-validateで拾えないため、独自でエラーを持つ
      if (!value) {
        this.errRequired[key] = true
      } else {
        this.errRequired[key] = false
      }
      newValue[key] = value
      this.$emit("input", newValue)
      if (func) {
        func()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include theSelect();

.infoBlock {
  &_Aria {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      align-items: center;
      width: 50%;
      padding: 15px 20px;
      &:nth-child(4n + 1),
      &:nth-child(4n + 2) {
        background-color: map-get($colors, lightlightGray);
      }
      > div[name^="pr-"] {
        width: 100%;
      }
      > p {
        width: 35%;
        color: map-get($colors, darkGlay);
      }
    }
  }
}

.error {
  color: red;
  font-size: 0.11rem;
  position: absolute;
  left: 20px;
  bottom: -5px;
  vertical-align: bottom;
  z-index: 1;
}
</style>
