var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wrapper }, [
    _c("label", { class: _vm.$style.label }, [_vm._t("default")], 2),
    _vm._v(" "),
    _vm.type !== "select"
      ? _c("input", {
          class: _vm.$style.input,
          attrs: {
            type: _vm.type,
            readonly: _vm.readonly !== undefined,
            name: _vm.name,
          },
          domProps: { value: _vm.value },
          on: {
            input: _vm.updateValue,
            change: _vm.updateValue,
            blur: function ($event) {
              return _vm.$emit("blur")
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "select"
      ? _c(
          "select",
          {
            class: _vm.$style.input,
            attrs: { name: _vm.name },
            domProps: { value: _vm.value },
            on: { input: _vm.updateValue, change: _vm.updateValue },
          },
          _vm._l(_vm.options, function (v, j) {
            return _c("option", { key: j, domProps: { value: v.key } }, [
              _vm._v(_vm._s(v.value)),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }