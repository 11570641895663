<template>
  <div :class="$style.wrapper">
    <PageTitle>実績帳票出力</PageTitle>
    <InfoBlock width="1000px" block>
      <template slot="head"
        >帳票選択</template
      >
      <div :class="$style.row">
        <PrSelect
          label="対象帳票"
          :pr-row="[35]"
          name="name"
          :validate="'required'"
          v-model="conditions.report_category"
          :options="reportDefinitionOptions"
        />
      </div>
    </InfoBlock>
    <InfoBlock width="1000px" block>
      <template slot="head"
        >出力条件/出力</template
      >
      <div>
        <div
          :class="$style.row"
          v-if="conditions.report_category === '10_OrderedAchievement'"
        >
          <PrDatePicker
            label="注文日付"
            :pr-row="[35]"
            v-model="conditions.ordered_from"
          />
          <PrDatePicker
            label="~"
            :pr-row="[35]"
            v-model="conditions.ordered_to"
          />
        </div>
        <div
          :class="$style.row"
          v-if="conditions.report_category === '11_CustomerAchievement'"
        >
          <PrSelect
            label="取引先"
            :pr-row="[35]"
            name="name"
            v-model="conditions.customer_id"
            :searchable="true"
            :options="customerOptions"
          />
        </div>
        <div
          :class="$style.row"
          v-if="conditions.report_category === '12_CustomerPersonAchievement'"
        >
          <PrSelect
            label="取引先"
            :pr-row="[35]"
            name="name"
            v-model="conditions.customer_id"
            :searchable="true"
            :options="customerOptions"
          />
          <PrSelect
            label="取引先責任者"
            :pr-row="[35]"
            name="name"
            v-model="conditions.customer_person_id"
            :searchable="true"
            :options="customerPersonOptions"
          />
        </div>
        <div
          :class="$style.buttons_center"
          v-if="conditions.report_category !== null"
        >
          <Button color="submit" size="main" @click="handlePrintButtonClick"
            >出力</Button
          >
          <Button color="cancel" size="assist" @click="initValues"
            >条件クリア</Button
          >
        </div>
      </div>
    </InfoBlock>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import Button from "@/components/atoms/Button.vue"
import api, { paths } from "@/utils/api.js"
import { routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    PageTitle,
    InfoBlock,
    PrSelect,
    PrDatePicker,
    Button,
  },
  data() {
    return {
      definitions: [],
      conditions: {
        report_category: null,
        customer_id: null,
        customer_person_id: null,
        ordered_from: null,
        ordered_to: null,
      },
      customers: null,
      customerPersons: null,
    }
  },
  async created() {
    const [
      { ok: cOk, body: cBody },
      { ok: rOk, body: rBody },
    ] = await Promise.all([
      api.get(
        paths.ADMIN_CUSTOMERS_AND_CUSTOMER_PERSONS,
        this.$store.state.auth.token,
      ),
      api.get(paths.REPORT_ACHIEVEMENT, this.$store.state.auth.token),
    ])
    if (!cOk) {
      routeReplaceError(this, cBody.slice(-3))
    }
    if (!rOk) {
      routeReplaceError(this, rBody.slice(-3))
    }
    this.customers = cBody.customers
    this.customerPersons = cBody.customer_persons
    this.definitions = rBody
  },
  methods: {
    async handlePrintButtonClick() {
      const loader = this.$loading.show()
      try {
        const { ok, body } = await api.post(
          paths.REPORT_ACHIEVEMENT,
          { conditions: this.conditions },
          this.$store.state.auth.token,
        )

        if (!ok) {
          routeReplaceError(this, body.slice(-3))
          return
        }

        const anker = document.createElement("a")
        anker.setAttribute("href", body.report_path)
        anker.click()
      } finally {
        loader.hide()
      }
    },
    initValues() {
      this.conditions = {
        report_category: this.conditions.report_category,
        customer_id: null,
        customer_person_id: null,
        ordered_from: null,
        ordered_to: null,
      }
    },
  },
  computed: {
    reportDefinitionOptions() {
      return this.definitions.map(({ report_category, report_name }) => ({
        key: report_category,
        value: report_name,
      }))
    },
    customerOptions() {
      return this.customers.map(({ id, name }) => ({
        key: String(id),
        value: name,
      }))
    },
    customerPersonOptions() {
      return this.customerPersons
        .filter(
          ({ customer_id }) =>
            this.conditions.customer_id === null ||
            String(customer_id) === this.conditions.customer_id,
        )
        .map(({ id, last_name, first_name }) => ({
          key: String(id),
          value: last_name + "" + first_name,
        }))
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
  > div {
    width: 48%;
    &[class="special"] {
      display: flex;
      align-items: center;
      > p {
        width: 35%;
        color: map-get($colors, darkGlay);
      }
      > div {
        width: 65%;
      }
    }
  }
}
.buttons_center {
  width: 1000px;
  margin-top: 25px;
  text-align: center;
}
</style>
