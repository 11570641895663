var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.history }, [
    _c("h2", { class: _vm.$style.history_head }, [_vm._v("商談履歴")]),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.history_container },
      _vm._l(_vm.sortedHistory, function (history) {
        return _c("div", { key: history.id, class: _vm.$style.history_item }, [
          _c("p", { class: _vm.$style.history_date }, [
            _vm._v(_vm._s(_vm._f("getDate")(history.inserted_at))),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { class: _vm.$style.history_text },
            [
              _vm._v("\n        " + _vm._s(history.description) + "\n        "),
              history.inserted.role === "admin"
                ? [
                    _vm._v(
                      "\n          【" +
                        _vm._s(history.inserted.name) +
                        "】\n        "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }