<template>
  <div :class="$style.wrapper">
    <div :class="$style.inner">
      <h3 v-cloak>
        {{ code }}
        <br />
        {{ message }}
      </h3>
      <p v-html="subMessage"></p>
      <div :class="$style.base" @click="browserBack">前のページへ戻る</div>
    </div>
  </div>
</template>

<script>
const status = {
  400: {
    code: "400 Bad Request",
    message: "",
    subMessage: "",
  },
  404: {
    code: "404 File Not Found",
    message: "指定されたページは存在しません。",
    subMessage:
      "大変申し訳ございませんが、お探しのページは、<br>\
      移動または削除された可能性があります。<br>\
      URLに誤りがないかもご確認下さい。",
  },
  422: {
    code: "422 Unprocessable Entity",
    message: "",
    subMessage: "",
  },
  500: {
    code: "500 Internal Server Error",
    message: "内部サーバーエラー。",
    subMessage: "",
  },
  502: {
    code: "502 Bad Gateway",
    message: "",
    subMessage: "",
  },
  504: {
    code: "504 Gateway Timeout Error",
    message: "タイムアウトエラー。",
    subMessage: "",
  },
  900: {
    code: "セッションの有効期限が切れました。",
    message: "恐れ入りますが最初から操作をやり直してください。",
    subMessage: "",
  },
}

export default {
  data() {
    return {
      code: "",
      message: "",
      subMessage: "",
    }
  },
  props: {
    statusCode: {
      type: String,
      default: "404",
    },
    url: {
      type: String,
      default: null,
    },
  },
  methods: {
    browserBack() {
      history.go(-1)
    },
  },
  created() {
    if (this.url !== null) {
      history.replaceState(null, "", this.url)
    }

    if (this.statusCode in status) {
      this.code = status[this.statusCode].code
      this.message = status[this.statusCode].message
      this.subMessage = status[this.statusCode].subMessage
    } else {
      this.code = this.statusCode
      this.message = ""
      this.subMessage =
        "エラーが発生しました。<br>前のページに戻り、もう一度やり直してください。"
    }
  },
}
</script>

<style lang="scss" module>
.wrapper {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  background-color: #fff;
  padding: 50px;
  > h3 {
    margin-bottom: 20px;
    font-size: 0.4rem;
  }
  > p {
    margin-bottom: 20px;
    width: 80%;
    font-size: 0.18rem;
  }
}

.base {
  display: block;
  padding-left: 0.8em;
  color: #016cca;
  font-size: 0.18rem;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 0.4em);
    left: 0;
    border: 0.4em solid transparent;
    border-left-color: #016cca;
  }
}
</style>
