var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { block: "", width: "100%", margin: "0" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    見積概要\n  ")]),
      _vm._v(" "),
      !_vm.isNew
        ? _c("div", { class: [_vm.$style.row, _vm.$style.threeColumn] }, [
            _c("div", [
              _c("p", [_vm._v("見積管理No")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.deal.contract.request_key2))]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", [_vm._v("見積名")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.deal.contract.request_name))]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.$style.row, _vm.$style.fourColumn] },
        [
          _c("PrDatePicker", {
            attrs: {
              value: _vm.value.request_date1,
              label: "見積日",
              "pr-row": [30],
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "request_date1")
              },
            },
          }),
          _vm._v(" "),
          _c("PrDatePicker", {
            attrs: {
              value: _vm.value.contract.expiration_date,
              label: "有効期限",
              "pr-row": [30],
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "contract.expiration_date")
              },
            },
          }),
          _vm._v(" "),
          _c("PrCheckbox", {
            attrs: {
              value: _vm.value.quantity2,
              "before-label": "見積公開",
              disabled: _vm.value.status === "1",
              "pr-row": [40],
            },
            on: { input: _vm.handlePublishChange },
          }),
          _vm._v(" "),
          _c("PrInput", {
            attrs: {
              value: _vm.value.contract.shipping_fee,
              label: "納期日数",
              "pr-row": [40],
              name: "contract.shipping_fee",
              validate: "numeric",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "contract.shipping_fee")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.$style.row, _vm.$style.twoColumn] },
        [
          _c("PrSelect", {
            attrs: {
              value: _vm.value.request_key4,
              options: _vm.supplierOptions,
              label: "見積先",
              "pr-row": [30, 30],
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "request_key4")
              },
            },
          }),
          _vm._v(" "),
          _c("PrTextarea", {
            attrs: {
              value: _vm.value.note1,
              label: "見積備考",
              "pr-row": [15],
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "note1")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isNew
        ? _c(
            "div",
            { class: [_vm.$style.row, _vm.$style.fourColumn] },
            [
              _c("PrDatePicker", {
                attrs: {
                  value: _vm.value.request_date5,
                  label: "発注日",
                  "pr-row": [30],
                },
                on: {
                  input: function ($event) {
                    return _vm.updateValue($event, "request_date5")
                  },
                },
              }),
              _vm._v(" "),
              _c("PrInput", {
                attrs: {
                  value: _vm.value.description,
                  label: "発注データ名",
                  "pr-row": [30],
                },
                on: {
                  input: function ($event) {
                    return _vm.updateValue($event, "description")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isNew
        ? _c(
            "div",
            { class: [_vm.$style.row, _vm.$style.fourColumn] },
            [
              _c("PrDatePicker", {
                attrs: {
                  value: _vm.value.contract.contracted_date,
                  label: "注文日",
                  "pr-row": [30],
                },
                on: {
                  input: function ($event) {
                    return _vm.updateValue($event, "contract.contracted_date")
                  },
                },
              }),
              _vm._v(" "),
              _c("PrDatePicker", {
                attrs: {
                  value: _vm.value.request_date2,
                  label: "入稿日",
                  "pr-row": [30],
                },
                on: {
                  input: function ($event) {
                    return _vm.updateValue($event, "request_date2")
                  },
                },
              }),
              _vm._v(" "),
              _c("PrCheckbox", {
                attrs: {
                  value: _vm.value.status,
                  "before-label": "注文",
                  "pr-row": [40],
                  disabled: _vm.value.quantity3 === "1",
                },
                on: { input: _vm.handleOrderChange },
              }),
              _vm._v(" "),
              _c("PrCheckbox", {
                attrs: {
                  value: _vm.value.quantity3,
                  "before-label": "色校正",
                  "pr-row": [40],
                },
                on: { input: _vm.handleProofingChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.$style.row, _vm.$style.oneColumn] },
        [
          _c("PrTextarea", {
            attrs: {
              value: _vm.value.note4,
              label: "備考(内部確認用)",
              "pr-row": [10, 40],
              rows: "3",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "note4")
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }