var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { class: _vm.$style.radio, attrs: { name: "pr-radio" } }, [
    _c(
      "input",
      _vm._b(
        {
          attrs: { type: "radio" },
          domProps: {
            value: _vm.value,
            checked: Number(_vm.value) === Number(_vm.checked),
          },
          on: {
            change: function ($event) {
              return _vm.$emit("change", $event.target.value)
            },
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _vm._v(" "),
    _c("span"),
    _vm._v(" "),
    _vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }