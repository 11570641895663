import "@babel/polyfill"
import Vue from "vue"
import router from "@/router"
import store from "@/store"
import App from "@/components/templates/App.vue"
import Vuetify from "vuetify"
import VeeValidate, { Validator } from "vee-validate"
import validation from "@/validator/customRules.js"
import dict from "@/validator/customMessages.js"
import moment from "moment-timezone"
import Toasted from "vue-toasted"
import Loading from "vue-loading-overlay"

import "vue-loading-overlay/dist/vue-loading.css"
import "vuetify/dist/vuetify.min.css"
import "@/scss/baseStyle.scss"
import "@/scss/global.css"
import axios from "axios"

moment.tz.setDefault("Asia/Tokyo")

// Vue.use(VeeValidate)
Validator.extend("password", validation.password)
Validator.extend("telephone", validation.telephone)
Validator.extend("zipcode", validation.zipcode)
Validator.extend("voucher", validation.voucher)
Validator.extend("dot_num", validation.dot_num)
Validator.extend("fax", validation.fax)
Validator.extend("mobilephone", validation.fax)
Validator.extend("few", validation.dot_num)
Validator.localize("ja", dict)
Vue.use(VeeValidate, { locale: dict })

Vue.use(Loading)

Vue.use(Toasted, {
  position: "bottom-center",
  theme: "toasted-primary",
  fullWidth: true,
  singleton: true,
})

Vue.use(Vuetify)

const logger = (at, err) => {
  console.log(`Captured in ${at}:`, err)
  axios.post("/err-log", err).catch()
}

Vue.config.errorHandler = (err, vm, info) => {
  // 以下のようなものをログとして保存する
  // スタックトレース
  // ユーザID
  // フロントのルーティングパス
  // エビデンス用URL
  // エラー発生日時
  // 端末IP
  // ユーザエージェント
  const data = {
    agent: window.navigator.userAgent.toLowerCase(),
    reason: err.stack,
    info: info,
    route: {
      path: vm.$route.path,
      name: vm.$route.name,
    },
  }

  logger("Vue.config.errorHandler", data)
}

window.addEventListener("error", event => {
  const data = {
    agent: window.navigator.userAgent.toLowerCase(),
    reason: event.error.stack,
    path: event.currentTarget.location.pathname,
  }

  logger("error EventListener", data)
})
window.addEventListener("unhandledrejection", event => {
  const data = {
    agent: window.navigator.userAgent.toLowerCase(),
    reason: event.reason.stack,
    path: event.currentTarget.location.pathname,
  }

  logger("unhandledrejection EventListener", data)
})

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App),
})
