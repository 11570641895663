<template>
  <div :class="$style.wrapper">
    <div :class="$style.container">
      <v-btn
        @click="toggleCompanySeal"
        :class="$style.sealButton"
        :absolute="true"
        >社印表示切替</v-btn
      >
      <section :class="$style.section1">
        <h1>納品書</h1>
        <p :class="$style.date" v-cloak>
          <input :class="$style.input" v-model="documentDate" />
        </p>
        <p :class="$style.date">商談番号：{{ id }}</p>
        <div :class="$style.head">
          <div v-if="items.customer" :class="$style.left">
            <p v-cloak>
              <input
                :class="$style.input"
                v-model="customerName"
                v-bind:style="customerNameWidth"
              />
              <span :class="$style.customer" ref="customerName">{{
                customerName
              }}</span>
            </p>
            <p v-cloak>
              担当者：<input
                :class="$style.input"
                v-model="personName"
                v-bind:style="personNameWidth"
              />
              様
              <span :class="$style.person" ref="personName">{{
                personName
              }}</span>
            </p>
          </div>
          <div :class="$style.right">
            <div :class="$style.logo_box">
              <p>
                付箋・販促物製作専門店
                <br />株式会社
                <span :class="[$style.fwb, $style.fzb]">ＰＲ３９</span>
              </p>
              <div>
                <Logo :width="70" />
              </div>
            </div>
            <p>
              〒812-0895 <br />福岡市博多区竹下1丁目11−23 <br />TEL:092-409-6039
            </p>
            <img
              v-show="isCompanySeal"
              :class="$style.seal"
              src="@/images/company_seal.png"
              alt="株式会社ピーアールサンキュー"
              :ref="`img_company_seal`"
            />
          </div>
        </div>
        <p :class="$style.greeting">
          この度は弊社製品をお買い求め頂き誠に有難うございます。
          <br />下記の商品を納品します。
          <br />内容をご確認の上、お気づきの点がございましたら、
          <br />お手数ですが弊社までご一報下さいますようお願い申しあげます。
        </p>
        <table>
          <colgroup>
            <col style="width: 8%;" />
            <col style="width: 55%;" />
            <col style="width: 10%;" />
            <col style="width: 10%;" />
            <col style="width: 17%;" />
          </colgroup>
          <thead>
            <tr>
              <th>項目</th>
              <th>商品番号・商品名</th>
              <th>数量</th>
              <th>単価</th>
              <th>金額</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(detail, index) in detailsProduct"
              :key="`first-${index}`"
            >
              <td v-cloak>{{ detail.index }}</td>
              <td v-cloak>{{ detail.name }}</td>
              <td :class="$style.price" v-cloak>
                {{ detail.amount | formatNumber }}
              </td>
              <td :class="$style.price" v-cloak>
                {{ detail.price | formatDecimalPrice }}
              </td>
              <td :class="$style.price" v-cloak>
                ¥{{ detail.amountPrice | formatPrice }}
              </td>
            </tr>
            <tr
              v-for="(detail, index) in detailsOther"
              :key="`second-${index}`"
            >
              <td v-cloak>{{ detail.index }}</td>
              <td v-cloak>{{ detail.name }}</td>
              <td :class="$style.price" v-cloak>
                {{ detail.amount | formatNumber }}
              </td>
              <td :class="$style.price" v-cloak>
                {{ detail.price | formatPrice }}
              </td>
              <td :class="$style.price" v-cloak>
                ¥{{ detail.amountPrice | formatPrice }}
              </td>
            </tr>
            <tr
              v-for="(detail, index) in detailsReserved"
              :key="`third-${index}`"
            >
              <td></td>
              <td></td>
              <td :class="$style.price"></td>
              <td :class="$style.price"></td>
              <td :class="$style.price"></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td
                :class="[$style.bor0, $style.vat]"
                :rowspan="discountPrice == null ? 3 : 4"
                :colspan="2"
              >
                <div :class="$style.bikou">
                  <div>備考</div>
                  <AutoSizingTextarea v-model="documentNote" />
                </div>
              </td>
              <td :class="$style.tac" colspan="2">小計</td>
              <td :class="[$style.price, $style.summary]" v-cloak>
                ¥{{ (subTotalPrice + (discountPrice || 0)) | formatPrice }}
              </td>
            </tr>
            <tr>
              <td :class="$style.tac" colspan="2" v-cloak>
                消費税（{{ taxRate }}%）
              </td>
              <td :class="[$style.price, $style.summary]" v-cloak>
                ¥{{ taxTotalPrice | formatPrice }}
              </td>
            </tr>
            <tr v-if="discountPrice != null">
              <td :class="$style.tac" colspan="2" v-cloak>
                値引き
              </td>
              <td
                :class="[$style.price, $style.summary]"
                style="color:red"
                v-cloak
              >
                -¥{{ discountPrice | formatPrice }}
              </td>
            </tr>
            <tr>
              <td :class="$style.tac" colspan="2">税込み金額</td>
              <td :class="[$style.price, $style.summary]" v-cloak>
                ¥{{ totalPrice | formatPrice }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
import AdminDealingPrintMixin from "@/mixin/AdminDealingPrintMixin.js"
import Logo from "@/components/atoms/Logo.vue"
import AutoSizingTextarea from "@/components/atoms/AutoSizingTextarea.vue"

export default {
  mixins: [AdminDealingPrintMixin],
  components: {
    Logo,
    AutoSizingTextarea,
  },
  data() {
    return {
      customerNameWidth: "",
      personNameWidth: "",
    }
  },
  watch: {
    customerName() {
      this.calcWidth(this.updateCustomerNameWidthStyle)
    },
    personName() {
      this.calcWidth(this.updatePersonNameWidthStyle)
    },
  },
  methods: {
    updateCustomerNameWidthStyle() {
      this.customerNameWidth = `width: ${this.$refs.customerName.clientWidth +
        10}px;`
    },
    updatePersonNameWidthStyle() {
      this.personNameWidth = `width: ${this.$refs.personName.clientWidth +
        10}px;`
    },
    calcWidth(targetMethod) {
      this.$nextTick(() => targetMethod())
    },
  },
}
</script>

<style lang="scss" module>
@include reportPirnt();
</style>
