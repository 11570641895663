var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.values !== null
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [
            _vm._v("取引先責任者" + _vm._s(_vm.isNew ? "新規作成" : "詳細")),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.inner, staticStyle: { width: "1000px" } },
            [
              _c(
                "InfoBlock",
                { attrs: { width: "49%", block: "" } },
                [
                  _c("template", { slot: "head" }, [_vm._v("所属/氏名")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.forms },
                    [
                      _c("PrSelect", {
                        attrs: {
                          name: "name",
                          label: "取引先名",
                          value: _vm.values.customer_id,
                          options: _vm.customerOptions,
                          validate: "required",
                          searchable: true,
                          "pr-row": [30],
                          linkPath: "/admin/customers",
                          linkName: "確認",
                        },
                        on: { input: _vm.handleCustomerSelect },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "last_name",
                          label: "姓",
                          validate: "required",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.last_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "last_name", $$v)
                          },
                          expression: "values.last_name",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "first_name",
                          label: "名",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.first_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "first_name", $$v)
                          },
                          expression: "values.first_name",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "email",
                          label: "メールアドレス",
                          validate: "required|email",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "email", $$v)
                          },
                          expression: "values.email",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.isNew
                        ? _c("PrCheckbox", {
                            attrs: {
                              "before-label": "ユーザー本登録",
                              value: _vm.values.user_status,
                              "pr-row": [30],
                              disabled: "",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "department",
                          label: "部署",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.department,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "department", $$v)
                          },
                          expression: "values.department",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: { name: "title", label: "役職", "pr-row": [30] },
                        model: {
                          value: _vm.values.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "title", $$v)
                          },
                          expression: "values.title",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "sales_office",
                          label: "営業所",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.sales_office,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "sales_office", $$v)
                          },
                          expression: "values.sales_office",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "phone",
                          label: "電話番号",
                          validate: "telephone",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "phone", $$v)
                          },
                          expression: "values.phone",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "fax",
                          label: "FAX",
                          validate: "fax",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.fax,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "fax", $$v)
                          },
                          expression: "values.fax",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrInput", {
                        attrs: {
                          name: "mobile_phone",
                          label: "携帯電話",
                          validate: "mobilephone",
                          "pr-row": [30],
                        },
                        model: {
                          value: _vm.values.mobile_phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "mobile_phone", $$v)
                          },
                          expression: "values.mobile_phone",
                        },
                      }),
                      _vm._v(" "),
                      _c("PrCheckbox", {
                        attrs: { "before-label": "退職", "pr-row": [30] },
                        model: {
                          value: _vm.values.retirement,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "retirement", $$v)
                          },
                          expression: "values.retirement",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.inner_left, staticStyle: { width: "49%" } },
                [
                  _c(
                    "InfoBlock",
                    {
                      attrs: { width: "100%", block: "", margin: "0 0 20px 0" },
                    },
                    [
                      _c("template", { slot: "head" }, [_vm._v("住所")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.forms },
                        [
                          _c(
                            "PrInput",
                            {
                              attrs: {
                                name: "zip_code",
                                label: "郵便番号",
                                validate: "zipcode",
                                "pr-row": [30, 50],
                              },
                              model: {
                                value: _vm.values.zip_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.values, "zip_code", $$v)
                                },
                                expression: "values.zip_code",
                              },
                            },
                            [
                              _c("ButtonSerchZipCode", {
                                attrs: {
                                  slot: "after",
                                  zipcode: _vm.values.zip_code,
                                },
                                on: { click: _vm.handleZipSearch },
                                slot: "after",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: { label: "都道府県", "pr-row": [30] },
                            model: {
                              value: _vm.values.location,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "location", $$v)
                              },
                              expression: "values.location",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: { label: "市区郡", "pr-row": [30] },
                            model: {
                              value: _vm.values.address1,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "address1", $$v)
                              },
                              expression: "values.address1",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: { label: "町名・番地", "pr-row": [30] },
                            model: {
                              value: _vm.values.address2,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "address2", $$v)
                              },
                              expression: "values.address2",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: { label: "建物名", "pr-row": [30] },
                            model: {
                              value: _vm.values.building,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "building", $$v)
                              },
                              expression: "values.building",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm.selectedCustomerAddress
                          ? _c(
                              "p",
                              { class: _vm.$style.address_radio_wrapper },
                              _vm._l(
                                [
                                  ["「取引先」住所に変更", "0"],
                                  [
                                    "「取引先責任者」住所に変更(「取引先」住所も上書き)",
                                    "1",
                                  ],
                                  ["上書きしない", "2"],
                                ],
                                function ([label, value]) {
                                  return _c("PrInputRadio", {
                                    key: value,
                                    attrs: {
                                      label: label,
                                      value: value,
                                      name: "addressOverwriteOption",
                                    },
                                    on: {
                                      change: _vm.handleAddressOverwriteOption,
                                    },
                                    model: {
                                      value: _vm.addressOverwriteOption,
                                      callback: function ($$v) {
                                        _vm.addressOverwriteOption = $$v
                                      },
                                      expression: "addressOverwriteOption",
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "InfoBlock",
                    { attrs: { width: "100%", block: "" } },
                    [
                      _c("template", { slot: "head" }, [_vm._v("その他")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.forms },
                        [
                          _c("PrCheckbox", {
                            attrs: {
                              "before-label": "メルマガ会員",
                              "pr-row": [30],
                            },
                            model: {
                              value: _vm.values.mail_magazine_members,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.values,
                                  "mail_magazine_members",
                                  $$v
                                )
                              },
                              expression: "values.mail_magazine_members",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrCheckbox", {
                            attrs: {
                              "before-label": "メール配信",
                              "pr-row": [30],
                            },
                            model: {
                              value: _vm.values.mail_delivery,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "mail_delivery", $$v)
                              },
                              expression: "values.mail_delivery",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrCheckbox", {
                            attrs: { "before-label": "DM拒否", "pr-row": [30] },
                            model: {
                              value: _vm.values.reject_direct_mail,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "reject_direct_mail", $$v)
                              },
                              expression: "values.reject_direct_mail",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrCheckbox", {
                            attrs: {
                              "before-label": "広告代理店",
                              "pr-row": [30],
                            },
                            model: {
                              value: _vm.values.advertising_agency,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "advertising_agency", $$v)
                              },
                              expression: "values.advertising_agency",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: {
                              name: "expo",
                              label: "エキスポ情報",
                              "pr-row": [30],
                            },
                            model: {
                              value: _vm.values.expo,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "expo", $$v)
                              },
                              expression: "values.expo",
                            },
                          }),
                          _vm._v(" "),
                          _c("PrInput", {
                            attrs: {
                              name: "description",
                              label: "説明",
                              "pr-row": [30],
                            },
                            model: {
                              value: _vm.values.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.values, "description", $$v)
                              },
                              expression: "values.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isNew
            ? _c("RelatedInfo", { attrs: { infoList: _vm.relaedInfo } }, [
                _vm._v("関連情報"),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNew
            ? _c(
                "InfoBlock",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("template", { slot: "head" }, [
                    _vm._v("取引先責任者更新履歴"),
                  ]),
                  _vm._v(" "),
                  _c("InnerTable", {
                    attrs: {
                      head: _vm.histories.head,
                      body: _vm.histories.body,
                      "link-contain": true,
                    },
                  }),
                  _vm._v(" "),
                  _vm.histories.body.length === 0
                    ? _c("p", { class: _vm.$style.noParson }, [
                        _vm._v("\n      履歴がありません\n    "),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNew
            ? _c(
                "InfoBlock",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("template", { slot: "head" }, [_vm._v("商談")]),
                  _vm._v(" "),
                  _c("InnerTable", {
                    attrs: {
                      head: _vm.dealings.head,
                      body: _vm.dealings.body,
                      "link-contain": true,
                    },
                  }),
                  _vm._v(" "),
                  _vm.dealings.body.length === 0
                    ? _c("p", { class: _vm.$style.noParson }, [
                        _vm._v("\n      商談実績はありません\n    "),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.buttons_center },
            [
              _c(
                "Button",
                {
                  attrs: { color: "submit", size: "main" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSaveButtonClick.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("編集内容を保存する")]
              ),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "Button",
                    {
                      attrs: { color: "cancel", size: "assist" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.initValues.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("クリア")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c("DialogProvider", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: `本当に${_vm.items.last_name}${_vm.items.first_name}を削除しますか？`,
                        yas: "はい",
                        no: "いいえ",
                      },
                    },
                    on: { accept: _vm.handleDelete },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return _c(
                              "Button",
                              _vm._g(
                                { attrs: { color: "alert", size: "assist" } },
                                on
                              ),
                              [_vm._v("削除")]
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2488080323
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }