<template>
  <InfoBlock block width="48%" margin="0">
    <template slot="head">
      オプション代金
    </template>
    <div :class="$style.row">
      <PrInput
        :value="value.contract.contract_details[optionIdxs.proofreading].price"
        @input="updateValue($event, optionIdxs.proofreading)"
        label="色校正代金"
        :pr-row="[60]"
        name="proofreading"
        :validate="'required|numeric'"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :value="value.contract.contract_details[optionIdxs.design_fee].price"
        @input="updateValue($event, optionIdxs.design_fee)"
        label="デザイン料"
        :pr-row="[60]"
        name="design_fee"
        :validate="'required|numeric'"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :value="value.contract.contract_details[optionIdxs.mount_fee].price"
        @input="updateValue($event, optionIdxs.mount_fee)"
        label="MF台紙代"
        :pr-row="[60]"
        name="mount_fee"
        :validate="'required|numeric'"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :value="value.contract.contract_details[optionIdxs.type_fee].price"
        @input="updateValue($event, optionIdxs.type_fee)"
        label="型代"
        :pr-row="[60]"
        name="type_fee"
        :validate="'required|numeric'"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :value="value.contract.contract_details[optionIdxs.discount].price"
        @input="updateValue($event, optionIdxs.discount)"
        label="値引き"
        :pr-row="[60]"
        name="discount"
        :validate="'required|numeric'"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :value="value.contract.contract_details[optionIdxs.shipping_fee].price"
        @input="updateValue($event, optionIdxs.shipping_fee)"
        label="追加送料"
        :pr-row="[60]"
        name="shipping_fee"
        :validate="'required|numeric'"
      />
    </div>
    <div :class="$style.row">
      <PrInput
        :value="value.contract.contract_details[optionIdxs.other_fee].price"
        @input="updateValue($event, optionIdxs.other_fee)"
        label="その他代金"
        :pr-row="[60]"
        name="other_fee"
        :validate="'required|numeric'"
      />
    </div>
    <div :class="$style.row">
      <p>
        <span>企業値引き</span>
        <span>{{
          value.contract.contract_details | calcTotalCompanyDiscount(discount)
        }}</span>
      </p>
    </div>
  </InfoBlock>
</template>

<script>
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import { calcTotalCompanyDiscount } from "@/helpers/QuotationDetailViewHelper.js"

export default {
  inject: ["$validator"],
  components: {
    InfoBlock,
    PrInput,
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
    discount: {
      type: Number,
      require: true,
    },
  },
  computed: {
    optionIdxs() {
      return [
        "proofreading",
        "design_fee",
        "mount_fee",
        "type_fee",
        "discount",
        "shipping_fee",
        "other_fee",
      ].reduce(
        (acc, txt) => ({
          ...acc,
          [txt]: this.value.contract.contract_details.findIndex(
            ({ category2 }) => category2 === txt,
          ),
        }),
        {},
      )
    },
  },
  filters: {
    calcTotalCompanyDiscount: (cds, discount) =>
      calcTotalCompanyDiscount(cds, discount),
  },
  methods: {
    updateValue(val, idx) {
      const newValue = JSON.parse(JSON.stringify(this.value))
      newValue.contract.contract_details[idx].price = val
      this.$emit("input", newValue)
    },
  },
}
</script>

<style lang="scss" module>
.row {
  padding: 10px 20px;
  > div[name="pr-text"] {
    width: 200px;
  }
  > p {
    display: flex;
    align-items: center;
    width: 200px;
    > span {
      &:first-child {
        width: 60%;
        color: map-get($colors, darkGlay);
      }
    }
  }
  &:nth-child(odd) {
    background-color: map-get($colors, lightlightGray);
  }
}
</style>
