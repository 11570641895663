var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.contactBlock }, [
    _c("h2", { class: _vm.$style.contactBlock_ttl }, [
      _vm._v("\n    仕入先コンタクト情報\n  "),
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style.contactBlock_table }, [
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("見積入力")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contactBlock_content }, [
          _c(
            "div",
            { class: _vm.$style.contactBlock_firstRow },
            [
              _c("PrSelect", {
                attrs: {
                  value: _vm.items.quantity1,
                  options:
                    _vm.$store.getters["defs/getOptions"]("InputEstimates"),
                },
                on: { input: _vm.handleContruntStatusChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("見積")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contactBlock_content }, [
          _c("div", { class: _vm.$style.contactBlock_firstRow }, [
            _c("span", { class: _vm.$style.contactBlock_text }, [
              _vm._v(_vm._s(_vm.items.estimate_status) + " 入力済"),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [
          _vm._v("入稿データ"),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contactBlock_content }, [
          _c("div", { class: _vm.$style.contactBlock_firstRow }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$store.getters["defs/getOptionValueByKey"](
                    "DraftStatus_PR",
                    _vm.draft.request_key3
                  )
                ) +
                "\n        "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [
          _vm._v("最終確認画像"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.contactBlock_content },
          [
            _c(
              "div",
              { class: _vm.$style.contactBlock_firstRow },
              [
                _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$store.getters["defs/getOptionValueByKey"](
                          "VerificationStatus_PR",
                          _vm.verification_image.request_key3
                        )
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _vm.verification_image.request_key3 === "2"
                  ? [
                      _c("DialogProvider", {
                        attrs: {
                          texts: {
                            ttl: "確認",
                            msg: "最終確認画像をOKにしますか",
                            yas: "はい",
                            no: "いいえ",
                          },
                        },
                        on: {
                          accept: function ($event) {
                            return _vm.handleOKNGclick("verification_image", {
                              request_key4: "1",
                              request_key3: "3",
                              appendix_status: "2",
                            })
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ on }) {
                                return _c(
                                  "span",
                                  _vm._g(
                                    {
                                      class: [
                                        _vm.$style.contactBlock_minButton,
                                        _vm.$style.ok,
                                      ],
                                    },
                                    on
                                  ),
                                  [_vm._v("OK")]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          948207370
                        ),
                      }),
                      _vm._v(" "),
                      _c("DialogProvider", {
                        attrs: {
                          texts: {
                            ttl: "確認",
                            msg: "最終確認画像をNGにしますか",
                            yas: "はい",
                            no: "いいえ",
                          },
                        },
                        on: { accept: _vm.handleRefuseVerificationImage },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ on }) {
                                return _c(
                                  "span",
                                  _vm._g(
                                    {
                                      class: [
                                        _vm.$style.contactBlock_minButton,
                                        _vm.$style.ng,
                                      ],
                                    },
                                    on
                                  ),
                                  [_vm._v("NG")]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          442059786
                        ),
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.verification_image.appendix_status > 1
              ? _c("PrImg", {
                  class: _vm.$style.contactBlock_img,
                  attrs: { src: _vm.verification_image.appendix_name },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("発注書")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contactBlock_content }, [
          !_vm.orderedContract
            ? _c("div", { class: _vm.$style.contactBlock_firstRow }, [
                _vm._v("\n          注文未確定\n        "),
              ])
            : _vm.orderedContract && !_vm.orderedContract.supplier
            ? _c("div", [_vm._v("\n          仕入先未確定\n        ")])
            : _c(
                "div",
                { class: _vm.$style.contactBlock_firstRow },
                [
                  ["1", "2", "3", "5"].includes(
                    _vm.orderedContract.supplier.supplier_code
                  )
                    ? [
                        _c(
                          "div",
                          { staticStyle: { width: "120px" } },
                          [
                            _c("DialogProvider", {
                              staticStyle: { "justify-content": "flex-start" },
                              attrs: {
                                texts: {
                                  ttl: "確認",
                                  msg:
                                    _vm.items.quantity4 === 0
                                      ? "公開しますか？"
                                      : "公開停止しますか?",
                                  yas: "はい",
                                  no: "いいえ",
                                },
                              },
                              on: {
                                accept: function ($event) {
                                  return _vm.handleOrderSheetRelease(
                                    _vm.items.quantity4 === 0 ? "1" : "0"
                                  )
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ on }) {
                                      return _c(
                                        "span",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            "!click": function ($event) {
                                              $event.stopPropagation()
                                              return ((e) => {
                                                if (_vm.items.status >= 12) {
                                                  return
                                                }
                                                on.click(e)
                                              }).apply(null, arguments)
                                            },
                                          },
                                        },
                                        [
                                          _c("PrCheckbox", {
                                            staticStyle: {
                                              "pointer-events": "none",
                                            },
                                            attrs: {
                                              "before-label": "公開する",
                                              value: _vm.items.quantity4,
                                              disabled: _vm.items.status >= 12,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1521938043
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "Anker",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "margin-left": "10px",
                            },
                            attrs: { to: _vm.ordersheetPath, target: "_blank" },
                          },
                          [_vm._v("内容確認")]
                        ),
                      ]
                    : [_vm._v("対象外")],
                ],
                2
              ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [
          _vm._v("生産/商品写真"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.contactBlock_content },
          [
            _c(
              "div",
              { class: _vm.$style.contactBlock_firstRow },
              [
                _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$store.getters["defs/getOptionValueByKey"](
                          "ProduceStatus",
                          _vm.product_image.request_key3 || "0"
                        )
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _vm.product_image.request_key3 == "3"
                  ? [
                      _c("DialogProvider", {
                        attrs: {
                          texts: {
                            ttl: "確認",
                            msg: "商品画像をOKにしますか",
                            yas: "はい",
                            no: "いいえ",
                          },
                        },
                        on: {
                          accept: function ($event) {
                            return _vm.handleOKNGclick("product_image", {
                              request_key3: "4",
                              appendix_status: "2",
                            })
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ on }) {
                                return _c(
                                  "span",
                                  _vm._g(
                                    {
                                      class: [
                                        _vm.$style.contactBlock_minButton,
                                        _vm.$style.ok,
                                      ],
                                    },
                                    on
                                  ),
                                  [_vm._v("OK")]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          948207370
                        ),
                      }),
                      _vm._v(" "),
                      _c("DialogProvider", {
                        attrs: {
                          texts: {
                            ttl: "確認",
                            msg: "商品画像をNGにしますか",
                            yas: "はい",
                            no: "いいえ",
                          },
                        },
                        on: {
                          accept: function ($event) {
                            return _vm.handleOKNGclick("product_image", {
                              request_key3: "6",
                              appendix_status: "1",
                            })
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ on }) {
                                return _c(
                                  "span",
                                  _vm._g(
                                    {
                                      class: [
                                        _vm.$style.contactBlock_minButton,
                                        _vm.$style.ng,
                                      ],
                                    },
                                    on
                                  ),
                                  [_vm._v("NG")]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          442059786
                        ),
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.product_image.request_key3 == "3" ||
            _vm.product_image.request_key3 == "4"
              ? _c("PrImg", {
                  class: _vm.$style.contactBlock_img,
                  attrs: { src: _vm.product_image.appendix_name },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.contactBlock_row }, [
        _c("p", { class: _vm.$style.contactBlock_name }, [_vm._v("発送情報")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.contactBlock_content }, [
          _c("div", { class: _vm.$style.contactBlock_firstRow }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$store.getters["defs/getOptionValueByKey"](
                    "GoodsIssue",
                    _vm.items.delivery_status
                  )
                ) +
                "\n        "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }