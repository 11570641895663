<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>価格表{{ isNew ? "新規作成" : "詳細" }}</PageTitle>
    <InfoBlock>
      <template slot="head"
        >価格表概要</template
      >
      <InfoList v-model="values" :list="outlineList" ref="infoList" />
    </InfoBlock>
    <RelatedInfo :infoList="relatedInfo">関連情報</RelatedInfo>
    <div :class="$style.buttons_center">
      <Button color="submit" size="main" @click.native="handleSaveButtonClick"
        >編集内容を保存する</Button
      >
      <Button
        v-if="!isNew"
        color="cancel"
        size="assist"
        @click.native="initValues"
        >クリア</Button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import InfoList from "@/components/molecules/InfoList.vue"
import RelatedInfo from "@/components/molecules/RelatedInfo.vue"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"
import * as math from "mathjs"

export default {
  components: {
    Button,
    PageTitle,
    InfoBlock,
    InfoList,
    RelatedInfo,
  },
  data() {
    return {
      isReady: false,
      isNew: false,
      items: null,
      values: null,
      productItems: null,
      selectedProduct: null,
      readOnly: false,
    }
  },
  computed: {
    relatedInfo() {
      return this.createRelateInfo(this.selectedProduct)
    },
    outlineList() {
      let productName = {}

      if (this.isNew) {
        const data = this.productItems.map(v => {
          let name = v.name
          if (v.model_number) {
            name += "/" + v.model_number
          }
          return {
            key: v.item_code,
            value: name,
          }
        })

        productName = {
          name: "商品/型番",
          key: "name",
          type: "select",
          options: data,
          searchable: true,
          func: this.changeProduct,
          validate: {
            rule: "required",
          },
        }
      } else {
        productName = {
          name: "商品",
          key: "name",
          type: "text",
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
          readonly: true,
        }
      }

      const priceObject = this.readOnly
        ? {
            name: "単価(税別)",
            key: "unit_price",
            type: "text",
            readonly: true,
          }
        : {
            name: "単価(税別)",
            key: "unit_price",
            type: "text",
            validate: {
              rule: "required|decimal:3",
              message: {
                required: "必須項目です。",
                decimal: "小数点以下3桁までの数値を入力してください。",
              },
            },
          }

      return [
        productName,
        {
          name: "仕入値",
          key: "purchase_amount",
          type: "text",
          validate: {
            rule: "required|numeric",
            message: {
              required: "必須項目です。",
              numeric: "数値を入力してください。",
            },
          },
          func: this.changeCost,
        },
        {
          name: "原価",
          key: "cost",
          type: "text",
          readonly: true,
          validate: null,
        },
        {
          name: "掛率",
          key: "description",
          type: "text",
          validate: {
            rule: "decimal:3",
            message: {
              decimal: "小数点以下3桁までの数値を入力してください。",
            },
          },
          func: this.changeCost,
        },
        {
          name: "輸送費",
          key: "merchandise_cost",
          type: "text",
          validate: {
            rule: "required|decimal:1",
            message: {
              required: "必須項目です。",
              decimal: "小数点以下1桁までの数値を入力してください。",
            },
          },
          func: this.changeCost,
        },
        priceObject,
        {
          name: "適用開始日",
          key: "start_datetime",
          type: "datepicker",
          validate: {
            rule: "required",
            message: {
              required: "必須項目です。",
            },
          },
        },
      ]
    },
  },
  methods: {
    createRelateInfo(info) {
      return [
        ["型番", info.model_number],
        ["商品ファミリ", info.family],
        ["カバー・台紙サイズ", info.size1],
        ["付箋サイズ", info.size2],
        [
          "付箋印刷",
          this.$store.getters["defs/getOptionValueByKey"](
            "TagPrint",
            info.color,
            "JP",
          ),
        ],
        ["付箋枚数", info.size3],
        ["生産個数", info.size4],
        ["ホームページURL", info.image_url],
        ["税区分", info.tax_category],
        ["更新者", info.update_name],
        ["更新日", info.update_at],
      ]
    },
    clearSelectedProduct() {
      for (const key in this.selectedProduct) {
        this.selectedProduct[key] = ""
      }
    },
    changeProduct() {
      const product = this.productItems.find(
        ({ item_code }) => item_code == this.values.name,
      )

      if (!product) {
        this.clearSelectedProduct()
        return
      }

      const family = this.$store.getters["defs/getOptionValueByKey"](
        "ProductFamily",
        product.category1,
      )

      this.selectedProduct = {
        model_number: product.model_number,
        family: family,
        size1: product.size1,
        size2: product.size2,
        color: product.color,
        size3: product.size3,
        size4: product.size4,
        image_url: product.image_url,
        tax_category: product.tax_category,
        update_name: "",
        update_at: "",
      }
    },
    setReadOnly(description) {
      this.readOnly = !(!description || description === "0")
    },
    changeCost() {
      this.values.unit_price = this.calcUnitPrice(
        this.values.purchase_amount,
        this.values.description,
        this.values.unit_price,
      )
      this.values.cost = this.calcCost(
        this.values.purchase_amount,
        this.values.merchandise_cost,
      )
      this.setReadOnly(this.values.description)
    },
    calcUnitPrice(purchase_amount, description, unit_price) {
      if (!description || description === "0") {
        return Number(unit_price)
      } else {
        // 単価=仕入額*掛率
        const p = math
          .chain(math.bignumber(Number(purchase_amount)))
          .multiply(math.bignumber(Number(description)))
          .round(3)
          .done()
          .toNumber()

        return math.isNaN(p) ? "算出不可" : p
      }
    },
    calcCost(purchase_amount, merchandise_cost) {
      // 原価=(仕入額*0.1)+輸送費
      const p = math
        .chain(math.bignumber(Number(purchase_amount)))
        .divide(10)
        .add(math.bignumber(Number(merchandise_cost)))
        .done()
        .toNumber()

      return math.isNaN(p) ? "算出不可" : p
    },

    async validate() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      return result
    },
    async handleSaveButtonClick() {
      const result = await this.validate()
      console.log("validate = ", result)
      if (!result) {
        return
      }
      const time = moment(this.values.start_datetime)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssZ")

      let data = {
        unit_price: this.values.unit_price,
        start_datetime: time,
        purchase_amount: this.values.purchase_amount,
        merchandise_cost: this.values.merchandise_cost,
        description: this.values.description,
      }

      if (!this.isNew) {
        data.lock_version = this.items.lock_version
        data.item_code = this.items.item_code
      } else {
        data.item_code = this.values.name
      }

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.post(
          paths.ADMIN_PRICES,
          data,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$router.replace({ path: "/admin/prices" })
      } finally {
        loader.hide()
      }
    },
    initValues() {
      this.$validator.reset()
      if (this.$refs.infoList) {
        this.$refs.infoList.clearErrorRequired()
      }

      let unitPrice = this.calcUnitPrice(
        this.items.purchase_amount,
        this.items.description,
        this.items.unit_price,
      )

      let cost = this.calcCost(
        this.items.purchase_amount,
        this.items.merchandise_cost,
      )

      this.values = {
        name: this.items.item.name,
        purchase_amount: this.items.purchase_amount,
        cost: cost,
        description: this.items.description,
        merchandise_cost: this.items.merchandise_cost,
        unit_price: unitPrice,
        start_datetime: moment(
          this.items.start_datetime,
          "YYYY-MM-DDTHH:mm:ssZ",
        ).format("YYYY/MM/DD"),
      }
      this.setReadOnly(this.values.description)
    },
    async initNew() {
      const { ok, body } = await api.get(
        paths.ADMIN_NOT_PRICE_ITEMS,
        this.$store.state.auth.token,
      )

      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      this.productItems = body

      this.items = {
        item: {
          name: "",
        },
        purchase_amount: "",
        cost: "",
        description: "",
        merchandise_cost: "",
        unit_price: "",
        start_datetime: moment(new Date()).format("YYYY/MM/DD"),
      }

      this.selectedProduct = {
        model_number: "",
        family: "",
        size1: "",
        size2: "",
        color: "",
        size3: "",
        size4: "",
        image_url: "",
        tax_category: "",
        update_name: "",
        update_at: "",
      }
      this.initValues()
    },
    async initEdit() {
      const { ok, body } = await api.get(
        `${paths.ADMIN_PRICES}/${this.$route.params.price_id}`,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }

      const [updatedAt] = [body.updated_at].map(v =>
        moment(v, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD"),
      )

      const family = this.$store.getters["defs/getOptionValueByKey"](
        "ProductFamily",
        body.item.category1,
      )

      this.selectedProduct = {
        model_number: body.item.model_number,
        family: family,
        size1: body.item.size1,
        size2: body.item.size2,
        color: body.item.color,
        size3: body.item.size3,
        size4: body.item.size4,
        image_url: body.item.image_url,
        tax_category: body.item.tax_category,
        update_name: body.inserted.name,
        update_at: updatedAt,
      }

      this.items = body
      this.initValues()
    },
  },
  async created() {
    if (this.$route.name === "adminPriceNew") {
      this.isNew = true
      await this.initNew()
    } else {
      await this.initEdit()
    }

    this.isReady = true
  },
}
</script>

<style lang="scss" module>
@include dbDetailView();
</style>
