var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.relatedInfo }, [
    _c("div", [
      _c("p", { class: _vm.$style.relatedInfo_p }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.relatedInfo_infoAria },
        _vm._l(_vm.infoList, function ([name, val], idx) {
          return _c("div", { key: idx, class: _vm.$style.relatedInfo_item }, [
            _c("p", { class: _vm.$style.relatedInfo_ttl }, [
              _vm._v(_vm._s(name)),
            ]),
            _vm._v(" "),
            _c("p", { class: _vm.$style.relatedInfo_txt }, [
              _vm._v(_vm._s(val)),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }