<template>
  <div v-if="values !== null" :class="$style.wrapper">
    <PageTitle>詳細品目 オーダーメイド見積</PageTitle>
    <InfoBlock width="1600px" block margin="0 0 20px">
      <template slot="head"
        >見積概要</template
      >
      <div :class="$style.row">
        <div :class="$style.custom">
          <p>見積名</p>
          <p>
            <router-link
              :to="
                `/admin/contracts/${
                  this.items.contract.request_key2
                }/dealings/${this.items.request_number}`
              "
            >
              {{ this.items.contract.request_name }}
            </router-link>
          </p>
        </div>
        <PrDatePicker
          label="見積依頼日"
          :pr-row="[35]"
          v-model="values.datetime1"
        />
        <PrSelect
          label="見積確認"
          :pr-row="[35]"
          v-model="values.weight3"
          :options="$store.getters['defs/getOptions']('EstimatesContentStatus')"
        />
      </div>
    </InfoBlock>

    <OMContractDetailEditer
      v-bind="{ supplierOptions }"
      :contructDetail="values"
      @saved="handleSave"
    >
      <div
        slot="buttons"
        slot-scope="{ handleAddButtonClick, resetValidator }"
        :class="$style.buttons"
      >
        <Button color="submit" size="main" @click="handleAddButtonClick"
          >編集内容保存する</Button
        >
        <Button
          color="cancel"
          size="assist"
          @click="
            () => {
              initValues()
              resetValidator()
            }
          "
          >クリア</Button
        >

        <DialogProvider
          :texts="{
            ttl: '確認',
            msg: '削除しますか？',
            yas: '削除する',
            no: 'キャンセル',
          }"
          @accept="handleDeleteAccept"
          style="display:inline-block"
        >
          <Button slot-scope="{ on }" size="main" color="alert" v-on="on"
            >削除</Button
          >
        </DialogProvider>
      </div>
    </OMContractDetailEditer>
  </div>
</template>

<script>
import OMContractDetailEditer from "@/components/molecules/OMContractDetailEditer.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import PrDatePicker from "@/components/molecules/PrDatePicker.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import { routeReplaceError, showErrorToast } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"

export default {
  components: {
    Button,
    PageTitle,
    InfoBlock,
    PrDatePicker,
    PrSelect,
    OMContractDetailEditer,
    DialogProvider,
  },
  data() {
    return {
      items: null,
      suppliers: null,
      values: null,
    }
  },
  computed: {
    supplierOptions() {
      return this.suppliers.map(({ supplier_code, id }) => ({
        key: id,
        value: this.$store.getters["defs/getOptionValueByKey"](
          "SupplierCode",
          supplier_code,
        ),
      }))
    },
  },
  methods: {
    initValues() {
      const cd = this.items.contract.contract.contract_details[0]
      this.values = {
        id: cd.id,
        contract_name: cd.contract_name,
        contract_no: cd.contract_no,
        lock_version: cd.lock_version,
        category1: cd.category1,
        category2: cd.category2,
        item_code: cd.item_code,
        name: cd.name,
        size1: cd.size1,
        size2: cd.size2,
        color: cd.color,
        size3: cd.size3,
        size4: cd.size4,
        amount: cd.amount,
        price: cd.price,
        tax_category: cd.tax_category,
        thumbnail: cd.thumbnail,
        category3: cd.category3, // カバー型抜き
        category4: cd.category4, // 付箋型抜き
        description: cd.description, // 備考
        purchase_amount: cd.purchase_amount, // 仕入額
        merchandise_cost: cd.merchandise_cost, // 輸送費
        weight1: cd.weight1, // 掛け率
        //manufacturer: cd.manufacturer, //見積先
        image_url: cd.image_url,
        delivery_area: cd.delivery_area,
        weight3: cd.weight3, // 見積内容
        datetime1: cd.datetime1, //見積依頼日
      }
    },
    async handleSave(postValue) {
      const { weight3, datetime1 } = this.values

      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.post(
          paths.ADMIN_OM_CONTRACTS,
          { ...postValue, weight3, datetime1 },
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$router.push("/admin/om-contracts")
      } finally {
        loader.hide()
      }
    },
    async handleDeleteAccept() {
      const loader = this.$loading.show()

      try {
        const { ok, body, errorMsg } = await api.DELETE(
          paths.ADMIN_OM_CONTRACTS + "/" + this.$route.params.om_contract_id,
          this.$store.state.auth.token,
        )
        if (!ok) {
          if (body.slice(-3) === String(400)) {
            showErrorToast(
              this,
              body.slice(-3),
              errorMsg,
              "この品目は削除出来ません。",
            )
            return
          }
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$router.push("/admin/om-contracts")
      } finally {
        loader.hide()
      }
    },
  },
  async created() {
    const [
      { ok: oOk, body: oBody },
      { ok: sOk, body: sBody },
    ] = await Promise.all([
      api.get(
        `${paths.ADMIN_OM_CONTRACTS}/${this.$route.params.om_contract_id}`,
        this.$store.state.auth.token,
      ),
      api.get(paths.ADMIN_SUPPLIERS, this.$store.state.auth.token),
    ])
    if (!oOk) {
      routeReplaceError(this, oBody.slice(-3))
    }
    if (!sOk) {
      routeReplaceError(this, sBody.slice(-3))
    }

    this.items = oBody
    this.suppliers = sBody
    this.initValues()
  },
}
</script>

<style lang="scss" module>
.wrapper {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  padding: 10px 20px;
  background-color: map-get($colors, lightlightGray);
  display: flex;
  > div {
    width: calc(100% / 3);
  }
}
.custom {
  display: flex;
  align-items: center;
  > p:first-of-type {
    width: 35%;
    color: map-get($colors, darkGlay);
  }
}
.buttons {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -80px;
}
</style>
