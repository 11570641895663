var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InfoBlock",
    { attrs: { width: "100%", block: "", margin: "0" } },
    [
      _c("template", { slot: "head" }, [_vm._v("\n    入金情報\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "入金予定日",
              "pr-row": [30],
              value: _vm.value.request_date3,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "request_date3")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrDatePicker", {
            attrs: {
              label: "入金日",
              "pr-row": [30],
              value: _vm.value.request_date4,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "request_date4")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrSelect", {
            attrs: {
              name: "quantity4",
              label: "支払い方法",
              "pr-row": [30],
              value: _vm.value.quantity4,
              options: _vm.$store.getters["defs/getOptions"]("PaymentMethod"),
              validate: "required",
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "quantity4")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.row },
        [
          _c("PrCheckbox", {
            attrs: {
              "before-label": "後払い",
              "pr-row": [30],
              value: _vm.value.quantity5,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event, "quantity5")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", { class: _vm.$style.distribution_item_info_row_name }, [
          _vm._v("\n      コレクト代金\n    "),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.distribution_item_info_row_txt }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("formatPrice")(_vm.collectFee)) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.row }, [
        _c("p", { class: _vm.$style.distribution_item_info_row_name }, [
          _vm._v("\n      請求金額(税込)\n    "),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.distribution_item_info_row_txt }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("formatPrice")(_vm.billingAmount + _vm.collectFee)
              ) +
              "\n    "
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }