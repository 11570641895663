<template>
  <div :class="$style.wrapper">
    <h3>商談管理No : {{ deal.request_number }}</h3>
    <div :class="$style.inner">
      <div :class="$style.buttons">
        <Button
          size="assist"
          color="link"
          @click="
            $router.push(`/customer/ordered-histories/${deal.request_number}`)
          "
          >詳細を確認</Button
        ><br />
        <Button
          size="assist"
          color="submit"
          @click="
            $router.push(
              `/customer/ordered-histories/${deal.request_number}/re-order`,
            )
          "
          >再注文</Button
        >
      </div>
      <p :class="$style.txt">
        注文日 : {{ deal.contract.contract.contracted_date | getDate }}
      </p>
      <p :class="$style.txt">お支払 : {{ deal.payment | formatPrice }}</p>
      <div :class="$style.img">
        <p :class="$style.txt" style="margin-bottom:0;">最終確認画像:</p>
        <div>
          <img :src="deal.verification_image_path" />
        </div>
      </div>
      <ul :class="$style.ul">
        <li>詳細品目</li>
        <li
          v-for="contractDetail in contractDetailMade"
          :key="contractDetail.id"
        >
          {{ getProductName(contractDetail) }}
        </li>
      </ul>
      <p v-if="deal.display_ordered_status != null" :class="$style.status">
        {{
          $store.getters["defs/getOptionValueByKey"](
            "OrderedStatus",
            deal.display_ordered_status,
          )
        }}
      </p>
      <p v-else :class="$style.status">
        生産中
      </p>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import { getDate, formatPrice } from "@/utils/shared.js"

export default {
  components: {
    Button,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contractDetailMade() {
      return this.deal.contract.contract.contract_details.filter(
        ({ category2 }) => category2.endsWith("_made"),
      )
    },
  },
  filters: {
    getDate,
    formatPrice,
  },
  methods: {
    getProductName(contractDetail) {
      const productFamily = this.$store.getters["defs/getOptionValueByKey"](
        "ProductFamily",
        contractDetail.category1,
      )

      return (
        productFamily +
        (contractDetail.model_number ? `[${contractDetail.model_number}]` : "")
      )
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 320px;
  margin: 10px;
  > h3 {
    background-color: map-get($colors, lightYellow);
    padding: 5px 0;
    font-size: 0.16rem;
    text-align: center;
  }
}
.inner {
  width: 100%;
  padding: 10px;
  border: 1px solid map-get($colors, lightYellow);
  background-color: map-get($colors, white);
  position: relative;
  > * {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.txt {
  font-weight: bold;
}
.ul {
  padding: 0;
  list-style: none;
  > li {
    border: 1px solid map-get($colors, glay);
    border-top: none;
    padding-left: 10px;
    font-size: 0.14rem;
    &:first-child {
      text-align: center;
      padding-left: 5px;
      border-top: 1px solid map-get($colors, glay);
      font-size: 0.16rem;
      font-weight: bold;
    }
  }
}

.status {
  padding: 5px 0;
  font-weight: bold;
  text-align: center;
  color: map-get($colors, red);
  background-color: map-get($colors, lightGray);
}
.buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  > button {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
