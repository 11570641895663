var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [
            _vm._v("不良品" + _vm._s(_vm.isNew ? "新規作成" : "詳細")),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.relatedInfo }, [
            _c("div", [
              _c(
                "p",
                { class: _vm.$style.relatedInfo_p },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.relatedInfo_infoAria }, [
                _c("div", { class: _vm.$style.relatedInfo_item }, [
                  _c("p", { class: _vm.$style.relatedInfo_ttl }, [
                    _vm._v("不良品管理No"),
                  ]),
                  _vm._v(" "),
                  _c("p", { class: _vm.$style.relatedInfo_txt }, [
                    _vm._v(_vm._s(this.items.request_key2)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.relatedInfo_item }, [
                  _c("p", { class: _vm.$style.relatedInfo_ttl }, [
                    _vm._v("発注先"),
                  ]),
                  _vm._v(" "),
                  _c("p", { class: _vm.$style.relatedInfo_txt }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.$store.getters["defs/getOptionValueByKey"](
                            "SupplierCode",
                            this.itemsRelated.supplier.supplier_code
                          )
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.relatedInfo_item }, [
                  _c("p", { class: _vm.$style.relatedInfo_ttl }, [
                    _vm._v("商談名"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { class: _vm.$style.relatedInfo_txt },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: `/admin/dealings/${this.items.request_number}`,
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(this.itemsRelated.request_name) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.relatedInfo_item }, [
                  _c("p", { class: _vm.$style.relatedInfo_ttl }, [
                    _vm._v("取引先"),
                  ]),
                  _vm._v(" "),
                  _c("p", { class: _vm.$style.relatedInfo_txt }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.itemsRelated.customer.organization.name) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.relatedInfo_item }, [
                  _c("p", { class: _vm.$style.relatedInfo_ttl }, [
                    _vm._v("取引先責任者"),
                  ]),
                  _vm._v(" "),
                  _c("p", { class: _vm.$style.relatedInfo_txt }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.itemsRelated.customer_person.last_name +
                            this.itemsRelated.customer_person.first_name
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.relatedInfo_item }, [
                  _c("p", { class: _vm.$style.relatedInfo_ttl }, [
                    _vm._v("更新者"),
                  ]),
                  _vm._v(" "),
                  _c("p", { class: _vm.$style.relatedInfo_txt }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.items.inserted.name) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.relatedInfo_item }, [
                  _c("p", { class: _vm.$style.relatedInfo_ttl }, [
                    _vm._v("更新日"),
                  ]),
                  _vm._v(" "),
                  this.isNew
                    ? _c("p", { class: _vm.$style.relatedInfo_txt })
                    : _c("p", { class: _vm.$style.relatedInfo_txt }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("moment")(this.items.updated_at)) +
                            "\n          "
                        ),
                      ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "1000px", block: "" } },
            [
              _c("template", { slot: "head" }, [_vm._v("不良品詳細")]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.row },
                [
                  _c("PrSelect", {
                    attrs: {
                      label: "商品",
                      "pr-row": [35],
                      name: "name",
                      validate: "required",
                      options: _vm.productOptions,
                    },
                    model: {
                      value: _vm.values["name"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "name", $$v)
                      },
                      expression: "values['name']",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrSelect", {
                    attrs: {
                      label: "顧客連絡",
                      "pr-row": [35],
                      name: "status",
                      validate: "required",
                      options:
                        _vm.$store.getters["defs/getOptions"](
                          "DefectiveStatus"
                        ),
                    },
                    model: {
                      value: _vm.values["status"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "status", $$v)
                      },
                      expression: "values['status']",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.row },
                [
                  _c("PrDatePicker", {
                    attrs: { label: "対応期限", "pr-row": [35] },
                    model: {
                      value: _vm.values["request_date2"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "request_date2", $$v)
                      },
                      expression: "values['request_date2']",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrCheckbox", {
                    attrs: { "before-label": "再生産有無", "pr-row": [35] },
                    model: {
                      value: _vm.values["quantity1"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "quantity1", $$v)
                      },
                      expression: "values['quantity1']",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.row },
                [
                  _c("PrSelect", {
                    attrs: {
                      label: "不良種別",
                      "pr-row": [35],
                      name: "quantity2",
                      validate: "required",
                      options:
                        _vm.$store.getters["defs/getOptions"](
                          "DefectiveReason"
                        ),
                    },
                    model: {
                      value: _vm.values["quantity2"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "quantity2", $$v)
                      },
                      expression: "values['quantity2']",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.row },
                [
                  _c("PrInput", {
                    attrs: { label: "不良品詳細", "pr-row": [35] },
                    model: {
                      value: _vm.values["note1"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "note1", $$v)
                      },
                      expression: "values['note1']",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: { label: "原因", "pr-row": [35] },
                    model: {
                      value: _vm.values["note2"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "note2", $$v)
                      },
                      expression: "values['note2']",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.row }, [
                _c("div", { staticClass: "special" }, [
                  _c("p", [_vm._v("不良品写真①")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.values["appendix_name_image1"]
                        ? _c(
                            "div",
                            { class: _vm.$style.img },
                            [
                              _c("PrImg", {
                                attrs: {
                                  src: _vm.values["appendix_name_image1"],
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "Button",
                                {
                                  attrs: { size: "assist", color: "cancel" },
                                  on: {
                                    click: function ($event) {
                                      _vm.values["appendix_name_image1"] = null
                                    },
                                  },
                                },
                                [_vm._v("削除")]
                              ),
                            ],
                            1
                          )
                        : _c("PrImgInput", {
                            model: {
                              value: _vm.fileImage[0],
                              callback: function ($$v) {
                                _vm.$set(_vm.fileImage, 0, $$v)
                              },
                              expression: "fileImage[0]",
                            },
                          }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "special" }, [
                  _c("p", [_vm._v("不良品写真②")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.values["appendix_name_image2"]
                        ? _c(
                            "div",
                            { class: _vm.$style.img },
                            [
                              _c("PrImg", {
                                attrs: {
                                  src: _vm.values["appendix_name_image2"],
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "Button",
                                {
                                  attrs: { size: "assist", color: "cancel" },
                                  on: {
                                    click: function ($event) {
                                      _vm.values["appendix_name_image2"] = null
                                    },
                                  },
                                },
                                [_vm._v("削除")]
                              ),
                            ],
                            1
                          )
                        : _c("PrImgInput", {
                            model: {
                              value: _vm.fileImage[1],
                              callback: function ($$v) {
                                _vm.$set(_vm.fileImage, 1, $$v)
                              },
                              expression: "fileImage[1]",
                            },
                          }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "InfoBlock",
            { attrs: { width: "1000px", block: "" } },
            [
              _c("template", { slot: "head" }, [_vm._v("対策情報")]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.row },
                [
                  _c("PrInput", {
                    attrs: { label: "対策概要", "pr-row": [35] },
                    model: {
                      value: _vm.values["note3"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "note3", $$v)
                      },
                      expression: "values['note3']",
                    },
                  }),
                  _vm._v(" "),
                  _c("PrInput", {
                    attrs: { label: "対策詳細", "pr-row": [35] },
                    model: {
                      value: _vm.values["note4"],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "note4", $$v)
                      },
                      expression: "values['note4']",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.buttons_center },
            [
              _c(
                "Button",
                {
                  attrs: { color: "submit", size: "main" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSaveButtonClick.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("編集内容を保存する")]
              ),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "Button",
                    {
                      attrs: { color: "cancel", size: "assist" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.initValues.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("クリア")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c("DialogProvider", {
                    attrs: {
                      texts: {
                        ttl: "確認",
                        msg: `削除しますか？`,
                        yas: "削除する",
                        no: "キャンセル",
                      },
                    },
                    on: { accept: _vm.handleDeleteButtonClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ on }) {
                            return _c(
                              "Button",
                              _vm._g(
                                { attrs: { color: "alert", size: "assist" } },
                                on
                              ),
                              [_vm._v("削除")]
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2488080323
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }