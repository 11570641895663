import axios from "axios"
import store from "@/store"
import router from "@/router"
import * as types from "@/store/mutation-types"
import { getRootPath } from "@/router/guards"

export const paths = {
  SIGN_IN: "sign-in",
  SIGN_OUT: "sign-out",
  USER: "user",
  SEARCH_DEFINITIONS: "search-definitions",
  ADMIN_USERS: "admin/users",
  ADMIN_LOGICAL_DELETE: "admin/logical-delete",
  ADMIN_CUSTOMERS: "admin/customers",
  ADMIN_CUSTOMER_PERSONS: "admin/customer-persons",
  ADMIN_CUSTOMERS_AND_CUSTOMER_PERSONS:
    "admin/list-customers-and-customer-persons",
  ADMIN_SUPPLIERS: "admin/suppliers",
  ADMIN_DEALINGS: "admin/dealings",
  DEALINGS_MERGE: "admin/dealings-merge",
  ADMIN_DEALINGS_CONTRACTS: "admin/dealings-contracts",
  ADMIN_DEALINGS_CONTRACT: "admin/dealings-contract",
  ADMIN_DEALINGS_INSPECT: "admin/dealings-inspect",
  ADMIN_DEALINGS_INQUIRY: "admin/dealings-inquiry",
  ADMIN_DEALINGS_PROGRESS: "admin/dealings-progress",
  ADMIN_DEALINGS_CONTACT: "admin/dealings-contact",
  ADMIN_DEALINGS_DEFECTIVE: "admin/dealings-defective",
  ADMIN_DEALINGS_HEADER: "admin/dealings-header",
  ADMIN_DEALINGS_ETC: "admin/dealings-etc",
  ADMIN_DEALINGS_COPY: "admin/dealings-copy",
  ADMIN_CONTRACTS: "admin/contracts",
  ADMIN_OM_CONTRACTS: "admin/om-contracts",
  ADMIN_CONTRACTS_COPY: "admin/contracts-copy",
  ADMIN_INVOICE: "admin/invoice",
  ADMIN_TAXES: "admin/taxes",
  ADMIN_PURCHASE: "admin/purchase",
  ADMIN_PUBLISH_PURCHASE: "admin/publish-purchase",
  ADMIN_SEARCH_TAXES: "admin/search-taxes",
  ADMIN_CURRENT_TAXES: "admin/current-taxes",
  ADMIN_SEARCH_ITEMS: "admin/search-items",
  ADMIN_CURRENT_ITEMS: "admin/current-items",
  ADMIN_SEARCH_PRICES: "admin/search-prices",
  ADMIN_CURRENT_PRICES: "admin/current-prices",
  ADMIN_ITEMS: "admin/items",
  ADMIN_ITEM_CSV: "admin/download-items/csv",
  ADMIN_PRICES: "admin/prices",
  ADMIN_SEARCH_USERS: "admin/search-users",
  ADMIN_DEFECTIVES: "admin/defectives",
  ADMIN_ORDERED_CONSTRACTS: "admin/ordered-contracts",
  ADMIN_MAIL_TEMPLATE: "admin/mail-template",
  CUSTOMER_DEALINGS: "customer/dealings",
  REQUEST_RESET_PASSWORD: "request-password-reset",
  RESET_PASSWORD: "reset-my-password",
  CUSTOMER_USER: "customer/user",
  CUSTOMER_DRAFT_UPLOAD: "customer/draft-upload",
  CUSTOMER_VERIFICATION_IMAGE: "customer/verification-image",
  CUSTOMER_USER_BULK: "customer/user-bulk",
  CUSTOMER_ORDER: "customer/order",
  CUSTOMER_ORDERED_HISTORIES: "customer/ordered-histories",
  CUSTOMER_RE_ORDER: "customer/re-order",
  DEALINGS_CARDS: "admin/dealings-cards",
  SUPPLIER_DEALINGS: "supplier/dealings",
  SUPPLIER_GOODS_ISSUE: "supplier/goods-issue",
  SUPPLIER_USER: "supplier/user",
  SUPPLIER_DRAFT_CHECK: "supplier/draft-check",
  SUPPLIER_START_PRODUCT: "supplier/start-product",
  SUPPLIER_VERIFICATION_UPLOAD: "supplier/verification-upload",
  SUPPLIER_PRODUCT_UPLOAD: "supplier/product-upload/",
  SUPPLIER_PURCHASE: "supplier/purchase",
  UPLOAD_FILE: "upload_file",
  USER_REGISTRATION: "user-registration",
  ADMIN_MIGRATE_IMAGES: "admin/migrate/images",
  ADMIN_NOT_PRICE_ITEMS: "admin/not-price-items",
  ADMIN_PRODUCT_CATEGORY_GROUP: "admin/product-category-group",
  ADMIN_COPY_ITEM: "admin/copy-item",
  REPORT_QUOTATIONS: "admin/report/quotations",
  REPORT_ACHIEVEMENT: "admin/report/achievement",
}

const http = axios.create()

http.interceptors.response.use(
  function(response) {
    console.log(response)
    return response
  },
  function(error) {
    if (error.response.status === 401) {
      if (error.response.data.message === "invalid_token") {
        store.commit(`auth/${types.AUTH_LOGOUT}`, {
          token: null,
          userId: null,
          role: null,
        })
        store.commit(`messages/${types.SET_MESSAGE}`, {
          message: "トークンが有効期限切れです。ログインし直してください。",
        })
        router.replace({ path: "/login" })
      } else if (error.response.data.message === "no grant") {
        let path = getRootPath(store.state.auth.role)
        router.replace({ path: path })
      }
    }

    return Promise.reject(error)
  },
)

const success = res => ({ ok: true, body: res.data })

const failure = err => ({
  ok: false,
  body: err.message,
  errors: err.response.data.errors,
  errorMsg: err.response.data,
})

const post = (path, data, token) => {
  const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {}
  return http
    .post(`/api/${path}`, data, config)
    .then(success)
    .catch(failure)
}

const postUpload = (path, data, token) => {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
          ContentType: "multipart/form-data",
        },
      }
    : {}
  return http
    .post(`/api/${path}`, data, config)
    .then(success)
    .catch(failure)
}

const get = (path, token) => {
  return http
    .get(`/api/${path}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(success)
    .catch(failure)
}

const put = (path, data, token) => {
  return http
    .put(`/api/${path}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(success)
    .catch(failure)
}

const DELETE = (path, token) => {
  return http
    .delete(`/api/${path}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(success)
    .catch(failure)
}

const csvUpload = (data, token) => {
  return (
    http
      // eslint-disable-next-line no-undef
      .post(`${CSV_API_URL}/api/admin/prices/csv`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(success)
      .catch(failure)
  )
}

const csvDownload = token => {
  return (
    http
      // eslint-disable-next-line no-undef
      .get(`${CSV_API_URL}/api/admin/prices/csv`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(success)
      .catch(failure)
  )
}

export default {
  get,
  post,
  put,
  postUpload,
  DELETE,
  csv: { download: csvDownload, upload: csvUpload },
}
