import api, { paths } from "@/utils/api.js"

export const uploadFile = async (file, directoryId, token) => {
  let formData = new FormData()
  formData.append("file", file)
  formData.append("directory_id", directoryId)

  const response = await api.postUpload(paths.UPLOAD_FILE, formData, token)

  return response
}
