var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { class: _vm.$style.wrapper },
        [
          _c("PageTitle", [_vm._v("最終確認画像")]),
          _vm._v(" "),
          _c("CustomerUserName", {
            attrs: { "customer-person": _vm.items.customer_person },
          }),
          _vm._v(" "),
          _c("div", { class: _vm.$style.block }, [
            _c("div", { class: _vm.$style.block_container }, [
              _c(
                "div",
                { class: _vm.$style.checkImage },
                [
                  _c("p", { class: [_vm.$style.explain, _vm.$style.dark] }, [
                    _vm._v(
                      "\n          お客様からご注文いただいた商品の最終 確認画像になります。\n          問題がなければ「確認しました」ボタン を押してください。\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    class: _vm.$style.checkImage_img,
                    attrs: { src: _vm.info.appendix_name },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.button },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { color: "submit", size: "main" },
                          on: { click: _vm.handleConfirmed },
                        },
                        [_vm._v("確認しました")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("PrTextarea", {
                    attrs: {
                      label:
                        "修正のご依頼がある場合は、下記に記入 してください。",
                      name: "content",
                      validate: "required",
                      "pr-row": [100, 100],
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.button },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { color: "alert", size: "assist" },
                          on: { click: _vm.handleModify },
                        },
                        [_vm._v("修正を依頼する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }