<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>仕入先{{ isNew ? "新規作成" : "詳細" }}</PageTitle>

    <InfoBlock>
      <template slot="head"
        >仕入先概要</template
      >
      <InfoList v-model="values" :list="outlineList" />
    </InfoBlock>
    <RelatedInfo v-if="!isNew" :infoList="relatedInfo">関連情報</RelatedInfo>
    <div :class="$style.buttons_center">
      <Button color="submit" size="main" @click.native="handleSaveButtonClick"
        >編集内容を保存する</Button
      >
      <Button
        v-if="!isNew"
        color="cancel"
        size="assist"
        @click.native="initValues"
        >クリア</Button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import InfoList from "@/components/molecules/InfoList.vue"
import RelatedInfo from "@/components/molecules/RelatedInfo.vue"
import api, { paths } from "@/utils/api.js"
import moment from "moment"
import { showToast, showErrorToast, routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    Button,
    PageTitle,
    InfoBlock,
    InfoList,
    RelatedInfo,
  },

  data() {
    return {
      isReady: false,
      isNew: false,
      items: null,
      values: null,
    }
  },
  computed: {
    relatedInfo() {
      if (this.isNew) {
        return [["作成者", ""], ["作成日", ""], ["更新者", ""], ["更新日", ""]]
      } else {
        const body = this.items
        const [updatedAt, insertedAt] = [body.updated_at, body.inserted_at].map(
          v => moment(v, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD"),
        )
        return [
          ["作成者", body.inserted.name],
          ["作成日", insertedAt],
          ["更新者", body.updated.name],
          ["更新日", updatedAt],
        ]
      }
    },
    outlineList() {
      const name = {
        name: "仕入先名",
        key: "name",
        type: "text",
        validate: {
          rule: "required",
          message: {
            required: "必須項目です。",
          },
        },
      }
      const supplier = {
        name: "仕入先種別",
        key: "supplier_code",
        type: "select",
        options: this.$store.getters["defs/getOptions"]("SupplierCode"),
        defLang: null,
        validate: {
          rule: "required",
          message: {
            required: "必須項目です。",
          },
        },
      }
      const email = {
        name: "メールアドレス",
        key: "email",
        type: "text",
        validate: {
          rule: "required|email",
          message: {
            required: "必須項目です。",
            email: "メールアドレスを正しく入力してください。",
          },
        },
      }

      const password = {
        name: "パスワード",
        key: "password",
        type: "password",
        validate: {
          rule: "required|min:8|max:20|password",
          message: {
            required: "必須項目です。",
            min: "8文字以上20文字以下で入力してください。",
            max: "8文字以上20文字以下で入力してください。",
          },
        },
      }

      if (this.isNew) {
        return [name, supplier, email, password]
      } else {
        return [name, supplier, email]
      }
    },
  },
  methods: {
    async validate() {
      const result = await this.$validator.validateAll().then(result => {
        return result
      })

      console.log("_result=", result)
      return result
    },

    async handleSaveButtonClick() {
      let result = await this.validate()
      console.log("validate = ", result)
      if (!result) {
        return
      }

      let data = {
        name: this.values.name,
        email: this.values.email,
        supplier_code: this.values.supplier_code,
      }

      const loader = this.$loading.show()
      try {
        if (!this.isNew) {
          // 編集時
          data.lock_version = this.items.lock_version
          result = await api.put(
            `${paths.ADMIN_SUPPLIERS}/${this.$route.params.supplier_id}`,
            data,
            this.$store.state.auth.token,
          )
        } else {
          // 新規時
          data.password = this.values.password
          result = await api.post(
            paths.ADMIN_SUPPLIERS,
            data,
            this.$store.state.auth.token,
          )
        }

        if (!result.ok) {
          console.log(
            `ok = ${result.ok}, body = ${result.body}, errors = ${
              result.errors
            }`,
          )

          if (result.errors) {
            if (
              result.errors["email"] &&
              result.errors.email[0] === "has already been taken"
            ) {
              showToast(
                this,
                "error",
                `メールアドレス: ${this.values.email}は、登録済みです。`,
                3000,
                false,
              )
            } else if (
              result.errors["supplier_code"] &&
              result.errors.supplier_code[0] === "has already been taken"
            ) {
              const supplierCode = this.$store.getters[
                "defs/getOptionValueByKey"
              ]("SupplierCode", this.values.supplier_code)

              showToast(
                this,
                "error",
                `仕入先種別: ${supplierCode}は、登録済みです。`,
                3000,
                false,
              )
            }

            return
          }

          showErrorToast(this, result.body.slice(-3), result.errorMsg)
          return
        }

        this.$router.replace(
          this.$route.name === "adminDealingsSupplierDetail"
            ? `/admin/dealings/${this.$route.params.deal_id}`
            : "/admin/suppliers",
        )
      } finally {
        loader.hide()
      }
    },
    initValues() {
      this.$validator.reset()

      this.values = {
        name: this.items.user.name,
        email: this.items.user.email,
        supplier_code: this.items.supplier_code,
        password: "",
      }
    },
    initNew() {
      this.items = {
        user: {
          name: "",
          email: "",
        },
        supplier_code: "",
      }
      this.initValues()
    },
    async initEdit() {
      const { ok, body } = await api.get(
        `${paths.ADMIN_SUPPLIERS}/${this.$route.params.supplier_id}`,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
      }
      this.items = body
      this.initValues()
    },
  },
  async created() {
    if (this.$route.name === "adminSupplierNew") {
      this.isNew = true
      this.initNew()
    } else {
      await this.initEdit()
    }

    this.isReady = true
  },
}
</script>

<style lang="scss" module>
@include dbDetailView();
</style>
