import store from "@/store"
import axios from "axios"

const authorizeToken = async (to, from, next) => {
  // ログイン済みで、search-definitionsの情報が存在しない場合は、取得する

  axios
    .get(`/route-log${to.path}`)
    .then()
    .catch()

  // webpageのルートだった場合は何もしない
  if (to.matched.some(record => record.meta.isWebpage)) {
    next()
    return
  }

  // localStorageにtokenがあった場合はsearch-defenitionsを取得する
  if (store.state.auth.token) {
    if (store.state.defs.items.length === 0) {
      let result = await store.dispatch("defs/fetch")
      if (!result.ok) {
        console.log(`defs/fetch result = ${result.body}`)
      }
    }
  }

  if (to.name === "errorView") {
    next()
    return
  }

  if (to.matched.some(record => !record.meta.isPublic)) {
    if (!store.state.auth.token) {
      next({ path: "/login" })
    } else {
      store.dispatch("common/clearPasswordReminderEmail")
      if (to.meta.role.includes(store.state.auth.role)) {
        next()
      } else {
        const path = getRootPath(store.state.auth.role)
        next({ path: path })
      }
    }
  } else if (
    (to.path === "/" || to.path === "/login") &&
    store.state.auth.token
  ) {
    store.dispatch("common/clearPasswordReminderEmail")
    const path = getRootPath(store.state.auth.role)
    next({ path: path })
  } else {
    next()
  }
}

const getRootPath = role => {
  var path = {
    admin: "/admin",
    operator: "/admin",
    customer: "/customer",
    supplier: "/supplier",
  }

  if (role in path) {
    return path[role]
  }

  return "/"
}

export { authorizeToken, getRootPath }
