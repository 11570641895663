<template>
  <div v-if="values !== null" :class="$style.wrapper">
    <PageTitle>取引先責任者{{ isNew ? "新規作成" : "詳細" }}</PageTitle>

    <div :class="$style.inner" style="width:1000px">
      <InfoBlock width="49%" block>
        <template slot="head"
          >所属/氏名</template
        >
        <div :class="$style.forms">
          <PrSelect
            name="name"
            label="取引先名"
            :value="values.customer_id"
            @input="handleCustomerSelect"
            :options="customerOptions"
            validate="required"
            :searchable="true"
            :pr-row="[30]"
            linkPath="/admin/customers"
            linkName="確認"
          />
          <PrInput
            name="last_name"
            label="姓"
            v-model="values.last_name"
            validate="required"
            :pr-row="[30]"
          />
          <PrInput
            name="first_name"
            label="名"
            v-model="values.first_name"
            :pr-row="[30]"
          />
          <PrInput
            name="email"
            label="メールアドレス"
            v-model="values.email"
            validate="required|email"
            :pr-row="[30]"
          />
          <PrCheckbox
            v-if="!isNew"
            before-label="ユーザー本登録"
            :value="values.user_status"
            :pr-row="[30]"
            disabled
          />
          <PrInput
            name="department"
            label="部署"
            v-model="values.department"
            :pr-row="[30]"
          />
          <PrInput
            name="title"
            label="役職"
            v-model="values.title"
            :pr-row="[30]"
          />
          <PrInput
            name="sales_office"
            label="営業所"
            v-model="values.sales_office"
            :pr-row="[30]"
          />
          <PrInput
            name="phone"
            label="電話番号"
            v-model="values.phone"
            validate="telephone"
            :pr-row="[30]"
          />
          <PrInput
            name="fax"
            label="FAX"
            v-model="values.fax"
            validate="fax"
            :pr-row="[30]"
          />
          <PrInput
            name="mobile_phone"
            label="携帯電話"
            v-model="values.mobile_phone"
            validate="mobilephone"
            :pr-row="[30]"
          />
          <PrCheckbox
            before-label="退職"
            v-model="values.retirement"
            :pr-row="[30]"
          />
        </div>
      </InfoBlock>

      <div :class="$style.inner_left" style="width:49%">
        <InfoBlock width="100%" block margin="0 0 20px 0">
          <template slot="head"
            >住所</template
          >
          <div :class="$style.forms">
            <PrInput
              name="zip_code"
              label="郵便番号"
              v-model="values.zip_code"
              validate="zipcode"
              :pr-row="[30, 50]"
            >
              <ButtonSerchZipCode
                slot="after"
                :zipcode="values.zip_code"
                @click="handleZipSearch"
              />
            </PrInput>
            <PrInput
              label="都道府県"
              v-model="values.location"
              :pr-row="[30]"
            />
            <PrInput label="市区郡" v-model="values.address1" :pr-row="[30]" />
            <PrInput
              label="町名・番地"
              v-model="values.address2"
              :pr-row="[30]"
            />
            <PrInput label="建物名" v-model="values.building" :pr-row="[30]" />
          </div>

          <div>
            <p
              :class="$style.address_radio_wrapper"
              v-if="selectedCustomerAddress"
            >
              <PrInputRadio
                v-for="[label, value] in [
                  ['「取引先」住所に変更', '0'],
                  ['「取引先責任者」住所に変更(「取引先」住所も上書き)', '1'],
                  ['上書きしない', '2'],
                ]"
                :label="label"
                :value="value"
                :key="value"
                name="addressOverwriteOption"
                v-model="addressOverwriteOption"
                @change="handleAddressOverwriteOption"
              />
            </p>
          </div>
        </InfoBlock>

        <InfoBlock width="100%" block>
          <template slot="head"
            >その他</template
          >
          <div :class="$style.forms">
            <PrCheckbox
              before-label="メルマガ会員"
              v-model="values.mail_magazine_members"
              :pr-row="[30]"
            />
            <PrCheckbox
              before-label="メール配信"
              v-model="values.mail_delivery"
              :pr-row="[30]"
            />
            <PrCheckbox
              before-label="DM拒否"
              v-model="values.reject_direct_mail"
              :pr-row="[30]"
            />
            <PrCheckbox
              before-label="広告代理店"
              v-model="values.advertising_agency"
              :pr-row="[30]"
            />
            <PrInput
              name="expo"
              label="エキスポ情報"
              v-model="values.expo"
              :pr-row="[30]"
            />
            <PrInput
              name="description"
              label="説明"
              v-model="values.description"
              :pr-row="[30]"
            />
          </div>
        </InfoBlock>
      </div>
    </div>

    <RelatedInfo v-if="!isNew" :infoList="relaedInfo">関連情報</RelatedInfo>

    <InfoBlock v-if="!isNew" style="margin-top:20px;">
      <template slot="head"
        >取引先責任者更新履歴</template
      >
      <InnerTable
        :head="histories.head"
        :body="histories.body"
        :link-contain="true"
      />
      <p v-if="histories.body.length === 0" :class="$style.noParson">
        履歴がありません
      </p>
    </InfoBlock>

    <InfoBlock v-if="!isNew" style="margin-top:20px;">
      <template slot="head"
        >商談</template
      >
      <InnerTable
        :head="dealings.head"
        :body="dealings.body"
        :link-contain="true"
      />
      <p v-if="dealings.body.length === 0" :class="$style.noParson">
        商談実績はありません
      </p>
    </InfoBlock>

    <div :class="$style.buttons_center">
      <Button color="submit" size="main" @click.native="handleSaveButtonClick"
        >編集内容を保存する</Button
      >
      <Button
        v-if="!isNew"
        color="cancel"
        size="assist"
        @click.native="initValues"
        >クリア</Button
      >
      <DialogProvider
        v-if="!isNew"
        :texts="{
          ttl: '確認',
          msg: `本当に${items.last_name}${items.first_name}を削除しますか？`,
          yas: 'はい',
          no: 'いいえ',
        }"
        @accept="handleDelete"
      >
        <Button slot-scope="{ on }" color="alert" size="assist" v-on="on"
          >削除</Button
        >
      </DialogProvider>
    </div>
  </div>
</template>

<script>
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import PrInputRadio from "@/components/atoms/PrInputRadio.vue"
import Button from "@/components/atoms/Button.vue"
import ButtonSerchZipCode from "@/components/molecules/ButtonSerchZipCode.vue"
import PageTitle from "@/components/atoms/PageTitle.vue"
import RelatedInfo from "@/components/molecules/RelatedInfo.vue"
import InfoBlock from "@/components/molecules/InfoBlock.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import InnerTable from "@/components/molecules/InnerTable.vue"

import api, { paths } from "@/utils/api.js"
import {
  showToast,
  showErrorToast,
  routeReplaceError,
  getDate,
  getDateTime,
} from "@/utils/shared.js"

export default {
  components: {
    InnerTable,
    DialogProvider,
    PrSelect,
    PrCheckbox,
    PrInput,
    PrInputRadio,
    ButtonSerchZipCode,
    Button,
    PageTitle,
    RelatedInfo,
    InfoBlock,
  },
  data() {
    return {
      items: null,
      customerItems: null,
      values: null,
      isZipCodeElmMounted: false,
      selectedCustomerAddress: null,
      addressOverwriteOption: "2",
      tempPersonAddress: null,
    }
  },
  computed: {
    isNew() {
      return this.$route.name === "adminCustomerPersonNew"
    },
    customerOptions() {
      return this.customerItems.map(({ id, organization }) => ({
        key: id,
        value: organization.name,
      }))
    },
    dealings() {
      return {
        head: [
          "商談名",
          "商談管理No",
          "進捗状況",
          "注文日",
          "見積総額",
          "注文総額(税込)",
        ],
        body: this.items.dealing_histories.map(
          ({
            request_name,
            request_number,
            contracted_date,
            status,
            estimated_total_amount,
            total_order_amount,
          }) => [
            `|>/admin/dealings/${request_number}|>${request_name}`,
            request_number,
            this.$store.getters["defs/getOptionValueByKey"]("Progress", status),
            getDate(contracted_date, "未注文"),
            estimated_total_amount || "未注文",
            total_order_amount || "未注文",
          ],
        ),
      }
    },
    relaedInfo() {
      const { inserted_at, updated_at, inserted, updated } = this.items
      return [
        ["作成者", inserted.name],
        ["作成日", getDate(inserted_at)],
        ["更新者", updated.name],
        ["更新日", getDate(updated_at)],
      ]
    },
    histories() {
      return {
        head: [
          "メールアドレス",
          "電話番号",
          "郵便番号",
          "都道府県",
          "市区郡",
          "町名・番地",
          "建物名",
          "更新日時",
        ],
        body: this.items.customer_person_histories.map(history => [
          history.email,
          history.phone,
          history.zip_code,
          history.location,
          history.address1,
          history.address2,
          history.building,
          getDateTime(history.updated_at),
        ]),
      }
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const result = await this.$validator.validateAll().then(result => result)
      if (!result) {
        return
      }

      const { isNew, values } = this
      const method = isNew ? "post" : "put"
      const path = isNew
        ? paths.ADMIN_CUSTOMER_PERSONS
        : `${paths.ADMIN_CUSTOMER_PERSONS}/${
            this.$route.params.customer_person_id
          }`

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api[method](
          path,
          { ...values },
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        if (this.isNew) {
          this.$router.replace(`/admin/customer-persons/${body.id}`)
          this.items = body
          this.init()
        } else if (this.$route.name === "adminDealingsCustomerPersonDetail") {
          this.$router.push(`/admin/dealings/${this.$route.params.deal_id}`)
        } else {
          this.items = body
          this.init()
        }
      } finally {
        loader.hide()
      }
    },
    async handleDelete() {
      const { ok, body, errorMsg } = await api.DELETE(
        paths.ADMIN_CUSTOMER_PERSONS + "/" + this.items.id,
        this.$store.state.auth.token,
      )
      if (!ok) {
        showErrorToast(this, body.slice(-3), errorMsg, "削除に失敗しました")
        return
      }

      showToast(
        this,
        "success",
        `「${this.items.last_name}${this.items.first_name}」を削除しました。`,
        3000,
      )

      this.$router.replace(
        this.$route.name === "adminDealingsCustomerPersonDetail"
          ? `/admin/dealings/${this.$route.params.deal_id}`
          : `/admin/customer-persons`,
      )
    },
    handleZipSearch({ ok, address }) {
      if (!ok) {
        return
      }
      this.values = { ...this.values, ...address }
    },
    handleCustomerSelect(e) {
      const {
        building,
        organization: {
          addresses: [{ address1, address2, location, zip_code }],
        },
        phone_number,
      } = this.customerItems.find(({ id }) => String(id) === e)

      // 住所チェック
      const addressKeys = [
        "address1",
        "address2",
        "location",
        "zip_code",
        "building",
        "phone_number",
      ]

      // 住所と電話番号が未登録の場合は取引先の情報で上書き
      if (addressKeys.every(key => this.values[key]) == null) {
        this.values = {
          ...this.values,
          address1,
          address2,
          location,
          zip_code,
          building,
          phone: phone_number,
          customer_id: e,
        }
      } else {
        this.selectedCustomerAddress = {
          address1,
          address2,
          location,
          zip_code,
          building,
        }

        this.values = {
          ...this.values,
          customer_id: e,
        }
      }
    },
    initValues() {
      const { isNew, items } = this
      this.values = {
        last_name: isNew ? "" : items.last_name,
        first_name: isNew ? "" : items.first_name,
        department: isNew ? "" : items.department,
        title: isNew ? "" : items.title,
        sales_office: isNew ? "" : items.sales_office,
        phone: isNew ? "" : items.phone,
        fax: isNew ? "" : items.fax,
        mobile_phone: isNew ? "" : items.mobile_phone,
        email: isNew ? "" : items.user ? items.user.email : "",
        zip_code: isNew ? "" : items.address.zip_code,
        location: isNew ? "" : items.address.location,
        address1: isNew ? "" : items.address.address1,
        address2: isNew ? "" : items.address.address2,
        building: isNew ? "" : items.building,
        mail_magazine_members: isNew ? "0" : items.mail_magazine_members,
        mail_delivery: isNew ? "0" : items.mail_delivery,
        reject_direct_mail: isNew ? "0" : items.reject_direct_mail,
        advertising_agency: isNew ? "0" : items.advertising_agency,
        expo: isNew ? "" : items.expo,
        description: isNew ? "" : items.description,
        user_status: isNew ? "" : items.user ? items.user.status : false,
        retirement: isNew ? "0" : items.retirement,
        customer_id: isNew ? "" : items.customer.id,
      }
      this.$validator.reset()
    },
    async init() {
      const { isNew } = this

      const [
        { ok: pOk, body: pBody },
        { ok: tOk, body: tBody },
      ] = await Promise.all([
        api.get(paths.ADMIN_CUSTOMERS, this.$store.state.auth.token),
        isNew
          ? Promise.resolve({ ok: true, body: null })
          : api.get(
              `${paths.ADMIN_CUSTOMER_PERSONS}/${
                this.$route.params.customer_person_id
              }`,
              this.$store.state.auth.token,
            ),
      ])
      if (!pOk || !tOk) {
        for (const body of [pBody, tBody]) {
          if (typeof body === "string") {
            routeReplaceError(this, body.slice(-3))
            return
          }
        }
      }
      this.customerItems = pBody
      this.items = tBody
      this.initValues()
    },
    handleAddressOverwriteOption(value) {
      if (Number(value) === 0) {
        this.tempPersonAddress = {
          address1: this.values.address1,
          address2: this.values.address2,
          location: this.values.location,
          building: this.values.building,
          zip_code: this.values.zip_code,
        }

        this.values = {
          ...this.values,
          ...this.selectedCustomerAddress,
          addresss_overwrite_option: value,
        }
      } else {
        this.values = {
          ...this.values,
          ...this.tempPersonAddress,
          addresss_overwrite_option: value,
        }
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" module>
@include dbDetailView();
.inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.forms {
  > * {
    width: 100%;
    margin-bottom: 15px;
  }
}
.address_radio_wrapper {
  display: flex;
  flex-direction: column;
}
</style>
