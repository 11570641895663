<template>
  <div :class="$style.contactBlock">
    <h2 :class="$style.contactBlock_ttl">
      お客様コンタクト情報
    </h2>
    <div :class="$style.contactBlock_table">
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">見積確認</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <PrSelect
              :value="items.quantity2"
              :options="$store.getters['defs/getOptions']('CheckEstimates')"
              @input="handleContruntStatusChange"
            />
          </div>
          <ModalMailTemplate
            mail-type="estimates"
            :replace-values="mailConvertCustomerInfo"
            style="display: none;"
          />
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">注文</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <span :class="$style.contactBlock_text">{{
              items.order_status === 0 ? "未確定" : "確定済"
            }}</span>
          </div>
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">入稿データ</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <span>
              {{
                $store.getters["defs/getOptionValueByKey"](
                  "DraftStatus_PR",
                  draft.request_key3,
                )
              }}
            </span>
            <p v-if="Number(draft.request_key4) > 1" style="margin-left:20px;">
              <a v-if="draft.appendix_name" :href="draft.appendix_name"
                >ダウンロード</a
              >
              <span v-else>入稿済み</span>
            </p>
          </div>
          <template v-if="Number(draft.request_key3) === 2">
            <DialogProvider
              :texts="{
                ttl: '確認',
                msg: '入稿データをOKにしますか',
                yas: 'はい',
                no: 'いいえ',
              }"
              @accept="handleOKNGclick(true)"
            >
              <span
                slot-scope="{ on }"
                :class="[$style.contactBlock_minButton, $style.ok]"
                v-on="on"
                >OK</span
              >
            </DialogProvider>
            <DialogProvider
              :texts="{
                ttl: '確認',
                msg: '入稿データをNGにしますか',
                yas: 'はい',
                no: 'いいえ',
              }"
              @accept="handleOKNGclick(false)"
            >
              <span
                slot-scope="{ on }"
                :class="[$style.contactBlock_minButton, $style.ng]"
                v-on="on"
                >NG</span
              >
            </DialogProvider>
          </template>
          <div
            v-if="
              Number(draft.request_key3) > 0 && Number(draft.request_key3) < 4
            "
            :class="$style.superZeroButton"
          >
            <ModalToggler :closeButton="true">
              <v-btn slot="button" flat color="primary">アップロード</v-btn>
              <div :class="$style.draft" slot-scope="{ toggle }">
                <p>入稿データアップロード</p>
                <InputFile
                  @change="file = $event"
                  :accept-type="['zip', 'ai']"
                />
                <Button
                  size="main"
                  color="submit"
                  @click="handleDraftUpdate(toggle)"
                  >保存</Button
                >
              </div>
            </ModalToggler>
            <DialogProvider
              v-if="draft.request_key3 === '1'"
              :texts="{
                ttl: '確認',
                msg: '入稿したことにしますか？',
                yas: 'はい',
                no: 'いいえ',
              }"
              @accept="handleOKNGclick(true, true)"
            >
              <v-btn flat color="primary" slot-scope="{ on }" v-on="on"
                >入稿済み</v-btn
              >
            </DialogProvider>
          </div>
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">最終確認画像</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            {{
              $store.getters["defs/getOptionValueByKey"](
                "VerificationStatus_PR",
                verification_image.request_key3,
              )
            }}
          </div>
          <div
            v-if="
              Number(verification_image.request_key3) > 0 &&
                Number(items.quantity4) === 0
            "
            :class="$style.superZeroButton"
          >
            <DialogProvider
              :texts="{
                ttl: '確認',
                msg: '入稿前に戻ります｡よろしいですか？',
                yas: 'はい',
                no: 'いいえ',
              }"
              @accept="handleForceResetClick()"
            >
              <v-btn flat color="primary" slot-scope="{ on }" v-on="on"
                >リセット</v-btn
              >
            </DialogProvider>
            <DialogProvider
              v-if="verification_image.request_key4 === '1'"
              :texts="{
                ttl: '確認',
                msg: 'お客様確認したことにしますか？',
                yas: 'はい',
                no: 'いいえ',
              }"
              @accept="handleForceVerificationOKClick()"
            >
              <v-btn flat color="primary" slot-scope="{ on }" v-on="on"
                >強制OK</v-btn
              >
            </DialogProvider>
          </div>
          <ModalMailTemplate
            mail-type="verification_image"
            :replace-values="mailConvertCustomerInfo"
            style="display: none;"
          />
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">請求書</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <div
              v-if="items.order_status === 0"
              :class="$style.contactBlock_firstRow"
            >
              注文未確定
            </div>
            <div v-else>
              <div :class="[$style.contactBlock_firstRow, $style.gap10]">
                <template v-for="status in invoiceStatus">
                  <DialogProvider
                    :key="status.value"
                    :texts="{
                      ttl: '確認',
                      msg: hasInvoiceStatus(items.quantity5, status.value)
                        ? `${status.label}のチェックをはずしますか？`
                        : `${status.label}にしますか?`,
                      yas: 'はい',
                      no: 'いいえ',
                    }"
                    @accept="
                      handleSendInvoiceStatusChange(
                        hasInvoiceStatus(items.quantity5, status.value)
                          ? -1 * status.value
                          : status.value,
                      )
                    "
                  >
                    <span
                      slot-scope="{ on }"
                      @click.capture.stop="on.click"
                      style="cursor: pointer;"
                      ><PrCheckbox
                        :before-label="status.label"
                        :value="hasInvoiceStatus(items.quantity5, status.value)"
                        style="pointer-events: none;"
                    /></span>
                  </DialogProvider>
                </template>
              </div>
              <Button
                size="assist"
                color="submit"
                @click="handlePrint('print-invoice')"
                style="margin-left: 10px;"
                >請求書印刷</Button
              >
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">本製作開始</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <div :class="$style.contactBlock_text">
              {{ items.quantity4 === 0 ? "未対応" : "本製作開始済" }}
            </div>
          </div>
          <ModalMailTemplate
            mail-type="product_start"
            :replace-values="mailConvertProductStartInfo"
            style="display: none;"
          />
        </div>
      </div>
      <div :class="$style.contactBlock_row">
        <p :class="$style.contactBlock_name">発送</p>
        <div :class="$style.contactBlock_content">
          <div :class="$style.contactBlock_firstRow">
            <PrSelect
              :value="items.quantity3"
              :options="$store.getters['defs/getOptions']('SendStatus')"
              @input="handleSendStatusChange"
            />
          </div>
          <Button
            size="assist"
            color="submit"
            @click="handlePrint('print-delivery')"
            >納品書印刷</Button
          >
          <Button
            size="assist"
            color="submit"
            @click="handlePrint('print-receipt')"
            >領収書印刷</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMailTemplate from "@/components/molecules/ModalMailTemplate.vue"
import ModalToggler from "@/components/molecules/ModalToggler.vue"
import DialogProvider from "@/components/molecules/DialogProvider.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import InputFile from "@/components/atoms/InputFile.vue"
import Button from "@/components/atoms/Button.vue"
import PrCheckbox from "@/components/molecules/PrCheckbox.vue"
import api, { paths } from "@/utils/api.js"
import { uploadFile } from "@/utils/fileUpload.js"
import { showErrorToast, openNewWindow, deepCopy } from "@/utils/shared.js"
import moment from "moment"

const invoiceStatus = [
  {
    label: "送付済",
    value: 1,
  },
  {
    label: "納品時",
    value: 10,
  },
  {
    label: "月末締め",
    value: 100,
  },
]

export default {
  components: {
    ModalMailTemplate,
    InputFile,
    PrSelect,
    Button,
    DialogProvider,
    ModalToggler,
    PrCheckbox,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      invoiceStatus,
    }
  },
  computed: {
    mailConvertCustomerInfo() {
      if (this.items.customer == null) {
        if (this.items.customer_person == null) {
          return { last_name: "", first_name: "", name: "" }
        } else {
          const { last_name, first_name } = this.items.customer_person
          return { last_name: last_name, first_name: first_name, name: "" }
        }
      } else {
        const { name } = this.items.customer.organization
        if (this.items.customer_person == null) {
          return { last_name: "", first_name: "", name: name }
        } else {
          const { last_name, first_name } = this.items.customer_person
          return { last_name: last_name, first_name: first_name, name: name }
        }
      }
    },
    mailConvertProductStartInfo() {
      return {
        ...this.mailConvertCustomerInfo,
        person_charge: this.items.user == null ? "" : this.items.user.name,
        shipping_date:
          this.items.delivery.request_date2 == null
            ? ""
            : moment(
                this.items.delivery.request_date2,
                "YYYY-MM-DDTHH:mm:ssZ",
              ).format("YYYY/MM/DD"),
      }
    },
    draft() {
      return this.items.request_appendices.find(
        ({ appendix_category }) => appendix_category === "draft",
      )
    },
    verification_image() {
      return this.items.request_appendices.find(
        ({ appendix_category }) => appendix_category === "verification_image",
      )
    },
    values() {
      const {
        request_key1,
        request_number,
        lock_version,
        request_appendices,
      } = this.items
      return {
        request_key1,
        request_number,
        lock_version,
        request_appendices: request_appendices.map(
          ({ id, request_number, lock_version, appendix_category }) => ({
            id,
            request_number,
            lock_version,
            appendix_category,
          }),
        ),
      }
    },
  },
  methods: {
    async putApi(putValues) {
      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.ADMIN_DEALINGS_CONTACT}/${putValues.request_number}`,
          putValues,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        this.$emit("updated", body)
      } finally {
        loader.hide()
      }
    },
    async handleDraftUpdate(toggle) {
      const putValues = deepCopy(this.values)
      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await uploadFile(
          this.file,
          this.$store.state.auth.userId,
          this.$store.state.auth.token,
        )
        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }
        putValues.request_appendices = putValues.request_appendices.map(ra =>
          ra.appendix_category !== "draft"
            ? ra
            : {
                ...ra,
                request_key4: "2",
                request_key3: "3",
                appendix_status: "1",
                appendix_name: body.path,
              },
        )
      } finally {
        loader.hide()
      }
      await this.putApi({
        ...putValues,
        description: `入稿データをアップロードしました。`,
      })
      toggle()
    },
    handlePrint(kind) {
      openNewWindow(`/admin/dealings/${this.items.request_number}/${kind}`)
    },
    handleContruntStatusChange(quantity2) {
      const putValues = deepCopy(this.values)
      const statusText = this.$store.getters["defs/getOptionValueByKey"](
        "CheckEstimates",
        quantity2,
      )
      this.putApi({
        ...putValues,
        quantity2,
        description: `見積確認が[${statusText}]に更新されました。`,
      })
    },
    handleSendStatusChange(quantity3) {
      const putValues = deepCopy(this.values)
      const statusText = this.$store.getters["defs/getOptionValueByKey"](
        "SendStatus",
        quantity3,
      )
      this.putApi({
        ...putValues,
        quantity3,
        description: `発送ステータスが[${statusText}]に更新されました。`,
      })
    },
    handleOKNGclick(isOk, killAppndexName = false) {
      const putValues = deepCopy(this.values)
      putValues.request_appendices = putValues.request_appendices.map(ra => {
        if (ra.appendix_category === "draft") {
          return {
            ...ra,
            request_key4: isOk ? "2" : "1",
            request_key3: isOk ? "3" : "1",
            appendix_status: isOk ? "1" : "0",
            appendix_name: killAppndexName ? "" : ra.appendix_name,
          }
        }
        return ra
      })
      putValues.description = `入稿データに${
        isOk ? "[OK]" : "[NG]"
      }を出しました。`
      this.putApi(putValues)
    },
    handleForceVerificationOKClick() {
      const putValues = deepCopy(this.values)
      putValues.request_appendices = putValues.request_appendices.map(ra => {
        if (ra.appendix_category === "verification_image") {
          return {
            ...ra,
            request_key4: "2",
            request_key3: "4",
            appendix_status: "2",
          }
        }
        return ra
      })
      putValues.description = "最終確認画像に[強制OK]を出しました。"
      this.putApi(putValues)
    },
    handleForceResetClick() {
      const putValues = deepCopy(this.values)
      putValues.request_appendices = putValues.request_appendices.map(ra => {
        if (ra.appendix_category === "verification_image") {
          return {
            ...ra,
            request_key4: "0",
            request_key3: "0",
            appendix_status: "0",
          }
        } else if (ra.appendix_category === "draft") {
          return {
            ...ra,
            request_key4: "1",
            request_key3: "1",
            appendix_status: "0",
          }
        }
        return ra
      })
      putValues.description = "最終確認画像の[リセット]を行いました。"
      this.putApi(putValues)
    },
    handleSendInvoiceStatusChange(quantity5) {
      const putValues = deepCopy(this.values)
      const currentStatus = this.items.quantity5

      const updatedStatus =
        quantity5 > 0
          ? currentStatus | quantity5
          : currentStatus & ~(-1 * quantity5)

      const label = this.invoiceStatus
        .filter(item => this.hasInvoiceStatus(updatedStatus, item.value))
        .map(item => item.label)
        .join("、")

      this.putApi({
        ...putValues,
        quantity5: updatedStatus,
        description: `請求書が[${label}]に更新されました。`,
      })
    },
    hasInvoiceStatus(quantity5, status) {
      console.log("status", quantity5, status)
      // quantity5 は請求書のステータス
      // 2進数で管理している
      // 1桁目: 送付済み
      // 2桁目: 納品時
      // 3桁目: 月末締め
      return (quantity5 & status) === status
    },
  },
}
</script>

<style lang="scss" module>
@include contactBlock();
.draft {
  width: 500px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: map-get($colors, white);
  > p {
    width: 100%;
    font-size: 0.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  > label {
    width: 80%;
  }
}
.superZeroButton {
  width: 100%;
  display: flex;
}
.gap10 {
  gap: 10px;
}
</style>
