<template>
  <div v-if="isReady" :class="styles.formAria" style="width:800px;">
    <PrInput
      label="表示名(名前)"
      v-model="name"
      name="name"
      :validate="'required'"
      :pr-row="[100, 100]"
    />
    <PrInput
      label="メールアドレス"
      name="email"
      v-model="email"
      :validate="'required|email'"
      :pr-row="[100, 100]"
    />
    <PrSelect
      label="権限"
      name="role"
      v-model="role"
      :options="roleOptions"
      :validate="'required'"
      :pr-row="[100, 100]"
      :autoWidth="false"
    />
    <PrInput
      label="パスワード"
      type="password"
      name="password"
      v-model="password"
      :validate="'required|min:8|max:20|password'"
      :pr-row="[100, 100]"
    />
    <Button color="submit" size="main" @click="handleRegist">登録する</Button>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import PrInput from "@/components/molecules/PrInput.vue"
import PrSelect from "@/components/molecules/PrSelect.vue"
import api, { paths } from "@/utils/api.js"
import { showToast, showErrorToast, routeReplaceError } from "@/utils/shared.js"

export default {
  props: {
    styles: {
      type: Object,
    },
  },
  components: {
    Button,
    PrInput,
    PrSelect,
  },
  data() {
    return {
      isReady: false,
      id: null,
      items: null,
      isNew: false,
      name: null,
      email: null,
      role: null,
      password: null,
    }
  },
  computed: {
    roleOptions() {
      const options = this.$store.getters["defs/getOptions"]("Role").filter(
        v => {
          if (v.key === "admin" || v.key === "operator") {
            return true
          }
        },
      )
      return options
    },
  },
  methods: {
    validate() {
      return this.$validator.validateAll().then(result => {
        return result
      })
    },
    async handleRegist() {
      let result = await this.validate()
      if (!result) {
        return
      }

      let data = {
        name: this.name,
        email: this.email,
        role: this.role,
        password: this.password,
      }

      const loader = this.$loading.show()
      try {
        if (this.isNew) {
          result = await api.post(
            paths.ADMIN_USERS,
            data,
            this.$store.state.auth.token,
          )
        } else {
          result = await api.put(
            `${paths.ADMIN_USERS}/${this.id}`,
            data,
            this.$store.state.auth.token,
          )
        }

        if (!result.ok) {
          console.log(`ADMIN_USERS ${result.body}`)
          if (
            result.errors &&
            result.errors.email[0] === "has already been taken"
          ) {
            showToast(
              this,
              "error",
              `${this.email}は、登録できません。`,
              3000,
              false,
            )
            return
          }

          showErrorToast(this, result.body.slice(-3), result.errorMsg)
          return
        }

        this.$router.push({ path: "/admin/users" })
      } finally {
        loader.hide()
      }
    },
  },
  async created() {
    if (this.$route.name === "adminUserNew") {
      this.isNew = true
    } else {
      this.id = this.$route.params.user_id
      const { ok, body } = await api.get(
        `${paths.ADMIN_USERS}/${this.id}`,
        this.$store.state.auth.token,
      )
      if (!ok) {
        routeReplaceError(this, body.slice(-3))
        return
      }
      if (body.role !== "admin" && body.role !== "operator") {
        console.log("hoge")
        routeReplaceError(this, "404")
        return
      }

      this.items = body
      this.name = body.name
      this.email = body.email
      this.role = body.role
    }
    this.isReady = true
  },
}
</script>

<style lang="scss" module>
.error {
  color: red;
  text-align: right;
}
</style>
