<template>
  <v-app :class="$style.app">
    <Header id="header" :toggleSlideMenuShow="toggleSlideMenuShow" />
    <router-view />
    <SlideMenu
      v-if="!$route.meta.isWebpage"
      :isShow="isSlideMenuShow"
      :toggleSlideMenuShow="toggleSlideMenuShow"
    />
    <transition name="slide">
      <div
        v-show="isSlideMenuShow"
        @click="toggleSlideMenuShow"
        :class="$style.overray"
      />
    </transition>
  </v-app>
</template>

<script>
import Header from "@/components/organisms/Header.vue"
import SlideMenu from "@/components/organisms/SlideMenu.vue"

export default {
  data() {
    return {
      isSlideMenuShow: false,
    }
  },
  components: {
    Header,
    SlideMenu,
  },
  methods: {
    toggleSlideMenuShow() {
      this.isSlideMenuShow = !this.isSlideMenuShow
    },
  },
}
</script>

<style lang="scss" module>
.app {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.overray {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
