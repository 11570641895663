<template>
  <div :class="$style.sheet">
    <p :class="$style.right">
      <input :class="$style.input" v-model="documentDate" />
    </p>
    <p>
      <input
        :class="$style.nameInput"
        v-model="customerName"
        v-bind:style="customerNameWidth"
      />
      <span :class="$style.customer" ref="customerName">{{
        customerName
      }}</span>
    </p>
    <p>
      <input
        :class="$style.nameInput"
        v-model="personName"
        v-bind:style="personNameWidth"
      />
      様
      <span :class="$style.person" ref="personName">{{ personName }}</span>
    </p>
    <p :class="$style.right">株式会社ＰＲ３９</p>
    <br />
    <p :class="$style.center">商品サンプル送付のご案内</p>
    <br />
    <p>
      <span :class="$style.tab1">拝啓</span>
      <input
        :class="$style.nameInput"
        v-model="kisya"
        v-bind:style="kisyaWidth"
      />
      <span :class="$style.kisya" ref="kisya">{{ kisya }}</span>
      ますますご清祥のこととお慶び申し上げます。平素は格別のご高配を賜り、厚くお礼申し上げます。
    </p>
    <p :class="$style.indent">
      さて、この度は下記弊社商品のサンプル送付をご依願いただきまして、誠にありがとうございます。商品に関してご不明な点等ございましたら、下記連絡先までお問い合わせいただければ、弊社社員より詳細にご説明させていただきます。
    </p>
    <br />
    <p :class="$style.indent">以上、取り急ぎ、ご案内まで。</p>
    <p :class="$style.right">敬具</p>
    <p :class="$style.center">記</p>
    <br />
    <div :class="$style.tab3">
      <div :class="$style.content">
        <p :class="$style.tab2">商品サンプル</p>
        <p :class="$style.preWrap">{{ items.inquiry.note4 }}</p>
      </div>
      <br />
      <div :class="$style.content">
        <p :class="$style.tab1">連絡先</p>
        <div :class="$style.contact">
          <p>電話番号：092-409-6039</p>
          <p>ファックス番号：092-409-6097</p>
          <p>Ｅメールアドレス：info@pr39.com</p>
          <p>ホームページアドレス：www.pr39.com</p>
        </div>
      </div>
    </div>
    <br />
    <p :class="$style.right">以上</p>
  </div>
</template>

<script>
import { routeReplaceError } from "@/utils/shared.js"
import api, { paths } from "@/utils/api.js"
import moment from "moment"

export default {
  data() {
    return {
      items: null,
      documentDate: null,
      customerNameWidth: "",
      personNameWidth: "",
      customerName: "",
      personName: "",
      kisya: "",
      kisyaWidth: "",
    }
  },
  async mounted() {
    const { ok, body } = await api.get(
      paths.ADMIN_DEALINGS + "/" + this.$route.params.deal_id,
      this.$store.state.auth.token,
    )
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }
    this.items = body
    this.documentDate = moment().format("YYYY年MM月DD日")
    this.customerName = body.customer ? body.customer.organization.name : ""
    this.personName = body.customer_person
      ? body.customer_person.last_name + " " + body.customer_person.first_name
      : ""
    this.kisya = "貴社"
    await this.$nextTick()
    //window.print()
    //window.close()
  },
  watch: {
    customerName() {
      this.calcWidth(this.updateCustomerNameWidthStyle)
    },
    personName() {
      this.calcWidth(this.updatePersonNameWidthStyle)
    },
    kisya() {
      this.calcWidth(this.updateKisyaWidthStyle)
    },
  },
  methods: {
    updateCustomerNameWidthStyle() {
      this.customerNameWidth = `width: ${this.$refs.customerName.clientWidth +
        10}px;`
    },
    updatePersonNameWidthStyle() {
      this.personNameWidth = `width: ${this.$refs.personName.clientWidth +
        10}px;`
    },
    updateKisyaWidthStyle() {
      console.log(this.$refs.kisya.clientWidth)
      this.kisyaWidth = `width: ${this.$refs.kisya.clientWidth}px;`
    },
    calcWidth(targetMethod) {
      this.$nextTick(() => targetMethod())
    },
  },
}
</script>

<style lang="scss" module>
.sheet {
  padding: 30mm 25mm 25mm;
  width: 210mm;
  height: 296mm;
  page-break-after: always;
  font-size: 16px;
}
.content {
  display: flex;
}
.right {
  text-align: right;
  .input {
    text-align: right;
    background-color: #fde9d9;
  }
}
.nameInput {
  background-color: #fde9d9;
}
.customer {
  visibility: hidden;
  position: fixed;
  white-space: nowrap;
}
.person {
  visibility: hidden;
  position: fixed;
  white-space: nowrap;
}
.kisya {
  visibility: hidden;
  position: fixed;
  white-space: nowrap;
}
.center {
  text-align: center;
}
.preWrap {
  white-space: pre-wrap;
}
.indent {
  text-indent: 1em;
}
.tab1 {
  padding-right: 1em;
}
.tab2 {
  padding-right: 2em;
}
.tab3 {
  padding-left: 3em;
}
.contact {
  > p {
    &:nth-of-type(1) {
      padding-left: 6em;
    }
    &:nth-of-type(2) {
      padding-left: 3em;
    }
    &:nth-of-type(3) {
      padding-left: 2em;
    }
  }
}

@media print {
  @page {
    size: A4;
    margin: 0mm 10mm;
  }
  .sheet {
    padding: 30mm 10mm 0;
    width: auto;
    height: auto;
    page-break-after: always;
    font-size: 16px;
  }
}
</style>
