<template>
  <div :class="styles.inner">
    <!-- <p :class="$style.message_error" v-show="errorMessage" v-cloak>
	 {{ errorMessage }}
	 </p> -->
    <PrErrorText :class="$style.message_error" v-show="errorMessage" v-cloak>
      {{ errorMessage }}
    </PrErrorText>
    <form :class="styles.formAria" @submit.prevent="handlelogin">
      <InputForm
        name="email"
        v-model="email"
        :class="styles.form"
        type="email"
        v-validate="'required|email'"
        >メールアドレス</InputForm
      >
      <InputForm
        name="password"
        v-model="password"
        :class="styles.form"
        type="password"
        v-validate="'required|password'"
        >パスワード</InputForm
      >
      <Button type="submit" color="submit" size="main">ログイン</Button>
      <Anker to="/password/reminder">パスワードを忘れた方はこちら</Anker>
    </form>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button.vue"
import InputForm from "@/components/molecules/InputForm.vue"
import Anker from "@/components/atoms/Anker.vue"
import PrErrorText from "@/components/atoms/PrErrorText.vue"
import { getRootPath } from "@/router/guards"

export default {
  data() {
    return {
      email: "",
      password: "",
      validMessage: null,
    }
  },
  props: {
    styles: {
      type: Object,
    },
  },
  components: {
    Button,
    InputForm,
    Anker,
    PrErrorText,
  },
  computed: {
    errorMessage() {
      return this.validMessage || this.$store.state.messages.message
    },
  },
  methods: {
    setValidMessage(message) {
      this.validMessage = message
    },
    validate() {
      return this.$validator.validateAll().then(result => {
        if (!result) {
          if (this.errors.firstByRule("email", "required")) {
            this.setValidMessage("メールアドレスを入力してください。")
            return false
          }

          if (this.errors.firstByRule("email", "email")) {
            this.setValidMessage("メールアドレスを正しく入力してください。")
            return false
          }

          if (this.errors.firstByRule("password", "required")) {
            this.setValidMessage("パスワードを入力してください。")
            return false
          }

          return false
        } else {
          this.setValidMessage(null)
          return true
        }
      })
    },
    async handlelogin() {
      this.$store.dispatch("messages/clearMessage")

      let result = await this.validate()
      if (!result) {
        console.log("validate error")
        return
      }

      result = await this.$store.dispatch("auth/login", {
        email: this.email,
        password: this.password,
      })

      if (!result) {
        this.setValidMessage("メールアドレスかパスワードが間違っています。")
        return
      }

      result = await this.$store.dispatch("defs/fetch")
      if (!result.ok) {
        console.log(`defs/fetch result = ${result.body}`)
        return
      }

      let path = getRootPath(this.$store.state.auth.role)
      this.$router.replace({ path: path })
    },
  },
}
</script>

<style lang="scss" module>
@include displayMessage();
</style>
