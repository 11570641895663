var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: _vm.$style.dataImport },
      [
        _c("PrInput", {
          attrs: {
            label: "ご担当者様氏名",
            name: "name",
            "pr-row": [60, 60],
            validate: "required",
          },
          model: {
            value: _vm.values.name,
            callback: function ($$v) {
              _vm.$set(_vm.values, "name", $$v)
            },
            expression: "values.name",
          },
        }),
        _vm._v(" "),
        _c("PrInput", {
          attrs: {
            label: "ご担当者様メールアドレス",
            name: "email",
            "pr-row": [60, 60],
            validate: "required|email",
          },
          model: {
            value: _vm.values.email,
            callback: function ($$v) {
              _vm.$set(_vm.values, "email", $$v)
            },
            expression: "values.email",
          },
        }),
        _vm._v(" "),
        _c("PrInput", {
          attrs: {
            label: "電話番号",
            "pr-row": [60, 60],
            name: "phone_number",
            validate: "required|telephone",
          },
          model: {
            value: _vm.values.phone,
            callback: function ($$v) {
              _vm.$set(_vm.values, "phone", $$v)
            },
            expression: "values.phone",
          },
        }),
        _vm._v(" "),
        _c("PrInput", {
          attrs: {
            label: "GigaFile便URL",
            "pr-row": [60, 60],
            name: "giga_file_url",
            validate: "url",
          },
          model: {
            value: _vm.values.giga_file_url,
            callback: function ($$v) {
              _vm.$set(_vm.values, "giga_file_url", $$v)
            },
            expression: "values.giga_file_url",
          },
        }),
        _vm._v(" "),
        _c("p", { class: _vm.$style.label }, [_vm._v("入稿データ選択")]),
        _vm._v(" "),
        _c("InputFile", {
          staticStyle: { width: "60%" },
          attrs: { "accept-type": ["zip", "ai"] },
          on: {
            change: function ($event) {
              _vm.values.file = $event
            },
          },
        }),
        _vm._v(" "),
        _vm.isButtonClicked && !(_vm.values.file || _vm.values.giga_file_url)
          ? [
              _c("PrErrorText", [
                _vm._v(
                  "\n        入稿ファイル選択またはGigaFile便URLを入力してください\n      "
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "ModalToggler",
          {
            attrs: { scroll: false, noOpen: "" },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function ({ toggleActive }) {
                  return [
                    _c(
                      "Button",
                      {
                        staticStyle: { margin: "0 auto", display: "block" },
                        attrs: {
                          type: "button",
                          color: "submit",
                          size: "main",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(toggleActive)
                          },
                        },
                      },
                      [_vm._v("送信する")]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _vm._v(" "),
            [
              _c(
                "div",
                { class: _vm.$style.done },
                [
                  _vm.success
                    ? [
                        _c("h3", [_vm._v("データの入稿が完了しました")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n              入稿処理完了のメールが届きます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              また、これよりデータチェックの工程へと進みます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              問題がなければデータチェック完了メールにてご連絡差し上げます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              問題を発見しましたらお電話もしくはメールにてご報告致します。\n            "
                          ),
                        ]),
                      ]
                    : [
                        _c("h3", [_vm._v("データの入稿に失敗しました。")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("\n              大変申し訳ございません。"),
                          _c("br"),
                          _vm._v(
                            "\n              不明なエラーによりファイルの送信に失敗しました。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              時間を再度お試し下さい。\n            "
                          ),
                        ]),
                      ],
                ],
                2
              ),
            ],
          ],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }