<template>
  <div
    :class="[$style.wrapper, { [$style.dragMode]: dragMode }]"
    ref="wrapper"
    :draggable="checkStatus(deal, 13) && dragMode"
    @dragstart="$emit('dragstart', deal)"
    @dragend="
      $emit('dragend')
      dragMode = false
    "
  >
    <h3>{{ deal.request_number }}</h3>

    <v-btn
      v-if="checkStatus(deal, 13, 14)"
      outline
      small
      color="indigo"
      @click="handleEditClick"
      :style="
        checkStatus(deal, 13)
          ? 'margin:0 0 5px;width:100%;'
          : 'margin:0;width:100%;'
      "
    >
      <v-icon>edit</v-icon>
    </v-btn>

    <div
      v-if="checkStatus(deal, 13)"
      :class="$style.drag"
      @mousedown="dragMode = true"
      @mouseup="dragMode = false"
    >
      <v-icon color="indigo">drag_handle</v-icon>
    </div>

    <p>
      <router-link :to="`/admin/dealings/${deal.request_number}`">
        {{ deal.request_name }}
      </router-link>
    </p>

    <template v-if="!checkStatus(deal, 1, 2, 3)">
      <template v-if="deal.contract">
        <p>
          <router-link
            :to="
              `/admin/dealings/card/${deal.request_number}/contracts/${
                deal.contract.request_key2
              }`
            "
            >{{ deal.contract.request_name }}</router-link
          >
        </p>

        <p v-if="checkStatus(deal, 10)">
          入稿:{{ deal.contract.request_date2 | getDate }}
        </p>
        <p v-if="checkStatus(deal, 10)">
          注文:{{ deal.contract.contract.contracted_date | getDate }}
        </p>
        <p v-if="checkStatus(deal, 11)">
          発注:{{ deal.contract.request_date5 | getDate }}
        </p>
      </template>
      <p v-if="checkStatus(deal, 11, 13)">
        出庫:{{ deal.product.request_date1 | getDate }}
      </p>
      <p v-if="checkStatus(deal, 13)">
        港:{{ deal.product.request_date2 | getDate }}
      </p>
      <p v-if="checkStatus(deal, 14)">
        検品:{{ deal.product.request_date3 | getDate }}
      </p>
      <p v-if="checkStatus(deal, 20)">
        発送:{{ deal.delivery.request_date2 | getDate }}
      </p>
      <p v-if="checkStatus(deal, 10, 11, 13, 14, 20)">
        納期:{{ deal.request_date2 | getDate }}
      </p>

      <p v-if="checkStatus(deal, 13, 14, 20)">
        *{{ deal.product.quantity3 }}箱出庫
      </p>
      <p v-if="checkStatus(deal, 14, 20)">*{{ deal.product.quantity4 }}個着</p>
      <p v-if="checkStatus(deal, 14, 20)">
        *{{ deal.product.quantity4 - deal.product.quantity6 }}個送
      </p>
      <p v-if="checkStatus(deal, 14, 20)">
        *{{ deal.product.quantity5 }}箱発送
      </p>
      <p v-if="checkStatus(deal, 14, 20)">
        *{{ deal.product.quantity6 }}個保管
      </p>

      <template v-if="checkStatus(deal, 15)">
        <template v-if="deal.defect.length !== 0">
          <p>対応期限:{{ deal.defect[0].request_date2 | getDate }}</p>
          <div :class="$style.defect">
            <p>不良原因:</p>
            <p>{{ deal.defect[0].note2 }}</p>
          </div>
          <div :class="$style.defect">
            <p>不良概要:</p>
            <p>{{ deal.defect[0].note3 }}</p>
          </div>
        </template>
        <p :class="$style.noDefect" v-else>不良品情報を<br />登録して下さい</p>
      </template>

      <PrImg
        v-if="getVerificationImage(deal)"
        :src="getVerificationImage(deal)"
      />

      <span
        v-for="{ name, text } in checkFlags(deal)"
        :name="name"
        :key="name"
        >{{ text }}</span
      >
    </template>
  </div>
</template>

<script>
import PrImg from "@/components/atoms/PrImg.vue"
import { getDate } from "@/utils/shared.js"
import {
  getVerificationImage,
  checkStatus,
  checkFlags,
} from "@/helpers/AdminDealingIndexCardHelper.js"

export default {
  components: {
    PrImg,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dragMode: false,
    }
  },
  filters: {
    getDate,
  },
  methods: {
    getVerificationImage,
    checkStatus,
    checkFlags,
    handleEditClick() {
      const { top, right } = this.$refs.wrapper.getBoundingClientRect()
      const deal = this.deal
      this.$emit("edit", { deal, top, right })
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  padding: 5px;
  background-color: map-get($colors, lightGray);
  font-size: 0.1rem;
  border-radius: 5px;
  &.dragMode {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(map-get($colors, orange), 0.3);
      pointer-events: none;
    }
  }
  > h3 {
    text-align: center;
    background-color: map-get($colors, lightlightGray);
    border-radius: 5px;
    margin-bottom: 3px;
  }
  > p {
    overflow-x: hidden;
    > a {
      white-space: nowrap;
      font-size: 0.12rem;
    }
  }
  > img {
    margin-bottom: 2px;
  }
  > span {
    display: inline-block;
    margin: 2px;
    padding: 0.3em 0.5em;
    background-color: lime;
    border-radius: 5px;
    border: 1px solid black;
    font-weight: bold;
    &[name="draft"] {
      background-color: #00eb56;
    }
    &[name="data_check"] {
      background-color: #51e5e1;
    }
    &[name="noImage"] {
      background-color: #272727;
      color: #fff;
    }
    &[name="proofreading"] {
      background-color: #fdd11b;
    }
    &[name="cod"] {
      background-color: #fb3031;
    }
    &[name="separate"] {
      background-color: #20fb26;
    }
    &[name="invoice"] {
      background-color: #d703fc;
      color: #fff;
    }
    &[name="defective"] {
      background-color: #eb6304;
    }
    &[name="time_service"] {
      background-color: #00ff00;
    }
    &[name="internal_job"] {
      background-color: #2b5cfd;
      color: #fff;
    }
    &[name="note"] {
      background-color: #2b3ffb;
      color: #fff;
    }
  }
  .drag {
    padding-top: 1px;
    text-align: center;
    border: 1px solid rgb(63, 81, 181);
    border-radius: 2px;
    cursor: grab;
    position: relative;
    &:active {
      cursor: grabbing;
    }
  }
}
.defect {
  margin-bottom: 0.3em;
  display: grid;
  grid-template-columns: 4.5em 1fr;
  > p:last-child {
    padding: 0 0.3em;
    background-color: map-get($colors, lightlightGray);
  }
}
.noDefect {
  font-weight: bold;
  text-align: center;
  color: map-get($colors, red);
  padding: 5px 0;
  margin: 5px 0;
  border: 1px solid map-get($colors, red);
  border-radius: 5px;
}
</style>
