<template>
  <div v-if="isReady" :class="$style.wrapper">
    <PageTitle>最終確認画像</PageTitle>
    <CustomerUserName :customer-person="items.customer_person" />
    <div :class="$style.block">
      <div :class="$style.block_container">
        <div :class="$style.checkImage">
          <p :class="[$style.explain, $style.dark]">
            お客様からご注文いただいた商品の最終 確認画像になります。
            問題がなければ「確認しました」ボタン を押してください。
          </p>
          <img :class="$style.checkImage_img" :src="info.appendix_name" />
          <div :class="$style.button">
            <Button color="submit" size="main" @click="handleConfirmed"
              >確認しました</Button
            >
          </div>
          <PrTextarea
            label="修正のご依頼がある場合は、下記に記入 してください。"
            v-model="content"
            name="content"
            :validate="'required'"
            :pr-row="[100, 100]"
          />
          <div :class="$style.button">
            <Button color="alert" size="assist" @click="handleModify"
              >修正を依頼する</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue"
import Button from "@/components/atoms/Button.vue"
import PrTextarea from "@/components/molecules/PrTextarea.vue"
import CustomerUserName from "@/components/atoms/CustomerUserName.vue"
import api, { paths } from "@/utils/api.js"
import { showErrorToast, routeReplaceError } from "@/utils/shared.js"

export default {
  components: {
    PageTitle,
    Button,
    PrTextarea,
    CustomerUserName,
  },
  data() {
    return {
      isReady: false,
      id: null,
      items: null,
      info: null,
      content: "",
    }
  },
  async created() {
    this.id = this.$route.params.deal_id

    const { ok, body } = await this.getDealing()
    if (!ok) {
      routeReplaceError(this, body.slice(-3))
      return
    }

    // 未確認でない場合
    if (
      body.request_appendices.find(
        ({ appendix_category }) => appendix_category === "verification_image",
      ).request_key4 != 1
    ) {
      routeReplaceError(this, 900)
      return
    }

    this.items = body
    const { request_appendices } = this.items

    this.info = request_appendices.find(
      data => data.appendix_category === "verification_image",
    )

    this.isReady = true
  },
  methods: {
    async getDealing() {
      const { ok: result, body: items } = await api.get(
        `${paths.CUSTOMER_DEALINGS}/${this.id}`,
        this.$store.state.auth.token,
      )

      console.log("result:", result, "items", items)
      return { ok: result, body: items }
    },
    async handleConfirmed() {
      await this.execApi(1, "")
    },
    async handleModify() {
      const result = await this.$validator.validateAll().then(r => r)
      if (!result) {
        return
      }

      await this.execApi(2, this.content)
    },
    async execApi(status, content) {
      const data = {
        request_number: this.info.request_number,
        request_key1: this.info.request_key1,
        lock_version: this.info.lock_version,
        appendix_status: status,
        id: this.info.id,
        //appendix_description: content,
        appendix_category: this.info.appendix_category,
      }

      if (content !== "") {
        data.appendix_description = content
      }

      const loader = this.$loading.show()
      try {
        const { ok, body, errorMsg } = await api.put(
          `${paths.CUSTOMER_VERIFICATION_IMAGE}/${this.id}`,
          data,
          this.$store.state.auth.token,
        )

        if (!ok) {
          showErrorToast(this, body.slice(-3), errorMsg)
          return
        }

        this.$router.push({ path: "/customer/dealings" })
      } finally {
        loader.hide()
      }
    },
  },
}
</script>

<style lang="scss" module>
@include clientPage();

.button {
  margin: 20px 0;
  text-align: center;
}

.checkImage {
  width: 100%;
  &_img {
    display: block;
    margin: 0 auto;
  }
  > textarea {
    width: 100%;
    border: 1px solid #eee;
  }
}
</style>
