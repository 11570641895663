const telephone = {
  validate: value => {
    const regex = /^\d{2,5}-\d{1,4}-\d{4}$/
    return regex.test(value)
  },
}

const fax = {
  validate: value => {
    const regex = /^\d{2,5}-\d{1,4}-\d{4}$/
    return regex.test(value)
  },
}

const mobilephone = {
  validate: value => {
    const regex = /^\d{2,5}-\d{1,4}-\d{4}$/
    return regex.test(value)
  },
}

const zipcode = {
  validate: value => {
    const regex = /^[0-9]{3}-[0-9]{4}$/
    return regex.test(value)
  },
}

const voucher = {
  validate: value => {
    const regex = /^[0-9-]+$/
    return regex.test(value)
  },
}

const dot_num = {
  validate: value => {
    const regex = /^[0-9.]+$/
    return regex.test(value)
  },
}

const password = {
  validate: value => {
    const regex = /^[0-9a-zA-Z!-/:-@¥[-`{-~]+$/
    return regex.test(value)
  },
}

export default {
  telephone,
  zipcode,
  voucher,
  dot_num,
  fax,
  mobilephone,
  password,
}
