<template>
  <div>
    <div
      @click="
        () => {
          if (!noOpen) toggleIsActive()
        }
      "
    >
      <slot name="button" :toggleActive="toggleIsActive" />
    </div>
    <div v-if="isActive || debug !== undefined" :class="$style.container">
      <div :class="$style.wrapper">
        <v-btn
          v-if="closeButton"
          @click="toggleIsActive"
          icon
          :absolute="true"
          :class="$style.closeButton"
        >
          <v-icon x-large color="white">close</v-icon>
        </v-btn>
        <div :class="{ [$style.inner]: scroll }">
          <slot :toggle="toggleIsActive" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  props: {
    debug: null,
    scroll: {
      type: Boolean,
      default: true,
    },
    noOpen: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    toggleIsActive() {
      if (this.isActive) {
        document.body.removeAttribute("style", "overflow: hidden;")
        document.body.parentElement.removeAttribute(
          "style",
          "overflow: hidden;",
        )
      } else {
        document.body.setAttribute("style", "overflow: hidden;")
        document.body.parentElement.setAttribute("style", "overflow: hidden;")
      }
      this.isActive = !this.isActive
    },
  },
}
</script>

<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#000, 0.4);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  position: relative;
}
.closeButton {
  top: 0;
  right: -40px;
}
.inner {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
