var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c("div", { class: _vm.$style.wrapper }, [
        _c("p", { class: _vm.$style.pageTtl }, [
          _vm._v("検品内容 " + _vm._s(_vm.today)),
        ]),
        _vm._v(" "),
        _c("table", { class: _vm.$style.table }, [
          _c("thead", { class: _vm.$style.table_thead }, [
            _c("tr", { class: _vm.$style.table_thead_tr }, [
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("商談名"),
              ]),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("最終確認画像"),
              ]),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("見積り名"),
              ]),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("出庫箱数"),
              ]),
              _vm._v(" "),
              _c(
                "td",
                {
                  class: _vm.$style.table_thead_tr_td,
                  staticStyle: { "line-height": "1.1em" },
                },
                [
                  _vm._v("\n          出庫商品 "),
                  _c("br"),
                  _vm._v("総数\n        "),
                ]
              ),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("保管商品個数"),
              ]),
              _vm._v(" "),
              _c(
                "td",
                {
                  class: _vm.$style.table_thead_tr_td,
                  staticStyle: { "line-height": "1.1em" },
                },
                [
                  _vm._v("\n          発送商品 "),
                  _c("br"),
                  _vm._v("総数\n        "),
                ]
              ),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("発送予定日"),
              ]),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("納品希望日"),
              ]),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("備考"),
              ]),
              _vm._v(" "),
              _c("td", { class: _vm.$style.table_thead_tr_td }, [
                _vm._v("公開"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            { class: _vm.$style.table_tbody },
            _vm._l(_vm.inspections, function (deal) {
              return _c(
                "tr",
                { key: deal.id, class: _vm.$style.table_tbody_tr },
                [
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(_vm._s(deal.request_name)),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _c("img", {
                      ref: `img_${deal.id}`,
                      refInFor: true,
                      staticStyle: { width: "25mm" },
                      attrs: {
                        src: deal.request_appendices.find(
                          ({ appendix_category }) =>
                            appendix_category === "verification_image"
                        ).appendix_name,
                      },
                      on: { load: _vm.handleImgLoad },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(deal.contract.request_name) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(deal.product.quantity3) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(deal.product.quantity4) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(deal.product.quantity6) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          +deal.product.quantity4 - +deal.product.quantity6
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("getDate")(deal.delivery.request_date2)) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("getDate")(deal.request_date2)) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _c(
                      "p",
                      { class: _vm.$style.table_tbody_tr_td_p },
                      [
                        _vm._l(_vm.checkFlags(deal), function ({ name, text }) {
                          return _c(
                            "span",
                            {
                              key: name,
                              class: _vm.$style.note,
                              attrs: { name: name },
                            },
                            [_vm._v(_vm._s(text))]
                          )
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n            " +
                            _vm._s(deal.product.note2) +
                            "\n          "
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { class: _vm.$style.table_tbody_tr_td }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("releaseInfo")(deal.contract.note2)) +
                        "\n        "
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }